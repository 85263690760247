import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/css';
import FullScreenLayout from '../../scenes/fullScreenLayout';
import PageContainer from './PageContainer';
import Button from '../general/Button';
import FooterBar from './FooterBar';
import styled from '@emotion/styled';
import ModalHeader from '../general/ModalHeader';
import { breakpoint_small } from '../../constants/breakpoints';

interface ConfirmationDialogProps {
    onClose?: () => void
    onConfirm?: (e) => void
    deleteText?: string
    title?: string
    isLoading?: boolean
    disabled?: boolean
    warning?: boolean
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {

    const containerRef = useRef(null);

    useEffect(() => {
        containerRef.current.focus();
    }, []);

    const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        if (props.isLoading || props.disabled) return;

        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <div ref={containerRef}
            tabIndex={-1}
            onKeyUp={(e) => handleKeyUp(e)}
        >
            <div className={innerLayoutStyle}>
                <div className={innerWrapperStyle}>
                    <FullScreenLayout>
                        <PageContainer fill withFooter>
                            <ModalHeader title={props.title} onClose={props.onClose} />
                            {props.children}
                            <FooterBar>
                                <StyledSubmitButton
                                    loading={props.isLoading}
                                    disabled={props.isLoading || props.disabled}
                                    themes={[(props.warning ? "primary" : "destructive")]}
                                    text={props.deleteText}
                                    type="button"
                                    onClick={props.onConfirm}
                                />
                                <StyledButton
                                    disabled={props.isLoading}
                                    themes={["white", "secondary"]}
                                    text="Cancel"
                                    type="button"
                                    onClick={props.onClose}
                                />
                            </FooterBar>
                        </PageContainer>
                    </FullScreenLayout>
                </div>
            </div>
            <div className={layoutStyle} onClick={props.isLoading ? null : props.onClose}>
            </div>
        </div>
    )
}

const StyledSubmitButton = styled(Button)`
    ${breakpoint_small} {
        width: 100px;
        span {
            font-size: 12px;
        }
    }
    margin-right: auto;
`;

const StyledButton = styled(Button)`
    ${breakpoint_small} {
        width: 100px;
        span {
            font-size: 12px;
        }
    }
`;

const layoutStyle = css`
    overflow-x: visible;
    background: rgba(44,61,82, 0.7);
    padding: 65px 115px;
    z-index: 1003;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const innerLayoutStyle = css`
    border-radius: 20px;
    border: 12px solid rgba(44,61,82, 0.8);    
    display: block;
    width: 580px;
    height: 520px;
    max-height: calc(100vh - 210px);
    position: fixed;
    z-index: 1004;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    overflow: hidden;
    ${breakpoint_small} {
        width: 90%;
    }
`;

const innerWrapperStyle = css`
    border-radius: 20px;
    button:disabled {
        background: linear-gradient(258deg, #c9c9c9, #a8a8a8 63%, #929292);
        cursor: not-allowed;
    }
`;
const SubmitButton = styled(Button)`
    margin-right: auto;
`;

export default ConfirmationDialog;