import { GET_MEDIAITEMS, GET_UPLOAD_URL, MEDIAVERIFICATION } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendGet, uploadFile, putFile, sendPost, sendPut, sendDelete } from './generic';
import MediaItemModel from '../../models/media/MediaItemModel';
import NewMediaItemRequest from '../../models/media/NewMediaItemRequest';
import MediaItemLinkedContent from '../../models/media/MediaItemLinkedContent';

export const getMediaItemsForApplication = (applicationId: string): Promise<IResult<MediaItemModel[]>> => {
    return sendGet<MediaItemModel[]>(`${GET_MEDIAITEMS}/${applicationId}`);
}

export const getDisabledMediaItemsForApplication = (applicationId: string): Promise<IResult<MediaItemModel[]>> => {
    return sendGet<MediaItemModel[]>(`${GET_MEDIAITEMS}/disabled/${applicationId}`);
}

export const getContentItemsForMediaItem = (mediaItemId: string): Promise<IResult<MediaItemLinkedContent>> => {
    return sendGet<MediaItemLinkedContent>(`${MEDIAVERIFICATION}/contentItems/${mediaItemId}`);
}

export const disableMediaItem = (itemId: string): Promise<IResult<MediaItemModel>> => {
    return sendDelete<MediaItemModel>(`${GET_MEDIAITEMS}/${itemId}/disable`);
}

export const deleteMediaItem = (itemId: string): Promise<IResult<MediaItemModel>> => {
    return sendDelete<MediaItemModel>(`${GET_MEDIAITEMS}/${itemId}`);
}

export const uploadMediaItem = (applicationId: string, name: string, file: File): Promise<IResult<MediaItemModel>> => {
    return uploadFile<MediaItemModel>(`${GET_MEDIAITEMS}/${applicationId}/upload?name=${escape(name)}`, file);
}

export const getUploadUrl = (applicationId: string, fileName: string, displayName: string): Promise<IResult<string>> => {
    return sendGet<string>(`${GET_UPLOAD_URL}/${applicationId}/uploadUrl?fileName=${escape(fileName)}&name=${escape(displayName)}`);
}

export const getOrganizationUploadUrl = (organizationId: string, fileName: string, displayName: string): Promise<IResult<string>> => {
    return sendGet<string>(`${GET_UPLOAD_URL}/${organizationId}/orgUploadUrl?fileName=${escape(fileName)}&name=${escape(displayName)}`);
}

export const uploadDirect = (uploadUrl: string, file: File, progressCallback?: (number) => void): Promise<IResult<any>> => {
    return putFile<any>(uploadUrl, file, progressCallback);
}
export const updateMediaItem = (mediaItemId: string, mediaItem: MediaItemModel): Promise<IResult<MediaItemModel>> => {
    return sendPut<MediaItemModel>(`${GET_UPLOAD_URL}/${mediaItemId}`, mediaItem);
}

export const createMediaItem = (applicationId: string, model: NewMediaItemRequest): Promise<IResult<MediaItemModel>> => {
    return sendPost<MediaItemModel>(`${GET_MEDIAITEMS}/${applicationId}`, model);
}

export const convertAudioItemToSsml = (mediaItemId: string) : Promise<IResult<MediaItemModel>> => {
    return sendPost<MediaItemModel>(`/mediaitem/convert/ssml/${mediaItemId}`, '');
}