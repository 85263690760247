import axios from 'axios';
import { GET_HELPMESSAGES, ADD_HELPMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import FallbackMessageModel from '../../models/features/api/Fallback/FallbackMessageModel';
import NewFallbackMessageRequest from '../../models/features/api/Fallback/NewFallbackMessageRequest';
import UpdateFallbackMessageRequest from '../../models/features/api/Fallback/UpdateFallbackMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import FallbackMessageCategoryModel from '../../models/features/api/Fallback/FallbackMessageCategoryModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getFallbackMessagesForApp = (applicationId: string): Promise<IResult<FallbackMessageModel[]>> => {
    return sendGet<FallbackMessageModel[]>(`/FallbackMessage/${applicationId}`);
}

export const getFallbackMessagesForAppFeature = (applicationFeatureId: string): Promise<IResult<FallbackMessageModel[]>> => {
    return sendGet<FallbackMessageModel[]>(`/FallbackMessage/byFeature/${applicationFeatureId}`);
}

export const addFullFallbackMessage = (model: FallbackMessageModel): Promise<IResult<FallbackMessageModel>> => {
    return sendPost<FallbackMessageModel>(`/FallbackMessage/full`, model);
}
export const addFallbackMessage = (applicationId: string, model: NewFallbackMessageRequest): Promise<IResult<FallbackMessageModel>> => {
    return sendPost<FallbackMessageModel>(`/FallbackMessage/${applicationId}`, model);
}
export const addFallbackMessageStub = (model: NewFallbackMessageRequest): Promise<IResult<FallbackMessageModel>> => {
    return sendPost<FallbackMessageModel>(`/FallbackMessage/${model.applicationId}/stub`, model);
}

export const toggleFallbackMessageLive = (fallbackMessageId: string, isLive: boolean) : Promise<IResult<FallbackMessageModel>> => {
    return sendPut<FallbackMessageModel>(`/FallbackMessage/${fallbackMessageId}/isLive`, {
        isLive
    });
}

export const toggleFallbackMessageSync = (fallbackMessageId: string, shouldNotSync: boolean): Promise<IResult<FallbackMessageModel>> => {
    return sendPut<FallbackMessageModel>(`/FallbackMessage/${fallbackMessageId}/shouldNotSync`, {
        shouldNotSync
    });
}

export const updateFullFallbackMessage = (fallbackMessageId: string, model: FallbackMessageModel) : Promise<IResult<FallbackMessageModel>> => {
    return sendPut<FallbackMessageModel>(`/FallbackMessage/${fallbackMessageId}/full`, model);
}
export const updateFallbackMessage = (fallbackMessageId: string, model: UpdateFallbackMessageRequest) : Promise<IResult<FallbackMessageModel>> => {
    return sendPut<FallbackMessageModel>(`/FallbackMessage/${fallbackMessageId}/update`, model);
}
export const updateFallbackMessageStub = (fallbackMessageId: string, model: UpdateFallbackMessageRequest) : Promise<IResult<FallbackMessageModel>> => {
    return sendPut<FallbackMessageModel>(`/FallbackMessage/${fallbackMessageId}/update/stub`, model);
}


export const deleteFallbackMessage = (fallbackMessageId: string) : Promise<IResult<FallbackMessageModel>> => {
   return sendDelete<FallbackMessageModel>(`/FallbackMessage/${fallbackMessageId}`);
}

export const findFallbackMessage = (fallbackMessageId: string) : Promise<IResult<FallbackMessageModel>> => {
    return sendGet<FallbackMessageModel>(`/FallbackMessage/full/${fallbackMessageId}`);
 }

export const getFallbackMessageWebhooks = (fallbackMessageId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/FallbackMessage/${fallbackMessageId}/webhooks`);
}

export const removeFallbackMessageWebhook = (fallbackMessageWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/FallbackMessage/webhook/${fallbackMessageWebhookId}`);
}

export const addFallbackMessageWebhook = (fallbackMessageId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/FallbackMessage/${fallbackMessageId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateFallbackMessageWebhook = (fallbackMessageId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/FallbackMessage/${fallbackMessageId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const getFallbackMessageCategories = (): Promise<IResult<FallbackMessageCategoryModel[]>> => {
    return sendGet(`/Fallbackmessage/categories`);
}

export const copyFallbackMessage = (contentItemId: string): Promise<IResult<FallbackMessageModel>> => {
    return sendPost<FallbackMessageModel>(`/FallbackMessage/${contentItemId}/copy`, null);
}

export const bulkUpdateFallbackMessageWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/FallbackMessage/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getFallbackMessagePublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/FallbackMessage/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}