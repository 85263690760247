import * as voicifyApi from '../../api';
import NumberRangeModel from '../../models/features/api/NumberRanges/NumberRangeModel';
import NewNumberRangeRequest from '../../models/features/api/NumberRanges/NewNumberRangeRequest';
import BulkUpdateNumberRangeRequest from '../../models/features/api/NumberRanges/BulkUpdateNumberRangeRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import { NumberRangeFeatureTypeId } from '../../constants/featureTypeIds';
import { getNumberRangePrefix } from '../../models/extensions/followUps';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { NumberRangeFormData } from '../../scenes/formScenes/NumberRangeForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

interface NumberRangeState extends ContentItemState<NumberRangeModel> {
}

export default class NumberRangeContainer extends GenericContentItemContainer<NumberRangeState, NumberRangeModel, NewNumberRangeRequest, BulkUpdateNumberRangeRequest, NumberRangeFormData> {

    // default state
    state = {
        contentItems: [],
        isLoading: false,
        errors: [],
        isSaving: false,
        isPublishing: false,
        isSavingStub: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }


    mapModelToGenericContentItemModel = (model: NumberRangeModel) => ({
        ...model,
        hasAudio: model.responses.some(a => a.audio != null),
        hasVideo: model.responses.some(a => a.video != null),
        hasImage: model.responses.some(a => a.largeImage != null),
        hasBackgroundImage: model.responses.some(a => a.backgroundImage != null),
        featureTypeId: NumberRangeFeatureTypeId,
        titleAttribute: getNumberRangePrefix(model.minimumValue, model.maximumValue)

    })
    mapFormDataToModel = (originalModel: NumberRangeModel, formData: NumberRangeFormData) => ({
        ...originalModel,
        webhookChainingType: formData.webhookChainingType,
        title: formData.title,
        minimumValue: formData.minimumValue,
        maximumValue: formData.maximumValue,
        requiresParent: formData.requiresParent,
        isLive: formData.isLive,
        languages: formData.languages,
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,
        disabledForFeatureFlagIds: formData.disabledForFeatureFlagIds,
    });


    featureTypeId = NumberRangeFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getNumberRangesForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => voicifyApi.getNumberRangesForModule(applicationModuleId);
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getNumberRangesForAppFeature(applicationFeatureId);
    createPromise = (model: NewNumberRangeRequest) => voicifyApi.createNumberRange(model);
    createStubPromise = (model: NewNumberRangeRequest) => voicifyApi.createNumberRangeStub(model);
    createFromModelPromise = (model: NumberRangeModel) => voicifyApi.createFullNumberRange(model);
    updatePromise = (contentItemId: string, model: BulkUpdateNumberRangeRequest) => voicifyApi.bulkUpdateNumberRange(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: BulkUpdateNumberRangeRequest) => voicifyApi.bulkUpdateNumberRangeStub(contentItemId, model);
    updateFromModelPromise = (model: NumberRangeModel) => voicifyApi.updateFullNumberRange(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteNumberRange(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleNumberRangeLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleNumberRangeSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyNumberRange(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findNumberRange(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getNumberRangePublishHistory(contentItemId, environmentId, skip, take);

    getWebhooksPromise = (contentItemId: string) => voicifyApi.getNumberRangeWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeNumberRangeWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateNumberRangeWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addNumberRangeWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateNumberRangeWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);
    getSimpleSampleFlows = (contentItem: NumberRangeModel, application?: ApplicationModel) => {
        const getRangeType = (min?: number, max?: number) => {
            if (min != null && max != null && min == max) {
                return "equal";
            }
            else if (min != null && max != null) {
                return "between";
            }
            else if (min != null) {
                return "greater-than";
            }
            else if (max != null) {
                return "less-than";
            }
            return "equal";
        }
        const getUserUtterances = () => {
            switch (getRangeType(contentItem.minimumValue, contentItem.maximumValue)) {
                case "equal": return [{ id: "equal", content: contentItem.minimumValue?.toString(), highlights: [] }];
                case "between": return [
                    { id: "middle-random", content: `[Value greater than ${contentItem.minimumValue} but less than ${contentItem.maximumValue}]`, highlights: [] },
                    { id: "lower", content: contentItem.minimumValue?.toString(), highlights: [] },
                    { id: "upper", content: contentItem.maximumValue?.toString(), highlights: [] },
                ];
                case "greater-than": return [
                    { id: "middle-random", content: `[Value greater than ${contentItem.minimumValue}]`, highlights: [] },
                    { id: "lower", content: '' + contentItem.minimumValue?.toString(), highlights: [] },
                ];
                case "less-than": return [
                    { id: "middle-random", content: `[Value less than ${contentItem.maximumValue}]`, highlights: [] },
                    { id: "upper", content: '' + contentItem.maximumValue?.toString(), highlights: [] },
                ];
            }

            return []
        }
        return [{
            requests: getUserUtterances(),
            followUp: contentItem.responses[0]?.followUp,
            responses: contentItem.responses.map(a => ({
                content: a.content,
                highlights: [a.content],
                id: a.content
            })),
            requestTitle: "User Says",
            responseTitle: "Device Response"
        }]
    }

}
