import React from 'react';
import styled from "@emotion/styled";
import ConversationMarkdown from './ConversationMarkdown';
import { dark_grey_blue_20, dark_sky_blue, greeny_blue } from '../../../constants/colors';
import Button from '../../../components/general/Button';
import { breakpoint_small } from '../../../constants/breakpoints';
const deviceIcon = require("../../../content/images/device.svg");
const userAvatar = require("../../../content/images/user-avatar.svg");

interface ChatBubbleProps {
    markdown: string
    sender: "bot" | "user",
    showCodeModal?: () => void
    simple?: boolean
}

const LiveChatBubble: React.FC<ChatBubbleProps> = ({ markdown, sender, showCodeModal, simple }) => {
    if (sender === "user") {
        return (
            <ConversationContainer>
                <BotAvatar src={userAvatar} />
                <BotConversation>
                    <ConversationMarkdown className="content-container" conversationText={markdown} />
                </BotConversation>
                { !simple && <StyledButton
                    disabled={false}
                    themes={['secondary-small']}
                    text="Full Interaction JSON"
                    onClick={() => showCodeModal()}
                    type="button" />}
            </ConversationContainer>
        )
    } else {
        return (
            <ConversationContainer>
                <UserConversation>
                    <ConversationMarkdown className="content-container" conversationText={markdown} />
                </UserConversation>
                <UserAvatar src={deviceIcon} />
            </ConversationContainer>
        )
    }
};

const ConversationContainer = styled.div`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
        height: 24px;
        width: 24px;
    }
`;
const StyledButton = styled(Button)`
    top: 32px;
    right: 32px;
`;

const UserConversation = styled.div`
    margin: 30px 0 0 300px;    
    border: solid 1px ${greeny_blue};
    box-shadow: 0 1px 2px 0 ${dark_grey_blue_20};
    background: white;
    border-radius: 10px 0 10px 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    ${breakpoint_small} {
        margin: 16px 0 0 0;
    }
`;

const UserAvatar = styled.img`
    background: white;
    height: 24px;
    width: 24px;
    margin: 0 0 0 8px;
    border-radius: 50px;
`;

const BotAvatar = styled.img`
    background: white;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin: 16px 8px 0 0;
`;

const BotConversation = styled.div`
    margin: 30px 200px 0 0;  
    border: solid 1px ${dark_sky_blue};
    box-shadow: 0 1px 2px 0 ${dark_grey_blue_20};
    background: white;
    border-radius: 0 10px 10px 10px;
    padding: 20px;
    display: flex;
    position: relative;  
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    ${breakpoint_small} {
        margin: 16px 0 0 0;
    }
`;
export default LiveChatBubble;