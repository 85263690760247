import React from 'react';
import { css } from '@emotion/css';
import LevelSelector from './LevelSelector';
import ssmlParseService from '../../../services/ssmlParseService';
import SsmlParseService from '../../../services/ssmlParseService';

interface VolumeSelectorProps {
    onChange: (ssml: string, placeholder?: string) => void
    ssml?: string
}

interface VolumeSelectorState {
    value: number
    isCustomSelected: boolean

}

class VolumeSelector extends React.Component<VolumeSelectorProps, VolumeSelectorState> {
    private ssmlParser = new SsmlParseService()
    private placeholder: string = 'Volume changed text'
    private options = [
        { name: 'Silent', value: -100 },
        { name: 'Very Soft', value: -4.0 },
        { name: 'Soft', value: -2.0 },
        { name: 'Medium', value: 0.0 },
        { name: 'Loud', value: 2.0 },
        { name: 'Very Loud', value: 4.0 },
    ];
    componentWillMount() {
        this.setInitialState();
        // send out the default ssml when it is first added
        var ssml = this.props.ssml ? this.props.ssml : `<prosody volume="-2.0dB">${this.placeholder}</prosody>`;
        this.props.onChange(ssml, this.placeholder);
    }

    private setInitialState() {
        if (this.props.ssml) {
            // parse the break tag to set state
            const volume = this.ssmlParser.getElementProperty(this.props.ssml, 'prosody', 'volume')
            if (volume) {
                this.setState({
                    ...this.state,
                    isCustomSelected: volume != 'silent' && !this.options.some(o => o.value + 'dB' == volume.replace('+', '')),
                    value: volume == 'silent' ? -100 : parseInt(volume.replace('dB', '').replace('+', ''))
                })
                return;
            }
        }
        this.setState({
            ...this.state,
            value: -2,
            isCustomSelected: false
        })
    }

    buildSsml(value?: number) {
        value = value ? value : this.state.value
        var valueLabel = "" + value + "dB"

        // need explicit "+"
        if (value > 0) valueLabel = "+" + valueLabel
        if (value == -100) valueLabel = 'silent';
        const ssml = `<prosody volume="${valueLabel}">${this.placeholder}</prosody>`;
        this.props.onChange(ssml, this.placeholder);
    }

    handleCustomSelected() {
        this.setState({
            ...this.state,
            isCustomSelected: true
        })
        this.buildSsml();
    }

    handleOptionSelected(option) {
        this.setState({
            ...this.state,
            isCustomSelected: false,
            value: option.value
        })
        this.buildSsml(option.value);
    }

    handleCustomChanged(value: number) {
        this.setState({
            ...this.state,
            isCustomSelected: true,
            value: value
        })
        this.buildSsml(value);
    }

    render() {
        return (
            <div>
                <LevelSelector options={this.options}
                    onCustomSelected={this.handleCustomSelected.bind(this)}
                    onOptionSelected={this.handleOptionSelected.bind(this)}
                    onCustomValueChanged={this.handleCustomChanged.bind(this)}
                    value={this.state.value}
                    unit='dB'
                    isCustom={this.state.isCustomSelected}
                />
            </div>
        )
    }
}

export default VolumeSelector;