import IResult from "../../models/result/IResult";
import { sendGet, sendPost } from "./generic";
import IntegrationConfiguration from "../../models/integrations/IntegrationFormField";
import { getIntegrationsApiUrl } from "../../constants/Urls";
import OAuthResultRequest from "../../models/integrations/api/OAuthResultRequest";
import IntegrationSetupRequest from "../../models/integrations/setup/IntegrationSetupRequest";

export const getIntegrationSetup = (organizationId : string, integrationListingId: string) : Promise<IResult<IntegrationConfiguration>> => {
    return sendGet<IntegrationConfiguration>(`/setup/${organizationId}/${integrationListingId}`, getIntegrationsApiUrl());
}

export const sendIntegrationConfiguration = (organizationId : string, integrationListingId : string, setupRequest: IntegrationSetupRequest) : Promise<IResult<boolean>> => {
    return sendPost(`/setup/${organizationId}/${integrationListingId}/config`, setupRequest, getIntegrationsApiUrl());
}

export const sendIntegrationOauthResult = (organizationId : string, request: OAuthResultRequest, search: string) : Promise<IResult<boolean>> => {
    return sendPost(`/setup/${organizationId}/oAuth${search}`, request, getIntegrationsApiUrl());
}