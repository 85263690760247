import * as voicifyApi from '../../api';
import SimpleChoiceModel from '../../models/features/api/SimpleChoices/SimpleChoiceModel';
import NewSimpleChoiceRequest from '../../models/features/api/SimpleChoices/NewSimpleChoiceRequest';
import BulkUpdateSimpleChoiceRequest from '../../models/features/api/SimpleChoices/BulkUpdateSimpleChoiceRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import { SimpleChoiceFeatureTypeId } from '../../constants/featureTypeIds';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import { SimpleChoiceFormData } from '../../scenes/formScenes/SimpleChoiceForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

interface SimpleChoiceState extends ContentItemState<SimpleChoiceModel> {
}

export default class SimpleChoiceContainer extends GenericContentItemContainer<SimpleChoiceState, SimpleChoiceModel, NewSimpleChoiceRequest, BulkUpdateSimpleChoiceRequest, SimpleChoiceFormData> {

    // default state
    state = {
        contentItems: [],
        isLoading: false,
        errors: [],
        isSaving: false,
        isSavingStub: false,
        isPublishing: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }


    mapModelToGenericContentItemModel = (model: SimpleChoiceModel) => ({
        ...model,
        hasAudio: model.responses.some(a => a.audio != null),
        hasVideo: model.responses.some(a => a.video != null),
        hasImage: model.responses.some(a => a.largeImage != null),
        hasBackgroundImage: model.responses.some(a => a.backgroundImage != null),
        featureTypeId: SimpleChoiceFeatureTypeId,
        titleAttribute: model.choiceType ? `${model.choiceType}: ` : null

    })
    mapFormDataToModel = (originalModel: SimpleChoiceModel, formData: SimpleChoiceFormData) => ({
        ...originalModel,
        title: formData.title,
        choiceType: formData.choiceType,
        requiresParent: formData.requiresParent,
        webhookChainingType: formData.webhookChainingType,
        isLive: formData.isLive,
        languages: formData.languages,
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,
        disabledForFeatureFlagIds: formData.disabledForFeatureFlagIds,
    });


    featureTypeId = SimpleChoiceFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getSimpleChoicesForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => voicifyApi.getSimpleChoicesForModule(applicationModuleId);
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getSimpleChoicesForAppFeature(applicationFeatureId);
    createPromise = (model: NewSimpleChoiceRequest) => voicifyApi.createSimpleChoice(model);
    createStubPromise = (model: NewSimpleChoiceRequest) => voicifyApi.createSimpleChoiceStub(model);
    createFromModelPromise = (model: SimpleChoiceModel) => voicifyApi.createFullSimpleChoice(model);
    updatePromise = (contentItemId: string, model: BulkUpdateSimpleChoiceRequest) => voicifyApi.bulkUpdateSimpleChoice(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: BulkUpdateSimpleChoiceRequest) => voicifyApi.bulkUpdateSimpleChoiceStub(contentItemId, model);
    updateFromModelPromise = (model: SimpleChoiceModel) => voicifyApi.updateFullSimpleChoice(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteSimpleChoice(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleSimpleChoiceLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleSimpleChoiceSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copySimpleChoice(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findSimpleChoice(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getSimpleChoicePublishHistory(contentItemId, environmentId, skip, take);


    getWebhooksPromise = (contentItemId: string) => voicifyApi.getSimpleChoiceWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeSimpleChoiceWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateSimpleChoiceWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addSimpleChoiceWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateSimpleChoiceWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);
    getSimpleSampleFlows = (contentItem: SimpleChoiceModel, application?: ApplicationModel) => {
        const getUserUtterances = () => {
            switch (contentItem.choiceType) {
                case "Yes": return [
                    { id: "1", content: "Yes", highlights: [] },
                    { id: "2", content: "Yeah", highlights: [] },
                    { id: "3", content: "Sure", highlights: [] },
                    { id: "4", content: "Yes please", highlights: [] },
                    { id: "5", content: "Uh huh", highlights: [] },
                ];
                case "No": return [
                    { id: "1", content: "No", highlights: [] },
                    { id: "2", content: "Nope", highlights: [] },
                    { id: "3", content: "Nah", highlights: [] },
                    { id: "4", content: "No thanks", highlights: [] },
                    { id: "5", content: "Nuh uh", highlights: [] },
                ];
            }

            return []
        }
        return [{
            requests: getUserUtterances(),
            followUp: contentItem.responses[0]?.followUp,
            responses: contentItem.responses.map(a => ({
                content: a.content,
                highlights: [],
                id: a.content
            })),
            requestTitle: "User Says",
            responseTitle: "Device Response"
        }]
    }

}
