import React, { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import AnalyticsRequestFilter from "../models/analytics/AnalyticsRequestFilter";
import ContentHitEvent from "../models/analytics/api/ContentHitEvent";
import IResult from "../models/result/IResult";
import { WelcomeMessagesFeatureTypeId } from "../constants/featureTypeIds";

function useSessionAnalyticsContainerOld() {
    const [contentHits, setContentHits] = useState([] as ContentHitEvent[]);
    const [featureTypeContentHits, setFeatureTypeContentHits] = useState([] as ContentHitEvent[]);
    const [sessions, setSessions] = useState({} as { [sessionId: string]: ContentHitEvent[] })
    const [sessionsByFeatureType, setSessionsByFeatureType] = useState({} as { [sessionId: string]: ContentHitEvent[] })
    const [moreToLoad, setMoreToLoad] = useState(true);
    const [moreHitsByFeatureTypeToLoad, setMoreHitsByFeatureTypeToLoad] = useState(true);
    const [applicationId, setAppId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingSessionsByFeatureType, setLoadingSessionsByFeatureType] = useState(false);
    const [skip, setSkip] = useState(0);
    const [skipByType, setSkipByType] = useState(0);
    const [sessionIds, setSessionIds] = useState([] as string[]);
    const [sessionIdsByFeatureType, setSessionIdsByFeatureType] = useState([] as string[]);
    const [error, setError] = useState("");

    const setHits = (contentHitType: string) => {
        let filtered: ContentHitEvent[] = [];
        if (contentHitType === "allHits") {
            filtered = contentHits.filter(e => e.response);
        } else {
            filtered = featureTypeContentHits.filter(e => e.response);
        }
        // aggregate by session id
        const newSessions: {
            [sessionId: string]: ContentHitEvent[]
        } = {};
        for (const hit of filtered) {
            if (!newSessions[hit.sessionId])
                newSessions[hit.sessionId] = [];
            newSessions[hit.sessionId].push(hit);
        };
        // order sessions by eventDate
        const ordered = Object.keys(newSessions).sort((a, b) => {
            return new Date(newSessions[b][0].eventDate).valueOf() - new Date(newSessions[a][0].eventDate).valueOf();
        }).reduce((obj, key) => {
            obj[key] = newSessions[key];
            return obj;
        }, {})
        // order each session by eventDate
        for (const sessionId of Object.keys(ordered)) {
            ordered[sessionId] = ordered[sessionId].sort((a, b) => {
                return new Date(a.eventDate).valueOf() - new Date(b.eventDate).valueOf();
            });
        }
        if (contentHitType === "allHits") {
            setSessions(ordered);
        } else {
            setSessionsByFeatureType(ordered);
        }
    };

    useEffect(() => {
        setHits("allHits");
    }, [contentHits])

    useEffect(() => {
        setHits("hitsByFeatureType");
    }, [featureTypeContentHits])

    const isConflictingHit = (hit1: any, hit2: any): boolean => {
        // welcome messages had bug to have the same request ID as the first turn. lets fix that. 
        if (hit1.requestId === hit2.requestId) {
            return true;
            // check if event dates are within 3 seconds
            const date1 = new Date(hit1.eventDate);
            const date2 = new Date(hit2.eventDate);
            const diff = Math.abs(date1.valueOf() - date2.valueOf());
            if (diff > 3000)
                return false;
            if (hit1.featureTypeId === WelcomeMessagesFeatureTypeId && hit2.featureTypeId === WelcomeMessagesFeatureTypeId) {
                // if they have the same requestID and are both welcome messages then it is a conflict
                return true;
            } else if (hit1.featureTypeId === WelcomeMessagesFeatureTypeId || hit2.featureTypeId === WelcomeMessagesFeatureTypeId) {
                // if they have the same requestID and only one is welcome message then it is not a conflict
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const loadMoreContentByFeatureType = async (appId: string, filter: AnalyticsRequestFilter, featureTypeId = null): Promise<ContentHitEvent[]> => {
        try {
            let contentHitsToUse = featureTypeContentHits;
            let currentSkip: number = skipByType;
            if (applicationId !== appId || startDate !== filter.startDate || endDate !== filter.endDate) {
                setFeatureTypeContentHits([]);
                contentHitsToUse = [];
                setAppId(appId);
                setStartDate(filter.startDate);
                setEndDate(filter.endDate);
                setMoreHitsByFeatureTypeToLoad(true);
                setSessionIdsByFeatureType([])
                setSkipByType(0);
                currentSkip = 0;
            }
            setLoadingSessionsByFeatureType(true);
            const newEvents = [];
            const maxEvents = 5000;
            let go = true;
            while (go) {
                let events: IResult<ContentHitEvent[]> = await voicifyApi.getContentHits(appId, filter, currentSkip, 100, true, "", featureTypeId);
                currentSkip += 100;
                if (!events?.data?.length || events.data.length < 100) {
                    go = false;
                    setMoreHitsByFeatureTypeToLoad(false);
                }
                for (const event of events.data) {
                    let existingHit = newEvents.find(e => isConflictingHit(e, event));
                    if (existingHit) {
                        // use hit with last created date
                        if (new Date(existingHit.eventDate).valueOf() > new Date(event.eventDate).valueOf())
                            continue;
                        else {
                            newEvents.splice(newEvents.indexOf(existingHit), 1);
                            newEvents.push(event);
                            continue;
                        }
                    }
                    existingHit = contentHitsToUse.find(e => isConflictingHit(e, event));
                    if (existingHit) {
                        // use hit with last created date
                        if (new Date(existingHit.eventDate).valueOf() > new Date(event.eventDate).valueOf())
                            continue;
                        else {
                            contentHitsToUse.splice(newEvents.indexOf(existingHit), 1);
                            newEvents.push(event);
                            continue;
                        }
                    }
                    newEvents.push(event);
                }
                if (newEvents.length >= maxEvents)
                    go = false;
            }
            setSkipByType(currentSkip);
            setFeatureTypeContentHits([...contentHitsToUse, ...newEvents]);
            setLoadingSessionsByFeatureType(false);
            return [...contentHitsToUse, ...newEvents];
        } catch {
            setError("There was an error loading the content hits");
        }
    };

    const loadMoreContentHits = async (appId: string, filter: AnalyticsRequestFilter, sessionId = null): Promise<ContentHitEvent[]> => {
        try {
            let contentHitsToUse = contentHits;
            let currentSkip = skip;
            if (applicationId !== appId || startDate !== filter.startDate || endDate !== filter.endDate) {
                setContentHits([]);
                contentHitsToUse = [];
                setAppId(appId);
                setStartDate(filter.startDate);
                setEndDate(filter.endDate);
                setMoreToLoad(true);
                setSessionIds([])
                setSkip(0);
                currentSkip = 0;
            }
            setLoading(true);
            const newEvents = [];
            const maxEvents = 100;
            let go = true;
            while (go) {
                let events: IResult<ContentHitEvent[]> = await voicifyApi.getContentHits(appId, filter, currentSkip, 100, false);
                currentSkip += 50;
                if (!events?.data?.length || events.data.length < 100) {
                    go = false;
                    setMoreToLoad(false);
                }
                for (const event of events.data) {
                    let existingHit = newEvents.find(e => isConflictingHit(e, event));
                    if (existingHit) {
                        // use hit with last created date
                        if (new Date(existingHit.eventDate).valueOf() > new Date(event.eventDate).valueOf())
                            continue;
                        else {
                            newEvents.splice(newEvents.indexOf(existingHit), 1);
                            newEvents.push(event);
                            continue;
                        }
                    }
                    existingHit = contentHitsToUse.find(e => isConflictingHit(e, event));
                    if (existingHit) {
                        // use hit with last created date
                        if (new Date(existingHit.eventDate).valueOf() > new Date(event.eventDate).valueOf())
                            continue;
                        else {
                            contentHitsToUse.splice(newEvents.indexOf(existingHit), 1);
                            newEvents.push(event);
                            continue;
                        }
                    }
                    newEvents.push(event);
                }
                if (newEvents.length >= maxEvents)
                    go = false;
            }
            setSkip(currentSkip);
            if (sessionId?.length && !sessionIds.includes(sessionId)) {
                const sessionEvents = await voicifyApi.getContentHits(appId, filter, 0, 100, true, sessionId);
                for (const event of sessionEvents.data) {
                    var existingHit = newEvents.find(e => isConflictingHit(e, event));
                    if (existingHit) {
                        // use hit with last created date
                        if (new Date(existingHit.eventDate).valueOf() > new Date(event.eventDate).valueOf())
                            continue;
                        else {
                            newEvents.splice(newEvents.indexOf(existingHit), 1);
                            newEvents.push(event);
                            continue;
                        }
                    }
                    existingHit = contentHitsToUse.find(e => isConflictingHit(e, event));
                    if (existingHit) {
                        // use hit with last created date
                        if (new Date(existingHit.eventDate).valueOf() > new Date(event.eventDate).valueOf())
                            continue;
                        else {
                            contentHitsToUse.splice(newEvents.indexOf(existingHit), 1);
                            newEvents.push(event);
                            continue;
                        }
                    }
                    newEvents.push(event);
                }
                setSessionIds([...sessionIds, sessionId]);
            }
            setContentHits([...contentHitsToUse, ...newEvents]);
            setLoading(false);
            return [...contentHitsToUse, ...newEvents];
        } catch {
            setError("There was an error loading the content hits");
        }
    };

    const loadSession = async (appId: string, sessionId: string, filter: AnalyticsRequestFilter): Promise<IResult<ContentHitEvent[]>> => {
        try {
            if (sessionIds.includes(sessionId))
                return;
            const allEvents = [];
            const newStartDate = new Date(filter.startDate);
            newStartDate.setDate(newStartDate.getDate() - 2);
            const newEndDate = new Date(filter.endDate);
            newEndDate.setDate(newEndDate.getDate() + 2);
            const newFilter = {
                ...filter,
                startDate: newStartDate.toISOString(),
                endDate: newEndDate.toISOString()
            }
            const sessionResult = await voicifyApi.getContentHits(appId, newFilter, 0, 50, true, sessionId);
            const newContentHits = [...contentHits];
            for (const event of sessionResult.data) {
                var existingHit = newContentHits.find(e => isConflictingHit(e, event));
                if (existingHit) {
                    // use hit with last created date
                    if (new Date(existingHit.eventDate).valueOf() > new Date(event.eventDate).valueOf())
                        continue;
                    else {
                        newContentHits.splice(newContentHits.indexOf(existingHit), 1);
                    }
                }
                newContentHits.push(event);
            }
            setSessionIds([...sessionIds, sessionId]);
            setContentHits(newContentHits);
            return sessionResult;
        } catch {
            setError("There was an error loading session");
        }
    };

    return {
        loadMoreContentHits,
        sessions,
        loadMoreContentByFeatureType,
        sessionsByFeatureType,
        loadingSessionsByFeatureType,
        loading,
        loadSession,
        moreToLoad,
        moreHitsByFeatureTypeToLoad,
        featureTypeContentHits
    };
}

const SessionAnalyticsContainerOld = createContainer(useSessionAnalyticsContainerOld);
export default SessionAnalyticsContainerOld;
