import React from 'react';
import { css } from '@emotion/css';
import Select, { ArrowRendererProps, Option } from 'react-select';
import ToggleIndicator from '../general/ToggleIndicator';
import { breakpoint_small } from '../../constants/breakpoints';

interface TextFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e) => void
    onBlur?: (e: any) => void
    label?: string
    id?: string
    options: Option[]
    useFormTheme?: boolean
    icon?: string
    clearable?: boolean
    clearValueText?: string
    className?: string
}

class AnalyticsSelectField extends React.Component<TextFieldProps, {}> {
    handleChange = value => {
        this.props.onChange(value);
    };

    handleBlur = () => {
        if (this.props.onBlur) this.props.onBlur(true);
    };

    render() {
        const props = {
            ...this.props
        };

        return (
            <div className={`${containerStyle} ${props.className} selector`}>
                {props.label !== undefined ? <label htmlFor={props.name} className={labelStyle}>{props.label}</label> : null}
                <div className={fieldContainer}>
                    <Select id={props.id}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={this.handleChange.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        disabled={props.disabled}
                        options={props.options}
                        clearValueText={props.clearValueText}
                        clearable={false}
                        className={formSelectStyle}
                        arrowRenderer={(p: ArrowRendererProps) => <ToggleIndicator direction={p.isOpen ? 'up' : 'down'} />} />
                </div>
            </div>
        );

    }
};

const containerStyle = css`
    display: flex;
    align-items: center;
`;

const labelStyle = css`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-right: 16px;
`;

const formSelectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    .Select {
        height: 32px;
    }
    .Select-control {
        border-radius: 4px;
        height: 32px;

        .Select-value {
            line-height: 32px;
        }
    }
    .Select-placeholder, .Select-value{
        font-family: Muli;
        font-size: 14px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .Select-option, Select-noresults {
        height: 32px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .Select.is-focused:not(.is-open) > .Select-control {
        border-color: transparent;
    }
`;

const fieldContainer = css`
    display: flex;
    align-items: center;
    height: 32px;
    width: 280px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(50, 70, 97, 0.1);
    background: white;
    img {
        margin: 16px;
    }
    ${breakpoint_small} {
        width: 80%;
    }
`;

export default AnalyticsSelectField;