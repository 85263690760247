import IResult from '../../models/result/IResult';
import { sendPut } from './generic';
import ApplicationEnvironmentModel from '../../models/applications/api/environments/ApplicationEnvironmentModel';
import UpdateApplicationEnvironmentRequest from '../../models/applications/api/environments/UpdateApplicationEnvironmentRequest';
import { UPDATE_ENVIRONMENT } from '../../constants/Urls';

export const updateEnvironmentSettings = (environmentId:string, model: UpdateApplicationEnvironmentRequest): Promise<IResult<ApplicationEnvironmentModel>> => {
    return sendPut<ApplicationEnvironmentModel>(`${UPDATE_ENVIRONMENT}/${environmentId}`, model);
}

