import { getAnalyticsApiUrl } from "../../constants/Urls";
import AnalyticsRequestFilter from "../../models/analytics/AnalyticsRequestFilter";
import { ConversationTrackingTreeBaseNode } from "../../models/analytics/api/ConversationEventTrackingTreeNode";
import { sendGet } from "./generic";

export const getApplicationConversationTracking = (applicationId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<ConversationTrackingTreeBaseNode>(`/ConversationTracking/application/${applicationId}?${buildQuery(filter)}`, getAnalyticsApiUrl());
}
export const getContentConversationTracking = (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<ConversationTrackingTreeBaseNode[]>(`/ConversationTracking/content/${applicationId}/${contentId}?${buildQuery(filter)}`, getAnalyticsApiUrl());
}

const buildQuery = (filter: AnalyticsRequestFilter) => {
    var startDate = new Date(filter.startDate).toISOString();
    var endDate = new Date(filter.endDate).toISOString();

    let query = `startDate=${startDate}&endDate=${endDate}`;
    if (filter.assistants?.length > 0) {
        query += ("&assistants=" + filter.assistants.join("&assistants="))
    }
    if (filter.userIds?.length > 0) {
        query += ("&userIds=" + filter.userIds.join("&userIds="))
    }
    if (filter.sessionIds?.length > 0) {
        query += ("&sessionIds=" + filter.sessionIds.join("&sessionIds="))
    }
    if (filter.deviceTargetIds?.length > 0) {
        query += ("&deviceTargetIds=" + filter.deviceTargetIds.join("&deviceTargetIds="))
    }
    if (filter.languageIds?.length > 0) {
        query += ("&languageIds=" + filter.languageIds.join("&languageIds="))
    }
    return query;
}