import React from 'react';
import { css } from '@emotion/css';
const logo = require('../../content/images/voicify-logo-grey.svg');
const logoGreen = require('../../content/images/voicify-logo-green.svg');

interface LoaderProps {
    fullscreen?: boolean,
    className?: string,
    static?: boolean
    greenStatic?: boolean
}

const Loader: React.FC<LoaderProps> = (props) => {
    if (props.static) {
        return (<img className={`${staticLoader} ${props.className ? props.className : ''}`} src={logo} alt="loader" />);
    }
    if (props.greenStatic) {
        return (<img className={`${staticLoader} ${props.className ? props.className : ''}`} src={logoGreen} alt="loader" />);
    }
    return (<svg className={`${loaderStyle} ${props.className ? props.className : ''}`} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="" d="M19.255 43.1862C11.3467 43.1862 4.8825 36.722 4.8825 28.8138C4.8825 22.5559 8.9398 17.192 14.51 15.2665V10.2465C6.18909 12.3783 0 19.8739 0 28.8138C0 39.404 8.59596 48 19.1862 48C28.126 48 35.6905 41.8109 37.7535 33.49H32.7334C30.8079 39.1289 25.5128 43.1862 19.255 43.1862Z" fill="url(#paint0_linear)" />
        <path className="path outer" d="M19.2545 0V4.81374C32.4579 4.81374 43.1857 15.5415 43.1857 28.7449H47.9995C47.9995 12.9283 35.1399 0 19.2545 0Z" fill="url(#paint1_linear)" />
        <path className="path middle" d="M19.2545 9.55872V14.3725C27.1628 14.3725 33.627 20.8366 33.627 28.7449H38.4407C38.4407 18.2234 29.8448 9.55872 19.2545 9.55872Z" fill="url(#paint2_linear)" />
        <path className="path inner" d="M23.9307 28.814H28.7444C28.7444 23.5189 24.4808 19.2552 19.1857 19.2552V24.069C21.8676 24.069 23.9307 26.2008 23.9307 28.814Z" fill="url(#paint3_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="38.9564" y1="9.077" x2="9.23599" y2="38.7974" gradientUnits="userSpaceOnUse">
                <stop stopColor="#12C3F4" />
                <stop offset="1" stopColor="#4EBE99" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="38.9558" y1="9.07672" x2="9.23535" y2="38.7972" gradientUnits="userSpaceOnUse">
                <stop stopColor="#12C3F4" />
                <stop offset="1" stopColor="#4EBE99" />
            </linearGradient>
            <linearGradient id="paint2_linear" x1="38.9558" y1="9.07672" x2="9.23536" y2="38.7972" gradientUnits="userSpaceOnUse">
                <stop stopColor="#12C3F4" />
                <stop offset="1" stopColor="#4EBE99" />
            </linearGradient>
            <linearGradient id="paint3_linear" x1="38.956" y1="9.07721" x2="9.23556" y2="38.7977" gradientUnits="userSpaceOnUse">
                <stop stopColor="#12C3F4" />
                <stop offset="1" stopColor="#4EBE99" />
            </linearGradient>
        </defs>
    </svg>)
};

const loaderStyle = css`
    height: 80px;
    width: 80px;
    position: absolute;
    top: 50%;
    left: 50%;  
    margin: -40px 0 0 -40px;
    .path {        
        animation: blink .65s ease alternate infinite;
    }
    .path.inner {
      animation-delay: 0.1s;
    }
    .path.middle {
      animation-delay: 0.2s;
    }
    .path.outer {
      animation-delay: 0.3s;
    }  
    @keyframes blink {
        0% {
        opacity: 0;
        }
        40% {
            opacity: 1;
        }
    }
`;

const staticLoader = css`
    height: 80px;
    width: 80px;
    position: absolute;
    top: 50%;
    left: 50%;  
    margin: -40px 0 0 -40px;
`;

export default Loader;