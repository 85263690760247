import React from 'react';
import { css } from '@emotion/css';
import moment from 'moment';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import { ocean_blue } from '../../../constants/colors';
import HistogramChartCard from '../../../components/charts/HistogramChartCard';
import AnalyticsSelectField from '../../../components/analytics/AnalyticsSelectField';
import BreakdownChartCard from '../../../components/charts/BreakdownChartCard';
import AveragesCard from '../../../components/charts/AveragesCard';
import GenericMeasurementsContainer, { MeasurementState } from '../../../state/containers/GenericMeasurementsContainer';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import { DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';

interface ApplicationAnalyticsChartsProps {
    applicationId: string
    filter: AnalyticsRequestFilter
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    dataType: string
    primaryDataName: string
    secondaryDataName?: string
    showSecondary?: boolean
    showPrimary?: boolean
    stateContainer: GenericMeasurementsContainer<MeasurementState>
}


class ApplicationAnalyticsCharts extends React.Component<ApplicationAnalyticsChartsProps> {
    today = moment()
    componentDidMount() {
        this.props.stateContainer.loadFeatureTypes();
        this.props.stateContainer.loadDeviceTypes();
    }
    async handleBreakdownChange(option: string) {
        await this.props.stateContainer.handleBreakdownChange(option);
        await this.props.stateContainer.loadPrimaryBreakdown({ applicationId: this.props.applicationId }, this.props.filter, option);
        await this.props.stateContainer.loadSecondaryBreakdown({ applicationId: this.props.applicationId }, this.props.filter, option);
    }
    async handleDeltaPeriodChange(option: string) {
        await this.props.stateContainer.handleDeltaPeriodChange(option);
        await this.props.stateContainer.loadPrimaryHistogram({ applicationId: this.props.applicationId }, this.props.innerInterval, this.props.filter);
        await this.props.stateContainer.loadSecondaryHistogram({ applicationId: this.props.applicationId }, this.props.innerInterval, this.props.filter);
        await this.props.stateContainer.getAverages({ applicationId: this.props.applicationId }, this.props.filter);
    }

    getInnerDateString(momentDate: moment.Moment): string {
        switch (this.props.innerInterval) {
            case 'year': return momentDate.format("YYYY");
            case 'month': return momentDate.format("MMMM YYYY");
            case 'day': return momentDate.format("MMMM DD, YYYY");
            case 'dayAndHour': return momentDate.format("DD hh:mm a") //more then a day but less than 2 days, need to supply Day and Hour
            case 'hour': return momentDate.format("hh:mm a");
        }
    }

    render() {
        const prevDates = this.props.stateContainer.getPreviousDates(this.props.filter.startDate, this.props.filter.endDate);
        const histogramData = this.props.stateContainer.state.primaryHistogramData?.find(
            h => this.props.stateContainer.buildHistogramKey(h) == this.props.stateContainer.buildCacheKey(this.props.applicationId, this.props.filter))?.data?.map(d => {
                const momentDate = moment(d.date);
                return {
                    color: ocean_blue,
                    x: this.props.stateContainer.getHistogramDateLabel(this.props.innerInterval, momentDate),
                    y: this.today.diff(momentDate) > 0 ? d.count : null, // null will add gaps - so we can null the values for future dates
                    fullDate: this.getInnerDateString(momentDate)
                }
            });

        const secondaryHistogramData = this.props.stateContainer.state.secondaryHistogramData?.find(
            h => this.props.stateContainer.buildHistogramKey(h) == this.props.stateContainer.buildCacheKey(this.props.applicationId, this.props.filter))?.data?.map(d => {
                const momentDate = moment(d.date);
                return {
                    color: ocean_blue,
                    x: this.props.stateContainer.getHistogramDateLabel(this.props.innerInterval, momentDate),
                    y: this.today.diff(momentDate) > 0 ? d.count : null, // null will add gaps - so we can null the values for future dates
                    fullDate: this.getInnerDateString(momentDate)
                }
            });
        const breakdownData = this.props.stateContainer.state.primaryBreakdownData?.find(
            h => this.props.stateContainer.buildBreakdownKey(h) == this.props.stateContainer.buildCacheKey(this.props.applicationId, this.props.filter, this.props.stateContainer.state.currentBreakdown))?.items ?? []
        const secondaryBreakdownData = this.props.stateContainer.state.secondaryBreakdownData?.find(h => this.props.stateContainer.buildBreakdownKey(h) == this.props.stateContainer.buildCacheKey(this.props.applicationId, this.props.filter, this.props.stateContainer.state.currentBreakdown))?.items ?? []
        const averagesData = this.props.stateContainer.state.averages?.find(h => this.props.stateContainer.buildAverageKey(h) == this.props.stateContainer.buildCacheKey(this.props.applicationId, this.props.filter))?.items ?? []
        return (
            <div className={wrapperStyle}>
                <div className="options-row-container">
                    <AnalyticsSelectField label="Display:" className="ac-analytics-display-field" options={this.props.stateContainer.displayOptions.map(s => ({ label: s, value: s }))} value={this.props.stateContainer.state.currentDisplayOption} onChange={(e) => this.props.stateContainer.handleDisplayOptionChange(e.value)} />
                    <div className="end-container">
                        <AnalyticsSelectField className="ac-analytics-compare-field" label="Compare to:" options={['Previous Period', 'Same Time Last Year'].map(s => ({ label: s, value: s }))} value={this.props.stateContainer.state.currentDeltaPeriod} onChange={(e) => this.handleDeltaPeriodChange(e.value)} />
                        <AnalyticsSelectField label="Change in:" options={['Value', 'Percentage', 'Value & Percentage'].map(s => ({ label: s, value: s }))} value={this.props.stateContainer.state.currentDeltaType} onChange={(e) => this.props.stateContainer.handleDeltaTypeChange(e.value)} />
                    </div>
                </div>
                <div className="card-container">
                    <div className={histogramContainer}>
                        <HistogramChartCard
                            isLoading={this.props.stateContainer.state.isLoadingHistogram || !histogramData}
                            data={histogramData}
                            showSecondary={this.props.showSecondary}
                            showPrimary={this.props.showPrimary}
                            secondaryData={secondaryHistogramData}
                            secondaryDataName={this.props.secondaryDataName}
                            primaryDataName={this.props.primaryDataName}
                            title={this.props.dataType} />
                    </div>
                    <div className={breakdownContainer}>
                        <BreakdownChartCard
                            options={this.props.stateContainer.breakdownOptions}
                            selectedOption={this.props.stateContainer.state.currentBreakdown}
                            onOptionChange={this.handleBreakdownChange.bind(this)}
                            secondaryDataLabel={this.props.secondaryDataName}
                            primaryDataLabel={this.props.primaryDataName}
                            items={breakdownData}
                            secondaryItems={secondaryBreakdownData}
                            showSecondary={this.props.showSecondary}
                            showPrimary={this.props.showPrimary}
                            isLoading={this.props.stateContainer.state.isLoadingBreakdown}
                        />
                    </div>
                </div>
                <div className={averagesContainer}>
                    <AveragesCard
                        isLoading={this.props.stateContainer.state.isLoadingHistogram || !histogramData}
                        items={averagesData ?? []}
                        comparedDateRangeLabel={`vs ${moment(prevDates.startDate, DATE_FORMAT).format("MMM DD, YYYY")} - ${moment(prevDates.endDate, DATE_FORMAT).format("MMM DD, YYYY")}`}
                        showPercentage={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                        showValue={this.props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1} />
                </div>
            </div>
        )
    }
}
const wrapperStyle = css`
   .card-container {
        display: flex;
   }
   .options-row-container {
        display: flex;
        align-items: center;
        margin: 32px;
   }
   .end-container {
        display: flex;
        align-items: center;
       margin-right: 0;
       margin-left: auto;

       >div {
           margin-left: 24px;
       }
   }
`

const histogramContainer = css`
    flex: 1;
    min-width: 300px;
    margin: 0 32px;
`

const breakdownContainer = css`
    min-width: 300px;
    margin-right: 32px;
`

const averagesContainer = css`
   margin: 32px;
`

export default ApplicationAnalyticsCharts;