import React, { ReactNode } from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';
import CustomScrollbars from '../structure/CustomScrollbars';
import styled from '@emotion/styled';

const redAlertTriangle = require('../../content/images/alert-triangle-red.svg');
const yellowAlertTriangle = require('../../content/images/alert-triangle-yellow.svg');

interface WarningConfirmationProps {
    text?: string
    showImage?: boolean
    removalTarget?: string
    useWarningColor?: boolean
    className?: string
    additionalText?: ReactNode
}

const WarningConfirmation: React.FC<WarningConfirmationProps> = (props) => {

    return (
        props.additionalText ?
            <CustomScrollbars
                autoHide
                autoHeight
                autoHeightMax="calc(100vh - 550px)"
            >
                <WarningConfirmationWrapper>
                    <SubtitleWrapper>
                        <Subtitle>Are you sure?</Subtitle>
                        {(props.showImage === undefined || props.showImage === true) ? <StyledImage src={props.useWarningColor ? yellowAlertTriangle : redAlertTriangle} /> : null}
                        <Text>{props.text}</Text>
                    </SubtitleWrapper>
                    <AdditionalTextWrapper>{props.additionalText}</AdditionalTextWrapper>
                </WarningConfirmationWrapper>
            </CustomScrollbars>
            :
            <div className={`${containerStyle} ${props.className && props.className}`}>
                <p>Are you sure?</p>
                {(props.showImage === undefined || props.showImage === true) ? <img src={props.useWarningColor ? yellowAlertTriangle : redAlertTriangle} /> : null}
                <p>{props.text}</p>
            </div>
    )
};

const AdditionalTextWrapper = styled.div`
    margin: 16px 0;
`;

const StyledImage = styled.img`
    width: 50px;
    margin-top: 16px;
`;

const Text = styled.p`
    margin-top: 16px;
    text-align: center;
`;

const SubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    justify-content: center;
    align-items: center;
    margin: 32px;
`;

const Subtitle = styled.h2``;

const WarningConfirmationWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 64px;
    img {
        width: 82px;
        height: 82px;
        margin: 32px 0;
    }
    p {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${dark_grey_blue};
    }
    ${breakpoint_small} {
        margin: 32px;
        p {
            font-size: 14px;
        }
    }
`;

export default WarningConfirmation;