import React from 'react'
import styled from '@emotion/styled'
import { color_colors_ocean, color_variants_ocean_light_2 } from '../../../constants/colors'
import MenuContainer from '../../../hooks/MenuContainer'
import { style_border_default } from '../../../constants/stylesValues'
import CollapsableItem from './CollapsableItem'
import ContentCard, { NoMarginContentCard } from '../../../components/structure/ContentCard'
import { MenuItemSize } from '../../../models/nlp/menu/MenuItemSize'
import ContentCollapsingSection from '../../../components/structure/ContentCollapsingSection'
import TextField from '../../../components/forms/TextField'
import { css } from '@emotion/css'
import PersistentPropertyTitle from './PersistentPropertyTitle'

const listImage = require("../../../content/images/menu-editor/list.svg");
const advancedSettingsIcon = require('../../../content/images/menu-editor/advanced-settings.svg');

export default function MenuItemSizeGroupEditor() {
    const menuContainer = MenuContainer.useContainer();
    const lastItem = menuContainer.path[menuContainer.path.length - 1];

    const activeSizeOptions = lastItem.selectedSizeGroup?.sizes?.filter(size => !size.isDisabled) ?? [];
    const inactiveSizeOptions = lastItem.selectedSizeGroup?.sizes?.filter(size => size.isDisabled) ?? [];
    const advancedSettings = [
        {
            label: <PersistentPropertyTitle
                title="Prompt"
                item={lastItem.selectedSizeGroup}
                propertyName='initialPromptOverride'
            />,
            tooltip: "This prompt makes reference to the item by name and lists the options available to the customer.",
            placeholder: "Enter a question",
            value: lastItem?.selectedSizeGroup?.initialPromptOverride,
            onChange: (e) => {
                lastItem.selectedSizeGroup.initialPromptOverride = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSizeGroup, "initialPromptOverride");
            }
        },
        {
            label: <PersistentPropertyTitle
                title="Multi-Item Order Prompt"
                item={lastItem.selectedSizeGroup}
                propertyName='numberedInitialPromptOverride'
            />,
            tooltip: "This prompt asks the customer for selections when ordering multiple items of the same type, one at a time.",
            placeholder: "Enter a question",
            value: lastItem?.selectedSizeGroup?.numberedInitialPromptOverride,
            onChange: (e) => {
                lastItem.selectedSizeGroup.numberedInitialPromptOverride = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSizeGroup, "numberedInitialPromptOverride");
            }
        },
        {
            label: <PersistentPropertyTitle
                title="Follow-Up Prompt"
                item={lastItem.selectedSizeGroup}
                propertyName='promptOverride'
            />,
            tooltip: "This prompt is used if the customer doesn't respond to the initial prompt.",
            placeholder: "Enter a question",
            value: lastItem?.selectedSizeGroup?.promptOverride,
            onChange: (e) => {
                lastItem.selectedSizeGroup.promptOverride = e.target.value;
                menuContainer.updateMenuItem(menuContainer.menuItem, lastItem.selectedSizeGroup, "promptOverride");
            }
        }
    ]

    function renderSizeOption(sizeOptions: MenuItemSize[]) {
        return sizeOptions.map((size, index) => {
            return (
                <OptionGroupWrapper
                    key={`size.${index}`}
                    onClick={() => {
                        menuContainer.path?.push({
                            text: size.name,
                            pathLength: menuContainer.path?.length ?? 0,
                            selectedSize: size,
                            selectedOption: null,
                            selectedOptionGroup: null,
                            selectedSizeGroup: null
                        });
                        menuContainer.setPath([...menuContainer.path])
                    }}
                >
                    <ListItemLeftWrapper>
                        <CollapsableItem
                            text={size.name}
                            isDisabled={size.isDisabled}
                            onEnabledChange={(enabled) => {
                                size.isDisabled = !enabled;
                                menuContainer.updateMenuItem(menuContainer.menuItem, size, "isDisabled");
                            }}
                        />
                    </ListItemLeftWrapper>
                </OptionGroupWrapper>
            )
        })
    }
    return (
        <>
            {lastItem.selectedSizeGroup ?
                <>
                    <ContentCollapsingSection
                        title={"Size Options"}
                        isCollapsedByDefault={false}
                        icon={listImage}
                        className={firstSectionStyle}
                    >
                        {activeSizeOptions.length > 0 &&
                            <ActiveSizeOptionsContentCard
                                title="Edit Active Size Options"
                                subtitle="These size options are currently active and available to customers."
                            >
                                {renderSizeOption(activeSizeOptions)}
                            </ActiveSizeOptionsContentCard>
                        }
                        {inactiveSizeOptions.length > 0 &&
                            <InactiveSizeOptionsContentCard
                                title="Inactive Size Options"
                                subtitle="These size options are currently inactive and unavailable to customers."
                            >
                                {renderSizeOption(inactiveSizeOptions)}
                            </InactiveSizeOptionsContentCard>
                        }
                    </ContentCollapsingSection>
                </>
                :
                <></>
            }
            <ContentCollapsingSection
                title={"Advanced Settings (Admin Only)"}
                icon={advancedSettingsIcon}
                isCollapsedByDefault={true}
                bottom
                scrollDownOnClick={true}
            >
                <NoMarginContentCard
                    title='Edit Assistant Prompts'
                    subtitle="If any required menu choices haven't been made, the assistant will ask the customer one of these questions to complete them. Leave variables like {menuItem} and {itemPosition} alone as they will be replaced with contextually appropriate content."
                    id="sizeGroupAdvancedSettings"
                >
                    {advancedSettings?.map((setting, index) => {
                        return (
                            <PropertyWrapper key={`setting.${index}`}>
                                <TextField
                                    useForm={false}
                                    disabled={false}
                                    value={setting.value}
                                    label={setting.label}
                                    placeholder={setting.placeholder}
                                    tooltip={setting.tooltip}
                                    tooltipStyle={tooltipStyle}
                                    onChange={setting.onChange}
                                    type={typeof setting.value}
                                />
                            </PropertyWrapper>
                        )
                    })}

                </NoMarginContentCard>
            </ContentCollapsingSection>
        </>

    )
};

const firstSectionStyle = css`
    border-top: none;
`;

const InactiveSizeOptionsContentCard = styled(ContentCard)`
    margin-top: 32px;
    margin-bottom: 0;
`;

const ActiveSizeOptionsContentCard = styled(ContentCard)`
    margin-bottom: 0;
`;

const tooltipStyle = css`
    margin-left: 8px;
`;

const PropertyWrapper = styled.div`
    display: flex;
`;

const ListItemLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const OptionGroupWrapper = styled.div`
    border: ${style_border_default};
    border-radius: 8px;
    margin: 8px 0 8px 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
    }
`;