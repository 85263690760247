import React from 'react';
import { css } from '@emotion/css';
import AuthContainer from '../../../state/containers/AuthContainer';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import UserContainer from '../../../state/containers/UserContainer';
import PageError from '../../../components/general/PageError';
import TermsCondition from './TermsCondition';


interface CreatePasswordFormData {
    password?: string;
    confirmPassword?: string;
    termsAgreed?: boolean;
}

interface CreatePasswordFormProps {
    stateContainer: UserContainer;
    authContainer: AuthContainer;
    invitationCode?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
}

const InnerForm: React.FC<CreatePasswordFormProps & FormikProps<CreatePasswordFormData>> = ({ handleSubmit, isSubmitting, values, handleChange, handleBlur, stateContainer, authContainer }) => {
    const isFirstTime = window.location.pathname.includes("firstTime")
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <TextField name="password" type="password" value={values.password} label="Password" placeholder="It's a secret to everybody" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} />
                <TextField name="confirmPassword" type="password" value={values.confirmPassword} label="Confirm Password" placeholder="One more time" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} />
                {isFirstTime && <TermsCondition handleChange={handleChange} isSubmitting={isSubmitting} handleBlur={handleBlur} termsAgreed={values.termsAgreed} />}
                <Button themes={["primary", "wide"]} type="submit" disabled={isSubmitting} text="Let's Go!" additionalClasses={buttonSplitStyle} />
            </form>
            <PageError errors={[...stateContainer.state.errors, ...authContainer.state.errors]} />
        </div>)
}
    ;

const CreatePasswordForm = withFormik<CreatePasswordFormProps, CreatePasswordFormData>({
    mapPropsToValues: props => ({
        password: '',
        confirmPassword: '',
        termsAgreed: false,
    }),
    validationSchema: yup.object().shape({
        password: yup.string().required("You must enter your password"),
        confirmPassword: yup.string().required("You must confirm your password"),
        termsAgreed: window.location.pathname.includes("firstTime") ? yup.bool().oneOf([true], "You must agree to the Terms & Conditions") : yup.bool().notRequired(),
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        const isFirstTime = window.location.pathname.includes("firstTime")
        if (props.invitationCode === undefined || props.invitationCode === null || props.invitationCode === '') {
            props.authContainer.addError("Invalid invitation code");
            setSubmitting(false);
            return;
        }
        const userState = props.stateContainer.state.userRegistrationInformation;
        const result = await props.stateContainer.signUp({
            firstName: isFirstTime ? props.firstName : userState.firstName,
            lastName: isFirstTime ? props.lastName : userState.lastName,
            email: isFirstTime ? props.email : userState.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
            agreedToTerms: isFirstTime ? values.termsAgreed : userState.termsAgreed
        })
        setSubmitting(false);
        if (result.resultType == "Ok") {
            const authResult = await props.authContainer.signIn(isFirstTime ? props.email : userState.email, values.password)
            if (authResult.resultType == "Ok") {
                props.stateContainer.acceptInvitation(props.invitationCode);
            }
        }
    }
})(InnerForm);

const buttonSplitStyle = css`
    margin-bottom: -56px;
`;

export default CreatePasswordForm;