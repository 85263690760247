import React from "react";
import Axios from "axios";
import Loader from "../../components/general/Loader";
import { Subscribe } from "unstated";
import AuthContainer from "../../state/containers/AuthContainer";
import { Redirect, RouteComponentProps } from "react-router";
import Acceptor from "../acceptInvitation/components/Acceptor";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import UserContainer from "../../state/containers/UserContainer";

interface RouteProps {
    invitationCode?: string
}

interface SingleSignOnRedirectorProps extends RouteComponentProps<RouteProps> { }

interface SingleSignOnRedirectorChildProps {
    invitationCode?: string,
    authContainer: AuthContainer,
    orgContainer: OrganizationContainer,
    userContainer: UserContainer,
    history: any
}

class SingleSignOnSignUpRedirector extends React.Component<SingleSignOnRedirectorProps> {
    render() {
        return (
            <Subscribe to={[AuthContainer, UserContainer, OrganizationContainer]}>
                {(authContainer: AuthContainer, userContainer: UserContainer, orgContainer: OrganizationContainer) =>
                    <SingleSignOnSignUpRedirectorChild
                        authContainer={authContainer}
                        invitationCode={this.props.match.params.invitationCode}
                        orgContainer={orgContainer}
                        userContainer={userContainer}
                        history={this.props.history}
                    />}
            </Subscribe>
        );
    }
};

class SingleSignOnSignUpRedirectorChild extends React.Component<SingleSignOnRedirectorChildProps> {
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get("email");
        const token = urlParams.get("token");
        if (this.props.invitationCode != null) {
            this.props.authContainer.signInWithSingleSignOn(email, token);
        }
    }
    render() {
        if (this.props.authContainer.state.isAuthenticated === true && this.props.invitationCode != null) {
            return <Acceptor orgContainer={this.props.orgContainer} container={this.props.userContainer} invitationCode={this.props.invitationCode} history={this.props.history} />
        }
        return (<Loader />)
    }
}

export default SingleSignOnSignUpRedirector;