import IResult from "../../models/result/IResult";
import User from "../../models/user/User";
import { sendPost, sendGet, sendDelete } from "./generic";
import UserApplicationPreferences from "../../models/user/UserApplicationPreferences";
import PinnedMessageRequest from "../../models/user/NewSimulatorPinnedMessageRequest";
import SimulatorPinnedMessage from "../../models/user/SimulatorPinnedMessage";

export const getUserApplicationPreferences = (applicationId: string): Promise<IResult<UserApplicationPreferences>> => {
    return sendGet<UserApplicationPreferences>(`/UserApplicationPreferences/${applicationId}`);
}
export const deleteSimulatorPinnedMessage = (applicationId: string, pinnedMessageId: string): Promise<IResult<SimulatorPinnedMessage>> => {
    return sendDelete<SimulatorPinnedMessage>(`/UserApplicationPreferences/application/${applicationId}/simulatorPinnedMessage/${pinnedMessageId}`);
}
export const addSimulatorPinnedMessage = (request: PinnedMessageRequest): Promise<IResult<SimulatorPinnedMessage>> => {
    return sendPost<SimulatorPinnedMessage>(`/UserApplicationPreferences/SimulatorPinnedMessage`, request);
}