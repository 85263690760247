import React, { useState } from 'react';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationOrderingStatisticsForm from './ApplicationOrderingStatisticsForm';
import { Dictionary } from 'lodash';
import ContentHitEvent from '../../../models/analytics/api/ContentHitEvent';
import { OrderingStatisticsPreferences } from './ApplicationStatistics';
import { ApplicationOrderingStatisticsFormData } from './ApplicationOrderingStatisticsFormData';
import IGenericContentContainer from '../../../state/definitions/IGenericContentContainer';
import ContentItemModel from '../../../models/features/api/ContentItemModel';
import SessionAnalyticsContainerOld from '../../../hooks/SessionAnalyticsContainerOld';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';

interface ApplicationOrderingWrapperProps {
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string
    ssmlContainer: SsmlContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    history: any
    envContainer: ApplicationEnvironmentsContainer
    applicationContainer: ApplicationContainer
}

const ApplicationOrderingExport = (props: ApplicationOrderingWrapperProps) => {
    const [isLoadingApplicationOrderingStatistics, setIsLoadingApplicationOrderingStatistics] = useState(false);
    const sessionContainer = SessionAnalyticsContainerOld.useContainer();

    const ORDER_INTENT_NAMES = [
        "NoSlotOrderIntent",
        "Order-Confirm",
        "Order-Enhanced",
        "Order-MenuItem",
        "SameMenuItem",
        "SplitMenuItem"
    ];
    const CORRECTION_INTENT_NAMES = [
        "OrderCorrectionSwap",
        "OrderCorrectionAdd",
        "OrderCorrectionAddAndRemove",
        "OrderCorrectionRemove"
    ];
    const ORDERING_INTENT_NAMES = ORDER_INTENT_NAMES.concat(CORRECTION_INTENT_NAMES);
    const TRANSFER_INTENT_NAMES = ["Transfer"];

    const convertCallSummaryDataToCSVText = (rows: string[][]): string => {
        if (!rows || rows?.length === 0) return "";
        const newLine = '\r\n';
        const columnSeparator = ',';
        const csvText = rows.map(row => row.join(columnSeparator)).join(newLine);
        return csvText;
    };

    const handleDownload = (fileName: string, contentDataInCSV: string) => {
        const blob = new Blob([contentDataInCSV], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
    };

    const exportCallSummary = (callSummaryData) => {
        const appName = props.applicationContainer.state.currentApp.name
        let fileName: string = `${appName}_call_summary`;
        if (callSummaryData == null || callSummaryData?.length === 0) return;
        const valuesDataInCSV = convertCallSummaryDataToCSVText(callSummaryData);
        handleDownload(fileName, valuesDataInCSV);
    };

    const convertUtcToEst = (utcTimeString) => {
        const utcTime = new Date(utcTimeString);
        const utcYear = utcTime.getUTCFullYear();
        const utcMonth = utcTime.getUTCMonth();
        const utcDay = utcTime.getUTCDate();
        const utcHours = utcTime.getUTCHours();
        const utcMinutes = utcTime.getUTCMinutes();
        const utcSeconds = utcTime.getUTCSeconds();
        const estTime = new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHours, utcMinutes, utcSeconds));
        estTime.setHours(estTime.getHours() - 5);
        return estTime;
    };

    const areDatesWithin2Hours = (utcDate1String: string, utcDate2String: string) => {
        const utcDate1 = new Date(utcDate1String);
        const utcDate2 = new Date(utcDate2String);
        if (isNaN(utcDate1.getTime()) || isNaN(utcDate2.getTime())) {
            throw new Error("Invalid date format");
        }
        const timeDifference = Math.abs(utcDate1.getTime() - utcDate2.getTime());
        const within2Hours = timeDifference <= (2 * 60 * 60 * 1000);
        return within2Hours;
    };

    const populateOrderingStatistics = (sessions) => {
        if (!sessions) {
            return;
        }

        const sortedSessions = [];

        Object.keys(sessions).forEach((sessionId: string) => {
            const session = { events: [...sessions[sessionId]], sessionId: sessionId, firstEventDate: null };
            const firstEvent = session.events[0];
            const firstEventDate = firstEvent.eventDate;
            const firstEventDateObject = new Date(firstEventDate);
            session.firstEventDate = firstEventDateObject;
            sortedSessions.push(session);
        });

        sortedSessions.sort((a, b) => {
            const aDate = a.firstEventDate;
            const bDate = b.firstEventDate;
            return aDate - bDate;
        });

        const sessionsObject = {};
        sortedSessions.forEach((session) => {
            sessionsObject[session.sessionId] = session.events;
        });

        const callSummaryData = [
            ["Date (UTC)", "Time (UTC)", "Date (EST)", "Time (EST)", "Caller Phone Number", "Attempt #", "Session Link", "Session ID", "Caller Action", "Order Attempted", "Transferred", "Order Placed", "Number of Turns", "Last Turn Item Count"]
        ];

        Object.keys(sessionsObject).forEach((sessionId: string) => {
            // each session is a line in the csv
            const session = [...sessionsObject[sessionId]];
            // adjust start date and end date by a day to account for time zone differences
            const dayBeforeStartDate = new Date(new Date(session[0].eventDate).getTime() - 86400000).toISOString().split("T")[0];
            const dayAfterEndDate = new Date(new Date(session[0].eventDate).getTime() + 86400000).toISOString().split("T")[0];
            const sessionLink = `https://app.voicify.com/v/apps/${props.applicationId}/analytics/sessions?sessionId=${sessionId}&startDate=${dayBeforeStartDate}&endDate=${dayAfterEndDate}`;
            const conversationTurnCount = session.length;
            const [UtcDate, utcUnformattedTime] = session[0].eventDate.split("T");
            const utcTimeFormatted = utcUnformattedTime.split(".")[0];
            const estTime = convertUtcToEst(session[0].eventDate);
            const estDate = estTime.toISOString().split("T")[0];
            const formattedEstTime = estTime.toISOString().split("T")[1].split(".")[0];
            const callerPhoneNumber = session[0]?.originalRequest?.sessionAttributes['callingNumber'];
            let orderPlaced = false;
            let callerAction = "";
            let transfer = false;
            let aIAnswering = false;
            let orderAttempted = "";

            Object.values(session).forEach((contentHit: ContentHitEvent) => {
                if (contentHit.originalRequest) {
                    if (contentHit.originalRequest.sessionFlags) {
                        const includesOrderPlacedFlag = contentHit.originalRequest.sessionFlags.includes("order-placed");
                        if (includesOrderPlacedFlag) {
                            orderPlaced = true;
                        }
                    }
                    const intentName = contentHit.originalRequest.nlp?.intent?.name;
                    if (intentName && (callerAction === "")) {
                        if (ORDERING_INTENT_NAMES.includes(intentName)) {
                            callerAction = "Order Attempt";
                        }
                        if (intentName.includes("Information-")) {
                            aIAnswering = true;
                        }
                    }
                    if (intentName && !transfer) {
                        if (TRANSFER_INTENT_NAMES.includes(intentName)) {
                            transfer = true;
                        }
                    }
                }
            });

            // get the last session content hit
            let lastTurnItemCount = 0;
            const lastContentHit = session[session.length - 1];
            const order = lastContentHit?.originalRequest?.sessionAttributes['order'];
            if (order) {
                const parsedOrder = JSON.parse(order);
                if (parsedOrder?.MenuItems?.length) {
                    for (const menuItem of parsedOrder.MenuItems) {
                        lastTurnItemCount += menuItem.Quantity ?? 1;
                    }
                }
            }
            const incompleteItems = lastContentHit?.originalRequest?.sessionAttributes['orderIssues'];
            if (incompleteItems) {
                const parsedOrderIssues = JSON.parse(incompleteItems);
                if (parsedOrderIssues?.IncompleteMenuItems?.length) {
                    for (const incompleteMenuItem of parsedOrderIssues.IncompleteMenuItems) {
                        lastTurnItemCount += incompleteMenuItem.Quantity ?? 1;
                    }
                }
            }

            if (callerAction === "") {
                if (transfer) {
                    callerAction = "Immediate Transfer";
                } else if (aIAnswering) {
                    callerAction = "AI Answering Question";
                } else {
                    callerAction = "Immediate Hangup / Dropped Call";
                }
            }

            if (callerAction === "Order Attempt") {
                orderAttempted = "Order Attempted";
            } else {
                orderAttempted = "Order Not Attempted";
            }

            callSummaryData.push([
                UtcDate,
                utcTimeFormatted,
                estDate,
                formattedEstTime,
                callerPhoneNumber,
                "Only",
                sessionLink,
                sessionId,
                callerAction,
                orderAttempted,
                transfer ? "Transferred" : "Not Transferred",
                orderPlaced ? "Order Placed" : "Order Not Placed",
                conversationTurnCount.toString(),
                lastTurnItemCount
            ]);
        });

        let previousCallers = {};

        const identifyRepeatCallers = () => {
            const repeatCallIndexes = [];
            callSummaryData.forEach((row, index) => {
                if (index === 0) {
                    return;
                }
                const rowUtcDate = row[0];
                const rowUtcTime = row[1];
                const rowFormattedUtcTime = rowUtcTime.split(".")[0];
                const rowCallerPhoneNumber = row[4];
                if (previousCallers.hasOwnProperty(rowCallerPhoneNumber)) {
                    const within2Hours = areDatesWithin2Hours(previousCallers[rowCallerPhoneNumber][previousCallers[rowCallerPhoneNumber].length - 1], `${rowUtcDate}T${rowFormattedUtcTime}`);
                    if (within2Hours) {
                        previousCallers[rowCallerPhoneNumber] = [...previousCallers[rowCallerPhoneNumber], `${rowUtcDate}T${rowFormattedUtcTime}`];
                        const callCount = previousCallers[rowCallerPhoneNumber].length;
                        if (callCount === 2) {
                            const repeatCallIndex = callSummaryData.findIndex((row) => row[4] === rowCallerPhoneNumber);
                            callSummaryData[index][5] = "Repeat";
                            callSummaryData[repeatCallIndex][5] = "First";
                            // if the first call was an unsuccessful order attempt and the second call was a successful order attempt, update the first call to "Order Deferred"
                            if (callSummaryData[index][9] === "Order Attempted" && callSummaryData[index][11] === "Order Not Placed" && callSummaryData[repeatCallIndex][9] === "Order Attempted" && callSummaryData[repeatCallIndex][11] === "Order Placed") {
                                callSummaryData[repeatCallIndex][9] = "Order Deferred";
                            }
                        } else if (callCount > 2) {
                            //find the indexes of the repeat calls and push them into an array called repeatCallIndexes
                            callSummaryData.forEach((row, index) => {
                                if (row[4] === rowCallerPhoneNumber) {
                                    repeatCallIndexes.push(index);
                                }
                            });
                            callSummaryData[index][5] = "Repeat";
                            // if the first call was an unsuccessful order attempt and the last call was a successful order attempt, update all calls except for the last to "Order Deferred"
                            repeatCallIndexes.forEach((idx) => {
                                if (idx !== repeatCallIndexes[repeatCallIndexes.length - 1]) {
                                    if (callSummaryData[idx][9] === "Order Attempted" && callSummaryData[idx][11] === "Order Not Placed" && callSummaryData[repeatCallIndexes[repeatCallIndexes.length - 1]][9] === "Order Attempted" && callSummaryData[repeatCallIndexes[repeatCallIndexes.length - 1]][11] === "Order Placed") {
                                        callSummaryData[idx][9] = "Order Deferred";
                                    }
                                }
                            })
                        }
                    } else {
                        previousCallers[rowCallerPhoneNumber] = [`${rowUtcDate}T${rowFormattedUtcTime}`];
                    }
                } else {
                    previousCallers[rowCallerPhoneNumber] = [`${rowUtcDate}T${rowFormattedUtcTime}`];
                }
            });
        };

        identifyRepeatCallers();
        exportCallSummary(callSummaryData);
    };

    const calculateFilteredSessions = (formValues: ApplicationOrderingStatisticsFormData) => {
        let newFilteredSessions = {};
        for (const sessionId in sessionContainer.sessions) {
            // check content item ids and phone numbers
            const sessionHits = sessionContainer.sessions[sessionId];
            if (sessionHits?.length) {
                const callingNumber = sessionHits[0]?.originalRequest?.sessionAttributes['callingNumber'];
                if (!callingNumber?.length) {
                    continue;
                } else if (formValues.callerNumbersToIgnore.includes(callingNumber)) {
                    continue;
                }
            }
            const calledNumbers = [sessionHits[0]?.originalRequest?.sessionAttributes['callednumber'], sessionHits[0]?.originalRequest?.sessionAttributes['calledNumber']]
            if (formValues.assistantNumbersToUse.some(c => calledNumbers.includes(c))) {
                newFilteredSessions[sessionId] = sessionHits;
            }
        }
        return newFilteredSessions;
    }

    const handleSubmitApplicationOrderingStatisticsForm = async (formValues: ApplicationOrderingStatisticsFormData) => {
        setIsLoadingApplicationOrderingStatistics(true);
        // check to see if app preferences exist in local storage for the current app
        const localStorageOrderingStatisticsPreferences: OrderingStatisticsPreferences[] = JSON.parse(localStorage.getItem("orderingStatisticsPreferences"));
        const currentAppPreferencesIndex = localStorageOrderingStatisticsPreferences && localStorageOrderingStatisticsPreferences.findIndex((preference) =>
            preference.applicationId === props.applicationId
        );
        let newLocalStorageOrderingStatisticsPreferences: OrderingStatisticsPreferences[] = [];
        if (currentAppPreferencesIndex) {
            // app preferences found, update the existing preferences
            newLocalStorageOrderingStatisticsPreferences = [...localStorageOrderingStatisticsPreferences]
            newLocalStorageOrderingStatisticsPreferences[currentAppPreferencesIndex] = {
                "applicationId": props.applicationId,
                "callerNumbersToIgnore": formValues.callerNumbersToIgnore,
                "assistantNumbersToUse": formValues.assistantNumbersToUse
            }
        } else {
            // app preferences not found, create new preferences
            newLocalStorageOrderingStatisticsPreferences[0] = {
                "applicationId": props.applicationId,
                "callerNumbersToIgnore": formValues.callerNumbersToIgnore,
                "assistantNumbersToUse": formValues.assistantNumbersToUse
            }
        }
        localStorage.setItem("orderingStatisticsPreferences", JSON.stringify(newLocalStorageOrderingStatisticsPreferences));
        //recalculate the filtered sessions
        const newFilteredSessions = calculateFilteredSessions(formValues);
        populateOrderingStatistics(newFilteredSessions);
        setIsLoadingApplicationOrderingStatistics(false);
    }

    return (
        <ApplicationOrderingStatisticsForm
            {...props}
            calculateFilteredSessions={calculateFilteredSessions}
            isLoadingApplicationOrderingStatistics={isLoadingApplicationOrderingStatistics}
            handleSubmitForm={handleSubmitApplicationOrderingStatisticsForm}
        />
    );
};

export default ApplicationOrderingExport;
