import React from 'react';
import { css } from '@emotion/css';
import { Tabs, TabList } from 'react-tabs';
import { ImageTabItem } from '../ImageInnerTabs';
import BodyContainerPanel from './BodyContainerPanel';
import HeaderSeparator from './HeaderSeparator';
import PrimaryIconTab from './PrimaryIconTab';
import HeaderBar from '../HeaderBar';
import FullPagePanel from './FullPagePanel';
import { breakpoint_small } from '../../../constants/breakpoints';

interface FullPageTabbedPanelProps {
    tabs: ImageTabItem[]
    selectedIndex?: number
    forceRenderTabPanel?: boolean
    onTabSelected?: (index: number, last: number) => void
    leftRender: () => JSX.Element
    titleRender: () => JSX.Element
    rightRender: () => JSX.Element
}

const FullPageTabbedPanel: React.FC<FullPageTabbedPanelProps> = (props) => {

    const renderTab = (tab: ImageTabItem, index) => {
        return PrimaryIconTab({ tab, index, className: '' });
    };

    return (
        <FullPagePanel className={`${panelStyle}`}>
            <Tabs className="tabs" forceRenderTabPanel={props.forceRenderTabPanel} defaultIndex={props.selectedIndex} onSelect={props.onTabSelected}>
                <HeaderBar>
                    {props.leftRender()}
                    {props.titleRender()}
                    <HeaderSeparator />
                    <TabList className="panel-tab-list full-page-tab-list">
                        {props.tabs.map(renderTab)}
                    </TabList>
                    {props.rightRender()}
                </HeaderBar>
                <BodyContainerPanel>
                    {props.children}
                </BodyContainerPanel>
            </Tabs>
        </FullPagePanel>
    )
};

const panelStyle = css`
    .tabs {
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0px;
    }
    ul {
        flex: 1;
    }
    .panel-tab-list {
        color: white;
        list-style-type: none;
        display: flex; 
        align-items: center;
        margin-left: 16px;
        flex: 1.5;
    }
    .full-page-tab-list {
        ${breakpoint_small} {
            overflow-x: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
`;

export default FullPageTabbedPanel;