import { sendGet, queryStringSerialize, sendDelete } from './generic';
import IResult from '../../models/result/IResult';
import AmazonAuthUrlRequest from '../../models/deployments/api/AmazonAuthUrlRequest';
import TokenResponse from '../../models/deployments/api/TokenResponse';
import salt from '../../services/authSalt';


export const getAmazonAuthUrl = (authUrlRequest: AmazonAuthUrlRequest) : Promise<IResult<string>> => {
    var csrfToken = salt();
    localStorage.setItem("amazon-salt", csrfToken)
    return sendGet<string>("/AmazonAuthorization/AuthorizationUrl" + "?" + queryStringSerialize(authUrlRequest) + `&salt=${csrfToken}`);
}

export const amazonAuthCallback = (query: string) : Promise<IResult<boolean>> => {
    return sendGet<boolean>(`/AmazonAuthorization/Callback${query}`)
}

export const getAvailableAmazonCredentials = (organizationId: string) : Promise<IResult<TokenResponse[]>> => {
    return sendGet<TokenResponse[]>(`/AmazonAuthorization/Credentials/${organizationId}`)
}

export const removeAmazonCredentials = (tokenId: string): Promise<IResult<boolean>> => {
    return sendDelete<boolean>(`/AmazonAuthorization/Credentials/${tokenId}`)
}