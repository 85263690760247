import React, { useState } from "react";
import { createContainer } from "unstated-next";
import * as voicifyApi from '../api';
import { CustomAssistantConfiguration } from "../models/customAssistant/CustomAssistantConfiguration";
import MediaItemModel from "../models/media/MediaItemModel";
import IResult from "../models/result/IResult";

function useCustomAssistantContainer() {
    const [customAssistantConfigurations, setCustomAssistantConfigurations] = useState<CustomAssistantConfiguration[]>([]);
    const [customAssistantConfiguration, setCustomAssistantConfiguration] = useState<CustomAssistantConfiguration>();
    const [configurationImage, setConfigurationImage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);

    const getCustomAssistantConfigurations = async (currentAppId: string) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.getCustomAssistantConfigurations(currentAppId);
            if (result.resultType == "Ok") {
                setCustomAssistantConfigurations(result.data);
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const getCustomAssistantConfiguration = async (configId: string) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.getCustomAssistantConfiguration(configId);
            if (result.resultType == "Ok") {
                setCustomAssistantConfiguration(result.data);
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const createCustomAssistantConfiguration = async (config: CustomAssistantConfiguration) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.createCustomAssistantConfiguration(config);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const updateCustomAssistantConfiguration = async (config: CustomAssistantConfiguration) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.updateCustomAssistantConfiguration(config);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const deleteCustomAssistantConfiguration = async (configId: string) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.deleteCustomAssistantConfiguration(configId);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const copyCustomAssistantConfiguration = async (configId: string) => {
        const oldConfig = await getCustomAssistantConfiguration(configId);
        const newConfig = await createCustomAssistantConfiguration(oldConfig.data);
        return newConfig;
    };

    const updateConfigurationImage = async (applicationId: string, imageFile: File): Promise<IResult<MediaItemModel>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.uploadMediaItem(applicationId, "configurationAvatar", imageFile);
            if (result.resultType == "Ok") {
                setConfigurationImage(result.data.url);
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        errors,
        customAssistantConfigurations,
        customAssistantConfiguration,
        getCustomAssistantConfigurations,
        getCustomAssistantConfiguration,
        deleteCustomAssistantConfiguration,
        updateCustomAssistantConfiguration,
        createCustomAssistantConfiguration,
        copyCustomAssistantConfiguration,
        updateConfigurationImage,
        configurationImage
    };
}

const CustomAssistantContainer = createContainer(useCustomAssistantContainer);
export default CustomAssistantContainer;
