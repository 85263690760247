import React from 'react';
import ReactMarkdown from 'react-markdown'
import styled from "@emotion/styled";
import { dark_sky_blue, greeny_blue, customGreen, bluegreen } from '../../../constants/colors';

interface Props {
    conversationText?: string
    className?: string
};

const ConversationMarkdown: React.FC<Props> = (props: Props) => (
    <StyledConversationMarkdown>
        <ReactMarkdown className={props.className ?? ''}
            linkTarget={"_blank"} >
            {props.conversationText}
        </ReactMarkdown>
    </StyledConversationMarkdown>
);

const StyledConversationMarkdown = styled.div`
/* unvisited link */
a:link {
  color: ${greeny_blue};
}
/* visited link */
a:visited {
  color: ${dark_sky_blue};
}
/* mouse over link */
a:hover {
  color: ${bluegreen};
}
/* selected link */
a:active {
  color: ${customGreen};
}
ol {
  margin-bottom: 6px;
  margin-top: 6px;
}
ul {
  margin-bottom: 6px;
  margin-top: 6px;
}
p {
  margin-bottom: 2px;
  margin-top: 2px;
}
h1 {
  margin-bottom: 2px;
  margin-top: 2px;
  color: ${dark_sky_blue};
}
h2 {
  margin-bottom: 2px;
  margin-top: 2px;
  color: ${dark_sky_blue};
}
h3 {
  margin-bottom: 2px;
  margin-top: 2px;
  color: ${dark_sky_blue};
}
h4 {
  margin-bottom: 2px;
  margin-top: 2px;
  color: ${dark_sky_blue};
}
h5 {
  margin-bottom: 2px;
  margin-top: 2px;
  color: ${dark_sky_blue};
}
h6 {
  margin-bottom: 2px;
  margin-top: 2px;
  color: ${dark_sky_blue};
}
`;

export default ConversationMarkdown;
