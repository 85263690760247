import React from 'react';
import {css} from '@emotion/css';

const scrollviewStyle = css`
    height: 100%;
    width: 100%;
    overflow-y: auto;
`

interface ScrollViewProps {
    className?: string
}
const ScrollView: React.SFC<ScrollViewProps> = (props) => (
    <div className={`${scrollviewStyle} ${props.className}`}>
        {props.children}
    </div>
);

export default ScrollView;