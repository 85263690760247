import * as yup from 'yup';
import { withFormik } from "formik";
import SparkTelephonyDetails from './SparkTelephonyDetails';
import { TelephonyDeploymentResponse } from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentResponse';
import TelephonyDeploymentRequest from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentRequest';
import { female1Voice } from '../../../../shared/constants/sparkConstants/voices';

export interface SparkTelephonyDetailsProps {
    selectedTelephonyDeployment: TelephonyDeploymentResponse
    handleSubmitTelephonyForm: (phoneNumber: string, telephonyDeploymentRequest: TelephonyDeploymentRequest) => void
    isLoadingTelephonyDeployments: boolean
    getVoice: (ttsVoice: string) => string
    loadTelephonyDeployments: () => void
}

export interface SparkTelephonyDetailsFormData {
    callRecordingEnabled: boolean
    ttsVoice: string
    virtualNumber?: string
}

const SparkTelephonyDetailsForm = withFormik<SparkTelephonyDetailsProps, SparkTelephonyDetailsFormData>({
    mapPropsToValues: (props) => ({
        callRecordingEnabled: props.selectedTelephonyDeployment?.callRecordingEnabled ?? false,
        ttsVoice: props.getVoice(props.selectedTelephonyDeployment?.ttsVoice) ?? female1Voice,
        virtualNumber: props.selectedTelephonyDeployment?.virtualNumber
    }),
    validationSchema: yup.object().shape({
        callRecordingEnabled: yup.boolean().required(),
        ttsVoice: yup.string().required(),
        virtualNumber: yup.string().required().max(50)
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(true);
    }
})(SparkTelephonyDetails);

export default SparkTelephonyDetailsForm;