import IResult from "../../models/result/IResult";
import { sendGet, sendPut } from "./generic";
import InteractionModel from "../../models/interactionModel/api/InteractionModel";
import InteractionModelUpdate from "../../models/interactionModel/api/InteractionModelUpdate";
import InteractionModelStatus from "../../models/interactionModel/api/InteractionModelStatus";
import InteractionModelUpdateWithEntitiesRequest from "../../models/interactionModel/api/InteractionModelUpdateWithEntitiesRequest";

export const getInteractionModel = (applicationId: string): Promise<IResult<InteractionModel>> => {
    return sendGet<InteractionModel>(`/InteractionModel/${applicationId}`);
}

export const getInteractionModelByLocale = (applicationId: string, locale: string): Promise<IResult<InteractionModel>> => {
    return sendGet<InteractionModel>(`/InteractionModel/${applicationId}?locale=${locale}`);
}

export const commitUpdateToInteractionModel = (applicationId: string, locale: string, update: InteractionModelUpdate): Promise<IResult<boolean>> => {
    return sendPut(`/InteractionModel/${applicationId}/${locale}`, update);
}

export const updateNlpEntitiesAndInteractionModelForApplication = (applicationId: string, update: InteractionModelUpdateWithEntitiesRequest): Promise<IResult<boolean>> => {
    return sendPut(`/InteractionModel/${applicationId}/entitiesAndInteractionModel`, update);
}

export const getInteractionModelStatusPerEnvironment = (applicationId: string, environmentId: string): Promise<IResult<InteractionModelStatus>> => {
    return sendGet<InteractionModelStatus>(`/InteractionModel/${applicationId}/environment/${environmentId}/status`);
}

export const queueDeployments = (applicationId: string): Promise<IResult<boolean>> => {
    return sendPut(`/InteractionModel/${applicationId}/queueDeployments`, {});
}
