import React from 'react';
import { css } from '@emotion/css';
import Select, { Option } from 'react-select';
import { Field } from 'formik';
import FieldError from './FieldError';
import { dark_grey_blue, silver_two } from '../../constants/colors';
import VerticalSeparator from '../structure/VerticalSeparator';
import { style_border_default } from '../../constants/stylesValues';

// component used in building forms with consistent components for look and function
interface TextFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any> | any) => void
    onBlur?: (e: any) => void
    label?: string
    id?: string
    options: Option[]
    useFormTheme?: boolean
    hidden?: boolean
    icon?: string
    labelIcon?: string
    clearable?: boolean
    clearValueText?: string
    className?: string
    labelStyle?: string
    useForm?: boolean
}

class SelectField extends React.Component<TextFieldProps, {}> {
    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        if (this.props.onBlur) {
            this.props.onBlur(true);
        }

    };
    render() {
        const props = {
            ...this.props
        };

        return (
            <div className={`${containerStyle} ${props.className} ${props.hidden ? hiddenStyle : ''} selector`}>
                <div className={labelContainer}>
                    {props.labelIcon && <img src={props.labelIcon} />}
                    {props.label !== undefined ? <label htmlFor={props.name} className={`${labelStyle} ${props.labelStyle}`}>{props.label}</label> : null}
                </div>
                {props.useFormTheme ?
                    <div className={fieldContainer}>
                        {props.icon !== undefined ?
                            <img src={props.icon} /> : null}
                        {props.icon !== undefined ?
                            <div className={separatorStyle}>
                                <VerticalSeparator />
                            </div> : null}
                        <Select id={props.id}
                            name={props.name}
                            placeholder={props.placeholder}
                            value={props.value}
                            onChange={this.handleChange.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            disabled={props.disabled}
                            options={props.options}
                            clearValueText={props.clearValueText}
                            clearable={props.clearable}
                            className={props.useFormTheme ? formSelectStyle : selectStyle} />
                    </div>
                    :
                    <Select id={props.id}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={this.handleChange.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        disabled={props.disabled}
                        options={props.options}
                        className={props.useFormTheme ? formSelectStyle : selectStyle} />}

                {props.useForm === undefined || props.useForm == true ?
                    <FieldError name={props.name} />
                    : null}
            </div>
        );
    }
}

const labelContainer = css`
display: flex;
align-items: center;
height: 22px;
`

const containerStyle = css`
margin-bottom: 24px;

`
const labelStyle = css`
font-family: Muli;
font-size: 14px;
font-weight: 600;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
margin: 24px 16px 25px 16px
`;

const selectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    margin-top: 8px;
    .Select-control {
        border-radius: 30px;
        min-height: 56px;
    }
    .Select-placeholder, .Select-value{
        padding: 12px 26px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-value-label {
        margin-left: 16px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .Select-option, Select-noresults {
        height: 56px;
        padding: 24px 26px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .Select-input {
        margin: 16px;
        font-family: Muli, sans-serif;
    }
`


const formSelectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    .Select-control {
        border-radius: 8px;
        min-height: 56px;
        border: none;
    }
    .Select-placeholder, .Select-value{
        padding: 12px 26px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-value-label {
        margin-left: 16px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .Select-option, Select-noresults {
        height: 56px;
        padding: 24px 26px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .Select-input {   
        margin: 12px 26px;
    }
    &.is-open  {
        .Select-control {
            .Select-arrow {
                width: 8px;
                height: 8px;
                border: none;
                border-left: solid 1px ${dark_grey_blue};
                border-top: solid 1px ${dark_grey_blue};
                transform: rotate(45deg);
                border-radius: 0;
                margin-right: 16px;
            }
        }
    }
    .Select-arrow {
        width: 8px;
        height: 8px;
        border: none;
        border-left: solid 1px ${dark_grey_blue};
        border-top: solid 1px ${dark_grey_blue};
        transform: rotate(-135deg);
        border-radius: 0;
        margin-right: 16px;
    }
`

const hiddenStyle = css`
    display: none;
`

const fieldContainer = css`
display: flex;
align-items: center;
height: 60px;
width: 100%;
background: #FFFFFF;
border: ${style_border_default};
box-sizing: border-box;
box-shadow: 0px 1px 2px rgba(50, 70, 97, 0.1);
border-radius: 8px;
margin-top: 8px;
img {
    margin: 16px;
}
`
const separatorStyle = css`
    height: 30px;
`

export default SelectField;