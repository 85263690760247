import React from 'react';
import { css } from '@emotion/css';
import ReactTable, { Column, ComponentPropsGetterR, ComponentPropsGetter0 } from 'react-table';
import { ocean_blue } from '../../constants/colors';

// component wrapping the react-table component to allow for consistent styles and functionality

interface SortedTableProps {
    columns?: Column[]
    data?: any
    className?: string
    showPagination?: boolean
    pageSize?: number
    noDataText?: string
    getTrProps?: ComponentPropsGetterR | ComponentPropsGetter0;
}

interface SortedTableState {
    pageSize?: number
}

class SortedTable extends React.Component<SortedTableProps, SortedTableState>{
    constructor(props) {
        super(props);
        this.state = {
            pageSize: props.pageSize ? props.pageSize : (localStorage.getItem("defaultPageSize") ? parseInt(localStorage.getItem("defaultPageSize")) : 20)
        }
    }
    handlePageSizeChange(size) {
        this.setState({
            ...this.state,
            pageSize: size
        });
        localStorage.setItem("defaultPageSize", size.toString());
    }
    render() {
        const props = this.props;
        return (
            <ReactTable className={`${tableStyles} ${props.className}`}
                data={props.data}
                columns={props.columns}
                noDataText={props.noDataText}
                showPagination={props.showPagination !== true ? false : true}
                onPageSizeChange={this.handlePageSizeChange.bind(this)}
                showPaginationBottom={props.showPagination}
                pageSize={this.state.pageSize}
                getTrProps={props.getTrProps}
                minRows={1} />
        );
    }
}

const tableStyles = css`
    border-radius: 10px;
    border: solid 1px #cccccc;
    overflow: hidden;
    &.no-border {
        border-width: 0;
    }
    .rt-table {
        background: white;
        overflow: hidden;
        .rt-thead, .rt-thead -header {
            padding: 0 16px;
            height: 60px;
            box-shadow: none;
            border-bottom: solid 1px #cccccc;
            .rt-th {
                height: 60px;
                border: none;
                box-shadow: none;
                div {
                    height: 100%;
                }
            }
        }
        .rt-tbody {
            min-height: 64px;
            .rt-tr-group {
                min-height: 52px;
                border-bottom: solid 1px #cccccc;

                .rt-tr {
                    background: transparent;
                    padding: 0 16px;
                    border: none;
                    overflow: visible;
                }
            }
        }

        div.rt-td {
            background: transparent;
            align-self: center;
            overflow: visible;
        }
    }

    .pagination-bottom {
        height: 45px;
        font-family: Muli;

        .-pagination {
            height: 100%;

            button, .-btn {
                color: ${ocean_blue};
                background: none;
                border: none;

                &:disabled {
                    opacity: .5;
                }
            }
        }
    }
`;
export default SortedTable;