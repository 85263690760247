import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';

interface ModalTitleProps {
    padded?: boolean
}

const ModalTitle: React.FC<ModalTitleProps> = (props) => (
    <h2 className={`${titleStyle} ${props.padded ? paddedStyle : ''}`}>
        {props.children}
    </h2>
);

const titleStyle = css`
    font-family: Muli;
    font-size: 24px;
    color: ${dark_grey_blue}; 
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 26px 0;
    ${breakpoint_small} {
        font-size: 16px;
    }
`;

const paddedStyle = css`
    margin: 26px;
`;

export default ModalTitle;