import React, { useState } from "react"
import { createContainer } from "unstated-next"
import IResult from "../models/result/IResult";
import * as voicifyApi from '../api';
import Term from "../models/term/Term";

function useTermsContainer() {
    const [term, setTerm] = useState([] as Term[]);
    const [latestTerm, setLatestTerm] = useState({ id: "", url: "" } as Term);
    const [errors, setErrors] = useState([] as string[]);

    const loadLatestTerm = async (): Promise<IResult<Term>> => {
        try {
            const result = await voicifyApi.getLatestTerm();
            if (result.resultType == "Ok") {
                setLatestTerm(result.data);
            }
            else {
                setErrors(result.errors);
            }
            return result;
        } catch (e) {
            setErrors(e);
        }
    }

    const clearErrors = () => {
        setErrors([]);
    }

    return {
        term,
        setTerm,
        latestTerm,
        setLatestTerm,
        errors,
        setErrors,
        loadLatestTerm,
        clearErrors,
    };
}

const TermsContainer = createContainer(useTermsContainer);
export default TermsContainer;
