import React from 'react';
import FileUploadField from '../../../../../shared/components/forms/FileUploadField';
import TextField from '../../../../../shared/components/forms/TextField';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import FormFieldCard from '../../../../../shared/components/forms/FormFieldCard';
import RadioGroup from '../../../../../shared/components/forms/RadioGroup';
import WarningLabel from '../../../../../shared/components/general/WarningLabel';

const infoCircle = require('../../../../../shared/content/images/info-circle-yellow.svg');
interface InformationGathering {
    values: {
        usefileUpload: boolean,
        fileUrl: string,
        webPageUrls: string[]
    }
    handleFileUrlFieldChange: (url: string) => void
    mediaContainer: any
    applicationId: string,
    handleWebPageUrlChange: (e) => void
    setUseFileUpload: (value: boolean) => void
}

export default function AutoGenerateFormFieldInformationGathering(props: InformationGathering) {
    return (
        <div>
            <WarningLabel image={infoCircle} text='Note: Automatically generating answers will overwrite any previously saved information in the fields.' />
            {/* <StyledFormFieldCard
                title='Responses Source'
                tip='Choose the source of information for your assistant responses.'
                className={formFieldCardStyle}
            >
                <RadioGroup
                    onChange={(e) => props.setUseFileUpload(e.value as boolean)}
                    value={props.values.usefileUpload}
                    options={[
                        { value: true, label: "I'll upload a PDF file" },
                        { value: false, label: 'Use my website' }
                    ]}
                />
            </StyledFormFieldCard> */}
            {((props.values.usefileUpload) === true) &&
                <StyledFormFieldCard
                    title="Upload File"
                    tip="Upload a file with your restaurant's information. We'll use it to create responses to common customer questions."
                    className={formFieldCardStyle}
                >
                    <FileUploadField
                        onChange={(e) => {
                            props.handleFileUrlFieldChange(e.url);
                        }}
                        disabled={false}
                        fileFormats=".pdf, .txt, .md"
                        mediaContainer={props.mediaContainer}
                        applicationId={props.applicationId}
                        value={props.values.fileUrl}
                        preview={false}
                    />
                </StyledFormFieldCard>
            }
            {((!props.values.usefileUpload) === true) &&
                <StyledFormFieldCard
                    title="Use My Website"
                    tip="Enter your website URL. We'll use it to create responses to common customer questions."
                    className={formFieldCardStyle}
                >
                    <div>
                        {props.values.webPageUrls?.map((webPageUrl: string, index: number) => (
                            <>
                                <div key={"webPageUrl" + index}>
                                    <TextField
                                        name={`webPageUrls.${index}`}
                                        id={`webPageUrls${index}`}
                                        value={webPageUrl}
                                        onChange={props.handleWebPageUrlChange}

                                        label="Website URL"
                                        placeholder="Enter your website URL here."
                                        useForm={false}
                                    />
                                </div>
                            </>
                        ))}
                    </div>
                </StyledFormFieldCard>
            }
        </div>
    )
}

const StyledFormFieldCard = styled(FormFieldCard)``;
const formFieldCardStyle = css`
    margin: 32px 0;
`;