import axios from 'axios';
import { GET_HELPMESSAGES, ADD_HELPMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import RepromptModel from '../../models/features/api/Reprompt/RepromptModel';
import NewRepromptRequest from '../../models/features/api/Reprompt/NewRepromptRequest';
import UpdateRepromptRequest from '../../models/features/api/Reprompt/UpdateRepromptRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';

export const getRepromptsForApp = (applicationId: string): Promise<IResult<RepromptModel[]>> => {
    return sendGet<RepromptModel[]>(`/Reprompt/${applicationId}`);
}
export const addReprompt = (applicationId: string, model: NewRepromptRequest): Promise<IResult<RepromptModel>> => {
    return sendPost<RepromptModel>(`/Reprompt/${applicationId}`, model);
}
export const updateReprompt = (model: UpdateRepromptRequest): Promise<IResult<RepromptModel>> => {
    return sendPut<RepromptModel>(`/Reprompt`, model);
}
export const deleteReprompt = (repromptId: string): Promise<IResult<RepromptModel>> => {
    return sendDelete<RepromptModel>(`/Reprompt/${repromptId}`);
}

