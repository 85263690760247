import IResult from "../../models/result/IResult";
import ApplicationModuleModel from "../../models/features/api/Modules/ApplicationModuleModel";
import { sendGet, sendPut, sendDelete } from "./generic";
import NewModuleRequest from "../../models/features/api/Modules/NewModuleRequest";
import LanguageModel from "../../models/features/api/LanguageModel";


export const getAllLanguages = () : Promise<IResult<LanguageModel[]>> => {
    return sendGet(`/ContentLanguage/all`)
}
