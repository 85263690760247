import React, { useEffect, useRef } from "react";
import { color_shades_dark, color_shades_darkest, text_blue } from "../../../constants/colors";
import styled from "@emotion/styled";
import Button from "../../../components/general/Button";
import { css } from "@emotion/css";
import ModalHeader from "../../../components/general/ModalHeader";
import FooterBar from "../../../components/structure/FooterBar";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import useWindowSize from "../../../hooks/UseWindowSize";
import ModalLayout from "../../../components/structure/ModalLayout";
import { JsonEditor as Editor } from 'jsoneditor-react';
import CustomAssistantRequest from "../../../models/customAssistant/CustomAssistantRequest";
import CustomAssistantResponse from "../../../models/customAssistant/CustomAssistantResponse";

const copyIcon = require('../../../content/images/organization-settings/copy-blue.svg');

export interface CodeOutputModalProps {
    showCodeOutputModal: boolean;
    closeModal: () => void;
    data: any,
    title: string
};

const CodeOutputModal: React.FC<CodeOutputModalProps> = (props) => {
    const windowSize = useWindowSize();

    const windowHeight = (windowSize.windowSize.innerHeight * .9) - 300;

    const CustomEditorWrapper = styled.div`
        height: ${windowHeight};
    `;

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const copyAndClose = (text: string) => {
        copyText(text);
        props.closeModal();
    };

    const jsonEditorRef = useRef(null);

    useEffect(() => {
        if (jsonEditorRef.current !== null) {
            jsonEditorRef.current.set(props.data);
        }
    }, [props.data])

    const setRef = instance => {
        if (instance) {
            jsonEditorRef.current = instance.jsonEditor;
        } else {
            jsonEditorRef.current = null;
        }
    };

    return (
        <ModalLayout className={CodeOutputModalStyle} isVisible={props.showCodeOutputModal}>
            <ModalHeader title={props.title} onClose={() => props.closeModal()} />
            <CodeDiv>
                <CustomEditorWrapper>
                    <CustomEditor>
                        <Editor
                            ref={setRef}
                            value={props.data}
                            enableTransform={false}
                            enableSort={false}
                            mode="tree"
                            allowedModes={['tree', 'code']}
                            sortObjectKeys={true}
                        />
                    </CustomEditor>
                </CustomEditorWrapper>
            </CodeDiv>
            <CopyDiv>
                <CopySpan onClick={() => copyText(JSON.stringify(props.data, null, 2))}>
                    Copy
                    <CopyImg src={copyIcon} />
                </CopySpan>
            </CopyDiv>
            <FooterBar>
                <CopyButton
                    type="button"
                    themes={["primary"]}
                    text="Copy & Close"
                    onClick={() => copyAndClose(JSON.stringify(props.data, null, 2))}
                />
                <Button
                    type="button"
                    themes={["secondary"]}
                    text="Cancel"
                    onClick={() => props.closeModal()}
                />
            </FooterBar>
        </ModalLayout>
    )
}

export default CodeOutputModal;

const CustomEditor = styled.div`
    font-family: Muli;
    *.jsoneditor-mode-tree {
        border: 1px solid ${color_shades_dark} ;
        font-family: Muli;
    }
    div.jsoneditor-menu {
        background-color: ${color_shades_darkest};
        border-bottom: none;
    }
    ul.jsoneditor-menu {
        background-color: white;
    }
    *.jsoneditor-navigation-bar {
        font-family: Muli;
    }
    .div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor td, div.jsoneditor th, div.jsoneditor textarea, pre.jsoneditor-preview, .jsoneditor-schema-error, .jsoneditor-popover {
        font-family: Muli;
    }
    div.jsoneditor-tree div.jsoneditor-color {
        display: none;
    }
`;

const CopyButton = styled(Button)`
    margin-right: auto;
`;

const CodeDiv = styled.div`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin-right: 24px;
    margin-bottom: 32px;
    margin-left: 24px;
    margin-top: 32px;
    overflow: hidden;
`;

const CopySpan = styled.span`
    margin-left: auto;
    color: ${text_blue}
    cursor: pointer;
    margin-right: 24px;
`;

const CopyImg = styled.img`
    margin-left: 8px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    vertical-align: middle;
`;

const CopyDiv = styled.div`
    margin-top: 24px;
    display: flex;
`;

const CodeOutputModalStyle = css`
    height: 804px;
    min-height: 804px;
    width: 1152px;
    min-width: 1152px;
`;
