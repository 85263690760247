import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';
import CheckIndicator from './CheckIndicator';

interface CheckboxGroupGroupProps {
    onChange: (selectedOptions: CheckboxOption, checked: boolean) => void
    options: CheckboxOption[]
    values: string[]
}

export interface CheckboxOption {
    label: string
    value: string
    icon?: string
}
class CheckboxGroup extends React.Component<CheckboxGroupGroupProps> {

    handleOptionSelected(option: CheckboxOption, checked: boolean) {
        if(this.props.onChange) this.props.onChange(option, checked);
    };

    render() {
        return (
            <div className={containerStyle}>
                {this.props.options.map((option, index) => (
                    <div className="check-option" key={index} onClick={this.handleOptionSelected.bind(this, option, !this.props.values?.some(v => v == option.value))}>
                        <CheckIndicator checked={this.props.values?.some(v => v == option.value)}/>
                        {option.icon ? <img className="option-icon" src={option.icon}/> : null}
                        <span>{option.label}</span>
                    </div>
                ))}
            </div>
        )
    }
}


const containerStyle = css`
    margin: 16px 0;
     >*{
     }

     .check-option {
        margin: 8px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        span {
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${dark_grey_blue};
            margin-left: 8px;
            word-break: break-all;
        }
     }

     .option-icon {
         margin-left: 16px;
     }
`;

export default CheckboxGroup;