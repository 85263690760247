import React from 'react';
import {css} from '@emotion/css';
import { color_shades_dark, page_primary_background } from '../../../constants/colors';

interface TemplateReviewSectionProps {
    title: string
    icon: string
    description: string
    iconStyle?: string
}

const TemplateReviewSection: React.FC<TemplateReviewSectionProps> = (props) => (
    <div className={containerStyle}>
        <h5>{props.title}</h5>
        <img className={props.iconStyle ?? `section-icon`} src={props.icon}/>
        <p>{props.description}</p>
        {props.children}
    </div>
);

const containerStyle = css`
    border: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 16px;

    h5 {
        margin-top: -14px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        background: ${page_primary_background};
        padding: 0 8px;
    }
    .section-icon {
        width: auto;
        height: 48px;
        margin-top: 16px;
    }
    p {
        margin: 16px 32px 32px 32px;
        
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
`;

export default TemplateReviewSection;