import { sendPut, sendPost } from './generic';
import IResult from '../../models/result/IResult';
import BulkContentRequest from '../../models/features/api/BulkContentRequest';
import SlimGenericContentItemModel from '../../models/features/api/SlimGenericContentItemModel';

export const bulkMoveTo = (applicationFeatureId: string, request: BulkContentRequest) : Promise<IResult<string>> => {
    return sendPut<string>(`/BulkContent/MoveToFeature/${applicationFeatureId}`, request);
}
export const bulkCopyTo = (applicationFeatureId: string, request: BulkContentRequest) : Promise<IResult<string>> => {
    return sendPut<string>(`/BulkContent/CopyToFeature/${applicationFeatureId}`, request);
}
export const bulkDisableAndDelete = (request: BulkContentRequest) : Promise<IResult<string>> => {
    return sendPut<string>(`/BulkContent/Delete/`, request);
}
export const bulkLoadDetails = (request: BulkContentRequest) : Promise<IResult<SlimGenericContentItemModel[]>> => {
    return sendPost<SlimGenericContentItemModel[]>(`/BulkContent/LoadDetails/`, request);
}