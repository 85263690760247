import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { css } from '@emotion/css';

interface SlideInSlideOutProps {
    direction: SlideOutDirection
    showChildren?: boolean
    styleName?: string
    slideType?: SlideType
    transitionTimeMS?: number
}

interface SlideInSlideOutState {
    shouldRender: boolean
}

type SlideType = "position" | "size"
type SlideOutDirection = "up" | "down" | "left" | "right";
class SlideInSlideOutComponent extends React.Component<SlideInSlideOutProps, SlideInSlideOutState> {
    constructor(props) {
        super(props);
        this.state = {
            shouldRender: false
        }
    }
    componentDidMount() {
        if (this.props.showChildren == null) {
            this.renderTimeout();
        }
        this.setState({
            ...this.state,
            shouldRender: true
        });
    }
    renderTimeout() {
        setTimeout(() => {
            this.setState({
                ...this.state,
                shouldRender: true
            })
        }, 100)
    }
    render() {
        const props = this.props;
        const showChildren = (props.showChildren ?? true) && this.state.shouldRender;
        const transitionTimeInTenths = props.transitionTimeMS == null ? 2 : props.transitionTimeMS * 100;
        const styleName = props.styleName ?? Math.random().toString(36).substring(2, 15);
        return (<CSSTransitionGroup
            className={generateSlideStyle(props.direction, styleName, props.slideType ?? "position", transitionTimeInTenths)}
            transitionName={styleName}
            transitionEnterTimeout={transitionTimeInTenths * 100}
            transitionLeaveTimeout={transitionTimeInTenths * 100}
        >
            {showChildren ?
                props.children
                :
                null}
        </CSSTransitionGroup>)
    }
}

const generateSlideStyle = (direction: SlideOutDirection, styleName: string, slideType: SlideType, transitionTime: number) => {
    const transitionStyle = `    
    .${styleName}-enter.${styleName}-enter-active {
        animation: ${styleName}-in .${transitionTime}s forwards;
    }

    .${styleName}-leave.${styleName}-leave-active {
        animation: ${styleName}-out .${transitionTime}s forwards;
    }
    `
    // we have to call 'css' on the string so emotion builds the animation correctly. 
    if (slideType === "position") {
        switch (direction) {
            case ("up"):
                const upStyle = `
                @keyframes ${styleName}-in {
                    0% {
                        transform: translateY(100%);
                    }
                    100% {
                        transform: translateY(0%);
                    }
                }            
                @keyframes ${styleName}-out {
                    0% {
                        transform: translateY(0%);
                    }
                    100% {
                        transform: translateY(100%);
                    }
                }`;
                return css`${transitionStyle} ${upStyle}`;
            case ("down"):
                const downStyle = `
                @keyframes ${styleName}-in {
                    0% {
                        transform: translateY(-100%);
                    }
                    100% {
                        transform: translateY(0%);
                    }
                }
                @keyframes ${styleName}-out {
                    0% {
                        transform: translateY(0%);
                    }
                    100% {
                        transform: translateY(-100%);
                    }
                }`;
                return css`${transitionStyle} ${downStyle}`;
            case ("left"):
                const leftStyle = `
                @keyframes ${styleName}-in {
                    0% {
                        transform: translateX(100%);
                    }
                    100% {
                        transform: translateX(0%);
                    }
                }

                @keyframes ${styleName}-out {
                    0% {
                        transform: translateX(0%);
                    }
                    100% {
                        transform: translateX(100%);
                    }
                }`;
                return css`${transitionStyle} ${leftStyle}`;
            case ("right"):
                const rightStyle = `
                @keyframes ${styleName}-in {
                    0% {
                        transform: translateX(-100%);
                    }
                    100% {
                        transform: translateX(0%);
                    }
                }
                @keyframes ${styleName}-out {
                    0% {
                        transform: translateX(0%);
                    }
                    100% {
                        transform: translateX(-100%);
                    }
                }`;
                return css`${transitionStyle} ${rightStyle}`;
        }
    }
    else {
        switch (direction) {
            case ("up"):
            case ("down"):
                const upDownStyle = `
                @keyframes ${styleName}-in {
                    from {
                        height: 0px;
                    }
                    to {
                        height:calc(100%);
                    }
                }
                @keyframes ${styleName}-out {
                    0% {
                        height:calc(100%);
                    }
                    100% {
                        height: 0px;
                    }
                }`;
                return css`${transitionStyle} ${upDownStyle}`;
            case ("left"):
            case ("right"):
                const leftRightStyle = `
                @keyframes ${styleName}-in {
                    from {
                        width: 0px;
                    }
                    to {
                        width:calc(100%);
                    }
                }
                @keyframes ${styleName}-out {
                    0% {
                        width:calc(100%);
                    }
                    100% {
                        width: 0px;
                    }
                }`;
                return css`${transitionStyle} ${leftRightStyle}`;
        }
    }
}

export default SlideInSlideOutComponent;