import { css } from '@emotion/css';
import React, { useEffect, useState } from "react";
import KeyModalLayout from '../../../components/structure/KeyModalLayout';
import ModalLayout from "../../../components/structure/ModalLayout";
import ApiKeyResult from "../../../models/auth/api/ApiKeyResult";
import ApiKeyContainer from "../../../state/containers/ApiKeyContainer";
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import ModalHeader from '../../../components/general/ModalHeader';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import CheckboxField from '../../../components/forms/CheckboxField';
import styled from '@emotion/styled';
import SessionAnalyticsContainer from '../../../hooks/SessionAnalyticsContainer';

interface AnalyticsSettingsModalProps {
    applicationContainer: ApplicationContainer,
    envContainer: ApplicationEnvironmentsContainer,
    appId: string,
    onClose: () => void
}

const AnalyticsSettingsModal: React.FC<AnalyticsSettingsModalProps> = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detailedAnalyticsEnabled, setDetailedAnalyticsEnabled] = useState(false);
    const [initialState, setInitialState] = useState(false);
    const sessionContainer = SessionAnalyticsContainer.useContainer();
    const load = async () => {
        const envs = await props.envContainer.loadEnvironments(props.appId, true);
        if (envs.data?.length) {
            if (envs.data[0]?.detailedAnalyticsEnabled) {
                setDetailedAnalyticsEnabled(true);
                setInitialState(true);
            }
        }
        setLoaded(true);
    }
    useEffect(() => {
        load();
    }, [])
    const handleToggle = () => {
        setDetailedAnalyticsEnabled(!detailedAnalyticsEnabled);
    }
    const handleSave = async () => {
        setLoading(true);
        if (detailedAnalyticsEnabled && !initialState) {
            await props.applicationContainer.updateDetailedAnalytics(props.appId, true);
            await props.envContainer.loadEnvironments(props.appId, true);
        } else if (!detailedAnalyticsEnabled && initialState) {
            await props.applicationContainer.updateDetailedAnalytics(props.appId, false);
            await props.envContainer.loadEnvironments(props.appId, true);           
        }
        props.onClose();
    }
    return (
        <ModalLayout className={modalStyle} isVisible={true} onClose={props.onClose}>
            <ModalHeader title="Analytics Settings" onClose={props.onClose} />
            <ContentContainer>
                <h4>Privacy Notice</h4>

                <ContentParagraph>By enabling detailed analytics, you will gain access to the transcripts of entire user conversations, <b>including potentially sensitive information shared during those interactions.</b></ContentParagraph>
   
                <ContentParagraph>Here are a few key points to consider before proceeding:</ContentParagraph>

                <ContentParagraph>
                    User Privacy: Enabling detailed analytics means that you will have access to full transcripts of user conversations. Ensure any sensitive data is marked as such using Voicify's Secure Conversations feature so it is not tracked.
                </ContentParagraph>
                <ContentParagraph>
                    By enabling detailed analytics, you acknowledge that you have read and understood the implications outlined above, and you accept responsibility for ensuring compliance with relevant privacy laws and regulations. If you have any questions or concerns, please reach out to Voicify's support team before proceeding.
                </ContentParagraph>
                <CheckboxField
                    id={"detailedAnalyticsEnabled"}
                    onChange={() => handleToggle()}
                    label={"Detailed Analytics Enabled"}
                    checked={detailedAnalyticsEnabled}
                    disabled={!loaded} />
                {detailedAnalyticsEnabled && <DetailedSimpleCheckbox
                    id={"simpleView"}
                    label={"Simple Transcript View"}
                    checked={sessionContainer.simpleView}
                    disabled={!loaded} 
                    onChange={() => sessionContainer.updateSimpleView(!sessionContainer.simpleView)}
                />}
            </ContentContainer>
            <FooterBar>
                <Button type="button" themes={['primary', 'start']} text="Save" onClick={() => handleSave()} disabled={!loaded || loading} loading={!loaded || loading} />
                <Button type="button" themes={['secondary', 'end']} text="Cancel" onClick={() => props.onClose()} disabled={!loaded} loading={!loaded} />
            </FooterBar>
        </ModalLayout>
    )
}

const modalStyle = css`
    width: 20vw;
    height: 10vh;
`;

const DetailedSimpleCheckbox = styled(CheckboxField)`
    margin-top: 32px;
`;

const ContentContainer = styled.div`
    margin: 32px;
    padding: 32px;
    display: flex
    flex-direction: column
    flex-wrap: wrap
`;

const ContentParagraph = styled.p`
    margin: 16px 0;
`;

export default AnalyticsSettingsModal;