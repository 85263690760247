import { Header } from "./CustomAssistantConfigurationStyleHeader";
import { Body } from "./CustomAssistantConfigurationStyleBody";
import { Toolbar } from "./CustomAssistantConfigurationStyleToolbar";

export type CodeExportLanguage = "JavaScript" | "Kotlin" | "Swift";

export const codeExportLanguageOptions = [
    { label: "JavaScript", value: "JavaScript" },
    { label: "iOS: Swift", value: "Swift" },
    { label: "Android: Kotlin", value: "Kotlin" }
]

export type MobileAssistantUIProps = {
    assistantSettings: {
        serverRootUrl: string,
        appId: string,
        appKey: string,
        textToSpeechProvider: string,
        locale: string,
        textToSpeechVoice: string,
        channel: string,
        device: string,
        autoRunConversation: boolean,
        initializeWithWelcomeMessage: boolean,
        useOutputSpeech: boolean,
        useVoiceInput: boolean,
        noTracking: boolean,
        useDraftContent: boolean,
        initializeWithText: boolean,
        backgroundColor: string
    },
    headerProps: Header,
    bodyProps: Body,
    toolbarProps: Toolbar
}

export const getKotlinHexValue = (hex: string, isKotlin: boolean) => {
    //check for gradient
    let colors = hex?.split(",")
    let gradient = ""
    if (colors?.length > 1) {
        colors.forEach((color, idx) => {
            let updatedColor = getKotlinHexValue(color, true)
            if (idx == 0) {
                gradient = gradient + `${updatedColor}`
            }
            else {
                gradient = gradient + `,${updatedColor}`
            }
        })
        return gradient
    }
    if (hex?.length == 9 && isKotlin) {
        return `#${hex.substring(7, 9)}${hex.substring(1, 7)}`
    }
    return hex
}