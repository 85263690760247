import React from 'react';
import {css} from '@emotion/css';
import { color_colors_royalty, color_variants_royalty_light_10 } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
const inIcon = require('../../../content/images/analytics-icons/lead-in.svg')
const outIcon = require('../../../content/images/analytics-icons/lead-out.svg')

interface NodeSocketProps {
    direction: "in" | "out"
    active: boolean
    className?: string
}

const NodeSocket: React.SFC<NodeSocketProps> = (props) => (
    <div className={`${containerStyle} ${props.className ?? ''} ${props.active ? 'active' : 'inactive'}`}>
        <img src={props.direction == "in" ? inIcon : outIcon}/>
    </div>
);

const containerStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: ${style_border_default};
    border-radius: 4px;
    background: ${color_variants_royalty_light_10};
    &.active {
        border: 1px solid ${color_colors_royalty};
    }
`;

export default NodeSocket;
