import React from 'react';
import { css } from '@emotion/css';
import SsmlElementSelector, { SsmlElement } from './SsmlElementSelector';
const speedIcon = require('../../../content/images/ssml/music-metronome-dark.svg');
const pitchIcon = require('../../../content/images/ssml/pitch-dark.svg');
const emphasisIcon = require('../../../content/images/ssml/megaphone-dark.svg');
const volumeIcon = require('../../../content/images/ssml/volume-control-dark.svg');

interface SsmlElementMenuProps {
    onElementSelected: (element: SsmlElement) => void
}

const containerStyle = css`
    display: flex;
    flex-wrap: wrap;

    > * + * {
        margin-left: 20px;
    }
`
const ProsodyMenu: React.SFC<SsmlElementMenuProps> = (props) => (
    <div className={containerStyle}>
        <SsmlElementSelector
            options={[
                { value: 'rate', name: 'Speed', icon: speedIcon },
                { value: 'pitch', name: 'Pitch', icon: pitchIcon },
                { value: 'emphasis', name: 'Emphasis', icon: emphasisIcon },
                { value: 'volume', name: 'Volume', icon: volumeIcon },
            ]}
            onOptionSelected={props.onElementSelected} />
    </div>

)

export default ProsodyMenu;