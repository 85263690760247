import React from 'react';
import { css } from '@emotion/css';
import { color_colors_decline, color_shades_darkest, color_variants_decline_light_1, faded_red } from '../../constants/colors';
import styled from '@emotion/styled';

const alertIcon = require('../../content/images/alert-circle.svg');

interface PageErrorProps {
    errors?: string[]
    onClear?: () => void,
    className?: string
}
interface PageErrorState {
    errors?: string[]
}


class PageError extends React.Component<PageErrorProps, PageErrorState>{
    constructor(props) {
        super(props);
        this.state = {
            errors: props.errors ?? []
        }
    }
    componentDidUpdate(previousProps: PageErrorProps, previousState: PageErrorState) {
        if (this.props.errors != previousProps.errors) {

            this.setState({
                errors: this.props.errors
            })
        }
    }
    handleClick() {
        this.setState({
            errors: []
        });
        if (this.props.onClear) {
            this.props.onClear();
        }
    }
    render() {
        return (
            <div onClick={this.handleClick.bind(this)}>
                {(this.state?.errors == null || this.state?.errors?.length == 0) ? null :
                    <div className={`${containerStyle} ${this.props.className?.length ? this.props.className : ""}`}>
                        <AlertIconStyle src={alertIcon} />
                        <div>
                            {this.state.errors?.map((e, i) => <p className="error-text" key={i}>{e?.toString() ?? ''}</p>)}
                        </div>
                    </div>
                }
            </div>
        )
    }
}
const containerStyle = css`
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 32;
    width: 360px;
    background: ${color_variants_decline_light_1};
    border: 1px solid ${color_colors_decline};
    padding: 16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    cursor: pointer;
    z-index: 999;
    .error-text {
        color: ${color_shades_darkest};
        font-family: Muli;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
    } 
`
const AlertIconStyle = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 8px;
`;

export default PageError;