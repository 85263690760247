import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, color_shades_dark, color_shades_darker } from '../../constants/colors';
import styled from '@emotion/styled';
import MultiFileAudioPlayer from '../messagePreviews/MultiFileAudioPlayer';
import UserAvatar from '../general/UserAvatar';
import { style_border_default } from '../../constants/stylesValues';
import { breakpoint_small } from '../../constants/breakpoints';

interface RadioGroupCardWithAudioProps {
    onChange: (selectedOption: RadioOption) => void
    options: RadioOption[]
    value: string | boolean
    disabled?: boolean
    selectedOptionStyle?: string
    checkContainerStyle?: string
}

export interface RadioOption {
    label?: string
    detailOverride?: () => JSX.Element
    value: string | boolean
    icon?: string
    description?: string
    audioSrc?: string
}

const RadioCardGroupWithAudio = (props: RadioGroupCardWithAudioProps) => {

    const handleOptionSelected = (option: RadioOption) => {
        if (props.onChange) props.onChange(option);
    };

    return (
        <>
            {props.options.map((option, index) => (
                <VoiceCardWrapper key={option.label}>
                    <UserAvatarWrapper>
                        <UserAvatar src={option.icon} firstName='' lastName='' size={32} />
                    </UserAvatarWrapper>
                    <div className={`${containerStyle} ${props.disabled ? 'disabled' : ''}`}>
                        <div
                            className="radio-option"
                            key={index}
                            onClick={props.disabled ? undefined : () => handleOptionSelected(option)}
                        >
                            <div className={props.checkContainerStyle ? props.checkContainerStyle : `check-container`}>
                                {option.value == props.value ? <div className={props.selectedOptionStyle ? props.selectedOptionStyle : `selected-option`} /> : null}
                            </div>
                            {option.label ? <p className="label">{option.label}</p> : <div className="label-filler" />}
                            {option.detailOverride && option.detailOverride()}
                        </div>

                    </div>
                    <VoiceDescription>{option.description}</VoiceDescription>
                    {option.label !== "Custom" && option.audioSrc &&
                        <MultiFileAudioPlayer
                            src={[option.audioSrc]}
                            isLoading={false}
                            onLoad={() => null}
                        />
                    }
                </VoiceCardWrapper >
            ))
            }
        </>
    )
}

const UserAvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const VoiceDescription = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin: -18px 0 32px 46px;
`;

const VoiceCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    border: ${style_border_default};
    border-radius: 5px;
    padding: 24px;
    margin: 24px 24px 0 0;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 250px;
    ${breakpoint_small} {
        margin: 24px 0 0 0;
    }
`;

const containerStyle = css`
    margin: 16px 0;
    >*{
    }

    &.disabled {
        .radio-option {
            cursor: not-allowed;
            .selected-option {
                background: ${color_shades_dark};
            }
        }
    }
    .radio-option {
        margin: 8px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

                
        .check-container {
            position: relative;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ffffff;
            border: solid 1px #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .selected-option {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-image: linear-gradient(225deg, #76c3b5, #3ea6ac 72%, #5296b3);
        }

        .label {
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${dark_grey_blue};
            margin-left: 16px;
            word-break: break-all;
            flex: 1;
        }
        .label-filler {
            margin-left: 16px;
        }
        .detail {            
            font-size: 14px;
            line-height: 20px;
            margin-top: 8px;
            margin-left: 16px;
        }
    }

    .option-icon {
        margin-left: 16px;
    }
`;
export default RadioCardGroupWithAudio;