import React, { useEffect, useState } from 'react';
import { TabPanel } from 'react-tabs';
import OrganizationContainer from '../../../../../shared/state/containers/OrganizationContainer';
import ApplicationContainer from '../../../../../shared/state/containers/ApplicationContainer';
import TitleStateContainer from '../../../../../shared/state/containers/TitleStateContainer';
import FullPageTabbedPanel from '../../../../../shared/components/structure/Panels/FullPageTabbedPanel';
import Logo from '../../../../../shared/scenes/dashboardHeader/components/Logo';
import DashboardHeader from '../../../../../shared/scenes/dashboardHeader';
import HeaderSeparator from '../../../../../shared/components/structure/Panels/HeaderSeparator';
import ProfileMenu from '../../../../../shared/scenes/profileMenu';
import ScrollablePanelBody from '../../../../../shared/components/structure/ScrollablePanelBody';
import OrganizationMembers from '../../../../../shared/scenes/organizationMembers';
import BasicOrganizationApplications from '../../basicOrganizationApplications';
import OrganizationSettings from '.';
import ApplicationModel from '../../../../../shared/models/applications/api/ApplicationModel';
import TemplateFormSectionModel from '../../../../../shared/models/templating/api/TemplateFormSectionModel';
import { getAppliedApplicationTemplatesForApp } from '../../../../../shared/api/controllers/appliedApplicationTemplates';
import TemplateFormFieldModel from '../../../../../shared/models/templating/api/TemplateFormFieldModel';
import { getAvailableTemplateConfigurations } from '../../../../../shared/api/controllers/templateConfigurations';
import { getAIAnsweringModuleId, getFoodOrderingModuleId } from '../../../../../shared/constants/ModuleConsts';
import SparkDeploymentContainer from '../../../../../shared/hooks/SparkDeploymentContainer';
import { AIAnsweringFeatureFlagId } from '../../../../../shared/constants/featureFlagIds';
import ApplicationWithMembers from '../../../../../shared/models/applications/api/ApplicationWithMembers';
import { aiAnsweringCustomModuleName } from '../../../../../shared/constants/sparkConstants/moduleConsts';

const appsIcon = require('../../../../../shared/content/images/tab-icons/apps.svg');
const membersIcon = require('../../../../../shared/content/images/tab-icons/members.svg');
const settingsIcon = require('../../../../../shared/content/images/tab-icons/settings.svg');

interface OrganizationDashboardProps {
    orgsContainer: OrganizationContainer
    appContainer: ApplicationContainer
    titleContainer: TitleStateContainer
    history: any
}

const membersTabName = "members";
const settingsTabName = "orgSettings";

const OrganizationDashboardContent: React.FC<OrganizationDashboardProps> = (props) => {
    const [commonApp, setCommonApp] = useState<ApplicationModel>();
    const [manageLocationsAvailable, setManageLocationsAvailable] = useState<boolean>(false);
    const [locationApps, setLocationApps] = useState<ApplicationModel[]>([]);
    const [commonAppSections, setCommonAppSections] = useState<TemplateFormSectionModel[]>([])
    const [locationAppFormFields, setLocationAppFormFields] = useState<TemplateFormFieldModel[]>([])
    const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);
    const [locationAppNames, setLocationAppNames] = useState<string[]>([]);

    const trainingContainer = SparkDeploymentContainer.useContainer();

    useEffect(() => {
        props.titleContainer.setTitleView(props.orgsContainer.state.currentOrganization.name, null, null);
    }, []);

    useEffect(() => {
        trainingContainer.setCurrentOrgId(props.orgsContainer.state.currentOrganization.id);
    }, [props?.orgsContainer?.state?.currentOrganization?.id]);


    useEffect(() => {
        loadTemplates();
    }, [props.appContainer.state.applicationsCount, props.orgsContainer.state.currentOrganization.id]);

    const loadTemplates = async () => {
        setIsLoadingTemplates(true);
        try {
            const orgApplicationsResponse = await props.appContainer.searchForOrgApplications(props.orgsContainer.state.currentOrganization.id);
            // 1: Get all apps in org
            let applications = orgApplicationsResponse?.data?.applications;
            const filteredApplications = applications.filter((app) => app.featureFlags?.some((flag) => flag.id === AIAnsweringFeatureFlagId)) ?? [];
            if (filteredApplications?.length) {
                applications = filteredApplications;
            }
            if (applications.length > 1) {
                // 2: Get common app
                const aiAnsweringModuleId = getAIAnsweringModuleId();
                const foodOrderingModuleId = getFoodOrderingModuleId();
                let commonApplicationList = applications.filter(app => app.allowsTemplating);
                if (commonApplicationList.length > 1) {
                    let found = false;
                    for (let potentialCommonApp of commonApplicationList) {
                        const allAppSectionsList = [] as TemplateFormSectionModel[];
                        const commonAppAppliedAppTemplates = await getAppliedApplicationTemplatesForApp(potentialCommonApp.id);
                        let commonAppAppliedAppTemplate: any;
                        const commonAppFoundByName = commonAppAppliedAppTemplates?.data?.find(at => at?.templateForm?.templateConfiguration?.name === aiAnsweringCustomModuleName);
                        if (commonAppFoundByName) {
                            commonAppAppliedAppTemplate = commonAppFoundByName;
                        } else {
                            commonAppAppliedAppTemplate = commonAppAppliedAppTemplates?.data?.find(at => at?.templateForm?.templateConfiguration?.id === aiAnsweringModuleId);
                        }
                        if (commonAppAppliedAppTemplate) {
                            // 4. Set common app
                            setCommonApp(potentialCommonApp);
                            for (const section of commonAppAppliedAppTemplate.templateForm.templateFormSections) {
                                allAppSectionsList.push(section);
                            }
                            // 5. Set common app form field sections
                            setCommonAppSections(allAppSectionsList);
                            setManageLocationsAvailable(true);
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        for (let potentialCommonApp of commonApplicationList) {
                            const allAppSectionsList = [] as TemplateFormSectionModel[];
                            const commonAppAppliedAppTemplate = await getAppliedApplicationTemplatesForApp(potentialCommonApp.id);
                            if (commonAppAppliedAppTemplate?.data?.some(at => at?.templateForm?.templateConfigurationId === foodOrderingModuleId)) {
                                // 4. Set common app
                                setCommonApp(potentialCommonApp);
                                for (const section of commonAppAppliedAppTemplate.data[0].templateForm.templateFormSections) {
                                    allAppSectionsList.push(section);
                                }
                                // 5. Set common app form field sections
                                setCommonAppSections(allAppSectionsList);
                                setManageLocationsAvailable(false);// we don't want food ordering only orgs to manage locations for now. 
                                // TODO: integrate food ordering management into the settings tab.
                                break;
                            }
                        }
                    }
                } else if (commonApplicationList.length == 1) {
                    const allAppSectionsList = [] as TemplateFormSectionModel[];
                    const commonApplication = commonApplicationList[0]
                    // 4. Set common app
                    setCommonApp(commonApplication);
                    const commonAppAppliedAppTemplates = await getAppliedApplicationTemplatesForApp(commonApplication.id);
                    let commonAppTemplateConfig = commonAppAppliedAppTemplates?.data?.filter(at => at?.templateForm?.templateConfigurationId === aiAnsweringModuleId);
                    if (commonAppTemplateConfig.length === 0) {
                        commonAppTemplateConfig = commonAppAppliedAppTemplates?.data?.filter(at => at?.templateForm?.templateConfigurationId === foodOrderingModuleId);
                        if (commonAppTemplateConfig?.length) {
                            setManageLocationsAvailable(false);
                        }
                    } else {
                        setManageLocationsAvailable(true);
                    }
                    if (commonAppTemplateConfig?.length) {
                        for (const section of commonAppTemplateConfig[0].templateForm.templateFormSections) {
                            allAppSectionsList.push(section);
                        }
                        // 5. Set common app form field sections
                        setCommonAppSections(allAppSectionsList);
                    }
                }
                // 6. Get location apps
                const locationApplications = applications.filter(app => !app.allowsTemplating);
                if (locationApplications.length > 0) {
                    // 7. Set location apps
                    setLocationApps(locationApplications);
                    setLocationAppNames(locationApplications.map(app => app.name));
                    // 8. Get location app form field sections
                    const locationAppSections = [] as TemplateFormSectionModel[];
                    let locationApplication: ApplicationWithMembers;
                    for (const locationApp of locationApplications) {
                        const locationAppAppliedAppTemplateResponse = await getAppliedApplicationTemplatesForApp(locationApp.id);
                        if (locationAppAppliedAppTemplateResponse.data) {
                            for (const appliedTemplate of locationAppAppliedAppTemplateResponse.data) {
                                if (appliedTemplate?.templateForm?.templateConfiguration?.name === aiAnsweringCustomModuleName) {
                                    locationApplication = locationApp;
                                    break;
                                }
                            }
                        }
                    };
                    if (locationApplication) {
                        const locationAppAppliedAppTemplateResponse = await getAppliedApplicationTemplatesForApp(locationApplication.id);
                        if (locationAppAppliedAppTemplateResponse.data) {
                            for (const locationAppTemplate of locationAppAppliedAppTemplateResponse.data) {
                                for (const section of locationAppTemplate.templateForm?.templateFormSections) {
                                    locationAppSections.push(section);
                                }
                            }
                        }
                    }
                    // 9. Get existing selected form fields
                    const locationApplicationFormFields = locationAppSections.flatMap(section => {
                        return section.templateFormFields;
                    })
                    // 10. Set existing selected form fields
                    setLocationAppFormFields(locationApplicationFormFields)
                }
            } else {
                if (!applications.length) {
                    setManageLocationsAvailable(true);
                }
                // 1: Get AI Answering app from Voicify Modules
                const availableTemplateConfigsResponse = await getAvailableTemplateConfigurations(props.orgsContainer.state.currentOrganization.id);
                const availableTemplateConfigs = availableTemplateConfigsResponse.data.filter(config => config.id === getAIAnsweringModuleId())
                // 2: Set common app sections to AI Answering app form field sections
                if (availableTemplateConfigs.length > 0) {
                    const aiAnsweringTemplateConfig = availableTemplateConfigs[0]; // There should only be one AI Answering Module
                    const aiAnsweringSections = aiAnsweringTemplateConfig?.templateForm?.templateFormSections;
                    setCommonAppSections(aiAnsweringSections);
                }
            }
        } catch (error) {
            console.error("Failed to load applications:", error);
        }
        setIsLoadingTemplates(false);
    };

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = "/v";
        if (index == 0) {
            props.history.push(urlStart)
        };
        if (index == 1) {
            props.history.push(urlStart + "/" + membersTabName)
        };
        if (index == 2) {
            props.history.push(urlStart + "/" + settingsTabName)
        };
    }

    const getSelectedIndex = () => {
        if (location.href.indexOf(membersTabName) > -1) {
            return 1;
        };
        if (location.href.indexOf(settingsTabName) > -1) {
            return 2;
        };
        return 0;
    }

    return (
        <FullPageTabbedPanel tabs={[{
            title: "Dashboard",
            icon: appsIcon,
            selectedIcon: appsIcon
        }, {
            title: "Members",
            icon: membersIcon,
            selectedIcon: membersIcon
        }, {
            title: "Settings",
            icon: settingsIcon,
            selectedIcon: settingsIcon
        }]}
            onTabSelected={handleTabSelection}
            selectedIndex={getSelectedIndex()}
            leftRender={() => <>
                <Logo isFull isInverse />
            </>}
            titleRender={() => <DashboardHeader />}
            rightRender={() =>
                <>
                    <HeaderSeparator />
                    <ProfileMenu />
                </>
            }>
            <TabPanel>
                <ScrollablePanelBody>
                    <BasicOrganizationApplications history={props.history} />
                </ScrollablePanelBody>
            </TabPanel>
            <TabPanel>
                <ScrollablePanelBody>
                    <OrganizationMembers history={props.history} />
                </ScrollablePanelBody>
            </TabPanel>
            <TabPanel>
                <ScrollablePanelBody>
                    <OrganizationSettings
                        commonApp={commonApp}
                        locationApps={locationApps}
                        locationAppNames={locationAppNames}
                        commonAppSections={commonAppSections}
                        locationAppFormFields={locationAppFormFields}
                        isLoadingTemplates={isLoadingTemplates}
                        history={props.history}
                        hideApplicationSettings={!manageLocationsAvailable}
                    />
                </ScrollablePanelBody>
            </TabPanel>
        </FullPageTabbedPanel>
    );
}

export default OrganizationDashboardContent;
