import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { color_gradients_green_shift, color_gradients_green_shift_reverse, color_gradients_knight_shift } from '../../../constants/colors';

const emptyImage = require('../../../content/images/content-explorer/empty.svg');

interface EmptyViewProps {
    title: string
    description: string
    actionLink?: string
    actionText?: string
    imageStyle?: string
    wrapperStyle?: string
    noImage?: boolean
}

const EmptyView: React.FC<EmptyViewProps> = ({
    title,
    description,
    actionLink,
    actionText,
    imageStyle,
    wrapperStyle,
    noImage
}) => (
    <div className={`${wrapperStyle && wrapperStyle} ${emptyWrapper}`}>
        {!noImage && <img src={emptyImage} className={imageStyle} />}
        <h4>{title}</h4>
        <p>{description}</p>
        {actionLink && actionText && actionLink.length > 0 && actionText.length > 0 &&
            <Link to={actionLink} className={buttonStyle}>{actionText}</Link>
        }
    </div>
);

const emptyWrapper = css`
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    p {
        max-width: 360px;
        margin: 16px;
        text-align: center;
    }
`;

const buttonStyle = css`
    position: relative;
    height: 50px;
    white-space: nowrap;
    border-radius: 40px;
    border: none;
    padding: 0 40px;
    margin: 16px;
    cursor: pointer;
    font-family: Muli;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: white;
    background: ${color_gradients_green_shift};
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
        background: ${color_gradients_knight_shift};
        &:hover, &:active {
            background: ${color_gradients_knight_shift};
        }
    }
    &:hover {
        background: ${color_gradients_green_shift_reverse};
    }
    &:active {
        box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.25);
    }
`;

export default EmptyView;