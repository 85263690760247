import { sendGet, queryStringSerialize, sendDelete } from './generic';
import IResult from '../../models/result/IResult';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';

export const getAllDeviceTargets = () : Promise<IResult<DeviceTargetModel[]>> => {
    return sendGet<DeviceTargetModel[]>("/DeviceTarget/")
}

export const getDeviceTargets = (id: string) : Promise<IResult<DeviceTargetModel>> => {
    return sendGet<DeviceTargetModel>(`/DeviceTarget/${id}`)
}