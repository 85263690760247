import React from "react";
import { Subscribe } from 'unstated';
import MemberContainer from "../../state/containers/MemberContainer";
import OrganizationMemberListView from "./components/OrganizationMemberListView";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import ApplicationContainer from "../../state/containers/ApplicationContainer";
import UserContainer from "../../state/containers/UserContainer";
import AuthContainer from "../../state/containers/AuthContainer";

interface OrganizationmembersProps {
    history: any
}

const OrganizationMembers: React.FC<OrganizationmembersProps> = (props) => {

    return (
        <Subscribe to={[MemberContainer, OrganizationContainer, ApplicationContainer, UserContainer]}>
            {(memberContainer: MemberContainer,
                orgContainer: OrganizationContainer,
                appContainer: ApplicationContainer,
                userContainer: UserContainer,
                authContainer: AuthContainer
            ) => {

                return (
                    <OrganizationMemberListView
                        history={props.history}
                        memberContainer={memberContainer}
                        organizationId={orgContainer.state.currentOrganization.id}
                        appContainer={appContainer}
                        userContainer={userContainer}
                        organizationContainer={orgContainer}
                        authContainer={authContainer}
                    />
                );
            }}
        </Subscribe>
    );

};

export default OrganizationMembers;
