import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { color_colors_gold, color_shades_dark, color_variants_gold_light } from '../../constants/colors';
import Button from './Button';

const closeIcon = require('../../content/images/close_icon.svg');

interface WarningLabelProps{
    image: string,
    text?: string,
    closable?: boolean,
    onClose?: () => void
}

const WarningLabel = (props: WarningLabelProps) => {
    
    return(
        <WarningLabelWrapper>
            <div className={props.closable ? "wrapper-with-close" : "wrapper-no-close"}>
                <img src={props.image} />
                <p>{props.text}</p>
                {props.closable && <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={props.onClose}/>}
            </div>
        </WarningLabelWrapper>
    )

}

const WarningLabelWrapper = styled.div`
    border-radius: 12px;
    background: ${color_variants_gold_light};
    border: 1px solid ${color_colors_gold};
    width: auto;
    margin-bottom: 16px;
    .wrapper-with-close{
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-left: 24px;
        padding-right: 16px;
    }
    .wrapper-no-close{
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 18px 16px 18px 24px;
    }
    img {
        margin-right: 25px;
    }
    button {
        img {
            width: 20px;
            height: 20px;
        }
    }
`;

export default WarningLabel;