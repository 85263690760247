import { GET_MEDIARESPONSES } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import MediaResponseContainerModel from '../../models/features/api/MediaResponses/MediaResponseContainerModel';
import NewMediaResponseRequest from '../../models/features/api/MediaResponses/NewMediaResponseRequest';
import MediaResponseModel from '../../models/features/api/MediaResponses/MediaResponseModel';
import NewMediaResponseContainerRequest from '../../models/features/api/MediaResponses/NewMediaResponseContainerRequest';
import NewBulkMediaResponseContainerRequest from '../../models/features/api/MediaResponses/NewBulkMediaResponseContainerRequest';
import ResponseTemplateTypeModel from '../../models/features/api/MediaResponses/ResponseTemplateTypeModel';
import UpdateMediaResponseContainerRequest from '../../models/features/api/MediaResponses/UpdateMediaResponseContainerRequest';
import UpdateMediaResponseRequest from '../../models/features/api/MediaResponses/UpdateMediaResponseRequest';
import UpdateBulkMediaResponseContainerRequest from '../../models/features/api/MediaResponses/UpdateBulkMediaResponseContainerRequest';


// create
export const createMediaResponse = (model: NewMediaResponseRequest): Promise<IResult<MediaResponseModel>> => {
    return sendPost<MediaResponseModel>(`${GET_MEDIARESPONSES}`, model)
}
export const createMediaResponseContainer = (model: NewMediaResponseContainerRequest): Promise<IResult<MediaResponseContainerModel>> => {
    return sendPost<MediaResponseContainerModel>(`${GET_MEDIARESPONSES}/containers`, model)
}
export const createBulkMediaResponseContainer = (model: NewBulkMediaResponseContainerRequest): Promise<IResult<MediaResponseContainerModel>> => {
    return sendPost<MediaResponseContainerModel>(`${GET_MEDIARESPONSES}/containers/bulk`, model)
}


// read
export const getMediaResponseContainersForApplication = (applicationId: string): Promise<IResult<MediaResponseContainerModel[]>> => {
    return sendGet<MediaResponseContainerModel[]>(`${GET_MEDIARESPONSES}/containers/forapp/${applicationId}`)
}
export const getMediaResponseContainerById = (containerId: string): Promise<IResult<MediaResponseContainerModel>> => {
    return sendGet<MediaResponseContainerModel>(`${GET_MEDIARESPONSES}/containers/${containerId}`)
}
export const getResponseTemplateTypesByAssistant = (assistant: string): Promise<IResult<ResponseTemplateTypeModel[]>> => {
    return sendGet<ResponseTemplateTypeModel[]>(`${GET_MEDIARESPONSES}/templateType/assistant/${assistant}`)
}
export const getEnabledResponseTemplates = () : Promise<IResult<ResponseTemplateTypeModel[]>> => {
    return sendGet<ResponseTemplateTypeModel[]>(`${GET_MEDIARESPONSES}/templateType/enabled`)
}
export const getAllResponseTemplates = () : Promise<IResult<ResponseTemplateTypeModel[]>> => {
    return sendGet<ResponseTemplateTypeModel[]>(`${GET_MEDIARESPONSES}/templateType`)
}
export const getResponseTemplateById = (templateId: string) : Promise<IResult<ResponseTemplateTypeModel>> => {
    return sendGet<ResponseTemplateTypeModel>(`${GET_MEDIARESPONSES}/templateType/${templateId}`)
}

// update
export const updateMediaResponse = (responseId: string, model: UpdateMediaResponseRequest) : Promise<IResult<MediaResponseModel>> => {
    return sendPut<MediaResponseModel>(`${GET_MEDIARESPONSES}/${responseId}`, model)
}
export const updateMediaResponseContainer = (containerId: string, model: UpdateMediaResponseContainerRequest) : Promise<IResult<MediaResponseContainerModel>> => {
    return sendPut<MediaResponseContainerModel>(`${GET_MEDIARESPONSES}/containers/${containerId}`, model)
}
export const updateBulkMediaResponseContainer = (containerId: string, model: UpdateBulkMediaResponseContainerRequest) : Promise<IResult<MediaResponseContainerModel>> => {
    return sendPut<MediaResponseContainerModel>(`${GET_MEDIARESPONSES}/containers/${containerId}/bulk`, model)
}

// delete

export const deleteMediaResponse = (responseId: string) : Promise<IResult<MediaResponseModel>> => {
    return sendDelete<MediaResponseModel>(`${GET_MEDIARESPONSES}/${responseId}`)
}
export const deleteMediaResponseContainer = (containerId: string) : Promise<IResult<MediaResponseContainerModel>> => {
    return sendDelete<MediaResponseContainerModel>(`${GET_MEDIARESPONSES}/containers/${containerId}`)
}