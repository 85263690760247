import React from 'react';
import { css } from '@emotion/css';
import BreakdownChart from "./BreakdownChart";
import AnalyticsSelectField from '../analytics/AnalyticsSelectField';
import BreakdownItem from '../../models/analytics/BreakdownItem';
import SecondaryLoader from '../general/SecondaryLoader';
import CustomScrollbars from '../structure/CustomScrollbars';
import { color_colors_ocean, color_shades_dark } from '../../constants/colors';


interface BreakdownProps {
    items: BreakdownItem[]
    secondaryItems: BreakdownItem[]
    showSecondary?: boolean
    showPrimary?:boolean
    isLoading: boolean
    options: string[]
    selectedOption: string
    primaryDataLabel: string
    secondaryDataLabel?: string
    onOptionChange: (option: string) => void
}

const BreakdownChartCard: React.FC<BreakdownProps> = (props) => (
    <div className={containerStyle}>
        <div className="title-section ac-analytics-breakdown-card">
            <h5>Breakdown</h5>
            <AnalyticsSelectField className="breakdown-select" options={props.options?.map(o => ({ value: o, label: o }))} onChange={e => props.onOptionChange(e.value)} value={props.selectedOption} />
        </div>
        {props.isLoading
            ? <div className="loader-container"><SecondaryLoader /></div>
            : <CustomScrollbars className="scroll-container" autoHide>
                <BreakdownChart items={props.items} secondaryItems={props.secondaryItems} showSecondary={props.showSecondary} showPrimary={props.showPrimary} isLoading={props.isLoading} primaryDataLabel={props.primaryDataLabel} secondaryDataLabel={props.secondaryDataLabel} />
            </CustomScrollbars>}
    </div>
)


const containerStyle = css`
    height: 468px;
    width: 532px;
    font-family: Muli;
    border: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 0;
    background: white;
    overflow: hidden;
    .scroll-container {
        padding: 0;
    }
    .title-section {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        padding: 0 32px;
        h5 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            flex: 1;
        }

        .breakdown-select {
            .Select {
                height: 48px;
            }
            .Select-control {
                border-radius: 8px;
                height: 48px;
                .Select-value {
                    line-height: 48px;
                }
            }
            .Select-menu-outer {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            .Select-option, Select-noresults {
                height: 48px;
                font-family: Muli;
                font-size: 14px;
                line-height: 32px;
            }
            .Select-option:last-child {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            .Select.is-focused:not(.is-open) > .Select-control {
                border-color: transparent;
            }
        }
    }
    .loader-container {
     display: flex;
     flex: 1;
     align-items: center;
     justify-content: center;
     width: 100%;
     .spinner > div {
         background: ${color_colors_ocean};
     }
    }

`

export default BreakdownChartCard;