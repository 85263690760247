import React from 'react';
import { css } from '@emotion/css';
import { Subscribe } from 'unstated';
import ModalLayout from '../../components/structure/ModalLayout';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import FullScreenLayout from '../fullScreenLayout';
import ScrollView from '../../components/structure/ScrollView';
import PageContainer from '../../components/structure/PageContainer';
import HorizontalContainer from '../../components/structure/HorizontalContainer';
import ModalTitle from '../../components/general/ModalTitle';
import Button from '../../components/general/Button';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import TabPanelBody from '../../components/structure/TabPanelBody';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { RouteComponentProps } from 'react-router';
import FeatureContainer from '../../state/containers/FeatureContainer';
import UserContainer from '../../state/containers/UserContainer';
import PageError from '../../components/general/PageError';
import MemberContainer from '../../state/containers/MemberContainer';
import OrganizationMemberInviteForm from './components/OrganizationMemberInviteForm';
import ModalHeader from '../../components/general/ModalHeader';
import styled from '@emotion/styled';
import { breakpoint_small } from '../../constants/breakpoints';
const closeIcon = require('../../content/images/close_icon.svg');


interface OrganizationMemberInviteProps {
}

interface OrganizationMemberInviteState {
    hasChanges: boolean
}

class OrganizationMemberInvite extends React.Component<RouteComponentProps<OrganizationMemberInviteProps>, OrganizationMemberInviteState> {
    componentWillMount() {
        this.setState({
            hasChanges: false
        })
    }
    handleClose() {
        if (this.state.hasChanges) {
            var confirmClose = confirm("You have unsaved changes. Are you sure you want to close?");
            if (!confirmClose)
                return;
        }

        this.props.history.goBack();
    }
    handleFinish() {
        this.props.history.goBack();
    }
    handleChange() {
        this.setState({ hasChanges: true });
    }
    render() {
        return (

            <ModalLayout isVisible={true} onClose={this.handleClose.bind(this)}>
                <Subscribe to={[MemberContainer, OrganizationContainer]}>
                    {(memberContainer: MemberContainer, orgContainer: OrganizationContainer) => {
                        return (
                            <FullScreenLayout>
                                <ScrollView>
                                    <PageContainer fill withFooter>
                                        <HorizontalContainer>
                                            <ModalHeader title="Add a New Member" onClose={this.handleClose.bind(this)} />
                                        </HorizontalContainer>
                                        <HorizontalSeparator />
                                        <OrganizationMemberInviteForm organizationContainer={orgContainer} memberContainer={memberContainer} onFinish={this.handleFinish.bind(this)} onClose={this.handleClose.bind(this)} onChange={this.handleChange.bind(this)} />
                                    </PageContainer>
                                </ScrollView>
                                <PageError errors={memberContainer.state.errors} />
                            </FullScreenLayout>
                        );
                    }}
                </Subscribe>
            </ModalLayout>
        )
    }
};

const StyledModalHeader = styled(ModalHeader)`
    ${breakpoint_small} {
        h2 {
            
        }
    }
`;

export default OrganizationMemberInvite;