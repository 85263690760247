import React from 'react';
import { css } from '@emotion/css';
import { ResponsiveLine } from '@nivo/line';
import { ocean_blue } from '../../constants/colors';
import { style_border_default } from '../../constants/stylesValues';
import HorizontalSeparator from '../structure/HorizontalSeparator';

interface HistogramChartProps {
    primaryDataName: string
    secondaryDataName?: string
    data: { x: string, y: number, color: string, fullDate: string, link?: string }[]
    secondaryData?: { x: string, y: number, color: string, fullDate: string, link?: string }[]
}

export const HistogramChart: React.FC<HistogramChartProps> = (props) => {

    const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
        return series.map(({ id, data, color }) => (
            <path
                key={id}
                d={lineGenerator(
                    data?.map(d => ({
                        x: xScale(d.data.x),
                        y: d.data.y != null ? yScale(d.data.y) : null,
                    }))
                )}
                fill="none"
                stroke={color}
                style={id == props.primaryDataName ? {
                    strokeWidth: 2
                } : {
                    strokeDasharray: '2, 2',
                    strokeWidth: 2,
                }}
            />
        ))
    };

    const ToolTip = (data) => {
        return <div className={tooltipStyle}>
            <p className="date-label">{data?.fullDate}</p>
            <HorizontalSeparator />
            <p className="value-label">{data?.y}</p>
            <div className="triangle"></div>
        </div>
    };

    const data = [];

    if (props.data) {
        data.push({ id: props.primaryDataName, data: props.data, color: ocean_blue, dotColor: 'white', dotBorder: ocean_blue });
    }
    if (props.secondaryData) {
        data.push({ id: props.secondaryDataName, data: props.secondaryData, color: ocean_blue, dotColor: ocean_blue, dotBorder: 'white' })
    }

    return (
        <div className={chartContainerStyle}>
            <ResponsiveLine data={data}
                enableArea={true}
                animate={true}
                curve="monotoneX"
                enableGridX={false}
                colors={[ocean_blue]}
                pointSize={8}
                pointBorderColor={(c) => c.serieColor == c.color ? 'white' : c.serieColor}
                pointBorderWidth={2}
                pointColor={(c) => c.dotColor}
                useMesh
                tooltip={(c) => ToolTip(c.point.data)}
                margin={{
                    "top": 20,
                    "right": 40,
                    "bottom": 80,
                    "left": 24
                }}
                axisTop={null}
                axisLeft={null}
                axisBottom={{
                    "tickSize": 0,
                    "tickPadding": 12,
                    "tickRotation": -56,
                    format: d => d?.toString()
                }}
                axisRight={{
                    "tickSize": 0,
                    "tickPadding": 12,
                    "tickRotation": 0,
                    format: d => d?.toString()
                }}
                theme={{
                    "tooltip": {
                        "container": {
                            "fontSize": "13px"
                        }
                    },
                    "labels": {
                        "text": { "color": "#555" }
                    }
                }}
                layers={['grid', 'areas', DashedLine, 'slices', 'points', 'axes', 'legends', 'markers', 'mesh']} />
        </div>
    )
}

const chartContainerStyle = css`
    height: 400px;
    width: auto;
    font-family: Muli;

    tspan {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
    }
`

const tooltipStyle = css`
    padding: 16px;
    background: white;
    border: ${style_border_default};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .date-label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
    .value-label {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    .triangle {
        transform: rotate(225deg);
        position: absolute;
        width: 8px;
        height: 8px;
        bottom: 0;
        margin-bottom: -4px;
        border-top: ${style_border_default};
        border-left: ${style_border_default};
        background: white;
        z-index: 10;
    }
`

export default HistogramChart;