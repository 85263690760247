import * as yup from 'yup';
import { withFormik } from "formik";
import { ApplicationOrderingStatisticsFormData } from './ApplicationOrderingStatisticsFormData';
import ApplicationOrderingStatistics from './ApplicationOrderingStatistics';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import { Dictionary } from 'lodash';
import IGenericContentContainer from '../../../state/definitions/IGenericContentContainer';
import ContentItemModel from '../../../models/features/api/ContentItemModel';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';

export interface ApplicationOrderingStatisticsProps {
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string,
    ssmlContainer: SsmlContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    history: any
    envContainer: ApplicationEnvironmentsContainer
    handleSubmitForm: (formValues: ApplicationOrderingStatisticsFormData) => void
    isLoadingApplicationOrderingStatistics: boolean
    calculateFilteredSessions: (formValues: ApplicationOrderingStatisticsFormData) => void
}

const ApplicationOrderingStatisticsForm = withFormik<ApplicationOrderingStatisticsProps, ApplicationOrderingStatisticsFormData>({
    mapPropsToValues: (props) => ({
        callerNumbersToIgnore: [""],
        assistantNumbersToUse: [""]
    }),
    validationSchema: yup.object().shape({
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        props.handleSubmitForm(values);
        setSubmitting(false);
    }
})(ApplicationOrderingStatistics);

export default ApplicationOrderingStatisticsForm;