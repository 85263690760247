import React from 'react';
import { css } from '@emotion/css';
import Scrollbars from 'react-custom-scrollbars';

const bodyStyle = css`
    padding: 32px 0;
    
`;

interface TabPanelProps {
    className?: string
}

const TabPanelBody: React.SFC<TabPanelProps> = (props) => (
    <div className={bodyStyle + " " + props.className}>
        {props.children}
    </div>
);

export default TabPanelBody;