import React from "react";
import ConversationTurn from "../../../models/sessionAttributes/ConversationTurn";
import { color_shades_dark, color_shades_darker, text_blue, color_colors_light_ocean } from "../../../constants/colors";
import { getFeatureTypeIcon, getEditUrl } from "../../../models/extensions";
import TooltipWrapper from "../../../components/general/TooltipWrapper";
import SimulatorHistoryContentItem from "../../../models/features/api/SimulatorHistoryContentItem";
import { useState } from "react";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import CodeOutputModal from "./CodeOutputModal";
import styled from "@emotion/styled";
import { css } from "@emotion/css";
import VerticalSeparator from "../../../components/structure/VerticalSeparator";
import CustomAssistantRequest from "../../../models/customAssistant/CustomAssistantRequest";
import CustomAssistantResponse from "../../../models/customAssistant/CustomAssistantResponse";
import ContentHitEvent from "../../../models/analytics/api/ContentHitEvent";
const expandIcon = require('../../../content/images/expand-blue.svg');
const expandGrey = require('../../../content/images/expand-grey.svg');
const closed = require('../../../content/images/simulator/closed.svg');
const open = require('../../../content/images/simulator/open.svg');
const investigateIcon = require('../../../content/images/simulator/bubble-search.svg');
const selectedInvestigateIcon = require('../../../content/images/simulator/bubble-search-blue.svg');
const codeIcon = require('../../../content/images/simulator/code-search.svg');
const codeIconSearch = require('../../../content/images/simulator/code-search-blue.svg');
const copyIcon = require('../../../content/images/organization-settings/copy-blue.svg');
const codeExpandIcon = require('../../../content/images/simulator/code-output-expand.svg');

interface ConversationHistoryItemProps {
    turn: ConversationTurn
    content?: SimulatorHistoryContentItem
}

const SimulatorConversationHistoryItem: React.FC<ConversationHistoryItemProps> = ({ turn, content }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [investigate, setInvestigate] = useState<boolean>(true);
    const [showCodeModal, setShowCodeModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalContent, setModalContent] = useState<CustomAssistantRequest | CustomAssistantResponse>();

    if (!content) return null;

    const showModal = (title, content) => {
        setShowCodeModal(true);
        setModalTitle(title);
        setModalContent(content);
    }

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    return (
        <div className={expanded ? ExpandedDiv : ClosedDiv}>
            <div className={containerStyle} onClick={() => setExpanded(!expanded)}>
                <div className="left">
                    <OpenCloseIcon src={expanded ? open : closed} />
                    {turn ? <FeatureTypeIcon src={getFeatureTypeIcon(turn?.FeatureTypeId)} /> : < EmptyIconSpace />}
                    <p>{content?.title}</p>
                    {(content.hasBeenDeleted || !turn) ?
                        <TooltipWrapper place="left" text={!turn ? "Voicify returned default fallback" : "This item is no longer available"}>
                            <DisabledLinkOut>
                                <img src={expandGrey} />
                            </DisabledLinkOut>
                        </TooltipWrapper>
                        :
                        <TooltipWrapper place="left" text={content.isDraft ? "Open content item" : "Open draft version of this content item"}>
                            <LinkOut
                                href={getEditUrl(content.contentId, content.applicationFeatureId, turn?.FeatureTypeId)}
                                target="__blank">
                                <img src={expandIcon} />
                            </LinkOut>
                        </TooltipWrapper>
                    }
                </div>
            </div>
            {expanded &&
                <>
                    <CardHorizontalSeparator>
                        <HorizontalSeparator />
                    </CardHorizontalSeparator>
                    <ExpandedContentDiv>
                        <ContentDiv>
                            <LabelText> We think you said: </LabelText>
                            <ContentText>{`"${content?.response?.nlp?.utterance ?? content.request?.context?.originalInput}"`}</ContentText>
                            <HorizontalSeparator />
                            <InvestigateCodeDiv>
                                <LeftCodeInvestigateDiv className={investigate ? SelectedDataDiv : NonSelectedDataDiv} onClick={() => setInvestigate(true)}>
                                    <InvestigateText className={investigate ? InvestigateTextSelected : null}><CodeInvestigateIcon src={investigate ? selectedInvestigateIcon : investigateIcon} />Investigate</InvestigateText>
                                </LeftCodeInvestigateDiv>
                                <CustomVerticalSeparator fit />
                                <CodeInvestigateDiv className={!investigate ? SelectedDataDiv : NonSelectedDataDiv} onClick={() => setInvestigate(false)}>
                                    <InvestigateText className={!investigate ? InvestigateTextSelected : null}><CodeInvestigateIcon src={investigate ? codeIcon : codeIconSearch} />Code Output</InvestigateText>
                                </CodeInvestigateDiv>
                            </InvestigateCodeDiv>
                            <HorizontalSeparator />
                            <br />
                            {investigate ?
                                <>
                                    {content.response?.nlp?.intent != null &&
                                        <>
                                            <LabelText>Intent</LabelText>
                                            <ContentText>{content.response?.nlp?.intent?.name}</ContentText>
                                        </>
                                    }
                                    {content.interactionType != null &&
                                        <>
                                            <LabelText>Interaction Type</LabelText>
                                            <ContentText>{content.interactionType}</ContentText>
                                        </>
                                    }
                                    {(content.response?.nlp?.extractedEntities != null && content.response?.nlp?.extractedEntities.length > 0) &&
                                        <>
                                            <LabelText>Entities</LabelText>
                                            <CustomScrollbars autoHide autoHeight autoHeightMax={100}>
                                                {content.response?.nlp?.extractedEntities?.map((entity, index) =>
                                                    <SlotEntityDiv key={"EKey " + index}>
                                                        {`${entity.name} -\u00A0`} <KVPValue>{entity.value ?? entity.utteranceText}</KVPValue>
                                                    </SlotEntityDiv>
                                                )}
                                            </CustomScrollbars>
                                        </>
                                    }
                                    {!!(content.response?.nlp?.slotDetails?.length) &&
                                        <>
                                            <br />
                                            <LabelText>Slots</LabelText>
                                            <CustomScrollbars autoHide autoHeight autoHeightMax={100}>
                                                {content.response?.nlp?.slotDetails?.map((slotDetail, index) =>
                                                    <SlotEntityDiv key={"SKey " + index}>
                                                        {`${slotDetail.slotName} -\u00A0`} <KVPValue>{slotDetail.value ?? slotDetail.utteranceText}</KVPValue>
                                                    </SlotEntityDiv>
                                                )}
                                            </CustomScrollbars>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <CodeOutputContainerDiv>
                                        <CodeOutputDiv>
                                            REQUEST
                                            <ImgSpan>
                                                <CodeOutputImg src={copyIcon} onClick={() => copyText(JSON.stringify(content.request, null, 2))} />
                                                <CodeOutputImg src={codeExpandIcon} onClick={() => showModal("Request", content.request)} />
                                            </ImgSpan>
                                        </CodeOutputDiv>
                                    </CodeOutputContainerDiv>
                                    <br />
                                    <CodeDiv>
                                        <CustomScrollbars autoHide autoHeight autoHeightMax={140}>
                                            <pre>
                                                {JSON.stringify(content.request, null, 2)}
                                            </pre>
                                        </CustomScrollbars>
                                    </CodeDiv>
                                    <br />
                                    <CodeOutputContainerDiv>
                                        <CodeOutputDiv>
                                            RESPONSE
                                            <ImgSpan>
                                                <CodeOutputImg src={copyIcon} onClick={() => copyText(JSON.stringify(content.response, null, 2))} />
                                                <CodeOutputImg src={codeExpandIcon} onClick={() => showModal("Response", content.response)} />
                                            </ImgSpan>
                                        </CodeOutputDiv>
                                    </CodeOutputContainerDiv>
                                    <br />
                                    <CodeDiv>
                                        <CustomScrollbars autoHide autoHeight autoHeightMax={140}>
                                            <pre>
                                                {JSON.stringify(content.response, null, 2)}
                                            </pre>
                                        </CustomScrollbars>
                                    </CodeDiv>
                                </>
                            }
                        </ContentDiv>
                    </ExpandedContentDiv>
                </>
            }
            <CodeOutputModal
                data={modalContent}
                title={modalTitle}
                closeModal={() => setShowCodeModal(false)}
                showCodeOutputModal={showCodeModal}
            />
        </div>
    )
};

const KVPValue = styled.p`
    font-style: italic;
`;

const EmptyIconSpace = styled.div`
    width: 8px;
`;

const DisabledLinkOut = styled.div`
    img {
        width: 32px;
        height: 32px;
    }
    margin-right: 16px;
    margin-left: auto;   
    cursor: default;
    
`;

const CodeDiv = styled.div`
    max-height: 140px;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    overflow: hidden;
`;

const CodeOutputContainerDiv = styled.div`
    display: grid;
    grid-template-columns: auto;
`;

const CodeOutputDiv = styled.div`
    grid-row: 1 / 1;
    display: flex;       
`;

const CodeOutputImg = styled.img`
    margin-left: 8px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    vertical-align: middle;
`;

const ImgSpan = styled.span`
    margin-left: auto;
    align-items: center;
    cursor: pointer;
`;

const OpenCloseIcon = styled.img`
    width: 8px;
    height: 8px;
    margin-left: 8px;
`;

const FeatureTypeIcon = styled.img`
    height: 32px;
    width: 32px;
    margin-left: 16px;
    margin-right: 12px;
`;

const LinkOut = styled.a`
    img {
        width: 32px;
        height: 32px;
    }
    margin-right: 16px;
    margin-left: auto;
    cursor: pointer;    
`;

const ExpandedDiv = css`
    width: 100%;
    max-height: 656px;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    background: white;
    margin-bottom: 24px;
`

const ClosedDiv = css`
    width: 100%;
    height: 56px;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    background: white;
    margin-bottom: 24px;
`

const ExpandedContentDiv = styled.div`
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    margin: 24px;
    max-height: 552px;
`

const ContentDiv = styled.div`
    margin: 16px;
`

const LabelText = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: ${color_shades_darker}
`

const ContentText = styled.p`
    margin-top: 8px;
    margin-bottom: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
`
const SelectedDataDiv = css`
    border: 1px solid ${color_colors_light_ocean};
    border-radius: 8px;
    background-color: ${color_colors_light_ocean};
    display: flex;
    cursor: pointer;
    padding-bottom: 2px;
`

const NonSelectedDataDiv = css`
    display: flex;
    cursor: pointer;
    padding-bottom: 2px;
`

const CodeInvestigateDiv = styled.div`
    width: 50%;
    margin: 8px 4px 8px 8px;
`

const LeftCodeInvestigateDiv = styled(CodeInvestigateDiv)`
    margin: 8px 8px 8px 4px;
`

const CustomVerticalSeparator = styled(VerticalSeparator)`
    margin: 8px 0px;
`;

const InvestigateCodeDiv = styled.div`
    display: flex;
    width: 100%;
`

const SlotEntityDiv = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
`

const InvestigateText = styled.p`
    vertical-align: middle;
    padding: 0px 8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4px;
`

const InvestigateTextSelected = css`
    color: ${text_blue}
    padding: 0px 8px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    margin-top: 4px;
`

const CodeInvestigateIcon = styled.img`
    margin-right: 8px;
    vertical-align: middle;
`

const CardHorizontalSeparator = styled.div`
    margin-right: 24px;
    margin-left: 24px;
`

const containerStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    cursor: pointer;
    .left {
        display:flex;
        width: 100%;
        align-items:center;
        justify-content: flex-start;
        p {
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: break-word;
            overflow: hidden;
        }
    }
`;

export default SimulatorConversationHistoryItem;