import React from 'react';
import { css } from '@emotion/css';
import AnalyticsInterval, { analyticsIntervalTypes } from '../../models/analytics/AnalyticsInterval';
import { color_text_link, color_variants_ocean_light_1 } from '../../constants/colors';

interface PickerProps {
    interval: AnalyticsInterval
    onChange: (interval: AnalyticsInterval) => void
}

const TimeIntervalPicker: React.FC<PickerProps> = (props) => {
    return (
        <div className={`${containerStyle} ac-analytics-common-intervals`}>
            {analyticsIntervalTypes.map((a, i) => (
                <button className={`interval-item ${a == props.interval ? 'active' : 'inactive'}`} type="button" onClick={() => props.onChange(a)} key={i}>
                    <p>{a}</p>
                    <span className="active-indicator" />
                </button>
            ))}
        </div>
    );
}

const containerStyle = css`
    display: flex;
    flex-direction: column;
    max-width: 340px;
    width: 340px;
    margin: 24px 0;
    flex: 1;
    button {
        background: transparent;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        border: none;
        text-transform: capitalize;
        cursor: pointer;

        .active-indicator {
            display: none;
        }

        &.active {
            background: ${color_variants_ocean_light_1};
            .active-indicator {
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 0;
                margin-left: auto;
                background: ${color_text_link};
            }
        }
    }
`;

export default TimeIntervalPicker;