import React from 'react';
import { css } from '@emotion/css';

interface FormFieldsProps {
    className?: string
}

const FormFieldsContainer: React.FC<FormFieldsProps> = (props) => (
    <div className={containerStyle + " " + props.className}>
        {props.children}
    </div>
);

const containerStyle = css`
    margin-top: 24px;
    width: 100%;
`;

export default FormFieldsContainer;