import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import { isAbsolute } from 'path';
import AuthenticationResponse from '../../models/auth/api/AuthenticationResponse';
import { VOICIFY_ACCESS_TOKEN_STORE, VOICIFY_REFRESH_TOKEN_STORE, VOICIFY_EMAIL_STORE, VOICIFY_ACCESS_EXPIRATION } from '../../constants/Keys';
import IResult from '../../models/result/IResult';
import SingleSignOnResponse from '../../models/auth/api/SingleSignOnResponse';
import SingleSignOnRequest from '../../models/auth/api/SingleSignOnRequest';

type AuthState = {
    isAuthenticated: boolean
    accessToken: string
    refreshToken: string
    expirationDate: Date
    errors: string[]
    isLoading: boolean
}

export default class AuthContainer extends Container<AuthState> {
    state = this.getInitialState()
    timerTaskId;

    getInitialState(): AuthState {
        // check local storage for initial state
        localStorage.removeItem(VOICIFY_ACCESS_TOKEN_STORE); // remove any history of storing access in local storage
        const storedAccessToken = sessionStorage.getItem(VOICIFY_ACCESS_TOKEN_STORE);
        const storedRefreshToken = localStorage.getItem(VOICIFY_REFRESH_TOKEN_STORE);
        const storedExpiration = localStorage.getItem(VOICIFY_ACCESS_EXPIRATION);
        if (storedRefreshToken !== null) {
            return {
                isAuthenticated: true,
                accessToken: storedAccessToken,
                refreshToken: storedRefreshToken,
                expirationDate: new Date(storedExpiration),
                errors: [],
                isLoading: false
            }
        }

        return {
            isAuthenticated: false,
            accessToken: '',
            refreshToken: '',
            errors: [],
            expirationDate: null,
            isLoading: false
        }
    }

    beginSingleSignOnSignIn(ssoRequest: SingleSignOnRequest): Promise<IResult<SingleSignOnResponse>> {
        this.setState({
            ...this.state,
            isLoading: true
        })
        return this.beginSingleSignOn(voicifyApi.beginSingleSignOnSignIn(ssoRequest));
    }

    beginSingleSignOnSignUp(ssoRequest: SingleSignOnRequest): Promise<IResult<SingleSignOnResponse>> {
        this.setState({
            ...this.state,
            isLoading: true
        })
        return this.beginSingleSignOn(voicifyApi.beginSingleSignOnSignUp(ssoRequest));
    }


    beginSingleSignOn(promise: Promise<IResult<SingleSignOnResponse>>): Promise<IResult<SingleSignOnResponse>> {
        promise.then((ssoResult) => {
            if (ssoResult.resultType == "Ok") {
                window.location.href = ssoResult.data.redirectUrl;
            }
            else {
                this.setState({
                    ...this.state,
                    errors: ssoResult.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [...error],
                isLoading: false
            });

        });
        return promise;
    }

    updateLocalStorageAuth(email: string, authResult: AuthenticationResponse) {
        sessionStorage.setItem(VOICIFY_ACCESS_TOKEN_STORE, authResult.accessToken);
        localStorage.setItem(VOICIFY_REFRESH_TOKEN_STORE, authResult.refreshToken);
        localStorage.setItem(VOICIFY_EMAIL_STORE, email);
        localStorage.setItem(VOICIFY_ACCESS_EXPIRATION, authResult.accessTokenExpirationDate);
        this.setRefreshTimer();
    }

    signInWithSingleSignOn(email: string, refreshToken: string): Promise<IResult<AuthenticationResponse>> {
        this.setState({
            ...this.state,
            isLoading: true
        })
        var promise =
            voicifyApi.refreshToken({
                email,
                refreshToken
            });
        promise.then((authResult) => {

            if (authResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isAuthenticated: true,
                    accessToken: authResult.data.accessToken,
                    refreshToken: authResult.data.refreshToken,
                    expirationDate: new Date(authResult.data.accessTokenExpirationDate),
                    errors: [],
                    isLoading: false
                });
                this.updateLocalStorageAuth(email, authResult.data);
            }
            else {
                this.setState({
                    ...this.state,
                    errors: authResult.errors,
                    isLoading: false
                });
                this.signOut();
                window.location.href = `${window.location.origin.toString()}/login`;
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [...error],
                isLoading: false
            });

        });
        return promise;
    }

    addError(error: string) {
        this.setState({
            ...this.state,
            errors: [...this.state.errors, error],
        });
    }

    signIn(email: string, password: string): Promise<IResult<AuthenticationResponse>> {
        this.setState({
            ...this.state,
            isLoading: true
        })
        var promise = voicifyApi.signIn({
            email,
            password
        });
        promise.then((authResult) => {

            if (authResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isAuthenticated: true,
                    accessToken: authResult.data.accessToken,
                    refreshToken: authResult.data.refreshToken,
                    expirationDate: new Date(authResult.data.accessTokenExpirationDate),
                    errors: [],
                    isLoading: false
                });
                this.updateLocalStorageAuth(email, authResult.data);
            }
            else {
                this.setState({
                    ...this.state,
                    errors: authResult.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [...error],
                isLoading: false
            });

        });
        return promise;
    }
    setRefreshTimer() {
        clearInterval(this.timerTaskId);
        this.timerTaskId = setInterval(this.refreshToken.bind(this), 1740000);
    }
    removeRefreshTimer() {
        clearInterval(this.timerTaskId);
    }
    refreshToken(): Promise<IResult<AuthenticationResponse>> {
        var email = localStorage.getItem(VOICIFY_EMAIL_STORE);
        var refreshToken = localStorage.getItem(VOICIFY_REFRESH_TOKEN_STORE);
        this.setState({
            ...this.state,
            isLoading: true
        })
        var promise =
            voicifyApi.refreshToken({
                email,
                refreshToken
            });
        promise.then((authResult) => {

            if (authResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isAuthenticated: true,
                    accessToken: authResult.data.accessToken,
                    refreshToken: authResult.data.refreshToken,
                    expirationDate: new Date(authResult.data.accessTokenExpirationDate),
                    errors: [],
                    isLoading: false
                });
                this.updateLocalStorageAuth(email, authResult.data);
            }
            else {
                this.setState({
                    ...this.state,
                    errors: authResult.errors,
                    isLoading: false
                });
                this.signOut();
                window.location.href = `${window.location.origin.toString()}/login`;
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [...error],
                isLoading: false
            });

        });
        return promise;
    }

    signOut() {
        localStorage.clear();
        this.setState({
            ...this.state,
            isAuthenticated: false,
            accessToken: '',
            refreshToken: '',
            errors: []
        })
    }
}