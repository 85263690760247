import React from 'react';
import FilterSelector from './FilterSelector';
import FilterOption from './FilterOption';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
const deviceTypeIcon = require('../../../content/images/analytics-icons/device-type.svg');
const customBotIcon = require("../../../content/images/platforms/custom-chat.svg");
interface DeviceFilterProps {
    deviceIds: string[]
    devices: DeviceTargetModel[]
    onChange: (deviceIds: string[]) => void
}

class DeviceTypeFilter extends React.Component<DeviceFilterProps> {
    handleToggleDeviceType(deviceTypeId: string) {
        var deviceTypeIds = this.props.deviceIds ?? []
        if (deviceTypeIds.some(l => l == deviceTypeId)) {
            deviceTypeIds.splice(deviceTypeIds.indexOf(deviceTypeId), 1);
        }
        else {
            deviceTypeIds.push(deviceTypeId);
        }

        this.props.onChange(deviceTypeIds);
    }
    handleClear() {
        this.props.onChange(null);
    }

    render() {
        const props = this.props;
        const selectedText = props.devices.filter(f => props.deviceIds?.some(id => id == f.id))?.map(f => f.displayName)?.join(', ');
        return (
            <FilterSelector label="Device Type"
                icon={deviceTypeIcon}
                placeholder={'All Device Types'}
                selectedText={selectedText}
                className="ac-analytics-device-filter"
                onClear={this.handleClear.bind(this)}>
                <CustomScrollbars autoHide autoHeight autoHeightMax={400}>
                    <FilterOption title="- Select All / None -"
                        onClick={() => props.onChange([])}
                        isSelected={!props.deviceIds}
                        isPartialSelected={true}
                        optionType="check" />
                    {props.devices.map(f => (
                        <FilterOption key={f.id} title={f.displayName}
                            icon={f.iconUrl ?? customBotIcon}
                            selectedIcon={f.iconUrl ?? customBotIcon}
                            isSelected={props.deviceIds?.some(id => id == f.id)}
                            optionType="check"
                            onClick={() => this.handleToggleDeviceType(f.id)} />
                    ))}
                </CustomScrollbars>
            </FilterSelector>
        )
    }
}

export default DeviceTypeFilter;

