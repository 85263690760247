import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { TabPanel } from 'react-tabs';
import { css } from '@emotion/css';
import OrganizationContainer from '../../../../../shared/state/containers/OrganizationContainer';
import UserContainer from '../../../../../shared/state/containers/UserContainer';
import MemberContainer from '../../../../../shared/state/containers/MemberContainer';
import TabContainerPanel from '../../../../../shared/components/structure/Panels/TabContainerPanel';
import ScrollablePanelBody from '../../../../../shared/components/structure/ScrollablePanelBody';
import OrganizationInfoSettings from '../../../../../shared/scenes/organizationSettings/components/OrganizationInfoSettings';
import OrganizationApplications from './OrganizationApplications';
import ApplicationModel from '../../../../../shared/models/applications/api/ApplicationModel';
import TemplateFormSectionModel from '../../../../../shared/models/templating/api/TemplateFormSectionModel';
import TemplateFormFieldModel from '../../../../../shared/models/templating/api/TemplateFormFieldModel';
import ApplicationContainer from '../../../../../shared/state/containers/ApplicationContainer';
import { ImageTabItem } from '../../../../../shared/components/structure/ImageInnerTabs';
import TemplateConfigurationContainer from '../../../../../shared/state/containers/TemplateConfigurationContainer';
import AppliedApplicationTemplateContainer from '../../../../../shared/state/containers/AppliedApplicationTemplateContainer';

const basicInfoBlue = require('../../../../../shared/content/images/organization-settings/basic-info-blue.svg');
const basicInfoDark = require('../../../../../shared/content/images/organization-settings/basic-info-dark.svg');
const locationsBlue = require("../../../../../shared/content/images/spark/locations-blue.svg");
const locationsDark = require("../../../../../shared/content/images/spark/locations-dark.svg");

interface OrganizationSettingsProps {
    appContainer: ApplicationContainer;
    orgContainer: OrganizationContainer;
    userContainer: UserContainer;
    memberContainer: MemberContainer;
    commonApp: ApplicationModel;
    locationApps?: ApplicationModel[];
    locationAppNames: string[];
    commonAppSections: TemplateFormSectionModel[];
    locationAppFormFields: TemplateFormFieldModel[];
    isLoadingTemplates: boolean;
    history: any;
    hideApplicationSettings?: boolean;
    padding?: string;
    templateConfigurationContainer: TemplateConfigurationContainer;
}

const OrganizationSettingsTabs: React.FC<OrganizationSettingsProps> = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        props.userContainer.getUser();
        props.memberContainer.checkIsOrgAdmin(props.orgContainer.state.currentOrganization?.id, props.userContainer.state.currentUser?.id);
    }, []);

    const handleTabSelection = (index: number, last: number) => {
        if (index === last) return;
        setActiveIndex(index);
    };

    const renderTabs = () => {
        let tabs: ImageTabItem[] = [];

        if (!props.hideApplicationSettings) {
            tabs = [
                {
                    title: 'Basic Info',
                    icon: basicInfoDark,
                    selectedIcon: basicInfoBlue
                },
                {
                    title: 'Locations',
                    icon: locationsDark,
                    selectedIcon: locationsBlue
                }
            ];
        } else {
            tabs = [
                {
                    title: 'Basic Info',
                    icon: basicInfoDark,
                    selectedIcon: basicInfoBlue
                }
            ];
        }

        return tabs;
    };

    return (
        <TabContainerPanel
            selectedIndex={activeIndex}
            onTabSelected={handleTabSelection}
            tabs={renderTabs()}
        >
            {
                renderTabs().length === 1 ?
                    <TabPanel>
                        <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                            <OrganizationInfoSettings
                                orgContainer={props.orgContainer}
                                templateConfigurationContainer={props.templateConfigurationContainer}
                                commonApp={props.commonApp}
                                appContainer={props.appContainer}
                            />
                        </ScrollablePanelBody>
                    </TabPanel>
                    :
                    <>
                        <TabPanel>
                            <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                                <OrganizationInfoSettings
                                    orgContainer={props.orgContainer}
                                    templateConfigurationContainer={props.templateConfigurationContainer}
                                    commonApp={props.commonApp}
                                    appContainer={props.appContainer}
                                />
                            </ScrollablePanelBody>
                        </TabPanel>
                        <TabPanel>
                            <ScrollablePanelBody className={paddedBody} autoHeight={true} autoHeightMax={"calc(100vh - 136px)"}>
                                <OrganizationApplications
                                    appContainer={props.appContainer}
                                    orgContainer={props.orgContainer}
                                    commonApp={props.commonApp}
                                    locationApps={props.locationApps}
                                    locationAppNames={props.locationAppNames}
                                    commonAppSections={props.commonAppSections}
                                    locationAppFormFields={props.locationAppFormFields}
                                    isLoadingTemplates={props.isLoadingTemplates}
                                    history={props.history}
                                />
                            </ScrollablePanelBody>
                        </TabPanel>
                    </>
            }

        </TabContainerPanel>
    );
};

const paddedBody = css`
    padding: 32px;
`;

export default OrganizationSettingsTabs;
