import React, { useEffect, useState } from "react";
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';
import { pale_grey, color_shades_dark } from '../../constants/colors';
import Button from '../../components/general/Button';
import TemplateFormFieldModel from '../../models/templating/api/TemplateFormFieldModel';
import MediaContainer from '../../state/containers/MediaContainer';
import PageError from '../general/PageError';

const cloudIcon = require('../../content/images/cloud-upload-copy.svg');
const closeIcon = require('../../content/images/close_icon.svg')

interface FieldUploadFieldProps {
    onChange: (e: any) => void
    disabled: boolean
    value: string
    mediaContainer: MediaContainer
    applicationId: string
    preview: boolean
    field?: TemplateFormFieldModel
    fileFormats?: string
}

const FieldUploadField: React.FC<FieldUploadFieldProps> = (props) => {

    const [file, setFile] = useState<string>(null);
    const [errors, setErrors] = useState<string[]>(null);

    const clearFile = () => {
        setFile(null);
        props.onChange({ url: null })
    }

    useEffect(() => {
        if (props.value != null) {
            //parses name from s3 file location url
            setFile(props.value.substring(props.value.lastIndexOf("/") + 1, props.value.length));
        }
    }, [])

    async function handleFilePicked(files: File[]) {
        if (files.length > 0) {
            setFile(files[0].name)
            let result = await props.mediaContainer.uploadMediaItem(props.applicationId, files[0].name, files[0], '', () => { });
            if (!result.errors.length) {
                props.onChange(result.data);
                setErrors(null);
            }
            else {
                setErrors(result.errors)
            }

        }
    };

    return (
        <div className={`${containerStyle} ${props.disabled ? 'disabled' : ''}`}>
            <FileSizeInfoDiv>
                <SubLabel>File under 10 MB</SubLabel>
            </FileSizeInfoDiv>
            <div>
                <Dropzone
                    onDrop={handleFilePicked.bind(this)}
                    className={fieldContainer}
                    accept={props.field?.fileFormats ? props.field.fileFormats : props.fileFormats}
                    disabled={props.preview}
                >
                    <ImgContainer>
                        <img src={cloudIcon} />
                    </ImgContainer>
                    <TextContainer>
                        <p>Drag a file here</p>
                    </TextContainer>
                    <OrLabel>
                        <p>or</p>
                    </OrLabel>
                    <ButtonStyleContainer>
                        <Button className={buttonStyle}
                            themes={["primary-small", "start"]}
                            text="Select Local File"
                            type="button"
                            disabled={props.preview}
                        />
                    </ButtonStyleContainer>
                </Dropzone>
            </div>
            {props.field?.fileFormats &&
                <SubLabelContainer>
                    <SubLabel>File formats supported: {props.field.fileFormats} </SubLabel>
                </SubLabelContainer>
            }
            {props.fileFormats &&
                <SubLabelContainer>
                    <SubLabel>File formats supported: {props.fileFormats} </SubLabel>
                </SubLabelContainer>
            }
            {props.field?.suggestions &&
                <SubLabelContainer>
                    <SubLabel>Suggestions: {props.field.suggestions}</SubLabel>
                </SubLabelContainer>
            }
            {file &&
                <SubLabelContainer >
                    <label>Current file selected: {file}</label>
                    <CloseIcon src={closeIcon} onClick={() => clearFile()} />
                </SubLabelContainer>
            }
            <PageError errors={errors} />
        </div>
    )
}

const containerStyle = css`
    margin-bottom: 24px;
    flex: 1;
    &.tight {
        margin-bottom: 16px;
        label {
            margin: 8px 16px 0 16px;
        }
    }
`

const CloseIcon = styled.img`
    height: 14px;
    width: 14px;
    margin-left: 16px;
    cursor: pointer;
    vertical-align: middle;
`

const FileSizeInfoDiv = styled.div`
    width: 100%;
    display: flex;
`

const ButtonStyleContainer = styled.div`
    justify-content: center;
    display: flex;
    width: 100%;
    grid-row: 4 / span 1;
`

const buttonStyle = css`
    margin-right:auto;
    margin-left:auto;
`
const SubLabel = styled.label`
    font-size: 12px;
    font-weight: 100;
`

const OrLabel = styled.div`
    font-size: 12px;
    font-weight: 100;
    justify-content: center;
    display: flex;
    width: 100%;
    grid-row: 3 / span 1;
`

const SubLabelContainer = styled.div`
    padding-top: 10px
`

const fieldContainer = css`
    display: flex;
    align-items: center;
    height: 280px;
    width: 100%;
    display: grid;
    grid-template-rows: 160px 20px 20px 50px;
    border-radius: 8px;
    border: dashed 4px ${color_shades_dark}; 
    background: white;
    margin-top: 10px;
    img {
        margin: 16px;
    }
    &.disabled {
        background: ${pale_grey};
        input {
            background: ${pale_grey};
        }
    }
`
const ImgContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-row: 1 / span 1;
`

const TextContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    grid-row: 2 / span 1;
`

export default FieldUploadField;