import IResult from "../../models/result/IResult";
import IntegrationListing from "../../models/integrations/IntegrationListing";
import { sendGet, sendPost, sendDelete } from "./generic";
import { getIntegrationsApiUrl } from "../../constants/Urls";
import CreateIntegrationListingRequest from "../../models/integrations/api/CreateIntegrationListingRequest";

export const getIntegrationsListings = (organizationId : string) : Promise<IResult<IntegrationListing[]>> => {
    return sendGet<IntegrationListing[]>(`/integrations/listings/${organizationId}`, getIntegrationsApiUrl());
}

export const addIntegrationListing = (organizationId : string, request : CreateIntegrationListingRequest) : Promise<IResult<string>> => {
    return sendPost(`/integrations/listings/${organizationId}`, request, getIntegrationsApiUrl());
}

export const removeIntegrationListing = (integrationListingId : string) : Promise<IResult<string>> => {
    return sendDelete(`/integrations/listings/${integrationListingId}`, getIntegrationsApiUrl());
}