import React from 'react';
import { css } from '@emotion/css';
import MediaItemModel from '../../models/media/MediaItemModel';
import MediaResponseStateContainer from '../../state/containers/MediaResponseContainer';
import MediaContainer from '../../state/containers/MediaContainer';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import UserVariationsBubble from './UserVariationsBubble';
import DeviceTargetModel from '../../models/features/api/MediaResponses/DeviceTargetModel';
import DeviceChatVariationsBubble from './DeviceChatVariationBubble';
import ConversationBranchSwitcher from '../structure/ConversationFlow/ConversationBranchSwitcher';
import LanguageModel from '../../models/features/api/LanguageModel';

interface ChatConversationFlowViewerProps {
    imageItem?: MediaItemModel
    audioItem?: MediaItemModel
    videoItem?: MediaItemModel
    backgroundImageItem?: MediaItemModel
    displayTitleOverride?: string
    displayTextOverride?: string
    applicationId: string
    mediaContainer: MediaContainer
    responseContainer: MediaResponseStateContainer
    conversationItems: ConversationItem[]
    targets: DeviceTargetModel[]
    endSession: boolean
    emptyLabel?: string
    noHighlights?: boolean
}

interface ChatConversationFlowViewerState {
    deviceTarget: DeviceTargetModel
}


const containerStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

`



class ChatConversationFlowViewer extends React.Component<ChatConversationFlowViewerProps, ChatConversationFlowViewerState> {
    componentWillMount() {
        this.setState({
            deviceTarget: this.props.targets ? this.props.targets[0] : null
        })
    }
    handleTargetChange(target: DeviceTargetModel) {
        this.setState({
            ...this.state,
            deviceTarget: target
        })
    }
    renderBlock(conversationItem: ConversationItem, index: number) {
        return (
            <div key={index}>
                <UserVariationsBubble title={conversationItem.requestTitle}
                    variations={conversationItem.requests}
                    noHighlights={this.props.noHighlights} />
                <DeviceChatVariationsBubble
                    title={conversationItem.responseTitle}
                    variations={conversationItem.responses}
                    reprompt={conversationItem.reprompt}
                    followUp={conversationItem.followUp}
                    deviceTargetId={this.props.responseContainer?.state?.deviceTargets?.find(d => !d.supportsSsml && d.supportsDisplayText)?.id}
                    assistant={'chat'}
                    noHighlights={this.props.noHighlights} />
                {conversationItem.children && conversationItem.children.length > 0 ? this.renderBranch(conversationItem.children) : null}
            </div>
        )
    }
    renderBranch(conversationItems: ConversationItem[]) {
        if (conversationItems && conversationItems.length > 1) {
            return (
                <ConversationBranchSwitcher position={0}>
                    {conversationItems.map((c, i) => (this.renderBlock(c, i)))}
                </ConversationBranchSwitcher>
            )
        }
        return conversationItems.map((c, i) => (this.renderBlock(c, i)))
    }
    render() {
        return (
            <div>
                <div className={containerStyle}>
                    {this.renderBranch(this.props.conversationItems)}
                    {this.props.emptyLabel ? <p className="empty-label-container">{this.props.emptyLabel}</p> : null}
                </div>
            </div>
        )
    }
}

export default ChatConversationFlowViewer;