import axios from 'axios';
import { GET_EXITMESSAGES, ADD_EXITMESSAGES } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import ExitMessageModel from '../../models/features/api/Exit/ExitMessageModel';
import NewExitMessageRequest from '../../models/features/api/Exit/NewExitMessageRequest';
import UpdateExitMessageRequest from '../../models/features/api/Exit/UpdateExitMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getExitMessagesForApp = (applicationId: string): Promise<IResult<ExitMessageModel[]>> => {
    return sendGet<ExitMessageModel[]>(`${GET_EXITMESSAGES}/${applicationId}`);
}

export const getExitMessagesForAppFeature = (applicationFeatureId: string): Promise<IResult<ExitMessageModel[]>> => {
    return sendGet<ExitMessageModel[]>(`${GET_EXITMESSAGES}/byFeature/${applicationFeatureId}`);
}


export const addFullExitMessage = (model: ExitMessageModel): Promise<IResult<ExitMessageModel>> => {
    return sendPost<ExitMessageModel>(`${ADD_EXITMESSAGES}/full`, model);
}

export const addExitMessage = (applicationId: string, model: NewExitMessageRequest): Promise<IResult<ExitMessageModel>> => {
    return sendPost<ExitMessageModel>(`${ADD_EXITMESSAGES}/${applicationId}`, model);
}

export const toggleExitMessageLive = (exitMessageId: string, isLive: boolean): Promise<IResult<ExitMessageModel>> => {
    return sendPut<ExitMessageModel>(`/ExitMessage/${exitMessageId}/isLive`, {
        isLive
    });
}

export const toggleExitMessageSync = (exitMessageId: string, shouldNotSync: boolean): Promise<IResult<ExitMessageModel>> => {
    return sendPut<ExitMessageModel>(`/ExitMessage/${exitMessageId}/shouldNotSync`, {
        shouldNotSync
    });
}
export const updateFullExitMessage = (exitMessageId: string, model: ExitMessageModel): Promise<IResult<ExitMessageModel>> => {
    return sendPut<ExitMessageModel>(`/ExitMessage/${exitMessageId}/full`, model);
}
export const updateExitMessage = (exitMessageId: string, model: UpdateExitMessageRequest): Promise<IResult<ExitMessageModel>> => {
    return sendPut<ExitMessageModel>(`/ExitMessage/${exitMessageId}/update`, model);
}

export const deleteExitMessage = (exitMessageId: string): Promise<IResult<ExitMessageModel>> => {
    return sendDelete<ExitMessageModel>(`/ExitMessage/${exitMessageId}`);
}
export const findExitMessage = (exitMessageId: string): Promise<IResult<ExitMessageModel>> => {
    return sendGet<ExitMessageModel>(`/ExitMessage/full/${exitMessageId}`);
}

export const getExitMessageWebhooks = (exitMessageId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/ExitMessage/${exitMessageId}/webhooks`);
}

export const removeExitMessageWebhook = (exitMessageWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/ExitMessage/webhook/${exitMessageWebhookId}`);
}

export const addExitMessageWebhook = (exitMessageId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/ExitMessage/${exitMessageId}/webhooks/${webhookId}`, { values: defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateExitMessageWebhook = (exitMessageId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/ExitMessage/${exitMessageId}/webhooks/${webhookInstanceId}`, { values: defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const copyExistMessage = (contentItemId: string): Promise<IResult<ExitMessageModel>> => {
    return sendPost<ExitMessageModel>(`/ExitMessage/${contentItemId}/copy`, null);
}

export const bulkUpdateExitMessageWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/ExitMessage/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getExitMessagePublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/ExitMessage/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}