import * as voicifyApi from '../../api';
import IResult, { ResultType } from '../../models/result/IResult';
import GenericMeasurementsContainer, { MeasurementState } from './GenericMeasurementsContainer';
import CardinalityAggregate from '../../models/analytics/api/CardinalityAggregate';
import BreakdownItem from '../../models/analytics/BreakdownItem';
import AverageItem from '../../models/analytics/Averageitem';
import AnalyticsScope from '../../models/analytics/AnalyticsScope';
import AnalyticsRequestFilter from '../../models/analytics/AnalyticsRequestFilter';

interface ApplicationAnalyticsState extends MeasurementState {
}

export default class ApplicationMissAnalyticsContainer extends GenericMeasurementsContainer<ApplicationAnalyticsState> {
    constructor() {
        super();
        this.state = {
            isLoadingHistogram: false,
            isLoadingBreakdown: false,
            currentBreakdown: this.breakdownOptions[0],
            currentDisplayOption: this.displayOptions[0],
            currentDeltaPeriod: 'Previous Period',
            currentDeltaType: 'Value'
        }
    }

    breakdownOptions = ['By Platform', 'By Interface', 'By Device Type', 'By Language']
    displayOptions = ['Missed Intents', 'Missed Interactions']
    getContextId = (scope: AnalyticsScope) => scope.applicationId 
    getPrimaryHistogramPromise = (scope, interval, filter: AnalyticsRequestFilter) => voicifyApi.getFeatureMissHistogramForApplication(scope.applicationId, interval, filter);
    getSecondaryHistogramPromise =(scope, interval,filter: AnalyticsRequestFilter) => voicifyApi.getContentMissHistogramForApplication(scope.applicationId, interval, filter);
    getAverages = async (scope: AnalyticsScope, filter: AnalyticsRequestFilter) => {
        const output = [] as AverageItem[];
        const previousDates = this.getPreviousDates(filter.startDate, filter.endDate);
        var primaryHistogram = this.state.primaryHistogramData?.find(h => this.buildHistogramKey(h) == this.buildCacheKey(scope.applicationId, filter));
        var secondaryHistogram = this.state.secondaryHistogramData?.find(h => this.buildHistogramKey(h) == this.buildCacheKey(scope.applicationId, filter));
        var previousPrimaryHistogram = this.state.primaryHistogramData?.find(h => this.buildHistogramKey(h) == this.buildCacheKey(scope.applicationId, { ...filter, startDate: previousDates.startDate, endDate: previousDates.endDate }));
        var previousSecondaryHistogram = this.state.secondaryHistogramData?.find(h => this.buildHistogramKey(h) == this.buildCacheKey(scope.applicationId, { ...filter, startDate: previousDates.startDate, endDate: previousDates.endDate }));
        var numberOfDays = this.getDaysBetween(filter.startDate, filter.endDate);
        var numberOfOcurredDays = this.getDaysBetween(filter.startDate, filter.endDate, true);

        if (primaryHistogram != null && previousPrimaryHistogram != null) {
            const total = primaryHistogram?.data?.map(h => h.count)?.reduce((a, b) => (a + b))
            const prevTotal = previousPrimaryHistogram?.data?.map(h => h.count)?.reduce((a, b) => (a + b))

            const value = (total ?? 0) / (numberOfOcurredDays ?? 1)
            const prevValue = (prevTotal ?? 0) / (numberOfDays ?? 1);
            const deltaValue = (value ?? 0) - (prevValue ?? 0)
            output.push({
                title: "missed intents per day",
                value: value !== Math.round(value) ? value.toFixed(2) : value,
                deltaPercentage: deltaValue / (value > 0 ? value : 1) * 100,
                deltaValue: deltaValue !== Math.round(deltaValue) ? deltaValue.toFixed(2) : deltaValue
            })
        }
        if (secondaryHistogram != null && previousSecondaryHistogram != null) {
            const total = secondaryHistogram.data?.map(h => h.count)?.reduce((a, b) => (a + b))
            const prevTotal = previousSecondaryHistogram?.data?.map(h => h.count)?.reduce((a, b) => (a + b))
            const value = (total ?? 0) / (numberOfOcurredDays ?? 1);
            const prevValue = (prevTotal ?? 0) / (numberOfDays ?? 1);
            const deltaValue = (value ?? 0) - (prevValue ?? 0)
            output.push({
                title: "missed interactions per day",
                value: value !== Math.round(value) ? value.toFixed(2) : value,
                deltaPercentage: deltaValue / (value > 0 ? value : 1) * 100,
                deltaValue: deltaValue !== Math.round(deltaValue) ? deltaValue.toFixed(2) : deltaValue
            })
        }
        const averages = [];
        averages.push({
            id: scope.applicationId,
            filter,
            items: output
        })
        this.setState({
            ...this.state,
            averages
        })

        return {
            resultType: "Ok" as ResultType,
            errors: [],
            data: output
        }
    }

    getPrimaryBreakdownPromise = async (scope: AnalyticsScope, filter: AnalyticsRequestFilter, breakdownType: string) => {
        const breakdownData: IResult<BreakdownItem[]> = {
            resultType: "Ok",
            data: [],
            errors: []
        }
        var aggregateData: IResult<CardinalityAggregate[]>
        switch (breakdownType) {
            case "By Platform": aggregateData = await voicifyApi.getFeatureMissAggregateByAssistant(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: a.key,
                    iconRender: () => this.getPlatformImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))

                break;
            case "By Interface": aggregateData = await voicifyApi.getFeatureMissAggregateByInterfaceType(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: a.key,
                    iconRender: () => this.getDeviceCapabilityImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))
                break;
            case "By Device Type": aggregateData = await voicifyApi.getFeatureMissAggregateByDeviceTarget(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: this.findDeviceType(a.key)?.displayName ?? "Unknown",
                    iconRender: () => this.getDeviceTypeImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))
                break;
            case "By Language": aggregateData = await voicifyApi.getFeatureMissAggregateByLanguage(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: this.getLanguageTitle(a.key),
                    iconRender: () => this.getLanguageImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))
                break;
        }
        return breakdownData;
    }
    getSecondaryBreakdownPromise = async (scope: AnalyticsScope, filter: AnalyticsRequestFilter, breakdownType: string) => {
        const breakdownData: IResult<BreakdownItem[]> = {
            resultType: "Ok",
            data: [],
            errors: []
        }
        var aggregateData: IResult<CardinalityAggregate[]>
        switch (breakdownType) {
            case "By Platform": aggregateData = await voicifyApi.getContentMissAggregateByAssistant(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: a.key,
                    iconRender: () => this.getPlatformImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))

                break;
            case "By Interface": aggregateData = await voicifyApi.getContentMissAggregateByInterfaceType(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: a.key,
                    iconRender: () => this.getDeviceCapabilityImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))
                break;
            case "By Device Type": aggregateData = await voicifyApi.getContentMissAggregateByDeviceTarget(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: this.findDeviceType(a.key)?.displayName ?? "Unknown",
                    iconRender: () => this.getDeviceTypeImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))
                break;
            case "By Language": aggregateData = await voicifyApi.getContentMissAggregateByLanguage(scope.applicationId, filter);
                if (aggregateData.resultType != "Ok")
                    return aggregateData;
                breakdownData.data = aggregateData.data.map((a, i, agg) => ({
                    label: a.key,
                    iconRender: () => this.getLanguageImage(a.key),
                    count: a.count,
                    percentage: this.getPercentage(a.count, agg)
                }))
                break;
        }
        return breakdownData;
    }
}