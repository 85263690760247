import React, { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import LanguageModel from '../models/features/api/LanguageModel';
import IResult from '../models/result/IResult';
import * as voicifyApi from '../api';
import NotificationsContainer from './NotificationsContainer';
import { Moment } from "moment";
import { VOICIFY_MENU_SYNC_STATE } from "../constants/Keys";
import { set } from 'lodash';
import moment from "moment";


interface MenuSyncingState {
    applicationId: string,
    processId: string,
    syncStart?: Moment
}

function useMenuSyncContainer() {
    const notificationContainer = NotificationsContainer.useContainer();
    const [syncingMenus, setSyncingMenus] = useState<MenuSyncingState[]>([]);
    const [counter, setCounter] = useState(0);
    const [isPolling, setIsPolling] = useState(false);

    useEffect(() => {
        const storedState = getStoredState();
        setSyncingMenus(storedState);
    }, []);

    useEffect(() => {
        asyncPoll();
    }, [counter]);
    
    const asyncPoll = async () => {
        // wait 3 seconds first
        await new Promise(resolve => setTimeout(resolve, 3000));
        const notifications = (await notificationContainer.getNotifications()).data;
        const newSyncingMenus: MenuSyncingState[] = [];
        for (const sync of syncingMenus) {
            if (moment().diff(sync.syncStart, 'minutes') > 15) {
                continue;
            }
            if (notifications.some(n => n?.markdown?.endsWith(sync.processId))) {
                continue;
            }
            newSyncingMenus.push(sync);
        }
        setSyncingMenus(newSyncingMenus);
        storeState(newSyncingMenus);
        if (!newSyncingMenus.length) {
            setIsPolling(false);             
        } else {
            setCounter(counter + 1);
        }
    }

    const getStoredState = (): MenuSyncingState[] => {
        let storedState: MenuSyncingState[] = [];
        const storedTrainingState = localStorage.getItem(VOICIFY_MENU_SYNC_STATE);
        if (storedTrainingState)
            storedState = JSON.parse(storedTrainingState);
        return storedState;
    }

    const storeState = (state: MenuSyncingState[]) => {
        const json = JSON.stringify(state);
        localStorage.setItem(VOICIFY_MENU_SYNC_STATE, json);
    }

    const isApplicationSyncing = (applicationId: string): boolean => {
        const sync = syncingMenus.find(s => s.applicationId == applicationId);
        if (!sync)
            return false;
        if (moment().diff(sync.syncStart, 'minutes') > 5) {
            const newSyncingMenus = syncingMenus.filter(s => s.applicationId !== applicationId);
            setSyncingMenus(newSyncingMenus);
            storeState(newSyncingMenus);
            return false;
        }
        return true;
    }

    const startMenuSync = async (applicationId: string, processId: string) => {
        const newArr = ([...syncingMenus, { applicationId, processId, syncStart: moment() }]);
        setSyncingMenus(newArr);
        storeState(newArr);
        if (!isPolling) {
            setCounter(counter + 1);
        }
    }

    return {
        isApplicationSyncing,
        startMenuSync,
        syncingMenus
    };
}

const MenuSyncContainer = createContainer(useMenuSyncContainer);
export default MenuSyncContainer;
