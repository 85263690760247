import React from "react";
import Axios from "axios";
import Loader from "../../components/general/Loader";
import * as voicifyApi from "../../api";
import { Subscribe } from "unstated";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import OrganizationModel from "../../models/organizations/api/OrganizationModel";
import OAuthResultRequest from "../../models/integrations/api/OAuthResultRequest";

const OAuthRedirector: React.SFC = () => (
    <Subscribe to={[OrganizationContainer]}>
        {(ctr: OrganizationContainer) => <OAuthRedirectorChild organizationContainer={ctr} />}
    </Subscribe>
)

class OAuthRedirectorChild extends React.Component<{ organizationContainer: OrganizationContainer }> {
    componentWillMount() {
        const search = window.location.search;
        const urlParams = new URLSearchParams(search);
        var oAuthUrl = urlParams.get("oAuthUrl");
        var code = urlParams.get("code");
        var state = urlParams.get("state");
        if (this.props.organizationContainer.state.currentOrganization != null)
            this.resolveOAuth(this.props.organizationContainer.state.currentOrganization, oAuthUrl, code, state, search)
        else {
            this.props.organizationContainer.getOrganizations()
                .then((res) => {
                    this.resolveOAuth(res.data[0], oAuthUrl, code, state, search);
                })
        }
    }
    resolveOAuth(organization: OrganizationModel, oAuthUrl: string, code: string, state: string, search: string) {
        var oAuthResult: OAuthResultRequest = { oAuthUrl, code, state };

        voicifyApi.sendIntegrationOauthResult(organization.id, oAuthResult, search)
            .then(res => {
                window.open(window.location.origin, "_self")
            })
            .catch(err => {
                console.log(err);
                window.open(window.location.origin, "_self")
            })
    }
    render() {
        return (<Loader />)
    }
}

export default OAuthRedirector;