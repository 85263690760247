import React from 'react';
import { css } from '@emotion/css';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import { dark_grey_blue, dark_grey_blue_20, cool_grey, dark_sky_blue, highlight_blue } from '../../constants/colors';
import { style_border_default } from '../../constants/stylesValues';
const deviceIcon = require('../../content/images/device-grey.svg');

const DeletedVariationBubble = () => (
    <div className={containerStyle}>
        <div className={bubbleStyle}>
            <>
                <span className="title">- Deleted - </span>
                <HorizontalSeparator />
            </>
            <div className="content-container">
                <p>[This content has been deleted]</p>
            </div>
        </div>
        <img src={deviceIcon} />
    </div>
);


const containerStyle = css`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
    }
`
const bubbleStyle = css`
    margin: 30px 0 0 80px;
    border: ${style_border_default};
    background: white;
    border-radius: 10px 0 10px 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media only screen and (min-width: 1240px) {
        max-width: 75%;
        margin-right: 0;
        margin-left: auto;
    }
    .title {
        font-family: Muli;
        font-size: 18px;
        font-style: italic;
        color: ${dark_grey_blue};
        margin-bottom: 24px;
    }
    .content-container {
        margin: 48px 48px;
    }
`;

export default DeletedVariationBubble;
