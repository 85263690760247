import React from 'react';
import { css } from '@emotion/css';
import LevelSelector from './LevelSelector';
import ssmlParseService from '../../../services/ssmlParseService';
import SsmlParseService from '../../../services/ssmlParseService';

interface EmphasisSelectorProps {
    onChange: (ssml: string, placeholder?: string) => void
    ssml?: string
}

interface EmphasisSelectorState {
    value: number
    isCustomSelected: boolean

}

class EmphasisSelector extends React.Component<EmphasisSelectorProps, EmphasisSelectorState> {
    private ssmlParser = new SsmlParseService()
    private placeholder = 'Emphasis changed text'
    private options = [
        { name: 'Reduced', value: 1 },
        { name: 'Moderate', value: 2 },
        { name: 'Strong', value: 3 },
    ];
    componentWillMount() {
        this.setInitialState();
        // send out the default ssml when it is first added
        var ssml = this.props.ssml ? this.props.ssml : `<emphasis level="reduced">${this.placeholder}</emphasis>`;
        this.props.onChange(ssml, this.placeholder);
    }

    private setInitialState() {
        if (this.props.ssml) {
            // parse the break tag to set state
            const level = this.ssmlParser.getElementProperty(this.props.ssml, 'emphasis', 'level')
            if (level) {
                const matchLevel = this.options.find(o => o.name.toLowerCase() == level);
                this.setState({
                    ...this.state,
                    isCustomSelected: !matchLevel,
                    value: matchLevel ? matchLevel.value : null
                })
                return;
            }
        }
        this.setState({
            ...this.state,
            value: 1,
            isCustomSelected: false
        })
    }

    private getLevelFromNumber(value: number) {
        switch(value) {
            case 1: return 'reduced';
            case 2: return 'moderate';
            case 3:
            default: return 'strong';
        }
    }

    buildSsml(value?: number) {
        value = value ? value : this.state.value
        var valueLabel = this.getLevelFromNumber(value);
        const ssml = `<emphasis level="${valueLabel}">${this.placeholder}</emphasis>`;
        this.props.onChange(ssml, this.placeholder);
    }

    handleCustomSelected() {
        this.setState({
            ...this.state,
            isCustomSelected: true
        })
        this.buildSsml();
    }

    handleOptionSelected(option) {
        this.setState({
            ...this.state,
            isCustomSelected: false,
            value: option.value
        })
        this.buildSsml(option.value);
    }

    handleCustomChanged(value: number) {
        this.setState({
            ...this.state,
            isCustomSelected: true,
            value: value
        })
        this.buildSsml(value);
    }

    render() {
        return (
            <div>
                <LevelSelector options={this.options}
                    onOptionSelected={this.handleOptionSelected.bind(this)}
                    value={this.state.value}
                    disableCustom
                    isCustom={false}
                />
            </div>
        )
    }
}

export default EmphasisSelector;