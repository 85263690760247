import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';

interface PageContainerProps {
    fill?: boolean;
    hidden?: boolean
    withFooter?: boolean
}
const getClassName = (props: PageContainerProps) => {
    var style = containerStyle + " ";
    if (props.hidden)
        style = hiddenStyle;
    if (props.fill)
        style = fillStyle + " ";

    if (props.withFooter)
        style += bottomPadded;
    return style;
}

const PageContainer: React.FC<PageContainerProps> = (props) => (
    <div className={`${getClassName(props)}`}>
        {props.children}
    </div>
);

const containerStyle = css`
    padding: 0 50px;
    flex-grow: 1;
    h3 {
        font-family: Muli;
        font-size: 21px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 16px;
        margin-top: 32px;
    }
    h4 {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 16px;
    }
    p {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        color: ${dark_grey_blue};
    }
    ${breakpoint_small} {
        padding: 0 32px;
    }
`;

const fillStyle = css`
    padding: 0;
    flex-grow: 1;
`
const hiddenStyle = css`
display: none;
`
const bottomPadded = css`
    padding-bottom: 90px;
`

export default PageContainer;