import React, { useEffect, useRef, useState } from 'react';
import { color_text_link, color_text_default, color_variants_ocean_light_1, color_text_light, color_shades_dark, color_colors_gold, color_colors_light_blue, color_colors_sky, color_highlights_blue, color_defaults_black, color_defaults_white } from '../../../constants/colors';
import TemplateSectionStatusModel from '../../../models/templating/TemplateSectionStatusModel';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { AppendedSection } from '../../../../clients/spark/scenes/dashboard/ApplicationManagerContent';
import Button from '../../../components/general/Button';
import styled from '@emotion/styled';
import SparkContainer from '../../../hooks/SparkContainer';
import SparkNewTopicForm from '../../../../clients/spark/scenes/dashboard/SparkNewTopicForm';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import WarningConfirmation from '../../../components/general/WarningConfirmation';
import InteractionModelContainer from '../../../hooks/InteractionModelContainer';
import LanguageContainer from '../../../hooks/LanguageContainer';
import LanguageModel from '../../../models/features/api/LanguageModel';
import InteractionModelUpdate from '../../../models/interactionModel/api/InteractionModelUpdate';
import InteractionModel from '../../../models/interactionModel/api/InteractionModel';
import _, { filter, set, template } from 'lodash';
import PageError from '../../../components/general/PageError';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import ImportFileDialog from '../../../components/general/ImportFileDialog';
import { css } from '@emotion/css';
import TemplateFormFieldValueModel from '../../../models/templating/api/TemplateFormFieldValueModel';
import { columnOneHeading, columnThreeHeading, columnTwoHeading } from '../../../constants/sparkConstants/formFieldImportHeadings';
import useCSVHandler from '../../../hooks/UseCSVHandler';
import useIsMobile from '../../../hooks/UseIsMobile';
import InviteSparkFormModal from './InviteSparkFormModal';
import MemberContainer from '../../../state/containers/MemberContainer';
import { Subscribe } from 'unstated';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import useWindowSize from '../../../hooks/UseWindowSize';
import MediaContainer from '../../../state/containers/MediaContainer';
import { AutoGenerateFormFieldResponsesFormModal } from '../../../../clients/spark/scenes/dashboard/autoGenerateFormField/AutoGenerateFormFieldResponsesForm';

const completeIcon = require('../../../content/images/templates-icons/complete.svg');
const incompleteIcon = require('../../../content/images/templates-icons/incomplete.svg');
const addCircleIcon = require('../../../content/images/add-circle.svg');
const deleteIcon = require('../../../content/images/spark/close.svg');
const exportIcon = require("../../../content/images/menu-item-icons/file-code-download.svg");
const importIcon = require("../../../content/images/menu-item-icons/file-code-upload.svg");
const shareIcon = require("../../../content/images/share.svg");
const autoGenerateIcon = require('../../../content/images/spark/auto-generate.svg');


interface AppliedAppSectionSelectorProps {
    sections: TemplateSectionStatusModel[] | any[]
    activeSection: TemplateSectionStatusModel
    isFinishSelected?: boolean
    isStartSelected?: boolean
    disabled?: boolean
    isNew?: boolean
    handleActiveSectionChange: (section: TemplateSectionStatusModel) => void
    onFinishSelect: () => void
    onStartSelect: () => void
    firstSectionName?: string,
    hideStart?: boolean
    appendedSections?: AppendedSection[]
    templateConfigContainer: TemplateConfigurationContainer
    orgContainer: OrganizationContainer
    applicationId: string
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    appContainer: ApplicationContainer
    appliedAppTemplateId: string
    importedFormFields: TemplateFormFieldValueModel[]
    setImportedFormFields: (fields: TemplateFormFieldValueModel[]) => void
    fileImportErrors: string[]
    setFileImportErrors: (errors: string[]) => void
    handleToggleExpandLeft?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, expanded: boolean) => void
}

const AppliedAppSectionSelector: React.FC<AppliedAppSectionSelectorProps> = (props) => {
    const [showNewTopicModal, setShowNewTopicModal] = useState(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [sectionToDelete, setSectionToDelete] = useState(null);
    const [sortedSections, setSortedSections] = useState(props.sections ?? []);
    const [interactionModels, setInteractionModels] = useState<InteractionModel[]>([]);
    const [languages, setLanguages] = useState<LanguageModel[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [isDeletingSection, setIsDeletingSection] = useState(false);
    const [isImportFileDialogModalOpen, setIsImportFileDialogModalOpen] = useState(false);
    const [isConfirmImportModalOpen, setIsConfirmImportModalOpen] = useState(false);
    const [importedFields, setImportedFields] = useState<TemplateFormFieldValueModel[]>([]);
    const [isShareFormOpen, setIsShareFormOpen] = useState(false);
    const [isAutoAnswerGenerateModalOpen, setIsAutoAnswerGenerateModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const sparkContainer = SparkContainer.useContainer();
    const interactionModelContainer = InteractionModelContainer.useContainer();
    const languagesContainer = LanguageContainer.useContainer();
    const { importedData, isLoadingImport, importCSV, exportCSV, setIsLoadingImport } = useCSVHandler();

    const prevSectionsLengthRef = useRef<number | undefined>(undefined);
    const isMobile = useIsMobile();
    const windowSize = useWindowSize();
    const sectionRef = useRef();

    useEffect(() => {
        if (importedData?.length) {
            setImportedFields(importedData);
        }
    }, [importedData])

    useEffect(() => {
        if (!languagesContainer.languages.length) {
            languagesContainer.loadLanguages();
        }
        if (!props.sections?.length) {
            getCurrentAppliedTemplate()
        }
    }, []);

    useEffect(() => {
        if (prevSectionsLengthRef.current !== undefined && props.sections.length > prevSectionsLengthRef.current) {
            // A new custom topic was added
            props.handleActiveSectionChange(props.sections[props.sections.length - 1]);
        }
        // Update the previous length
        prevSectionsLengthRef.current = props.sections?.length;
    }, [props.sections?.length]);

    useEffect(() => {
        const app = props.appContainer.state.currentApp;
        const defaultLanguage = languagesContainer.languages.find(l => l.id == app?.defaultLanguageId);
        if (defaultLanguage) {
            const languages = defaultLanguage == null ? [] : [defaultLanguage];
            languagesContainer.updateSelectedLanguages(languages);
        }
    }, [languagesContainer.languages]);

    useEffect(() => {
        if (languagesContainer.currentSelectedLanguages.length > 0) {
            setLanguages(languagesContainer.currentSelectedLanguages);
        }
    }, [languagesContainer.currentSelectedLanguages]);

    const loadInteractionModels = async () => {
        if (props.applicationId && languages.length) {
            const loadInteractionModelsResponse = await interactionModelContainer.loadInteractionModels(props.applicationId, languages);
            if (loadInteractionModelsResponse.resultType === "Ok") {
                setInteractionModels(loadInteractionModelsResponse.data);
            }
        }
    };

    useEffect(() => {
        loadInteractionModels();
    }, [languages, props.applicationId]);

    useEffect(() => {
        setInteractionModels(interactionModelContainer.interactionModels);
    }, [interactionModelContainer.interactionModels]);

    useEffect(() => {
        setSortedSections(_.sortBy(props.sections ?? [], s => s?.section?.priority));
    }, [props.sections]);

    useEffect(() => {
        if (sectionRef?.current) {
            const current = sectionRef.current as HTMLElement
            current.scrollIntoView({
                behavior: "smooth",
            })
        }

    }, [props.activeSection])

    const getCurrentAppliedTemplate = async () => {
        const loadedTemplatesResult = await props.appliedAppTemplateContainer.loadAppliedTemplates(props.applicationId)
        const loadedTemplate = loadedTemplatesResult.data.find(at => at.id == props.appliedAppTemplateId)
        const loadedSortedSections = loadedTemplate?.templateForm?.templateFormSections?.map(s => (
            {
                section: s,
                isComplete: props.appliedAppTemplateContainer.getIsSectionOfCurrentComplete(loadedTemplate, s),
                isDisabled: !props.appliedAppTemplateId
            }
        ));
        setSortedSections(loadedSortedSections)
    };

    const activeIndex = sortedSections.findIndex(s => (props.activeSection?.section?.name && s?.section?.name == props.activeSection?.section?.name) || (props.activeSection?.section?.id && s.section?.id == props.activeSection?.section?.id));

    const handleDeleteSectionClick = (section: any) => {
        setSectionToDelete(section);
        toggleDeleteConfirmationModal();
    }

    const toggleDeleteConfirmationModal = () => {
        setIsDeleteConfirmationModalOpen(!isDeleteConfirmationModalOpen);
    };

    const toggleNewTopicModal = () => {
        setShowNewTopicModal(!showNewTopicModal);
    };

    const deleteSection = async () => {

        if (sectionToDelete?.appliedTemplateId) {

            setIsDeletingSection(true);

            const appliedAppTemplateToDelete = props.appliedAppTemplateContainer.state.appliedTemplates.find(appliedAppTemplate => {
                return appliedAppTemplate.id === sectionToDelete.appliedTemplateId;
            });

            if (appliedAppTemplateToDelete) {
                await props.appliedAppTemplateContainer.removeAppliedTemplate(props.applicationId, appliedAppTemplateToDelete.id, result => {
                    if (result.resultType === "Ok") {
                        if (interactionModels?.length) {

                            const interactionModel = interactionModels.find(interactionModel => interactionModel.locale === languages[0].shortCode);

                            const intentToDeleteId = interactionModel?.intents?.find(intent => intent.displayName === appliedAppTemplateToDelete.name)?.id;

                            const interactionModelUpdateRequest: InteractionModelUpdate = {
                                intents: {},
                                disableIntents: [],
                                enableIntents: [],
                                locale: interactionModel.locale,
                                removeCustomIntents: [
                                    {
                                        intentName: appliedAppTemplateToDelete.name,
                                        intentId: intentToDeleteId
                                    }
                                ],
                                addCustomIntents: [],
                                updatedEnhancedIntentMatchingPrompt: interactionModel.enhancedIntentMatchingPrompt,
                                enableEnhancedIntentMatching: interactionModel.useEnhancedIntentMatching,
                            };

                            interactionModelContainer.updateInteractionModel(props.applicationId, languages[0].shortCode, interactionModelUpdateRequest);
                        };
                        props.appliedAppTemplateContainer.loadAppliedTemplates(props.applicationId);
                        toggleDeleteConfirmationModal();
                    } else {
                        setErrors(result.errors);
                    }
                });
            }

            setIsDeletingSection(false);

        };

    };

    const toggleImportFileDialogModal = () => {
        setIsImportFileDialogModalOpen(!isImportFileDialogModalOpen);
    };

    const toggleConfirmImportModal = () => {
        setIsConfirmImportModalOpen(!isConfirmImportModalOpen);
    };

    const convertCSVTextToTemplateFormFields = (csvArray: string[][]): TemplateFormFieldValueModel[] => {

        const combinedFormFields: TemplateFormFieldValueModel[] = [];

        for (const appliedTemplate of props.appliedAppTemplateContainer.state?.appliedTemplates) {
            const appliedApplicationTemplateId = appliedTemplate.id;
            for (const section of appliedTemplate.templateForm?.templateFormSections) {
                for (const formField of section.templateFormFields) {
                    const updatedFormField: TemplateFormFieldValueModel = {
                        templateFormFieldId: formField.id,
                        value: formField.associatedVariable ? formField.associatedVariable : formField.defaultValue,
                        associatedVariable: formField.associatedVariable,
                        label: formField.label,
                        fieldType: formField.fieldType,
                        placeholder: formField.placeholder,
                        defaultValue: formField.defaultValue,
                        tip: formField.tip,
                        title: formField.title,
                        appliedApplicationTemplateId: appliedApplicationTemplateId
                    };
                    combinedFormFields.push(updatedFormField);
                }
            }
        };

        const csvRows = csvArray.map(row => row.join(","));
        const headers = csvRows[0].split(",");
        const appliedAppTemplateIdHeadingIndex = headers.findIndex(header => header.toLowerCase() === columnOneHeading.toLowerCase());
        const displayTitleHeadingIndex = headers.findIndex(header => header.toLowerCase() === columnTwoHeading.toLowerCase());
        const assistantResponseHeadingIndex = headers.findIndex(header => header.toLowerCase() === columnThreeHeading.toLowerCase());

        if (displayTitleHeadingIndex === -1 || assistantResponseHeadingIndex === -1) {
            props.setFileImportErrors(["The file you selected is not formatted correctly. Please ensure that the file contains the correct headers."]);
            return [];
        }

        const importedTemplateFormFields: TemplateFormFieldValueModel[] = [];
        let fileImportErrorsList: string[] = [];

        for (const row of csvArray.slice(1)) {
            let appliedAppTemplateId: string;
            if (appliedAppTemplateIdHeadingIndex !== -1) {
                appliedAppTemplateId = row[appliedAppTemplateIdHeadingIndex];
            };
            const displayTitle = row[displayTitleHeadingIndex];
            const assistantResponse = row[assistantResponseHeadingIndex];

            const currentFormFields = combinedFormFields.filter(formField => formField.title === displayTitle);

            let currentFormField: TemplateFormFieldValueModel;

            if (currentFormFields.length > 1) {
                if (appliedAppTemplateId) {
                    currentFormField = currentFormFields.find(formField => {
                        return formField.appliedApplicationTemplateId === appliedAppTemplateId
                    });
                } else {
                    props.setFileImportErrors([`Looks like there is more than one field with the title ${displayTitle}. Please include the module id in the file to specify which field to import.`]);
                }
            } else if (currentFormFields.length === 1) {
                currentFormField = currentFormFields[0];
            };

            if (currentFormField) {
                const importedFormField: TemplateFormFieldValueModel = {
                    ...currentFormField,
                    value: assistantResponse
                };
                importedTemplateFormFields.push(importedFormField);
            } else {
                fileImportErrorsList.push(displayTitle);
            }
        };

        for (const combinedFormField of combinedFormFields) {
            const isImported = importedTemplateFormFields.some(importedFormField => (importedFormField.title === combinedFormField.title) && (importedFormField.appliedApplicationTemplateId ? importedFormField.appliedApplicationTemplateId === combinedFormField.appliedApplicationTemplateId : true));
            if (!isImported) {
                importedTemplateFormFields.push(combinedFormField);
            };
        };

        // ensure all file import errors have a length at all
        fileImportErrorsList = fileImportErrorsList.filter(error => error?.trim()?.length);
        if (fileImportErrorsList.length >= 1) {
            const fileImportErrorsString: string[] = [`We cannot import the following fields, as they're not in this form:`];

            for (const [index, fileImportError] of fileImportErrorsList.entries()) {
                if (index === 2) {
                    fileImportErrorsString[0] += ` ${fileImportError}, ...`;
                    break;
                } else if (index === fileImportErrorsList.length - 1) {
                    fileImportErrorsString[0] += ` ${fileImportError}.`;
                } else {
                    fileImportErrorsString[0] += ` ${fileImportError},`;
                }
            }

            props.setFileImportErrors(fileImportErrorsString);
        }

        return importedTemplateFormFields;
    };

    const importFormFields = (file: File) => {
        importCSV(file, convertCSVTextToTemplateFormFields);
        toggleImportFileDialogModal();
        toggleConfirmImportModal();
    };

    const useImportedTemplateFormFieldValues = async () => {

        setIsLoadingImport(true);

        props.setImportedFormFields(importedFields);
        if (importedFields.length > 0) {
            const appliedTemplates = props.appliedAppTemplateContainer.state?.appliedTemplates;
            if (appliedTemplates) {
                for (const appliedTemplate of appliedTemplates) {
                    const appliedTemplateImportedFormValues = importedFields.filter(importedField => {
                        return appliedTemplate.templateForm?.templateFormSections?.some(section => {
                            return section.templateFormFields.some(field => field.title === importedField.title && (importedField.appliedApplicationTemplateId ? importedField.appliedApplicationTemplateId === appliedTemplate.id : true));
                        });
                    });
                    const updatedAppliedTemplate = {
                        templateFormFieldValues: appliedTemplateImportedFormValues,
                        name: appliedTemplate.name
                    }
                    await props.appliedAppTemplateContainer.updateAppliedTemplate(appliedTemplate?.id, updatedAppliedTemplate);
                };
            };
        };

        setIsLoadingImport(false);
        toggleConfirmImportModal();

    };

    const formatFileName = () => {
        const fileExportName = props.appContainer.state?.currentApp?.name ? props.appContainer.state.currentApp.name.toLowerCase().split(/\s+/) : [];
        const date = new Date();
        const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
        const formattedTime = `${date.getHours()}:${date.getMinutes()}`;
        fileExportName.push(formattedDate);
        fileExportName.push(formattedTime);
        const formattedFileExportName = fileExportName.join('-');
        return formattedFileExportName;
    };

    const exportFormFields = () => {

        const formFieldValues = props.appliedAppTemplateContainer.state?.appliedTemplates?.flatMap(appliedTemplate => {
            return appliedTemplate.templateForm.templateFormSections.flatMap(section => {
                return section.templateFormFields.map(field => {
                    const matchingField = appliedTemplate.templateFormFieldValues.find(value => value.templateFormFieldId === field.id);
                    return {
                        appliedAppTemplateId: appliedTemplate.id,
                        title: field.title,
                        value: matchingField ? matchingField.value : "",
                        associatedVariable: field.associatedVariable,
                    };
                });
            });
        });

        exportCSV(formFieldValues, [columnOneHeading, columnTwoHeading, columnThreeHeading], formatFileName());

    };

    const handleActiveSectionChangeOnMobile = (e: React.PointerEvent<HTMLDivElement>) => {
        props.handleToggleExpandLeft(e, false);
    };

    const filteredAppendedSections = props.appendedSections?.filter(section => section.name !== "Auto-Generate Responses");
    const autoGenerateSection = props.appendedSections?.find(section => section.name === "Auto-Generate Responses");

    const renderAutoGenerateSection = () => {
        if (!autoGenerateSection) {
            return null;
        }
        return (
            <SectionItem
                className={`clickable ${autoGenerateSection.isSelected ? 'active' : ''}`}
                onPointerDown={(e) => {
                    props.handleActiveSectionChange(autoGenerateSection as any);
                    if (isMobile) {
                        handleActiveSectionChangeOnMobile(e);
                    }
                }}
            >
                <SectionTitle>{autoGenerateSection.name}</SectionTitle>
                <CompleteIcon src={autoGenerateSection.isComplete ? completeIcon : incompleteIcon} />
            </SectionItem>
        )
    };

    return (
        <Wrapper>
            {isDeleteConfirmationModalOpen &&
                <ConfirmationDialog
                    title={`Delete ${sectionToDelete.section.title ? sectionToDelete.section.title : 'Section'}`}
                    deleteText="Yes, Delete"
                    isLoading={isDeletingSection}
                    onClose={() => toggleDeleteConfirmationModal()}
                    onConfirm={() => deleteSection()}>
                    <WarningConfirmation
                        text={`Are you sure you want to delete ${sectionToDelete.section.title ? sectionToDelete.section.title : 'this section'}?`}
                    />
                </ConfirmationDialog>
            }
            {
                showNewTopicModal && sparkContainer.inSpark &&
                <SparkNewTopicForm
                    toggleNewTopicModal={toggleNewTopicModal}
                    templateConfigContainer={props.templateConfigContainer}
                    orgContainer={props.orgContainer}
                    appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                    applicationId={props.applicationId}
                    sections={props.sections}
                />

            }
            {!props.hideStart &&
                <>
                    <SectionItem
                        className={`overview-section ${props.isStartSelected ? 'active' : ''}`}
                        onPointerDown={(e) => {
                            if ((!props.isNew || props.disabled)) {
                                props.onStartSelect;
                                if (isMobile) {
                                    handleActiveSectionChangeOnMobile(e);
                                }
                            }
                        }}
                    >
                        <SectionTitle>{props.firstSectionName ? props.firstSectionName : `Overview`}</SectionTitle>
                    </SectionItem>
                    <HorizontalSeparator />
                </>
            }
            <TopicButtonWrapper>
                <CustomScrollbars autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .5)}>
                    {renderAutoGenerateSection()}
                    {
                        sortedSections?.map((s, idx) => (
                            <SectionItem
                                ref={props.activeSection?.section?.title == s.section?.title ? sectionRef : null}
                                key={idx}
                                className={`${(props.disabled && ((idx > activeIndex && !props.isFinishSelected) || props.isStartSelected)) ? "disabled" : ""} ${!props.disabled ? "clickable" : ""} ${(props.activeSection?.section?.title && props.activeSection?.section?.title == s.section?.title) ? 'active' : ''} ${s.isDisabled || props.isNew ? 'disabled' : ''}`}
                                onPointerDown={(e) => {
                                    if (!(s.isDisabled || props.disabled)) {
                                        props.handleActiveSectionChange(s);
                                    }
                                }}
                                onClick={(e: any) => {
                                    if (!(s.isDisabled || props.disabled)) {
                                        props.handleActiveSectionChange(s);
                                        if (isMobile) {
                                            handleActiveSectionChangeOnMobile(e);
                                        }
                                    }
                                }}
                            >
                                <SectionTitleWrapper>
                                    <SectionTitle>{s.section?.title}</SectionTitle>
                                    {s.section.isCustom &&
                                        <DeleteButton themes={["icon-small"]} icon={deleteIcon} onClick={() => handleDeleteSectionClick(s)} />
                                    }
                                </SectionTitleWrapper>
                                <CompleteIcon src={s.isComplete ? completeIcon : incompleteIcon} />
                            </SectionItem>
                        ))
                    }
                    {
                        filteredAppendedSections && filteredAppendedSections?.map((section: AppendedSection | any, idx) => (
                            <SectionItem
                                key={idx}
                                className={`clickable ${section.isSelected ? 'active' : ''}`}
                                onPointerDown={(e) => {
                                    props.handleActiveSectionChange(section);
                                    if (isMobile) {
                                        handleActiveSectionChangeOnMobile(e);
                                    }
                                }}
                            >
                                <SectionTitle>{section.name}</SectionTitle>
                                <CompleteIcon src={section.isComplete ? completeIcon : incompleteIcon} />
                            </SectionItem>
                        ))
                    }
                    {sparkContainer.inSpark &&
                        <CustomButton
                            themes={['small-flat']}
                            text="Add a New Topic"
                            rightIcon={addCircleIcon}
                            onClick={() => {
                                toggleNewTopicModal()
                            }}
                        />
                    }
                    <SectionItem
                        className={`review-section ${props.isFinishSelected ? 'active' : ''} ${props.isNew ? 'disabled' : ''}`}
                        onPointerDown={(e) => {
                            if (!(props.isNew || props.disabled)) {
                                console.log("finish selected")
                                props.onFinishSelect();
                            }
                            if (isMobile) {
                                handleActiveSectionChangeOnMobile(e);
                            }
                        }}
                    >
                        <SectionTitle>Review</SectionTitle>
                    </SectionItem>
                </CustomScrollbars>
                <ButtonsWrapper>
                    <HorizontalSeparator />
                    {sparkContainer.inSpark &&
                        <FormPopulationButtonWrapper>
                            <FormPopulationButton
                                disabled={false}
                                loading={false}
                                type="submit"
                                themes={['secondary-small', 'end-tight']}
                                rightIcon={importIcon}
                                onClick={() => toggleImportFileDialogModal()}
                                text="Import All Form Fields"
                            />
                            <FormPopulationButton
                                disabled={false}
                                loading={false}
                                type="submit"
                                themes={['secondary-small', 'end-tight']}
                                rightIcon={exportIcon}
                                onClick={() => exportFormFields()}
                                text="Export All Form Fields"
                            />
                            <FormPopulationButton
                                disabled={false}
                                loading={false}
                                type="submit"
                                themes={['secondary-small', 'end-tight']}
                                rightIcon={shareIcon}
                                onClick={() => setIsShareFormOpen(true)}
                                text="Share Form"
                            />
                            <FormPopulationButton
                                disabled={false}
                                loading={false}
                                type="submit"
                                themes={['secondary-small', 'end-tight']}
                                rightIcon={autoGenerateIcon}
                                onClick={() => setIsAutoAnswerGenerateModalOpen(true)}
                                text="Auto-Generate Responses"
                            />
                        </FormPopulationButtonWrapper>
                    }
                </ButtonsWrapper>
            </TopicButtonWrapper>
            {isShareFormOpen &&
                <Subscribe to={[MemberContainer]}>
                    {(memberContainer: MemberContainer) => {
                        return (
                            <InviteSparkFormModal
                                isShareFormOpen={isShareFormOpen}
                                setIsShareFormOpen={setIsShareFormOpen}
                                applicationId={props.applicationId}
                                memberContainer={memberContainer}
                            />
                        )
                    }}

                </Subscribe>
            }
            {isImportFileDialogModalOpen &&
                <ImportFileDialog
                    onClose={toggleImportFileDialogModal}
                    completeImport={importFormFields}
                    title={"Select a .csv File to Import"}
                    acceptFileTypes={".csv"}
                    acceptFileTypesDisplayText={"Supports .csv"}
                    isLoading={false}
                    errors={[]}
                />
            }
            {isConfirmImportModalOpen &&
                <ConfirmationDialog
                    title={`Confirm Form Fields Import`}
                    deleteText="Yes, Import"
                    isLoading={isLoadingImport}
                    onClose={toggleConfirmImportModal}
                    onConfirm={useImportedTemplateFormFieldValues}>
                    <WarningConfirmation
                        text={`By importing these form fields you will override any existing responses. To preserve existing responses, export them and combine everything into a single file to be imported.`}
                        className={importWarningStyle}
                    />
                </ConfirmationDialog>
            }
            {isAutoAnswerGenerateModalOpen &&
                <Subscribe to={[MediaContainer]}>
                    {(mediaContainer: MediaContainer) => {
                        return (
                            <AutoGenerateFormFieldResponsesFormModal
                                applicationId={props.applicationId}
                                mediaContainer={mediaContainer}
                                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                                onClose={() => setIsAutoAnswerGenerateModalOpen(false)}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        )
                    }}

                </Subscribe>
            }
            <PageError
                errors={errors}
            />
        </Wrapper >
    );

};

const importWarningStyle = css`
    margin-top: 32px;
`;

const FormPopulationButton = styled(Button)`
    margin: 0 0 16px 8px;
    font-size: 14px;
    font-weight: 400;
    .right-img {
        margin-left: 8px;
        width: 16px;
    }
`;

const FormPopulationButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 20px;
`;

const CompleteIcon = styled.img``;

const SectionTitle = styled.p``;

const SectionTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    max-width: 90%;
`;

const DeleteButton = styled(Button)`
    margin-left: 0px;
    img {
        width: 8px;
        height: 8px;
    }
`;

const CustomButton = styled(Button)`
    margin: 0 0 0 16px;
    font-size: 14px;
    font-weight: 400;
    .right-img {
        margin-left: 8px;
    }
`;

const SectionItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    height: 48px;
    padding: 0 16px;   
    p {
        font-size: 14px;
        line-height: 20px;
        color: ${color_text_default};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .selected-indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${color_text_link};
        margin-right: 8px;
        margin-left: auto;
    }
    &.active {
        background: ${color_variants_ocean_light_1};
        p {
            color: ${color_text_link}
        }
    }
    &.clickable {
        cursor: pointer;
    }
    &.disabled {
        img {
            display: none;
        }
        p {
            color: ${color_text_light};
        }
    }
    &.review-section {
        margin-bottom: 8px;
        cursor: pointer;
    }
`;

const Wrapper = styled.div`
    padding: 16px;
`;

const TopicButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonsWrapper = styled.div`
    background: ${color_defaults_white};
    position: sticky;
    bottom: 0;
`;


export default AppliedAppSectionSelector;