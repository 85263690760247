import React, { useState } from 'react'
import styled from '@emotion/styled';
import ConfirmationDialog from '../../../../../shared/components/structure/ConfirmationDialog';
import TextField from '../../../../../shared/components/forms/TextField';
import WarningLabel from '../../../../../shared/components/general/WarningLabel';
import { OutboundCallRequest } from '../../../../../shared/models/outboundCall/outboundCallRequest';
import ApplicationModel from '../../../../../shared/models/applications/api/ApplicationModel';
import { css } from '@emotion/css';
import OutboundCallContainer from '../../../../../shared/hooks/OutboundCallContainer';
import { useForm, Controller, Resolver } from "react-hook-form";
import SelectFieldFC from '../../../../../shared/components/forms/SelectFieldFC';

const infoCircle = require('../../../../../shared/content/images/info-circle-yellow.svg');
const phoneIcon = require('../../../../../shared/content/images/spark/phone.svg');
const alertTriangle = require('../../../../../shared/content/images/alert-triangle.svg');

interface props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    application: ApplicationModel,
}

type FormValues = {
    assistantNumber: string;
    numberToCall: string;
};

export default function CallNowModal(props: props) {
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const outboundCallContainer = OutboundCallContainer.useContainer();

    const resolver: Resolver<FormValues> = async (values) => {
        const errors: any = {};
        if (!values.assistantNumber) {
            errors.assistantNumber = {
                type: 'required',
                message: 'Assistant number is required.',
            };
        }
        if (!values.numberToCall) {
            errors.numberToCall = {
                type: 'required',
                message: 'Number to call is required.',
            };
        } else if (!/^[1][0-9]{10}$/.test(values.numberToCall)) {
            errors.numberToCall = {
                type: 'pattern',
                message: 'Number to call should be in this format 16475465015 and start with 1.',
            };
        }
        return {
            values: Object.keys(errors).length ? {} : values,
            errors,
        };
    };

    const { control, handleSubmit, formState: { errors } } = useForm<FormValues>({ resolver });

    const onSubmit = async (data: any) => {
        const { numberToCall, assistantNumber } = data;
        const outboundCallRequest: OutboundCallRequest = {
            callingNumber: numberToCall,
            assistantNumber: assistantNumber.value,
            voicifyApplicationId: props.application.id,
            voicifyApplicationSecret: props.application.secret
        }
        setLoading(true);
        const result = await outboundCallContainer.placeOutboundCall(outboundCallRequest);
        if (result.resultType != 'Ok') {
            setSuccessMessage("Failed to place call. Please try again later.")
        }

        setTimeout(() => {
            setLoading(false);
            if (result.resultType === 'Ok') {
                setSuccessMessage("Call placed successfully!");
                setTimeout(() => {
                    setSuccessMessage("");
                }, 10000);
            }

        }, 3000);
    }

    return (
        <ConfirmationDialog
            title="Call Now"
            deleteText="Call Now"
            warning={true}
            onClose={() => { props.setShowModal(false); }}
            isLoading={loading}
            onConfirm={handleSubmit(onSubmit)}>
            {
                <NumberFieldWrapper>
                    <WarningLabel image={successMessage === "" ? infoCircle : phoneIcon} text={successMessage === "" ? `Note: This call will be place immediately!` : successMessage} />
                    <>
                        <Controller
                            name="assistantNumber"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <SelectFieldFC
                                        field={field}
                                        name='assistantNumber'
                                        label="Assistant Number"
                                        labelStyle={labelStyle}
                                        options={outboundCallContainer?.assistantNumbers?.map(numbers => ({ value: numbers.number, label: `${numbers.number} - ${numbers.name}` }))}
                                        clearable={false}
                                    />
                                );
                            }}
                        />
                        {errors.assistantNumber &&
                            <ErrorMessageWrapper>
                                <ErrorIcon src={alertTriangle} alt="Error" />
                                <p>{errors.assistantNumber.message}</p>
                            </ErrorMessageWrapper>
                        }
                    </>

                    <>
                        <Controller
                            name="numberToCall"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    useForm={false}
                                    label="Number To Call"
                                    placeholder="16475465015"
                                    labelStyle={labelStyle}
                                />
                            )}
                        />
                        {errors.numberToCall &&
                            <ErrorMessageWrapper>
                                <ErrorIcon src={alertTriangle} alt="Error" />
                                <p>{errors.numberToCall.message}</p>
                            </ErrorMessageWrapper>
                        }
                    </>
                </NumberFieldWrapper>
            }
        </ConfirmationDialog>
    )
}

const NumberFieldWrapper = styled.div`
    margin-top: 20px;
    padding: 4px 20px;
`;

const ErrorMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    color: red;
    font-size: 14px;
    margin-top: -20px;
    margin-bottom: 10px;
`;

const labelStyle = css`
    margin-left: 4px;
    margin-bottom: -4px;
    font-weight: 500;
    font-size: 14px;
`;


const ErrorIcon = styled.img`
    margin-right: 4px;
    width: 12px;
    height: 12px;
`;

