import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import ApplicationAnalyticsCharts from './ApplicationAnalyticsCharts';
import ApplicationInteractionsAnalyticsContainer from '../../../state/containers/ApplicationInteractionAnalyticsContainer';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';

interface ApplicationInteractionsChartsProps {
    stateContainer: ApplicationInteractionsAnalyticsContainer
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string
}
const ApplicationInteractionsCharts = (props: ApplicationInteractionsChartsProps) => {
    useEffect(() => {
        loadChartData(); 
    }, [props.interval, props.filter])

    const [loading, setLoading] = useState(false);
    const loadChartData = async () => {
        if(loading) return;
        await setLoading(true);
        props.stateContainer.loadPrimaryBreakdown(
            {applicationId: props.applicationId},
            props.filter,
            props.stateContainer.state.currentBreakdown)

        props.stateContainer.loadSecondaryBreakdown(
            {applicationId: props.applicationId},
            props.filter,
            props.stateContainer.state.currentBreakdown)

        await props.stateContainer.loadPrimaryHistogram(
            {applicationId: props.applicationId},
            props.innerInterval,
            props.filter
        )
        await props.stateContainer.loadSecondaryHistogram(
            {applicationId: props.applicationId},
            props.innerInterval,
            props.filter
        );
        await props.stateContainer.getAverages(
            {applicationId: props.applicationId},
            props.filter)
        await setLoading(false);
    }
    return (
        <ApplicationAnalyticsCharts
            applicationId={props.applicationId}
            filter={props.filter}
            innerInterval={props.innerInterval}
            interval={props.interval}
            dataType='Interactions'
            stateContainer={props.stateContainer}
            showSecondary={props.stateContainer.state.currentDisplayOption?.toLowerCase()?.indexOf("session") > -1}
            showPrimary={props.stateContainer.state.currentDisplayOption?.toLowerCase()?.indexOf("interactions") > -1}
            secondaryDataName="Sessions"
            primaryDataName="Interactions" />
    )
    
}

export default ApplicationInteractionsCharts;