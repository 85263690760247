import IResult from "../../models/result/IResult";
import BulkContentRequest from "../../models/features/api/BulkContentRequest";
import { sendPost, sendPostForFile, uploadFile } from "./generic";
import BulkWebhookRequest from "../../models/features/api/BulkWebhookRequest";

export const exportContentToFile = (request: BulkContentRequest): Promise<IResult<Blob>> => {
    return sendPostForFile(`/importExport/export/contentToFile`, request);
}
export const exportWebhooksToFile = (request: BulkWebhookRequest): Promise<IResult<Blob>> => {
    return sendPostForFile(`/importExport/export/webhooksToFile`, request);
}
export const importToAppFeature = (applicationFeatureId: string, file: File): Promise<IResult<any>> => {
    return uploadFile<any>(`/importExport/import/intoApplicationFeatureFromFile/${applicationFeatureId}`, file);
}
export const importToWebhookFolder = (targetFolderId: string, applicationId: string, file: File): Promise<IResult<any>> => {
    return uploadFile<any>(`/importExport/import/intoApplication/${applicationId}/intoWebhookFolderFromFile?folderId=${targetFolderId}`, file);
}

