import React from 'react';
import { css } from '@emotion/css';
import { silver_two } from '../../constants/colors';

const separatorStyle = css`
height: 100%;
width: 1px;
background: ${silver_two};
`

const flexStyle = css`
width: 1px;
background: ${silver_two};
height: auto;
`

interface VerticalSeparatorProps {
    fit?: boolean
    className?: string
}
const VerticalSeparator: React.SFC<VerticalSeparatorProps> = (props) => (
    <div className={`${props.fit ? flexStyle : separatorStyle} ${props.className}`}></div>
);

export default VerticalSeparator;