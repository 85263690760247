import React from 'react';
import { css } from '@emotion/css';
import { silver_two, cool_grey } from '../../../constants/colors';
import { SsmlType } from './SsmlManager';

interface SsmlElementSelectorProps {
    title?: string
    options: SsmlElement[]
    onOptionSelected?: (SsmlElement) => void
}

export interface SsmlElement {
    icon: string
    name: string
    value: string
}

const containerStyle = css`
    border: 1px solid ${silver_two};
    border-radius: 5px;
    position: relative;
    height: 80px;
    flex: 1;
    margin-top: 4px;
    
    .box-title {
        position: absolute;
        background: white;
        text-transform: uppercase;
        font-family: Muli;
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: ${cool_grey};
        padding: 0 4px;
        top: -6px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 40px;
        text-align: center;
    }

    .elements-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        .separator {
            height: 32px;
        }

    }

    .element {
        min-width: 90px;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .ssml-element {
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        cursor: pointer;
        margin: 6px;
        > img {
            height: 24px;
            margin-right: 0;
        }
        p {
            font-family: Muli;
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            text-align: center;
            color: #304562;
            margin-top: 4px;
        }
        &:hover {  
            background-color: rgba(0, 125, 187, 0.1);
            border-radius: 10px;
        }
    }

`

const SsmlElementSelector: React.SFC<SsmlElementSelectorProps> = (props) => (
    <div className={containerStyle}>
        {props.title ? <p className="box-title">{props.title}</p> : null}
        <div className="elements-wrapper">
            {props.options.map((o,i) => (
                <div key={o.value} className="element" onClick={() => props.onOptionSelected(o)}>
                    
                    {i > 0 ? <div className="separator" /> : null}
                    
                    <div className="ssml-element">
                        <img src={o.icon}/>
                        <p>{o.name}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
)

export default SsmlElementSelector
