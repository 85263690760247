import React from 'react';
import FilterSelector from './FilterSelector';
import FilterOption from './FilterOption';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
const interfaceTypeIcon = require('../../../content/images/analytics-icons/interfaces.svg');
interface InterfaceTypeFilterProps {
    selectedInterfaces: string[]
    interfaces: {name: string, iconUrl: string}[]
    onChange: (selectedInterfaces: string[]) => void
}

class InterfaceTypeFilter extends React.Component<InterfaceTypeFilterProps> {
    handleToggleInterfaceType(selectedInterface: string) {
        var selectedInterfaces = this.props.selectedInterfaces ?? []
        if (selectedInterfaces.some(l => l == selectedInterface)) {
            selectedInterfaces.splice(selectedInterfaces.indexOf(selectedInterface), 1);
        }
        else {
            selectedInterfaces.push(selectedInterface);
        }

        this.props.onChange(selectedInterfaces);
    }
    handleClear() {
        this.props.onChange(null);
    }

    render() {
        const props = this.props;
        const selectedText = props.interfaces.filter(f => props.selectedInterfaces?.some(id => id == f.name))?.map(f => f.name)?.join(', ');
        return (
            <FilterSelector label="Interface"
                icon={interfaceTypeIcon}
                placeholder={'All Interfaces'}
                selectedText={selectedText}
                className="ac-analytics-interface-filter"
                onClear={this.handleClear.bind(this)}>
                <CustomScrollbars autoHide autoHeight autoHeightMax={400}>
                    <FilterOption title="- Select All / None -"
                        onClick={() => props.onChange([])}
                        isSelected={!props.selectedInterfaces}
                        isPartialSelected={true}
                        optionType="check" />
                    {props.interfaces.map(f => (
                        <FilterOption key={f.name} title={f.name}
                            icon={f.iconUrl}
                            selectedIcon={f.iconUrl}
                            isSelected={props.selectedInterfaces?.some(id => id == f.name)}
                            optionType="check"
                            onClick={() => this.handleToggleInterfaceType(f.name)} />
                    ))}
                </CustomScrollbars>
            </FilterSelector>
        )
    }
}

export default InterfaceTypeFilter;

