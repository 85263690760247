export const dark_grey_blue = '#2c3d52';
export const cool_grey = '#8e97a1';
export const greeny_blue = '#4cb58f';
export const dark_sky_blue = '#2ebbe1';
export const silver = '#ebecec';
export const customGreen = `#76b041`;
export const bluegreen = `#17bebb`;
export const ocean_blue = '#0074a6';
export const faded_red = '#dd4e60';
export const pale_grey = '#e9eaee';
export const silver_two = '#cbd0d2';
export const silver_three = '#e5e7e8';
export const silver_four = '#cbccd2';
export const pale_grey_two = '#fcfdfe';
export const dark_grey_blue_10 = 'rgba(44, 61, 82, 0.1)';
export const dark_grey_blue_20 = 'rgba(44, 61, 82, 0.2)';
export const dark_grey_blue_30 = 'rgba(44, 61, 82, 0.3)';
export const dark_grey_blue_70 = 'rgba(44, 61, 82, 0.7)';
export const dark_grey_blue_80 = 'rgba(44, 61, 82, 0.8)';
export const twilight = '#624589';
export const denim_blue = '#395994';
export const faded_grey = 'rgba(203, 208, 210, 0.5)';
export const primary_gradient = 'linear-gradient(257deg, #76c3b5, #3ea6ac 72%, #5296b3)';
export const faded_blue = '#E6F0F5';
export const highlight_green = '#EDF7F3';
export const highlight_blue = '#EAF8FC';
export const blue_white = '#fafcfd';
export const highlight_blue_2 = '#4d9cbe';
export const faded_blue_3 = "#E5F1F6";
export const faded_blue_2 = "#99C7DB";
export const green_1 = "#4EBE99";
export const text_blue = "#1e7eb9;"
export const pastel_light_blue = "#b3f1fe";
export const pastel_blue = "#cfe3fa";
export const pastel_green = "#89eac3";
export const pastel_purple = "#d8cff8";
export const pastel_gold = "#ffe9b1";
export const pastel_pink = "#ffbfd9";
export const pastel_yellow = "#fff656";
export const pastel_red = "#ffb3a7";
export const pastel_grey = "#e9eaee";
export const shade_2_lighter = '#f4f4f6';
export const page_primary_background = '#FAFCFD';
export const light_gray = '#f9f9f9';
export const dark_red = '#d8414d';
export const charcoal_blue = "rgba(50, 70, 97, 0.1)";
export const light_black = "rgba(0, 0, 0, 0.1)";

// new colors from style guide - replacing others above in the future

// defaults
export const color_defaults_black = "#000000";
export const color_defaults_white = "#FFFFFF";
export const color_defaults_spec = "#B42360";
export const color_defaults_specbg = "rgba(180, 35, 96, 0.1)";

// shades
export const color_shades_darkest = "#324661";
export const color_shades_darkest_transparent = "rgb(50,70,97,.85)";
export const color_shades_darker = "#8F97A1";
export const color_shades_dark = "#CBCCD2";
export const color_shades_medium = "#DCDDE2";
export const color_shades_light = "#E9EAEE";
export const color_shades_lighter = "#F4F4F6";
export const color_shades_lightest = "#FCFDFE";

// colors
export const color_colors_ocean = "#1E7EB9";
export const color_colors_light_ocean = "rgba(30, 126, 185, 0.08)";
export const color_colors_sky = "#36C4F2";
export const color_colors_royalty = "#6C4C9F";
export const color_colors_growth_background = "rgb(88,189,53,.1)";
export const color_colors_growth = "#58BD99";
export const color_colors_gold = "#F6A12F";
export const color_colors_gold_background = "rgb(246,161,47,.1)";
export const color_colors_decline = "#D8414D";
export const color_colors_light_blue = "#2BA4D8";

// color-variants
export const color_variants_shadow_10 = "rgba(50, 70, 97, 0.1)";
export const color_variants_shadow_20 = "rgba(50, 70, 97, 0.2)";
export const color_variants_ocean_light_1 = "rgba(30, 126, 185, 0.08)";
export const color_variants_ocean_light_2 = "rgba(30, 126, 185, 0.12)";
export const color_variants_ocean_xlight = "rgba(30, 126, 185, 0.04)";
export const color_variants_blue_2 = "rgba(30, 126, 185, 0.2)";
export const color_variants_green_2 = "rgba(88, 189, 153, 0.2)";
export const color_variants_red_2 = "rgba(216, 65, 77, 0.2)";
export const color_variants_gold_light = "rgb(246, 161, 47, 0.1)";
export const color_variants_gold_dark = "rgb(246, 161, 47, 1)";
export const color_variants_ocean_light_opaque = "#EDF5FA";
export const color_variants_ocean_light_opaque_2 = "#E4EFF6";
export const color_variants_royalty_light_10 = "#F0EDF5";
export const color_variants_royalty_light_20 = "#E2DBEC";
export const color_variants_main_bg = "#FAFCFD";
export const color_variants_decline_light_1 = "#FCECEE";

// gradients
export const color_gradients_blue_shift = "linear-gradient(90deg, #1E7EB9 0%, #36C4F2 100%, #2BA5D8 100%)";
export const color_gradients_green_shift = "linear-gradient(224.91deg, #76C3B5 3.06%, #3EA6AC 72.52%, #5296B3 100%)";
export const color_gradients_green_shift_reverse = "linear-gradient(14.4deg, #76C3B5 0%, #3EA6AC 71.66%, #5296B3 100%)";
export const color_gradients_silver_shift = "linear-gradient(224.91deg, #F5F9FC 3.06%, #FAFDFE 50.21%, #F4F5F5 100%)";
export const color_gradients_silver_shift_reverse = "linear-gradient(16.95deg, #F5F9FC 0%, #FAFDFE 48.69%, #F4F5F5 100.09%)";
export const color_gradients_knight_shift = "linear-gradient(224.91deg, #CBCCD2 3.06%, #A8A8A8 68.4%, #929292 100%)";

// highlights
export const color_highlights_green = "#89EAC3";
export const color_highlights_purple = "#D8CFF8";
export const color_highlights_pink = "#FFBFD9";
export const color_highlights_salmon = "#FFB3A7";
export const color_highlights_gold = "#FFE9B1";
export const color_highlights_yellow = "#FFF656";
export const color_highlights_cyan = "#B3F1FE";
export const color_highlights_blue = "#CFE3FA";

// text colors
export const color_text_default = color_shades_darkest;
export const color_text_light = color_shades_darker;
export const color_text_xlight = color_shades_dark;
export const color_text_link = color_colors_ocean;
export const color_text_inverse = color_defaults_white;