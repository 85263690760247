import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomAssistantApi, CustomAssistantDevice, CustomAssistantUser } from '@voicify/voicify-sdk-assistant';
import { SUPPORT_APPLICATION_ID, SUPPORT_APPLICATION_SECRET } from '../../../constants/Keys';
import { getSupportAssistantBaseUrl } from '../../../constants/Urls';

interface Request {
    requestId: string;
    user: CustomAssistantUser;
    device: CustomAssistantDevice;
    context: Context;
}

interface Context {
    channel: string;
    locale: string;
    requestName?: string;
    sessionId: string;
    requestType: string;
    originalInput: string;
    requiresLanguageUnderstanding: boolean;
}

export function useSupportAssistant () {
    const [hints, setHints] = useState<string[]>([]);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [userId, setUserId] = useState<string>(uuidv4());
    const [sessionId, setSessionId] = useState<string>(uuidv4());
    const [loading, setLoading] = useState<boolean>(true);

    const voicifyApi = new CustomAssistantApi({
        basePath: getSupportAssistantBaseUrl()
    });
    const appId = SUPPORT_APPLICATION_ID;
    const appSecret = SUPPORT_APPLICATION_SECRET;

    const buildRequest = (input: string): Request => {
        return (
            {
                requestId: uuidv4(),
                user: {
                    id: userId,
                    name: userId
                },
                device: {
                    id: "Support Assistant",
                    name: "Support Assistant",
                    supportsDisplayText: true,
                    supportsTextInput: true
                },
                context: {
                    channel: "Support Assistant",
                    locale: "en-US",
                    sessionId: sessionId,
                    requestType: "IntentRequest",
                    originalInput: input,
                    requiresLanguageUnderstanding: true
                }
            }
        );
    };

    const buildWelcomeRequest = (): Request => {
        return (
            {
                requestId: uuidv4(),
                user: {
                    id: userId,
                    name: userId
                },
                device: {
                    id: "Support Assistant",
                    name: "Support Assistant",
                    supportsDisplayText: true,
                    supportsTextInput: true
                },
                context: {
                    channel: "Support Assistant",
                    requestName: "VoicifyWelcome",
                    locale: "en-US",
                    sessionId: sessionId,
                    requestType: "IntentRequest",
                    originalInput: "automatic app start",
                    requiresLanguageUnderstanding: false
                }
            }
        );
    };

    const startSession = async () => {
        try {
            setLoading(true);
            const result = await voicifyApi.handleRequest(buildWelcomeRequest(), appId, appSecret)
            await addBotHistory(result.displayText, []);
            setHints(result.hints);
            setLoading(false);
        } catch (e) {
            console.log("error:", e);
            setLoading(false);
        }
    };

    const resetSession = () => {
        setSessionId(uuidv4());
        setUserId(uuidv4());
    };

    const addUserHistory = (userString: string, history = null) => {
        let conversationHistoryCopy = history !== null ? history : [...conversationHistory];
        conversationHistoryCopy.push({ "user": userString });
        setConversationHistory(conversationHistoryCopy);
        return conversationHistoryCopy;
    };

    const addBotHistory = (botString: string, history = null) => {
        let conversationHistoryCopy = history !== null ? history : [...conversationHistory];
        conversationHistoryCopy.push({ "bot": botString });
        setConversationHistory(conversationHistoryCopy);
        return conversationHistoryCopy;
    };

    const makeRequest = async (input: string, history: string[]) => {
        try {
            setLoading(true);
            const result = await voicifyApi.handleRequest(buildRequest(input), appId, appSecret);
            await addBotHistory(result.displayText, history);
            setHints(result.hints);
            setLoading(false);
        } catch (e) {
            console.log("error:", e);
            setLoading(false);
        }
    };

    return {
        startSession,
        resetSession,
        addUserHistory,
        makeRequest,
        hints,
        conversationHistory,
        loading,
        appId
    }
};
