import React from 'react';
import { css } from '@emotion/css';
import LevelSelector from './LevelSelector';
import ssmlParseService from '../../../services/ssmlParseService';
import SsmlParseService from '../../../services/ssmlParseService';

interface BreakSelectorProps {
    onChange: (ssml: string) => void
    ssml?: string
}

interface BreakSelectorState {
    value: number
    isCustomSelected: boolean

}

class BreakSelector extends React.Component<BreakSelectorProps, BreakSelectorState> {
    private ssmlParser = new SsmlParseService()
    private options = [
        { name: 'Very Short', value: 0.25 },
        { name: 'Short', value: 0.5 },
        { name: 'Normal', value: 1.0 },
        { name: 'Long', value: 1.5 },
        { name: 'Very Long', value: 2.0 },
    ];
    componentWillMount() {
        this.setInitialState();
        // send out the default ssml when it is first added
        var ssml = this.props.ssml ? this.props.ssml : '<break time=".5s"/>';
        this.props.onChange(ssml);
    }

    private setInitialState() {
        if (this.props.ssml) {
            // parse the break tag to set state
            const time = this.ssmlParser.getElementProperty(this.props.ssml, 'break', 'time')
            if (time) {
                this.setState({
                    ...this.state,
                    isCustomSelected: !this.options.some(o => o.value == time.replace('s', '')),
                    value: time.replace('s', '')
                })
                return;
            }
        }
        this.setState({
            ...this.state,
            value: 0.5,
            isCustomSelected: false
        })
    }

    buildSsml(value?: number) {
        value = value ? value : this.state.value
        const ssml = `<break time="${value}s"/>`;
        this.props.onChange(ssml);
    }

    handleCustomSelected() {
        this.setState({
            ...this.state,
            isCustomSelected: true
        })
        this.buildSsml();
    }

    handleOptionSelected(option) {
        this.setState({
            ...this.state,
            isCustomSelected: false,
            value: option.value
        })
        this.buildSsml(option.value);
    }

    handleCustomChanged(value: number) {
        this.setState({
            ...this.state,
            isCustomSelected: true,
            value: value
        })
        this.buildSsml(value);
    }

    render() {
        return (
            <div>
                <LevelSelector options={this.options}
                    onCustomSelected={this.handleCustomSelected.bind(this)}
                    onOptionSelected={this.handleOptionSelected.bind(this)}
                    onCustomValueChanged={this.handleCustomChanged.bind(this)}
                    value={this.state.value}
                    unit='sec'
                    isCustom={this.state.isCustomSelected}
                />
            </div>
        )
    }
}

export default BreakSelector;