import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark } from '../../constants/colors';
const calendarIcon = require("../../content/images/analytics-icons/calendar.svg");

interface DateInputFieldProps {
    value: string,
    onChange?: (e) => void,
    onBlur?: (e) => void
    onFocus?: (e) => void
    name?: string
}

const DateInputField: React.SFC<DateInputFieldProps> = (props) => (
    <div className={containerStyle}>
        <img src={calendarIcon} />
        <input name={props.name} type="date" {...props} />
    </div>
);

const containerStyle = css`
    background: #FFFFFF;
    border: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    box-shadow: inset 0px 1px 2px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    height: 32px;
    width: 154px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    >img {
        width: 20px;
        height: 20px;
    }
    
    input {
        border: none;
        background: transparent;
        box-shadow: none;
        margin-left: 12px;

        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        &::-webkit-calendar-picker-indicator,
        &::-webkit-inner-spin-button {
            display: none;
        }
    }
`;

export default DateInputField;