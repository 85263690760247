import React from 'react';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { FormikProps } from 'formik';
import Button from '../../components/general/Button';
import TextField from '../../components/forms/TextField';
import TextAreaField from '../../components/forms/TextAreaField';

export interface OrganizationInformationFormProps {
    orgContainer: OrganizationContainer
}

export interface OrganizationInformationFormData {
    name?: string
    description?: string
}

const OrganizationInformationForm: React.FC<OrganizationInformationFormProps & FormikProps<OrganizationInformationFormData>> = (props) => {

    const isLoading = props.orgContainer.state.isUpdating;

    return (
        <form onSubmit={props.handleSubmit}>
            <TextField
                name="name"
                disabled={isLoading}
                required
                value={props.values.name}
                label="Organization Name:"
                placeholder="Give your organization a name"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
            />
            <TextAreaField
                name="description"
                disabled={isLoading}
                value={props.values.description}
                label="Description:"
                placeholder="Now give it some detail"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
            />
            <Button
                loading={isLoading}
                disabled={isLoading}
                themes={["primary"]}
                text="Save"
                type="submit"
            />
        </form>
    )
}

export default OrganizationInformationForm
