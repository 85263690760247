import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import { FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import TextField from "../../../../../shared/components/forms/TextField";
import Button from "../../../../../shared/components/general/Button";
import styled from "@emotion/styled";
import { css } from "@emotion/css";
import {
  color_shades_darker,
  color_shades_dark,
  silver_two,
  text_blue,
  dark_grey_blue,
  color_shades_darkest,
} from "../../../../../shared/constants/colors";
import CheckboxField from "../../../../../shared/components/forms/CheckboxField";
import ApplicationModel from "../../../../../shared/models/applications/api/ApplicationModel";
import TemplateFormSectionModel from "../../../../../shared/models/templating/api/TemplateFormSectionModel";
import TemplateFormFieldModel from "../../../../../shared/models/templating/api/TemplateFormFieldModel";
import OrganizationContainer from "../../../../../shared/state/containers/OrganizationContainer";
import ApplicationContainer from "../../../../../shared/state/containers/ApplicationContainer";
import CollapsingPanel from "../../../../../shared/components/structure/Panels/CollapsingPanel";
import VerticalSeparator from "../../../../../shared/components/structure/VerticalSeparator";
import SearchField from "../../../../../shared/components/forms/SearchField";
import { breakpoint_small } from "../../../../../shared/constants/breakpoints";

const deleteIcon = require("../../../../../shared/content/images/custom-assistant-deployment/delete.svg");
const xIcon = require("../../../../../shared/content/images/x-small.svg");
const expandIcon = require("../../../../../shared/content/images/expand-circle.svg");
const collapseIcon = require("../../../../../shared/content/images/collapse-circle.svg");
const checkIcon = require("../../../../../shared/content/images/dark-check.svg");
const searchIcon = require("../../../../../shared/content/images/search-blue.svg");
const rightArrow = require("../../../../../shared/content/images/arrow-circle-right.svg");
const lightRightArrow = require("../../../../../shared/content/images/arrow-circle-right-light.svg");
const leftArrow = require("../../../../../shared/content/images/arrow-circle-left.svg");
const lightLeftArrow = require("../../../../../shared/content/images/arrow-circle-left-light.svg");

const WAIT_INTERVAL = 800;

export interface ManageSparkLocationsFormProps {
  appContainer: ApplicationContainer;
  orgContainer: OrganizationContainer;
  commonApp: ApplicationModel;
  locationApps: ApplicationModel[];
  locationAppNames: string[];
  locationAppFormFields: TemplateFormFieldModel[];
  commonAppSections: TemplateFormSectionModel[];
  isLoadingTemplates: boolean;
  history: any;
  isSubmittingForm: boolean;
  setIsSubmittingForm: (isSubmittingForm: boolean) => void;
  padding?: string;
}

export interface ManageSparkLocationsFormData {
  commonAppName?: string;
  locationNames: string[];
  selectedFormFields: TemplateFormFieldModel[];
  deselectedFormFields: TemplateFormFieldModel[];
}

const SparkLocationsForm: React.FC<ManageSparkLocationsFormProps & FormikProps<ManageSparkLocationsFormData>> = (props) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLocationCollapsed, setIsLocationCollapsed] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredSections, setFilteredSections] = useState(props.commonAppSections);
  const [originalSelectedFormFields, setOriginalSelectedFormFields] = useState<TemplateFormFieldModel[]>();

  let timer: NodeJS.Timer = null;

  useEffect(() => {
    setOriginalSelectedFormFields(props.values.selectedFormFields);
  }, [])

  useEffect(() => {
    // set initial selectedFormFields value, since it's not set in time by the mapPropsToValues Formik function
    if (props.commonApp) {
      props.setFieldValue("commonAppName", props.commonApp?.name);
    }
    if (props.locationAppFormFields.length > 0) {
      const locationAppFormFieldsCopy = [...props.locationAppFormFields];
      for (const locationAppFormField of locationAppFormFieldsCopy) {
        const commonAppFormField = props.commonAppSections
          .flatMap((section) => section.templateFormFields)
          .find((formField) => formField.title === locationAppFormField.title);
        if (commonAppFormField) {
          locationAppFormField.id = commonAppFormField.id;
        }
      }
      props.setFieldValue("selectedFormFields", locationAppFormFieldsCopy);
    }
    if (props.locationAppNames.length > 0) {
      props.setFieldValue("locationNames", props.locationAppNames);
    } else {
      props.setFieldValue("locationNames", [""]);
    }
  }, [props.locationAppFormFields, props.locationAppNames, props.commonApp?.name]);

  useEffect(() => {
    const deselectedFormFields = originalSelectedFormFields?.filter((originalFormField) => {
      return !props.values.selectedFormFields.some((selectedFormField) => selectedFormField.title === originalFormField.title);
    });
    props.setFieldValue("deselectedFormFields", deselectedFormFields);
  }, [props.values.selectedFormFields]);

  const createFilteredSections = (search: string) => {
    if (search?.length) {
      const searchLower = search.toLowerCase();
      const filteredSections = props.commonAppSections
        .map((section) => {
          const filteredFields = section.templateFormFields.filter(
            (formField) => formField.title.toLowerCase().includes(searchLower) || section.title.toLowerCase().includes(searchLower)
          );
          return { ...section, templateFormFields: filteredFields };
        })
        .filter((section) => section.templateFormFields.length > 0);

      setFilteredSections(filteredSections);
    } else {
      setFilteredSections(props.commonAppSections);
    }
  };

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    return props.setFieldValue(fieldName, e.target.value);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, commonAppFormField: TemplateFormFieldModel) => {
    const selectedFormFieldsCopy = [...props.values.selectedFormFields];
    if (e.target.checked) {
      // add form field to selected form fields if not already there
      if (
        selectedFormFieldsCopy.some(
          (selectedFormField) => selectedFormField.id === commonAppFormField.id
        )
      ) {
        // do nothing if already checked
        return;
      } else {
        //flatMap section formFields in props.commonAppSections
        const commonAppFormFields = props.commonAppSections.flatMap(
          (section) => section.templateFormFields
        );
        const selectedFormFieldToCheck = commonAppFormFields.find(
          (formField) => formField.id === commonAppFormField.id
        );
        if (selectedFormFieldToCheck) {
          selectedFormFieldsCopy.push(selectedFormFieldToCheck);
        }
      }
    } else {
      // remove form field from selected form fields if it is there
      const selectedFormFieldToUncheck = selectedFormFieldsCopy.find(
        (selectedFormField) => selectedFormField.id === commonAppFormField.id
      );

      if (selectedFormFieldToUncheck) {
        selectedFormFieldsCopy.splice(
          selectedFormFieldsCopy.indexOf(selectedFormFieldToUncheck),
          1
        );
      }
    }
    props.setFieldValue("selectedFormFields", selectedFormFieldsCopy);
  };

  const renderSectionFormFields = (commonAppSection: TemplateFormSectionModel) => {
    if (commonAppSection.templateFormFields.length > 0) {
      return commonAppSection?.templateFormFields?.map((commonAppFormField) => {
        return (
          <CheckboxField
            label={commonAppFormField.title}
            key={commonAppFormField.id}
            name={commonAppFormField.title}
            checked={props.values.selectedFormFields.some(
              (selectedFormField) =>
                selectedFormField.title === commonAppFormField.title
            )} //checking titles because ids are different
            onChange={(e) => handleCheckboxChange(e, commonAppFormField)}
            className={checkboxField}
            disabled={props.isLoadingTemplates}
            tooltip={commonAppFormField?.label}
            tooltipPosition={"right"}
            tooltipStyle={tooltipStyle}
            tooltipContentStyle={tooltipContentStyle}
          />
        );
      });
    }
  };

  const handleNextButtonClick = () => {
    if (activeIndex < filteredSections.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePreviousButtonClick = () => {
    if (activeIndex <= filteredSections.length - 1) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleSubmitButtonClick = () => {
    setIsSubmitted(true);
  };

  const handleSearch = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    setSearch(searchEvent.target.value);
    timer = setTimeout(
      () => createFilteredSections(searchEvent.target.value),
      WAIT_INTERVAL
    );
    timer = setTimeout(() => setActiveIndex(0), WAIT_INTERVAL);
  };

  const clearSearchTerm = () => {
    setSearch("");
    setActiveIndex(0);
    setFilteredSections(props.commonAppSections);
  };

  const handleSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      clearSearchTerm();
    }
  };

  const renderLocationButtons = (index: number) => {
    return (
      <SearchButtonsWrapper>
        <div>
          <VerticalSeparator className={separatorStyle} />
        </div>

        <div>
          <StyledSearchField
            placeholder={"Search All Topics"}
            boxType={"rounded"}
            onChange={handleSearch}
            onKeyDown={handleSearchKeyDown}
            searchIcon={searchIcon}
            value={search}
            deletePosition={"right"}
            deleteIcon={xIcon}
            handleClear={clearSearchTerm}
          />
        </div>

        {index !== filteredSections.length - 1 ? (
          <ButtonWrapper>
            <StyledButton
              className={index === 0 && lightButtonStyle}
              onClick={() => (index !== 0 ? handlePreviousButtonClick() : null)}
              themes={["icon"]}
              icon={index !== 0 ? leftArrow : lightLeftArrow}
            />
            <StyledButton
              onClick={() => handleNextButtonClick()}
              themes={["icon"]}
              icon={filteredSections.length > 0 ? rightArrow : lightRightArrow}
            />
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <StyledButton
              className={filteredSections.length === 1 && lightButtonStyle}
              onClick={() =>
                filteredSections.length !== 1
                  ? handlePreviousButtonClick()
                  : null
              }
              themes={["icon"]}
              icon={filteredSections.length !== 1 ? leftArrow : lightLeftArrow}
            />
            <StyledButton
              className={lightButtonStyle}
              onClick={() => null}
              themes={["icon"]}
              icon={lightRightArrow}
            />
          </ButtonWrapper>
        )}
      </SearchButtonsWrapper>
    );
  };

  const renderSubmitButton = () => {
    return (
      <SubmitButtonWrapper>
        <StyledButton
          onClick={() => {
            handleSubmitButtonClick();
            props.handleSubmit();
          }}
          loading={props.isSubmittingForm}
          themes={["primary"]}
          text="Submit All"
          type="submit"
        />
      </SubmitButtonWrapper>
    );
  };

  const handleAddLocationButtonClick = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push("");
    isLocationCollapsed && setIsLocationCollapsed(!isLocationCollapsed);
  };

  const handleAddLocationKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  const renderAddButton = (arrayHelpers: FieldArrayRenderProps) => {
    return (
      <AddButtonWrapper>
        <Button
          themes={["flat"]}
          text="+ Add a Location"
          type="button"
          className={addLocationName}
          onClick={() => handleAddLocationButtonClick(arrayHelpers)}
        />
      </AddButtonWrapper>
    );
  };

  const renderNoFormsMessage = () => {
    return (
      <div className={noFormsMessageWrapper}>
        <p className={noMessageStyle}>{"Sorry, that topic doesn't exist!"}</p>
        <p className={xMessageStyle}> {"Click x to clear the search field."}</p>
      </div>
    );
  };

  return (
    <form>
      <CollapsingPanel
        isCollapsed={isLocationCollapsed}
        collapsedView={
          <FieldArray
            validateOnChange={false}
            name="locationNames"
            render={(arrayHelpers) => (
              <LocationsPanelWrapper>
                <div
                  onClick={() =>
                    setIsLocationCollapsed(!isLocationCollapsed)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <TextField
                    className={labelStyle}
                    fieldContainerStyle={outerFieldContainerStyle}
                    name="Locations"
                    disabled={props.isLoadingTemplates}
                    required={false}
                    value={"View Locations"}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    icon={expandIcon}
                    iconPosition="right"
                    fieldStyle={textFieldStyle}
                  />
                </div>

                {renderAddButton(arrayHelpers)}
              </LocationsPanelWrapper>
            )}
          />
        }
        expandedView={
          <FieldArray
            validateOnChange={false}
            name="locationNames"
            render={(arrayHelpers) => (
              <LocationsPanelWrapper>
                <div
                  onClick={() =>
                    setIsLocationCollapsed(!isLocationCollapsed)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <TextField
                    className={labelStyle}
                    fieldContainerStyle={outerFieldContainerStyle}
                    name="Locations"
                    disabled={props.isLoadingTemplates}
                    required={false}
                    value={"Collapse All Locations"}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    icon={collapseIcon}
                    iconPosition="right"
                    fieldStyle={textFieldStyle}
                  />
                </div>

                {props.values.locationNames.map((locationName, index) => {
                  return (
                    <TextFieldWithDeleteWrapper
                      key={`locationNames.${index}`}
                    >
                      <TextField
                        name={`locationNames.${index}`}
                        disabled={props.locationApps?.some(
                          (l) => l.name === locationName
                        )}
                        value={locationName}
                        label=""
                        placeholder="Type a location name here"
                        required={true}
                        onChange={(e) =>
                          handleTextFieldChange(e, `locationNames.${index}`)
                        }
                        onBlur={props.handleBlur}
                        fieldContainerStyle={fieldContainerStyle}
                        className={textFieldContainerStyle}
                        autoFocus={true}
                        onKeyDown={(e) => handleAddLocationKeyDown(e)}
                      />

                      {!props.locationApps.some(
                        (locationApp) => locationApp.name === locationName
                      ) && (
                          <DeleteIcon
                            src={deleteIcon}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        )}
                    </TextFieldWithDeleteWrapper>
                  );
                })}

                {renderAddButton(arrayHelpers)}
              </LocationsPanelWrapper>
            )}
          />
        }
      />

      <OutsideFormWrapper>
        <div className={OutsideFormHeader}>
          <CheckBoxSectionsTitle>
            Location-Specific Topics
          </CheckBoxSectionsTitle>

          <CheckBoxSectionsDescription>
            Select the form field topic(s) that will have different answers
            per location.
          </CheckBoxSectionsDescription>
        </div>

        <FieldArray
          name="selectedFormFields"
          render={() => (
            <CheckboxFieldWrapper>
              {!isSubmitted ? (
                Array.isArray(filteredSections) &&
                  filteredSections.length > 0 ? (
                  filteredSections.map((section, index) => {
                    if (activeIndex === index && activeIndex !== null) {
                      return (
                        <div key={index}>
                          <InsideFormWrapper>
                            <SectionWrapper
                              key={`${section.title}.${index}`}
                            >
                              <SectionTitle>
                                <InsideFormHeader>
                                  <div>{section.title}</div>
                                  {renderLocationButtons(index)}
                                </InsideFormHeader>
                              </SectionTitle>
                              {renderSectionFormFields(section)}
                              <PageNumberWrapper>
                                <p>
                                  {activeIndex + 1} /{" "}
                                  {filteredSections.length}
                                </p>
                              </PageNumberWrapper>
                            </SectionWrapper>
                          </InsideFormWrapper>
                          {renderSubmitButton()}
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <>
                    <InsideFormWrapper>
                      <SectionWrapper key={``}>
                        <SectionTitle>
                          <InsideFormHeader>
                            {renderNoFormsMessage()}
                            {renderLocationButtons(activeIndex)}
                          </InsideFormHeader>
                        </SectionTitle>
                      </SectionWrapper>
                    </InsideFormWrapper>
                    {renderSubmitButton()}
                  </>
                )
              ) : (
                <ThankYouWrapper>
                  <TextField
                    name="Submitted"
                    disabled={props.isLoadingTemplates}
                    required={false}
                    value={
                      "Thank you for submitting the location-specific form fields!"
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    icon={checkIcon}
                    iconPosition="right"
                  />
                </ThankYouWrapper>
              )}
            </CheckboxFieldWrapper>
          )}
        />
      </OutsideFormWrapper>
    </form>
  );
};

const tooltipStyle = css`
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 8px;
  border: solid 1px ${text_blue};
  span {
    color: ${text_blue};
  }
`;

const tooltipContentStyle = css`
  .rc-tooltip-inner {
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    z-index: 120;
    color: ${color_shades_darker};
    background-color: white;
    border: 1px solid ${color_shades_dark};
    padding: 8px;
    min-height: 0;
    box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
  }
`;

const labelStyle = css`
  padding: 0px 0px;
`;

const textFieldStyle = css`
  cursor: pointer;
  color: ${dark_grey_blue};
  font-family: Muli;
  font-weight: bold;
  text-align: left;
  width: 100%;
  padding: 12px 42px;
  font-size: 14px;
  background: transparent;
  border: none;
`;

const separatorStyle = css`
  width: 1px;
  height: 30px;
  color: ${silver_two}
  ${breakpoint_small} {
    display: none;
  }
`;

const CheckBoxSectionsDescription = styled.p`
  font-size: 14px;
  color: ${color_shades_darker};
  margin: 0px 0px 16px 34px;
`;

const OutsideFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const InsideFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoint_small} {
    margin: 32px 0;
  }
`;

const SearchButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin: 0px;
  }
  ${breakpoint_small} {
    margin: 8px 0;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  &:first-of-type {
    margin-left: 16px;
  }
`;

const OutsideFormHeader = css`
  margin-left: 8px;
`;

const SectionTitle = styled.div`
  font-size: 14px;
  margin-top: -4px;
  margin-left: 16px;
  margin-bottom: 0px;
`;

const CheckBoxSectionsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin: -32px 32px 8px 34px;
`;

const CheckboxFieldWrapper = styled.div`
  padding: 0px 28px 32px 28px;
  ${breakpoint_small} {
    padding: 8px;
  }
`;

const checkboxField = css`
  .check-label {
    font-weight: 500;
  }
  margin-left: 16px;
`;

const fieldContainerStyle = css``;

const outerFieldContainerStyle = css`
  width: 100%;
  display: flex;
  border-bottom: solid 1px ${color_shades_dark};
  align-items: center;
  height: 60px;
  border-radius: 0px;
  border: none;
  border-top: solid 1px ${color_shades_dark};
  border-bottom: solid 1px ${color_shades_dark};
  background: white;
  margin: 16px 0px -30px 0px;
  padding-right: 29px;
`;

const textFieldContainerStyle = css`
  margin-bottom: 0px;
`;

const DeleteIcon = styled.img`
  cursor: pointer;
  margin: 32px 12px 12px 12px;
`;

const PageNumberWrapper = styled.div`
  display: flex;
  justify-content: right;
  font-size: 12px;
  color: ${silver_two};
  margin: -4px 16px 16px 0px;
`;

const TextFieldWithDeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px 0px 32px;
`;

const LocationsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 0px;
  padding-bottom: 0px;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 48px 0px 24px -6px;
  ${breakpoint_small} {
    justify-content: flex-end;
  }
`;

const InsideFormWrapper = styled.div`
  background: white;
  border: 1px solid ${color_shades_dark};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0px 0px 0px 2px;
  margin: 0px 0px 0px 0px;
`;

const ThankYouWrapper = styled.div`
  margin-top: -10px;
`;

const lightButtonStyle = css`
  cursor: not-allowed;
`;

const addLocationName = css`
    color: ${text_blue}
    font-size: 14px;
    box-shadow: none;
`;

const noFormsMessageWrapper = css`
  display: flex;
  flex-direction: column;
`;

const noMessageStyle = css`
  font-size: 14px;
  padding-top: 2px;
`;

const xMessageStyle = css`
  padding-top: 6px;
  color: ${color_shades_dark};
  font-style: italic;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: -10px -12px 0px 0px;
`;

const StyledButton = styled(Button)`
  margin: 0px 9px 0px 6px;
`;

const StyledSearchField = styled(SearchField)`
  padding: 16px 16px 0px 20px;
  .field-container {
    height: 26px;
    border: solid 1.2px ${silver_two};
    box-shadow: 0 0 0 0;
    margin-top: 0 px;
    img {
      margin-left: 8px;
    }
  }
  input {
    font: Muli;
    color: ${color_shades_darkest};
    font-weight: normal;
    letter-spacing: 1px;
    ::placeholder {
      font: Muli;
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      letter-spacing: 1px;
      color: ${silver_two};
    }
  }
  ${breakpoint_small} {
    display: none;
  }
`;

export default SparkLocationsForm;
