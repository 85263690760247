import IResult from "../../models/result/IResult";
import WebhookModel from "../../models/webhooks/api/WebhookModel";
import { sendGet, sendPost, sendPut, sendDelete } from "./generic";
import WebhookTypeModel from "../../models/webhooks/api/WebhookTypeModel";
import NewWebhookRequest from "../../models/webhooks/api/NewWebhookRequest";
import UpdateWebhookRequest from "../../models/webhooks/api/UpdateWebhookRequest";
import WebhookAttachmentModel from "../../models/webhooks/api/WebhookAttachmentModel";
import WebhookSensitiveDataModel from "../../models/webhooks/api/WebhookSensitiveDataModel";

export const getWebhooksForOrg = (organizationId: string): Promise<IResult<WebhookModel[]>> => {
    return sendGet(`/Webhook/fromOrganization/${organizationId}`);
}

export const getWebhooksForApp = (appId: string): Promise<IResult<WebhookModel[]>> => {
    return sendGet(`/Webhook/fromApplication/${appId}`);
}

export const getWebhookTypes = (): Promise<IResult<WebhookTypeModel[]>> => {
    return sendGet("/Webhook/types");
}

export const createWebhook = (organizationId: string, model: NewWebhookRequest): Promise<IResult<WebhookModel>> => {
    return sendPost(`/Webhook/${organizationId}`, model);
}

export const updateWebhook = (webhookId: string, model: UpdateWebhookRequest): Promise<IResult<WebhookModel>> => {
    return sendPut(`/Webhook/${webhookId}`, model);
}

export const removeOrganizationWebhook = (webhookId: string): Promise<IResult<boolean>> => {
    return sendDelete(`/Webhook/${webhookId}`);
}

export const createFullWebhook = (model: WebhookModel): Promise<IResult<WebhookModel>> => {
    return sendPost(`/Webhook/full`, model);
}

export const updateFullWebhook = (webhookId: string, model: WebhookModel): Promise<IResult<WebhookModel>> => {
    return sendPut(`/Webhook/${webhookId}/full`, model);
}

export const getWebhookAttachmentsForApplication = (appId: string): Promise<IResult<WebhookAttachmentModel[]>> => {
    return sendGet(`/Webhook/application/${appId}/attachments`);
}

export const generateSensitiveDataKey = (webhookId: string): Promise<IResult<WebhookSensitiveDataModel>> => {
    return sendPut(`/Webhook/${webhookId}/sensitiveDataKey`);
}

export const removeSensitiveDataKey = (webhookId: string): Promise<IResult<boolean>> => {
    return sendDelete(`/Webhook/${webhookId}/sensitiveDataKey`);
}
