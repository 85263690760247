import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { string } from "yup";
import { VOICIFY_NLP_MODEL_TRAINING_STATE } from "../constants/Keys";
import * as voicifyApi from '../api';
import AssistantApplicationModel from "../models/applications/api/AssistantApplicationModel";
import moment, { Moment } from "moment";
import IResult from "../models/result/IResult";

interface ModelTrainingIndicator {
    type: "success" | "building" | "error",
    message: string
};

interface ApplicationNlpModelTrainingState {
    applicationId: string,
    latestNlpModelId: string,
    isCurrentlyTraining: boolean,
    lastTrainingStart?: Moment
}

const TRAINING_TIMEOUT_MINUTES = 20;

function useNlpModelTrainingContainer() {
    const [indicator, setIndicator] = useState<ModelTrainingIndicator>(null);
    const [currentApp, setCurrentApp] = useState<AssistantApplicationModel>(null);
    const [isPolling, setIsPolling] = useState(false);
    const [pollingTrigger, setPollingTrigger] = useState(0);

    useEffect(() => {
        if (isPolling) {
            pollForNlpModelTrain();
        }
    }, [isPolling, pollingTrigger]);

    useEffect(() => {
        if (!currentApp?.id) {
            setIsPolling(false);
        }
    }, [currentApp]);

    const pollForNlpModelTrain = async () => {// wait 4 seconds first.
        var appResult = await loadAssistantApplication(currentApp?.id);
        if (appResult.resultType != "Ok")
            setErrorIndicator();
        const app = appResult.data;
        let storedState = getStoredState();
        let currentAppState = storedState.find(s => s.applicationId == app.id);  
        if (!currentAppState)
            setIsPolling(false); 
        else {
            const idx = storedState.indexOf(currentAppState);
            storedState.splice(idx, 1);        
        }
        
        const newAppNlpModelId = getNlpModelId(app);
        if (newAppNlpModelId && currentAppState.latestNlpModelId !== newAppNlpModelId) {
            setSuccessIndicator();
            setIsPolling(false);
            currentAppState.isCurrentlyTraining = false;
        } else if (moment().diff(currentAppState.lastTrainingStart, 'minutes') > TRAINING_TIMEOUT_MINUTES // timed out
            || (currentAppState.latestNlpModelId && !newAppNlpModelId) // removed nlp
        ) {
            setErrorIndicator();
            setIsPolling(false);
            currentAppState.isCurrentlyTraining = false;
        } else {
            await new Promise(r => setTimeout(r, 4000)); 
            setPollingTrigger(pollingTrigger + 1);
        }

        storedState.push(currentAppState);   
        storeState(storedState);
        setCurrentApp(app);
    }

    const setNewApplication = async (app: AssistantApplicationModel) => {
        if (app?.id && app?.id !== currentApp?.id) {
            setCurrentApp(app);
            let storedState = getStoredState();
            let currentAppState = storedState.find(s => s.applicationId == app.id);   
            if (currentAppState) {
                const idx = storedState.indexOf(currentAppState);
                storedState.splice(idx, 1);              
            } else {
                currentAppState = {
                    applicationId: app.id,
                    isCurrentlyTraining: false,
                    latestNlpModelId: getNlpModelId(app)
                }
            }
            if (currentAppState.isCurrentlyTraining) {
                if (currentAppState.latestNlpModelId !== getNlpModelId(app)) {
                    currentAppState.isCurrentlyTraining = false;
                    setSuccessIndicator();
                    setIsPolling(false);
                } else if (moment().diff(currentAppState.lastTrainingStart, 'minutes') > TRAINING_TIMEOUT_MINUTES) {
                    setErrorIndicator();
                    currentAppState.isCurrentlyTraining = false;
                    setIsPolling(false);
                } else {
                    setIsPolling(true);
                    setBuildingIndicator();
                    storedState.push(currentAppState);
                    storeState(storedState);
                    await new Promise(r => setTimeout(r, 4000)); 
                    setPollingTrigger(pollingTrigger + 1);
                }
            } else {
                setIsPolling(false);
                clear();
            }
            if (!storedState.some(s => s.applicationId === currentAppState.applicationId)) {
                storedState.push(currentAppState);
                storeState(storedState);                
            }
        }
    }

    const startPollingForTraining = async() => {
        let storedState = getStoredState();
        let currentAppState = storedState.find(s => s.applicationId == currentApp.id);
        if (currentAppState) {
            const idx = storedState.indexOf(currentAppState);
            storedState.splice(idx, 1);
        }
        currentAppState = {
            applicationId: currentApp.id,
            isCurrentlyTraining: true,
            latestNlpModelId: getNlpModelId(currentApp),
            lastTrainingStart: moment()
        }
        storedState.push(currentAppState);
        setBuildingIndicator();        
        await new Promise(r => setTimeout(r, 4000));
        setIsPolling(true);
        setPollingTrigger(pollingTrigger + 1);
        storeState(storedState);
    }

    const setBuildingIndicator = () => {
        setIndicator({
            type: "building",
            message: "Your NLP Model is currently building"
        })
    }

    const queueTraining = async (appId: string) => {
        await voicifyApi.queueDeployments(appId);
    }

    const setErrorIndicator = () => {
        setIndicator({
            type: "error",
            message: "Your NLP Model failed to build"
        })
    }

    const setSuccessIndicator = () => {
        setIndicator({
            type: "success",
            message: "Your NLP Model has been built successfully"
        })
    }

    // we use the most recent default lang production environment nlp model id
    const getNlpModelId = (app: AssistantApplicationModel): string => {
        const env = app.environments?.find(e => e.name === "Production");
        var lang = env?.languages?.find(l => l.languageId === app.defaultLanguageId);
        if (lang)
            return lang.nlpModelId;
        
        return env?.nlpModelId
    }

    const getStoredState = (): ApplicationNlpModelTrainingState[] => {
        let storedState: ApplicationNlpModelTrainingState[] = [];
        const storedTrainingState = localStorage.getItem(VOICIFY_NLP_MODEL_TRAINING_STATE);
        if (storedTrainingState)
            storedState = JSON.parse(storedTrainingState);
        return storedState;
    }

    const storeState = (state: ApplicationNlpModelTrainingState[]) => {
        const json = JSON.stringify(state);
        localStorage.setItem(VOICIFY_NLP_MODEL_TRAINING_STATE, json);
    }

    const loadAssistantApplication = async (applicationId): Promise<IResult<AssistantApplicationModel>> => {
        try {
            var result = await voicifyApi.getAssistantApplication(applicationId);
            setCurrentApp(result.data);
            return result;
        } catch (e){
            console.log(e);
        }
    } 

    const clear = () => {
        setIndicator(null);
    }
    return {
        indicator,
        currentApp,
        clear,
        setNewApplication,
        startPollingForTraining,
        loadAssistantApplication,
        queueTraining
    }
}

const NlpModelTrainingContainer = createContainer(useNlpModelTrainingContainer);
export default NlpModelTrainingContainer;
