import React from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import TooltipWrapper, { TooltipWrapperProps } from './TooltipWrapper';


interface ConditionalTooltipWrapperProps extends TooltipWrapperProps {
    showTooltip?: boolean
}
const ConditionalTooltipWrapper: React.FC<ConditionalTooltipWrapperProps> = (props) => (
    <>
        {
            props.showTooltip ? 
            <TooltipWrapper {...props}>
                {props.children}
            </TooltipWrapper>
            :
            props.children
        }
    </>
)

export default ConditionalTooltipWrapper;