import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { color_shades_dark, color_shades_darkest, color_variants_blue_2, color_variants_green_2, color_variants_red_2, color_variants_shadow_10 } from "../../../../../shared/constants/colors";
import Loader from "../../../../../shared/components/general/Loader";
import { TrainingState } from "../../../../../shared/hooks/SparkDeploymentContainer";

const closeIcon = require('../../../../../shared/content/images/close_icon.svg');
const redIcon = require('../../../../../shared/content/images/nlp-icons/training-indicator-red.svg');
const greenIcon = require('../../../../../shared/content/images/nlp-icons/training-indicator-green.svg');

interface SparkPublishingIndicatorProps {
    trainingState: TrainingState
    trainingMessage: string
    errorMessage: string
    trainedMessage: string
}

const SparkPublishingIndicator: React.FC<SparkPublishingIndicatorProps> = (props) => {
    const [hide, setHide] = useState(true);
    const [previousState, setPreviouisState] = useState<TrainingState>(null);
    useEffect(() => {
        if (!previousState?.length) {
            if (props.trainingState === "training") {
                setPreviouisState("training");
                setHide(false);
            }            
        } else if(previousState !== props.trainingState) {
            setHide(false);
            setPreviouisState(props.trainingState);
        }
    }, [props.trainingState])
    const minimizeIndicator = () => {
        setHide(true);
    };

    const renderIndicator = () => {
        if (hide)
            return <></>
        switch (props.trainingState) {
            case "training":
                return (
                    <IndicatorWrapper onClick={() => minimizeIndicator()} >
                        <BlueIndicator>
                            <StyledLoader/>
                            <IndicatorText>{props.trainingMessage}</IndicatorText>
                            <CloseIcon src={closeIcon} />
                        </BlueIndicator>
                    </IndicatorWrapper>
                )
            case "trained":
                return (
                    <IndicatorWrapper onClick={() => minimizeIndicator()} >
                        <GreenIndicator>
                            <StyledLoader greenStatic />
                            <IndicatorText>{props.trainedMessage}</IndicatorText>
                            <CloseIcon src={closeIcon} />
                        </GreenIndicator>
                    </IndicatorWrapper>
                )
            case "error":
                return (
                    <IndicatorWrapper onClick={() => minimizeIndicator()} >
                        <RedIndicator>
                            <StyledLoader />
                            <IndicatorText>{props.errorMessage}</IndicatorText>
                            <CloseIcon src={closeIcon} />
                        </RedIndicator>
                    </IndicatorWrapper>
                )
            default:
                return <></>
        }
    };

    return renderIndicator();
}

export default SparkPublishingIndicator;

const IndicatorText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${color_shades_darkest};
    margin: auto auto auto 100px;
`;

const StyledLoader = styled(Loader)`
    width: 50px;
    height: 50px;
    top: 25%;
    left: 5%;  
    margin: 0;
`;

const CloseIcon = styled.img`
    margin: auto 32px auto auto;
    width: 12px;
    height: 12px;
`;

const IndicatorWrapper = styled.div`
    position: fixed;
    bottom: 32px;
    right: 32px;
    width: 528px;
    height: 104px;
    border-radius: 8px;
    border: 1px solid ${color_shades_dark};
    box-shadow: 0px 4px 20px ${color_variants_shadow_10};
    z-index: 1100;
    background: white;
    box-sizing: border-box;
    cursor: pointer;
`;

const Indicator = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    border-radius: 8px;
    width: 100%;
`;

const GreenIndicator = styled(Indicator)`
    background: ${color_variants_green_2};
`;

const RedIndicator = styled(Indicator)`
    background: ${color_variants_red_2};
`;

const BlueIndicator = styled(Indicator)`
    background: ${color_variants_blue_2};
`;