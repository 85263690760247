import React from 'react';
import { css } from '@emotion/css';
import SsmlElementSelector, { SsmlElement } from './SsmlElementSelector';
const breakIcon = require('../../../content/images/ssml/pause-dark.svg');
const audioIcon = require('../../../content/images/ssml/audio-file-dark.svg');
const sayAsIcon = require('../../../content/images/ssml/volume-control-settings-dark.svg');
const effectIcon = require('../../../content/images/ssml/equalizer-dark.svg');

interface SsmlElementMenuProps {
    onElementSelected: (element: SsmlElement) => void
}

const containerStyle = css`
    display: flex;
    flex-wrap: wrap;

    > * + * {
        margin-left: 20px;
    }
`
const SsmlElementMenu: React.SFC<SsmlElementMenuProps> = (props) => (
    <div className={containerStyle}>
        <SsmlElementSelector
            options={[
                { value: 'break', name: 'Pause', icon: breakIcon },
                { value: 'audio', name: 'Audio', icon: audioIcon },
            ]}
            title="insert"
            onOptionSelected={props.onElementSelected} />
        <SsmlElementSelector
            options={[
                { value: 'say-as', name: 'Say as', icon: sayAsIcon },
                { value: 'prosody', name: 'Effect', icon: effectIcon },
            ]}
            title="alter"
            onOptionSelected={props.onElementSelected} />
    </div>

)

export default SsmlElementMenu;