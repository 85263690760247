import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import Tooltip from 'rc-tooltip';
import OverflowMenu, { OverflowOption } from './OverflowMenu';
import { text_blue } from '../../../constants/colors';

export interface SmallOverflowMenuProps {
    isActive?: boolean
    onOptionSelected?: (optionName: string) => void
    options: OverflowOption[]
    label?: string
    className?: string
    buttonClassName?: string
    optionClassName?: string
    menuClassName?: string
    topOptionStyle?: string
    bottomOptionStyle?: string
    setIsOverFlowMenuOpen?: (bool: boolean) => void
}

const SmallOverflowMenu: React.FC<SmallOverflowMenuProps> = (props) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (props.isActive != isActive) {
            setIsActive(props.isActive)
            updateSetIsOverFlowMenuOption(props.isActive)
        }
    }, [])

    const handleClick = () => {
        setIsActive(!isActive)
        updateSetIsOverFlowMenuOption(!props.isActive)
    }

    const handleMenuClose = () => {
        setIsActive(false)
        updateSetIsOverFlowMenuOption(false)
    }

    const updateSetIsOverFlowMenuOption = (val: boolean) => {
        if (props.setIsOverFlowMenuOpen) {
            props.setIsOverFlowMenuOpen(val)
        }
    }

    return (
        <div className={`${rowCss} ${props.className}`}>
            {props.label != null && <span onClick={handleClick.bind(this)} className={'label'}>{props.label}</span>}
            <div className={containerStyle}>
                <Tooltip mouseEnterDelay={.5} overlayClassName={tooltipContentStyle} trigger={['hover']} placement="top" overlay={<span>Show options</span>}>
                    <span onClick={handleClick.bind(this)} className={`${overflowButtonStyle} ${isActive ? 'active' : ''} ${props.buttonClassName}`}>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </span>
                </Tooltip>
                {isActive ?
                    <OverflowMenu
                        onClose={handleMenuClose.bind(this)}
                        onOptionSelected={props.onOptionSelected}
                        options={props.options}
                        optionClassName={props.optionClassName}
                        className={props.menuClassName}
                        topOptionStyle={props.topOptionStyle}
                        bottomOptionStyle={props.bottomOptionStyle}
                    >
                        {props.children}
                    </OverflowMenu>
                    : null}
            </div>
        </div>
    )
}

const rowCss = css`
    display: flex;
    flex-direction: row;   
    .label {        
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        padding-right: 5px;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        cursor: pointer;
        color: ${text_blue};
    }
    border: 0px;
`;

const overflowButtonStyle = css`
    border: solid 1px #007dbb;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    cursor: pointer;
    .circle {
        width: 2px;
        height: 2px;
        border-radius: 50%;
        border: solid 1px #007dbb;
        margin: 1px;
    }

    &:hover, &.active { 
        box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
        border: solid 1px #007dbb;
        .circle {
            border: solid 1px #007dbb;
        }
    }

    &.active {
        background-color: rgba(0, 125, 187, 0.1);
    }
`;


const tooltipContentStyle = css`
    .rc-tooltip-inner{
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0.6px;
        z-index: 120;
        color: white;
        background-color: #304562;
        box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
        padding: 8px;
        min-height: 0;
    }
`

const containerStyle = css`
    position: relative;
`

export default SmallOverflowMenu;