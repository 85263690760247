import React, { ChangeEvent, useEffect, useState } from 'react';
import SessionAnalyticsContainerOld from '../../../hooks/SessionAnalyticsContainerOld';
import styled from '@emotion/styled';
import Loader from '../../../components/general/Loader';
import SimulatorContainer from '../../../hooks/SimulatorContainer';
import TextField from '../../../components/forms/TextField';
import { FieldArray, FormikProps } from 'formik';
import { ApplicationOrderingStatisticsFormData } from './ApplicationOrderingStatisticsFormData';
import { css } from '@emotion/css';
import { ApplicationOrderingStatisticsProps } from './ApplicationOrderingStatisticsForm';
import Button from '../../../components/general/Button';
import { text_blue } from '../../../constants/colors';
import { OrderingStatisticsPreferences } from './ApplicationStatistics';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import IconTooltip from '../../../components/general/IconTooltip';

const deleteIcon = require("../../../content/images/custom-assistant-deployment/delete.svg");
const csvIcon = require("../../../content/images/analytics-icons/csv.svg");

const ApplicationOrderingStatistics: React.FC<ApplicationOrderingStatisticsProps & FormikProps<ApplicationOrderingStatisticsFormData>> = (props) => {
    const [sessionsLoaded, setSessionsLoaded] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const simulatorContainer = SimulatorContainer.useContainer();
    const sessionContainer = SessionAnalyticsContainerOld.useContainer();

    useEffect(() => {
        simulatorContainer.startNewSession();
        loadMore();
        getLocalStoragePhoneNumersToIgnoreAndUse();
    }, [])

    useEffect(() => {
        if (!sessionsLoaded) return;
        props.calculateFilteredSessions(props.values);
    }, [sessionContainer.sessions])

    useEffect(() => {
        if (startDate === "" && props.filter.startDate) {
            setStartDate(props.filter?.startDate);
            setEndDate(props.filter?.endDate);
            return;
        }
        if (props.filter?.startDate !== startDate || props.filter?.endDate !== endDate) {
            setStartDate(props.filter?.startDate);
            setEndDate(props.filter?.endDate);
            setSessionsLoaded(false);
            simulatorContainer.startNewSession();
            sessionContainer.loadMoreContentHits(props.applicationId, props.filter);
        }
    }, [props.filter])

    useEffect(() => {
        if (sessionContainer.moreToLoad)
            loadMore();
        else
            setSessionsLoaded(true);
    }, [sessionContainer.sessions, sessionContainer.moreToLoad])

    const loadMore = async () => {
        await sessionContainer.loadMoreContentHits(props.applicationId, props.filter);
    }

    const getLocalStoragePhoneNumersToIgnoreAndUse = () => {
        const phoneNumbersInLocalStorage: OrderingStatisticsPreferences[] = JSON.parse(localStorage.getItem("orderingStatisticsPreferences"));
        let ignoredCallerNumbers = [""];
        let assistantNumbers = [""];
        if (phoneNumbersInLocalStorage) {
            const currentAppLocalStoragePreferences = phoneNumbersInLocalStorage.find(p => p.applicationId === props.applicationId);
            if (currentAppLocalStoragePreferences) {
                if (currentAppLocalStoragePreferences.callerNumbersToIgnore) {
                    ignoredCallerNumbers = currentAppLocalStoragePreferences.callerNumbersToIgnore;
                }
                if (currentAppLocalStoragePreferences.assistantNumbersToUse) {
                    assistantNumbers = currentAppLocalStoragePreferences.assistantNumbersToUse;
                }
            }
        }
        props.setFieldValue("callerNumbersToIgnore", ignoredCallerNumbers);
        props.setFieldValue("assistantNumbersToUse", assistantNumbers);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        return props.setFieldValue(fieldName, e.target.value);
    };

    if (props.isLoadingApplicationOrderingStatistics) return <Loader />

    return (
        <form onSubmit={props.handleSubmit} >
            <Title>AI Ordering Summary Report Filter</Title>
            <SectionWrapper>
                <Button
                    disabled={!sessionsLoaded}
                    loading={!sessionsLoaded}
                    type="submit"
                    themes={['secondary-small', 'end-tight']}
                    rightIcon={csvIcon}
                    text="Download"
                />
                <FormFieldsWrapper>
                    <FieldArray
                        validateOnChange={false}
                        name="assistantNumbersToUse"
                        render={arrayHelpers => (
                            <FieldWrapper>
                                <TextFieldWrapper>
                                    <SubtitleWrapper>
                                        <Subtitle>Assistant Numbers to Use</Subtitle>
                                        <IconTooltip place='right' text=" Go to Settings > Deployment > Telephony to get your assistant's number." className={iconTooltipStyle} />
                                    </SubtitleWrapper>
                                    <CustomHorizonalSeparator />
                                    {
                                        props.values.assistantNumbersToUse.map((phoneNumber, index) => {
                                            return (
                                                <TextFieldWithDeleteWrapper key={`assistantNumbersToUse.${index}`}>
                                                    <TextField
                                                        name={`assistantNumbersToUse.${index}`}
                                                        disabled={false}
                                                        value={phoneNumber}
                                                        label="Assistant Phone Number"
                                                        placeholder="Type an assistant number to use here"
                                                        onChange={(e) => handleChange(e, `assistantNumbersToUse.${index}`)}
                                                        onBlur={props.handleBlur}
                                                        fieldContainerStyle={fieldContainerStyle}
                                                        className={textFieldContainerStyle}
                                                    />
                                                    <DeleteIcon src={deleteIcon} onClick={() => arrayHelpers.remove(index)} />
                                                </TextFieldWithDeleteWrapper>
                                            )
                                        })
                                    }
                                    <ButtonWrapper>
                                        <Button
                                            themes={["flat"]}
                                            text="+ Add Phone Number"
                                            type="button"
                                            className={addPhoneNumber}
                                            onClick={() => { arrayHelpers.push("") }}
                                        />
                                    </ButtonWrapper>
                                </TextFieldWrapper>
                            </FieldWrapper>
                        )}
                    />
                    <FieldArray
                        validateOnChange={false}
                        name="callerNumbersToIgnore"
                        render={arrayHelpers => (
                            <FieldWrapper>
                                <TextFieldWrapper>
                                    <SubtitleWrapper>
                                        <Subtitle>Caller Numbers to Ignore</Subtitle>
                                        <IconTooltip place='right' text='i.e. Phone numbers used to test the assistant.' className={iconTooltipStyle} />
                                    </SubtitleWrapper>
                                    <CustomHorizonalSeparator />
                                    {
                                        props.values.callerNumbersToIgnore.map((phoneNumber, index) => {
                                            return (
                                                <TextFieldWithDeleteWrapper key={`callerNumbersToIgnore.${index}`}>
                                                    <TextField
                                                        name={`callerNumbersToIgnore.${index}`}
                                                        disabled={false}
                                                        value={phoneNumber}
                                                        label="Caller Phone Number"
                                                        placeholder="Type a caller number to ignore here"
                                                        onChange={(e) => handleChange(e, `callerNumbersToIgnore.${index}`)}
                                                        onBlur={props.handleBlur}
                                                        fieldContainerStyle={fieldContainerStyle}
                                                        className={textFieldContainerStyle}
                                                    />
                                                    <DeleteIcon src={deleteIcon} onClick={() => arrayHelpers.remove(index)} />
                                                </TextFieldWithDeleteWrapper>
                                            )
                                        })
                                    }
                                    <ButtonWrapper>
                                        <Button
                                            themes={["flat"]}
                                            text="+ Add Phone Number"
                                            type="button"
                                            className={addPhoneNumber}
                                            onClick={() => { arrayHelpers.push("") }}
                                        />
                                    </ButtonWrapper>
                                </TextFieldWrapper>
                            </FieldWrapper>
                        )}
                    />
                </FormFieldsWrapper>
            </SectionWrapper>
        </form>
    )
}

const iconTooltipStyle = css`
    margin-left: 8px;
    margin-bottom: 16px;
`;

const SubtitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CustomHorizonalSeparator = styled(HorizontalSeparator)`
    flex-grow: initial;
`;

const textFieldContainerStyle = css`
    margin-bottom: 0;  
`;

const addPhoneNumber = css`
    color: ${text_blue}
    font-size: 14px;
    box-shadow: none;
    margin: inherit;
    padding: inherit;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;
`;

const TextFieldWithDeleteWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #CBCCD2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 16px 32px 32px;
    background: white;
    margin: 16px 32px 96px 32px;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
`;

const FormFieldsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Subtitle = styled.h3`
    margin-bottom: 16px;
`;

const Title = styled.h2`
    margin-left: 32px;
`;

const TextFieldWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: left;
`;

const FieldWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 0 32px;
`;

const DeleteIcon = styled.img`
    cursor: pointer;
    margin: 32px 12px 12px 12px;
`;

const fieldContainerStyle = css`
    height: 48px;
`;

export default ApplicationOrderingStatistics;