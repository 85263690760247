import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, color_shades_dark, color_shades_light, color_shades_darker } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';

interface RadioGroupProps {
    onChange: (selectedOption: RadioOption) => void
    options: RadioOption[]
    value: string
    disabled?: boolean
}

export interface RadioOption {
    label: string
    value: string
    description?: string
    icon?: string
    className?: string
}

const RadioCardGroup: React.FC<RadioGroupProps> = (props) => {

    const handleOptionSelected = (option: RadioOption) => {
        if (props.onChange && !props.disabled) props.onChange(option);
    };

    return (
        <div className={`${containerStyle} ${props.disabled ? 'disabled' : ''}`}>
            {props.options.map((option, index) => (
                <div className={`${radioOptionStyle} ${option.className ? option.className: ''}`} key={index} onClick={handleOptionSelected.bind(this, option)}>
                    <div className="check-container">
                        {option.value == props.value ? <div className="selected-option" /> : null}
                    </div>
                    <div className="content-container">
                        {option.icon ? <img className="option-icon" src={option.icon} /> : null}
                        <p>{option.label}</p>
                        {option.description ? <div className="option-description">{option.description}</div> : null}
                    </div>
                </div>
            ))}
        </div>
    )
}

const containerStyle = css`
    margin: 16px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 -8px;
    ${breakpoint_small} {
        flex-direction: column;
    }
    .option-icon {
        margin-left: 16px;
    }
    .option-description {
        color: ${color_shades_darker};
        font-style: italic;
        font-size: 14px;
        text-align: center;
    }
    &.disabled {
        .selected-option {
            background: ${color_shades_light};
        }
    }
`;

const radioOptionStyle = css`
    margin: 8px;
    cursor: pointer;
    border-radius: 5px;
    border: solid 1px ${color_shades_dark};
    background-color: #ffffff;
    min-height: 120px;
    min-width: 120px;
    flex: 1;
    position: relative;
    padding: 8px;
    display: flex;
    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .check-container {
        position: absolute;
        min-width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        border: solid 1px #cccccc;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
        margin-left: 6px;
    }
    .selected-option {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-image: linear-gradient(225deg, #76c3b5, #3ea6ac 72%, #5296b3);
    }
    p {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin: 16px;
        word-break: break-word;
        text-align: center;
    }
`

export default RadioCardGroup;