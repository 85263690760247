const alexaIcon = require("../../content/images/platforms/alexa.svg");
const azureIcon = require("../../content/images/platforms/azure-bot.svg");
const customBotIcon = require("../../content/images/platforms/custom-chat.svg");
const cortanaIcon = require("../../content/images/platforms/cortana.svg");
const dialogflowIcon = require("../../content/images/platforms/dialogflow.svg");
const googleIcon = require("../../content/images/platforms/google.svg");

export const getPlatformIcon: (platform: string) => string = (platform) => {
    switch (platform?.toLocaleLowerCase()) {
        case 'alexa': return alexaIcon;
        case 'google':
        case 'googleassistant': return googleIcon;
        case 'cortana': return cortanaIcon;
        case 'dialogflow': return dialogflowIcon;
        case 'webchat': return azureIcon;
    }

    return customBotIcon;
}