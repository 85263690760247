import React from 'react';
import { Subscribe } from 'unstated';
import OrganizationDashboardContent from './components/OrganizationDashboardContent';
import { RouteComponentProps } from 'react-router';
import OrganizationContainer from '../../../../shared/state/containers/OrganizationContainer';
import ApplicationContainer from '../../../../shared/state/containers/ApplicationContainer';
import TitleStateContainer from '../../../../shared/state/containers/TitleStateContainer';

interface BasicOrganizationDashboardProps {}

const BasicOrganizationDashboard: React.FC<RouteComponentProps<BasicOrganizationDashboardProps>> = (props) => {

    return (
        <Subscribe to={[OrganizationContainer, ApplicationContainer, TitleStateContainer]}>
            {(
                orgContainer: OrganizationContainer,
                appContainer: ApplicationContainer,
                titleContainer: TitleStateContainer
            ) => (
                <OrganizationDashboardContent
                    history={props.history}
                    orgsContainer={orgContainer}
                    titleContainer={titleContainer}
                    appContainer={appContainer}
                />
            )}
        </Subscribe>
    );

}

export default BasicOrganizationDashboard;