import React from "react";
import { Subscribe } from 'unstated';
import AuthContainer from '../../state/containers/AuthContainer';
import { Redirect, RouteComponentProps } from "react-router";
import FrontTabbedLayout from "../../components/structure/FrontTabbedLayout";
import { TabPanel } from "react-tabs";
import TabPanelBody from "../../components/structure/TabPanelBody";
import RegisterForm from "./components/RegisterForm";
import UserContainer from "../../state/containers/UserContainer";
import LandingContainer from "../../components/structure/LandingContainer";
import CreatePasswordForm from "./components/CreatePasswordForm";

interface NewUserLoginRouteProps {
    invitationCode?: string
}

interface NewUserLoginProps extends RouteComponentProps<NewUserLoginRouteProps> { }

const NewUserLogin: React.FC<NewUserLoginProps> = (props) => {
    const query = new URLSearchParams(window.location.search)
    const email = query.get('email')
    const firstName = query.get('firstName')
    const lastName = query.get('lastName')
    const applicationId = query.get('app')
    if(applicationId){
        sessionStorage.setItem("inviteTargetApplicationId", applicationId)
    }
    return (
        <LandingContainer>
            <Subscribe to={[AuthContainer, UserContainer]}>
                {(authContainer: AuthContainer, userContainer: UserContainer) => {
                    // if we are logged in, go to root
                    if (authContainer.state.isAuthenticated === true) {
                        return <Redirect to="/v" />
                    }
                    const enteringPassword = userContainer.state.userRegistrationInformation.enteringPassword;
                    return (
                        <FrontTabbedLayout tabs={["Create Account"]}>
                            <TabPanel>
                                <TabPanelBody>
                                    {
                                        enteringPassword ?
                                            <CreatePasswordForm
                                                stateContainer={userContainer}
                                                authContainer={authContainer}
                                                invitationCode={props.match.params.invitationCode}
                                            />
                                            :
                                            <RegisterForm
                                                stateContainer={userContainer}
                                                authContainer={authContainer}
                                                invitationCode={props.match.params.invitationCode}
                                                email={email}
                                                firstName={firstName}
                                                lastName={lastName}
                                            />
                                    }
                                </TabPanelBody>
                            </TabPanel>
                        </FrontTabbedLayout>
                    );
                }}
            </Subscribe>
        </LandingContainer>
    );

};

export default NewUserLogin;