import React from 'react';
import { css } from '@emotion/css';
import Dropzone from 'react-dropzone';
import Button from '../general/Button';
import { silver_two, cool_grey } from '../../constants/colors';
import { BytesToGigabytesMultiplier, BytesToMegabytesMultiplier, getFileTypeFromFileName, MaxAudioBytesSize, MaxBackupBytesSize, MaxImageBytesSize, MaxVideoBytesSize } from '../../constants/UploadFileSize';

const cloudIcon = require('../../content/images/cloud-upload-copy.svg');

interface FileUploadFieldProps {
    onSelected?: (files: File[]) => void;
    accept?: string;
    uploadLabel?: string;
    uploadButtonText?: string;
    maxSizeOverrideMegabytes?: number
}

const dropzoneStyle = {
    borderRadius: 20,
    border: `dashed 5px ${silver_two}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

const dropzoneClass = css`
    flex-direction: column;
    padding: 32px;
    .upload-label {
        font-size: 18px;
        font-family: Muli;
        color: ${cool_grey};
        margin: 12px;
    }
    .sub-detail {
        font-size: 12px;
        font-family: Muli;
        color: ${cool_grey};   
    }
`
// Used in 1. Content Item Media File Upload 2. Backup import ".vfy, .zip"
class MultiFileSelector extends React.Component<FileUploadFieldProps> {

    handleFilePicked(files: File[]) {
        if (files.length > 0) {
            var hasErrors = false;

            files.forEach(file => {
                if (this.props.maxSizeOverrideMegabytes) {
                    if (file.size * BytesToMegabytesMultiplier > this.props.maxSizeOverrideMegabytes) {
                        alert(`[${file.name}] file is too large. Please select a smaller file. The max file size allowed is ${this.props.maxSizeOverrideMegabytes} MB`);
                        hasErrors = true;
                    }
                    return;
                }
                const fileType = getFileTypeFromFileName(file.name);
                if (fileType === "AudioFile" && file.size > MaxAudioBytesSize) {
                    const audioSizeAllowed: number = Math.round(MaxAudioBytesSize * BytesToGigabytesMultiplier);
                    alert(`[${file.name}] file is too large. Please select a smaller audio file. The max file size allowed is ${audioSizeAllowed}GB`);
                    hasErrors = true;
                }

                if (fileType === "BackupFile" && file.size > MaxBackupBytesSize) {
                    const backupSizeAllowed: number = Math.round(MaxBackupBytesSize * BytesToMegabytesMultiplier);
                    alert(`[${file.name}] file is too large. Please select a smaller backup file. The max file size allowed is ${backupSizeAllowed}MB`);
                    hasErrors = true;
                }

                if (fileType === "ImageFile" && file.size > MaxImageBytesSize) {
                    const imageSizeAllowed: number = Math.round(MaxImageBytesSize * BytesToMegabytesMultiplier);
                    alert(`[${file.name}] image is too large. Please select a smaller image file. The max file size allowed is ${imageSizeAllowed}MB`);
                    hasErrors = true;
                }

                if (fileType === "VideoFile" && file.size > MaxVideoBytesSize) {
                    const videoSizeAllowed: number = Math.round(MaxVideoBytesSize * BytesToGigabytesMultiplier);
                    alert(`[${file.name}] file is too large. Please select a smaller video file. The max file size allowed is ${videoSizeAllowed}GB`);
                    hasErrors = true;
                }
            });

            if (this.props.onSelected && !hasErrors) {
                this.props.onSelected(files);
            }
        }
    };

    render() {
        return (
            <div>
                <div>
                    <Dropzone onDrop={this.handleFilePicked.bind(this)} className={dropzoneClass} style={dropzoneStyle} accept={this.props.accept} multiple={true}>
                        <img src={cloudIcon} />
                        <span className="upload-label">{this.props.uploadLabel || "Drag files here"}</span>
                        <span className="sub-detail">or</span>
                        <Button themes={['primary-small']} text={this.props.uploadButtonText || "Select Local Files"} type="button" />
                    </Dropzone>
                </div>
            </div>
        )
    }
}

export default MultiFileSelector;