import React from 'react';
import { css } from '@emotion/css';
import Select, { ArrowRendererProps, Option } from 'react-select';
import { silver_four, dark_grey_blue, silver_three, color_variants_shadow_10 } from '../../constants/colors';
import ToggleIndicator from '../general/ToggleIndicator';

// component used in building forms with consistent components for look and function
interface SelectProps {
    name?: string
    value?: string
    disabled?: boolean
    onChange?: (e: Option) => void
    id?: string
    options: Option[]
    label?: string
}

const selectStyle = css`
    height: 30px;
    border: 0px;    
    width: 120px;
    display: inline-block;
    flex-direction: row;
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    .Select-control {
        box-shadow: 0 2px 4px 0 ${color_variants_shadow_10};
        border: solid 1px ${silver_four};
        border-radius: 20px;
        background: white;
        max-height: 30px;
        .Select-multi-value-wrapper.Select-input {
            max-height: 30px;
        }
    }
    .Select-placeholder, .Select-value{
        font-family: Muli;
        font-size: 12px;
    }
    span {
        max-height: 30px;
        line-height: 30px;
        div {
            max-height: 30px;
        }
    }    
    div {
        max-height: 30px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border: solid 1px ${silver_four};
        max-height: 120px;
        z-index: 100;
        .Select-menu {
            max-height: 120px;
            background: white;
            max-height: 120px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            .Select-option {                
                border-top: solid 1px ${silver_four};
            }
        }
    }
    .Select-option, Select-noresults {
        font-family: Muli;
        font-size: 12px;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-bottom: solid 1px ${silver_four}
    }
`
class RoundedSelectField extends React.Component<SelectProps, {}> {
    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(value);
    };
    render() {
        const props = {
            ...this.props
        };

        return (
            <div className={`sort-container selector`}>  
                {this.props.label === undefined ? null : <div className={'label selector'}>{this.props.label}</div>}              
                <Select id={props.id}
                    name={props.name}
                    clearable={false}                    
                    searchable={false}
                    value={props.value}
                    onChange={this.handleChange.bind(this)}
                    disabled={props.disabled}
                    options={props.options}
                    className={selectStyle}
                    arrowRenderer={(p: ArrowRendererProps) => <ToggleIndicator direction={p.isOpen ? 'up' : 'down'}/>} />
            </div>
        );
    }
}

export default RoundedSelectField;