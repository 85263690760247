import React from 'react';
import { css } from '@emotion/css';
import { ConversationTrackingTreeContentNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import { style_border_default } from '../../../constants/stylesValues';
import { color_colors_royalty, color_defaults_white, color_highlights_pink, color_shades_light, color_variants_decline_light_1, color_variants_royalty_light_10, color_variants_royalty_light_20 } from '../../../constants/colors';
import SlimGenericContentItemModel from '../../../models/features/api/SlimGenericContentItemModel';
import TooltipWrapper from '../../general/TooltipWrapper';
import NodeSocket from './NodeSocket';
import { getFeatureTypeIcon } from '../../../models/extensions';
import { FallbackFeatureTypeId } from '../../../constants/featureTypeIds';
import { defaultWhite } from '../../../constants/customAssistantConfigurationStyles/customAssistantConfigurationsColors';

interface ContentNodeProps {
    id?: string
    nodeData: ConversationTrackingTreeContentNode
    conversationItem: SlimGenericContentItemModel
    active: boolean
    selected: boolean
    childSelected: boolean
    percentage: number
    number: number
    isLoadingDetails: boolean
    isStart?: boolean
    isEnd?: boolean
    onClick?: () => void
}

const ContentNode: React.SFC<ContentNodeProps> = (props) => {
    let percentage: string | number = props.percentage * 100;
    percentage = percentage !== Math.round(percentage) ? percentage.toFixed(2) : percentage
    const getTitle = () => {
        if (props.conversationItem?.title) return props.conversationItem.title;
        if (props.isLoadingDetails) return 'Loading';
        return '- Deleted -'
    }
    return (
        <div className={`${containerStyle} ${props.active ? 'active' : 'inactive'} ${props.selected ? 'selected' : 'unselected'}`} id={props.id}>
            <div className="percentage-container ac-analytics-track-node-percentage">
                <p>{percentage}% ({props.number})</p>
            </div>
            <div className="node-card ac-analytics-track-node-card" onClick={props.onClick}>
                {!props.isStart && <div className="in-socket">
                    <NodeSocket direction="in" active={props.selected} />
                </div>}
                <div className="feature-icon-container" />
                <div className="card-body">
                    <TooltipWrapper place="top" text={getTitle()}>
                        <p>{getTitle()}</p>
                    </TooltipWrapper>
                </div>
                <img className="feature-icon" src={getFeatureTypeIcon(props.conversationItem?.featureTypeId ?? props.nodeData.featureTypeId ?? FallbackFeatureTypeId)} />
                {!props.isEnd && <div className="out-socket">
                    <NodeSocket direction="out" active={props.childSelected} />
                </div>}
            </div>
            {!props.isStart && <div className="circle" />}
        </div>
    );
}

const containerStyle = css`
    width: 120px;
    margin: 0 12px;
    position: relative;
    .circle {
        width: 8px;
        height: 8px;
        position: absolute;
        border-radius: 50%;
        top: -84px;
        left: 57px;
        background: ${color_variants_royalty_light_10};
        border: 1px solid ${color_variants_royalty_light_20};
    }
    .percentage-container {
        display: flex;
        position: absolute;
        top: -32px;
        right: 0;
        background: ${color_defaults_white};
        z-index: 5;
        padding: 4px;
        border-radius: 3px;
        border: 1px solid ${color_colors_royalty};
        >p {
            margin-right: 0;
            margin-left: auto;
            color: ${color_colors_royalty};
            text-align: right;   
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
        }
    }
    .node-card {
        cursor: pointer;
        width: 120px;
        height: 80px;
        border-radius: 4px;
        border: ${style_border_default};
        background: ${color_shades_light};
        padding: 8px 0;
        position: relative;
        .feature-icon-container {
            position: absolute;
            top: -4px;
            left: -8px;
            width: 32px;
            height: 32px;
            border: ${style_border_default};
            border-radius: 4px;
            background: white;
        }
        .feature-icon {
            position: absolute;
            top: 2px;
            left: -2px;
            height: 20px;
        }
        .card-body {
            background: white;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            position: absolute;
            left: 0;
            right: 0;
            >p {
                font-size: 12px;
                line-height: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
       
        .out-socket {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            position: absolute;
            bottom: -5px;
            z-index: 3;
        }
        .in-socket {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            position: absolute;
            top: -5px;
            z-index: 3;
        }
    }

    &.inactive {
        opacity: 0.4;
    }
    &.selected {
        .circle {
            background :${color_colors_royalty};
            border: 2px solid white;
            z-index: 3;
            left: 56px;
            top: -85px;
            width: 10px;
            height:10px;
        }
    }
`;

export default ContentNode;