import React from 'react';
import { css } from '@emotion/css';

interface PanelContainerProps {
    className?: string
}
const PanelContainer: React.SFC<PanelContainerProps> = (props) => (
    <div className={`${containerStyle} ${props.className ?? ''}`}>
        {props.children}
    </div>
)

const containerStyle = css`
    display: flex;
    flex: 1;
    height: 100%;
`;

export default PanelContainer;