import React from 'react';
import { css } from '@emotion/css';
import LanguageModel from '../../models/features/api/LanguageModel';
import { getFlagIcon, getFlagIconForLanguage, getLanguageNameForLanguage, getRegionyNameForLanguage } from '../../models/extensions/languages';
import Tooltip from 'rc-tooltip';
import TooltipWrapper from './TooltipWrapper';
import { cool_grey } from '../../constants/colors';

interface LanguageCollectionProps {
    languages: LanguageModel[]
    limit: number
    className?: string
}

const LanguageCollectionAvatar: React.SFC<LanguageCollectionProps> = (props) => (
    <div className={containerStyle + (props.className ? ` ${props.className}` : '')}>
        {props.languages?.slice(0, props.limit).map((l, idx) => (
            <TooltipWrapper text={`${getLanguageNameForLanguage(l)} (${getRegionyNameForLanguage(l)})`} className={flagTooltip} key={idx}>
                <div className="flag-item">
                    <div className="inner-flag">
                        <img src={getFlagIconForLanguage(l)} />
                    </div>
                </div>
            </TooltipWrapper>
        ))}
        {props.languages?.length === props.limit + 1 ?
            <TooltipWrapper text={props.languages.slice(props.limit).map(l => `${getLanguageNameForLanguage(l)} (${getRegionyNameForLanguage(l)})`).join(', ')} className={flagTooltip}>
                <div className="flag-item">
                    <div className="inner-flag">
                        <img src={getFlagIconForLanguage(props.languages[props.limit])} />
                    </div>
                </div>
            </TooltipWrapper>
            : null}
        {props.languages?.slice(props.limit).length > 1 ?
            <TooltipWrapper text={props.languages.slice(props.limit).map(l => `${getLanguageNameForLanguage(l)} (${getRegionyNameForLanguage(l)})`).join(', ')} className={flagTooltip}>
                <div className="flag-item">
                    <div className="inner-flag overflow-icon">
                        <p>+{props.languages.slice(props.limit).length}</p>
                    </div>
                </div>
            </TooltipWrapper>
            : null}
    </div>
);

const containerStyle = css`
    display: flex;
    .inner-flag {
        border-radius: 50%;
        border: 1px solid ${cool_grey};
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &.overflow-icon {
            width: 24px;
            height: 24px;
            max-height: 24px;
            max-width: 24px;
        }
    }
    .flag-item {
        padding: 1px;
        width: 24px;
        height: 24px;
        max-height: 24px;
        max-width: 24px;
        margin-left: -12px;
        border-radius: 50%;
        z-index: 0;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #ffffff;
        &:hover {
            z-index: 1;
        }
        &:first-child {
            margin-left: 0;
        }
    }
`
const flagTooltip = css`
    .rc-tooltip-inner {
        max-width: 200px;
    }
`
export default LanguageCollectionAvatar;