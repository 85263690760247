import React, { useState } from 'react'
import ModalLayout from '../../../components/structure/ModalLayout'
import ModalHeader from '../../../components/general/ModalHeader'
import CustomScrollbars from '../../../components/structure/CustomScrollbars'
import styled from '@emotion/styled'
import {  color_shades_darker, dark_grey_blue } from '../../../constants/colors'
import TextField from '../../../components/forms/TextField'
import useWindowSize from '../../../hooks/UseWindowSize'
import FooterBar from '../../../components/structure/FooterBar'
import Button from '../../../components/general/Button'
import MemberContainer from '../../../state/containers/MemberContainer'
import { FormikProps, withFormik } from 'formik'
import * as yup from 'yup';
import { css } from '@emotion/css'
import OrganizationContainer from '../../../state/containers/OrganizationContainer'
import SparkFormInvitationRequest from '../../../models/organizations/api/SparkFormInvitationRequest'
import WarningLabel from '../../../components/general/WarningLabel'

const infoCircle = require('../../../content/images/info-circle-yellow.svg');
const copyIcon = require('../../../content/images/copy-text.svg');
const sendEmailIcon = require('../../../content/images/send-email.svg');
const generateIcon = require('../../../content/images/generate.svg');

interface InviteSparkFormModal {
    applicationId: string
    isShareFormOpen: boolean,
    setIsShareFormOpen: (bool: boolean) => void,
    memberContainer: MemberContainer
}

interface InviteSparkFormData {
    email?: string,
    firstName?: string,
    lastName?: string,
    sendEmail?: boolean,
}
const InnerForm: React.FC<InviteSparkFormModal & FormikProps<InviteSparkFormData>> = (props) => {
    const [isURLCopied, setIsURLCopied] = useState<boolean>(false);

    const MODAL_SCROLLBAR_SUBTRACTION: number = 200;
    const windowSize = useWindowSize();

    const isLoading = props.memberContainer.state.isLoading;

    const handleURLCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        setIsURLCopied(true);
        setTimeout(() => {
            setIsURLCopied(false);
        }, 3000);
    }

    const handleCustomSubmit = (sendEmail: boolean) => {
        if (sendEmail) {
            props.setValues({ ...props.values, sendEmail: true });
        } else {
            props.setValues({ ...props.values, sendEmail: false });
        }
        props.handleSubmit()
    }

    return (
        <ModalLayout isVisible={true}>
            <ModalHeader title="Invite Others to Your Organization" onClose={() => { props.setIsShareFormOpen(false) }} />
            <form onSubmit={(e) => e.preventDefault()}>
                <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                    <FormBodyWrapper>
                        <WarningLabel image={infoCircle} text='Note: Inviting others gives them full access to all resources and data within the organization. Please invite trusted individuals only.' />
                        <hr></hr>
                        <FieldsWrapper>
                            <TextField
                                name="email"
                                label='Email'
                                type='email'
                                required={true}
                                value={props.values.email}
                                placeholder="user@company.com"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                disabled={props.isSubmitting}
                            />
                            <TextField
                                name="firstName"
                                value={props.values.firstName}
                                label="First Name"
                                required={true}
                                placeholder="What name do you go by?"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                disabled={props.isSubmitting}
                            />
                            <TextField
                                name="lastName"
                                value={props.values.lastName}
                                label='Last Name'
                                placeholder='Last name'
                                required={true}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                disabled={props.isSubmitting}
                            />
                        </FieldsWrapper>
                        {props?.status?.error?.length > 1 && <h4>{props.status.error}</h4>}


                        {props?.status?.inviteURL &&
                            <>
                                <hr></hr>
                                <InviteURLWrapper onClick={() => handleURLCopy(props.status.inviteURL)}>
                                    <TextField
                                        name="inviteUrl"
                                        value={props.status.inviteURL}
                                        label={isURLCopied ? "Invite URL - COPIED" : "Invite URL"}
                                        disabled={true}
                                        icon={copyIcon}
                                        fieldStyle={isURLCopied && InviteURLStyle}
                                    />
                                </InviteURLWrapper>
                            </>

                        }
                    </FormBodyWrapper>

                </CustomScrollbars>
                <FooterBar className={footerStyle}>
                    <div>
                        <StyledButton
                            themes={['primary', 'start']}
                            text="Generate Link"
                            loading={isLoading}
                            icon={generateIcon}
                            onClick={() => handleCustomSubmit(false)}
                        />
                        <StyledButton
                            themes={['primary', 'start']}
                            text="Send Invite"
                            loading={isLoading}
                            icon={sendEmailIcon}
                            onClick={() => handleCustomSubmit(true)}
                        />
                    </div>
                    <StyledButton
                        themes={['secondary']}
                        text="Cancel"
                        onClick={() => props.setIsShareFormOpen(false)}
                    />
                </FooterBar>
            </form>
        </ModalLayout>
    )
}

const InviteSparkFormModal = withFormik<InviteSparkFormModal, InviteSparkFormData>({
    mapPropsToValues: props => ({ email: '', firstName: '', lastName: '', sendEmail: false }),
    validationSchema: yup.object().shape({
        firstName: yup.string().required("You must enter your first name"),
        lastName: yup.string().required("You must enter your last name"),
        email: yup.string().email("Must be a valid email").required("You must enter your email"),
    }),
    handleSubmit: async (values, { props, setStatus, setSubmitting }) => {
        const currentOrgId = JSON.parse(localStorage.getItem('CURRENT_ORG'))?.id
        if (!currentOrgId) {
            setStatus({ error: "Could not find organization. Please refresh." })
            return
        }

        const organizationContainer = new OrganizationContainer()
        const systemRolesResults = await organizationContainer.getSystemRoles();
        if (systemRolesResults.resultType !== "Ok") {
            setStatus({ error: systemRolesResults.errors[0] })
            return
        }

        const request: SparkFormInvitationRequest = {
            applicationId: props.applicationId,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            isAdmin: true,
            isAddedToApplications: true,
            applicationRoleId: systemRolesResults.data.find(role => role.name === "Admin").id,
            sendEmail: values.sendEmail
        }
        setSubmitting(true)
        const result = await props.memberContainer.inviteSparkForm(currentOrgId, request)
        setSubmitting(false)
        if (result.resultType !== "Ok") {
            setStatus({ error: result.errors[0] })
        } else {
            if (values.sendEmail) {
                props.setIsShareFormOpen(false)
            } else {
                setStatus({ inviteURL: result.data.inviteURL })
            }

        }
    }
})(InnerForm);

const StyledButton = styled(Button)`
    margin: 0px;
    margin-left: 10px;

    @media(max-width: 900px){
        font-size: 15px;
        padding: 0px 10px 0px 10px;
    }

    @media(max-width: 500px){
        font-size: 13px;
        padding: 0px 10px 0px 10px;
        margin-top: 10px;
    }
`;

const FormBodyWrapper = styled.div`
    padding: 20px 30px 0px 30px;
`;

const FieldsWrapper = styled.div`
    margin-top: 10px;
`;

const footerStyle = css`
    display: flex;
    justify-content: space-between;
    padding-right: 10px;

    @media(max-width: 500px){
        height: auto;
        padding: 10px;
    }
`;


const InviteURLStyle = css`
    border: 1px solid rgb(30,144,255);
    color: ${dark_grey_blue};
    font-family: Muli;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    padding: 12px 16px;
    font-size: 14px;
    background: transparent;
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        letter-spacing: normal;
        color: ${color_shades_darker};
    }
`;

const InviteURLWrapper = styled.span`
    cursor: pointer;
`;

export default InviteSparkFormModal
