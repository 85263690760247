import IResult from "../../models/result/IResult";
import ApplicationModuleModel from "../../models/features/api/Modules/ApplicationModuleModel";
import { sendGet, sendPut, sendDelete } from "./generic";
import NewModuleRequest from "../../models/features/api/Modules/NewModuleRequest";


export const getModulesForApp = (applicationId: string) : Promise<IResult<Array<ApplicationModuleModel>>> => {
    return sendGet(`/Module/${applicationId}`);
}

export const addModulesToApp = (applicationId: string, modules: NewModuleRequest[]): Promise<IResult<ApplicationModuleModel[]>> => {
    return sendPut(`/Module/addCustomModules/${applicationId}`, modules);
}

export const updateApplicationModule = (applicationModuleId: string, name: string): Promise<IResult<ApplicationModuleModel>> => {
    return sendPut(`/Module/${applicationModuleId}/update`, {
        name
    });
}

export const disableApplicationModule = (applicationModuleId: string): Promise<IResult<ApplicationModuleModel>> => {
    return sendDelete(`/Module/${applicationModuleId}/disable`);
}

export const setModuleOrder = (applicationId: string, applicationModuleIds: string[]): Promise<IResult<ApplicationModuleModel>> => {
    return sendPut(`/Module/reorderModules/${applicationId}`, applicationModuleIds);
}