import React, { DetailedHTMLProps, ImgHTMLAttributes, MouseEventHandler } from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, silver_two, dark_grey_blue_30, cool_grey, pale_grey } from '../../constants/colors';
import IconTooltip from '../general/IconTooltip';

const searchImage = require('../../content/images/search.svg');

interface SearchFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: any) => void
    onKeyDown?: (e: any) => void
    label?: string
    id?: string
    tooltip?: string
    deletePosition?: IconPosition
    toolTipPosition?: string
    className?: string
    boxType?: "square" | "rounded"
    searchIcon?: string
    deleteIcon?: string
    handleClear?: (e: any) => void
    searchStyle?: string
    deleteStyle?: string
}

type IconPosition = "top" | "right";

const SearchField: React.FC<SearchFieldProps> = (props) => {

    let field: any;

    return (
        <div className={`${containerStyle} ${props.className}`}>
            <div className={labelContainer}>
                {props.label !== undefined ? <label htmlFor={props.name} className={labelStyle}>{props.label}</label> : null}
                {props.tooltip !== undefined ? <IconTooltip text={props.tooltip} className={tooltipStyle} place={props.toolTipPosition ? props.toolTipPosition : "top"} /> : null}
            </div>
            <div className={fieldWrapper}>
                <div className={`${props.boxType === "square" ? squareFieldContainer : fieldContainer}  ${props.disabled ? 'disabled' : ''} field-container`}>
                    <img className={props.searchStyle} src={props.searchIcon ? props.searchIcon : searchImage} />
                    <input
                        id={props.id}
                        className={props.boxType === "square" ? squareSearchFieldStyles : searchFieldStyles}
                        type={props.type}
                        name={props.name}
                        ref={(input) => field = input}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onKeyDown={props.onKeyDown}
                        disabled={props.disabled}
                    />
                    <img className={props.deleteStyle} src={props.deleteIcon && props.deleteIcon} onClick={props.handleClear}/>
                </div>
            </div>
        </div>
    );
}

const searchFieldStyles = css`
    color: ${dark_grey_blue}
    font-family: Muli sans-serif;
    text-align: left;
    width: 100%;
    border-radius: 25px;
    border: none; 
    padding: 12px 16px;
    font-size: 14px;
    background: transparent;
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        letter-spacing: normal;
        color: ${cool_grey};
    }
`;

const squareSearchFieldStyles = css`
    color: ${dark_grey_blue}
    font-family: Muli sans-serif;
    text-align: left;
    width: 100%;
    border-radius: 8px 8px 8px 8px;
    border: none; 
    padding: 12px 16px;
    font-size: 14px;
    background: transparent;
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        letter-spacing: normal;
        color: ${cool_grey};
    }
`;

const containerStyle = css`
    margin-bottom: 24px;
    flex: 1;
    &.tight {
        margin-bottom: 16px;
        label {
            margin: 8px 16px 0 16px;
        }
    }
`;

const labelStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin: 24px 16px 0 16px;
`;

const tooltipStyle = css`
    margin: 0;
    margin-top: 24px;
    margin-bottom: 0;
`;

const labelContainer = css`
    display: flex;
    align-items: center;
`;

const fieldContainer = css`
    display: flex;
    align-items: center;
    height: 45px;
    width: 100%;
    border-radius: 25px;
    border: solid 1px ${silver_two}; 
    box-shadow: inset 0 1px 1px 0 ${dark_grey_blue_30};
    background: white;
    margin-top: 8px;
    img {
        margin: 16px;
        margin-right: 0px;
    }
    &.disabled {
        background: ${pale_grey};
        input {
            background: ${pale_grey};
        }
    }
`;

const squareFieldContainer = css`
    display: flex;
    align-items: center;
    height: 45px;
    width: 100%;
    border-radius: 8px 8px 8px 8px;
    border: solid 1px ${silver_two}; 
    box-shadow: inset 0 1px 1px 0 ${dark_grey_blue_30};
    background: white;
    margin-top: 8px;
    img {
        margin: 16px;
        margin-right: 0px;
    }
    &.disabled {
        background: ${pale_grey};
        input {
            background: ${pale_grey};
        }
    }
`;

const fieldWrapper = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export default SearchField;