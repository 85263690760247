import React, { ReactElement } from 'react'
import Button from '../../../components/general/Button'
import Switch from '../../../components/forms/Switch';
import styled from '@emotion/styled';

interface CollapsableItemProps {
    text: string,
    isDisabled: boolean
    onEnabledChange: (enabled: boolean) => void
    italicText?: any
}

export default function CollapsableItem(props: CollapsableItemProps) {
    return (
        <CollapsableItemWrapper>
            <StyledButton
                themes={["white-small"]}
                text={props.text}
                type="button"
            />
            {props.italicText?.length ? <IconWrapper>{props.italicText}</IconWrapper> : null}
            <SwitchWrapper>
                <Switch
                    stopPropagation={true}
                    onChange={props.onEnabledChange}
                    disabled={false}
                    checked={!props.isDisabled}
                    label={!props.isDisabled ? "Can Order" : "Can't Order"}
                />
            </SwitchWrapper>
        </CollapsableItemWrapper>
    )
}

const CollapsableItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SwitchWrapper = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: row;
`;

const IconWrapper = styled.div`
    margin-right: auto;
    color: grey;
    font-style: italic;
`;

const StyledButton = styled(Button)``;