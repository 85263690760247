import React from 'react';
import styled from '@emotion/styled';
import RadioGroup from '../../../../shared/components/forms/RadioGroup';
import { color_gradients_green_shift, color_shades_darker, color_shades_darkest } from '../../../../shared/constants/colors';
import { css } from '@emotion/css';
import { style_border_default } from '../../../../shared/constants/stylesValues';
import RadioCardGroupWithAudio from '../../../../shared/components/forms/RadioCardGroupWithAudio';
import { SparkTelephonyDetailsFormData, SparkTelephonyDetailsProps } from './SparkTelephonyDetailsForm';
import { FormikProps } from 'formik';
import Button from '../../../../shared/components/general/Button';
import { voiceOptions } from '../../../../shared/constants/sparkConstants/voiceOptions';
import TextField from '../../../../shared/components/forms/TextField';
import TelephonyDeploymentRequest from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentRequest';

const maleIcon = require('../../../../shared/content/images/spark/male.svg');

const SparkTelephonyDetails: React.FC<SparkTelephonyDetailsProps & FormikProps<SparkTelephonyDetailsFormData>> = (props) => {

    const customVoiceOption = { value: "custom", label: "Custom", icon: maleIcon, description: "Custom voice" };

    const voiceOptionsWithCustom = [...voiceOptions, customVoiceOption];

    const manualSubmit = () => {
        const telephonyDeploymentRequest: TelephonyDeploymentRequest = {
            noTracking: props.selectedTelephonyDeployment.noTracking,
            phoneNumberStartsWith: props.selectedTelephonyDeployment.phoneNumber[0] + props.selectedTelephonyDeployment.phoneNumber[1] + props.selectedTelephonyDeployment.phoneNumber[2],
            environmentId: props.selectedTelephonyDeployment.environmentId,
            ttsProvider: props.selectedTelephonyDeployment.ttsProvider,
            ttsVoice: props.values.ttsVoice,
            locale: props.selectedTelephonyDeployment.locale,
            asrEndpointing: props.selectedTelephonyDeployment.asrEndpointing,
            asrModel: props.selectedTelephonyDeployment.asrModel,
            asrTier: props.selectedTelephonyDeployment.asrTier,
            firstMessageDelayMS: props.selectedTelephonyDeployment.firstMessageDelayMS,
            mergeTurnsAfterPauses: props.selectedTelephonyDeployment.mergeTurnsAfterPauses,
            mergeTurnTimeoutMS: props.selectedTelephonyDeployment.mergeTurnTimeoutMS,
            disableBargeIn: props.selectedTelephonyDeployment.disableBargeIn,
            repromptAttempts: props.selectedTelephonyDeployment.repromptAttempts,
            repromptDelaySeconds: props.selectedTelephonyDeployment.repromptDelaySeconds,
            name: props.selectedTelephonyDeployment.name,
            proactiveResponseDelayMS: props.selectedTelephonyDeployment.proactiveResponseDelayMS,
            proactiveResponses: props.selectedTelephonyDeployment.proactiveResponses,
            useProactiveResponses: props.selectedTelephonyDeployment.useProactiveResponses,
            disableWelcomeMessageBargeIn: props.selectedTelephonyDeployment.disableWelcomeMessageBargeIn,
            disableSpeechStarted: props.selectedTelephonyDeployment.disableSpeechStarted,
            speechStartedUnconfirmedBargeInLimit: props.selectedTelephonyDeployment.speechStartedUnconfirmedBargeInLimit,
            speechStartedPauseMS: props.selectedTelephonyDeployment.speechStartedPauseMS,
            speechStartedRequiredBufferCount: props.selectedTelephonyDeployment.speechStartedRequiredBufferCount,
            callRecordingEnabled: props.values.callRecordingEnabled,
            speechStartedLevel: props.selectedTelephonyDeployment.speechStartedLevel,
            virtualNumber: props.values.virtualNumber
        }
        props.handleSubmitTelephonyForm(props.selectedTelephonyDeployment.phoneNumber, telephonyDeploymentRequest);
    }
    return (
        <div>
            {props.selectedTelephonyDeployment.virtualNumber &&
                <>
                    <RadioGroupLabel>Virtual Number</RadioGroupLabel>
                    <StyledTextField
                        name="virtualNumber"
                        value={props.values.virtualNumber}
                        onChange={(e) => props.setFieldValue("virtualNumber", e.target.value)}
                        placeholder="Update your virtual number here."
                        labelStyle={textFieldLabelStyle}
                    />
                </>
            }
            <RadioGroupLabel>Record Calls</RadioGroupLabel>
            <Description>Call recordings are available in session analytics.</Description>
            <RadioGroup
                checkContainerStyle={checkContainerStyle}
                selectedOptionStyle={selectedOptionStyle}
                onChange={(e) => props.setFieldValue("callRecordingEnabled", e.value)}
                value={props.values.callRecordingEnabled}
                options={[
                    { label: "Call Recording Enabled", value: true },
                    { label: "Call Recording Disabled", value: false },
                ]}
            />
            <RadioGroupLabel>Voice</RadioGroupLabel>
            <Description>Select your preferred voice from the list below.</Description>
            <VoiceCardsWrapper>
                <RadioCardGroupWithAudio
                    onChange={(e) => props.setFieldValue("ttsVoice", e.value)}
                    options={props.getVoice(props.selectedTelephonyDeployment.ttsVoice) === "custom" ? voiceOptionsWithCustom : voiceOptions}
                    value={props.values.ttsVoice}
                />
            </VoiceCardsWrapper>
            <Button
                disabled={!props.selectedTelephonyDeployment}
                loading={props.isLoadingTelephonyDeployments}
                themes={["primary"]}
                text="Save"
                type="button"
                onClick={() => {
                    props.handleSubmit();
                    manualSubmit();
                }}
            />
        </div>
    );
};

const textFieldLabelStyle = css`
    margin: 16px 0 0 0;
`;

const StyledTextField = styled(TextField)``;

const Description = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin-top: 4px;
`;

const VoiceCardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;  
`;

const RadioGroupLabel = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: ${color_shades_darkest};
    margin-top: 24px;
    &:first-of-type {
        margin-top: 0;
    }
`;

const checkContainerStyle = css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: ${style_border_default};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const selectedOptionStyle = css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: ${color_gradients_green_shift};
`;

export default SparkTelephonyDetails;

