import React from 'react'
import TooltipWrapper from '../../../components/general/TooltipWrapper'
import MenuContainer from '../../../hooks/MenuContainer'
import MenuItemModel from '../../../models/nlp/menu/MenuItemModel'
import styled from '@emotion/styled';
const pushPinActiveIcon = require('../../../content/images/menu-editor/push-pin-active.svg');
const pushPinInactiveIcon = require('../../../content/images/menu-editor/push-pin-inactive.svg');

interface PersistentPropertyTitleProps {
    title?: string
    showTitle?: boolean
    item: MenuItemModel | MenuItemModel["attributes"]
    propertyName: string
}

export default function PersistentPropertyTitle(props: PersistentPropertyTitleProps) {
    const menuContainer = MenuContainer.useContainer();
    const isPersitent = props.item.persistentProperties?.includes(props.propertyName);
    let persistentPropertyToolTipText =
        <span>The "{props.title}" value
            <PersistenPropertyVerb style={{}}>
                {isPersitent ? "won't" : "might"}
            </PersistenPropertyVerb>
            update when you pull the latest menu from your POS. Click to change.
        </span>

    return (
        <PersistenPropertyDiv>
            {props.title && props.showTitle !== false && <Title>{props.title}</Title>}
            {
                <TooltipWrapper
                    place="right"
                    text={persistentPropertyToolTipText}
                >
                    <ImageWrapper
                        onClick={(e) => {
                            e.stopPropagation();
                            if (props.item.persistentProperties?.includes(props.propertyName)) {
                                const index = props.item.persistentProperties.findIndex(p => p === props.propertyName)
                                if (index >= 0) {
                                    props.item.persistentProperties.splice(index, 1);
                                }
                            } else {
                                props.item.persistentProperties.push(props.propertyName)
                            }
                            menuContainer.setMenuItem({ ...menuContainer.menuItem });
                        }}
                    >
                        <img src={isPersitent ? pushPinActiveIcon : pushPinInactiveIcon} />
                    </ImageWrapper>
                </TooltipWrapper>
            }
        </PersistenPropertyDiv>
    )
}

const PersistenPropertyDiv = styled.div`
    display: flex;
`;
const Title = styled.span`
    margin-right: 5px;
`;
const ImageWrapper = styled.div`
    cursor: pointer;
    margin-left: 5px;
`;

const PersistenPropertyVerb = styled.span`
    font-weight: bold;
    text-decoration: underline;
    margin: 0 5px;
`