import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue_10, silver_three, color_text_default } from '../../constants/colors';
import ApplicationModel from '../../models/applications/api/ApplicationModel';

interface ApplicationAvatarProps {
    size: number
    className?: string
    noPadding?: boolean
}

const AvatarContainer: React.SFC<ApplicationAvatarProps> = (props) => (
    <div className={`${containerStyle} ${props.className !== undefined ? props.className : ''} ${props.noPadding ? 'no-pad' : ''}`} style={{width: props.size, height: props.size, minWidth: props.size, minHeight: props.size}}>
       {props.children}
    </div>
)

const containerStyle = css`
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 ${dark_grey_blue_10};
    border: solid 1px ${silver_three};
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &.no-pad {
        padding: 0;
        >img {
            width: 100%;
            height: 100%;
        }
    }
`
export default AvatarContainer;

