import React from 'react';
import { css } from '@emotion/css';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import PositionIndicator from '../structure/ConversationFlow/PositionIndicator';
import { dark_grey_blue, dark_grey_blue_20, cool_grey, dark_sky_blue, highlight_blue } from '../../constants/colors';
import ConversationMediaResponse from '../../models/conversationFlow/ConversationMediaResponse';
import RepromptModel from '../../models/features/api/Reprompt/RepromptModel';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import SsmlCacheItem from '../../models/ssml/SsmlCacheItem';
import DOMPurify from 'dompurify';

const openQuoteIcon = require('../../content/images/quote-open.svg');
const closeQuoteIcon = require('../../content/images/quote-close.svg');
const deviceIcon = require('../../content/images/device.svg');

const containerStyle = css`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
    }
`
const bubbleStyle = css`
    margin: 30px 0 0 80px;
    border: solid 1px ${dark_sky_blue};
    box-shadow: -1px 4px 10px 0 ${dark_grey_blue_20};
    background: white;
    border-radius: 10px 0 10px 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media only screen and (min-width: 1240px) {
        max-width: 75%;
        margin-right: 0;
        margin-left: auto;
    }
    .title {
        font-family: Muli;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 24px;
    }

    .open-quote {
        margin-left: 0;
        margin-right: auto;
        margin-bottom: 16px;
        margin-top: 16px;
    }

    .close-quote {
        margin-right: 0;
        margin-left: auto;
        margin-bottom: 16px;
        margin-top: 0;
    }

    .placeholder {
        text-align: center;
        font-style: italic;
        color: ${cool_grey};
    }
    .carousel-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 16px;
        span {
            margin-bottom: 8px;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            text-align: center;
            color: ${cool_grey};
        }
    }
`;

const inputStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    margin-top: 12px;
    span {
        background: ${highlight_blue};
    }
`;

const followUpDetail = css`
    background: white;
    margin-top: -9px;
    margin-left: 8px;
    padding: 0 4px;
    font-family: Muli;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: ${cool_grey};
    position: absolute;
`
const followUpContainer = css`
    position: relative;
    margin: 48px 0 16px 0;
    p {
        margin-top: 48px;
    }
`

interface DeviceVariationsBubbleProps {
    variations: ConversationMediaResponse[]
    title?: string
    detail?: string
    followUp?: FollowUpModel
    reprompt?: RepromptModel
    assistant?: string
    deviceTargetId?: string
    noHighlights?: boolean
}
interface DeviceVariationsBubbleState {
    position: number
    audioResponses: SsmlCacheItem[]
}


class DeviceChatVariationsBubble extends React.Component<DeviceVariationsBubbleProps, DeviceVariationsBubbleState> {

    componentWillMount() {
        this.setState({
            position: 0,
            audioResponses: []
        });
    }

    getSsmlContent(conversationResponse: ConversationMediaResponse) {
        var customResponse = conversationResponse.mediaResponseContainer
            ? conversationResponse.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTargetId && r.displayText != null)
            : null;
        if (customResponse) return customResponse.displayText;

        return conversationResponse.content;
    }

    buildLabel(item: ConversationMediaResponse) {
        // strip tags from ssml first
        const ssml = this.getVariationSsml(item, false);
        const stripTagRegex = /<\/?[^>]+(>|$)/g
        var displayText = ssml;
        displayText = displayText.replace(stripTagRegex, "");
        if (!this.props.noHighlights)
            item.highlights.forEach(highlight => {
                displayText = displayText.replace(highlight.replace(stripTagRegex, ""), `<span>${highlight}</span>`);
            });

        return (
            <p className={inputStyle} key={item.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayText) }}>
            </p>
        )
    }

    private getVariationSsml(item: ConversationMediaResponse, includeFollowUp: boolean): string {
        var ssml = item.content;
        var customResponse = item.mediaResponseContainer
            ? item.mediaResponseContainer.responses
                .find(r => r.deviceTargetId == this.props.deviceTargetId && r.readoutOverride != null)
            : null;
        if (customResponse) ssml = customResponse.readoutOverride

        if (includeFollowUp && this.props.followUp) {
            ssml += `<break time=".5s"/> ${this.props.followUp.content}`;
        }

        return ssml;
    }

    handlePositionChange(position: number) {
        this.setState({
            ...this.state,
            position
        })
    }

    stripTags(ssml: string) {
        const stripTagRegex = /<\/?[^>]+(>|$)/g
        var displayText = ssml;
        displayText = displayText.replace(stripTagRegex, "");
        return displayText;
    }
    render() {
        const { title, variations } = this.props;
        if (!variations) return null;
        return (
            <div className={containerStyle}>
                <div className={`${bubbleStyle} ac-output-message-preview`}>
                    {title && <><span className="title">{title}</span>
                        <HorizontalSeparator /></>}
                    <img className="open-quote" src={openQuoteIcon} />
                    <div className="content-container">
                        {variations.some(v => v.content != '') ? this.buildLabel(variations[this.state.position]) : <p className="placeholder">Enter your response to see here</p>}
                        {this.props.followUp ?
                            <div className={followUpContainer}>
                                <HorizontalSeparator />
                                <span className={followUpDetail}>&amp; Then…</span>

                                <p className={inputStyle}>
                                    {this.props.noHighlights ? this.stripTags(this.props.followUp.content) :
                                        <span>{this.stripTags(this.props.followUp.content)}</span>
                                    }
                                </p>

                            </div> : null}

                    </div>
                    <img className="close-quote" src={closeQuoteIcon} />
                    {
                        variations.length > 1 ?
                            <>
                                <HorizontalSeparator />
                                <div className="carousel-container">
                                    <span>{`VARIATION: ${this.state.position + 1} of ${variations.length}`}</span>
                                    <PositionIndicator count={variations.length} position={this.state.position} onChange={this.handlePositionChange.bind(this)} />
                                </div>
                            </>
                            : null
                    }
                </div>

                <img src={deviceIcon} />
            </div>
        );
    }
}

export default DeviceChatVariationsBubble;
