import React from "react";
import { Subscribe } from 'unstated';
import OrganizationContainer from "../../../../../shared/state/containers/OrganizationContainer";
import MemberContainer from "../../../../../shared/state/containers/MemberContainer";
import UserContainer from "../../../../../shared/state/containers/UserContainer";
import OrganizationSettingsTabs from "./OrganizationSettingsTabs";
import ApplicationContainer from "../../../../../shared/state/containers/ApplicationContainer";
import ApplicationModel from "../../../../../shared/models/applications/api/ApplicationModel";
import TemplateFormSectionModel from "../../../../../shared/models/templating/api/TemplateFormSectionModel";
import TemplateFormFieldModel from "../../../../../shared/models/templating/api/TemplateFormFieldModel";
import TemplateConfigurationContainer from "../../../../../shared/state/containers/TemplateConfigurationContainer";

interface OrganizationSettingsProps {
    commonApp: ApplicationModel;
    locationApps: ApplicationModel[];
    locationAppNames: string[];
    commonAppSections: TemplateFormSectionModel[];
    locationAppFormFields: TemplateFormFieldModel[];
    isLoadingTemplates: boolean;
    history: any;
    hideApplicationSettings?: boolean;
}

const OrganizationSettings: React.FC<OrganizationSettingsProps> = (props) => (
    <Subscribe to={
        [OrganizationContainer,
            MemberContainer,
            UserContainer,
            ApplicationContainer,
            TemplateConfigurationContainer]
    }>
        {(orgContainer: OrganizationContainer,
            memberContainer: MemberContainer,
            userContainer: UserContainer,
            appContainer: ApplicationContainer,
            templateConfigurationContainer: TemplateConfigurationContainer,
        ) => {
            return (
                <OrganizationSettingsTabs
                    orgContainer={orgContainer}
                    memberContainer={memberContainer}
                    userContainer={userContainer}
                    appContainer={appContainer}
                    commonApp={props.commonApp}
                    locationApps={props.locationApps}
                    locationAppNames={props.locationAppNames}
                    commonAppSections={props.commonAppSections}
                    locationAppFormFields={props.locationAppFormFields}
                    isLoadingTemplates={props.isLoadingTemplates}
                    history={props.history}
                    hideApplicationSettings={props.hideApplicationSettings}
                    templateConfigurationContainer={templateConfigurationContainer}
                />
            );
        }}
    </Subscribe>
);

export default OrganizationSettings;