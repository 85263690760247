import React from 'react';
import { css } from '@emotion/css';
import { silver_two, cool_grey, ocean_blue } from '../../../constants/colors';
import { SsmlType } from './SsmlManager';
import HorizontalScrollView from '../../structure/HorizontalScrollView';
const indicatorIcon = require('../../../content/images/ssml/chevron-right-dark.svg');
const indicatorDisabledIcon = require('../../../content/images/ssml/chevron-right-light-gray.svg');

interface SsmlElementSelectorProps {
    title?: string
    options: SsmlElement[]
    onOptionSelected?: (SsmlElement) => void
    selectedValue?: string
}

interface SsmlElementSelectorState {
    canScrollLeft: boolean
    canScrollRight: boolean
}

export interface SsmlElement {
    icon: string
    selectedIcon?: string
    name: string
    value: string
}

const containerStyle = css`
   
    width: 100%;
    .wrapper {
        overflow: hidden; 
        border: 1px solid ${silver_two};
        border-radius: 5px;
        position: relative;
        height: 80px;
        flex: 1;
        margin-top: 4px;
        background: white;
    }
    .box-title {
        position: absolute;
        background: white;
        text-transform: uppercase;
        font-family: Muli;
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: ${cool_grey};
        padding: 0 4px;
        top: -6px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 40px;
        text-align: center;
    }

    .elements-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-top: 8px;
        .separator {
            height: 32px;
        }

    }

    .element {
        min-width: 90px;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .ssml-element {
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        cursor: pointer;
        margin: 6px;
        .selected-icon {
            display: none;
        }
        img {
            height: 24px;
        }
        p {
            font-family: Muli;
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            text-align: center;
            color: #304562;
            margin-top: 4px;
        }
        &:hover {  
            background-color: rgba(0, 125, 187, 0.1);
            border-radius: 10px;
        }

        &.selected {
            background-color: rgba(0, 125, 187, 0.1);
            border-radius: 10px;

            p{
                color: ${ocean_blue};
            }
            
            .element-icon {
                display: none;
            }

            .selected-icon {
                display: block;
            }

        }
    }

    .indicator-wrapper {
        width: 20px;
        height: 50%;
        .indicator {
            width: 20px;
            height: 20px;
            box-shadow: 0 1px 4px 0 rgba(48, 69, 98, 0.1);
            background-color: #ffffff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;

            img {
                margin: 0;
            }
        }
        position: absolute;
        top: 0;
        bottom: 0; 
        margin: auto 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .indicator-icon-disabled {
            display: none;
        }

        &.disabled {
            .indicator-icon-disabled {
                display: block;
            }
            .indicator-icon {
                display: none;
            }
        }

        &.left {
            left: -10px;
            img {
                transform: rotate(180deg);
            }
        }

        &.right {
            right: -10px;
        }
    }


    .indicator-background-wrapper {
        width: 40px;
        height: 50%;
        position: absolute;
        top: 0;
        bottom: 0; 
        margin: auto 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        .indicator-background {
            border-radius: 50%;
            background-color: rgba(48, 69, 98, 0.1);
            height: 40px;
            width: 40px;
            margin-bottom: 24px;
        }
        &.left {
            left: -20px;
        }

        &.right {
            right: -20px;
        }

        &.disabled {
            display: none;
        }
    }
`


class SsmlElementCarousel extends React.Component<SsmlElementSelectorProps, SsmlElementSelectorState> {
    scrollView: HorizontalScrollView
    componentWillMount() {
        this.setState({
            canScrollLeft: false,
            canScrollRight: true
        })
    }
    handleScrollLeft() {
        this.scrollView.scrollLeft(91);
    }
    handleScrollRight() {
        this.scrollView.scrollRight(91);
    }
    handleScrollPositionChanged(scrollLeft: number, isAtRight: boolean) {
        if(scrollLeft == 0) {
            this.setState({
                ...this.state,
                canScrollLeft: false,
                canScrollRight: true
            })
        }
        else if(isAtRight) {
            this.setState({
                ...this.state,
                canScrollLeft: true,
                canScrollRight: false
            })
        }
        else {
            this.setState({
                ...this.state,
                canScrollLeft: true,
                canScrollRight: true
            })
        }
    }
    render() {
        const props = { ...this.props };
        return (
            <div className={containerStyle}>
                <div className={`indicator-background-wrapper left ${this.state.canScrollLeft ? '' : 'disabled'}`}>
                    <div className='indicator-background'/>
                </div>
                <div className={`indicator-background-wrapper right ${this.state.canScrollRight ? '' : 'disabled'}`}>
                    <div className='indicator-background'/>
                </div>
                <div className="wrapper">
                    {props.title ? <p className="box-title">{props.title}</p> : null}
                    <HorizontalScrollView hideBar ref={view => this.scrollView = view} onScrollChange={this.handleScrollPositionChanged.bind(this)}>
                        <div className="elements-wrapper">
                            {props.options.map((o, i) => (
                                <div key={o.value} className="element" onClick={() => props.onOptionSelected(o)}>

                                    {i > 0 ? <div className="separator" /> : null}

                                    <div className={`ssml-element ${o.value == props.selectedValue ? 'selected': ''}`}>
                                        <img className="element-icon" src={o.icon} />
                                        <img className="selected-icon" src={o.selectedIcon} />
                                        <p>{o.name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </HorizontalScrollView>
                </div>
                <div className={`indicator-wrapper left ${this.state.canScrollLeft ? '' : 'disabled'}`}>
                    <div className="indicator" onClick={this.handleScrollLeft.bind(this)} >
                        <img className="indicator-icon" src={indicatorIcon} />
                        <img className="indicator-icon-disabled" src={indicatorDisabledIcon} />
                    </div>
                </div>
                <div className={`indicator-wrapper right ${this.state.canScrollRight ? '' : 'disabled'}`}>
                    <div className="indicator" onClick={this.handleScrollRight.bind(this)}>
                        <img className="indicator-icon" src={indicatorIcon} />
                        <img className="indicator-icon-disabled" src={indicatorDisabledIcon} />
                    </div>
                </div>
            </div>
        )
    }
}

export default SsmlElementCarousel
