import React from "react";
import { Provider, Subscribe } from 'unstated';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { Redirect } from "react-router";
import AuthContainer from "../../state/containers/AuthContainer";
import OrganizationListView from './components/OrganizationListView';
import FrontContainer from "../../components/structure/FrontContainer";
import OrganizationSelectionForm from "./components/OrganizationSelectionForm";
import SplitContainer from "../../components/structure/SplitContainer";
import LandingContainer from "../../components/structure/LandingContainer";

class Organizations extends React.Component {
    render() {
        return (
            <LandingContainer>
                <Subscribe to={[OrganizationContainer, AuthContainer]}>
                    {(orgs: OrganizationContainer, auth: AuthContainer) => {
                        // if we are logged in, go to root
                        if(!auth.state.isAuthenticated) {
                            return <Redirect to="/login"/>
                        }
                        if (orgs.state.currentOrganization !== null) {
                            return <Redirect to="/v" />
                        }
                        return (
                            <FrontContainer>
                                <OrganizationSelectionForm stateContainer={orgs as OrganizationContainer} />
                            </FrontContainer>
                        );
                    }
                    }
                </Subscribe>
            </LandingContainer>
        );
    }
};

export default Organizations;