import { Container } from "unstated";
import * as voicifyApi from "../../api";
import ApplicationEnvironmentPublishModel from "../../models/applications/api/environments/ApplicationEnvironmentPublishModel";
import ApplicationEnvironmentModel from "../../models/applications/api/environments/ApplicationEnvironmentModel";
import IResult from "../../models/result/IResult";
import CreatePartialApplicationBackupVersionRequest from "../../models/applications/api/backups/CreatePartialApplicationBackupVersionRequest";
import CreateApplicationBackupVersionRequest from "../../models/applications/api/backups/CreateApplicationBackupVersionRequest";
import ApplicationBackupAndPublishResult from "../../models/applications/api/environments/ApplicationBackupAndPublishResult";
import UnpublishContentRequest from "../../models/applications/api/backups/UnpublishContentRequest";
import UpdateApplicationEnvironmentRequest from "../../models/applications/api/environments/UpdateApplicationEnvironmentRequest";

export type ApplicationEnvironmentsOption = "live" | "draft"
interface ApplicationEnvironmentsContainerState {
    publishes: ApplicationEnvironmentPublishModel[]
    environments: ApplicationEnvironmentModel[]
    errors: string[]
    isLoading: boolean
    isCreating: boolean
    pollingIds: string[]
    currentAppId: string
}

export default class ApplicationEnvironmentsContainer extends Container<ApplicationEnvironmentsContainerState> {
    public state: ApplicationEnvironmentsContainerState = {
        publishes: [],
        environments: [],
        errors: [],
        isLoading: false,
        isCreating: false,
        pollingIds: [],
        currentAppId: ""
    };

    // If Application's environments were alreday retrieved, then get them from state
    // If not - retrieve Application's environments and set state 
    // If no environemnts found at all set errors
    async loadEnvironments(applicationId: string, force: boolean = false) : Promise<IResult<ApplicationEnvironmentModel[]>>{
        try {
            if(this.state.currentAppId == applicationId && this.state.environments?.length > 0 && !force) {
                const applicationEnvironments: ApplicationEnvironmentModel[] = this.state.environments;

                return {
                    data: applicationEnvironments,
                    resultType: "Ok",
                    errors:[]
                };
            }
            
            this.setState({ 
                isLoading: true, 
                environments: [], 
                currentAppId: applicationId });

            const result = await voicifyApi.getApplicationEnvironments(applicationId);
            
            if(result.resultType == "Ok"){
                const applicationEnvironments: ApplicationEnvironmentModel[] = result.data;

                if(applicationEnvironments?.length > 0) {
                    await this.setState({ 
                        environments: applicationEnvironments, 
                        isLoading: false});
                }
                else {
                    await this.setState({ 
                        isLoading: false,
                        errors: ["No environments found."]});
                }
            }
            else {
                await this.setState({ 
                    isLoading: false,
                    errors: result.errors});
            }

            return result;
        } catch (error) {
            this.setState({ errors: [error.message], isLoading: false, currentAppId: applicationId });
        }
    };

    // If we have Live/Production environment then return it, if not return Draft environment
    // All apps are suppose to have Production environment
    findPrimaryEnvironment(applicationId: string): ApplicationEnvironmentModel {
        let env: ApplicationEnvironmentModel = this.findLiveEnvironment(applicationId);
        if(!env){
            env = this.findDraftEnvironment(applicationId);
        }

        if(!env){
            this.setState({ ...this.state, errors: [...this.state.errors, "Unable to find application's Primary environment."]});
        }
        return env;
    }

    findLiveEnvironment(applicationId: string): ApplicationEnvironmentModel {
        return this.state.environments.find(e => e.applicationId === applicationId && !e.isCustom && !e.isDraft);
    }

    findDraftEnvironment(applicationId: string): ApplicationEnvironmentModel {
        return this.state.environments.find(e => e.applicationId === applicationId && !e.isCustom && e.isDraft);
    }

    findEnvironmentByOption(applicationId: string, environmentOption: ApplicationEnvironmentsOption): ApplicationEnvironmentModel {
        if(environmentOption === "live") {
            const env: ApplicationEnvironmentModel = this.findLiveEnvironment(applicationId);
            if(!env){
                this.setState({ ...this.state, errors: [...this.state.errors, "Unable to find application's Live(Production) environment."]});
            }
            return env;
        }
        if(environmentOption === "draft") {
            const env: ApplicationEnvironmentModel = this.findDraftEnvironment(applicationId);
            if(!env){
                this.setState({ ...this.state, errors: [...this.state.errors, "Unable to find application's Draft(Test) environment."]});
            }
            return env;
        }

        this.setState({ ...this.state, errors: [...this.state.errors, "Invalid environment option"]});
        return null;
    }

    // Update Application Environment Settings
    updateEnvironmentSettings(applicationId: string, environmentId:string, model: UpdateApplicationEnvironmentRequest): Promise<IResult<ApplicationEnvironmentModel>> {
        this.setState({
            ...this.state,
            isCreating: true
        })
        var promise = voicifyApi.updateEnvironmentSettings(environmentId, model);

        promise.then(result => {
            if (result.resultType != "Ok") {
                this.setState({
                    ...this.state,
                    isCreating: false,
                    errors: result.errors
                })
            } else {
                const appEnvironments = this.state.environments;

                // update the environment locally
                const updatedEnvironment: ApplicationEnvironmentModel = appEnvironments?.find(e => e.applicationId === applicationId && e.id === environmentId);
                if (updatedEnvironment) {
                    updatedEnvironment.applicationInformationItems = result.data.applicationInformationItems;
                    updatedEnvironment.name = result.data.name; // Environment Name
                    updatedEnvironment.applicationName = result.data.applicationName; 
                    updatedEnvironment.imageItemId = result.data.imageItemId;
                    updatedEnvironment.invocationPhrase = result.data.invocationPhrase;
                    updatedEnvironment.description = result.data.description;
                    updatedEnvironment.shortDescription = result.data.shortDescription;
                    updatedEnvironment.keywords = result.data.keywords;
                    updatedEnvironment.imageItemId = result.data.imageItemId;
                }
                else{
                    appEnvironments.push(result.data);
                }

                this.setState({
                    ...this.state,
                    isCreating: false,
                    environments: appEnvironments,
                    errors: [],
                    currentAppId: applicationId
                })

            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isCreating: false,
                errors: [error.toString()]
            })
        });
        return promise;
    }

    buildPublishCacheKey(skip, take) {
        return `${skip}-${take}`;
    }
    async loadPublishes(applicationId: string, skip: number = 0, take: number = 50, append: boolean = false) : Promise<IResult<ApplicationEnvironmentPublishModel[]>> {
        try {
            if(this.state.currentAppId != applicationId || skip == 0) {
                await this.setState({
                    ...this.state,
                    publishes: [],
                    environments: []
                });
            }
            await this.setState({ ...this.state, isLoading: true });
            let environments = this.state.environments;
            if (this.state.environments.length == 0) {
                var environmentResults = await voicifyApi.getApplicationEnvironments(applicationId);
                if (environmentResults.resultType == "Ok") {
                    await this.setState({ ...this.state, environments: environmentResults.data, isLoading: false });
                    environments = environmentResults.data;
                } else {
                    await this.setState({ ...this.state, errors: environmentResults.errors, isLoading: false });
                }                
            }
            const result = await voicifyApi.getEnvironmentPublishes(environments.find(e => !e.isDraft && !e.isCustom).id, skip, take);
            if (result.resultType == "Ok") {
                if(skip = 0) {
                    await this.setState({
                        ...this.state,
                        publishes: []
                    });
                }
                if (append) {
                    const newPublishes = [...this.state.publishes, ...result.data];
                    await this.setState({
                        ...this.state,
                        publishes: [...this.state.publishes, ...result.data],
                        isLoading: false,
                        errors: [],
                        currentAppId: applicationId,
                    });                    
                } else {
                    await this.setState({
                        ...this.state,
                        publishes: result.data,
                        isLoading: false,
                        errors: []
                    });
                }
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isLoading: false });
        }
    }

    async queuePublishBackup(applicationEnvironmentId: string, backupVersionId: string) {
        try {
            await this.setState({ ...this.state, isCreating: true });
            const result = await voicifyApi.queuePublishToEnvironment(applicationEnvironmentId, backupVersionId);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    publishes: [result.data, ...this.state.publishes],
                    isCreating: false,
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isCreating: false
                });
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isCreating: false });
        }
    }

    async queueFullBackupAndPublish(applicationId: string, applicationEnvironmentId: string, model: CreateApplicationBackupVersionRequest) : Promise<IResult<ApplicationBackupAndPublishResult>>  {
        try {
            await this.setState({ ...this.state, isCreating: true });
            const result = await voicifyApi.queueBackupAndPublishApplication(applicationId, applicationEnvironmentId, model);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isCreating: false,
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isCreating: false
                });
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isCreating: false });
        }
    }

    async queuePartialBackupAndPublish(applicationId: string, applicationEnvironmentId: string, model: CreatePartialApplicationBackupVersionRequest) : Promise<IResult<ApplicationBackupAndPublishResult>>  {
        try {
            await this.setState({ ...this.state, isCreating: true });
            const result = await voicifyApi.queueBackupAndPublishPartialApplication(applicationId, applicationEnvironmentId, model);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isCreating: false,
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isCreating: false
                });
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isCreating: false });
        }
    }

    async unpublishContentItems(applicationEnvironmentId: string, request: UnpublishContentRequest) {
        try {
            await this.setState({ ...this.state, isLoading: true });
            const result = await voicifyApi.unpublishContentItemsFromEnvironment(applicationEnvironmentId, request);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: []
                });
            } else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                });
            }

            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isCreating: false });
        }
    }

    async addPublish(publish: ApplicationEnvironmentPublishModel) {
        await this.setState({
            ...this.state,
            publishes: [publish, ...this.state.publishes]
        });
    }

    
    async waitForPublish(publishId: string) : Promise<IResult<any>> {
        try {
            let checking = true;
            let checkCount = 0;
            if(this.state.pollingIds.some(s => s == publishId))
                return;
            else {
                await this.setState({
                   ...this.state,
                   pollingIds: [...this.state.pollingIds, publishId] 
                });
            }
             // 900 loops, 2 second pause per loop = ~ 30 minutes of polling (which matches lambda timeout of a potential backup and publish 2x15min)
            while (checking && checkCount < 900) {
                const result = await voicifyApi.findEnvironmentPubishById(publishId);
                checkCount++;
                if (result.resultType == "Ok") {
                    const publish = result.data;
                    await this.setState({
                        ...this.state, 
                        publishes: this.state.publishes.map(p => p.id == publish.id ? publish : p)
                    })

                    switch (publish.stage) {
                        case ("Processing"):
                        case ("Queued"):
                            await new Promise(r => setTimeout(r, 2000));
                            break;
                        case ("Complete"):
                            if (publish.error) {
                                await this.setState({
                                    ...this.state,
                                    pollingIds: this.state.pollingIds.filter(i => i != publishId),
                                    errors: [publish.error],
                                });
                                return {
                                    data: null,
                                    resultType: "Invalid",
                                    errors: [publish.error],
                                }
                            } else {
                                await this.setState({
                                    ...this.state,
                                    pollingIds: this.state.pollingIds.filter(i => i != publishId),
                                    errors: []
                                });
                                return result;
                            }
                            break;
                        default:
                            await this.setState({
                                ...this.state,
                                pollingIds: this.state.pollingIds.filter(i => i != publishId),
                                errors: ["Unknown issue with publish"]
                            });
                            return {
                                data: null,
                                resultType: "Invalid",
                                errors: ["Unknown issue with publish"],
                            }
                    }
                }
                else {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        errors: result.errors
                    })
                }
            }
            // we've timed out.
            await this.setState({
                ...this.state,
                isLoading: false,
                pollingIds: this.state.pollingIds.filter(i => i != publishId),
                errors: ["Publish timed out"],
            });
        }
        catch (e) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            })
        }
    }    

    async clearErrors() {
        await this.setState({ ...this.state, errors: [] });
    }
}