import React, { useEffect, useState } from 'react'
import ApplicationModel from '../../../../../shared/models/applications/api/ApplicationModel'
import NumbersToCall from './NumbersToCall'
import styled from '@emotion/styled'
import CallHistory from './CallHistory'
import Button from '../../../../../shared/components/general/Button'
import { css } from '@emotion/css'
import CallNowModal from './CallNowModal'
import ScrollablePanelBody from '../../../../../shared/components/structure/ScrollablePanelBody'
import OutboundCallContainer from '../../../../../shared/hooks/OutboundCallContainer'
import CreateOutboundCall from './CreateOutboundCall'

const voiceIcon = require('../../../../../shared/content/images/tab-icons/voice.svg');

interface props {
    application: ApplicationModel,
    history: any
}

export default function OutboundCall(props: props) {
    const [showCallNowModal, setShowCallNowModal] = useState<boolean>(false);

    const organizationString = localStorage.getItem("CURRENT_ORG");
    const org = organizationString ? JSON.parse(organizationString) : null;
    const outboundCallContainer = OutboundCallContainer?.useContainer();


    useEffect(() => {
        outboundCallContainer.fetchAssistantNumbers(props.application.id);
        outboundCallContainer.setApplication(props.application);
        outboundCallContainer.setOrganizationId(org?.id);

    }, []);


    return (
        <>
            {showCallNowModal && <CallNowModal setShowModal={setShowCallNowModal} application={props.application} />}
            <ScrollablePanelBody>
                <OutboundCallWrapper>
                    <NumberWrapper>
                        <CreateOutboundCall
                            application={props.application}
                            organizationId={org?.id}
                        />
                        <hr></hr>
                        <NumbersToCall history={props.history} />
                    </NumberWrapper>
                    <RightSide>
                        <div className={callNowButtonStyle}>
                            <Button themes={["black-small"]} text='Call Now' icon={voiceIcon} iconClassName={voiceIconStyle} className='callNow-button' onClick={() => setShowCallNowModal(true)} />
                        </div>
                        <CallHistoryWrapper>
                            <CallHistory />
                        </CallHistoryWrapper>
                    </RightSide>
                </OutboundCallWrapper>
            </ScrollablePanelBody>
        </>

    )
}

const OutboundCallWrapper = styled.div`
    display: flex;
    padding: 10px 20px 10px 20px;
    column-gap: 20px;
`
const NumberWrapper = styled.div`
    margin-top: 4px;
    width: 100%;
`;

const RightSide = styled.div`
    margin-top: 4px;
    width: 100%;

    padding-left: 10px;
    border-left: solid;
    border-width: thin;

    .callNow-button{
        margin: 0;
        margin-left: auto;
    }
`;

const CallHistoryWrapper = styled.div`
    margin-top: 10px;
`;

const voiceIconStyle = css`
    width: 20px;
`;

const callNowButtonStyle = css`
    display: flex;
`;