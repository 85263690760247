import React, { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import AnalyticsRequestFilter from "../models/analytics/AnalyticsRequestFilter";
import ContentHitEvent from "../models/analytics/api/ContentHitEvent";
import IResult from "../models/result/IResult";
import { WelcomeMessagesFeatureTypeId } from "../constants/featureTypeIds";
import InvalidResult from "../models/result/InvalidResult";
import VoicifyNlpResult from "../models/nlp/VoicifyNlpResult";


function useNlpContainer() {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    
    const runNlp = async (appId: string, appSecret: string, environmentId: string, text: string, modelId: string): Promise<IResult<VoicifyNlpResult>> => {
        setLoading(true);
        var sessionTokenResult = await getSessionToken(appId, appSecret, environmentId, "cms-slot-filling");
        if (sessionTokenResult.resultType !== "Ok") 
        {
            setLoading(false);
            return new InvalidResult<VoicifyNlpResult>(sessionTokenResult.errors?.[0]);
        }
        setLoading(false);
        return await voicifyApi.processNlp(text, modelId, sessionTokenResult.data);
        
    }

    const getSessionToken = async (appId: string, appSecret: string, environmentId: string, sessionId: string): Promise<IResult<string>> => {
        const result = await voicifyApi.generateSessionToken(appId, appSecret, environmentId, sessionId);
        if (result.resultType === "Ok") {
            return {
                data: result.data.token,
                resultType: "Ok",
                errors: null
            }
        }
        else {
            setError(result.errors?.[0]);
            return new InvalidResult<string>(result.errors?.[0]);
        }
    }

    return {
        runNlp,
        loading,
        setLoading
    };
}

const NlpContainer = createContainer(useNlpContainer);
export default NlpContainer;
