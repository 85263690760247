import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import OrganizationModel from '../../models/organizations/api/OrganizationModel';
import IResult from '../../models/result/IResult';
import UserRoleModel from '../../models/user/UserRoleModel';
import UpdateOrganizationRequest from '../../models/organizations/api/UpdateOrganizationRequest';
import _ from 'lodash';

type OrganizationState = {
    organizations: OrganizationModel[]
    currentOrganization: OrganizationModel
    isLoadingOrgs: boolean
    isLoadingRoles: boolean
    isUpdating: boolean
    systemRoles: UserRoleModel[]
    errors: string[]
}

export default class OrganizationContainer extends Container<OrganizationState> {
    // default state
    state = this.getInitialState()

    getInitialState(): OrganizationState {
        // check local storage for initial state
        const storedOrganization = localStorage.getItem("CURRENT_ORG");
        
        if (!!storedOrganization && storedOrganization !== "undefined") {
            return {
                organizations: [],
                currentOrganization: JSON.parse(storedOrganization),
                isLoadingOrgs: false,
                isLoadingRoles: false,
                isUpdating: false,
                systemRoles: [],
                errors: []
            }
        }

        return {
            organizations: [],
            currentOrganization: null,
            isLoadingOrgs: false,
            isLoadingRoles: false,
            isUpdating: false,
            systemRoles: [],
            errors: []
        }
    }

    getSystemRoles(): Promise<IResult<UserRoleModel[]>> {
        if (this.state.systemRoles.length > 0) {
            return new Promise(resolve => resolve({
                resultType: "Ok",
                errors: [],
                data: this.state.systemRoles
            }))
        }
        this.setState({
            ...this.state,
            isLoadingRoles: true
        })
        var promise = voicifyApi.getSystemUserRoles();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoadingRoles: false,
                    systemRoles: result.data
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoadingRoles: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoadingRoles: false
            })
        })

        return promise;
    }

    async setCurrentOrgById(id: string) {
        if (id === null) {
            await this.setState({
                ...this.state,
                currentOrganization: null
            });
            return;
        }

        let matchingOrgs = this.state.organizations.filter(o => o.id === id);
        if (matchingOrgs.length < 1) {
            try {
                const orgs = await this.getOrganizations();
                matchingOrgs = [orgs.data.find(o => o.id === id)];
            } catch (error) {
                console.log(error);
                return;
            }
        }

        localStorage.setItem("CURRENT_ORG", JSON.stringify(matchingOrgs[0]));
        await this.setState({
            ...this.state,
            currentOrganization: matchingOrgs[0],
            isUpdating: false
        });

    }

    async getOrganizations(): Promise<IResult<OrganizationModel[]>> {

        this.setLoading(true);

        const organizationsResult = await voicifyApi.getOrganizations();

        if (organizationsResult.resultType == "Ok") {
            await this.setState({
                ...this.state,
                errors: [],
                organizations: _.sortBy(organizationsResult.data, o => o.name),
                isLoadingOrgs: false
            });
            // check the loaded orgs. If we have just one, set it to current
            if (organizationsResult.data.length === 1) {
                await this.setCurrentOrgById(organizationsResult.data[0].id);
            }
        }
        else {
            await this.setState({
                ...this.state,
                errors: organizationsResult.errors,
                isLoadingOrgs: false
            })
        }

        return organizationsResult;
    
    };

    updateOrganizationInfo(model: UpdateOrganizationRequest) {
        if (this.state.currentOrganization) {
            this.setState({
                ...this.state,
                isUpdating: true
            });
            const promise = voicifyApi.updateOrganizationInfo(this.state.currentOrganization.id, model).then(result => {
                if (result.resultType == "Ok") {
                    // update orgs list
                    var orgs = this.state.organizations;
                    if (orgs) {
                        var currentOrgIndex = this.state.organizations.findIndex(o => o.id == result.data.id);
                        if (currentOrgIndex > -1) {
                            orgs[currentOrgIndex] = result.data;
                            this.setState({
                                ...this.state,
                                organizations: orgs,
                                isUpdating: false,
                                errors: []
                            })
                        }
                    }

                    // update current org
                    if (this.state.currentOrganization.id == result.data.id) {
                        this.setCurrentOrgById(result.data.id)
                    }
                }
                else {
                    this.setState({
                        ...this.state,
                        isUpdating: false,
                        errors: result.errors
                    })
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: [error]
                })
            });


            return promise;
        }
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    };


    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoadingOrgs: isLoading
        });
    }
}