import React from 'react';
import Select, { ArrowRendererHandler, ArrowRendererProps, MenuRendererHandler, MenuRendererProps, Option, ValueRendererHandler } from 'react-select';
import { color_colors_ocean, color_shades_dark, color_shades_darkest, color_variants_ocean_xlight} from '../../constants/colors';
import styled from '@emotion/styled';
import ToggleIndicator from '../general/ToggleIndicator';
import { style_border_default } from '../../constants/stylesValues';

export type Size = "small" | "medium"

interface ImageOption extends Option {
    image?: string
    selectedImage?: string
    description?: string
}
interface SelectProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: any) => void
    valueRenderer?: ValueRendererHandler<any>
    menuRenderer?: MenuRendererHandler<any>
    arrowRenderer?: ArrowRendererHandler
    label?: string
    id?: string
    options: ImageOption[]
    useFormTheme?: boolean
    hidden?: boolean
    labelIcon?: string
    clearable?: boolean
    searchable?: boolean
    clearValueText?: string
    className?: string
    useForm?: boolean
    optionImageStyle?: string
    size?: Size
}

const getSelectField = (props: SelectProps, menuProps: MenuRendererProps) => {
    return (
        <>
            <Triangle />
            {props.options.map((option, index) =>
                <div key={index}>
                    <CustomSelectOption className="user-entry-option" onClick={() => menuProps.selectValue(option)} >
                        {option.image ? <img className={props.optionImageStyle ?? ''} src={option.image} /> : null}
                        <div className={menuProps.valueArray[0].value == option.value ? "option-selected" : null} onMouseOver={() => menuProps.focusOption(option)}>{option.label}</div>
                    </CustomSelectOption>
                    {index != menuProps.options.length - 1 ? <Divider /> : null}
                </div>
            )}
        </>
    );
}

const DropDownSelectField = (props: SelectProps) => {
    return (
        <>
            {props.size === "medium" ?
                <CustomSelectMedium
                    {...props}
                    arrowRenderer={props.arrowRenderer ? props.arrowRenderer : (p: ArrowRendererProps) => <Arrow direction={p.isOpen ? 'up' : 'down'} />}
                    menuRenderer={props.menuRenderer ? props.menuRenderer : (menuProps: MenuRendererProps) =>
                        <MenuOptionsWrapper>
                            {getSelectField(props, menuProps)}
                        </MenuOptionsWrapper>
                    }
                />
                : 
                props.size === "small" ?
                <CustomSelectSmall
                    {...props}
                    arrowRenderer={props.arrowRenderer ? props.arrowRenderer : (p: ArrowRendererProps) => <Arrow direction={p.isOpen ? 'up' : 'down'} />}
                    menuRenderer={props.menuRenderer ? props.menuRenderer : (menuProps: MenuRendererProps) =>
                        <MenuOptionsWrapper>
                            {getSelectField(props, menuProps)}
                        </MenuOptionsWrapper>
                    }
                />
                :
                <CustomSelect
                    {...props}
                    arrowRenderer={props.arrowRenderer ? props.arrowRenderer : (p: ArrowRendererProps) => <Arrow direction={p.isOpen ? 'up' : 'down'} />}
                    menuRenderer={props.menuRenderer ? props.menuRenderer : (menuProps: MenuRendererProps) =>
                        <MenuOptionsWrapper>
                            {getSelectField(props, menuProps)}
                        </MenuOptionsWrapper>
                    }
                />
            }
        </>
    );
}

const Arrow = styled(ToggleIndicator)`
    height: 6px;
    width: 6px;
`;

const Divider = styled.div`
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${color_shades_dark};
    padding: 0;
`;

const CustomSelectOption = styled.div`
    display: flex;
    flex-direction: row;
    line-height: 36px;
    padding-left: 15px;
    &.user-entry-option:hover{
        background-color: ${color_variants_ocean_xlight};
        cursor: pointer;
    }
    div{
        margin-left:10px; 
        color: ${color_shades_darkest};
        &.option-selected{
            color: ${color_colors_ocean};
        }
    }
`;

const MenuOptionsWrapper = styled.div`
    margin-top: 8px;
`;

const Triangle = styled.div`
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    border-top: solid 1px ${color_shades_dark};
    border-left: solid 1px ${color_shades_dark};
    background: white;
    margin-top: -5px;
    margin-left: 112px;
    position: absolute;
`;

const CustomSelect = styled(Select)`
    .Select-control {
        border-radius: 20px;
    }
    .Select-menu{
        margin-top:-8px;
    }
    .Select-menu-outer{
        margin-top: 21px;
        border-radius: 6px;
        border-top-color: ${color_shades_dark};
    }
    .Select-value:hover{
        cursor: pointer;
    }
    &.is-open  {
        .Select-control {
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            border-color: ${color_shades_dark};
        }
    }
`;

const CustomSelectSmall = styled(Select)`
    flex-grow: 1;
    .Select-placeholder {
        padding: 10px;
        display: flex;
        align-items: center;
    }
    .Select-control {
        height: 32px;
        flex-grow: 1;
        border: ${style_border_default};
        box-shadow: 0px 1px 2px rgba(50, 70, 97, 0.1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
    }
    .Select-control .Select-multi-value-wrapper .Select-value {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }
    .Select-menu {
        margin-top: -8px;
    }
    .Select-menu-outer {
        margin-top: 12px;
        border-radius: 4px;
        border: ${style_border_default};
    }
    &.is-open  {
        .Select-control {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border: ${style_border_default};
        }
    }
`;

const CustomSelectMedium = styled(Select)`
    flex-grow: 1;
    .Select-placeholder {
        padding: 16px;
        display: flex;
        align-items: center;
    }
    .Select-control {
        height: 48px;
        flex-grow: 1;
        border: ${style_border_default};
        box-shadow: 0px 1px 2px rgba(50, 70, 97, 0.1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
    }
    .Select-control .Select-multi-value-wrapper .Select-value {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }
    .Select-menu {
        margin-top: -8px;
        margin-bottom: -8px;
        padding-bottom: 8px;
    }
    .Select-menu-outer {
        margin-top: 12px;
        border-radius: 8px;
        border: ${style_border_default};
    }
    &.is-open  {
        .Select-control {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border: ${style_border_default};
        }
    }
`;

export default DropDownSelectField;