import React from 'react';
import { css } from '@emotion/css';
import Button from '../../general/Button';
import { color_text_link } from '../../../constants/colors';
const filterIcon = require("../../../content/images/content-explorer/filter.svg");
const filterBlueIcon = require("../../../content/images/content-explorer/filter-blue.svg");

interface FilterButtonProps {
    isExpanded: boolean
    filterCount?: number
    onClick: () => void
}

const FilterToggleButton: React.SFC<FilterButtonProps> = (props) => {
    const renderFilterRight = () => {
        // if we have any active filters, show the number. Otherwise show the icon based on expanded state
        if (props.filterCount > 0) {
            return <div className="filter-right filter-count"><span>{props.filterCount}</span></div>
        }
        else if (props.isExpanded)
            return <div className="filter-right"><img src={filterBlueIcon} /></div>
        else
            return <div className="filter-right"><img src={filterIcon} /></div>
    }
    return (
        <Button themes={['white-small', 'end-tight']} className="ac-analytics-filter-button" text="Filter" onClick={props.onClick} additionalClasses={props.isExpanded ? expandedStyle : buttonStyle} rightComponent={() => renderFilterRight()} />
    )
};

const expandedStyle = css`
    background: linear-gradient(0deg, rgba(30, 126, 185, 0.07), rgba(30, 126, 185, 0.07)), #FFFFFF;
    border: 1px solid ${color_text_link};
    color: ${color_text_link};

    .filter-right {
        margin-left: 8px;
        width: 20px;
        img {
            width: 16px;
            height: 16px;
        }
    }

    .filter-count {
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${color_text_link};
        width: 20px;
        height: 20px;
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
        }
    }
`
const buttonStyle = css`

    .filter-right {
        margin-left: 8px;
        width: 20px;
        img {
            width: 16px;
            height: 16px;
        }
    }

    .filter-count {
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${color_text_link};
        width: 20px;
        height: 20px;
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
        }
    }
`

export default FilterToggleButton;