import React from 'react';
import { css } from '@emotion/css';
import ToggleIndicator from '../general/ToggleIndicator';

const scrollviewStyle = css`
    height: auto;
    width: 100%;
    height: 100%;
    margin-bottom: 80px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 0px;
    flex-grow: 1;
`

interface ScrollViewProps {
    hideBar?: boolean
    showIndicators?: boolean
    onScrollChange?: (scrollLeft: number, isAtRight: boolean) => void
}

const containerStyle = css`
width: 100%;
height: 100%;
height: auto;
overflow: hidden;
display: flex;
align-items: center;

.left-indicator {
    margin-left: 4px;
    margin-right: auto;
}

.right-indicator {
    margin-right: 4px;
    margin-left: auto;
}
`

class HorizontalScrollView extends React.Component<ScrollViewProps> {
    container: HTMLDivElement
    scrollLeft(pixels: number) {
        this.container.scrollLeft -= pixels
        this.updateScrollChange();
    }
    scrollRight(pixels: number) {
        this.container.scrollLeft += pixels
        this.updateScrollChange();
    }
    handleScroll() {
        this.updateScrollChange();
    }
    private updateScrollChange() {
        if (this.props.onScrollChange) {
            const isAtRight = (this.container.scrollLeft + 5 + this.container.offsetWidth) >= this.container.scrollWidth;
            this.props.onScrollChange(this.container.scrollLeft, isAtRight)
        }
    }
    render() {
        return (
            <div className={containerStyle} >
                {this.props.showIndicators ? <ToggleIndicator direction="left" className="left-indicator" /> : null}
                <div className={scrollviewStyle} onScroll={this.handleScroll.bind(this)} ref={view => this.container = view} style={{ paddingBottom: this.props.hideBar ? 17 : 0 }}>
                    {this.props.children}
                </div>
                {this.props.showIndicators ? <ToggleIndicator direction="right" className="right-indicator" /> : null}
            </div>
        )
    }
}

export default HorizontalScrollView;
