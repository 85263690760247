import IResult from "../../models/result/IResult";
import { sendGet, sendPut } from "./generic";
import { GET_NOTIFICATIONS } from "../../constants/Urls";
import VoicifyNotification from "../../models/notifications/VoicifyNotification";

export const getAllNotifications = (): Promise<IResult<VoicifyNotification[]>> => {
    return sendGet<VoicifyNotification[]>(`${GET_NOTIFICATIONS}/all`);
}
export const markNotificationAsRead = (notificationId: string): Promise<IResult<VoicifyNotification>> => {
    return sendPut<VoicifyNotification>(`${GET_NOTIFICATIONS}/${notificationId}/read`, {});
}
export const markAllNotificationsAsRead = (): Promise<IResult<VoicifyNotification[]>> => {
    return sendPut<VoicifyNotification[]>(`${GET_NOTIFICATIONS}/read`, {});
}