import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled';
import MenuContainer from '../../hooks/MenuContainer';
import { style_border_default } from '../../constants/stylesValues';
import MenuItemEditor from './components/MenuItemEditor';
import OptionGroupAndOptionPanel from './components/OptionGroupAndOptionPanel';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ApplicationNlpMenuContainer from '../../hooks/ApplicationNlpMenuContainer';
import StatusIndicator from '../../components/general/StatusIndicator';
import MenuSyncContainer from '../../hooks/MenuSyncContainer';

interface ApplicationMenuItemManagerProps {
    application: ApplicationModel
    history: any
}
const ApplicationMenuItemManager: React.FC<ApplicationMenuItemManagerProps> = (props) => {
    const menuContainer = MenuContainer.useContainer();
    const applicationNlpMenuContainer = ApplicationNlpMenuContainer.useContainer();
    const rightPanelRef = useRef(null);

    useEffect(() => {
        if (!props.application.manageMenuApplicationId) return;
        const url = window.location.pathname;
        if (!url) return;

        const urlItemIdMath = url.match(/\/menu\/item\/([^/]+)/)
        const itemId = Array.isArray(urlItemIdMath) ? urlItemIdMath[1] : null;

        if (!menuContainer.categories || menuContainer.categories.length === 0 && !menuContainer?.menuItems?.length) {
            menuContainer.getMenu(props.application.manageMenuApplicationId);
        }
        else if (!menuContainer.menuItems || menuContainer.menuItems.length === 0) {
            menuContainer.getMenu(props.application.manageMenuApplicationId);
        }

        if (menuContainer.menuItems && menuContainer.menuItems.length > 0) {
            const menuItem = menuContainer.menuItems.find(menuItem => menuItem.id === itemId);
            if (menuItem) {
                menuContainer.setMenuItem(menuItem);
                menuContainer.setOriginalMenuItem(JSON.parse(JSON.stringify(menuItem)))
            }
            else {
                props.history.goBack();
            }
        }
    }, [menuContainer.menuItems, props.application.manageMenuApplicationId, menuContainer?.menuItems?.length])


    return (
        <>
            <MenuItemWrapper >
                <MenuItemEditorWrapper>
                    <MenuItemEditor
                        history={props.history}
                        rightPanelRef={rightPanelRef}
                    />
                </MenuItemEditorWrapper>

                <MenuItemOptionGroupWrapper>
                    <OptionGroupAndOptionPanel
                        history={props.history}
                        application={props.application}
                        rightPanelRef={rightPanelRef}
                    />
                </MenuItemOptionGroupWrapper>
            </MenuItemWrapper>
            {menuContainer.menuItemAugmentStatus ?
                <StatusIndicator
                    state={menuContainer.menuItemAugmentStatus}
                    failedMessage='The assistant training failed. Please try again by making any necessary updates and re-saving your menu item.'
                    loadingMessage='Training the assistant with updated menu item preferences.'
                    successMessage='The assistant is now trained with the updates to this menu item.'
                    isClosable={menuContainer.menuItemAugmentStatus === "success"}
                    onClose={() => menuContainer.setMenuItemAugmentStatus(null)}
                    onAcceptFailure={() => {
                        if (!props.application.manageMenuApplicationId) return;
                        //they have accepted the augment failure
                        //set the lastAugmentFinishTime to the start time so that the dialog is not shown
                        const menuItem = { ...menuContainer.menuItem, lastAugmentFinishTime: menuContainer.menuItem.lastAugmentStartTime };
                        applicationNlpMenuContainer.updateVoicifyMenuItem(props.application.manageMenuApplicationId, menuItem);
                        menuContainer.setMenuItemAugmentStatus(null)
                    }}
                />
                :
                <></>
            }
        </>
    )


}



const MenuItemWrapper = styled.div`
    height: 100%;
    display: flex;
    border-top: ${style_border_default};
    overflow:hidden;
`;

const MenuItemEditorWrapper = styled.div`
    height: calc(100vh - 300px);
    display: flex;
    width: 50vw;
`;

const MenuItemOptionGroupWrapper = styled.div`
    border-left: ${style_border_default};
    height: 100%;
    width: 50vw;
`;

export default ApplicationMenuItemManager;
