import React from "react";
import Axios from "axios";
import Loader from "../../components/general/Loader";
import { Subscribe } from "unstated";
import AuthContainer from "../../state/containers/AuthContainer";
import { Redirect, RouteComponentProps } from "react-router";
import Acceptor from "../acceptInvitation/components/Acceptor";
import UserContainer from "../../state/containers/UserContainer";

interface RouteProps {
    invitationCode?: string
}


interface SsoLoginProps extends RouteComponentProps<RouteProps> { }

interface SingleSignOnRedirectorChildProps {
    invitationCode?: string,
    authContainer: AuthContainer,
    userContainer: UserContainer
}

class SingleSignOnLoginRedirector extends React.Component<SsoLoginProps> {
    render() {
        return (
            <Subscribe to={[AuthContainer, UserContainer]}>
                {(authContainer: AuthContainer, userContainer: UserContainer) =>
                    <SingleSignOnRedirectorChild authContainer={authContainer} userContainer={userContainer} invitationCode={this.props.match.params.invitationCode} />}
            </Subscribe>
        );
    }
};

class SingleSignOnRedirectorChild extends React.Component<SingleSignOnRedirectorChildProps> {
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get("email");
        const token = urlParams.get("token");
        this.props.authContainer.signInWithSingleSignOn(email, token).then(result => {
            if (result.resultType == "Ok" && this.props.invitationCode !== undefined) {
                this.props.userContainer.acceptInvitation(this.props.invitationCode);
            }
        });
    }
    render() {
        if (this.props.authContainer.state.isAuthenticated === true) {
            return <Redirect to="/v" />
        }
        return (<Loader />)
    }
}

export default SingleSignOnLoginRedirector;