import { css } from '@emotion/css';
import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { color_colors_light_ocean, color_shades_darker } from '../../constants/colors';
import Button from '../general/Button';
import styled from '@emotion/styled';
import SmallOverflowMenu from '../general/overflowMenu/SmallOverflowMenu';
import { OverflowOption } from '../general/overflowMenu/OverflowMenu';

interface props {
	className?: string,
	buttonsClassName: string,
	selectedItemColor: string,
	nonSelectedItemsColor: string,
	selectedItemIndex: number,
	setSelectedItemIndex: Dispatch<SetStateAction<number>>
	selectedTextColor: string,
	nonSelectedTextColor: string,
	children?: ReactNode,
	itemsArray: string[],
	hasOverFlowMenu?: boolean,
	overFlowMenuOptions?: OverflowOption[],
	onOverFlowOptionSelected?: (index: number, optionName: string) => void
	setIsOverFlowMenuOpen?: (bool: boolean) => void
	isOverFlowMenuOpen?: boolean
}

export default function HorizontalScrollableDiv(props: props) {

	return (
		<div className={`${props.className} ${containerStyle} ${props.isOverFlowMenuOpen && containerHeight}`}>
			{props.itemsArray.map((item, index) => {
				const backgroundColor =
					props.selectedItemIndex === index
						? props.selectedItemColor
						: props.nonSelectedItemsColor;
				const color =
					props.selectedItemIndex === index
						? props.selectedTextColor
						: props.nonSelectedTextColor;
				const innerStyle = css`
					background: ${backgroundColor};
					color: ${color};
					margin: 0;
					padding: 5px 10px 5px 10px;
		  		`;
				const firstCategoryOverflowStyle = css`
					margin: 0;
					text-align: center;
					margin: 10px -10px 20px 0px;
		  		`;

				return (
					<HorizontalScrollableWrapper className={innerStyle} key={index}>
						<Button
							themes={['black-small']}
							text={item}
							className={`${props.buttonsClassName} ${innerStyle}`}
							onClick={() => props.setSelectedItemIndex(index)}
						/>
						{props.hasOverFlowMenu && props.overFlowMenuOptions && (
							<SmallOverflowMenu
								options={props.overFlowMenuOptions}
								buttonClassName={`${overflowMenuButtonStyle}`}
								optionClassName={`${overflowOptionStyle}`}
								menuClassName={`${overflowMenuStyle} ${index === 0 && firstCategoryOverflowStyle
									}`}
								onOptionSelected={(optionName: string) =>
									props.onOverFlowOptionSelected(index, optionName)
								}
								setIsOverFlowMenuOpen={typeof props.setIsOverFlowMenuOpen === 'function' ? props.setIsOverFlowMenuOpen : undefined}
							/>
						)}
					</HorizontalScrollableWrapper>
				);
			})}
		</div>
	);
}

const overflowMenuStyle = css`
    width: 90px;
    margin-left: 0px;
    right: -11px;
    z-index: 2;
`;

const overflowOptionStyle = css`
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
`;

const overflowMenuButtonStyle = css`    
    width: 24px;
    height: 24px;
    border-color: ${color_shades_darker};
    .circle {
        width: 4px;
        height: 4px;
        border-color: ${color_shades_darker};
    }
`;

const HorizontalScrollableWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
`;


const containerHeight = css`
	height: 200px;
`;
const containerStyle = css`
	padding: 18px 0px;
	display: flex;
	overflow-x: auto; 
	justify-content: flex-start; /* Align items to the start */
	align-items: center;
	width: 100%;
	max-width: 99vw;
	margin: 0 auto;
	scrollbar-color: ${color_colors_light_ocean};
	scrollbar-width: thin; /* Firefox */
	
	/* Webkit-based browsers */
	::-webkit-scrollbar {
		width: 8px; /* Adjust the width of the scrollbar */
	}

	::-webkit-scrollbar-thumb {
		background-color: ${color_colors_light_ocean};
		border-radius: 10px; /* Optional: makes the scrollbar thumb rounded */
	}

	::-webkit-scrollbar-track {
		background: transparent; /* Optional: color of the scrollbar track */
	}

`;
