import React from 'react';
import { css } from '@emotion/css';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import { InjectedFormikProps, withFormik, Form } from 'formik';
import * as yup from 'yup';
import Button from '../../../components/general/Button';
import OrganizationModel from '../../../models/organizations/api/OrganizationModel';
import Select, { Option } from 'react-select';
import Loader from '../../../components/general/Loader';
import SelectField from '../../../components/forms/SelectField';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import UserContainer from '../../../state/containers/UserContainer';

interface ResetFormData {
    password?: string;
    confirmPassword?: string;
}
interface ResetFormProps {
    stateContainer: UserContainer
    resetToken: string
}

const buttonSplitStyle = css`
    margin-top: 60px;
    margin-bottom: -56px;
`

class InnerForm extends React.Component<InjectedFormikProps<ResetFormProps, ResetFormData>> {
    componentWillMount() {
        this.props.stateContainer.resetPasswordSetRequest();
    }
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit}>
                <FormFieldsContainer>
                    <h2>Forgot Password</h2>
                    <p>Enter the email of the account. We will send you an email with instructions to reset your password.</p>
                    <TextField type="password" name="password" label="New Password" placeholder="Make sure it's a good one" value={this.props.values.password} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                    <TextField type="password" name="confirmPassword" label="Confirm New Password" placeholder="One more time" value={this.props.values.confirmPassword} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
               
                </FormFieldsContainer>
                <Button themes={["primary", "wide"]} type="submit" disabled={this.props.isSubmitting} text="Submit" additionalClasses={buttonSplitStyle} />
            </Form>

        );
    }
}
const ResetPasswordForm = withFormik<ResetFormProps, ResetFormData>({
    mapPropsToValues: props => ({ password: '', confirmPassword: '' }),
    validationSchema: yup.object().shape({
        password: yup.string().required("You must enter a new password"),
        confirmPassword: yup.string().required("You must confirm your new password"),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        if (values.password !== '' && values.confirmPassword !== '' && values.confirmPassword === values.password) {
            props.stateContainer.setPassword({
                newPassword: values.password,
                confirmNewPassword: values.confirmPassword,
                resetCode: props.resetToken
            }).then(result => {
                setSubmitting(false);
                if(result.resultType !== "Ok") {
                    alert(result.errors[0]);
                }
            });
        }
        else {
            alert("Your passwords don't match");
            setSubmitting(false);
        }
    }
})(InnerForm);

export default ResetPasswordForm;