import React from 'react';
import styled from '@emotion/styled';
import { breakpoint_small } from '../../constants/breakpoints';

const logo = require('../../content/images/logo.svg');

const LandingContainer: React.FC = (props) => {

    return (
        <LandingContainerWrapper>
            <LandingImage src={logo} />
            {props.children}
        </LandingContainerWrapper>
    )

};

const LandingImage = styled.img`
    width: 300px;
    margin: 42px 42px 42px 120px;
    ${breakpoint_small} {
        width: 150px;
        margin: 32px 42px;
    }
`;

const LandingContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #f7f7f7, #eef0f1);
    max-height: 100vh;
`;

export default LandingContainer;