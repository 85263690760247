import IResult from "../../models/result/IResult";
import { queryStringSerialize, sendGet, sendPost, sendPut } from "./generic";
import TemplateConfigurationModel from "../../models/templating/api/TemplateConfigurationModel";
import TemplateCategoryModel from "../../models/templating/api/TemplateCategoryModel";
import TemplateIndustryModel from "../../models/templating/api/TemplateIndustryModel";
import TemplateSearchFilter from "../../models/templating/api/TemplateSearchFilter";
import TemplateConfigurationCountModel from "../../models/templating/api/TemplateConfigurationCountModel";

export const getAvailableTemplateConfigurations = (organizationId: string, filter?: TemplateSearchFilter): Promise<IResult<TemplateConfigurationModel[]>> => {
    return sendGet(`/TemplateConfiguration/${organizationId}/available?${queryStringSerialize(filter)}`);
}

export const getOrganizationTemplateConfigurations = (organizationId: string): Promise<IResult<TemplateConfigurationModel[]>> => {
    return sendGet(`/TemplateConfiguration/${organizationId}`);
}

export const getPrivateTemplateConfigurations = (organizationId: string): Promise<IResult<TemplateConfigurationModel[]>> => {
    return sendGet(`/TemplateConfiguration/${organizationId}/private`);
}

export const createTemplateConfiguration = (request: TemplateConfigurationModel): Promise<IResult<TemplateConfigurationModel>> => {
    return sendPost(`/TemplateConfiguration/`, request);
}

export const updateTemplateConfiguration = (templateConfigurationId: string, request: TemplateConfigurationModel): Promise<IResult<TemplateConfigurationModel>> => {
    return sendPut(`/TemplateConfiguration/${templateConfigurationId}`, request);
}

export const disableTemplateConfiguration = (templateConfigurationId: string): Promise<IResult<TemplateConfigurationModel>> => {
    return sendPut(`/TemplateConfiguration/${templateConfigurationId}/disable`, null);
}

export const enableTemplateConfiguration = (templateConfigurationId: string): Promise<IResult<TemplateConfigurationModel>> => {
    return sendPut(`/TemplateConfiguration/${templateConfigurationId}/enable`, null);
}

export const getTemplateCategories = (): Promise<IResult<TemplateCategoryModel[]>> => {
    return sendGet(`/TemplateConfiguration/categories`);
}

export const getTemplateIndustries = (): Promise<IResult<TemplateIndustryModel[]>> => {
    return sendGet(`/TemplateConfiguration/industries`);
}

export const getAppliedTemplateFormCounts = (templateConfigurationId: string): Promise<IResult<TemplateConfigurationCountModel>> => {
    return sendGet(`/TemplateConfiguration/${templateConfigurationId}/appliedTemplateFormCounts`);
}