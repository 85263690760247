import React from 'react';
import { css } from '@emotion/css';
import { color_text_default, color_text_light } from '../../../constants/colors';
import styled from '@emotion/styled';

interface PanelTitleProps {
    title: string
    truncate?: boolean
    subtitle?: string
    subtitleStyle?: string
    detail: string
}

const PanelTitle: React.FC<PanelTitleProps> = (props) => (
    <div className={containerStyle}>
        <PanelContainerWrapper>
            <div className="title-container">
                <Title className={props.truncate ? "truncate" : ""}>{props.title}</Title>
                <div className="buttons">{props.children}</div>
            </div>
            {props.subtitle &&
                <Subtitle>{props.subtitle}</Subtitle>
            }
        </PanelContainerWrapper>
        <p>{props.detail}</p>
    </div>
)

const Title = styled.h3`
    &.truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const Subtitle = styled.h4``;

const PanelContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const containerStyle = css`
    padding: 16px 32px;
    .title-container {
        display: flex;
        align-items: center;
        .buttons {
            margin-right: 0;
            margin-left: auto;
            display: flex;
            align-items: center;
            button {
                margin: 0 16px;
            }
        }
    }
    h3 {
        font-size: 24px;
        line-height: 32px;
        color: ${color_text_default};
        font-style: normal;
        font-weight: normal;
    }
    p {
        font-size: 14px;
        line-height: 20px;
        color: ${color_text_light};
        margin: 16px 0;
    }
`;

export default PanelTitle;