import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import OrganizationModel from '../../../models/organizations/api/OrganizationModel';
import { color_shades_dark, color_variants_shadow_10, color_shades_darkest, color_colors_ocean, color_shades_lighter } from '../../../constants/colors';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import VerticalSeparator from '../../../components/structure/VerticalSeparator';
import { TabPanel } from 'react-tabs';
import TabContainerPanel from '../../../components/structure/Panels/TabContainerPanel';
import Button from '../../../components/general/Button';
import PlatformHelpMenu from '../../platformHelpMenu';
import OrganizationsMenuItem from './OrganizationsMenuItem';
import UserContainer from '../../../state/containers/UserContainer';
import NotificationsList from './NotificationsList';
import VoicifyNotification from '../../../models/notifications/VoicifyNotification';
import { Link } from 'react-router-dom';
import UserSettings from './UserSettings';
import PageError from '../../../components/general/PageError';
import ScrollablePanelBody from '../../../components/structure/ScrollablePanelBody';
import styled from '@emotion/styled';
import { style_border_default } from '../../../constants/stylesValues';
import SupportAssistant from '../../platformHelpMenu/SupportAssistant'
import OverflowMenu from '../../../components/general/overflowMenu/OverflowMenu';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import NotificationsContainer from '../../../hooks/NotificationsContainer';
import { breakpoint_small } from '../../../constants/breakpoints';

const closeIcon = require('../../../content/images/close_icon.svg');
const help = require('../../../content/images/tab-icons/help.svg');
const helpSelected = require('../../../content/images/tab-icons/help-blue.svg');
const notifications = require('../../../content/images/account-settings/notifications-dark.svg');
const notificationsSelected = require('../../../content/images/account-settings/notifications-light.svg');
const notificationsAvailable = require('../../../content/images/account-settings/notifications-available-dark.svg');
const notificationsAvailableSelected = require('../../../content/images/account-settings/notifications-available-light.svg');
const organization = require('../../../content/images/account-settings/organization-dark.svg');
const organizationSelected = require('../../../content/images/account-settings/organization-light.svg');
const userSettings = require('../../../content/images/account-settings/user-settings-dark.svg');
const userSettingsSelected = require('../../../content/images/account-settings/user-settings-light.svg');
const signoutIcon = require('../../../content/images/account-settings/sign-out-dark.svg');
const markAsSeenIcon = require('../../../content/images/account-settings/mark-as-seen.svg');

interface AccountPanelProps {
    onClose: () => void
    organizationContainer: OrganizationContainer
    userContainer: UserContainer
}

const AccountPanel: React.FC<AccountPanelProps> = ({ onClose, organizationContainer, userContainer }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [showChat, setShowChat] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const notificationsHook = NotificationsContainer.useContainer();

    useEffect(() => {
        // subscribe event
        document.addEventListener("click", handleDocumentClick);
        return () => {
            // unsubscribe event
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleDocumentClick = (e: any) => {
        if (e.target.closest(`.${accountPanelStyle}`)) return;
        onClose();
    };

    const handleOrganizationSelected = async (org: OrganizationModel) => {
        await organizationContainer.setCurrentOrgById(org.id);
        location.reload();
        location.href = "/v";
        onClose();
    };

    const checkUnreadNotifications = (notifications: VoicifyNotification[]) => {
        return notifications.some(n => !n.isRead);
    };

    const clearErrors = () => {
        notificationsHook.clearErrors();
        userContainer.clearErrors();
        organizationContainer.clearErrors();
    };

    const hasUnreadNotifications = checkUnreadNotifications(notificationsHook.notifications);

    const errors = [...userContainer.state.errors, ...notificationsHook.errors, ...organizationContainer.state.errors];

    const openNewTab = (url: string) => {
        const win = window.open(url, '_blank');
        win.focus();
    };

    const handleMenuOptionSelected = (label: string, e: any) => {
        e.preventDefault();
        e.stopPropagation();
        switch (label) {
            case "Getting Started Tutorials":
                openNewTab("https://app.voicify.com?appcue=ee2ca7a4-4168-4c5b-b755-1c3da3ee7df6");
                break;
            case "Open the Knowledge Center":
                openNewTab("https://support.voicify.com");
                break;
            case "Voicify Support Assistant":
                setShowChat(true);
                break;
            case "Contact Support":
                setShowChat(false);
                break;
        };
    };

    const helpDropdownOptions = [
        { label: "Getting Started Tutorials" },
        { label: "Open the Knowledge Center" },
        { label: "Voicify Support Assistant" },
        { label: "Contact Support" }
    ];

    return (
        <div className={`${accountPanelStyle}`}>
            <div className={headerStyle}>
                <div className="title"> Your Account</div>
                <span />
                <VerticalSeparator className={verticalSeparator} />
                <button className="close-button" type="button" onClick={() => onClose()}>
                    <img src={closeIcon} />
                </button>
            </div>
            <div className={signOutStyle}>
                <VerticalSeparator className={`${verticalSeparator} ${signOutVerticalSeparator}`} />
                <Link to="/v/logout"><Button themes={['secondary-small']} text="Sign Out" type="button" rightIcon={signoutIcon} /></Link>
            </div>
            <TabContainerPanel
                className={tabContainerPanelStyle}
                tabs={[{
                    title: "Notifications",
                    icon: hasUnreadNotifications ? notificationsAvailable : notifications,
                    selectedIcon: hasUnreadNotifications ? notificationsAvailableSelected : notificationsSelected
                }, {
                    title: "Settings",
                    icon: userSettings,
                    selectedIcon: userSettingsSelected
                }, {
                    title: "Organization",
                    icon: organization,
                    selectedIcon: organizationSelected
                }, {
                    title: "Help",
                    icon: help,
                    selectedIcon: helpSelected
                }]}
                onTabSelected={(newIndex) => { setSelectedIndex(newIndex) }}
                selectedIndex={selectedIndex}
            >
                <TabPanel>
                    <ScrollablePanelBody className={noPadding}>
                        <div className={`${tabTitleStyle} ${notificationsHeaderStyle}`}>
                            <TabTitle>Notifications</TabTitle>
                            <span />
                            <div onClick={() => notificationsHook.markAllNotificationsAsRead()} className="markAsSeen">Mark all as seen</div>
                            <img className={markAsSeenIconStyle} onClick={() => notificationsHook.markAllNotificationsAsRead()} src={markAsSeenIcon} />
                        </div>
                        <NotificationsList />
                    </ScrollablePanelBody>
                </TabPanel>
                <TabPanel>
                    <ScrollablePanelBody className={noPadding}>
                        <UserSettings userContainer={userContainer} />
                    </ScrollablePanelBody>
                </TabPanel>
                <TabPanel className={orgContainerStyle}>
                    <ScrollablePanelBody className={noPadding}>
                        <div className={tabTitleStyle}>
                            <TabTitle>Organization</TabTitle>
                        </div>
                        <OrganizationsMenuItem
                            organizationContainer={organizationContainer}
                            userContainer={userContainer}
                            onOrganizationSelected={handleOrganizationSelected}
                            organizations={organizationContainer.state.organizations}
                            currentOrganization={organizationContainer.state.currentOrganization}
                        />
                    </ScrollablePanelBody>
                </TabPanel>
                <TabPanel>
                    <ScrollablePanelBody className={`${noPadding} ${darkBackgroundStyle}`}>
                        <div className={tabTitleStyle}>
                            <TabTitle>Help & Support</TabTitle>
                            <MenuLinkWrapper onClick={() => setShowMenu(!showMenu)} >
                                <MenuLink>Menu</MenuLink>
                                {showMenu ?
                                    <ArrowWrapper><Arrow direction={"up"} /></ArrowWrapper>
                                    :
                                    <ArrowWrapper><Arrow direction={"down"} /></ArrowWrapper>
                                }
                            </MenuLinkWrapper>
                            {showMenu &&
                                <AccountOverflowMenu
                                    options={helpDropdownOptions}
                                    onClose={() => setShowMenu(false)}
                                    onOptionSelected={handleMenuOptionSelected}
                                    optionClassName={overflowOptionStyle}
                                />
                            }
                        </div>
                        {showChat ?
                            <SupportAssistant userContainer={userContainer} />
                            :
                            <PlatformHelpMenu />}
                    </ScrollablePanelBody>
                </TabPanel>
            </TabContainerPanel>
            <PageError onClear={() => clearErrors()} errors={errors} />
        </div >
    );
};

const tabContainerPanelStyle = css`
    .panel-tab-list {
        margin-left: 12px;
    }
`;

const markAsSeenIconStyle = css`
    width: 24px;
    height: 24px;
    margin-top: 16px;
`;

const TabTitle = styled.div`
    margin-left: 32px;
`

const overflowOptionStyle = css`
    height: 48px;
    p {
        font-size: 14px;
        font-weight: 400;
        padding: 14px;
        :first-child {
            :hover {
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
            }
        }
        :last-child {
            :hover {
                border-bottom-right-radius: 12px;
                border-bottom-left-radius: 12px;
            }
        }
    }
`

const MenuLinkWrapper = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    outline: none;
    margin-right: 32px;
`

const MenuLink = styled.div`
    cursor: pointer;
    font-size: 16px;
    font-family: Muli;
    color: ${color_shades_darkest};
`

const ArrowWrapper = styled.div`
    margin: 8px;
`

const Arrow = styled(ToggleIndicator)`
    height: 8px;
    width: 8px;
`

const AccountOverflowMenu = styled(OverflowMenu)`
    margin-top: 272px;
    margin-left: 228px;
    ${breakpoint_small} {
        margin-left: 24px;
    }
`

const darkBackgroundStyle = css`
    background: ${color_shades_lighter};    
    height: 100%;
`

const tabTitleStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: ${style_border_default};
    font-size: 18px;
    line-height: 24px;
    background: white;
`

const orgContainerStyle = css`
    background: ${color_shades_lighter};
`;

const notificationsHeaderStyle = css`
    display: flex;
    flex-direction: row;
    padding-right: 32px;
    .markAsSeen {
        font-size: 14px;
        cursor: pointer;
        margin-right: 8px;
        line-height: 20px;
        color: ${color_colors_ocean};
    }
    img {
        cursor: pointer;
        width: 24px;
    }
    span {
        flex-grow: 1;
    }
`

const signOutStyle = css`
    display: flex;
    flex-direction: row;
    z-index: 3;
    height: 32px;
    top: 75px;
    right: 10;
    position: absolute;
    ${breakpoint_small} {
        top: 32px;
        right: 200px;
    }
`;

const headerStyle = css`
    padding-left: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    height: 79px;
    align-items: center;
    border-bottom: ${style_border_default};
    span {
        flex-grow: 1;
    }
    .title {
        font-size: 24px;
        padding-bottom: 8px;
        line-height: 32px;
        height: 32px;        
    }
    .close-button {
        margin-left: 32px;
        margin-right: 36px;
        cursor: pointer;
        background: none;
        border: none;
        height: 32px;
        width: 32px;
        img {
            height: 32px;
            width: 32px;
        }
    }
    ${breakpoint_small} {
        padding: 32px 0 64px 32px;
    }
`;

const verticalSeparator = css`
    background: ${color_shades_dark};
    height: 32px;
    ${breakpoint_small} {
        display: none;
    }
`;

const signOutVerticalSeparator = css`
    top: 14px;
    position: relative;    
`

const accountPanelStyle = css`
    height: 100vh;
    width: 576px;
    z-index: 2;
    position: absolute;
    top: 0px;
    right: 0px;
    border: ${style_border_default};
    box-shadow: 0px 4px 20px ${color_variants_shadow_10};
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    background-color: white;
    color: ${color_shades_darkest};
    ${breakpoint_small} {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`;

const noPadding = css`
    padding: 0px;
`;

export default AccountPanel;
