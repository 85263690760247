import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, primary_gradient, ocean_blue } from '../../constants/colors';

interface ProgressBarProps {
    percent: number
}


const outerStyle = css`
    height: 24px;
    width: 100%;
    border-radius: 12px;
    background: #eeeeee;
`

const innerStyle = css`
    height: 24px;
    border-radius: 12px;
    background: ${primary_gradient};
`
const wrapperStyle = css`
width: 100%;
`
const ProgressBar: React.SFC<ProgressBarProps> = (props) => (
    <div className={wrapperStyle}>
        {props.percent === 0 ? null :
            <div className={outerStyle}>
                <div className={innerStyle} style={{ width: `${props.percent}%` }}></div>
            </div>
        }
    </div>
)

export default ProgressBar;