import React, { useState } from "react";
import TextField from "../../../components/forms/TextField";
import Button from "../../../components/general/Button";
import styled from "@emotion/styled";
import { color_shades_light } from "../../../constants/colors";
import { FieldArray } from "formik";

const closeIcon = require("../../../content/images/close_icon.svg");
const addIcon = require("../../../content/images/add-circle-dark.svg");

export interface SynonymsEditorProps {
    synonyms: string[],
    synonymsFieldName: string,
    disableFields: boolean,
    handleDelete: (index: number, arrayHelpers: any) => void,
    handleAddSynonym: (synonym: string) => void,
    handleBlur?;
    autofocusNewSynonym: boolean;
    className?: string;
    placeholder?: string
}

const SynonymsEditor = (props: SynonymsEditorProps) => {
    const [newSynonymValue, setNewSynonymValue] = useState("");

    const handleNewSynonymKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
        if (props.disableFields) return;

        if (event.key === "Escape") {
            // Clear new synonym
            setNewSynonymValue("");
        } else if (event.key === "Enter") {
            // Add new synonym
            props.handleAddSynonym(newSynonymValue);
            setNewSynonymValue("");
        }
        event.stopPropagation();
    };

    const handleAddNewSynonymChange = (event) => {
        const synonym: string = event.target.value;
        setNewSynonymValue(synonym);
    };
    return (
        <CardContainer className={props.className ?? ""}>
            <fieldset>
                {props.synonyms && (
                    <SynonymsHolder>
                        <FieldArray
                            validateOnChange={false}
                            name={props.synonymsFieldName}
                            render={(arrayHelpers) =>
                                props.synonyms?.map((synonym, synIndex) => (
                                    <SynonymChip
                                        key={`display-${props.synonymsFieldName}.${synIndex}`}>
                                        <span>{synonym}</span>
                                        {
                                            props.disableFields ?
                                                null
                                                :
                                                <img onClick={() => props.handleDelete(synIndex, arrayHelpers)} src={closeIcon} />
                                        }
                                    </SynonymChip>
                                ))
                            }
                        />
                    </SynonymsHolder>
                )}

                {!props.disableFields && (
                    <>
                        <div
                            className="new-field-container"
                            key={`${props.synonymsFieldName}.addNewContainer`}
                            onKeyUp={handleNewSynonymKeyUp}
                        >
                            <TextField
                                disabled={props.disableFields}
                                placeholder={props.placeholder ? props.placeholder : 'Add a synonym'}
                                value={newSynonymValue}
                                onChange={handleAddNewSynonymChange}
                                onBlur={props.handleBlur}
                                useForm={false}
                                autoFocus={props.autofocusNewSynonym}
                            />
                            <AddSynonymButton
                            type="button"
                            themes={["icon", "end-tight"]}
                            icon={addIcon}
                            disabled={!newSynonymValue || props.disableFields}
                            onClick={() => {
                                props.handleAddSynonym(newSynonymValue);
                                setNewSynonymValue("");
                            }}
                            />
                        </div>
                    </>
                )}
            </fieldset>
        </CardContainer>
    );
};

export default SynonymsEditor;

const SynonymChip = styled.div`
    height: 42px;
    padding: 0 8px 0 16px;
    margin: 0 8px 8px 0;
    display: flex;
    flex-direction: row;
    background: ${color_shades_light};
    border-radius: 32px;
    line-height: 40px;
    img {
        width: 14px;
        height: 14px;
        cursor: pointer;
        margin: 14px 4px 0px 8px;
    }
`;

const SynonymsHolder = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const CardContainer = styled.div`
    .field-wrapper {
        label {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    .field-container {
        height: 44px;
    }
    label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .new-field-container {
        display: flex;
    }
`;

const AddSynonymButton = styled(Button)`
    margin-left: 16px;
    padding-top: 2px;
`;
