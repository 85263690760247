import React, { useState } from "react";
import { createContainer } from "unstated-next";
import LanguageModel from '../models/features/api/LanguageModel';
import IResult from '../models/result/IResult';
import * as voicifyApi from '../api';

function useLanguageContainer() {
    const [languages, setLanguages] = useState([] as LanguageModel[]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [currentSelectedLanguages, setCurrentSelectedLanguages] = useState([] as LanguageModel[]);

    const updateSelectedLanguages = (languages: LanguageModel[]) => {
        setCurrentSelectedLanguages(languages)
    };

    const loadLanguages = async (): Promise<IResult<LanguageModel[]>> => {
        try {
            setIsLoading(true)
            const result = await voicifyApi.getAllLanguages()
            if (result.resultType == "Ok") { 
                await setLanguages(result.data)
                setIsLoading(false)
            }
            else if(result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors])
                setIsLoading(false)
            }
            return result;
        } catch (error) {
            setErrors([error])
            setIsLoading(false)
        }
    };

    return {
        languages,
        currentSelectedLanguages,
        updateSelectedLanguages,
        loadLanguages
    };
}

const LanguageContainer = createContainer(useLanguageContainer);
export default LanguageContainer;
