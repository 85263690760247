import React from 'react';
import { css } from '@emotion/css';
import { color_text_light, silver_two } from '../../constants/colors';
import HorizontalSeparator from './HorizontalSeparator';
import styled from '@emotion/styled';

// used for containing data in the form style card

interface ContentCardProps {
    title?: string
    subtitle?: string
    subtitleStyle?: string
    icon?: string
    id?: string
    className?: string
    rightRender?: () => JSX.Element
}

const ContentCard: React.FC<ContentCardProps> = (props) => (
    <div className={`${cardStyle} ${props.className ?? ''}`}>
        {
            props.title !== undefined ?
                <TitleWrapper>
                    <div className={titleStyle}>
                        <a id={props.id} />
                        {props.icon !== undefined ? <img src={props.icon} /> : null}
                        <span>{props.title}</span>
                        <div className="end">
                            {props.rightRender ? props.rightRender() : null}
                        </div>
                    </div>
                    <Subtitle className={props.subtitleStyle}>{props.subtitle}</Subtitle>
                </TitleWrapper>
                : null
        }
        {props.title !== undefined ? (<HorizontalSeparator className={horizontalSeparatorStyle} />) : null}
        {props.children}
    </div>
);

const horizontalSeparatorStyle = css`
    margin-bottom: 16px;
`;

const Subtitle = styled.div`
    color: ${color_text_light};
    margin-bottom: 16px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const cardStyle = css`
    border-radius: 10px;
    background-color: #ffffff;
    border: solid 1px ${silver_two};
    padding: 32px 32px 12px 32px;
    margin-bottom: 32px;
    flex-grow: 0;
`;

export const NoMarginContentCard = styled(ContentCard)`
    margin-bottom: 0;
`;

const titleStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    >img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
    }
    >span {
        font-family: Muli;
        font-size: 18px;
        line-height: 24px;  
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2c3d52;
    }
    .end {
        margin-right: 0;
        margin-left: auto;
        button {
            margin: 0;
        }
    }
`;

export default ContentCard;