import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue_20 } from '../../constants/colors';

interface TabIndicatorProps {
    color: string;
    className?: string
}
const TabIndicator: React.SFC<TabIndicatorProps> = (props) => {
    const indicatorStyle = css`
        background: ${props.color}
        height: 3px;
        width: 50px;
        display: block;
        ${props.className === undefined ? `
        margin: 0 auto;` : ''}
        border-radius: 2px;
        box-shadow: -1px 1px 4px 0 ${dark_grey_blue_20}, -1px 1px 2px 0 rgba(0, 116, 166, 0.1);
    `;

    return (
        <div className={`tab-indicator ${props.className} ${indicatorStyle}`}/>
    );
}

export default TabIndicator;