import React from 'react';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { breakpoint_small } from '../../../constants/breakpoints';

const iconUrl = require('../../../content/images/icon.svg');
const logoUrl = require('../../../content/images/logo.svg');
const inverseLogo = require('../../../content/images/logo-inverse.svg');

interface LogoProps {
    isFull: boolean;
    isInverse?: boolean;
}

const Logo: React.FC<LogoProps> = (props) => (
    <div className={logoStyle}>
        <Link to="/v">
            {props.isFull ? <img src={props.isInverse ? inverseLogo : logoUrl} className="logo" alt="Voicify" /> : <img src={iconUrl} className="icon" alt="Voicify" />}
        </Link>
    </div>
);

const logoStyle = css`
    padding-left: 24px;
    img {
        padding: 0 4px;
        margin-right: 24px;
    }
    .icon {
        height: 42px;
        margin-right: 12px;
    }
    ${breakpoint_small} {
        img {
            margin-right: 0;
            padding: 0;
        }
        .logo {
            width: 100px;
        }
    }
`;

export default Logo;