import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../shared/components/general/TableHeader';
import TableCell from '../../../../../shared/components/general/TableCell';
import { css } from '@emotion/css';
import SortedTable from '../../../../../shared/components/structure/SortedTable';
import { OutboundCallModel } from '../../../../../shared/models/features/api/OutboundCall/OutboundCallModel';
import CustomScrollbars from '../../../../../shared/components/structure/CustomScrollbars';
import * as voicifyApi from '../../../../../shared/api/controllers/outboundCall';
import Button from '../../../../../shared/components/general/Button';
import ConfirmationDialog from '../../../../../shared/components/structure/ConfirmationDialog';
import WarningConfirmation from '../../../../../shared/components/general/WarningConfirmation';
import OutboundCallContainer from '../../../../../shared/hooks/OutboundCallContainer';
import qs from 'query-string';
import AnalyticsInterval from '../../../../../shared/models/analytics/AnalyticsInterval';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../shared/models/analytics/AnalyticsDateFormat';
import AnalyticsRequestFilter from '../../../../../shared/models/analytics/AnalyticsRequestFilter';
import PanelHeaderContainer from '../../../../../shared/components/structure/Panels/PanelHeaderContainer';
import TimeIntervalSelectorHeader from '../../../../../shared/components/analytics/TimeIntervalSelectorHeader';
import DateRangeIntervalPicker from '../../../../../shared/components/analytics/DateRangeIntervalPicker';
import { color_shades_dark } from '../../../../../shared/constants/colors';
const deleteIcon = require("../../../../../shared/content/images/custom-assistant-deployment/delete.svg");

interface props {
    history: any
}

export default function NumbersToCall(props: props) {
    const query = qs.parse(window.location.search);

    const [numberToDelete, setNumberToDelete] = useState<OutboundCallModel>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [loading, isLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>(moment().startOf('day').format(DATE_FORMAT));
    const [endDate, setEndDate] = useState<string>(moment().startOf('day').add(7, 'days').format(DATE_FORMAT));
    const [isDatePickerExpanded, setIsDatePickerExpanded] = useState<boolean>(false);
    const [interval, setInterval] = useState<AnalyticsInterval>(query?.interval as string ?? 'past 7 days');
    const [filter, setFilter] = useState<AnalyticsRequestFilter>({});


    const outboundCallContainer = OutboundCallContainer.useContainer();
    useEffect(() => {
        filter.startDate = startDate;
        filter.endDate = endDate;

        outboundCallContainer.fetchOutboundCalls(filter.startDate, filter.endDate);
    }, [startDate, endDate, outboundCallContainer.organizationId]);

    const toggleDatePicker = () => {
        setIsDatePickerExpanded(!isDatePickerExpanded);
    };
    const handleDateRangeChange = (startDate: string, endDate: string, interval: AnalyticsInterval) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setInterval(interval);
        setIsDatePickerExpanded(false);
        setFilter({
            ...filter
        });

        props.history.push({
            search: `?startDate=${startDate}&endDate=${endDate}&interval=${interval}`
        });

    };

    const convertTimeRangeToLocal = (timeRange: string) => {
        const [start, end] = timeRange.split(' - ');
        const localStart = moment.utc(start, 'ha').local();
        const localEnd = moment.utc(end, 'ha').local();
        return `${localStart.format('hA')} - ${localEnd.format('hA')}`;
    };

    const getLocalCallDate = (utcDate: string, timeRange: string) => {
        if (!timeRange) return moment.utc(utcDate).format('YYYY-MM-DD');

        const [start] = timeRange.split(' - ');
        const localDateTime = moment.utc(utcDate)
            .hour(moment.utc(start, 'ha').hour())
            .local();

        return localDateTime.format('YYYY-MM-DD');
    };

    const columns = [
        {
            id: "assistantNumber",
            Header: <TableHeader text="Assistant Number" />,
            accessor: (m: any) => m,
            Cell: row => (
                <TableCell>
                    <span>
                        {row.value.assistantNumber ?? "NA"}
                    </span>
                </TableCell>
            )
        },
        {
            id: "number",
            Header: <TableHeader text="Number" />,
            accessor: (m: any) => m,
            Cell: row => (
                <TableCell>
                    <span>
                        {row.value.numberToCall}
                    </span>
                </TableCell>
            )
        },
        {
            id: "date",
            Header: <TableHeader text="Date" />,
            accessor: (m: any) => m,
            Cell: row => (
                <TableCell>
                    <span>
                        {getLocalCallDate(row.value.callDate, row.value.timeRange)}
                    </span>
                </TableCell>
            )
        },
        {
            id: "leavevoicemail",
            Header: <TableHeader text="Leave Voicemail?" />,
            accessor: (m: any) => m,
            Cell: row => (
                <TableCell>
                    <span>
                        {row.value.leaveVoicemail ? "Yes" : "No"}
                    </span>
                </TableCell>
            )
        },
        {
            id: "timerange",
            Header: <TableHeader text="Time Range" />,
            accessor: (m: any) => m,
            Cell: row => (
                <TableCell>
                    <span>
                        {row.value.timeRange ? convertTimeRangeToLocal(row.value.timeRange) : "NA"}
                    </span>
                </TableCell>
            )
        },
        // TODO: numberOfAttempts and frequencyOfAttempts are present in OutboundCallModel but we don't currently use them
        // {
        //     id: "numberOfAttempts",
        //     Header: <TableHeader text="# Attempts" />,
        //     accessor: (m: any) => m,
        //     Cell: row => (
        //         <TableCell>
        //             <span>
        //                 {row.value.numberOfAttempts ?? "NA"}
        //             </span>
        //         </TableCell>
        //     )
        // },
        // {
        //     id: "freqofattempts",
        //     Header: <TableHeader text="Freq of Attempts" />,
        //     accessor: (m: any) => m,
        //     Cell: row => (
        //         <TableCell>
        //             <span>
        //                 {row.value.frequencyOfAttempts ?? "NA"}
        //             </span>
        //         </TableCell>
        //     )
        // },
        {
            id: "remove",
            Header: <TableHeader text="" />,
            accessor: (m: any) => m,
            width: 50,
            Cell: row => (
                <TableCell>
                    <Button themes={["icon-small"]} icon={deleteIcon} onClick={() => {
                        setNumberToDelete(row.value);
                        setShowDeleteModal(true);
                    }} />
                </TableCell>
            ),
        },
    ]

    const handleDeleteNumber = async () => {
        isLoading(true);
        const deleteResult = await outboundCallContainer.deleteOutboundCall(numberToDelete.id);
        if (deleteResult.resultType === "Ok") {
            const newNumbersToCall = outboundCallContainer.numbersToCall.filter(n => n.id !== numberToDelete.id);
            outboundCallContainer.setNumbersToCall(newNumbersToCall);
            setShowDeleteModal(false);
            isLoading(false);
        }
    }

    const deleteModal = () => (
        <ConfirmationDialog
            title="Delete Number"
            deleteText="Delete Number"
            warning={false}
            isLoading={loading}
            onClose={() => { setShowDeleteModal(false); }}
            onConfirm={handleDeleteNumber}>
            <WarningConfirmation text={`Are you sure you want to delete ${numberToDelete.numberToCall}?`} />
        </ConfirmationDialog>
    )

    return (
        <>
            {showDeleteModal && deleteModal()}
            <div className={datePickerContainerStyle}>
                <PanelHeaderContainer className={panelHeaderStyle}>
                    <div>
                        <Title>Numbers To Call</Title>
                        <TitleDescription>Numbers yet to be called</TitleDescription>
                    </div>
                    <div className={intervalSelectorWrapperStyle}>
                        <TimeIntervalSelectorHeader
                            startDate={startDate}
                            endDate={endDate}
                            isExpanded={isDatePickerExpanded}
                            onClick={toggleDatePicker}
                            className={intervalSelectorStyle}
                        />
                        {isDatePickerExpanded && (
                            <DateRangeIntervalPicker
                                startDate={startDate}
                                endDate={endDate}
                                interval={interval}
                                onChange={handleDateRangeChange}
                                openAtTop={true}
                                showTimeIntervalPicker={false}
                                onClose={toggleDatePicker}
                            />
                        )}
                    </div>
                </PanelHeaderContainer>

                <CustomScrollbars autoHide autoHeight autoHeightMax="calc(85vh - 600px)">
                    <SortedTable
                        noDataText="No Numbers To Call"
                        className={tableStyle}
                        columns={columns}
                        data={outboundCallContainer.numbersToCall.filter(number => !number.status)} // only show numbers that have not been called
                    />
                </CustomScrollbars>
            </div>
        </>
    )
}

const panelHeaderStyle = css`
    padding: 0 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        margin-right: 0;
        margin-left: auto;
    }
    h2 {
        font-size: 18px;
        line-height: 24px;
    }
    border: none;
`;

const intervalSelectorStyle = css`
    .time-selection {
        padding-left: 16px;
        font-size: 14px;
        line-height: 32px;
        border-left: 1px solid ${color_shades_dark};   
        >span {
            margin-right: 12px;
        }
    }
`;

const Title = styled.h1`
    font-size: 18px;
    font-weight: bold;
`;

const TitleDescription = styled.p`
    font-size: 14px;
    font-weight: light;
    opacity: 0.8;
`;

const tableStyle = css`
    border: none;
    text-align: center;
    .rt-noData {
        margin-top: 30px;
    }
`;

const datePickerContainerStyle = css`
    position: relative;
`;

const intervalSelectorWrapperStyle = css`
    position: relative;
    display: inline-block;
`;


