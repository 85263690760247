import React from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer from '../../../state/containers/AppliedApplicationTemplateContainer';
import AppliedAppSectionSelector from './AppliedAppSectionSelector';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import { color_shades_dark } from '../../../constants/colors';
import TemplateSectionStatusModel from '../../../models/templating/TemplateSectionStatusModel';
import TemplateFormSectionModel from '../../../models/templating/api/TemplateFormSectionModel';
import SparkTemplateFormSectionModel from '../../../models/templating/api/SparkTemplateFormSectionModel';
import { AppendedSection } from '../../../../clients/spark/scenes/dashboard/ApplicationManagerContent';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import TemplateFormFieldValueModel from '../../../models/templating/api/TemplateFormFieldValueModel';

interface AppliedAppTemplateSectionMenuProps {
    history: any
    applicationId: string
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    isFinishSelected: boolean
    isStartSelected?: boolean
    sections: TemplateFormSectionModel[] | SparkTemplateFormSectionModel[] | any[]
    activeSection?: TemplateSectionStatusModel | any
    isNew?: boolean
    onFinishSelect: () => void
    onStartSelect: () => void
    onSectionSelect: (section: TemplateFormSectionModel, appliedTemplateId: string) => void
    onAppendedSectionSelect?: (section: AppendedSection) => void
    firstSectionName?: string
    hideStart?: boolean
    appendedSections?: AppendedSection[]
    templateConfigContainer: TemplateConfigurationContainer
    orgContainer: OrganizationContainer
    appliedAppTemplateId: string
    importedFormFields?: TemplateFormFieldValueModel[]
    setImportedFormFields?: (fields: TemplateFormFieldValueModel[]) => void
    fileImportErrors?: string[]
    setFileImportErrors?: (errors: string[]) => void
    handleToggleExpandLeft?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, expanded: boolean) => void
}

const AppliedAppTemplateSectionMenu: React.FC<AppliedAppTemplateSectionMenuProps> = (props) => {
    
    const handleActiveSectionChange = (status: TemplateSectionStatusModel | any) => {
        if (!status.section) {
            const section = props.appendedSections.find((section) => {
                return section.id === status.id
            });
            props.onAppendedSectionSelect(section);
        } else {
            const section = props.sections.find((section) => {
                return section.section.title === status.section.title
            });
            props.onSectionSelect(status.section, section.appliedTemplateId);
        }
    };

    const handleFinishSelected = () => {
        props.onFinishSelect();
    };

    const handleStartSelected = () => {
        if (props.hideStart) return;
        props.onStartSelect();
    };

    return (
        <div className={containerStyle}>
            <CustomScrollbars className={scrollContainer} autoHide={true} autoHeight={false} autoHeightMax={"calc(100vh - 136px)"}>
                <AppliedAppSectionSelector
                    handleToggleExpandLeft={props.handleToggleExpandLeft}
                    fileImportErrors={props.fileImportErrors}
                    setFileImportErrors={props.setFileImportErrors}
                    importedFormFields={props.importedFormFields}
                    setImportedFormFields={props.setImportedFormFields}
                    firstSectionName={props.firstSectionName}
                    sections={props.sections}
                    activeSection={props.activeSection}
                    isStartSelected={props.isStartSelected && !props.activeSection}
                    isFinishSelected={props.isFinishSelected && !props.activeSection}
                    isNew={props.isNew}
                    handleActiveSectionChange={handleActiveSectionChange}
                    onStartSelect={handleStartSelected}
                    onFinishSelect={handleFinishSelected}
                    hideStart={props.hideStart}
                    appendedSections={props.appendedSections}
                    templateConfigContainer={props.templateConfigContainer}
                    orgContainer={props.orgContainer}
                    applicationId={props.applicationId}
                    appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                    appContainer={props.appContainer}
                    appliedAppTemplateId={props.appliedAppTemplateId}
                />
            </CustomScrollbars>
        </div>
    )

}

const containerStyle = css`
    flex: 1;
`
const scrollContainer = css`
    flex: 1;
`

export default AppliedAppTemplateSectionMenu;