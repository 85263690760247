import { GET_LATESTMESSAGES, GET_LATESTMESSAGES_FOR_MODULE, CREATE_LATESTMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import LatestMessageModel from '../../models/features/api/LatestMessages/LatestMessageModel';
import NewLatestMessageRequest from '../../models/features/api/LatestMessages/NewLatestMessageRequest';
import BulkUpdateLatestMessageRequest from '../../models/features/api/LatestMessages/BulkUpdateLatestMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getLatestMessagesForModule = (applicationModuleId: string): Promise<IResult<LatestMessageModel[]>> => {
    return sendGet<LatestMessageModel[]>(`${GET_LATESTMESSAGES_FOR_MODULE}/${applicationModuleId}`);

}

export const getLatestMessagesForApp = (applicationId: string): Promise<IResult<LatestMessageModel[]>> => {
    return sendGet<LatestMessageModel[]>(`${GET_LATESTMESSAGES}/${applicationId}`);
}

export const getLatestMessagesForAppFeature = (applicationFeatureId: string): Promise<IResult<LatestMessageModel[]>> => {
    return sendGet<LatestMessageModel[]>(`${GET_LATESTMESSAGES}/byFeature/${applicationFeatureId}`);
}


export const createFullLatestMessage = (request: LatestMessageModel): Promise<IResult<LatestMessageModel>> => {
    return sendPost<LatestMessageModel>(`${CREATE_LATESTMESSAGE}/full`, request);
}
export const createLatestMessage = (request: NewLatestMessageRequest): Promise<IResult<LatestMessageModel>> => {
    return sendPost<LatestMessageModel>(`${CREATE_LATESTMESSAGE}`, request);
}
export const createLatestMessageStub = (request: NewLatestMessageRequest): Promise<IResult<LatestMessageModel>> => {
    return sendPost<LatestMessageModel>(`${CREATE_LATESTMESSAGE}/stub`, request);
}

export const toggleLatestMessageLive = (latestMessageId: string, isLive: boolean) : Promise<IResult<LatestMessageModel>> => {
    return sendPut<LatestMessageModel>(`/LatestMessage/${latestMessageId}/isLive`, {
        isLive
    });
}
export const toggleLatestMessageSync = (latestMessageId: string, shouldNotSync: boolean): Promise<IResult<LatestMessageModel>> => {
    return sendPut<LatestMessageModel>(`/LatestMessage/${latestMessageId}/shouldNotSync`, {
        shouldNotSync
    });
}

export const updateFullLatestMessage = (latestMessageId: string, request: LatestMessageModel): Promise<IResult<LatestMessageModel>> => {
    return sendPut<LatestMessageModel>(`/LatestMessage/${latestMessageId}/full`, request);
}

export const bulkUpdateLatestMessage = (latestMessageId: string, request: BulkUpdateLatestMessageRequest): Promise<IResult<LatestMessageModel>> => {
    return sendPut<LatestMessageModel>(`/LatestMessage/${latestMessageId}/bulkUpdate`, request);
}

export const bulkUpdateLatestMessageStub = (latestMessageId: string, request: BulkUpdateLatestMessageRequest): Promise<IResult<LatestMessageModel>> => {
    return sendPut<LatestMessageModel>(`/LatestMessage/${latestMessageId}/bulkUpdate/stub`, request);
}
export const deleteLatestMessage = (latestMessageId: string): Promise<IResult<LatestMessageModel>> => {
    return sendDelete<LatestMessageModel>(`/LatestMessage/${latestMessageId}`);
}
export const findLatestMessage = (latestMessageId: string): Promise<IResult<LatestMessageModel>> => {
    return sendGet<LatestMessageModel>(`/LatestMessage/full/${latestMessageId}`);
}

export const getLatestMessageWebhooks = (latestMessageId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/LatestMessage/${latestMessageId}/webhooks`);
}

export const removeLatestMessageWebhook = (latestMessageWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/LatestMessage/webhook/${latestMessageWebhookId}`);
}

export const addLatestMessageWebhook = (latestMessageId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/LatestMessage/${latestMessageId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateLatestMessageWebhook = (latestMessageId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/LatestMessage/${latestMessageId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const copyLatestMessage = (contentItemId: string): Promise<IResult<LatestMessageModel>> => {
    return sendPost<LatestMessageModel>(`/LatestMessage/${contentItemId}/copy`, null);
}

export const bulkUpdateLatestMessageWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/LatestMessage/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getLatestMessagePublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/LatestMessage/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}