import React from 'react';
import { InjectedFormikProps, withFormik, Form, FieldArray, ArrayHelpers } from "formik";
import * as yup from 'yup';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import UserContainer from '../../../state/containers/UserContainer';
import Loader from '../../../components/general/Loader';
import { css } from '@emotion/css';
import { color_shades_dark } from '../../../constants/colors';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';

interface PersonalInfoFormProps {
    userContainer: UserContainer
    onFinish?: () => void
    onClose?: () => void
    onChange?: () => void
}
interface PersonalInfoFormData {
    firstName?: string
    lastName?: string
}
interface PersonalInfoFormState {
    imageUrl?: string
}
class InnerForm extends React.Component<InjectedFormikProps<PersonalInfoFormProps, PersonalInfoFormData>, PersonalInfoFormState> {
    componentWillMount() {

        if (this.props.userContainer.state.currentUser == null) {
            this.props.userContainer.getUser().then(result => {
                this.setState({
                    ...this.state,
                    imageUrl: this.props.userContainer.state.currentUser.imageUrl
                });
            });
        }
        else {
            this.setState({
                ...this.state,
                imageUrl: this.props.userContainer.state.currentUser.imageUrl
            });
        }
    }
    handleImageSelected(file: File) {
        this.readDataUrl(file);

        this.props.setFieldValue("imageFile", file);
    }
    readDataUrl(file: File) {
        const reader = new FileReader();
        reader.onloadend = e => {
            this.setState({
                ...this.state,
                imageUrl: reader.result.toString()
            })
        }

        reader.readAsDataURL(file);
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.userContainer.state.isLoading;

        return (
            <div className={containerStyle}>
                {props.userContainer.state.currentUser == null ? <Loader /> :
                    <form onSubmit={props.handleSubmit}>
                        <div className="title">Personal Info</div>
                        <HorizontalSeparator />
                        <FormFieldsContainer className={fieldsContainerStyle}>
                            <TextField name="firstName" required value={props.values.firstName} label="First Name" placeholder="What name do you go by?" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                            <TextField name="lastName" required value={props.values.lastName} label="Last Name" placeholder="What's your family name?" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                        </FormFieldsContainer>
                        <HorizontalSeparator />
                        <Button className="submit-button" loading={isLoading} disabled={isLoading} themes={["primary"]} text="Update" type="submit" />
                    </form>
                }
            </div>
        )
    }
}

const PersonalInfoForm = withFormik<PersonalInfoFormProps, PersonalInfoFormData>({
    mapPropsToValues: props => ({
        firstName: props.userContainer?.state?.currentUser?.firstName ?? '',
        lastName: props.userContainer?.state?.currentUser?.lastName ?? '',
    }),
    validationSchema: yup.object().shape({
        firstName: yup.string().required("You must enter your first name"),
        lastName: yup.string().required("You must enter your last name"),
    }),
    handleSubmit: (values, { props }) => {
        props.userContainer.updateUserDetails({
            firstName: values.firstName,
            lastName: values.lastName,
        })
    }
})(InnerForm);

export default PersonalInfoForm;

const fieldsContainerStyle = css`
    margin-top: 12px;
    margin-bottom: 32px;
`

const containerStyle = css`
    border: 1px solid ${color_shades_dark};
    background: white;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 24px 32px;
    padding-bottom: 0px;
    .submit-button {
        margin-left: 0px;
    }
    .title {        
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
    }
`