import { useState } from "react";
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import { ANALYTICS_ORDER_STATE } from "../constants/Keys";

export interface AnalyticsOrderState {
    [sessionId: string]: {
        orderPlaced?: boolean
        successfulConversation?: boolean
    }
}

function useAnalyticsOrderStateContainer() {
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [isSuccessfulConversation, setIsSuccessfulConversation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);

    const getStoredAnalyticsOrderState = (): AnalyticsOrderState => {
        let storedState: AnalyticsOrderState = {};
        const storedAnalyticsOrderState = localStorage.getItem(ANALYTICS_ORDER_STATE);
        if (storedAnalyticsOrderState)
            storedState = JSON.parse(storedAnalyticsOrderState);
        return storedState;
    };

    const storeAnalyticsOrderState = (state: AnalyticsOrderState) => {
        const json = JSON.stringify(state);
        localStorage.setItem(ANALYTICS_ORDER_STATE, json);
    };

    const markOrderAsPlaced = async (applicationId: string, sessionId: string) => {
        try {
            setIsLoading(true);
            const storedAnalyticsOrderState = getStoredAnalyticsOrderState();
            const result = await voicifyApi.markOrderAsPlaced(applicationId, sessionId, {});
            if (result.resultType == "Ok") {
                setOrderPlaced(true);
                storeAnalyticsOrderState({ ...storedAnalyticsOrderState, [sessionId]: { orderPlaced: true } });
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const markOrderAsSuccessfulConversation = async (applicationId: string, sessionId: string) => {
        try {
            setIsLoading(true);
            const storedAnalyticsOrderState = getStoredAnalyticsOrderState();
            const result = await voicifyApi.markOrderAsConversationalSuccess(applicationId, sessionId, {});
            if (result.resultType == "Ok") {
                setIsSuccessfulConversation(true);
                if (storedAnalyticsOrderState[sessionId]) {
                    storedAnalyticsOrderState[sessionId].successfulConversation = true;
                }
                storeAnalyticsOrderState(storedAnalyticsOrderState);
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    return {
        markOrderAsPlaced,
        orderPlaced,
        markOrderAsSuccessfulConversation,
        isSuccessfulConversation,
        getStoredAnalyticsOrderState,
        storeAnalyticsOrderState,
        isLoading,
        errors
    };
}

const AnalyticsOrderStateContainer = createContainer(useAnalyticsOrderStateContainer);
export default AnalyticsOrderStateContainer;
