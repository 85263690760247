import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";
import { APPLICATION_NLP_ENTITY } from "../../constants/Urls";
import NlpEntityModel from "../../models/nlpEntity/api/NlpEntityModel";
import ApplicationNlpEntityModel from "../../models/nlpEntity/api/ApplicationNlpEntityModel";
import NewNlpEntityRequest from "../../models/nlpEntity/api/NewNlpEntityRequest";
import UpdateNlpEntityRequest from "../../models/nlpEntity/api/UpdateNlpEntityRequest";

export const addApplicationNlpEntity = (applicationId: string, request: NewNlpEntityRequest) : Promise<IResult<ApplicationNlpEntityModel>> => {
    return sendPost(`${APPLICATION_NLP_ENTITY}/${applicationId}`, request);
}

export const getApplicationNlpEntitiesInLocale = (applicationId: string, locale: string) : Promise<IResult<ApplicationNlpEntityModel[]>> => {
    return sendGet(`${APPLICATION_NLP_ENTITY}/${applicationId}/locale/${locale}`);
}

export const getApplicationNlpEntities = (applicationId: string) : Promise<IResult<ApplicationNlpEntityModel[]>> => {
    return sendGet(`${APPLICATION_NLP_ENTITY}/${applicationId}`);
}

export const getApplicationNlpEntity = (applicationId: string, nlpEntityId: string) : Promise<IResult<ApplicationNlpEntityModel>> => {
    return sendGet(`${APPLICATION_NLP_ENTITY}/${applicationId}/${nlpEntityId}`);
}

export const updateApplicationNlpEntity = (applicationId: string, nlpEntityId: string, request: UpdateNlpEntityRequest) : Promise<IResult<ApplicationNlpEntityModel>> => {
    return sendPut(`${APPLICATION_NLP_ENTITY}/${applicationId}/${nlpEntityId}`, request);
}

export const deleteApplicationNlpEntity = (applicationId: string, nlpEntityId: string) : Promise<IResult<ApplicationNlpEntityModel>> => {
    return sendDelete(`${APPLICATION_NLP_ENTITY}/${applicationId}/${nlpEntityId}`);
}

export const copyApplicationNlpEntity = (applicationId: string, nlpEntityId: string) : Promise<IResult<ApplicationNlpEntityModel>> => {
    return sendPost(`${APPLICATION_NLP_ENTITY}/${applicationId}/${nlpEntityId}/copy`, null);
}

export const applyPreBuiltEntityToApplication = (applicationId: string, prebuiltNlpEntityId: string) : Promise<IResult<ApplicationNlpEntityModel>> => {
    return sendPost(`${APPLICATION_NLP_ENTITY}/${applicationId}/${prebuiltNlpEntityId}/applyPrebuiltToApplication`, null);
}

export const getApplicationPrebuiltNlpEntitiesInLocale = (applicationId: string, locale: string) : Promise<IResult<NlpEntityModel[]>> => {
    return sendGet(`${APPLICATION_NLP_ENTITY}/${applicationId}/locale/${locale}/prebuilt`);
}

export const updateAllApplicationNlpEntities = (applicationId: string, request: ApplicationNlpEntityModel[]) : Promise<IResult<ApplicationNlpEntityModel[]>> => {
    return sendPut(`${APPLICATION_NLP_ENTITY}/${applicationId}/updateAllEntities`, request);
}
