import React from 'react';
import { css } from '@emotion/css';
import { RouteComponentProps } from 'react-router';
import Button from '../../../components/general/Button';

interface ResetProps {
    history: any;
}

const buttonSplitStyle = css`
    margin-top: 60px;
    margin-bottom: -56px;
`
class ResetSuccess extends React.Component<ResetProps>
{
    handleBack() {
        this.props.history.goBack();
    }
    render() {
        return (
            <div>
                <h2>Reset Sent</h2>
                <p>We've sent you an email with instructions to reset your password. Once you're done, you can come back here and sign in.</p>
                <Button themes={["primary", "wide"]} type="button" text="Back to Login" additionalClasses={buttonSplitStyle} onClick={this.handleBack.bind(this)}/>
            </div>
        );
    }
}

export default ResetSuccess;