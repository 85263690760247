import CustomAssistantRequest from "../../models/customAssistant/CustomAssistantRequest";
import { sendPost } from "./generic";
import { getAssistantApiUrl } from "../../constants/Urls";
import CustomAssistantResponse from "../../models/customAssistant/CustomAssistantResponse";
import TokenResponse from "../../models/customAssistant/TokenResponse";

export const sendCustomAssistantRequest = (applicationId: string,
    applicationSecret: string,
    request: CustomAssistantRequest,
    useDraftContent: boolean,
    includeSessionAttributes: boolean,
    includeNlp: boolean) => {
    return sendPost<CustomAssistantResponse>(
        `/CustomAssistant/HandleRequest?applicationId=${applicationId}&applicationSecret=${applicationSecret}&useDraftContent=${useDraftContent}&includeSessionAttributes=${includeSessionAttributes}&includeNlp=${includeNlp}`,
        request, getAssistantApiUrl());
}


export const generateSessionToken = (applicationId: string,
    applicationSecret: string,
    environmentId: string,
    sessionId: string) => {
    return sendPost<TokenResponse>(
        `/Token/GenerateForSession`,
        {
            applicationId: applicationId,
            applicationSecret: applicationSecret,
            environmentId: environmentId,
            sessionId: sessionId
        },
        getAssistantApiUrl());
}