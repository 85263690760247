import IResult from "../../models/result/IResult";
import { sendGet, sendGetFile, sendPost } from "./generic";
import NewArchiveRequest from "../../models/features/api/NewArchiveRequest";
import ApplicationArchiveModel from "../../models/features/api/ApplicationArchiveModel";

export const queueArchive = (request: NewArchiveRequest): Promise<IResult<ApplicationArchiveModel>> => {
    return sendPost(`/archive/queue`, request);
}
export const getArchive = (archiveId: string): Promise<IResult<ApplicationArchiveModel>> => {
    return sendGet(`/archive/${archiveId}`);
}
export const getArchiveFile = (fileUrl: string): Promise<IResult<Blob>> => {
    return sendGetFile("", fileUrl);
}

