import React, { useState } from "react"
import { createContainer } from "unstated-next"
import IResult from "../models/result/IResult";
import * as voicifyApi from '../api';
import InteractionModel from "../models/interactionModel/api/InteractionModel";
import LanguageModel from "../models/features/api/LanguageModel";
import InteractionModelUpdateWithEntitiesRequest from "../models/interactionModel/api/InteractionModelUpdateWithEntitiesRequest";
import InteractionModelUpdate from "../models/interactionModel/api/InteractionModelUpdate";
import EditedMenuItemModel from "../models/nlp/menu/EditedMenuItemModel";
import EditedMenuItemOptionGroupModel from "../models/nlp/menu/EditedMenuItemOptionGroupModel";
import EditedMenuItemSizeGroupModel from "../models/nlp/menu/EditedMenuItemSizeGroupModel";
import { Matches } from "../scenes/interactionModel/components/InteractionModelEditorWrapper";
import { MenuItemOptionGroup } from "../models/nlp/menu/MenuItemOptionGroup";
import { MenuItemSizeGroup } from "../models/nlp/menu/MenuItemSizeGroup";
import { MenuItemOption } from "../models/nlp/menu/MenuItemOption";
import { MenuItemSize } from "../models/nlp/menu/MenuItemSize";
import MenuItemModel from "../models/nlp/menu/MenuItemModel";
import { v4 as uuidv4 } from 'uuid';

function useInteractionModelContainer() {
    const [interactionModels, setInteractionModel] = useState([] as InteractionModel[]);
    const [errors, setErrors] = useState([] as string[]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentApplicationId, setCurrentApplicationId] = useState(null as string);

    const [editedMenuItems, setEditedMenuItems] = useState([] as EditedMenuItemModel[]);
    const [sizeGroupsList, setSizeGroupsList] = useState<EditedMenuItemSizeGroupModel[]>();
    const [sizeGroupMatches, setSizeGroupMatches] = useState<Matches[]>();
    const [optionGroupsList, setOptionGroupsList] = useState<EditedMenuItemOptionGroupModel[]>();
    const [optionGroupMatches, setOptionGroupMatches] = useState<Matches[]>();
    

    const loadInteractionModels = async (applicationId: string, inLanguages: LanguageModel[]): Promise<IResult<InteractionModel[]>> => {
        try {
            setIsLoading(true);
            const promises = [];
            for (let i = 0; i < inLanguages.length; i++) {
                promises.push(voicifyApi.getInteractionModelByLocale(applicationId, inLanguages[i].shortCode));
            }
            const results = await Promise.all(promises);
            
            const newInteractionModels = [] as InteractionModel[];
            for (let i = 0; i < results.length; ++i) {
                const result = results[i];
                if(result.resultType != "Ok") {
                    setErrors([...errors, ...result.errors, "Unable to load Interaction Model"]);
                    setIsLoading(false);
                    return;
                }
                newInteractionModels.push(result.data);
            }

            setInteractionModel(newInteractionModels);
            setCurrentApplicationId(applicationId);
            setIsLoading(false);
            return {
                resultType: "Ok",
                data: newInteractionModels,
                errors: null
            };
        } catch (e) {
            setErrors([e?.toString(), "Unable to load Interaction Models"]);
            setIsLoading(false);
            return {
                resultType: "Invalid",
                data: null,
                errors: [e.toString()]
            };
        }
    }

    const updateNlpEntitiesAndInteractionModelForApplication = async (applicationId: string, request: InteractionModelUpdateWithEntitiesRequest): Promise<IResult<boolean>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.updateNlpEntitiesAndInteractionModelForApplication(applicationId, request);
            if (result?.resultType === "Ok") {
                setIsLoading(false);
                return result;
            } else {
                await setErrors([...errors, ...result.errors, "Unable to Update Application NLP Entities and Interaction Model"]);
            }
            setIsLoading(false);
        } catch (e) {
            setErrors([e?.toString(), "Unable to Update Application NLP Entities and Interaction Model"]);
            setIsLoading(false);
        }
    }

    const updateInteractionModel = async (applicationId: string, languageShortCode: string, request: InteractionModelUpdate): Promise<IResult<boolean>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.commitUpdateToInteractionModel(applicationId, languageShortCode, request);
            if (result?.resultType === "Ok") {
                setIsLoading(false);
                return result;
            } else {
                await setErrors([...errors, ...result.errors, "Unable to Update Interaction Model"]);
            }
            setIsLoading(false);
        } catch (e) {
            setErrors([e?.toString(), "Unable to Update Interaction Model"]);
            setIsLoading(false);
        }
    }

    const clearErrors = () => {
        setErrors([]);
    }


    const loadGroups = (menuItems: EditedMenuItemModel[], menuEditorView: string = "Option Groups") => {
        const uniqueGroups: (EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel)[] = [];
        const matchingGroups: Matches[] = [];
        const updatedMenuItems = [...menuItems];
        for (const updatedMenuItem of updatedMenuItems) {
            let groupsForMenuItem: MenuItemOptionGroup[] | MenuItemSizeGroup[] = [];

            groupsForMenuItem = getAllOptionGroups(updatedMenuItem.menuItem);

            for (const group of groupsForMenuItem) {
                let editedGroup: EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel;
                if ('options' in group) {
                    editedGroup = createOptionGroupForEditing(group);
                }
                else if ('sizes' in group) {
                    editedGroup = createSizeGroupForEditing(group);
                }
                let foundMatch = false;
                for (const uniqueGroup of uniqueGroups) {
                    let theyMatch = false;
                    if ('optionGroup' in editedGroup && 'optionGroup' in uniqueGroup) {
                        theyMatch = groupsMatch(editedGroup.optionGroup, uniqueGroup.optionGroup);
                    } else if ('sizeGroup' in editedGroup && 'sizeGroup' in uniqueGroup) {
                        theyMatch = groupsMatch(editedGroup.sizeGroup, uniqueGroup.sizeGroup);
                    }
                    if (theyMatch) {
                        foundMatch = true;
                        group.editId = uniqueGroup.editId;
                        if ('options' in group && 'optionGroup' in uniqueGroup) {
                            for (const optionGroupOption of group.options) {
                                optionGroupOption.editId = uniqueGroup.optionGroup.options.find((uniqueOptionGroupOption: MenuItemOption) => {
                                    return optionKey(uniqueOptionGroupOption) === optionKey(optionGroupOption);
                                })?.editId;
                            }
                        } else if ('sizes' in group && 'sizeGroup' in uniqueGroup) {
                            for (const sizeGroupSize of group.sizes) {
                                sizeGroupSize.editId = uniqueGroup.sizeGroup.sizes.find((uniqueSizeGroupSize: MenuItemSize) => {
                                    return optionKey(uniqueSizeGroupSize) === optionKey(sizeGroupSize);
                                })?.editId;
                            }
                        }
                        const matchIndex = matchingGroups.findIndex((match) => {
                            return match[group.editId];
                        });
                        // add menu item id to existing optionGroup
                        if (!matchingGroups[matchIndex][group.editId].includes(updatedMenuItem.menuItem.id)) {
                            matchingGroups[matchIndex][group.editId].push(updatedMenuItem.menuItem.id);
                        }
                        break;
                    }
                }
                if (!foundMatch) {
                    editedGroup.editId = uuidv4();
                    group.editId = editedGroup.editId;
                    if ('options' in group && 'optionGroup' in editedGroup) {
                        for (const optionGroupOption of group.options) {
                            optionGroupOption.editId = uuidv4();
                            const option = editedGroup.optionGroup.options.find((uniqueOptionGroupOption: MenuItemOption) => {
                                return optionKey(uniqueOptionGroupOption) === optionKey(optionGroupOption);
                            });
                            option.editId = optionGroupOption.editId;
                        }
                    } else if ('sizes' in group && 'sizeGroup' in editedGroup) {
                        for (const sizeGroupSize of group.sizes) {
                            sizeGroupSize.editId = uuidv4();
                            const size = editedGroup.sizeGroup.sizes.find((uniqueSizeGroupSize: MenuItemSize) => {
                                return optionKey(uniqueSizeGroupSize) === optionKey(sizeGroupSize);
                            });
                            size.editId = sizeGroupSize.editId;
                        }
                    }
                    uniqueGroups.push(editedGroup);
                    matchingGroups.push({ [editedGroup.editId]: [updatedMenuItem.menuItem.id] });
                }
            };
            // sort option groups alphabetically
            if (menuEditorView === "Option Groups") {
                uniqueGroups.sort((a, b) => {
                    if ('optionGroup' in a && 'optionGroup' in b) {
                        if (a.optionGroup.name < b.optionGroup.name) {
                            return -1;
                        }
                        if (a.optionGroup.name > b.optionGroup.name) {
                            return 1;
                        }
                    }
                    return 0;
                });
            }
            setEditedMenuItems(updatedMenuItems);
            if (menuEditorView === "Option Groups") {
                setOptionGroupsList(uniqueGroups as EditedMenuItemOptionGroupModel[]);
                setOptionGroupMatches(matchingGroups);
            } else {
                setSizeGroupsList(uniqueGroups as EditedMenuItemSizeGroupModel[]);
                setSizeGroupMatches(matchingGroups);
            }
        }
        return { uniqueGroups, matchingGroups }
    };

    const getAllOptionGroups = (item: MenuItemModel | MenuItemOption | MenuItemSize): MenuItemOptionGroup[] => {
        const optionGroupsList: MenuItemOptionGroup[] = [...(item.optionGroups ?? [])];
        for (const optionGroup of item.optionGroups) {
            for (const option of optionGroup?.options) {
                if (option?.optionGroups?.length > 0) {
                    optionGroupsList.push(...getAllOptionGroups(option));
                };
            };
        };
        if ('sizeGroups' in item) {
            for (const sizeGroup of item.sizeGroups) {
                for (const size of sizeGroup?.sizes) {
                    if (size?.optionGroups?.length > 0) {
                        optionGroupsList.push(...getAllOptionGroups(size));
                    };
                }
            };
        }
        return optionGroupsList;
    };

    const createOptionGroupForEditing = (optionGroup: MenuItemOptionGroup): EditedMenuItemOptionGroupModel => {
        let editedOptionGroup: EditedMenuItemOptionGroupModel;
        if (!optionGroup) {
            editedOptionGroup = {
                optionGroup: {
                    referenceId: undefined,
                    name: undefined,
                    colloquialName: undefined,
                    numberOfRequiredOptions: undefined,
                    maxSelections: undefined,
                    limiter: undefined,
                    promptOverride: undefined,
                    initialPromptOverride: undefined,
                    simpleInitialPromptOverride: undefined,
                    numberedInitialPromptOverride: undefined,
                    automaticallyAddDefault: undefined,
                    isRequired: undefined,
                    promptIfNoSelections: undefined,
                    options: []
                },
                editId: undefined
            };
        } else {
            editedOptionGroup = {
                optionGroup: {
                    ...optionGroup,
                    editId: undefined,
                    referenceId: undefined,
                    options: (optionGroup.options?.map((option) => {
                        let editedOption: MenuItemOption;
                        if (!option) {
                            editedOption = {
                                id: undefined,
                                parentId: undefined,
                                name: undefined,
                                colloquialName: undefined,
                                modifier: undefined,
                                default: undefined,
                                fixed: undefined,
                                isPrimaryOption: undefined,
                                ignoreForReadout: undefined,
                                hasQuantity: undefined,
                                defaultQuantity: undefined,
                                minQuantity: undefined,
                                maxQuantity: undefined,
                                disableInferencing: undefined,
                                optionGroups: undefined,
                                editId: undefined
                            };
                        } else {
                            editedOption = option;
                        }
                        return {
                            ...editedOption,
                            optionGroups: undefined,
                            id: undefined,
                            parentId: undefined
                        };
                    }) || [])
                }
            };
        }
        editedOptionGroup.optionGroup.options?.forEach((o) => {
            if (o) {
                const excludeProperties = ['editId', 'referenceId', 'id', 'parentId', 'options'];
                Object.keys(o).forEach(key => {
                    if (!excludeProperties.includes(key) &&
                        o[key] === null || o[key] === undefined) {
                        delete o[key];
                    }
                });
            }
        })
        sortOptionsOrSizes(editedOptionGroup.optionGroup.options);
        return editedOptionGroup;
    };

    const createSizeGroupForEditing = (menuItemSizeGroup: MenuItemSizeGroup): EditedMenuItemSizeGroupModel => {
        let editedSizeGroup: EditedMenuItemSizeGroupModel;
        if (!menuItemSizeGroup) {
            editedSizeGroup = {
                sizeGroup: {
                    numberOfRequiredSizes: undefined,
                    promptOverride: undefined,
                    sizes: [],
                    initialPromptOverride: undefined,
                    numberedInitialPromptOverride: undefined
                },
                editId: undefined
            };
        } else {
            editedSizeGroup = {
                sizeGroup: {
                    ...menuItemSizeGroup,
                    editId: undefined,
                    sizes: (menuItemSizeGroup.sizes?.map((size) => {
                        let editedSize: MenuItemSize;
                        if (!size) {
                            editedSize = {
                                id: undefined,
                                name: undefined,
                                colloquialName: undefined,
                                default: undefined,
                                fixed: undefined,
                                ignoreForReadout: undefined,
                                disableInferencing: undefined,
                                optionGroups: undefined
                            };
                        } else {
                            editedSize = size;
                        }
                        return {
                            ...editedSize,
                            optionGroups: undefined,
                            id: undefined,
                            parentId: undefined
                        };
                    }) || [])
                }
            };
        }
        editedSizeGroup.sizeGroup.sizes?.forEach((size) => {
            if (sizeGroupMatches) {
                const excludeProperties = ['editId', 'sizes'];
                Object.keys(setSizeGroupsList).forEach(key => {
                    if (!excludeProperties.includes(key) &&
                        size[key] === null || size[key] === undefined) {
                        delete size[key];
                    }
                });
            }
        })
        sortOptionsOrSizes(editedSizeGroup.sizeGroup.sizes);
        return editedSizeGroup;
    };

    const sortOptionsOrSizes = (sizesOrOptions: (MenuItemOption | MenuItemSize)[]) => {
        sizesOrOptions?.sort((a, b) => {
            if (!a || !a.name) {
                return -1;
            }
            if (!b || !b.name) {
                return 1;
            }
            const key1 = optionKey(a);
            const key2 = optionKey(b);
            const nameA = key1.toLowerCase();
            const nameB = key2.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    const groupsMatch = (obj1: MenuItemOptionGroup | MenuItemSizeGroup, obj2: MenuItemOptionGroup | MenuItemSizeGroup) => {
        const excludeProperties = ['editId', 'referenceId', 'id', 'parentId'];
        const deepEqual = (val1, val2, exclude = []) => {
            if (val1 === val2) {
                return true;
            }
            if (typeof val1 !== 'object' || val1 === null || typeof val2 !== 'object' || val2 === null) {
                return false;
            }
            if (Array.isArray(val1) && Array.isArray(val2)) {
                if (val1.length !== val2.length) {
                    return false;
                }
                for (let i = 0; i < val1.length; i++) {
                    if (!deepEqual(val1[i], val2[i], exclude)) {
                        return false;
                    }
                }
                return true;
            }
            const keys1 = Object.keys(val1);
            const keys2 = Object.keys(val2);
            const validKeys1 = keys1.filter(key => !exclude.includes(key));
            const validKeys2 = keys2.filter(key => !exclude.includes(key));
            if (validKeys1.length !== validKeys2.length) {
                return false;
            }
            for (const key of validKeys1) {
                if (!validKeys2.includes(key)) {
                    return false;
                }
                if (!deepEqual(val1[key], val2[key], exclude)) {
                    return false;
                }
            }
            return true;
        };
        return deepEqual(obj1, obj2, excludeProperties);
    };

    const optionKey = (option: MenuItemOption): string => {
        return `${option?.name ?? "unknown-name"}-${option.modifier ?? "unknown-modifier"}`;
    };


    return {
        interactionModels,
        errors,
        isLoading,
        currentApplicationId,
        setErrors,
        clearErrors,
        loadInteractionModels,
        updateNlpEntitiesAndInteractionModelForApplication,
        updateInteractionModel,
        loadGroups,
        sizeGroupMatches,
        setSizeGroupMatches,
        optionGroupMatches,
        optionGroupsList,
        setOptionGroupsList,
        setOptionGroupMatches,
        sizeGroupsList,
        setSizeGroupsList,
        editedMenuItems,
        setEditedMenuItems
    };
}

const InteractionModelContainer = createContainer(useInteractionModelContainer);
export default InteractionModelContainer;
