"use strict";
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Voicify Assistant API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: V1
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileApi = exports.UserProfileApiFactory = exports.UserProfileApiFp = exports.UserProfileApiFetchParamCreator = exports.HealthCheckApi = exports.HealthCheckApiFactory = exports.HealthCheckApiFp = exports.HealthCheckApiFetchParamCreator = exports.GoogleAssistantApi = exports.GoogleAssistantApiFactory = exports.GoogleAssistantApiFp = exports.GoogleAssistantApiFetchParamCreator = exports.DialogflowApi = exports.DialogflowApiFactory = exports.DialogflowApiFp = exports.DialogflowApiFetchParamCreator = exports.CustomAssistantApi = exports.CustomAssistantApiFactory = exports.CustomAssistantApiFp = exports.CustomAssistantApiFetchParamCreator = exports.BotServiceApi = exports.BotServiceApiFactory = exports.BotServiceApiFp = exports.BotServiceApiFetchParamCreator = exports.BixbyApi = exports.BixbyApiFactory = exports.BixbyApiFp = exports.BixbyApiFetchParamCreator = exports.AlexaApi = exports.AlexaApiFactory = exports.AlexaApiFp = exports.AlexaApiFetchParamCreator = exports.ViewportShape = exports.PlayBehavior = exports.LocationServiceStatus = exports.LocationServiceAccess = exports.RequiredError = exports.BaseAPI = exports.COLLECTION_FORMATS = void 0;
const url = require("url");
const portableFetch = require("portable-fetch");
const BASE_PATH = "/".replace(/\/+$/, "");
/**
 *
 * @export
 */
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
class BaseAPI {
    constructor(configuration, basePath = BASE_PATH, fetch = portableFetch) {
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}
exports.BaseAPI = BaseAPI;
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
class RequiredError extends Error {
    constructor(field, msg) {
        super(msg);
        this.field = field;
    }
}
exports.RequiredError = RequiredError;
/**
 *
 * @export
 * @enum {string}
 */
var LocationServiceAccess;
(function (LocationServiceAccess) {
    LocationServiceAccess[LocationServiceAccess["ENABLED"] = 'ENABLED'] = "ENABLED";
    LocationServiceAccess[LocationServiceAccess["DISABLED"] = 'DISABLED'] = "DISABLED";
})(LocationServiceAccess = exports.LocationServiceAccess || (exports.LocationServiceAccess = {}));
/**
 *
 * @export
 * @enum {string}
 */
var LocationServiceStatus;
(function (LocationServiceStatus) {
    LocationServiceStatus[LocationServiceStatus["RUNNING"] = 'RUNNING'] = "RUNNING";
    LocationServiceStatus[LocationServiceStatus["STOPPED"] = 'STOPPED'] = "STOPPED";
})(LocationServiceStatus = exports.LocationServiceStatus || (exports.LocationServiceStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
var PlayBehavior;
(function (PlayBehavior) {
    PlayBehavior[PlayBehavior["REPLACEALL"] = 'REPLACE_ALL'] = "REPLACEALL";
    PlayBehavior[PlayBehavior["ENQUEUE"] = 'ENQUEUE'] = "ENQUEUE";
    PlayBehavior[PlayBehavior["REPLACEENQUEUED"] = 'REPLACE_ENQUEUED'] = "REPLACEENQUEUED";
})(PlayBehavior = exports.PlayBehavior || (exports.PlayBehavior = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ViewportShape;
(function (ViewportShape) {
    ViewportShape[ViewportShape["SQUARE"] = 'SQUARE'] = "SQUARE";
    ViewportShape[ViewportShape["RECTANGLE"] = 'RECTANGLE'] = "RECTANGLE";
    ViewportShape[ViewportShape["ROUND"] = 'ROUND'] = "ROUND";
})(ViewportShape = exports.ViewportShape || (exports.ViewportShape = {}));
/**
 * AlexaApi - fetch parameter creator
 * @export
 */
exports.AlexaApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {APLSkillRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleLocalRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Alexa/HandleRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("APLSkillRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {APLSkillRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSignedRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Alexa/HandleSignedRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("APLSkillRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AlexaApi - functional programming interface
 * @export
 */
exports.AlexaApiFp = function (configuration) {
    return {
        /**
         *
         * @param {APLSkillRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleLocalRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.AlexaApiFetchParamCreator(configuration).handleLocalRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {APLSkillRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSignedRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.AlexaApiFetchParamCreator(configuration).handleSignedRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * AlexaApi - factory interface
 * @export
 */
exports.AlexaApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {APLSkillRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleLocalRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.AlexaApiFp(configuration).handleLocalRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {APLSkillRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleSignedRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.AlexaApiFp(configuration).handleSignedRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
    };
};
/**
 * AlexaApi - object-oriented interface
 * @export
 * @class AlexaApi
 * @extends {BaseAPI}
 */
class AlexaApi extends BaseAPI {
    /**
     *
     * @param {APLSkillRequest} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlexaApi
     */
    handleLocalRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.AlexaApiFp(this.configuration).handleLocalRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {APLSkillRequest} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlexaApi
     */
    handleSignedRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.AlexaApiFp(this.configuration).handleSignedRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
}
exports.AlexaApi = AlexaApi;
/**
 * BixbyApi - fetch parameter creator
 * @export
 */
exports.BixbyApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} actionName
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCustomRequest(actionName, body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            // verify required parameter 'actionName' is not null or undefined
            if (actionName === null || actionName === undefined) {
                throw new RequiredError('actionName', 'Required parameter actionName was null or undefined when calling handleCustomRequest.');
            }
            const localVarPath = `/api/Bixby/HandleRequest/Custom/{actionName}`
                .replace(`{${"actionName"}}`, encodeURIComponent(String(actionName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleDynamicRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Dynamic`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGeneralQuestionRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/GeneralQuestion`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGoToStepRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/GoToStep`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleHelpRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Help`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleLatestMessageRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/LatestMessage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleListItemSelectRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/ListItemSelect`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleMoreRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/More`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNextRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Next`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNoRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/No`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNumberRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Number`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handlePrevisousRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Previous`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRecipeDetailsRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/RecipeDetails`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStartOverRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/StartOver`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStartRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Start`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStopRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Stop`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWelcomeRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Welcome`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleYesRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Bixby/HandleRequest/Yes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BixbyApi - functional programming interface
 * @export
 */
exports.BixbyApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} actionName
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCustomRequest(actionName, body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleCustomRequest(actionName, body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleDynamicRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleDynamicRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGeneralQuestionRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleGeneralQuestionRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGoToStepRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleGoToStepRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleHelpRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleHelpRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleLatestMessageRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleLatestMessageRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleListItemSelectRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleListItemSelectRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleMoreRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleMoreRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNextRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleNextRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNoRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleNoRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNumberRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleNumberRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handlePrevisousRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handlePrevisousRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRecipeDetailsRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleRecipeDetailsRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStartOverRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleStartOverRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStartRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleStartRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStopRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleStopRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWelcomeRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleWelcomeRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleYesRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BixbyApiFetchParamCreator(configuration).handleYesRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * BixbyApi - factory interface
 * @export
 */
exports.BixbyApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {string} actionName
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCustomRequest(actionName, body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleCustomRequest(actionName, body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleDynamicRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleDynamicRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGeneralQuestionRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleGeneralQuestionRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGoToStepRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleGoToStepRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleHelpRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleHelpRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleLatestMessageRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleLatestMessageRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleListItemSelectRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleListItemSelectRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleMoreRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleMoreRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNextRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleNextRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNoRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleNoRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleNumberRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleNumberRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handlePrevisousRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handlePrevisousRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRecipeDetailsRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleRecipeDetailsRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStartOverRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleStartOverRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStartRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleStartRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStopRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleStopRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWelcomeRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleWelcomeRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleYesRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BixbyApiFp(configuration).handleYesRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
    };
};
/**
 * BixbyApi - object-oriented interface
 * @export
 * @class BixbyApi
 * @extends {BaseAPI}
 */
class BixbyApi extends BaseAPI {
    /**
     *
     * @param {string} actionName
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleCustomRequest(actionName, body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleCustomRequest(actionName, body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleDynamicRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleDynamicRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleGeneralQuestionRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleGeneralQuestionRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleGoToStepRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleGoToStepRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleHelpRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleHelpRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleLatestMessageRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleLatestMessageRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleListItemSelectRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleListItemSelectRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleMoreRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleMoreRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleNextRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleNextRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleNoRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleNoRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleNumberRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleNumberRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handlePrevisousRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handlePrevisousRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleRecipeDetailsRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleRecipeDetailsRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleStartOverRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleStartOverRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleStartRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleStartRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleStopRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleStopRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleWelcomeRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleWelcomeRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BixbyApi
     */
    handleYesRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BixbyApiFp(this.configuration).handleYesRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
}
exports.BixbyApi = BixbyApi;
/**
 * BotServiceApi - fetch parameter creator
 * @export
 */
exports.BotServiceApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleActivityRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/BotService/HandleActivityRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("{ [key: string]: any; }" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [nluTool]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(applicationId, applicationSecret, nluTool, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/BotService/HandleRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (nluTool !== undefined) {
                localVarQueryParameter['nluTool'] = nluTool;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BotServiceApi - functional programming interface
 * @export
 */
exports.BotServiceApiFp = function (configuration) {
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleActivityRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BotServiceApiFetchParamCreator(configuration).handleActivityRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [nluTool]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(applicationId, applicationSecret, nluTool, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.BotServiceApiFetchParamCreator(configuration).handleRequest(applicationId, applicationSecret, nluTool, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * BotServiceApi - factory interface
 * @export
 */
exports.BotServiceApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {{ [key: string]: any; }} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleActivityRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.BotServiceApiFp(configuration).handleActivityRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [nluTool]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(applicationId, applicationSecret, nluTool, environmentId, noTracking, useDraftContent, options) {
            return exports.BotServiceApiFp(configuration).handleRequest(applicationId, applicationSecret, nluTool, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
    };
};
/**
 * BotServiceApi - object-oriented interface
 * @export
 * @class BotServiceApi
 * @extends {BaseAPI}
 */
class BotServiceApi extends BaseAPI {
    /**
     *
     * @param {{ [key: string]: any; }} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotServiceApi
     */
    handleActivityRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.BotServiceApiFp(this.configuration).handleActivityRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [nluTool]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotServiceApi
     */
    handleRequest(applicationId, applicationSecret, nluTool, environmentId, noTracking, useDraftContent, options) {
        return exports.BotServiceApiFp(this.configuration).handleRequest(applicationId, applicationSecret, nluTool, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
}
exports.BotServiceApi = BotServiceApi;
/**
 * CustomAssistantApi - fetch parameter creator
 * @export
 */
exports.CustomAssistantApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CustomAssistantRequestBody} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/CustomAssistant/HandleRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("CustomAssistantRequestBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CustomAssistantApi - functional programming interface
 * @export
 */
exports.CustomAssistantApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CustomAssistantRequestBody} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.CustomAssistantApiFetchParamCreator(configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * CustomAssistantApi - factory interface
 * @export
 */
exports.CustomAssistantApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {CustomAssistantRequestBody} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.CustomAssistantApiFp(configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
    };
};
/**
 * CustomAssistantApi - object-oriented interface
 * @export
 * @class CustomAssistantApi
 * @extends {BaseAPI}
 */
class CustomAssistantApi extends BaseAPI {
    /**
     *
     * @param {CustomAssistantRequestBody} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomAssistantApi
     */
    handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.CustomAssistantApiFp(this.configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
}
exports.CustomAssistantApi = CustomAssistantApi;
/**
 * DialogflowApi - fetch parameter creator
 * @export
 */
exports.DialogflowApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ActionRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/Dialogflow/HandleRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("ActionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DialogflowApi - functional programming interface
 * @export
 */
exports.DialogflowApiFp = function (configuration) {
    return {
        /**
         *
         * @param {ActionRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.DialogflowApiFetchParamCreator(configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * DialogflowApi - factory interface
 * @export
 */
exports.DialogflowApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {ActionRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.DialogflowApiFp(configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
    };
};
/**
 * DialogflowApi - object-oriented interface
 * @export
 * @class DialogflowApi
 * @extends {BaseAPI}
 */
class DialogflowApi extends BaseAPI {
    /**
     *
     * @param {ActionRequest} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DialogflowApi
     */
    handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.DialogflowApiFp(this.configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
}
exports.DialogflowApi = DialogflowApi;
/**
 * GoogleAssistantApi - fetch parameter creator
 * @export
 */
exports.GoogleAssistantApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {GoogleAssistantRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options = {}) {
            const localVarPath = `/api/GoogleAssistant/HandleRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            if (environmentId !== undefined) {
                localVarQueryParameter['environmentId'] = environmentId;
            }
            if (noTracking !== undefined) {
                localVarQueryParameter['noTracking'] = noTracking;
            }
            if (useDraftContent !== undefined) {
                localVarQueryParameter['useDraftContent'] = useDraftContent;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("GoogleAssistantRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GoogleAssistantApi - functional programming interface
 * @export
 */
exports.GoogleAssistantApiFp = function (configuration) {
    return {
        /**
         *
         * @param {GoogleAssistantRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            const localVarFetchArgs = exports.GoogleAssistantApiFetchParamCreator(configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * GoogleAssistantApi - factory interface
 * @export
 */
exports.GoogleAssistantApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {GoogleAssistantRequest} [body]
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {string} [environmentId]
         * @param {boolean} [noTracking]
         * @param {boolean} [useDraftContent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
            return exports.GoogleAssistantApiFp(configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(fetch, basePath);
        },
    };
};
/**
 * GoogleAssistantApi - object-oriented interface
 * @export
 * @class GoogleAssistantApi
 * @extends {BaseAPI}
 */
class GoogleAssistantApi extends BaseAPI {
    /**
     *
     * @param {GoogleAssistantRequest} [body]
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {string} [environmentId]
     * @param {boolean} [noTracking]
     * @param {boolean} [useDraftContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleAssistantApi
     */
    handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options) {
        return exports.GoogleAssistantApiFp(this.configuration).handleRequest(body, applicationId, applicationSecret, environmentId, noTracking, useDraftContent, options)(this.fetch, this.basePath);
    }
}
exports.GoogleAssistantApi = GoogleAssistantApi;
/**
 * HealthCheckApi - fetch parameter creator
 * @export
 */
exports.HealthCheckApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/api/HealthCheck`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * HealthCheckApi - functional programming interface
 * @export
 */
exports.HealthCheckApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarFetchArgs = exports.HealthCheckApiFetchParamCreator(configuration).healthCheck(options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * HealthCheckApi - factory interface
 * @export
 */
exports.HealthCheckApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return exports.HealthCheckApiFp(configuration).healthCheck(options)(fetch, basePath);
        },
    };
};
/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
class HealthCheckApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    healthCheck(options) {
        return exports.HealthCheckApiFp(this.configuration).healthCheck(options)(this.fetch, this.basePath);
    }
}
exports.HealthCheckApi = HealthCheckApi;
/**
 * UserProfileApi - fetch parameter creator
 * @export
 */
exports.UserProfileApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} sessionId
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionAttributes(sessionId, applicationId, applicationSecret, options = {}) {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId', 'Required parameter sessionId was null or undefined when calling getSessionAttributes.');
            }
            const localVarPath = `/api/UserProfile/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} userId
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(userId, applicationId, applicationSecret, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserProfile.');
            }
            const localVarPath = `/api/UserProfile/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            // authentication X-Api-Key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("X-Api-Key")
                    : configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }
            if (applicationSecret !== undefined) {
                localVarQueryParameter['applicationSecret'] = applicationSecret;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserProfileApi - functional programming interface
 * @export
 */
exports.UserProfileApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} sessionId
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionAttributes(sessionId, applicationId, applicationSecret, options) {
            const localVarFetchArgs = exports.UserProfileApiFetchParamCreator(configuration).getSessionAttributes(sessionId, applicationId, applicationSecret, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} userId
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(userId, applicationId, applicationSecret, options) {
            const localVarFetchArgs = exports.UserProfileApiFetchParamCreator(configuration).getUserProfile(userId, applicationId, applicationSecret, options);
            return (fetch = portableFetch, basePath = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
/**
 * UserProfileApi - factory interface
 * @export
 */
exports.UserProfileApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @param {string} sessionId
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionAttributes(sessionId, applicationId, applicationSecret, options) {
            return exports.UserProfileApiFp(configuration).getSessionAttributes(sessionId, applicationId, applicationSecret, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} userId
         * @param {string} [applicationId]
         * @param {string} [applicationSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(userId, applicationId, applicationSecret, options) {
            return exports.UserProfileApiFp(configuration).getUserProfile(userId, applicationId, applicationSecret, options)(fetch, basePath);
        },
    };
};
/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
class UserProfileApi extends BaseAPI {
    /**
     *
     * @param {string} sessionId
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    getSessionAttributes(sessionId, applicationId, applicationSecret, options) {
        return exports.UserProfileApiFp(this.configuration).getSessionAttributes(sessionId, applicationId, applicationSecret, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @param {string} userId
     * @param {string} [applicationId]
     * @param {string} [applicationSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    getUserProfile(userId, applicationId, applicationSecret, options) {
        return exports.UserProfileApiFp(this.configuration).getUserProfile(userId, applicationId, applicationSecret, options)(this.fetch, this.basePath);
    }
}
exports.UserProfileApi = UserProfileApi;
