import React from 'react';
import HubspotForm from 'react-hubspot-form'
import { css } from '@emotion/css';
import Loader from '../../components/general/Loader';
import { color_shades_dark, color_text_default } from '../../constants/colors';

const PlatformHelpMenu = () => (
    <div className={formContainer}>
        <div className="form">
            <h4>Contact Us</h4>
            <HubspotForm portalId="4829283" formId="396bff7c-4366-48d4-9e3e-f981364f07e4" loading={<Loader />} />
        </div>
    </div>

)
const formContainer = css`
    width: 100%;
    position: relative;
    padding-top: 32px;
    padding-bottom: 128px;
    .form {
        margin: 32px;
        margin-top: 0px;
        padding: 32px;
        background: white;
        border-radius: 8px;
        position: relative;
        background: white;
        border: 1px solid ${color_shades_dark};
        h4 {
            color: ${color_text_default};
            margin-bottom: 24px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
    }
`;

export default PlatformHelpMenu;