import React from 'react';
import { withFormik, FormikProps } from "formik";
import * as yup from 'yup';
import PageContainer from '../../../components/structure/PageContainer';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import FooterBar from '../../../components/structure/FooterBar';
import Button from '../../../components/general/Button';
import SelectField from '../../../components/forms/SelectField';
import Loader from '../../../components/general/Loader';
import MemberContainer from '../../../state/containers/MemberContainer';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import CheckboxField from '../../../components/forms/CheckboxField';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import styled from '@emotion/styled';
import { breakpoint_small } from '../../../constants/breakpoints';

interface OrganizationMemberInviteFormProps {
    memberContainer: MemberContainer
    organizationContainer: OrganizationContainer
    onFinish?: () => void
    onClose?: () => void
    onChange?: () => void
}

interface OrganizationMemberInviteFormData {
    email?: string
    isAdmin?: boolean
    isAddedToApplications?: boolean
    applicationRoleId?: string
}

class InnerForm extends React.Component<OrganizationMemberInviteFormProps & FormikProps<OrganizationMemberInviteFormData>> {
    componentWillMount() {
        if(this.props.organizationContainer.state.systemRoles.length == 0) {
            this.props.organizationContainer.getSystemRoles();
        }
    }
    handleAdminToggle(e: React.ChangeEvent<any>) {
        this.props.setFieldValue('isAdmin', e.target.checked);
    }
    handleAddToAppsToggle(e: React.ChangeEvent<any>) {
        this.props.setFieldValue('isAddedToApplications', e.target.checked);
    }
    handleRoleChange(value) {
        const finalValue = value === null ? null : value.value;
        this.props.setFieldValue("applicationRoleId", finalValue);
        this.props.onChange();
    }
    handleRoleBlur(blur) {
        this.props.setFieldTouched("applicationRoleId", blur);
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.memberContainer.state.isLoading;

        return (
            <PageContainer fill>
                <form onSubmit={props.handleSubmit}>
                    <PageContainer>
                        <FormFieldsContainer>
                            <TextField name="email" type="email" className="ac-invite-email" required value={props.values.email} label="Email Address" placeholder="Where do you want to send the invitation?" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                            <CheckboxField name="isAdmin" className="ac-invite-admin" checked={props.values.isAdmin} label="Set as Administrator" onChange={this.handleAdminToggle.bind(this)} disabled={isLoading}/>
                        </FormFieldsContainer>
                        <HorizontalSeparator />
                        <FormFieldsContainer>
                            <CheckboxField name="isAddedToApplications" className="ac-invite-apps" checked={props.values.isAddedToApplications} label="Add as participant to all Apps" onChange={this.handleAddToAppsToggle.bind(this)} disabled={isLoading}/>
                            {this.props.organizationContainer.state.isLoadingRoles ? <Loader/> :
                            <SelectField value={props.values.applicationRoleId} 
                            name="applicationRoleId"
                            onChange={this.handleRoleChange.bind(this)} 
                            onBlur={this.handleRoleBlur.bind(this)} 
                            hidden={!props.values.isAddedToApplications}
                            options={
                                this.props.organizationContainer.state.systemRoles.map(r => ({
                                    label: r.name,
                                    value: r.id
                                }))
                            } 
                            label="Role:"
                            disabled={isLoading}/>
                        }
                        </FormFieldsContainer>
                    </PageContainer>
                    <FooterBar>
                        <StyledButton loading={isLoading} disabled={isLoading} className="ac-invite-send" themes={["primary", "wide"]} text="Send Invite" type="submit" />
                        <StyledButton disabled={isLoading} themes={["white", "end"]} text="Cancel" type="button" onClick={this.props.onClose} />
                    </FooterBar>
                </form>
            </PageContainer>
        )
    }
}

const OrganizationMemberInviteForm = withFormik<OrganizationMemberInviteFormProps, OrganizationMemberInviteFormData>({
    mapPropsToValues: props => ({
        email: '',
        isAdmin: false,
        isAddedToApplications: false,
        applicationRoleId: props.organizationContainer.state.systemRoles.length == 0 ? '' : props.organizationContainer.state.systemRoles[0].id
    }),
    validationSchema: yup.object().shape({
        email: yup.string().email("You must enter a valid email").required("You must enter an email to send the invitation to")
    }),
    handleSubmit: (values, { props }) => {
        props.memberContainer.inviteMember(props.organizationContainer.state.currentOrganization.id, {
            email: values.email,
            isAdmin: values.isAdmin,
            isAddedToApplications: values.isAddedToApplications,
            applicationRoleId: values.applicationRoleId
        }).then(result => {
            if(result.resultType == "Ok") {
                props.onFinish();
            }
        })

    }
})(InnerForm);

const StyledButton = styled(Button)`
    ${breakpoint_small} {
        width: 100px;
        span {
            font-size: 12px;
        }
    }
`;

export default OrganizationMemberInviteForm;
