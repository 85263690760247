import ApplicationUsageModel from "../../models/analytics/api/ApplicationUsageModel";
import IResult from "../../models/result/IResult";
import { sendGet } from "./generic";
import { getAnalyticsApiUrl } from "../../constants/Urls";
import DatedUsageModel from "../../models/analytics/api/DatedUsageModel";
import AnalyticsInterval from "../../models/analytics/AnalyticsInterval";
import StatisticsModel from "../../models/analytics/api/StatisticsModel";
import ApplicationMissStatisticsModel from "../../models/analytics/api/ApplicationMissStatisticsModel";
import TopContentHitModel from "../../models/analytics/api/TopContentHitModel";
import TopContentMissModel from "../../models/analytics/api/TopContentMissModel";
import AssistantUsageModel from "../../models/analytics/api/AssistantUsageModel";
import FeatureUsageModel from "../../models/analytics/api/FeatureUsageModel";
import DeviceTargetUsageModel from "../../models/analytics/api/DeviceTargetUsageModel";
import InterfaceUsageModel from "../../models/analytics/api/InterfaceUsageModel";
import LanguageUsageModel from "../../models/analytics/api/LanguageUsageModel";
import AnalyticsRequestFilter from "../../models/analytics/AnalyticsRequestFilter";
import ContentHitEvent from "../../models/analytics/api/ContentHitEvent";
import SessionDateModel from "../../models/analytics/api/SessionDateModel";
import AggregatedFeatureTypeInteractions from "../../models/analytics/api/AggregatedFeatureTypeInteractions";
import ItemAddedToOrderEvent from "../../models/analytics/ItemAddedToOrderEvent";
import ConversationalSuccessEvent from "../../models/analytics/ConversationalSuccessEvent";
import OrderPlacedEvent from "../../models/analytics/OrderPlacedEvent";
import { OutboundCallAnsweredEvent } from "../../models/analytics/OutboundCallAnsweredEvent";

// OLD:
export const getApplicationStats = (applicationId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<StatisticsModel>(`/v2/Application/${applicationId}/totals/usage?${buildQuery(filter)}`, getAnalyticsApiUrl());
}
export const getApplicationMissStats = (applicationId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<ApplicationMissStatisticsModel>(`/v2/Application/${applicationId}/totals/misses?${buildQuery(filter)}`, getAnalyticsApiUrl());
}

export const getApplicationTopContentHits = (applicationId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<TopContentHitModel>(`/v2/Application/${applicationId}/totals/top/hits?${buildQuery(filter)}`, getAnalyticsApiUrl());
}
export const getApplicationTopContentMisses = (applicationId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<TopContentMissModel>(`/v2/Application/${applicationId}/totals/top/misses?${buildQuery(filter)}`, getAnalyticsApiUrl());
}

export const getApplicationUniqueAssistants = (applicationId: string, filter: AnalyticsRequestFilter) => {
    return sendGet<string[]>(`/v2/Application/${applicationId}/unique/assistants?${buildQuery(filter)}`, getAnalyticsApiUrl());
}

// NEW:
export const getContentHits = (applicationId: string, filter: AnalyticsRequestFilter, skip: number = 0, take: number = 0, latest: boolean = false, sessionId: string = "", featureTypeId: string = "") => {
    let url = `/v2/Application/${applicationId}/events/contentHit?${buildQuery(filter)}&skip=${skip}&take=${take}&latest=${latest}`;
    if (sessionId?.length) {
        url = `${url}&sessionIds=${sessionId}`
    }
    if (featureTypeId?.length) {
        url = `${url}&featureTypeId=${featureTypeId}`
    }
    return sendGet<ContentHitEvent[]>(url, getAnalyticsApiUrl());
}

// aggregations
export const getAggregatedInteractionsByFeatureType = (applicationId: string, filter: AnalyticsRequestFilter, featureTypeId = null) => {
    let url = `/v2/Application/${applicationId}/interactions/aggregation/featureType?${buildQuery(filter)}`;
    if (featureTypeId?.length) {
        url = `${url}&featureTypeId=${featureTypeId}`
    }
    return sendGet<AggregatedFeatureTypeInteractions[]>(url, getAnalyticsApiUrl());
}

// users
export const getUserAggregateByAssistant = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, 'users', 'assistant', filter);
export const getUserAggregateByFeatureType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<FeatureUsageModel[]>(applicationId, 'users', 'featureType', filter);
export const getUserAggregateByDeviceTarget = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, 'users', 'deviceTarget', filter);
export const getUserAggregateByInterfaceType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, 'users', 'interfaceType', filter);
export const getUserAggregateByLanguage = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, 'users', 'locale', filter);

// new users
export const getNewUserAggregateByAssistant = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, 'newUsers', 'assistant', filter);
export const getNewUserAggregateByFeatureType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<FeatureUsageModel[]>(applicationId, 'newUsers', 'featureType', filter);
export const getNewUserAggregateByDeviceTarget = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, 'newUsers', 'deviceTarget', filter);
export const getNewUserAggregateByInterfaceType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, 'newUsers', 'interfaceType', filter);
export const getNewUserAggregateByLanguage = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, 'newUsers', 'locale', filter);


// interactions
export const getInteractionAggregateByAssistant = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, 'interactions', 'assistant', filter);
export const getInteractionAggregateByFeatureType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<FeatureUsageModel[]>(applicationId, 'interactions', 'featureType', filter);
export const getInteractionAggregateByDeviceTarget = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, 'interactions', 'deviceTarget', filter);
export const getInteractionAggregateByInterfaceType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, 'interactions', 'interfaceType', filter);
export const getInteractionAggregateByLanguage = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, 'interactions', 'locale', filter);

// sessions
export const getSessionAggregateByAssistant = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, 'sessions', 'assistant', filter);
export const getSessionAggregateByFeatureType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<FeatureUsageModel[]>(applicationId, 'sessions', 'featureType', filter);
export const getSessionAggregateByDeviceTarget = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, 'sessions', 'deviceTarget', filter);
export const getSessionAggregateByInterfaceType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, 'sessions', 'interfaceType', filter);
export const getSessionAggregateByLanguage = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, 'sessions', 'locale', filter);

// feature misses

export const getFeatureMissAggregateByAssistant = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, 'featureMisses', 'assistant', filter);
export const getFeatureMissAggregateByFeatureType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<FeatureUsageModel[]>(applicationId, 'featureMisses', 'featureType', filter);
export const getFeatureMissAggregateByDeviceTarget = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, 'featureMisses', 'deviceTarget', filter);
export const getFeatureMissAggregateByInterfaceType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, 'featureMisses', 'interfaceType', filter);
export const getFeatureMissAggregateByLanguage = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, 'featureMisses', 'locale', filter);

// content misses

export const getContentMissAggregateByAssistant = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<AssistantUsageModel[]>(applicationId, 'contentMisses', 'assistant', filter);
export const getContentMissAggregateByFeatureType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<FeatureUsageModel[]>(applicationId, 'contentMisses', 'featureType', filter);
export const getContentMissAggregateByDeviceTarget = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<DeviceTargetUsageModel[]>(applicationId, 'contentMisses', 'deviceTarget', filter);
export const getContentMissAggregateByInterfaceType = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<InterfaceUsageModel[]>(applicationId, 'contentMisses', 'interfaceType', filter);
export const getContentMissAggregateByLanguage = (applicationId: string, filter: AnalyticsRequestFilter) =>
    aggregateRequest<LanguageUsageModel[]>(applicationId, 'contentMisses', 'locale', filter);

// sessions
export const getSessionsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<SessionDateModel[]>> => {
    return sendGet<SessionDateModel[]>(`/v2/Application/${applicationId}/sessions?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}


export const getAllEventsForSession = (applicationId: string, sessionId: string): Promise<IResult<any[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/session/${sessionId}`, getAnalyticsApiUrl());

export const getCallStartedEventsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<any[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/callStarted?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
export const getOrderPlacedEventsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<OrderPlacedEvent[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/orderPlaced?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
export const getItemAddedToOrderEventsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ItemAddedToOrderEvent[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/itemAddedToOrder?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
export const getCallTransferredEventsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<any[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/callTransferred?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
export const getImmediateTransferEventsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<any[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/immediateTransfer?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
export const getConversationalSuccessEventsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ConversationalSuccessEvent[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/conversationalSuccess?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
export const getOutboundCallAnsweredEventsByApplication = (applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<OutboundCallAnsweredEvent[]>> =>
    sendGet<any[]>(`/v2/Application/${applicationId}/outboundCallAnswered?${buildDateFilter(filter)}`, getAnalyticsApiUrl());

// abstract for halding api reqs for aggregated data
const aggregateRequest = <T = any>(applicationId: string, type: string, aggkey: string, filter: AnalyticsRequestFilter): Promise<IResult<T>> =>
    sendGet<T>(`/v2/Application/${applicationId}/${type}/aggregation/${aggkey}?${buildQuery(filter)}`, getAnalyticsApiUrl());

// histograms
export const getUserHistogramForApplication = (applicationId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, 'users', interval, filter);
export const getNewUserHistogramForApplication = (applicationId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, 'newusers', interval, filter);
export const getUsageHistogramForApplication = (applicationId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, 'interactions', interval, filter);
export const getSessionHistogramForApplication = (applicationId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, 'sessions', interval, filter);
export const getFeatureMissHistogramForApplication = (applicationId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, 'featuremisses', interval, filter);
export const getContentMissHistogramForApplication = (applicationId: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) =>
    histogramRequest(applicationId, 'contentmisses', interval, filter);


// abstract for handling api reqs for histogram data
const histogramRequest = (applicationId: string, type: string, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) => {
    const intervalString = getApiInterval(interval)
    return sendGet<DatedUsageModel[]>(`/v2/Application/${applicationId}/${type}/histogram?interval=${intervalString}&${buildQuery(filter)}`, getAnalyticsApiUrl());
}

const buildQuery = (filter: AnalyticsRequestFilter) => {
    var startDate = new Date(filter.startDate).toISOString();
    var endDate = new Date(filter.endDate).toISOString();
    let query = `startDate=${startDate}&endDate=${endDate}`;
    if (filter.assistants?.length > 0) {
        query += ("&assistants=" + filter.assistants.join("&assistants="))
    }
    if (filter.userIds?.length > 0) {
        query += ("&userIds=" + filter.userIds.join("&userIds="))
    }
    if (filter.sessionIds?.length > 0) {
        query += ("&sessionIds=" + filter.sessionIds.join("&sessionIds="))
    }
    if (filter.deviceTargetIds?.length > 0) {
        query += ("&deviceTargetIds=" + filter.deviceTargetIds.join("&deviceTargetIds="))
    }
    if (filter.locales?.length > 0) {
        query += ("&locales=" + filter.locales.join("&locales="))
    }
    return query;
}

const buildDateFilter = (filter: AnalyticsRequestFilter) => {
    const startDate = new Date(filter.startDate).toISOString();
    const endDate = new Date(filter.endDate).toISOString();
    let query = `startDate=${startDate}&endDate=${endDate}`;
    return query;
}

const getApiInterval = (interval: AnalyticsInterval) => {
    let intervalString = interval.charAt(0).toUpperCase() + interval.substr(1);
    if (intervalString == "DayAndHour") {
        intervalString = "Hour";
    }
    return intervalString
}