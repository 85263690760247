import IResult, { ResultType } from "./IResult";

export default class NotFoundResult<T> implements IResult<T> {
    data: T = null;
    resultType: ResultType = "NotFound";
    errors: string[];

    constructor (error: string = "Unable to find the given resource.") {
        this.errors = [error];
    }
}