import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";
import LuisApplicationInformation from "../../models/deployments/api/LuisApplicationInformation";
import MicrosoftDeploymentLink from "../../models/deployments/api/MicrosoftDeploymentLink";
import NewLuisAppResponse from "../../models/deployments/api/NewLuisAppResponse";
import { PLATFORM_AZURE } from "../../constants/Urls";

const defaultLuisRegion = 'westus';

export const listLuisAppsForEnvironment = (applicationId: string, environmentId: string) : Promise<IResult<LuisApplicationInformation[]>> => {
    return sendGet(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/luisApps`);
}

export const getEnvironmentDeploymentInfoForMicrosoft = (applicationId: string, environmentId: string): Promise<IResult<MicrosoftDeploymentLink>> => {
    return sendGet(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/link`)
}

export const linkLuisPredictionResourcesToEnvironment = (applicationId: string, environmentId: string, luisKey: string, luisRegion?: string, luisEndpoint?: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/luisPredictionResources`, {
        luisPredictionKey: luisKey,
        luisPredictionRegion: luisRegion,
        luisPredictionEndpoint: luisEndpoint
    });
}

export const linkLuisKeyToEnvironment = (applicationId: string, environmentId: string, luisKey: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/luisKey/${luisKey}`, {});
}

export const createAndDeployEnvironmentNewLuisApp = (applicationId: string, environmentId: string, luisRegion: string = defaultLuisRegion): Promise<IResult<NewLuisAppResponse>> => {
    return sendPost(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/createAndDeploy?luisRegion=${luisRegion}`, {});
}

export const linkLuisAppToEnvironment = (applicationId: string, environmentId: string, luisAppId: string, luisRegion: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/${luisAppId}?luisRegion=${luisRegion}`, {});
}

export const unlinkBotServiceFromEnvironment = (applicationId: string, environmentId: string): Promise<IResult<boolean>> => {
    return sendDelete(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/microsoftProject`);
}

export const unlinkLuisFromEnvironment= (applicationId: string, environmentId: string): Promise<IResult<boolean>> => {
    return sendDelete(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/luisApp`)
}

export const linkBotServiceToEnvironment = (applicationId: string, environmentId: string, botServiceName: string, microsoftAppId: string, microsoftAppPassword: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_AZURE}/${applicationId}/environment/${environmentId}/microsoftProject`, {
        microsoftAppId,
        microsoftAppPassword,
        botServiceName
    });
}
