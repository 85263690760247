import IResult from "../../models/result/IResult";
import { sendGet, sendPost } from "./generic";
import Term from "../../models/term/Term";
import UserTerm from "../../models/term/UserTerm";
import UserTermsAgreement from "../../models/term/UserTermsAgreement";
import TermAgreementModel from "../../models/term/TermAgreementModel";

export const getLatestTerm = (): Promise<IResult<Term>> => {
    return sendGet(`/Term/latest`);
}

export const createUserTerm = (model: TermAgreementModel): Promise<IResult<UserTerm>> => {
    return sendPost(`/Term/agreement`, model);
}
