import React, { useEffect, useState } from 'react';
import OrderingAnalyticsContainer from '../../../hooks/OrderingAnalyticsContainer';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import styled from '@emotion/styled';
import TotalCard from '../../../components/charts/TotalCard';
import Loader from '../../../components/general/Loader';
import PageError from '../../../components/general/PageError';
import ModalLayout from '../../../components/structure/ModalLayout';
import ModalHeader from '../../../components/general/ModalHeader';
import SessionReview from './SessionReview';
import moment from 'moment';
import { SESSION_DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import useWindowSize from '../../../hooks/UseWindowSize';
import CodeOutputModal from '../../simulator/components/CodeOutputModal';
import Button from '../../../components/general/Button';
import { Link } from 'react-router-dom';
import FooterBar from '../../../components/structure/FooterBar';
import { color_colors_ocean, color_shades_dark, color_shades_light } from '../../../constants/colors';
import CollapsingPanel from '../../../components/structure/Panels/CollapsingPanel';
import { getBaseUrl } from '../../../constants/Urls';
import { css } from '@emotion/css';

const conversationalSuccessIcon = require("../../../content/images/analytics-icons/conversational-success.svg");
const orderPlacedIcon = require("../../../content/images/analytics-icons/order-placed.svg");

interface ApplicationOrderingWrapperProps {
    filter: AnalyticsRequestFilter
    applicationId: string,
}

const ApplicationOrderingAnalyticsReport = (props: ApplicationOrderingWrapperProps) => {
    const [eventsToReview, setEventsToReview] = useState([]);
    const [sessionIdToReview, setSessionIdToReview] = useState("");
    const [eventsToReviewInCode, setEventsToReviewInCode] = useState([]);
    const [expandFailedOrders, setExpandFailedOrders] = useState(true);
    const [expandNoOrders, setExpandNoOrders] = useState(false);
    const [expandPlacedOrders, setExpandPlacedOrder] = useState(false);
    const [expandOrderTransfers, setExpandOrderTransfers] = useState(false);
    const [copyButtonText, setCopyButtonText] = useState("Copy Session");

    const orderingContainer = OrderingAnalyticsContainer.useContainer();

    const windowSize = useWindowSize();
    useEffect(() => {
        orderingContainer.loadOrderingAnalytics(props.applicationId, props.filter);
    }, [props.filter, props.applicationId]);

    const convertEventsForCodeOutputModal = (events: any[]) => {
        var returnObj = {};
        events?.forEach((e, idx) => {
            returnObj[`${idx}-${e.eventType}`] = e;
        })

        // get last event that is request received
        const lastContentHit = events?.slice()?.reverse()?.find(e => e.eventType === "ContentHit");
        if (lastContentHit?.originalRequest?.sessionAttributes) {
            returnObj[`${events.length}-LatestSessionAttributes`] = lastContentHit.originalRequest.sessionAttributes;
        }

        return returnObj;
    }

    useEffect(() => {
        let load = false;
        if (sessionIdToReview?.length) {
            if (eventsToReview?.length) {
                if (eventsToReview.some(e => e?.sessionId === sessionIdToReview)) {
                    load = false;
                } else {
                    load = true;
                }
            } else {
                load = true;
            }
        }
        if (load) {
            if (sessionIdToReview in orderingContainer.allEventsCache) {
                setEventsToReview(orderingContainer.allEventsCache[sessionIdToReview]);
            } else {
                orderingContainer.loadAllEventsForSession(sessionIdToReview, props.applicationId);
            }
        }
    }, [sessionIdToReview, eventsToReview, orderingContainer.allEventsCache])

    const CodeReviewModal = () => {
        return (
            <CodeOutputModal
                title="JSON"
                closeModal={() => setEventsToReviewInCode(null)}
                data={convertEventsForCodeOutputModal(eventsToReviewInCode)}
                showCodeOutputModal={eventsToReviewInCode?.length > 0}
            />);
    }

    const clearSessionToReview = () => {
        setEventsToReview([]);
        setSessionIdToReview("");
    }

    const formatPercentage = (value: number) => {
        if (value < 0) {
            return "No orders";
        }
        return `${(value * 100).toFixed(2)} %`
    }

    const handleCopyButtonClick = (url: string) => {
        navigator.clipboard.writeText(url);
        setCopyButtonText("Copied!");
    }

    const resetCopyButtonText = () => {
        setCopyButtonText("Copy Session");
    }

    const getStartandEndDate = (sessionId: string) => {
        const session = orderingContainer.sessions?.find(s => s.sessionId === sessionId);
        if (session) {
            const weekBeforeStartDate = new Date(new Date(session?.startDate).getTime() - (7 * 86400000)).toISOString().split("T")[0];
            const dayAfterStartDate = new Date(new Date(session?.startDate).getTime() + 86400000).toISOString().split("T")[0];
            return `&startDate=${weekBeforeStartDate}&endDate=${dayAfterStartDate}`;
        }
    }

    const CustomSessionReview = () => {
        if (!eventsToReview?.length || eventsToReviewInCode?.length)
            return <></>;
        const e = (eventsToReview?.length && eventsToReview[0]) ? eventsToReview[0] : null;
        return (
            <div onClick={(e) => { e.stopPropagation() }}>
                <ModalLayout isVisible>
                    <ModalHeader title={moment(e?.eventDate).format(SESSION_DATE_FORMAT)} onClose={() => { clearSessionToReview(); resetCopyButtonText() }} />
                    <SessionReview
                        inModal
                        manualHeight={(windowSize.windowSize.innerHeight * .9) - 168}
                        events={eventsToReview ?? []}
                        applicationId={props.applicationId}
                        setEventsToShow={setEventsToReviewInCode}
                        resetCopyButtonText={resetCopyButtonText}
                        sessionId={e?.sessionId}
                    />
                    <FooterBar>
                        <Link
                            to={`/v/apps/${e?.applicationId}/analytics/sessions?sessionId=${e.sessionId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                                e.stopPropagation();
                                resetCopyButtonText();
                            }}
                        >
                            <Button
                                disabled={false}
                                themes={['secondary']}
                                text="Go To Session"
                                onClick={null}
                                type="button" />
                        </Link>
                        <div>
                            <Button
                                disabled={false}
                                themes={['secondary']}
                                text={copyButtonText}
                                onClick={() => handleCopyButtonClick(`${getBaseUrl()}/v/apps/${e?.applicationId}/analytics/sessions?sessionId=${e.sessionId}${getStartandEndDate(e.sessionId)}`)}
                                type="button" />
                        </div>
                        <FooterButton
                            type="button"
                            themes={["secondary"]}
                            text="Close"
                            onClick={(e) => {
                                clearSessionToReview();
                                e.stopPropagation();
                                resetCopyButtonText();
                            }}
                        />
                    </FooterBar>
                </ModalLayout>
            </div>
        )
    }

    const renderCollapsableSessionReview = (idsToReview: string[], title: string, expandBool: boolean, setBoolFunc: (b: boolean) => void) => {
        return (<CollapsingPanel
            collapsedView={<ClickableHeader onClick={() => setBoolFunc(!expandBool)}>{`${title} (${idsToReview.length})`}</ClickableHeader>}
            isCollapsed={!expandBool}
            expandedView={<>
                <ClickableHeader onClick={() => { setBoolFunc(!expandBool) }}>{`${title} (${idsToReview.length})`}</ClickableHeader>
                {idsToReview.map((sessionId, idx) => {
                    return (
                        <SessionHeader
                            className={orderingContainer.loadingSessionId === sessionId ? 'loading' : ""}
                            key={sessionId}
                            onClick={() => {
                                if (!orderingContainer.loadingSessionId.length) {
                                    setSessionIdToReview(sessionId)
                                }
                            }}>
                            <SessionHeaderDate>
                                {moment(orderingContainer?.sessions?.find(s => s.sessionId === sessionId)?.startDate ?? new Date()).format(SESSION_DATE_FORMAT)}
                            </SessionHeaderDate>
                            <SessionLink
                                to={`/v/apps/${props.applicationId}/analytics/sessions?sessionId=${sessionId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                <SessionLinkButton
                                    disabled={false}
                                    themes={['secondary-small']}
                                    text="Full Session"
                                    onClick={null}
                                    type="button" />
                            </SessionLink>
                        </SessionHeader>
                    )
                })}
            </>}
        />);
    };

    if (orderingContainer.loading)
        return <Loader />

    return <>
        <Header>App Summary</Header>
        <SummaryContainer>
            <StyledTotalCard
                title="Conversational Success Rate"
                subtitle="(All Calls)"
                value={formatPercentage(orderingContainer.conversationalSuccessRate)}
                subValue={`${orderingContainer.conversationalSuccess.toString()} ${orderingContainer.conversationalSuccess === 1 ? ' successful call' : ' successful calls'}`}
                tooltip='Calls marked as Conversational Success. This includes AI Answering queries, immediate hangups, and successful transfers.'
                highlight={true}
                className={highlightedTotalCardStyle}
                icon={conversationalSuccessIcon}
            />
            <StyledTotalCard
                title="Conversational Success Rate"
                subtitle="(Attempted Orders)"
                value={formatPercentage(orderingContainer.attemptedOrdersConversationalSuccessRate)}
                subValue={`${orderingContainer.attemptedOrderConversationalSuccess.toString()} ${orderingContainer.attemptedOrderConversationalSuccess === 1 ? ' successful call' : ' successful calls'}`}
                tooltip='Orders Placed plus Unplaced Orders marked as Conversational Success divided by the number of Attempted Orders.'
                highlight={true}
                className={highlightedTotalCardStyle}
                icon={conversationalSuccessIcon}
            />
            <StyledTotalCard
                title="Orders Placed Rate"
                subtitle="(Attempted Orders)"
                value={formatPercentage(orderingContainer.orderPlacedRate)}
                subValue={`${orderingContainer.ordersPlaced.toString()} ${orderingContainer.ordersPlaced === 1 ? ' order placed' : ' orders placed'}`}
                tooltip='Orders Placed divided by Order Attempts.'
                highlight={true}
                className={highlightedTotalCardStyle}
                icon={orderPlacedIcon}
            />
        </SummaryContainer>
        <SummaryContainer>
            <StyledTotalCard
                title="Calls"
                value={orderingContainer.sessions.length}
                tooltip='The total number of tracked sessions against this assistant.'
            />
            <StyledTotalCard title="Order Attempts"
                value={orderingContainer.orderAttempts}
                tooltip='The total number of sessions where any items were added to the order.'
            />
        </SummaryContainer>
        <SummaryContainer>
            <StyledTotalCard
                title="Transfer Rate"
                value={formatPercentage(orderingContainer.transferRate)}
                subValue={`${orderingContainer.transfers.toString()} ${orderingContainer.transfers === 1 ? ' transfer' : ' transfers'}`}
                tooltip='Transfers divided by Sessions.'
            />
            <StyledTotalCard
                title="Immediate Transfer Rate"
                value={formatPercentage(orderingContainer.immediateTransferRate)}
                subValue={`${orderingContainer.immediateTransfers.toString()} ${orderingContainer.immediateTransfers === 1 ? ' immediate transfer' : ' immediate transfers'}`}
                tooltip='Immediate Transfers divided by Sessions.'
            />
            <StyledTotalCard
                title="Order Transfer Rate"
                value={formatPercentage(orderingContainer.orderTransferRate)}
                subValue={`${orderingContainer.orderTransfers.toString()} ${orderingContainer.orderTransfers === 1 ? ' transfer' : ' transfers'}`}
                tooltip='Transfer rate during Order Attempts.'
            />
        </SummaryContainer>
        {orderingContainer.unsuccessfulOrderSessionIds.length === 0 ? <Header>No failed orders</Header> : null}
        {orderingContainer.unsuccessfulOrderSessionIds.length ?
            renderCollapsableSessionReview(orderingContainer.unsuccessfulOrderSessionIds, "Failed order sessions", expandFailedOrders, setExpandFailedOrders)
            :
            null
        }
        {orderingContainer.ordersPlacedSessionIds.length ?
            renderCollapsableSessionReview(orderingContainer.ordersPlacedSessionIds, "Order placed sessions", expandPlacedOrders, setExpandPlacedOrder)
            :
            null
        }
        {orderingContainer.noOrderSessionIds.length ?
            renderCollapsableSessionReview(orderingContainer.noOrderSessionIds, "No order sessions", expandNoOrders, setExpandNoOrders)
            :
            null
        }
        {orderingContainer.orderTransferredSessionIds.length ?
            renderCollapsableSessionReview(orderingContainer.orderTransferredSessionIds, "Order sessions with transfers", expandOrderTransfers, setExpandOrderTransfers)
            :
            null
        }
        <Spacer />
        <PageError errors={orderingContainer.errors} />
        <CodeReviewModal />
        <CustomSessionReview />
    </>

};

const highlightedTotalCardStyle = css`
    border: 1px solid ${color_colors_ocean};
    box-shadow: 0px 4px 8px rgba(30, 126, 185, 0.2);
`;

const StyledTotalCard = styled(TotalCard)`
    padding: 32px;
    height: auto;
    text-align: center;
    margin: 16px 16px 0px 16px;
`;

const Header = styled.h3`
    margin: 16px 32px;
    margin-top: 24px;
    font-size: 18px;
`;

const ClickableHeader = styled(Header)`
    cursor: pointer;
`;

const Spacer = styled.div`
    height: 100px;
`;

const SummaryContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 0 16px;
    padding-bottom: 16px;
`;

const FooterButton = styled(Button)`
    margin-left: auto;
`;

const SessionHeader = styled.h3`
    margin-top: 24px;
    margin-bottom: 16px;
    margin-left: 24px;
    margin-right: 24px;
    padding: 16px 16px;
    border: 1px solid ${color_shades_dark};
    cursor: pointer;
    border-radius: 12px 12px 12px 12px;
    display: flex;
    flex-direction: row;
    position: relative;
    &.loading {
        cursor: default;
        background-color: ${color_shades_light};
    }
`;

const SessionHeaderDate = styled.div`
    margin-top: 6px;
    margin-right: 8px;
    font-weight: lighter;
    min-width: 180px;
`;

const SessionLinkButton = styled(Button)`
    margin: 0;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    &:focus, 
    &:hover, 
    &:visited, 
    &:link, 
    &:active {
        text-decoration: none;
        color: inherit; 
    }
`;

const SessionLink = styled(Link)`
    margin-left: auto;
`;

export default ApplicationOrderingAnalyticsReport;
