import { female1VoiceRecording, female2VoiceRecording, female3VoiceRecording, female4VoiceRecording, male1VoiceRecording, male2VoiceRecording, male3VoiceRecording, male4VoiceRecording } from "./voiceRecordingUrls";

const femaleIcon = require('../../content/images/spark/female.svg');
const maleIcon = require('../../content/images/spark/male.svg');

export const voiceOptions = [
    { value: "female1", label: "Female 1 (Default)", icon: femaleIcon, description: "Young female with American accent", audioSrc: female1VoiceRecording },
    { value: "female2", label: "Female 2", icon: femaleIcon, description: "Middle-aged female with American accent", audioSrc: female2VoiceRecording },
    { value: "female3", label: "Female 3", icon: femaleIcon, description: "Middle-aged female with American accent", audioSrc: female3VoiceRecording },
    { value: "female4", label: "Female 4", icon: femaleIcon, description: "Young female with American accent", audioSrc: female4VoiceRecording },
    { value: "male1", label: "Male 1", icon: maleIcon, description: "Middle-aged male with American accent", audioSrc: male1VoiceRecording },
    { value: "male2", label: "Male 2", icon: maleIcon, description: "Young male with American accent", audioSrc: male2VoiceRecording },
    { value: "male3", label: "Male 3", icon: maleIcon, description: "Middle-aged male with Midwestern American accent", audioSrc: male3VoiceRecording },
    { value: "male4", label: "Male 4", icon: maleIcon, description: "Young male with American accent", audioSrc: male4VoiceRecording },
];