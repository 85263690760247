import React from 'react';
import { css } from '@emotion/css';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import { InjectedFormikProps, withFormik, Form } from 'formik';
import * as yup from 'yup';
import Button from '../../../components/general/Button';
import OrganizationModel from '../../../models/organizations/api/OrganizationModel';
import Select, { Option } from 'react-select';
import Loader from '../../../components/general/Loader';
import SelectField from '../../../components/forms/SelectField';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';

interface OrganizationSelectFormData {
    organizationId?: string;
}
interface OrganizationSelectFormProps {
    stateContainer: OrganizationContainer
}

const buttonSplitStyle = css`
margin-top: 60px;
    margin-bottom: -56px;
`

class InnerForm extends React.Component<InjectedFormikProps<OrganizationSelectFormProps, OrganizationSelectFormData>> {
    componentWillMount() {
        this.props.stateContainer.getOrganizations();
    }
    handleChange(value) {
        const finalValue = value === null ? null : value.value;
        this.props.setFieldValue("organizationId", finalValue);
    }
    handleBlur(blur) {
        this.props.setFieldTouched("organizationId", blur);
    }
    render() {
        const { isLoadingOrgs, organizations } = this.props.stateContainer.state;

        if (isLoadingOrgs) {
            return <Loader />
        }

        return (
            <form onSubmit={this.props.handleSubmit}>
                <FormFieldsContainer>
                    <h2>Select an Organization</h2>
                    <p>Looks like you belong to more than one organization. You’re very popular. 
Which one would you like to start with? You may switch at any time from your account dropdown</p>
                    <SelectField className={selectStyle} name="organizationId" label="Organization" onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)} value={this.props.values.organizationId} options={organizations.map(o => ({ value: o.id, label: o.name }))} />
                </FormFieldsContainer>
                <Button themes={["primary", "wide"]} type="submit" disabled={this.props.isSubmitting} text="Go" additionalClasses={buttonSplitStyle} />
            </form>

        );
    }
}
const OrganizationSelectionForm = withFormik<OrganizationSelectFormProps, OrganizationSelectFormData>({
    mapPropsToValues: props => ({ organizationId: null }),
    validationSchema: yup.object().shape({
        organizationId: yup.string().nullable().required("You must select an organization")
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        if (values.organizationId !== null) {
            props.stateContainer.setCurrentOrgById(values.organizationId);
        }
        setSubmitting(false);
    }
})(InnerForm);

const selectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    margin-top: 8px;
    .Select-control {
        border-radius: 30px;
        min-height: 56px;
    }
    .Select-placeholder, .Select-value{
        padding: 15px 26px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-value-label {
        margin-left: 16px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .Select-option, Select-noresults {
        height: 56px;
        padding: 24px 26px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .Select-input {
        margin: 16px;
        font-family: Muli, sans-serif;
    }
`

export default OrganizationSelectionForm;