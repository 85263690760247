import IResult from "../../models/result/IResult";
import WebhookModel from "../../models/webhooks/api/WebhookModel";
import { sendGet, sendPost, sendPut } from "./generic";
import WebhookTypeModel from "../../models/webhooks/api/WebhookTypeModel";
import NewWebhookRequest from "../../models/webhooks/api/NewWebhookRequest";
import UpdateWebhookRequest from "../../models/webhooks/api/UpdateWebhookRequest";
import SsmlRequest from "../../models/ssml/api/SsmlRequest";
import SsmlElement from "../../models/ssml/api/SsmlElement";
import SpeechOutput from "../../models/ssml/api/SpeechOutput";

export const parseSsml = (request: SsmlRequest): Promise<IResult<SsmlElement>> => {
    return sendPost(`/Ssml/parse`, request);
}
export const buildSsml = (request: SsmlElement): Promise<IResult<string>> => {
    return sendPost(`/Ssml/build`, request);
}

export const segmentSsml = (request: SsmlRequest, tag: string): Promise<IResult<SsmlElement[]>> => {
    return sendPost(`/Ssml/segment?tag=${tag}`, request);
}

export const segmentAndBuildSsml = (request: SsmlRequest, tag: string): Promise<IResult<string[]>> => {
    return sendPost(`/Ssml/segmentAndBuild?tag=${tag}`, request);
}

export const segmentAndBuildMultiSsml = (request: SsmlRequest, tags: string[]): Promise<IResult<string[]>> => {
    return sendPost(`/Ssml/segmentAndBuildMulti?${tags.map(t => ("tag=" + t))}`, request);
}

export const ssmlToSpeech = (request: SsmlRequest, assistant: string): Promise<IResult<SpeechOutput[]>> => {
    return sendPost(`/Ssml/toSpeech/${assistant}`, request);
}

export const validateSsml = (request: SsmlRequest, assistant?: string): Promise<IResult<boolean>> => {
    return sendPost(`/Ssml/toSpeech/${assistant}`, request);
}
