import React from 'react';
import { css } from '@emotion/css';
import ToggleIndicator from '../../general/ToggleIndicator';
import { cool_grey } from '../../../constants/colors';

interface PositionIndicatorProps {
    position: number
    count: number
    onChange: (position: number) => void
}
const indicatorContainer = css`
    display: flex;
    align-items: center;
    .toggle-indicator {
        cursor: pointer;
        border-color: ${cool_grey};
    }
`

const indicatorStyle = css`
    width: 10px;
    height: 10px;
    border: solid 1px ${cool_grey};
    border-radius: 50%;
    margin: 3px 4px;
    cursor: pointer;
`

const selectedIndicatorStyle = css`
    background: ${cool_grey};
`



const PositionIndicator: React.SFC<PositionIndicatorProps> = (props) => {

    const createIndicators = () => {
        const items = [];
        for(var i = 0; i < props.count; i++) {
            const position = i;
            items.push(<div key={i} className={`${indicatorStyle} ${i == props.position ? selectedIndicatorStyle : ''}`} onClick={() => props.onChange(position)}></div>)
        }
        return items;
    }

    return (
        <div className={indicatorContainer}>
            <ToggleIndicator direction="left" onClick={() => {
                if (props.position > 0)
                    props.onChange(props.position - 1)
                else 
                    props.onChange(props.count - 1)
            }} />
            {createIndicators()}
            <ToggleIndicator direction="right" onClick={() => {
                if (props.position < props.count - 1)
                    props.onChange(props.position + 1)
                else
                    props.onChange(0);
            }} />
        </div>
    )
};

export default PositionIndicator;