import React from 'react';
import { css } from '@emotion/css';
import { silver_two, dark_grey_blue, silver, cool_grey } from '../../../constants/colors';
import VerticalSeparator from '../../structure/VerticalSeparator';
import TextField from '../TextField';

interface LevelSelectorProps {
    options: LevelOption[]
    onOptionSelected?: (option: LevelOption) => void
    onCustomSelected?: () => void
    onCustomValueChanged?: (value: number) => void
    value: number
    isCustom: boolean
    unit?: string
    unitDetail?: string
    disableCustom?: boolean
}

interface LevelOption {
    name: string
    value: number
    selected?: boolean
}

const containerStyle = css`
    border: 1px solid ${silver_two};
    border-radius: 5px;
    position: relative;
    height: 80px;
    flex: 1;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    .selector-wrapper {
        position: relative;
        flex: 1;

        .items-wrapper {
            display: flex;

        }
    }

    .custom-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .radio-option {
        margin: 8px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        flex: 1;

        &.custom {
            flex: 0;
        }

        .check-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        .left-line, .right-line {
            height: 1px;
            background: transparent;
            flex-grow: 1;
            width: auto;
        }

        &.with-left-line {
            .left-line {
                background: ${silver_two};
            }
        }

        &.with-right-line {
            .right-line {
                background: ${silver_two};
            }
        }
                
        .check-container {
            position: relative;
            min-width: 17px;
            height: 17px;
            border-radius: 50%;
            background-color: #ffffff;
            border: solid 1px #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        }

        .selected-option {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-image: linear-gradient(225deg, #76c3b5, #3ea6ac 72%, #5296b3);
        }

        span {
            font-family: Muli;
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            text-align: center;
            color: #304562;
            margin-top: 8px;
        }
     }

     .dash-separator {
         height: 50%;
         width: 1px;
         border-left: 1px dashed ${silver_two};
         margin: 0 12px;
     }

     .unit-label {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${cool_grey};
     }

     .unit-detail-label {
         color: ${cool_grey};
         font-size: 9px;
     }

     .text-field-wrapper {
        width: 50px;
        height: 40px;
        margin: 0 12px;
         input {
            width: 50px;
            height: 40px;
            border-radius: 5px;
            box-shadow: inset 0 1px 1px 0 rgba(44, 61, 82, 0.3);
            background-color: #ffffff;
            border: 1px solid ${silver_two};
            text-align: center;
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #304562;
         }
         input[type=number]::-webkit-inner-spin-button, 
         input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }
     }
`


const LevelSelector: React.SFC<LevelSelectorProps> = (props) => (
    <div className={containerStyle}>
        <div className="selector-wrapper">
            <div className="items-wrapper">
                {props.options.map((option, index) => (
                    <div className={`${index > 0 ? 'with-left-line' : ''} ${index < props.options.length - 1 ? 'with-right-line' : ''} radio-option`} key={index} onClick={() => props.onOptionSelected(option)}>
                        <div className="check-wrapper">
                            <div className="left-line" />
                            <div className="check-container">
                                {option.value == props.value && !props.isCustom ? <div className="selected-option" /> : null}
                            </div>
                            <div className="right-line" />
                        </div>
                        <span>{option.name}</span>
                    </div>
                ))}
            </div>

        </div>
        {props.disableCustom ? null :
            <div className="custom-wrapper">
                <div className="dash-separator" />
                <div className="radio-option custom" onClick={() => props.onCustomSelected()}>
                    <div className="check-wrapper">
                        <div className="check-container">
                            {props.isCustom ? <div className="selected-option" /> : null}
                        </div>
                    </div>
                    <span>Custom</span>
                </div>
                <div className="text-field-wrapper">
                    <input type="number" name="value"
                        disabled={!props.isCustom}
                        value={props.value.toString()}
                        onChange={(e) => props.onCustomValueChanged(+e.target.value)}
                        onBlur={() => { }} />
                </div>
                <div>
                    <p className="unit-label">{props.unit}</p>
                    <p className="unit-detail-label">{props.unitDetail}</p>
                </div>
            </div>
        }
    </div>
)

export default LevelSelector