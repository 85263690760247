import React from "react";
import { Field, FieldAttributes, getIn } from 'formik';
import styled from "@emotion/styled";
import { color_colors_decline, color_shades_darkest, color_variants_decline_light_1 } from "../../constants/colors";

const alertIcon = require('../../content/images/alert-circle.svg');

export interface FieldErrorProps {
    ignoreTouched?: boolean;
};

const FieldError = ({ name, ignoreTouched }: FieldErrorProps & FieldAttributes<any>) => {
    return (
        <Field
            name={name}
            render={({ form }) => {
                const error = getIn(form.errors, name);
                const touch = ignoreTouched || getIn(form.touched, name);
                return touch && error ? (<ErrorStyle><AlertIconStyle src={alertIcon}></AlertIconStyle>{error}</ErrorStyle>) : null;
            }}
        />
    )
};

const ErrorStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Muli, "sans-serif";
    font-weight: normal;
    color: ${color_shades_darkest};
    background-color: ${color_variants_decline_light_1};
    border: 1px solid ${color_colors_decline};
    border-radius: 8px;
    flex-grow: 1;
    padding: 8px 16px;
    margin: 8px 0;
    font-size: 14px;
`;

const AlertIconStyle = styled.img`
    margin-right: 8px;
`;

export default FieldError;