import React, { useEffect, useState } from 'react'
import ApplicationModel from '../../../../shared/models/applications/api/ApplicationModel'
import HorizontalContainer from '../../../../shared/components/structure/HorizontalContainer'
import { css } from '@emotion/css'
import SearchField from '../../../../shared/components/forms/SearchField'
import VerticalSeparator from '../../../../shared/components/structure/VerticalSeparator'
import { color_defaults_white, color_highlights_blue, color_shades_dark, color_shades_darker, color_text_link } from '../../../../shared/constants/colors'
import Loader from '../../../../shared/components/general/Loader'
import Button from '../../../../shared/components/general/Button'
import PageError from '../../../../shared/components/general/PageError'
import styled from '@emotion/styled';
import HorizontalScrollableDiv from '../../../../shared/components/structure/HorizontalScrollableDiv'
import Switch from '../../../../shared/components/forms/Switch'
import TextAreaField from '../../../../shared/components/forms/TextAreaField'
import MenuContainer from '../../../../shared/hooks/MenuContainer'
import MenuItemModel from '../../../../shared/models/nlp/menu/MenuItemModel'
import ConfirmationDialog from '../../../../shared/components/structure/ConfirmationDialog'
import WarningConfirmation from '../../../../shared/components/general/WarningConfirmation'
import useIsMobile from '../../../../shared/hooks/UseIsMobile'
import MenuManagementSaveChangesDiff from './MenuManagementSaveChangesDiff'
import IconTooltip from '../../../../shared/components/general/IconTooltip'

const Pencil = require('../../../../shared/content/images/pencil-blue.svg')

interface props {
    application: ApplicationModel
    history: any
    selectedCategoryIndex: number
    setSelectedCategoryIndex: (index: number) => void

};

export default function MenuManagement(props: props) {
    const [search, setSearch] = useState("");
    const [editItemDescriptionIndexes, setEditItemDescriptionIndexes] = useState<number[]>([]);
    const [editedDescriptions, setEditedDescriptions] = useState<{ description: string, itemIndex: number, btnText: string }[]>([]);
    const [categoryIndexToUpdate, setCategoryIndexToUpdate] = useState<number>();
    const [selectedOptionName, setSelectedOptionName] = useState<string>("");
    const [confirmationModalInfo, setConfirmationModalInfo] = useState<{ title: string, body: string }>({ title: "", body: "" });
    const [isOverFlowMenuOpen, setIsOverFlowMenuOpen] = useState<boolean>();

    const isMobile = useIsMobile();

    const timerRef = React.useRef<NodeJS.Timer | null>(null);
    const menuContainer = MenuContainer?.useContainer();

    const NO_DESCRIPTION_AVAILABLE = "No description available!";
    const SAVE = "Save";
    const CLOSE = "Close";
    const ENABLE = "Enable All";
    const DISABLE = "Disable All"
    const SYNC_POS = props.application?.posLocationId?.length ?
        isMobile ?
            "Pull POS Menu" : "Pull Latest Menu from POS"
        :
        "POS Disconnected";
    const PUBLISH_ALL_CHANGES = isMobile ?
        menuContainer.pendingMenuItemUpdates.length < 1 ? "No Changes" : "Train"
        :
        menuContainer.pendingMenuItemUpdates.length < 1 ? "No Updates to Train" : "Train and Publish"

    useEffect(() => {
        if (!props.application.manageMenuApplicationId) return;
        menuContainer.getMenu(props.application.manageMenuApplicationId);
        menuContainer.getLiveMenu(props.application.id);
    }, [props.application.manageMenuApplicationId])

    const handleSearch = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timerRef.current as NodeJS.Timer);
        setSearch(searchEvent.target.value);
    };


    const truncateString = (str: string) => {
        if (typeof str === "undefined" || str === "") {
            return NO_DESCRIPTION_AVAILABLE;
        }
        if (str.length > 100) {
            return str.slice(0, 100) + '...';
        }
        return str;
    }

    const updateMenuItem = (menuItemIndex: number, action: "activateDeactivate" | "editDescription") => {
        menuContainer.setMenuItems(prevItems => {
            let shouldUpdateMenu = false
            const updatedItem = {
                ...prevItems[menuItemIndex]
            };

            let editedDesc = editedDescriptions.find(desc => desc.itemIndex === menuItemIndex)

            if (action === "activateDeactivate") {
                updatedItem.isDisabled = !updatedItem.isDisabled;
                menuContainer.updateMenuItem(updatedItem, updatedItem, "isDisabled");
                shouldUpdateMenu = true
            }
            else if (action === "editDescription" && editedDesc && updatedItem.attributes?.description !== editedDesc?.description) {
                updatedItem.attributes = {
                    ...updatedItem.attributes,
                    description: editedDesc?.description
                };
                shouldUpdateMenu = true

                menuContainer.updateMenuItem(updatedItem, updatedItem.attributes, "description");

                //Update the btnText to close after saving the description.
                setEditedDescriptions((prev) => {
                    const descIndex = prev.findIndex(t => t.itemIndex === menuItemIndex)
                    const updatedDescriptions = [...prev];
                    updatedDescriptions[descIndex].btnText = CLOSE
                    return updatedDescriptions;
                })
            }

            // Perform the asynchronous update operation on the specific item
            if (shouldUpdateMenu) {
                menuContainer.updateMenu(updatedItem, props.application.manageMenuApplicationId)
            }

            setEditItemDescriptionIndexes(prevIndexes => prevIndexes.filter(index => index !== menuItemIndex))

            // Return the updated array with the specific item modified
            return [
                ...prevItems.slice(0, menuItemIndex),
                updatedItem,
                ...prevItems.slice(menuItemIndex + 1)
            ];
        }
        );
    }

    const handleChangeDescription = (description: string, itemIndex: number) => {
        setEditedDescriptions((prevDescriptions) => {

            const existingIndex = editedDescriptions.findIndex(desc => desc.itemIndex === itemIndex)

            const menuItem = menuContainer.menuItems[itemIndex]
            if (existingIndex !== -1) {// If itemIndex exists, update the description
                const updatedDescriptions = [...prevDescriptions];
                updatedDescriptions[existingIndex].description = description;
                if (description !== menuItem.attributes.description) {
                    updatedDescriptions[existingIndex].btnText = SAVE
                } else {
                    updatedDescriptions[existingIndex].btnText = CLOSE
                }
                return updatedDescriptions;
            } else {// If itemIndex doesn't exist, add a new entry
                return [...prevDescriptions, { description, itemIndex, btnText: SAVE }];
            }

        })
    }

    const handleMenuItemClick = (itemId: string) => {
        const url = window.location.pathname;
        props.history.push(url + `/item/${itemId}`);
    }

    const getItemName = (item: MenuItemModel) => {
        const loweredOriginal = item.originalName?.toLowerCase()
        const loweredColloquial = item?.colloquialName?.toLowerCase()
        if (loweredColloquial?.length && loweredOriginal?.length && loweredOriginal !== loweredColloquial) {
            return (<NameHolder>
                <div className="hover-link">
                    {item.originalName} &nbsp;
                </div>
                <ColloquialNameDiv className="hover-link">
                    {`"${item.colloquialName}"`}
                </ColloquialNameDiv>
            </NameHolder>)
        }
        return <NameHolder>
            <div className="hover-link">
                {item.originalName ?? item.colloquialName ?? "No Name"} &nbsp;
            </div>
        </NameHolder>;
    }

    const renderCategoriesHr = () => menuContainer.categories.length > 0 && <hr className={categoriesHrStyle}></hr>

    const renderMenuItems = () => {
        if (!menuContainer.menuItems) { return }
        const selectedCategory = menuContainer.categories[props.selectedCategoryIndex]
        let menuToUse = menuContainer.menuItems
        if (search !== "") {
            const searchedMenu = menuContainer.menuItems
                .map((item, index) => ({ ...item, originalIndex: index })) // Add original index
                .filter(obj => obj.originalName.toLowerCase().includes(search.toLowerCase()));

            menuToUse = searchedMenu
        }
        return menuToUse.map((item, index) => {
            let label = ""
            if (item.isDisabled) {
                label = "Can't Order"
            } else {
                label = "Can Order"
            }
            if (selectedCategory === item.attributes?.data?.category || selectedCategory === "All" || selectedCategory === undefined) {
                const priceIsSizeDependent = item?.sizeGroups?.some(sizeGroup => sizeGroup?.sizes?.some(size => size?.attributes?.data?.price))
                const price = item.attributes?.data?.price && parseFloat(item.attributes.data.price.replace("$", '')).toFixed(2)
                const isDisabledStyle = css`
                    opacity: ${item.isDisabled ? "0.7" : "1"};
                `;
                const menuItemIndex = ("originalIndex" in item) ? item.originalIndex as number : index;
                const editedDesc = editedDescriptions.find(desc => desc.itemIndex === menuItemIndex)
                const descriptionSaveBtnTheme = editedDesc?.btnText === SAVE ? "primary-small" : "black-small"
                const isDescriptionBeingEdited = editItemDescriptionIndexes.includes(menuItemIndex)
                return (
                    <div className={`${menuItemStyle} ${isDisabledStyle}`} key={index} onClick={() => { handleMenuItemClick(item.id) }}>
                        <Header>
                            <ItemName>
                                {getItemName(item)}
                            </ItemName>
                            <Switch checked={!item.isDisabled} onChange={() => updateMenuItem(menuItemIndex, "activateDeactivate")} detail={label} stopPropagation={true} />
                        </Header>
                        {isDescriptionBeingEdited ?
                            <TextAreaField className={textAreaField}
                                stopPropagation={true}
                                value={editedDesc?.description ?? item.attributes.description} maxRows={3} useForm={false}
                                onChange={(e) => handleChangeDescription(e.target.value, menuItemIndex)}
                            />
                            :
                            <DescriptionWrapper>
                                {truncateString(item.attributes.description)}
                                <Button themes={["icon"]} icon={Pencil} className={editBtn}
                                    onClick={(e) => { isDescriptionBeingEdited ? updateMenuItem(menuItemIndex, "editDescription") : setEditItemDescriptionIndexes(prev => [...prev, menuItemIndex]); e.stopPropagation() }}
                                />
                            </DescriptionWrapper>
                        }
                        <Footer>
                            {
                                priceIsSizeDependent ?
                                    <SizeDependentPriceContainer>
                                        <h4>{`Price depends on size`}</h4>
                                        <IconTooltip text={`This item currently has different prices depending on the size. Select this item to edit the size group pricing.`} place={"right"} className={tooltipStyle} />
                                    </SizeDependentPriceContainer>
                                    :
                                    price && <Price>{`$${price}`}</Price>
                            }

                            {isDescriptionBeingEdited &&
                                <Button themes={[descriptionSaveBtnTheme]} text={editedDesc?.btnText ? editedDesc.btnText : CLOSE}
                                    onClick={(e) => { isDescriptionBeingEdited ? updateMenuItem(menuItemIndex, "editDescription") : setEditItemDescriptionIndexes(prev => [...prev, menuItemIndex]); e.stopPropagation() }}
                                    className={saveBtn}
                                />}
                        </Footer>
                    </div>
                )
            }
        })
    }

    //This would get triggered when the 3 dot menu icon next to a category is clicked
    const onOverFlowOptionSelected = (index: number, optionName: string) => {
        setCategoryIndexToUpdate(index);
        setSelectedOptionName(optionName);
        menuContainer.setShowConfirmationModal(true);

        let enableOrDisable = optionName === ENABLE ? "enable" : "disable"

        const text = `Are you sure you want to ${enableOrDisable} all the items in the ${menuContainer.categories[index]} category?`
        setConfirmationModalInfo({ title: optionName, body: text })
    }

    //This would get triggered "If yes, close" button is clicked on the ConfirmationDialog
    const handleConfirmation = async () => {
        if (confirmationModalInfo.title === SYNC_POS) {
            await menuContainer.syncMenuWithPOS(props.application);
        } else if (confirmationModalInfo.title === PUBLISH_ALL_CHANGES) {
            await menuContainer.publishAllChanges(props.application)
        } else {
            menuContainer.handleDisableEnableCategory(categoryIndexToUpdate, selectedOptionName === DISABLE, props.application.manageMenuApplicationId)
        }
    }

    if (menuContainer.isLoadingMenu) {
        return <Loader />
    }

    return (
        <>
            {menuContainer.showConfirmationModal && confirmationModalInfo.title !== "" &&
                <ConfirmationDialog
                    title={confirmationModalInfo.title}
                    deleteText="Yes, Close"
                    warning={true}
                    disabled={menuContainer.loadingPOSMenuSync}
                    onClose={() => { menuContainer.setShowConfirmationModal(false); }}
                    onConfirm={() => handleConfirmation()}>
                    {menuContainer.loadingPOSMenuSync || menuContainer.ladingPublishAllChanges ?
                        <Loader />
                        :
                        <WarningConfirmation
                            useWarningColor={true}
                            text={confirmationModalInfo.body}
                            additionalText={
                                <MenuManagementSaveChangesDiff
                                    pendingMenuUpdates={menuContainer.pendingMenuItemUpdates}
                                />
                            }
                        />}
                </ConfirmationDialog>
            }
            <MainMenuWrapper>
                <HorizontalContainer className={searchAndSyncContainer}>
                    <HorizontalScrollableDiv
                        selectedItemIndex={props.selectedCategoryIndex}
                        setSelectedItemIndex={props.setSelectedCategoryIndex}
                        selectedItemColor={color_highlights_blue}
                        nonSelectedItemsColor={"rgb(50,70,97)"}
                        selectedTextColor={"black"}
                        nonSelectedTextColor={"white"}
                        className={horizontalScrollableDivStyle}
                        buttonsClassName={categoryButtonsStyle}
                        itemsArray={menuContainer.categories}
                        hasOverFlowMenu={true}
                        overFlowMenuOptions={[{ label: ENABLE }, { label: DISABLE }]}
                        onOverFlowOptionSelected={onOverFlowOptionSelected}
                        setIsOverFlowMenuOpen={(bool) => setIsOverFlowMenuOpen(bool)}
                        isOverFlowMenuOpen={isOverFlowMenuOpen}
                    />
                    {menuContainer.categories.length > 0 && <VerticalSeparator className="separator" />}
                    <SyncPublishBtnWrapper>
                        <Button
                            onClick={() => {
                                setConfirmationModalInfo(
                                    {
                                        title: SYNC_POS,
                                        body: `This will update your Voicify menu by retrieving the latest menu from your POS system ${props.application.posName}.`
                                    }
                                )
                                menuContainer.setShowConfirmationModal(true);
                            }}
                            disabled={!props.application?.posLocationId?.length}
                            text={SYNC_POS}
                            themes={["primary-small"]}
                            className="sync-pos-button"
                        />
                        <Button
                            onClick={() => {
                                setConfirmationModalInfo(
                                    {
                                        title: PUBLISH_ALL_CHANGES,
                                        body: `This will train your live assistant with updates to the following menu item${menuContainer.pendingMenuItemUpdates.length > 1 ? "s" : ""}:`
                                    }
                                )
                                menuContainer.setShowConfirmationModal(true);
                            }}
                            text={PUBLISH_ALL_CHANGES}
                            themes={["primary-small"]}
                            className="sync-pos-button"
                            disabled={menuContainer.pendingMenuItemUpdates.length === 0}
                            borderHighlightCount={menuContainer.pendingMenuItemUpdates.length > 0 ? `${menuContainer.pendingMenuItemUpdates.length}` : undefined}
                        />
                    </SyncPublishBtnWrapper>
                </HorizontalContainer>
                {renderCategoriesHr()}
                <MenuItemsWrapper>
                    <SearchField
                        id="1"
                        name={"searchField"}
                        placeholder={"Search for a menu item"}
                        disabled={false}
                        onChange={handleSearch}
                        className={searchField} />
                    <MenuItems>
                        {renderMenuItems()}
                    </MenuItems>
                </MenuItemsWrapper>
                <PageError errors={menuContainer.errors} />
            </MainMenuWrapper>
        </>
    )
}

const MenuItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const MainMenuWrapper = styled.div`
    padding: 0px 20px 20px 20px;
`;

const SyncPublishBtnWrapper = styled.div`
    display: flex;
    @media(max-width: 800px){
        display: block;
    }
`;

const SizeDependentPriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
	bottom: 10px;
	left: 10px;
`

const tooltipStyle = css`
    margin-left: 8px;
`;


const searchAndSyncContainer = css`
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    .sync-pos-button {
        width: 100%;
        margin: 0px 10px 0px 10px;
    }

    @media(max-width: 800px){
        height: 100px;
        .sync-pos-button {
            margin: 5px 0px;
        }
    }
    .separator {
        margin-right: 5px;
        margin-left: 5px;
        height: 40px;
    }
`;

const searchField = css`
    margin-top: 12px;
    margin-bottom: 0px;
    .field-container {
        height: 32px;
        border-radius: 16px;
        margin-top: 0px;
    }
`;

const editBtn = css`
	position: absolute;
	right: -10;
	top: -10;
	color: black;
    margin: 0;
    background: ${color_defaults_white};
`;

const saveBtn = css`
	position: absolute;
	bottom: 10;
	right: 10;
    margin: 0;
`;

const MenuItems = styled.div`
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
	margin-top: 20px;

    @media(max-width: 1200px){
        grid-template-columns: repeat(3, 1fr);
    }
    @media(max-width: 800px){
        grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: 600px){
        grid-template-columns: repeat(1, 1fr);
    }
`;

const menuItemStyle = css`
	position: relative;
	border: 1px solid ${color_shades_dark};
	padding: 10px;
	border-radius: 5px;
	flex-grow: 1;
	flex-basis: calc(25% - 20px);
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    cursor: pointer;
`;

const horizontalScrollableDivStyle = css`
  	gap: 10px;
    width: 100%;
    margin-top: 5px;
`;
const categoryButtonsStyle = css`
	border-radius: 10px;
	cursor: pointer;
	border: none;
	font-size: 15px;
    margin: auto;
    padding: 5px 10px 5px 10px;
`;


const ItemName = styled.h2`
	margin: 0;
  	font-weight: bold;
`;

const NameHolder = styled.div`
    cursor: pointer;
    &:hover .hover-link {
        font-weight: bold;
        color: ${color_text_link};        
    }
`;

const ColloquialNameDiv = styled.div`
    font-weight: normal;
    color: ${color_shades_darker};
`

const DescriptionWrapper = styled.div`
    padding: 5px 10px;
    position: relative;
	margin-top: 15px;
	opacity: 0.8;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;

const Price = styled.h4`
    position: absolute;
	bottom: 10px;
	left: 10px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const Footer = styled.div`
    display: flex;
	justify-content: space-between;
	width: 100%;
    margin-top: 50px;
`;

const textAreaField = css`
	margin-bottom: 50px
`;

const categoriesHrStyle = css`
    margin: 0px 0px 10px 0px;
    padding: 0px;
`