import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue_70, dark_grey_blue_80 } from '../../constants/colors';

interface ModalLayoutProps {
    isVisible?: boolean
    onClose?: () => void
    className?: string
    zIndex?: number
    allowOverflow?: boolean
}

const ModalLayout: React.FC<ModalLayoutProps> = (props) => {
    let baseZIndex = props.zIndex;
    if (!baseZIndex) {
        baseZIndex = 1000; 
    } 
    const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Escape") {
            props.onClose();
        }
    };
    
    return (
        <div className={props.isVisible ? '' : hiddenStyle} 
            onKeyUp={(e) => handleKeyUp(e)} 
            aria-modal="true">
            <div className={`${innerLayoutStyle(baseZIndex, props.allowOverflow)} ${props.className != null ? props.className : ''}`}>
                <div className={innerWrapperStyle}>
                    {props.children}
                </div>
            </div>
            <div className={layoutStyle(baseZIndex, props.allowOverflow)} onClick={props.onClose} />
        </div>
    );
};
const layoutStyle = (z: number, allowOverflow: boolean) : string => {
    return css`
        position: fixed;
        width: 100vw;
        height: 100vh;
        overflow-x: visible;
        background: ${dark_grey_blue_70};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: ${z + 1};
        ${allowOverflow ? 'overflow: visible;' : ""}
    `;
} 

const innerLayoutStyle = (z: number, allowOverflow: boolean): string => {
    return css`
        border-radius: 20px;
        border: 12px solid ${dark_grey_blue_80};    
        display: block;
        width: 80%;
        max-width: 100%;
        height: 90%;
        max-height: calc(100vh - 20px);
        position: fixed;
        z-index: ${z + 2};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: white;
        ${allowOverflow ? '' : "overflow: hidden;"}
    `;
}
const innerWrapperStyle = css`
border-radius: 20px;
`
const hiddenStyle = css`
    display: none;
`

export default ModalLayout;