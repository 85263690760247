import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import moment from 'moment';
import AnalyticsInterval from '../../models/analytics/AnalyticsInterval';
import { color_shades_dark, color_shades_lighter, color_variants_ocean_light_2, color_text_default, color_text_light, color_colors_ocean } from '../../constants/colors';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Button from '../general/Button';
import DateInputField from './DateInputField';
import TimeIntervalPicker from './TimeIntervalPicker';
import ToggleIndicator from '../general/ToggleIndicator';
import { breakpoint_small } from '../../constants/breakpoints';
import useClickOutside from '../../hooks/general/ClickOutside';

interface TimeIntervalPickerProps {
    interval: AnalyticsInterval
    onChange?: (startDate: string, endDate: string, interval: AnalyticsInterval) => void;
    startDate?: string
    endDate?: string
    openAtTop?: boolean;
    showTimeIntervalPicker?: boolean;
    onClose: () => void
    alignLeft?: boolean;
}

const DATE_FORMAT = "YYYY-MM-DD hh:mm a";

const DateRangeIntervalPicker = React.memo((props: TimeIntervalPickerProps) => {
    const [intervalType, setIntervalType] = useState<AnalyticsInterval>();
    const [selectedDate, setSelectedDate] = useState({
        startDate: moment().format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
    })
    const [temporalDate, setTemporalDate] = useState({
        startDate: moment().format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
    })
    const DatePickerRef = useClickOutside(() => {
        if (props.onClose)
            props.onClose();
    });
    useEffect(() => {
        const isCustomDateEntered = intervalType === "custom" || selectedDate.startDate !== temporalDate.startDate || selectedDate.endDate !== temporalDate.endDate
        if (props && !isCustomDateEntered) {
            setIntervalType(props.interval);
            setSelectedDate({
                startDate: props.startDate,
                endDate: props.endDate
            });
            setTemporalDate({
                startDate: props.startDate,
                endDate: props.endDate
            });
        }
    }, [props.interval, props.startDate, props.endDate])

    function handleIntervalChange(interval) {
        var startDate = moment();
        var endDate = moment();

        // handle the non-moment intervals
        if (interval == 'custom') {
            // do nothing...
            startDate = moment(selectedDate.startDate, DATE_FORMAT);
            endDate = moment(selectedDate.endDate, DATE_FORMAT);
        }
        else if (interval == 'past 7 days') {
            startDate = moment().subtract(6, 'days'); // note the 6 and 29 is because it is inclusive of the current date by default
            endDate = moment();
        }
        else if (interval == 'past month') {
            startDate = moment().subtract(1, 'month').startOf('month');
            endDate = moment().subtract(1, 'month').endOf('month');
        }
        else {
            startDate = startDate.startOf(interval);
            endDate = endDate.endOf(interval);
        }

        setIntervalType(interval);
        setSelectedDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });
        setTemporalDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });
    }
    function handleDateRangeChange(ranges) {
        var startDate = moment(ranges.selection?.startDate);
        var endDate = moment(ranges.selection?.endDate);

        startDate = startDate.startOf('day');
        endDate = endDate.endOf('day');

        setIntervalType("custom");
        setSelectedDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });
        setTemporalDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });
    }
    function handleConfirmDateChange() {
        props.onChange(moment(selectedDate.startDate, DATE_FORMAT).format(DATE_FORMAT), moment(selectedDate.endDate, DATE_FORMAT).format(DATE_FORMAT), intervalType)
    }
    function handleTempStartChange(e) {
        const inputDate = moment(e.target.value, 'YYYY-MM-DD');
        if (inputDate.isValid()) {
            setTemporalDate({
                ...temporalDate,
                startDate: inputDate.format(DATE_FORMAT),
            });
        }
    }
    function handleTempEndChange(e) {
        const inputDate = moment(e.target.value, 'YYYY-MM-DD');
        if (inputDate.isValid()) {
            setTemporalDate({
                ...temporalDate,
                endDate: inputDate.format(DATE_FORMAT),
            });
        }
    }
    function weekOfMonth(input = moment()) {
        const firstDayOfMonth = input.clone().startOf('month');
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

        const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

        return Math.ceil((input.date() + offset) / 7);
    }
    function getCurrentPeriodLabel() {
        // gets a dynamic label based on the interval and start/end dates
        const startDate = moment(selectedDate.startDate, DATE_FORMAT);
        if (intervalType == "year") {
            return `${startDate.year()} Selected`
        }
        if (intervalType == "month") {
            return `${startDate.format("MMMM")} ${startDate.year()} Selected`;
        }
        if (intervalType == "week") {
            return `Week ${weekOfMonth(startDate)} of ${startDate.format("MMMM")} ${startDate.year()} Selected`;
        }
        var daysBetween = moment(selectedDate.endDate, DATE_FORMAT).diff(moment(selectedDate.startDate, DATE_FORMAT), 'days') + 1; // +1 is to be inclusive of the selected date
        return `${daysBetween} Day${daysBetween > 1 ? 's' : ''} Selected`;
    }
    function handleDateBlur(e) {
        var startDate = moment(temporalDate.startDate, DATE_FORMAT);
        var endDate = moment(temporalDate.endDate, DATE_FORMAT);
        var diff = endDate.diff(startDate, "hours");
        if (diff == 0) {
            startDate = startDate.startOf('day');
            endDate = endDate.endOf('day');
        }
        if (diff < 0) {
            // if they set the start to earlier, flip them
            setSelectedDate({
                startDate: endDate.format(DATE_FORMAT),
                endDate: startDate.format(DATE_FORMAT),
            });
            setTemporalDate({
                startDate: endDate.format(DATE_FORMAT),
                endDate: startDate.format(DATE_FORMAT)
            });
        } else {
            setSelectedDate({
                startDate: startDate.format(DATE_FORMAT),
                endDate: endDate.format(DATE_FORMAT)
            });
            setTemporalDate({
                startDate: startDate.format(DATE_FORMAT),
                endDate: endDate.format(DATE_FORMAT)
            });
        }
    }
    function handlePreviousPeriod() {
        var startDate = moment(selectedDate.startDate, DATE_FORMAT);
        var endDate = moment(selectedDate.endDate, DATE_FORMAT);
        if (intervalType == "year" || intervalType == "month" || intervalType == "week" || intervalType == "day") {
            startDate = startDate.subtract(1, intervalType);
            endDate = endDate.subtract(1, intervalType);
            if (intervalType == "month") {
                endDate = endDate.endOf('month'); // without this, shuffling between months with 30 and 31 days will exlcude the 31st
            }
        }
        else {
            var hoursBetween = endDate.diff(startDate, 'hours');
            startDate = startDate.subtract(hoursBetween > 0 ? hoursBetween : 24, 'hours');
            endDate = endDate.subtract(hoursBetween > 0 ? hoursBetween : 24, 'hours');
        }

        setSelectedDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });
        setTemporalDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });
    }
    function handleNextPeriod() {
        var startDate = moment(selectedDate.startDate, DATE_FORMAT);
        var endDate = moment(selectedDate.endDate, DATE_FORMAT);
        if (intervalType == "year" || intervalType == "month" || intervalType == "week") {
            startDate = startDate.add(1, intervalType);
            endDate = endDate.add(1, intervalType);
            if (intervalType == "month") {
                endDate = endDate.endOf('month'); // without this, shuffling between months with 30 and 31 days will exlcude the 31st
            }
        }
        else {
            var daysBetween = endDate.diff(startDate, 'days');
            startDate = startDate.add(daysBetween > 0 ? daysBetween : 1, 'days');
            endDate = endDate.add(daysBetween > 0 ? daysBetween : 1, 'days');
        }

        setSelectedDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });
        setTemporalDate({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT)
        });


    }

    const openAtTop = props.openAtTop;
    const showTimeIntervalPicker = props.showTimeIntervalPicker ?? true;
    const alignLeft = props.alignLeft ?? false;
    const selectionRange = {
        startDate: moment(selectedDate.startDate, DATE_FORMAT).toDate(),
        endDate: moment(selectedDate.endDate, DATE_FORMAT).toDate(),
        key: 'selection',
        showDateDisplay: false,
        color: color_variants_ocean_light_2
    }
    return (
        <div className={containerStyle(openAtTop, alignLeft)} ref={DatePickerRef}>
            <div className="triangle"></div>
            <div className="selection-container">
                <div className="date-picker-header">
                    <div className="field-wrapper ac-analytics-custom-date-input">
                        <DateInputField value={moment(temporalDate.startDate, DATE_FORMAT).format("YYYY-MM-DD")} onChange={handleTempStartChange} onBlur={handleDateBlur} />
                        <div className="field-separator"></div>
                        <DateInputField value={moment(temporalDate.endDate, DATE_FORMAT).format("YYYY-MM-DD")} onChange={handleTempEndChange} onBlur={handleDateBlur} />
                    </div>
                    <button className="period-toggle-button left" onClick={handlePreviousPeriod}>
                        <ToggleIndicator direction="left" />
                    </button>
                    <button className="period-toggle-button right" onClick={handleNextPeriod}>
                        <ToggleIndicator direction="right" />
                    </button>
                    <p className="period-label ac-analytics-selected-period">
                        {getCurrentPeriodLabel()}
                    </p>
                    <Button themes={['primary-small']} className="confirm-button ac-analytics-set-date-button" type="button" text="Update Timeframe" onClick={handleConfirmDateChange} />
                </div>
                <div className="date-interval-container">
                    {showTimeIntervalPicker && <TimeIntervalPicker interval={intervalType} onChange={handleIntervalChange} />}
                    <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleDateRangeChange}
                        months={2}
                        direction="horizontal"
                        className="date-picker ac-analytics-calendar-picker"
                    />
                </div>
            </div>
        </div>
    )
});

export default DateRangeIntervalPicker;

const containerStyle = (openAtTop = false, alignLeft = false) => css`
    display: flex;
    position: absolute;
    ${openAtTop ? 'bottom: 100%;' : "margin: 12px 288px;"}
    ${alignLeft ? 'right: 0;' : ''}
    background: ${color_shades_lighter};
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(50, 70, 97, 0.1);
    z-index: 8;
    .triangle {
        transform: rotate(45deg);
        position: absolute;
        width: 12px;
        height: 12px;
        ${openAtTop ? 'bottom: -6px;' : 'margin-top: -6px; margin-bottom: -6px;'}
        border-top: solid 1px ${color_shades_dark};
        border-left: solid 1px ${color_shades_dark};
        background: ${color_shades_lighter};
        ${alignLeft ? 'right: 60px;' : 'margin-left: 72px;'}
        border-radius: 2px;
        z-index: 5;
        ${breakpoint_small} {
            ${openAtTop ? 'bottom: -14px;' : 'margin-top: -14px;'}
            margin-left: 292px;
        }
    }
    .pick-container {
        margin: 24px 0;
        border-right: 1px solid ${color_shades_dark};
    }
    .selection-container {
        padding: 24px 32px;
        .date-picker-header {
            display: flex;
            align-items: center;
            padding-bottom: 24px;
            border-bottom: 1px solid ${color_shades_dark};
            .confirm-button {
                margin-top: 0;
                margin-bottom: 0;
            }
            .field-wrapper {
                display: flex;
                align-items: center;
                padding-right: 16px;
                border-right: 1px solid ${color_shades_dark};
                .field-separator {
                    background: ${color_shades_dark};
                    height: 1px;
                    width: 8px;
                    margin: 12px;
                }
            }
            .period-toggle-button {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                border: 1px solid ${color_shades_dark};
                box-sizing: border-box;
                border-radius: 8px;
                cursor: pointer;
                &.left {
                    margin-left: 16px;
                }
                &.right {
                    margin-left: 8px;
                }
            }
            .period-label {
                margin: 0 16px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .date-interval-container {
            display: flex;
        }
        .date-picker {
            font-size: 14px;
            flex: 1;
            div {
                background: transparent;
            }
            .rdrDay {
                &.rdrDayPassive {
                    > span > span {
                        color: ${color_text_light};
                    }
                }
                &:not(.rdrDayPassive) {
                    .rdrStartEdge + span, .rdrEndEdge + span {
                        display:none;
                    }
                    .rdrStartEdge ~ .rdrDayNumber, .rdrEndEdge ~ .rdrDayNumber  {
                        display: flex;
                        > span {
                            color: white;
                            background: ${color_colors_ocean};
                            border-radius: 16px;
                            width: 36px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }               
            }
            .rdrDayNumber {
                > span {
                    color: ${color_text_default};
                }
            }
            .rdrCalendarWrapper, .rdrDefinedRangesWrapper {
                font-size: 14px;
                font-family: Muli;
            }
            .rdrDateDisplayWrapper, .rdrInputRanges, .rdrDefinedRangesWrapper {
                display: none;
            }
        }
        ${breakpoint_small} {
            padding: 0;
            margin: 0;
            .date-picker-header {
                display: flex;
                align-items: flex-start;
                padding-bottom: 0;
                border-bottom: none;
                display: flex;
                flex-direction: column;
                .period-toggle-button.left {
                    display: none;
                }
                .period-toggle-button.right {
                    display: none;
                }
                .confirm-button {
                    margin: 8px 0 0 0;
                    align-self: flex-end;
                }
                .field-wrapper {
                    padding-right: 0;
                    border-right: none;
                }
            }
            .date-interval-container {
                display: none;
            }
            .period-label {
                display: none;
            }
        }
    }
    ${breakpoint_small} {
        display: flex;
        flex-direction: column; 
        margin: 12px 0 0 8px;
        padding: 8px;
    }

`