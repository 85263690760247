import React from 'react';
import { css } from '@emotion/css';
import SingleItemGauge from './SingleItemGauge';
import BreakdownItem from '../../models/analytics/BreakdownItem';
import _ from 'lodash';
import { color_colors_ocean, color_text_light } from '../../constants/colors';
import ToggleIndicator from '../general/ToggleIndicator';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import { style_border_default } from '../../constants/stylesValues';

interface BreakdownProps {
    items: BreakdownItem[]
    secondaryItems: BreakdownItem[]
    primaryDataLabel: string
    secondaryDataLabel?: string
    showSecondary?: boolean
    showPrimary?: boolean
    isLoading: boolean
}
interface BreakdownState {
    sort: BreakdownSort
}
type BreakdownSort = "primary-asc" | "primary-desc" | "secondary-asc" | "secondary-desc"

class BreakdownChart extends React.Component<BreakdownProps, BreakdownState> {
    constructor(props) {
        super(props);
        this.state = {
            sort: 'primary-desc'
        }
    }
    getOrderedItems() {
        if (this.state.sort == 'primary-desc')
            return _.orderBy(this.props.items ?? [], b => b.count, 'desc');
        if (this.state.sort == 'primary-asc')
            return _.orderBy(this.props.items ?? [], b => b.count, 'asc');
        if (this.state.sort == 'secondary-desc')
            return _.orderBy(this.props.secondaryItems ?? [], b => b.count, 'desc');
        if (this.state.sort == 'secondary-asc')
            return _.orderBy(this.props.secondaryItems ?? [], b => b.count, 'asc');
    }
    handlePrimarySort() {
        if (this.state.sort == 'primary-desc') {
            this.setState({
                ...this.state,
                sort: 'primary-asc'
            })
        } else {
            this.setState({
                ...this.state,
                sort: 'primary-desc'
            })
        }
    }
    handleSecondarySort() {
        if (this.state.sort == 'secondary-desc') {
            this.setState({
                ...this.state,
                sort: 'secondary-asc'
            })
        } else {
            this.setState({
                ...this.state,
                sort: 'secondary-desc'
            })
        }
    }
    render() {
        const props = this.props;
        const getSecondary = (item: BreakdownItem) => {
            return props.secondaryItems.find(s => s.label == item.label);
        }
        return (
            <div className={containerStyle}>
                <div className="label-container">
                    {props.showSecondary && <div className="label-item" onClick={this.handleSecondarySort.bind(this)}>
                        <p>{props.secondaryDataLabel}</p>
                        {this.state.sort == 'secondary-asc' && <ToggleIndicator type="triangle" className="toggle-indicator" direction="up" />}
                        {this.state.sort == 'secondary-desc' && <ToggleIndicator type="triangle" className="toggle-indicator" direction="down" />}
                    </div>}
                    {props.showPrimary && <div className="label-item" onClick={this.handlePrimarySort.bind(this)}>
                        <p>{props.primaryDataLabel}</p>
                        {this.state.sort == 'primary-asc' && <ToggleIndicator type="triangle" className="toggle-indicator" direction="up" />}
                        {this.state.sort == 'primary-desc' && <ToggleIndicator type="triangle" className="toggle-indicator" direction="down" />}
                    </div>}
                </div>
                {this.getOrderedItems()?.map((b, i) => (
                    <div key={i} className="breakdown-item">
                        <div className="icon-container">
                            {b.iconRender ? b.iconRender() : null}
                        </div>
                        <p>{b.label}</p>
                        <div className="chart-container">
                            {props.showSecondary && <div className="chart-item">
                                <p>{getSecondary(b)?.percentage?.toFixed(0) ?? 0}%</p>
                                <SingleItemGauge label={b.label} value={getSecondary(b)?.count ?? 0} percentage={getSecondary(b)?.percentage ?? 0} size={32} />
                            </div>}
                            {props.showPrimary && <div className="chart-item">
                                <p>{b.percentage?.toFixed(0) ?? 0}%</p>
                                <SingleItemGauge label={b.label} value={b.count ?? 0} percentage={b.percentage ?? 0} size={32} />
                            </div>}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
const containerStyle = css`
    width: 100%;
    padding: 0 32px 48px 32px;
    display: flex;
    flex-direction: column;
    .label-container {
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-left: auto;
        .label-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: 16px;
            p {
                color: ${color_text_light};
                font-size: 12px;
                line-height: 16px;
                text-align: right;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }
           
            .toggle-indicator {
                margin-left: 4px;
                .down {
                    border-top: 3px solid ${color_text_light};
                }
                .up {
                    border-bottom: 3px solid ${color_text_light};
                }
            }
        }
    }
   .breakdown-item {
       display: flex;
       align-items: center;
       margin: 16px 0;
       width:100%;
       .icon-container {
         width: 32px;
         height: auto;
         display: flex;
         align-items: center;
         justify-content: center;
         >img {
             width: 32px;
             height: auto;
         }
       }
       >p {
            margin: 0 16px;
            font-size: 16px;
            line-height: 24px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
       }
       .chart-container {
            margin-right: 0;
            margin-left: auto;
            height: 32px;
            display: flex;
            align-items: center;

           .chart-item {
                display: flex;
                align-items: center;      
                
                >p {
                    margin-right: 8px;
                    margin-left: 48px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 24px;
                }
           }
       }
   }
}
`;

export default BreakdownChart;