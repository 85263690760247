import { css } from '@emotion/css';
import React from 'react';
import { color_shades_dark, color_text_light } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
const powerIcon = require('../../../content/images/analytics-icons/start.svg');

const SessionStartItem = () => (
    <div className={containerStyle}>
        <div className="top-row">
            <div className="line"/>
            <div className="icon-container">
                <img src={powerIcon}/>
            </div>
            <div className="line"/>
        </div>
        <p>Session Starts</p>
    </div>
);

const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
    .top-row {
        display: flex;
        align-items: center;
        justify-content: center;

        .line {
            width: 48px;
            height: 1px;
            background: ${color_shades_dark};
        }
        .icon-container {
            margin: 0 24px;
            background: white;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: ${style_border_default};
        }
    }
    >p {
        margin-top: 8px;
        color: ${color_text_light};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
    }
`;

export default SessionStartItem