import { css } from '@emotion/css'
import React from 'react'
import Select, { Options, OptionValues } from 'react-select'
import { charcoal_blue, color_defaults_white, dark_grey_blue } from '../../constants/colors'
import VerticalSeparator from '../structure/VerticalSeparator'
import { style_border_default } from '../../constants/stylesValues'

interface props {
    field: any
    options: Options<OptionValues>
    labelIcon?: string
    label?: string
    name: string
    labelStyle?: string
    className?: string
    icon?: string
    clearable?: boolean
}

export default function SelectFieldFC(props: props) {
    return (
        <div className={`${containerStyle} ${props.className}`}>
            <div className={labelContainer}>
                {props.labelIcon && <img src={props.labelIcon} />}
                {props.label !== undefined ? <label htmlFor={props.name} className={`${labelStyle} ${props.labelStyle}`}>{props.label}</label> : null}
            </div>
            <div className={fieldContainer}>
                {props.icon !== undefined ?
                    <img src={props.icon} /> : null}
                {props.icon !== undefined ?
                    <div className={separatorStyle}>
                        <VerticalSeparator />
                    </div> : null}
                <Select
                    className={formSelectStyle}
                    name="assistantNumber"
                    placeholder="Select Assistant Number"
                    options={props.options}
                    clearable={props.clearable}
                    {...props.field}
                />
            </div>
        </div>
    )
}

const labelContainer = css`
    display: flex;
    align-items: center;
`;

const containerStyle = css`
    margin-bottom: 24px;
`;

const labelStyle = css`
    margin: 0px 16px;
    font-family: Muli;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
`;

const formSelectStyle = css`
    font-family: Muli sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    .Select-control {
        border-radius: 8px;
        min-height: 56px;
        border: none;
    }
    .Select-placeholder, .Select-value{
        padding: 12px 20px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-value-label {
        margin-left: 8px;
    }
    .Select-menu-outer {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .Select-option, Select-noresults {
        height: 56px;
        padding: 24px 18px;
        font-family: Muli;
        font-size: 14px;
    }
    .Select-option:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .Select-input {   
        margin: 12px 8px;
    }
    &.is-open  {
        .Select-control {
            .Select-arrow {
                width: 8px;
                height: 8px;
                border: none;
                border-left: solid 1px ${dark_grey_blue};
                border-top: solid 1px ${dark_grey_blue};
                transform: rotate(45deg);
                border-radius: 0;
                margin-right: 16px;
            }
        }
    }
    .Select-arrow {
        width: 8px;
        height: 8px;
        border: none;
        border-left: solid 1px ${dark_grey_blue};
        border-top: solid 1px ${dark_grey_blue};
        transform: rotate(-135deg);
        border-radius: 0;
        margin-right: 16px;
    }
`;

const fieldContainer = css`
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    background: ${color_defaults_white};
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 1px 2px ${charcoal_blue};
    border-radius: 8px;
    margin-top: 8px;
    img {
        margin: 16px;
    }
`;

const separatorStyle = css`
    height: 30px;
`;