import React from 'react';
import { css } from '@emotion/css';
import GenericContentItemContainer from '../../../state/containers/GenericContentItemContainer';
import { ConversationTrackingTreeBaseNode, ConversationTrackingTreeContentNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { color_colors_ocean, color_shades_lighter, color_text_light, color_text_link } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import { getEditUrl, getFeatureTypeIcon, getSmallLightFeatureTypeIcon } from '../../../models/extensions';
import ContentItemModel from '../../../models/features/api/ContentItemModel';
import { FallbackFeatureTypeId } from '../../../constants/featureTypeIds';
import ChatConversationFlowViewer from '../../../components/messagePreviews/ChatConversationFlowViewer';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import DeletedVariationBubble from '../../../components/messagePreviews/DeletedVariationBubble';
import FallbackMessageContainer from '../../../state/containers/FallbackMessageContainer';
const expandIcon = require('../../../content/images/expand-blue.svg');

interface TurnItemProps {
    container: GenericContentItemContainer<any, ContentItemModel, any, any, any>
    fallbackContainer: FallbackMessageContainer
    node: ConversationTrackingTreeContentNode
    application: ApplicationModel
}

export default class TurnItem extends React.Component<TurnItemProps> {
    async componentDidMount() {
        const contentItem = this.props.container?.findById(this.props.node.contentId);
        if (!contentItem) {
            const result = await this.props.container?.findFullById(this.props.node.contentId)
            if(result?.resultType != "Ok") {
                this.props.fallbackContainer.findFullById(this.props.node.contentId);
            }
        }
    }
    async componentDidUpdate(prevProps: TurnItemProps) {
        if (this.props.node?.contentId != prevProps.node?.contentId) {
            const contentItem = this.props.container?.findById(this.props.node.contentId);
            if (!contentItem) {
                const result = await this.props.container?.findFullById(this.props.node.contentId);
                if(result?.resultType != "Ok") {
                    this.props.fallbackContainer.findFullById(this.props.node.contentId);
                }
            }
        }
    }
    render() {
        let contentItem = this.props.container.findById(this.props.node.contentId);
        const fallback = this.props.fallbackContainer.findById(this.props.node.contentId);
        if(fallback) {
            contentItem = fallback;
        }
        if (this.props.container.state.isLoadingFull || this.props.fallbackContainer.state.isLoadingFull)
            return <div className={loaderContainer}><SecondaryLoader /></div>
        if (!contentItem)
            return <div className={deletedContainer}><DeletedVariationBubble /></div>
        return <div className={containerStyle}>
            <div className="turn-header">
                <div className="item-title-container">
                    <img src={getSmallLightFeatureTypeIcon(fallback ? FallbackFeatureTypeId : (this.props.node.featureTypeId ?? FallbackFeatureTypeId))} />
                    <p className="content-item-title">{contentItem?.title ?? 'Unknown'}</p>
                </div>
                <a href={`${getEditUrl(contentItem?.id, contentItem?.applicationFeatureId, fallback ? FallbackFeatureTypeId : (this.props.node.featureTypeId ?? FallbackFeatureTypeId))}analytics/tracking${window.location.search}`} target="_blank">
                    <p>Open</p>
                    <img src={expandIcon} />
                </a>
            </div>
            <div className="preview-container">
                <ChatConversationFlowViewer conversationItems={(fallback ? this.props.fallbackContainer : this.props.container).getSimpleSampleFlows(contentItem, this.props.application)}
                    mediaContainer={null}
                    applicationId={this.props.application.id}
                    endSession={null}
                    responseContainer={null}
                    targets={null}
                    noHighlights />
            </div>
        </div>
    }
}

const containerStyle = css`
    border-top: ${style_border_default};
    margin-bottom: 16px;
    .turn-header {
        display: flex;
        align-items: center;
        margin-top: -20px;
        padding: 0 32px;
        width: 100%;
        >* {
            background :${color_shades_lighter};
        }
        img {
            width: 16px;
            height: auto;
        }
        .item-title-container {
            display: flex;
            align-items: center;
            padding: 8px;
            >p {
                color: ${color_text_light};
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                margin-left: 8px;
            }
        }
       
        >a {
            color: ${color_text_link};
            padding: 8px;
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-left: auto;
            font-size: 14px;
            line-height: 20px;
            >img {
                margin-left: 8px;
            }
        }
    }
    .preview-container {
        padding-bottom: 16px;
    }
`

const loaderContainer = css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 64px 0;
    .spinner > div {
        background: ${color_colors_ocean};
    }
`

const deletedContainer = css`
width: 100%;
display: flex;
flex-direction: column;
overflow: hidden;
margin-bottom: 64px;
`