import { Container } from 'unstated';
import IResult from '../../models/result/IResult';
import IContentWebhookContainer from '../definitions/IContentWebhookContainer';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _, { result } from 'lodash';
import WebhookParameterValue from '../../models/webhooks/api/WebhookParameterValue';
import IConversationItemContainer from '../definitions/IConversationItemContainer';
import IGenericContentContainer from '../definitions/IGenericContentContainer';
import WebhookModel from '../../models/webhooks/api/WebhookModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import { addOrReplace } from '../../models/extensions';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';


export interface ContentItemState<TContentItem extends ContentItemModel> {
    isLoading: boolean
    errors: string[]
    isSaving: boolean
    isSavingStub: boolean
    isPublishing: boolean
    isLoadingWebhooks: boolean
    isUpdatingWebhooks: boolean
    isTogglingSync: boolean
    isLoadingFull?: boolean
    attachedWebhooks: WebhookInstanceModel[]
    contentItems: TContentItem[]
    hasWebhookUpdate: boolean
    webhookUpdates?: BulkWebhookInstancesUpdateRequest
}
export default abstract class GenericContentItemContainer<TState extends ContentItemState<TContentItem>,
    TContentItem extends ContentItemModel,
    TCreationRequest,
    TUpdateRequest,
    TFormData>
    extends Container<TState> implements IContentWebhookContainer, IConversationItemContainer, IGenericContentContainer<TContentItem, TCreationRequest, TUpdateRequest, TFormData> {
    abstract toggleLivePromise: (contentItemId: string, isLive: boolean) => Promise<IResult<TContentItem>>
    abstract toggleSyncPromise: (contentItemId: string, shouldNotSync: boolean) => Promise<IResult<TContentItem>>
    abstract copyPromise: (contentItemId: string) => Promise<IResult<TContentItem>>
    abstract deletePromise: (contentItemId: string) => Promise<IResult<TContentItem>>
    abstract updatePromise: (contentItemId: string, model: TUpdateRequest) => Promise<IResult<TContentItem>>
    abstract updateFromModelPromise: (model: TContentItem) => Promise<IResult<TContentItem>>
    abstract updateStubPromise: (contentItemId: string, model: any) => Promise<IResult<TContentItem>>
    abstract createPromise: (model: TCreationRequest) => Promise<IResult<TContentItem>>
    abstract createStubPromise: (model: any) => Promise<IResult<TContentItem>>
    abstract createFromModelPromise: (model: TContentItem) => Promise<IResult<TContentItem>>
    abstract getContentItemsByAppFeaturePromise: (applicationFeatureId: string) => Promise<IResult<TContentItem[]>>
    abstract getContentItemsByAppModulePromise: (applicationModuleId: string) => Promise<IResult<TContentItem[]>>
    abstract getContentItemsByAppPromise: (applicationId: string) => Promise<IResult<TContentItem[]>>
    abstract mapModelToGenericContentItemModel: (model: TContentItem) => GenericContentItemModel
    abstract findFullByIdPromise: (contentItemId: string) => Promise<IResult<TContentItem>>
    abstract mapFormDataToModel(originalModel: TContentItem, formContent: TFormData): TContentItem    
    abstract getPublishHistoryPromise(contentItemId: any, environmentId: string, skip: number, take: number) : Promise<IResult<GenericContentItemPublishModel[]>>;
    abstract getWebhooksPromise: (contentItemId: string) => Promise<IResult<WebhookInstanceModel[]>>
    abstract removeWebhookPromise: (contentItemWebhookId: string) => Promise<IResult<WebhookInstanceModel>>
    abstract bulkUpdateWebhookInstancesPromise: (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => Promise<IResult<WebhookInstanceModel[]>>
    abstract addWebhookPromise: (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => Promise<IResult<WebhookInstanceModel>>
    abstract updateWebhookPromise: (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => Promise<IResult<WebhookInstanceModel>>
    abstract getSimpleSampleFlows: (contentItem: TContentItem, application?: ApplicationModel) => ConversationItem[]
    abstract featureTypeId: string

    async findFullById(contentItemId: string): Promise<IResult<TContentItem>> {
        try {
            await this.setState({
                ...this.state,
                isLoadingFull: true
            })
            const result = await this.findFullByIdPromise(contentItemId);
            if (result?.resultType == "Ok") {
                const contentItems = this.state.contentItems
                const existingItem = contentItems.find(c => c.id == result.data.id);
                if (existingItem) {
                    contentItems[contentItems.indexOf(existingItem)] = result.data;
                } else {
                    contentItems.push(result.data);
                }
                await this.setState({
                    ...this.state,
                    contentItems,
                    isLoadingFull: false
                })
            } else {
                await this.setState({
                    ...this.state,
                    isLoadingFull: false
                })
            }
            return result;

        } catch (e) {
            await this.setState({
                ...this.state,
                isLoadingFull: false
            })
        }
    }

    setInitialCreateState(): void {
        this.setState({
            ...this.state,
            attachedWebhooks: [],
            hasWebhookUpdate: false,
            webhookUpdates: null,
        })
    }


    getGenericContentItemModels(applicationFeatureId: string): Promise<IResult<GenericContentItemModel[]>> {
        const promise = new Promise<IResult<GenericContentItemModel[]>>((resolve, error) => {
            this.getByApplicationFeature(applicationFeatureId).then(result => {
                if (result.resultType == "Ok") {
                    resolve({
                        resultType: "Ok",
                        errors: [],
                        data: result.data.map(lm => this.mapModelToGenericContentItemModel(lm))
                    })
                }
                else {
                    resolve({
                        resultType: result.resultType,
                        errors: result.errors,
                        data: null
                    })
                }
            }).catch(e => {
                error(e);
            });

        });

        return promise;
    }

    async addStub(applicationId: string, applicationFeatureId: string, title: string, additionalProperties: object): Promise<IResult<GenericContentItemModel>> {
        this.setState({
            ...this.state,
            isSavingStub: true
        })
        const promise = new Promise<IResult<GenericContentItemModel>>((resolve, error) => {
            this.createStubPromise({
                applicationFeatureId: applicationFeatureId,
                applicationId: applicationId,
                title: title,
                ...additionalProperties
            }).then(result => {
                this.setState({
                    ...this.state,
                    isSavingStub: false
                })
                if (result.resultType == "Ok") {
                    resolve({
                        resultType: "Ok",
                        errors: [],
                        data: this.mapModelToGenericContentItemModel(result.data)
                    })
                }
                else {
                    resolve({
                        resultType: result.resultType,
                        errors: result.errors,
                        data: null
                    })
                }
            }).catch(e => {
                error(e);
                this.setState({
                    ...this.state,
                    isSavingStub: false
                })
            });
        });

        return promise;
    }
    updateStub(id: string, applicationId: string, title: string): Promise<IResult<GenericContentItemModel>> {
        this.setState({
            ...this.state,
            isSavingStub: true
        })
        const promise = new Promise<IResult<GenericContentItemModel>>((resolve, error) => {
            // get the original item, and just replace the title so we don't delete stub props created elsewhere
            const originalItem = this.state.contentItems.find(q => q.id == id);
            const model = originalItem ? { ...originalItem } : { title: title, applicationId };
            model.title = title;
            this.updateStubPromise(id, model).then(result => {
                this.setState({
                    ...this.state,
                    isSavingStub: false
                })
                if (result.resultType == "Ok") {
                    resolve({
                        resultType: "Ok",
                        errors: [],
                        data: this.mapModelToGenericContentItemModel(result.data),
                    })
                }
                else {
                    resolve({
                        resultType: result.resultType,
                        errors: result.errors,
                        data: null
                    })
                }
            }).catch(e => {
                error(e);
                this.setState({
                    ...this.state,
                    isSavingStub: false
                })
            });
        });

        return promise;
    }

    async getForApp(applicationId: string) {
        try {
            await this.setLoading(true);
            const result = await this.getContentItemsByAppPromise(applicationId)
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    errors: [],
                    contentItems: result.data,
                    isLoading: false
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
            return result;
        } catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                errors: [e.toString()]
            })
        }
    }

    async getByApplicationFeature(applicationFeatureId: string) {
        try {
            await this.setLoading(true);
            const result = await this.getContentItemsByAppFeaturePromise(applicationFeatureId);
            if (result.resultType == "Ok") {
                const items = this.state.contentItems;
                result.data.forEach(item => {
                    addOrReplace(items, item);
                })
                await this.setState({
                    ...this.state,
                    errors: [],
                    contentItems: items,
                    isLoading: false
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }

            return result;

        } catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                errors: [e.toString()]
            })
        }
    }

    findById(contentItemId: string): TContentItem {
        return this.state.contentItems.find(lm => lm.id == contentItemId);
    }
    async create(model: TCreationRequest): Promise<IResult<TContentItem>> {
        try {
            await this.setSaving(true);
            const result = await this.createPromise(model);
            if (result.resultType == "Ok") {
                await this.setSaving(false);
                if (this.state.isPublishing) {
                    // toggle to live, then reset is publishing
                    await this.toggleLivePromise(result.data.id, true);
                    await this.setState({
                        ...this.state,
                        isPublishing: false
                    });
                }
            }
            else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                })
            }
            return result;
        } catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                errors: [e.toString()]
            })
        }
    }

    async createFromModel(model: TContentItem): Promise<IResult<TContentItem>> {
        try {
            await this.setSaving(true);
            const result = await this.createFromModelPromise(model);
            if (result.resultType == "Ok") {
                await this.setSaving(false);
            }
            else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                })
            }
            return result;
        } catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                errors: [e.toString()]
            })
        }
    }

    async update(contentItemId: string, model: TUpdateRequest): Promise<IResult<TContentItem>> {
        try {
            await this.setSaving(true);
            const result = await this.updatePromise(contentItemId, model);
            if (result.resultType == "Ok") {
                this.setSaving(false);
                // replace the message in state
                const messages = this.state.contentItems;
                const existingMessage = messages.find(m => m.id == contentItemId);
                if (existingMessage != null) {
                    messages[messages.indexOf(existingMessage)] = result.data;
                    await this.setState({
                        ...this.state,
                        contentItems: messages,
                        isSaving: false
                    });
                }

                if (this.state.isPublishing) {
                    // toggle to live, then reset is publishing
                    await this.toggleLivePromise(result.data.id, true)
                    await this.setState({
                        ...this.state,
                        isPublishing: false
                    });
                }
            }
            else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                });
            }
            return result;
        } catch (e) {
            console.log(e);
            await this.setState({
                ...this.state,
                isSaving: false,
                errors: [e.toString()]
            })
        }
    }

    async updateFromModel(model: TContentItem): Promise<IResult<TContentItem>> {
        try {
            await this.setSaving(true);
            const result = await this.updateFromModelPromise(model);
            if (result.resultType == "Ok") {
                await this.setSaving(false);

                // replace the message in state
                const messages = this.state.contentItems;
                const existingMessage = messages.find(m => m.id == model.id);
                if (existingMessage != null) {
                    messages[messages.indexOf(existingMessage)] = result.data;
                    await this.setState({
                        ...this.state,
                        contentItems: messages,
                        isSaving: false
                    });
                }
            }
            else {
                await this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                });
            }
            return result;
        } catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                errors: [e.toString()]
            })
        }
    }

    copy(contentItemId: string): Promise<IResult<TContentItem>> {
        const copyPromise = this.copyPromise(contentItemId);
        copyPromise.then(result => {
            if (result.resultType == "Ok") {
                const updatedMessages = [...this.state.contentItems, result.data];
                this.setState({
                    ...this.state,
                    contentItems: updatedMessages,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            console.log(error);
        });
        return copyPromise;
    }
    toggleLive(id: string, isLive: boolean) {
        return this.toggleLivePromise(id, isLive).then(liveResult => {
            this.setState({
                ...this.state
            });
        }).catch(error => console.log(error));
    }
    async toggleSync(id: string, shouldNotSync: boolean) { 
        try {
            const items = this.state.contentItems;
            const item = items.find(c => c.id == id);
            if (item) {
                item.shouldNotSync = shouldNotSync;
                const index = items.findIndex(c => c.id == id);
                items[index] = item;
            }
            await this.setState({
                ...this.state,
                isTogglingSync: true,
                contentItems: items
            });
            const liveResult = await this.toggleSyncPromise(id, shouldNotSync);
    
            await this.setState({
                ...this.state,
                isTogglingSync: false
            });
    
            return liveResult;

        } catch(e) {
            await this.setState({
                ...this.state,
                isTogglingSync: false,
                errors: "Error while toggling sync"
            })
        }
    }
    delete(contentItemId: string): Promise<IResult<TContentItem>> {
        const deletePromise = this.deletePromise(contentItemId);
        deletePromise.then(result => {
            if (result.resultType == "Ok") {
                // remove the message from state immutably;
                const updatedMessages = this.state.contentItems.filter(w => w.id !== contentItemId);
                this.setState({
                    ...this.state,
                    contentItems: updatedMessages,
                    isSaving: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                });
            }
        }).catch(error => {
            console.log(error);
        });

        return deletePromise;
    }
    async getContentWebhooks(id: string): Promise<IResult<WebhookInstanceModel[]>> {
        try {
            await this.setLoadingWebhooks(true);
            const result = await this.getWebhooksPromise(id);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isLoadingWebhooks: false,
                    errors: [],
                    attachedWebhooks: result.data
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoadingWebhooks: false,
                    errors: result.errors
                })
            }
            return result;
        } catch (e) {
            await this.setState({
                ...this.state,
                isLoadingWebhooks: false,
                errors: [e.toString()]
            })
        }
    }

    stageWebhookUpdates(updateRequest: BulkWebhookInstancesUpdateRequest) {
        this.setState({
            ...this.state,
            hasWebhookUpdate: true,
            webhookUpdates: updateRequest
        });
    }

    async bulkUpdateContentItemWebhookInstances(contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> {
        try {
            await this.setState({
                ...this.state,
                isUpdatingWebhooks: true
            })
            const result = await this.bulkUpdateWebhookInstancesPromise(contentItemId, updateRequest);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    isUpdatingWebhooks: false,
                    attachedWebhooks: result.data,
                    hasWebhookUpdate: false, 
                    webhookUpdates: [],
                    errors: [],
                });
            }
            else {
                await this.setState({
                    ...this.state,
                    isUpdatingWebhooks: false,
                    webhookUpdates: [],
                    errors: result.errors
                });
            }
            return result;
        } catch (error) {
            await this.setState({
                ...this.state,
                isUpdatingWebhooks: false,
                webhookUpdates: [],
                errors: [error]
            })
            return {
                resultType: "InternalError",
                data: null,
                errors: [error]
            }
        }
    }


    async setSaving(isSaving: boolean) {
        await this.setState({
            ...this.state,
            isSaving: isSaving
        });
    }
    async setLoading(isLoading: boolean) {
        await this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
    async setIsPublishing(isPublishing: boolean) {
        await this.setState({
            ...this.state,
            isPublishing: isPublishing
        });
    }
    async setLoadingWebhooks(isLoading: boolean) {
        await this.setState({
            ...this.state,
            isLoadingWebhooks: isLoading
        })
    }
    async clearErrors() {
        await this.setState({
            ...this.state,
            errors: []
        });
    }
    async setError(error: string) {
        await this.setState({
            ...this.state,
            errors: [error?.toString() ?? 'Unexpected error']
        })
    }

    async loadPublishHistory(contentItemId: string, environmentId: string, skip: number = 0, take: number = 50, append: boolean = false) : Promise<IResult<GenericContentItemPublishModel[]>> {
        try {
            const result = await this.getPublishHistoryPromise(contentItemId, environmentId, skip, take);
            return result;
        } catch (error) {
            await this.setState({ ...this.state, errors: [error.toString()], isLoading: false });
        }
    }

}