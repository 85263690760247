import React from 'react';
import { css } from '@emotion/css';
import { silver_four, color_variants_shadow_10 } from '../../constants/colors';
import ToggleIndicator from '../general/ToggleIndicator';
import VerticalSeparator from '../structure/VerticalSeparator';

// component used in building forms with consistent components for look and function
interface NumberProps {
    selectedNumber?: string
    label?: string
    updateValue: (boolean) => void
    showNumber?: boolean
    useShadow?: boolean
}

const container = css`    
    display: inline-block;
    text-align:center;
`

const verticalSeparatorStyle = css`
    height: 60%;
    margin-top: 7px;
`

const pageSelector = css`
    height: 30px;
    border: solid 1px ${silver_four};
    border-radius: 20px;
    background: white;
    width: 120px;
    &.simple { width: 75px; }
    display: inline-block;
    flex-direction: row;
    .left {
        margin-right: 6px;
    }
    .right {
        margin-left: 6px;
    }
    div {
        display: inline-block;
    }
    div .toggle-indicator {
        cursor: pointer;        
        position: relative;
        top: 13;
    }
    .toggleContainer {
        cursor: pointer;        
        height: 30px;
        width: 30px;
        position: relative;
        top: -20;
    }
    .numberValue {
        padding-left: 12px;    
        font-size: 12;
        padding-right: 12px;
        position: relative;
        font-family: Muli;
        top: -4;
    }
`;

const boxShadow = css`
    box-shadow: 0 2px 4px 0 ${color_variants_shadow_10};
`;


class NumberIncrementSelector extends React.Component<NumberProps, {}> {
    render() {
        return (
            <div className={`${container} page-container`}>
                {this.props.label === undefined ? null : <div className={`label page`}>{this.props.label}</div>}
                <div className={`${this.props.useShadow != false ? boxShadow : ''} ${pageSelector} ${(this.props.showNumber == null || this.props.showNumber === true) ? `` : `simple`}`}>
                    <div onClick={() => this.props.updateValue(false)} className={"toggleContainer"}>
                        <ToggleIndicator direction={"left"}></ToggleIndicator>
                    </div>
                    {(this.props.showNumber == null || this.props.showNumber === true) &&
                        <VerticalSeparator className={verticalSeparatorStyle} />}
                    {(this.props.showNumber == null || this.props.showNumber === true) &&
                        <div className={"numberValue"}>{this.props.selectedNumber}</div>}
                    <VerticalSeparator className={verticalSeparatorStyle} />
                    <div onClick={() => this.props.updateValue(true)} className={"toggleContainer"}>
                        <ToggleIndicator direction={"right"}></ToggleIndicator>
                    </div>
                </div>
            </div>
        );
    }
}

export default NumberIncrementSelector;