import React from "react";
import { Subscribe } from 'unstated';
import ApplicationContainer from "../../../../shared/state/containers/ApplicationContainer";
import OrganizationContainer from "../../../../shared/state/containers/OrganizationContainer";
import AppliedApplicationTemplateContainer from "../../../../shared/state/containers/AppliedApplicationTemplateContainer";
import ApplicationDashboard from "./components/ApplicationDashboard";
import TemplateConfigurationContainer from "../../../../shared/state/containers/TemplateConfigurationContainer";
import ApplicationAnalyticsFacetContainer from "../../../../shared/state/containers/ApplicationAnalyticsFacetContainer";

interface BasicOrganizationApplicationsProps {
    history: any
}

const BasicOrganizationApplications: React.FC<BasicOrganizationApplicationsProps> = (props) => {

    return (
        <Subscribe to={[
            ApplicationContainer,
            OrganizationContainer,
            AppliedApplicationTemplateContainer,
            TemplateConfigurationContainer,
            ApplicationAnalyticsFacetContainer
        ]}>
            {(
                appContainer: ApplicationContainer,
                orgContainer: OrganizationContainer,
                appliedAppTemplateContainer: AppliedApplicationTemplateContainer,
                templateConfigurationContainer: TemplateConfigurationContainer,
                facetContainer: ApplicationAnalyticsFacetContainer
            ) => {
                return (
                    <ApplicationDashboard
                        appsContainer={appContainer}
                        organizationId={orgContainer.state.currentOrganization.id}
                        orgContainer={orgContainer}
                        appliedAppTemplateContainer={appliedAppTemplateContainer}
                        templateConfigurationContainer={templateConfigurationContainer}
                        facetContainer={facetContainer}
                        history={props.history}
                    />
                );
            }}
        </Subscribe>
    );
};

export default BasicOrganizationApplications;