import React from 'react';
import { css } from '@emotion/css';
import CustomRequestContainer from '../../../state/containers/CustomRequestContainer';
import ExitMessageContainer from '../../../state/containers/ExitMessageContainer';
import FallbackMessageContainer from '../../../state/containers/FallbackMessageContainer';
import HelpMessageContainer from '../../../state/containers/HelpMessageContainer';
import LatestMessageContainer from '../../../state/containers/LatestMessageContainer';
import NumberRangeContainer from '../../../state/containers/NumberRangeContainer';
import QuestionAnswerContainer from '../../../state/containers/QuestionAnswerContainer';
import SimpleChoiceContainer from '../../../state/containers/SimpleChoiceContainer';
import WelcomeMessageContainer from '../../../state/containers/WelcomeMessageContainer';
import { ConversationTrackingTreeBaseNode, ConversationTrackingTreeContentNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import GenericContentItemContainer from '../../../state/containers/GenericContentItemContainer';
import TurnItem from './TurnItem';
import SessionStartItem from './SessionStartItem';
import SessionEndItem from './SessionEndItem';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import { FallbackFeatureTypeId } from '../../../constants/featureTypeIds';

interface ScriptPreviewProps {
    welcomeContainer: WelcomeMessageContainer,
    helpContainer: HelpMessageContainer,
    fallbackContainer: FallbackMessageContainer,
    exitContainer: ExitMessageContainer,
    questionAnswerContainer: QuestionAnswerContainer,
    latestMessageContainer: LatestMessageContainer,
    simpleChoiceContainer: SimpleChoiceContainer,
    numberRangeContainer: NumberRangeContainer,
    customRequestContainer: CustomRequestContainer,
    appContainer: ApplicationContainer,
    applicationId: string,
    hasStart: boolean,
    nodes: ConversationTrackingTreeBaseNode[]
}
interface ScriptPreviewState {

}
export default class ScriptPreview extends React.Component<ScriptPreviewProps, ScriptPreviewState> {
    containers: GenericContentItemContainer<any, any, any, any, any>[]

    constructor(props) {
        super(props);
        this.containers = [
            props.welcomeContainer,
            props.helpContainer,
            props.fallbackContainer,
            props.exitContainer,
            props.questionAnswerContainer,
            props.latestMessageContainer,
            props.simpleChoiceContainer,
            props.numberRangeContainer,
            props.customRequestContainer]
    }


    render() {
        return (
            <div className={containerStyle}>
                {this.props.hasStart && <SessionStartItem />}
                {this.props.nodes?.filter(n => n != null)?.map((n, i) => {
                    if (n.conversationTrackingNodeType == "Content") {
                        const contentNode = n as ConversationTrackingTreeContentNode;
                        return <TurnItem key={i}
                            node={contentNode}
                            fallbackContainer={this.props.fallbackContainer}
                            container={this.containers.find(c => c.featureTypeId == (contentNode.featureTypeId ?? FallbackFeatureTypeId))}
                            application={this.props.appContainer.state.currentApp} />
                    }
                    if (n.conversationTrackingNodeType == "SessionEnd") {
                        return <SessionEndItem key={i} />
                    }
                })}

            </div>
        )
    }
}

const containerStyle = css`
    margin-bottom: 80px;
    padding-top: 32px;
`