import { sendGet, queryStringSerialize, sendDelete } from './generic';
import IResult from '../../models/result/IResult';
import GoogleAuthUrlRequest from '../../models/deployments/api/GoogleAuthUrlRequest';
import TokenResponse from '../../models/deployments/api/TokenResponse';
import salt from '../../services/authSalt';

export const getDialogflowAuthUrl = (authUrlRequest: GoogleAuthUrlRequest): Promise<IResult<string>> => {
    var csrfToken = salt()
    localStorage.setItem("google-salt", csrfToken);
    return sendGet<string>("/GoogleAuthorization/AuthorizationUrl/Dialogflow?" + queryStringSerialize(authUrlRequest) + `&salt=${csrfToken}`);
}

export const getAvailableDialogflowCredentials = (organizationId: string): Promise<IResult<TokenResponse[]>> => {
    return sendGet<TokenResponse[]>(`/GoogleAuthorization/Credentials/${organizationId}`)
}

export const googleAuthCallback = (query: string): Promise<IResult<boolean>> => {
    return sendGet<boolean>(`/GoogleAuthorization/Callback${query}`)
}

// This is used for Dialogflow to remove Google account
export const removeGoogleCredentials = (tokenId: string): Promise<IResult<boolean>> => {
    return sendDelete<boolean>(`/GoogleAuthorization/Credentials/${tokenId}`)
}


