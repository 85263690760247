import React from 'react';
import { FormikProps } from 'formik';
import styled from '@emotion/styled';
import PanelContainer from '../../../../../shared/components/structure/Panels/PanelContainer';
import ScrollablePanelBody from '../../../../../shared/components/structure/ScrollablePanelBody';
import Button from '../../../../../shared/components/general/Button';
import { css } from '@emotion/css';
import { breakpoint_small } from '../../../../../shared/constants/breakpoints';
import PanelHeaderContainer from '../../../../../shared/components/structure/Panels/PanelHeaderContainer';
import { color_shades_dark, color_shades_lighter } from '../../../../../shared/constants/colors';
import Switch from '../../../../../shared/components/forms/Switch';
import { AutoGenerateFormFieldResponsesFormData, AutoGenerateFormFieldResponsesProps } from './AutoGenerateFormFieldResponsesForm';
import FormFieldCard from '../../../../../shared/components/forms/FormFieldCard';
import AutoGenerateFormFieldInformationGathering from './AutoGenerateFormFieldInformationGathering';

const instructionsIcon = require('../../../../../shared/content/images/info_icon.svg');

const AutoGenerateFormFieldResponses: React.FC<AutoGenerateFormFieldResponsesProps & FormikProps<AutoGenerateFormFieldResponsesFormData>> = (props) => {

    const updateAutoGenerateSectionStatus = (isComplete: boolean, isSelected: boolean) => {
        const autoGenerateSectionIndex = props.appendedSections.findIndex(appendedSection => appendedSection.id === props.autoGenerateFormResponsesSection.id);
        const updatedAutoGenerateSection = {
            ...props.appendedSections[autoGenerateSectionIndex],
            isComplete,
            isSelected
        }
        const appendedSectionsWithUpdatedAutoGenerateSection = [
            ...props.appendedSections.slice(0, autoGenerateSectionIndex),
            updatedAutoGenerateSection,
            ...props.appendedSections.slice(autoGenerateSectionIndex + 1)
        ];
        props.setAppendedSections(appendedSectionsWithUpdatedAutoGenerateSection);
    };

    const handleAutoGenerateFormFieldResponsesSubmit = () => {
        props.handleRemoveSection(props.autoGenerateFormResponsesSection);
        props.removeSectionFromIncompleteSections(props.autoGenerateFormResponsesSection);
        props.handleSubmit();
    };

    const handleFileUrlFieldChange = (value) => {
        props.setFieldValue("fileUrl", value);
        props.setFieldValue("webPageUrls", []);
    };

    const handleSkipAutoGenerateSection = () => {
        props.handleNextSection();
        updateAutoGenerateSectionStatus(true, false);
        props.removeSectionFromIncompleteSections(props.autoGenerateFormResponsesSection);
    };

    return (
        <AutoGenerateResponsesWrapper>
            <PanelHeaderContainer>
                <InstructionsIcon src={instructionsIcon} />
                <InstructionsText>Complete the fields below</InstructionsText>
                <div className={buttonContainerStyle}>
                    <Button
                        disabled={false}
                        themes={["white-small"]}
                        text="Previous"
                        type="button"
                        onClick={props.handlePreviousSection}
                    />
                    <Button
                        disabled={false}
                        loading={props.isLoading}
                        themes={["primary-small"]}
                        text="Save & Next"
                        type="button"
                        onClick={() => handleAutoGenerateFormFieldResponsesSubmit()}
                    />
                </div>
            </PanelHeaderContainer>
            <AutoGenerateResponsesFormWrapper>
                <PanelContainer className={panelContainerStyle}>
                    <ScrollablePanelBody>
                        <form onSubmit={props.handleSubmit}>
                            <FormFieldCardsWrapper>
                                <Title>Auto-Generate Responses</Title>
                                <StyledFormFieldCard
                                    title="Auto-Generate Responses"
                                    tip='Enable this feature to automatically generate responses for your assistant.'
                                    className={formFieldCardStyle}
                                >
                                    <CustomSwitch
                                        name='autoGenerateFormFieldResponses'
                                        label='Auto-generate responses for me'
                                        checked={props.values.autoGenerateFormFieldResponses}
                                        onChange={(checked) => props.setFieldValue('autoGenerateFormFieldResponses', checked)}
                                    />
                                </StyledFormFieldCard>
                                {props.values.autoGenerateFormFieldResponses === true &&
                                    <AutoGenerateFormFieldInformationGathering
                                        {...props}
                                        handleFileUrlFieldChange={(url: string) => handleFileUrlFieldChange(url)}
                                    handleWebPageUrlChange={e => {
                                        props.setFieldValue("fileUrl", null);
                                        props.handleChange(e);
                                    }}
                                        setUseFileUpload={(value: boolean) => props.setFieldValue("usefileUpload", value)}
                                    />
                                }
                            </FormFieldCardsWrapper>
                            <ButtonWrapper>
                                <StyledSaveAndNextButton
                                    disabled={false}
                                    loading={props.isLoading}
                                    themes={["primary"]}
                                    text="Save & Next"
                                    type="button"
                                    onClick={() => handleAutoGenerateFormFieldResponsesSubmit()}
                                />
                                <StyledSkipButton
                                    disabled={false}
                                    loading={props.isLoading}
                                    themes={["primary"]}
                                    text="Skip"
                                    type="button"
                                    onClick={() => handleSkipAutoGenerateSection()}
                                />
                            </ButtonWrapper>
                        </form>
                    </ScrollablePanelBody>
                </PanelContainer>
            </AutoGenerateResponsesFormWrapper>
        </AutoGenerateResponsesWrapper>
    )
};

const StyledFormFieldCard = styled(FormFieldCard)``;

const formFieldCardStyle = css`
    margin: 32px 0;
`;

const AutoGenerateResponsesWrapper = styled.div``;

const CustomSwitch = styled(Switch)`
    margin: 24px 0;
`;

const FormFieldCardWrapper = styled.div`
    padding: 16px 0px;
    position: relative;
    .field-triangle {
        position: absolute;
        width: 24px;
        height: 24px;
        background: ${color_shades_lighter};
        right: -44px;
        top: 64px;
        transform: rotate(45deg);
        border-left: 1px solid ${color_shades_dark};
        border-bottom: 1px solid ${color_shades_dark};
    }
`;

const FormFieldCardsWrapper = styled.div`
    padding: 32px;
    h3 {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
    ${breakpoint_small} {
        padding: 16px;
        h3 {
            margin-bottom: 0px;
        }
    }
`;

const InstructionsIcon = styled.img`
    width: 24px;
    height: 24px;
    margin: 0 8px 0 32px;
    ${breakpoint_small} {
        display: none;
    }
`;

const InstructionsText = styled.p`
    ${breakpoint_small} {
        display: none;
    }
`;

const buttonContainerStyle = css`
    margin-right: 16px;
    margin-left: auto;
    button {
        margin: 0 16px 0 0;
    }
    ${breakpoint_small} {
        margin-right: 0;
    }
`

const AutoGenerateResponsesFormWrapper = styled.div`
    h3 {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
    ${breakpoint_small} {
        padding: 16px;
        h3 {
            margin-bottom: 0px;
        }
    }
`;

const StyledSaveAndNextButton = styled(Button)`
    margin-top: 0px;
    margin-right: 32px;
    ${breakpoint_small} {
        margin-right: 0;
        width: 100px;
        font-size: 12px;
    }
`;

const StyledSkipButton = styled(Button)`
    margin-top: 0px;
    margin-right: 32px;
    ${breakpoint_small} {
        margin-right: 16px;
        width: 100px;
        font-size: 12px;
    }
`;

const panelContainerStyle = css`
    display: flex;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 88px 18px;
    ${breakpoint_small} {
        justify-content: flex-end;
    }
`;

const Title = styled.h3`
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 16px;
`;

export default AutoGenerateFormFieldResponses;
