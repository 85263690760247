import React from 'react';
import { css } from '@emotion/css';
import { style_border_default } from '../../../constants/stylesValues';
import TooltipWrapper from '../../general/TooltipWrapper';
import Button from '../../general/Button';
const clearIcon = require('../../../content/images/analytics-icons/remove-inset.svg');
interface ActiveFilterProps {
    items: ActiveFilterItem[]
    icon: string
    limit: number
    onClear: () => void
    activeFilterRender?: () => JSX.Element
}
export interface ActiveFilterItem {
    icon: string
    label: string
}

const ActiveFilterIndicator: React.SFC<ActiveFilterProps> = (props) => {
    return (
        <div className={`${containerStyle} ac-analytics-active-filters`}>
            <img src={props.icon} className="filter-group-icon" />
            {props.activeFilterRender ? props.activeFilterRender() : props.items.map((item, i) => <TooltipWrapper key={i} text={item.label}>
                <img className="option-icon" src={item.icon} />
            </TooltipWrapper>)
            }
            <Button icon={clearIcon} themes={['icon']} onClick={props.onClear} className="clear-button"/>
        </div>
    );
}

const containerStyle = css`
background: white;
border: ${style_border_default};
box-sizing: border-box;
border-radius: 24px;
height: 40px;
padding: 0 16px;
display: flex;
align-items: center;

.filter-group-icon {
    height: 24px;
    border-right: ${style_border_default};
    padding-right: 8px;
}
.option-icon {
    margin-left: 8px;
    height: 24px;
}
.clear-button {
    margin: 0;
    margin-left: 8px;
    width: 24px;
    height: 24px;
    padding: 0;
}
`

export default ActiveFilterIndicator;