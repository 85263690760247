import React, { useState } from 'react';
import { css } from '@emotion/css';
import ReactTable, { Column, ComponentPropsGetterR, ComponentPropsGetter0 } from 'react-table';
import { color_shades_dark } from '../../constants/colors';
import NumberIncrementSelector from '../forms/NumberIncrementSelector';
import RoundedSelectField from '../forms/RoundedSelectField';
import { breakpoint_small } from '../../constants/breakpoints';

interface SortedTableProps {
    columns?: Column[]
    data?: any[]
    className?: string
    showPagination?: boolean
    pageSize?: number
    getTrProps?: ComponentPropsGetterR | ComponentPropsGetter0;
    getExportableData?: () => string[][]
    exportName?: string
}

const ReportTable: React.FC<SortedTableProps> = (props) => {

    const [pageSize, setPageSize] = useState(props.pageSize ? props.pageSize : (localStorage.getItem("defaultPageSize") ? parseInt(localStorage.getItem("defaultPageSize")) : 20));
    const [pageNumber, setPageNumber] = useState(1);

    const pageSizes = [5, 10, 20, 50, 100];

    const handlePage = (increment: boolean) => {
        const currentDataSize = props.data?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)?.length ?? 0;
        let newPageNumber = pageNumber;
        if (increment && currentDataSize >= pageSize) {
            newPageNumber++;
        } else if (!increment && pageNumber > 1) {
            newPageNumber--;
        }
        setPageNumber(newPageNumber);
    };

    const handlePageSizeChange = (size: any) => {
        setPageSize(size.value);
        setPageNumber(1);
    };

    return (
        <div className={containerStyle}>
            <div className="table-options-header">
                <div className="table-pagination-options">
                    <div className="page-size-option">
                        <p>Rows per Page</p>
                        <RoundedSelectField
                            options={pageSizes.map(v => ({ label: v.toString(), value: v }))}
                            onChange={handlePageSizeChange.bind(this)}
                            value={pageSize.toString()} />
                    </div>
                    <NumberIncrementSelector
                        selectedNumber={pageNumber.toString()}
                        updateValue={handlePage.bind(this)}
                        showNumber={true}
                    />
                </div>
            </div>
            <ReactTable className={`${tableStyles} ${props.className}`}
                data={props.data?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)}
                columns={props.columns}
                showPagination={false}
                onPageSizeChange={handlePageSizeChange.bind(this)}
                showPaginationBottom={false}
                pageSize={pageSize}
                getTrProps={props.getTrProps}
                minRows={1}
            />
        </div>
    );
}

const containerStyle = css`
    border-top:1px solid ${color_shades_dark};
    background: white;
    margin-bottom: 128px;
    .table-options-header {
        height: 56px;
        margin: 0 32px;
        display: flex;
        align-items: center;
        border-bottom:1px solid ${color_shades_dark};
        .table-pagination-options {
            margin-right: 0;
            margin-left: auto;
            display: flex;
            align-items: center;
            > * {
                margin-left: 16px;
            }
            .page-size-option {
                display: flex;
                align-items: center;
                >p {                                
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 8px;
                }
                >.selector {
                    .Select-control {
                        width: 80px;
                    }
                    .Select-value {
                        margin-left: 8px;
                    }
                }
            }

        }
    }
    ${breakpoint_small} {
        display: flex;
        flex-direction: column;
        width: 100%;
        .table-options-header {
            margin: 0;
            width: 100%;
            .table-pagination-options {
                width: 100%;
                align-items: center;
            }
        }
    }
`

const tableStyles = css`
    border: none;
    overflow: hidden;
    .rt-table {
        background: white;
        overflow: hidden;
        .rt-thead, .rt-thead -header {
            padding: 0 32px;
            box-shadow: none;
            border-bottom: solid 1px ${color_shades_dark};
            .rt-th {
                border: none;
                box-shadow: none;
                div {
                    height: 100%;
                }
            }
        }
        .rt-tbody {
            min-height: 64px;
            .rt-tr-group {
                min-height: 52px;
                border-bottom: solid 1px #cccccc;
                .rt-tr {
                    background: transparent;
                    padding: 0 32px;
                    border: none;
                    overflow: visible;
                }
            }
        }
        div.rt-td {
            background: transparent;
            align-self: center;
            overflow: visible;
        }
    }
    ${breakpoint_small} {
        div {
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;          
            text-overflow: ellipsis;    
        }
        .rt-table {
            .rt-thead, .rt-thead -header {
                padding: 0 16px;
            }
            .rt-tbody {
                .rt-tr-group {
                    .rt-tr {
                        padding: 0 16px;
                        display: flex;
                    }
                }
            }
        }
    }
`;
export default ReportTable;