import React from 'react';
import {css} from '@emotion/css';
import HorizontalSeparator from '../../structure/HorizontalSeparator';

interface TooltipProps {
    icon?: string
    elementName?: string
    attributeValue?: string
    attributeUnit?: string
    dataId?: string
}
const container = css`
    position: absolute;
    display: block;
    background: #304562;
    bottom: 100%;
    left: 50%; 
    min-width: 152px;
    letter-spacing: 0.8px;
    margin-left: -80px; 
    box-shadow: 0 2px 4px 0 rgba(48, 69, 98, 0.1);
    border-radius: 6px;
    padding: 12px 16px;
    >* {            
        color: white;
    }
    img {
        filter: brightness(100);
    }
    .triangle {
        box-shadow: 0 2px 4px 0 rgba(48, 69, 98, 0.1);
        position: absolute;
    }
    .ssml-item-header {
        display: flex;
        align-items: baseline;
        justify-content: center;
        .ssml-element-name {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.8px;
            color: #ffffff;
            text-transform: uppercase;
            color: white;
            margin: 0 8px;
        }
        .ssml-attribute-value {
            font-family: Muli;
            font-size: 18px;
            font-weight: 600;
            text-align: right;
            text-transform: uppercase;
            color: white;
            margin-right: 0;
            margin-left: auto;
            .attribute-unit {
                font-size: 12px;
            }
        }

    }
    .ssml-separator {
        height: 1px;
        background: white;
        margin: 6px 0;
    }
    .ssml-action-text {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: white;
        white-space: nowrap;
    }
`
const SsmlHoverTooltip: React.SFC<TooltipProps> = (props) => (
    <div contentEditable={false} className={container} data-id={props.dataId}>
        <div data-id={props.dataId}>
            <div className="ssml-item-header" data-id={props.dataId}>
                <img src={props.icon} data-id={props.dataId}/>
                <p className="ssml-element-name" data-id={props.dataId}>{props.elementName}</p>
                {props.attributeValue ? <p className="ssml-attribute-value" data-id={props.dataId}>{props.attributeValue} {props.attributeUnit ? <span className="attribute-unit">{props.attributeUnit}</span> : null }</p> : null}
            </div>
            <div className="ssml-separator" data-id={props.dataId}/>
            <p className="ssml-action-text" data-id={props.dataId}>Click to edit or remove</p>
        </div>
    </div>
);

export default SsmlHoverTooltip;