import React, { useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import DeploymentAppInfo from "../models/deployments/DeploymentAppInfo";
import PlatformCredentials from "../models/deployments/PlatformCredentials";

import DeploymentLink from "../models/applications/api/DeploymentLink";
import AlexaVendor from "../models/deployments/api/AlexaVendor";
import AlexaSkillResult from "../models/deployments/api/AlexaSkillResult";
import SkillCertificationSummary from "../models/deployments/api/SkillCertificationSummary";
import AmazonAuthUrlRequest from "../models/deployments/api/AmazonAuthUrlRequest";

export type DeploymentSelectionType = "new" | "existing";

function useAmazonDeploymentContainer() {
    type AmazonDeploymentStep = "linkingAccount" | "selectingVendor" | "linkingSkill" | "launch";

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [accountSelection, setAccountSelection] = useState("new" as DeploymentSelectionType);
    const [availableApps, setAvailableApps] = useState([] as DeploymentAppInfo[]);
    const [availableCredentials, setAvailableCredentials] = useState([] as PlatformCredentials[]);
    const [tokenId, setTokenId] = useState(null as string);
    const [linkedProjectId, setLinkedProjectId] = useState(null as string);
    const [userName, setUserName] = useState(null as string);
    const [createNew, setCreateNew] = useState("new" as DeploymentSelectionType);
    const [loadingAvailableApps, setLoadingAvailableApps] = useState(false);
    const [status, setStatus] = useState("linkingAccount" as AmazonDeploymentStep);
    const [vendorId, setVendorId] = useState(null as string);
    const [vendors, setVendors] = useState([] as AlexaVendor[]);
    const [loadingVendors, setLoadingVendors] = useState(false);
    const [currentSkillCertifications, setCurrentSkillCertifications] = useState([] as SkillCertificationSummary[]);
    const [availableSkills, setAvailableSkills] = useState([] as AlexaSkillResult[]);
    const [currentApplicationId, setCurrentApplicationId] = useState(null as string);
    const [currentEnvironmentId, setCurrentEnvironmentId] = useState(null as string);

    const reset = () => {
        setStatus("linkingAccount");
        setAccountSelection("new");
        setCreateNew("new");
        setLoadingAvailableApps(false);
        setAvailableApps([]);
        setAvailableCredentials([]);
        setTokenId (null);
        setLinkedProjectId (null);
        setUserName(null);
        setErrors([]);
        setVendorId(null);
        setVendors([]);
        setLoadingVendors(false);
        setCurrentSkillCertifications([]);
        setAvailableSkills([]);
        setCurrentApplicationId(null);
        setCurrentEnvironmentId(null);
    }

    const setDeployToApp = async (linkedProjectId: string) => {
        await setLinkedProjectId(linkedProjectId);
    }

    const setLinkingStatus = async (status: AmazonDeploymentStep) => {
        await setStatus(status);
    }

    const setAccountToLink = async (tokenId: string, userName: string) => {
        await setTokenId(tokenId);
        await setUserName(userName); 
    }

    const getAvailableCredentials = async (organizationId: string) => {
        try {
            const result = await voicifyApi.getAvailableAmazonCredentials(organizationId);
            if (result.resultType == "Ok") {
                await setAvailableCredentials(result.data.map(t =>
                    ({ id: t.id, userName: t.userName } as PlatformCredentials)));
            } else {
                await setErrors(result.errors);
            }
            return result;
        } catch (err) {
            setErrors([err.toString()]);
        }
    }

    const setAccountNewOrExisting = async (accountSelection: DeploymentSelectionType) => {
        if(accountSelection == "new")
        {
            await setTokenId(null);
        }
        await setAccountSelection(accountSelection);
    }

    const setCreateNewSkill = async (createNew: DeploymentSelectionType) => {
        await setCreateNew(createNew);
    }

    const getSkills = async (vendorId: string, tokenId: string) => {
        await setLoadingAvailableApps(true);
 
        return await voicifyApi.listSkills(vendorId, tokenId)
            .then(async result => {
                if (result.resultType == "Ok") {
                    const skills: AlexaSkillResult[] = result.data;
                    await setAvailableApps(skills.map((a: AlexaSkillResult) =>
                            ({ id: a.skillId, name: a.nameByLocale["en-US"] } as DeploymentAppInfo)));
                    await setAvailableSkills(skills);
                    await setLoadingAvailableApps(false);
                } else {
                    setErrors(result.errors);
                    setLoadingAvailableApps(false);
                }
                return result;
            }).catch(err => {
                setErrors([err.toString()]);
                setLoadingAvailableApps(false);
            })
    }

    const loadEnvironmentDeploymentInformation = async (applicationId: string, environmentId: string, organizationId: string) => {
        try {
            if (currentApplicationId === applicationId && currentEnvironmentId === environmentId)
                return; 

            await setIsLoading(true);
            await reset();
            const link = (await getEnvironmentDeploymentStatus(applicationId, environmentId)) as DeploymentLink;

            if (link.linkedProjectId && link.userName) {     
                await setLinkingStatus("launch");
                await setAccountNewOrExisting("existing");
                await setCreateNewSkill("existing");
                await loadEnvironmentSkillCertifications();
            } else if (link.vendorId && link.userName) {  
                await setLinkingStatus("linkingSkill");
                await setAccountNewOrExisting("existing");
            } else if (link.userName) {
                await setLinkingStatus("selectingVendor");
                await setAccountNewOrExisting("existing");
            }
    
            await getAvailableCredentials(organizationId);

            if (link.tokenId) {
                // we need a valid tokenId in order to retrieve vendors
                const vendorsResult = await getEnvironmentVendors(applicationId, environmentId);
                let envVendorId = link.vendorId;

                if (!envVendorId) {
                    // We do not have a vendor selected 
                    if (vendorsResult && vendorsResult.resultType == "Ok" && vendorsResult.data && vendorsResult.data.vendors) {
                        // Link new vendor automatically if single one was found
                        // If multiple vendors found then user will have to manually select a vendor from drop down
                        if (vendorsResult.data.vendors.length == 1) {
                            envVendorId = vendorsResult.data.vendors[0].id;

                            await linkEnvironmentVendor(applicationId, environmentId, envVendorId);
                            await setVendorId(envVendorId);
                            await setStatus("linkingSkill");
                        }
                    }
                    else {
                        await setErrors(["Unable to find environment vendors"]);
                    }
                }

                if (envVendorId) {
                    // We have a vendor
                    await getSkills(envVendorId, link.tokenId);
                }
            }

            await setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setErrors([err.toString()]);
        }
    }

    const getEnvironmentDeploymentStatus = async (applicationId: string, environmentId: string) => {
        try {
            await setCurrentApplicationId(applicationId);
            await setCurrentEnvironmentId(environmentId);
            // Need to clear ProjectId (AlexaSkillId), UserName, TokenId and VendorId before getEnvironmentDeploymentInfoForAmazon call
            await setLinkedProjectId(null);
            await setUserName(null); 
            await setTokenId(null);
            await setVendorId(null);
            
            const result = await voicifyApi.getEnvironmentDeploymentInfoForAmazon(applicationId, environmentId);
            if (result.resultType == "Ok") {
                const status: DeploymentLink = result.data;
                await setLinkedProjectId(status.linkedProjectId);
                await setUserName(status.userName); 
                await setTokenId(status.tokenId);
                await setVendorId(status.vendorId);

                await setErrors([]);
                
                return status;
            } else {
                await setErrors(result.errors);
            }
        } catch (err) {
            setErrors([err.toString()]);
        }
    };

    const linkEnvironment = async (applicationId: string, environmentId: string, skillId: string) => {
        try {
            const response = await voicifyApi.linkEnvironmentToAlexaSkill(applicationId, environmentId, skillId);
            if (response.resultType != "Ok") {
                await setErrors(response.errors);
            }
            else {
                await setLinkedProjectId(skillId);
            }
        }catch (err) {
            setErrors([err.toString()]);
        }
    }

    const createAndDeployEnvironmentNewSkill = async (applicationId: string, environmentId: string) => {
        try {
            await setStatus("launch"); 
            await setLoadingAvailableApps(true);
            const response = await voicifyApi.createAndDeployEnvironmentNewAlexaSkill(applicationId, environmentId);

            if (response.resultType == "Ok") {
                const skills = availableApps;
                skills.push(response.data);
                await setAvailableApps(skills);
                await setLinkedProjectId(response.data.id); 
                await setCreateNew("existing");
                await setLoadingAvailableApps(false);
            } else {
                await setLoadingAvailableApps(false);
                await setStatus("linkingSkill");
                await setErrors(response.errors);
            }
            return response;
        } catch (err) {
            setLoadingAvailableApps(false);
            setErrors([err.toString()]);
        }
    }
    
    const linkTokenToEnvironment = async (applicationId: string, environmentId: string, tokenId: string) => {
        try {
            const res = await voicifyApi.linkAmazonTokenToEnvironment(applicationId, environmentId, tokenId);
            if (res.resultType != "Ok") {
                setErrors(res.errors);
            }
            return res;
        } catch (err) {
            setErrors([err.toString()]);
        }
    }

    const loadEnvironmentSkillCertifications = async () => {
        try {
            if(linkedProjectId == null || currentApplicationId == null) 
                return;      

            var result = await voicifyApi.getAlexaSkillCertifications(currentApplicationId, currentEnvironmentId, linkedProjectId);
            if (result.resultType == "Ok") {
                await setCurrentSkillCertifications(result.data ?? []);
                return result.data;
            } else {
                await setCurrentSkillCertifications(result.data ?? []);
                await setErrors(result.errors);
            }
        } catch (err) {
            setErrors([err.toString()]);
        }
    }

    const getAuthUrl = async (request: AmazonAuthUrlRequest) => {
        try {
            const res = await voicifyApi.getAmazonAuthUrl(request)
            if (res.resultType != "Ok") {
                setErrors(res.errors);
            }
            return res;
        } catch (err) {
            setErrors([err.toString()]);
        }
    }

    const getEnvironmentVendors = async (applicationId: string, environmentId: string) => {
        await setLoadingVendors(true);
        await setCurrentApplicationId(applicationId);
        await setCurrentEnvironmentId(environmentId);

        const result = await voicifyApi.getEnvironmentAlexaVendors(applicationId, environmentId);

        if (result.resultType == "Ok") {
            await setVendors(result.data.vendors);
            await setLoadingVendors(false);
        }
        else {
            await setErrors(result.errors);
            await setLoadingVendors(false);
        }

        return result;
    }

    const linkEnvironmentVendor = (applicationId: string, environmentId: string, vendorId: string) => {
        return voicifyApi.linkEnvironmentAlexaVendor(applicationId, environmentId, vendorId)
            .then(async res => {
                if (res.resultType == "Ok") {
                    setVendorId(vendorId);
                    setStatus("linkingSkill");
                    setLinkedProjectId(null);
                }
                else {
                    setErrors(res.errors);
                }
                return res;
            })
            .catch(err => {
                setErrors([err.toString()]);
            })
    }

    const unlinkEnvironmentSkill = async (applicationId: string, environmentId: string) => {
        try {
            await setLinkedProjectId(null);
            await setStatus("linkingSkill");
            const res = await voicifyApi.unlinkAlexaSkillFromEnvironment(applicationId, environmentId);
            if (res.resultType != "Ok") {
                setErrors(res.errors);
            }
            return res;
        } catch (err) {
            setErrors([err.toString()]);
        }
    }

    const unlinkEnvironmentUserAccount = async (applicationId: string, environmentId: string) => {
        try {
            const res = await voicifyApi.removeAmazonAuthTokenFromEnvironment(applicationId, environmentId);
            if (res.resultType != "Ok") {
                setErrors(res.errors);
            }
            return res
        } catch (err) {
            setErrors([err.toString()]);
        }
    }

    const unlinkEnvironmentVendor = async (applicationId: string, environmentId: string) => {
        return voicifyApi.removeAlexaVendorFromEnvironment(applicationId, environmentId)
            .then(res => {
                if (res.resultType != "Ok") {
                    setErrors(res.errors);
                }
            })
            .catch(err => {
                setErrors([err.toString()]);
            })
    }

    const clearErrors = async () => {
        await setErrors([]);
    }

    return {
        reset,
        setDeployToApp,
        setLinkingStatus,
        setAccountToLink,
        setAccountNewOrExisting,
        getAvailableCredentials,
        loadEnvironmentDeploymentInformation,
        getEnvironmentDeploymentStatus,
        createAndDeployEnvironmentNewSkill,
        setCreateNewSkill,
        getAuthUrl,
        getSkills,
        getEnvironmentVendors,
        linkEnvironment, 
        linkEnvironmentVendor,
        linkTokenToEnvironment, 
        unlinkEnvironmentSkill, 
        unlinkEnvironmentUserAccount,
        unlinkEnvironmentVendor,
        setVendorId,
        currentEnvironmentId,
        status,
        createNew,
        accountSelection, 
        availableSkills, 
        currentSkillCertifications,
        linkedProjectId, 
        tokenId, 
        userName, 
        vendors,
        vendorId,
        availableCredentials, 
        availableApps,
        loadingAvailableApps,
        isLoading,
        errors,
        clearErrors
    };
}

const AmazonDeploymentContainer = createContainer(useAmazonDeploymentContainer);
export default AmazonDeploymentContainer;