import React from 'react';
import { css } from '@emotion/css';
import LinkedAudioPlayer from '../../models/ssml/LinkedAudioPlayer';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { cool_grey, highlight_blue } from '../../constants/colors';

const loadIcon = require('../../content/images/synchronize-arrow.svg');
const playIcon = require('../../content/images/play.svg');
const pauseIcon = require('../../content/images/button-pause.svg');

interface MultiFileAudioPlayerProps {
    src: string[]
    isLoading: boolean
    hasFile?: boolean
    onLoad: () => void
}

interface MultiFileAudioPlayerState {
    isPlaying: boolean
    elapsedTime: number
    previousElapsedTime: number
    currentElapsedTime: number
    totalTime: number
    linkedAudioPlayers: LinkedAudioPlayer
    audioTimes: AudioTime[]
}

type AudioTime = {
    src: string
    duration: number
}

const fileStyle = css`
    border-radius: 0 0 5px 5px;
`;

const ssmlStyle = css`
    border-radius: 5px;
`;

const containerStyle = css`
    height: 40px;
    margin-bottom: 12px;
    background-color: ${cool_grey};
    display: flex;
    align-items: center;
    padding: 0 12px;
    span {
        height: 13px;
        font-size: 10px;
        line-height: normal;
        letter-spacing: 0.4px;
        text-align: center;
        color: white;

        &.listen{
            cursor: pointer;
        }
    }
    .separator {
        height: 20px;
        width: 1px;
        background: white;
    }
    > * {
        margin-right: 8px;
    }
    > img {
        cursor: pointer;

        &.spin {
            @-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
            @-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
            @keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
            -webkit-animation:spin 1s linear infinite;
            -moz-animation:spin 1s linear infinite;
            animation:spin 1s linear infinite;
        }
    }
    .rc-slider-handle {
        border: none;
        &:focus, &:hover, &:active {
            border: 2px solid white;
            box-shadow: 0 0 5px ${highlight_blue};
        }
    }
`;

const sliderStyle = {
    flex: 1
};

const railStyle = {
    backgroundColor: '${color_shades_dark}'
};

const trackStyle = {
    backgroundColor: 'white'
};

class MultiFileAudioPlayer extends React.Component<MultiFileAudioPlayerProps, MultiFileAudioPlayerState> {
    constructor(props) {
        // Required step: always call the parent class' constructor
        super(props);
    
        // Set the state directly. Use props if necessary.
        this.state = {
            isPlaying: false,
            elapsedTime: 0,
            previousElapsedTime: 0,
            currentElapsedTime: 0,
            totalTime: 0,
            audioTimes: [],
            linkedAudioPlayers: null
        }
    }
    componentDidMount() {
        this.loadPlayers();
    }
    
    componentWillUnmount(){
        if(this.state.isPlaying == true)
        {
            this.player.pause();
            this.setState({
                ...this.state,
                isPlaying: false
            })
        }
    }

    private setAudioDuration(duration: number, player: HTMLAudioElement) {
        const audioTimes = this.state.audioTimes;
        const audioTime = audioTimes.find(a => a.src == player.src);
        if (audioTime) {
            audioTime.duration = duration;
        }
        else {
            audioTimes.push({
                src: player.src,
                duration: duration
            })
        }

        this.setState({
            ...this.state,
            audioTimes: audioTimes
        })
    }

    private getDuration(src: string): number {
        const audioItem = this.state.audioTimes.find(a => a.src == src);
        if (audioItem) return audioItem.duration;

        return 0;
    }
    loadPlayers() {
        if (!this.props.src) return;
        const players = this.props.src.map(s => {
            const audioPlayer = new Audio(s);
            audioPlayer.preload = "metadata";
            audioPlayer.onloadedmetadata = () => {
                this.setAudioDuration(audioPlayer.duration, audioPlayer);
                this.setState({
                    ...this.state,
                    totalTime: players.map(p => p.duration).reduce((total, value) => total + value)
                })
            }
            return audioPlayer;
        });
        this.setState({
            ...this.state,
            linkedAudioPlayers: this.createLinkedList(players),
            currentElapsedTime: 0,
            elapsedTime: 0,
            previousElapsedTime: 0,
            isPlaying: false
        })
    }

    componentDidUpdate(prevProps: MultiFileAudioPlayerProps) {
        if (prevProps.src != this.props.src) {
            // re-adjust total time
           this.loadPlayers();
        }
    }

    private createLinkedList(players: HTMLAudioElement[]): LinkedAudioPlayer {
        var top = {} as LinkedAudioPlayer
        for (var i = players.length - 1; i >= 0; i--) {
            if (i == players.length - 1) {
                top = {
                    src: players[i].src,
                    next: null
                }
            }
            else {
                var temp = {
                    src: players[i].src,
                    next: top
                };
                top = temp;
            }
        }

        return top;
    }

    handleListenClick() {
        if (this.props.src) {
            this.play(0);
        }
        else {
            this.props.onLoad();
        }
    }

    player: HTMLAudioElement = new Audio("");

    play(startTime: number) {

        if (this.state.linkedAudioPlayers) {
            this.setState({
                ...this.state,
                isPlaying: true
            })

            var currentPlayer = this.state.linkedAudioPlayers;
            var innerTime = 0;
            var totalTraversedTime = 0;
            if (startTime > 0) {
                // find the right item to play
                while (currentPlayer != null) {
                    var nextTraversedTime = totalTraversedTime + this.getDuration(currentPlayer.src);
                    if (startTime <= nextTraversedTime) {
                        innerTime = startTime - totalTraversedTime;
                        this.setState({
                            ...this.state,
                            currentElapsedTime: innerTime,
                            previousElapsedTime: totalTraversedTime,
                            elapsedTime: startTime
                        })
                        break;
                    }
                    else {
                        currentPlayer = currentPlayer.next;
                    }
                    totalTraversedTime = nextTraversedTime;
                }
            }
            if (currentPlayer == null) {
                currentPlayer = this.state.linkedAudioPlayers;
            }
            this.player.src = currentPlayer.src;

            this.player.currentTime = innerTime;

            this.player.ontimeupdate = () => this.handleTimeChange(totalTraversedTime);
                this.player.onended = () => {
                    if (currentPlayer.next) {
                        this.setState({
                            ...this.state,
                            currentElapsedTime: 0,
                            previousElapsedTime: this.state.elapsedTime
                        });
                        this.player.src = currentPlayer.next.src;
                        this.player.play();
                        currentPlayer = currentPlayer.next;
                    }
                    else if (this.state.elapsedTime == this.state.totalTime) {
                        this.setState({
                            ...this.state,
                            isPlaying: false,
                            currentElapsedTime: 0,
                            previousElapsedTime: 0,
                            elapsedTime: 0
                        })
                    }
                }
            this.player.play();
            this.setState({
                ...this.state,
                isPlaying: true
            });
        }
        else {
            alert("No audio to play");
        }
    }

    private handleTimeChange(totalTraversedTime: number) {
        var currentElapsedTime = this.player.currentTime;
        const previousTime = this.state.previousElapsedTime > 0 ? this.state.previousElapsedTime : totalTraversedTime;
        this.setState({
            ...this.state,
            currentElapsedTime: currentElapsedTime,
            elapsedTime: previousTime + currentElapsedTime
        });
    }


    pause() {
        this.player.pause();
        this.setState({
            ...this.state,
            isPlaying: false
        });
    }

    getElapsedTimeText(): string {
        if (this.state.elapsedTime > 0) {
            const minutes = Math.floor(this.state.elapsedTime / 60);
            const seconds = Math.floor(this.state.elapsedTime - (minutes * 60));
            return `${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`
        }
        else {
            return '--:--';
        }
    }

    getTotalTimeText(): string {
        if (this.state.totalTime > 0) {
            const minutes = Math.floor(this.state.totalTime / 60);
            const seconds = Math.floor(this.state.totalTime - (minutes * 60));
            return `${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`
        }
        else {
            return '--:--';
        }
    }

    handleSliderChange(value: number) {
        if (this.player) {
            this.player.pause();
        }
        this.setState({
            ...this.state,
            elapsedTime: value,
            currentElapsedTime: 0,
            previousElapsedTime: 0,
            isPlaying: false
        })
    }
    render() {
        return (
            <div className={`${containerStyle} ${this.props.hasFile ? fileStyle : ssmlStyle }`}>
                <span className="listen" onClick={this.handleListenClick.bind(this)}>LISTEN</span>
                <div className="separator" />
                {this.props.src ?
                    <>
                        {this.state.isPlaying ? <img src={pauseIcon} onClick={this.pause.bind(this)} /> : <img src={playIcon} onClick={() => this.play(this.state.elapsedTime)} />}
                    </>
                    : <img src={loadIcon} className={this.props.isLoading ? "spin" : ""} onClick={this.props.onLoad} />}
                <span>{this.getElapsedTimeText()}</span>
                <Slider
                    style={sliderStyle}
                    railStyle={railStyle}
                    trackStyle={[trackStyle]}
                    onChange={this.handleSliderChange.bind(this)}
                    min={0}
                    step={0.01}
                    max={this.state.totalTime}
                    value={this.state.elapsedTime} />
                <span>{this.getTotalTimeText()}</span>
            </div>
        )
    }
}

export default MultiFileAudioPlayer;