import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';

const alertTriangle = require('../../content/images/alert-triangle-red.svg');
const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 64px;
    img {
        width: 82px;
        height: 82px;
        margin-bottom: 32px;
    }

    p {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${dark_grey_blue};
    }
`

interface RemoveConfirmationProps {
    memberName?: string
    customText?: React.ReactNode
    showImage?: boolean
    removalTarget?: string
}

const RemoveConfirmation: React.FC<RemoveConfirmationProps> = (props) => (
    <div className={containerStyle}>
        {(props.showImage === undefined || props.showImage === true) ? <img src={alertTriangle} /> : null}
        {props.customText === undefined ?
            <p>
                Are you sure you want to remove
            <b> {props.memberName} </b>
                from {props.removalTarget ?? "this organization"}?
        </p>
            :
            props.customText
        }
    </div>
)

export default RemoveConfirmation;