import React, { useEffect, useRef } from 'react'
import FooterBar from '../../../../../shared/components/structure/FooterBar'
import ModalLayout from '../../../../../shared/components/structure/ModalLayout'
import styled from '@emotion/styled'
import Button from '../../../../../shared/components/general/Button'
import { css } from '@emotion/css'
import ModalHeader from '../../../../../shared/components/general/ModalHeader'
import { AutoGenerateFormFieldResponsesFormData } from './AutoGenerateFormFieldResponsesForm'
import MediaContainer from '../../../../../shared/state/containers/MediaContainer'
import AppliedApplicationTemplateContainer from '../../../../../shared/state/containers/AppliedApplicationTemplateContainer'
import CustomScrollbars from '../../../../../shared/components/structure/CustomScrollbars'
import useWindowSize from '../../../../../shared/hooks/UseWindowSize'
import { color_shades_darker, color_text_default, dark_grey_blue } from '../../../../../shared/constants/colors'
import { FormikProps } from 'formik'
import AutoGenerateFormFieldInformationGathering from './AutoGenerateFormFieldInformationGathering'
import FullScreenLayout from '../../../../../shared/scenes/fullScreenLayout'
import { breakpoint_small } from '../../../../../shared/constants/breakpoints'

export interface AutoGenerateFormFieldResponsesModalProps {
    applicationId: string
    mediaContainer: MediaContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    isLoading: boolean
    setIsLoading: (value: boolean) => void
    onClose: () => void
}


const AutoGenerateFormFieldResponsesModal: React.FC<AutoGenerateFormFieldResponsesModalProps & FormikProps<AutoGenerateFormFieldResponsesFormData>> = (props) => {
    const containerRef = useRef(null);

    const MODAL_SCROLLBAR_SUBTRACTION: number = 250;
    const windowSize = useWindowSize();

    useEffect(() => {
        if (containerRef)
            containerRef.current.focus();
    }, []);

    useEffect(() => {
        if (props.appliedAppTemplateContainer && props.appliedAppTemplateContainer.state.appliedTemplates.length > 0) {
            if (props.appliedAppTemplateContainer.state.appliedTemplates[0]?.generativeAiContexts?.length > 0) {
                const generativeAiContexts = props.appliedAppTemplateContainer.state.appliedTemplates[0].generativeAiContexts;
                const lastGenerativeAiContexts = generativeAiContexts[generativeAiContexts.length - 1];
                if (lastGenerativeAiContexts.fileUrl?.length > 0) {
                    props.setFieldValue("usefileUpload", true);
                    props.setFieldValue("fileUrl", lastGenerativeAiContexts.fileUrl);
                    props.setFieldValue("webPageUrls", []);
                } else {
                    if (lastGenerativeAiContexts.webPageUrls?.length > 0) {
                        const lastUrl = lastGenerativeAiContexts.webPageUrls[lastGenerativeAiContexts.webPageUrls.length - 1];
                        props.setFieldValue("webPageUrls", [lastUrl]);
                        props.setFieldValue("fileUrl", null);
                    }
                }
            }
        }
    }, [props.appliedAppTemplateContainer.state.appliedTemplates])

    return (
        <div ref={containerRef}>
            {/* <ModalLayout isVisible={true}> */}
            <AutoGenerateFormFieldResponsesModalStyled isVisible={true} onClose={props.onClose}>
                <ModalHeader title="Auto generate answers" onClose={props.onClose} />
                <form onSubmit={(e) => e.preventDefault()}>
                    <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight) - MODAL_SCROLLBAR_SUBTRACTION}>
                        <FormBodyWrapper>
                            <AutoGenerateFormFieldInformationGathering
                                {...props}
                                handleFileUrlFieldChange={(url: string) => {
                                    props.setFieldValue("fileUrl", url);
                                    props.setFieldValue("webPageUrls", []);
                                }}
                                handleWebPageUrlChange={e => {
                                    const url = e.target.value;
                                    props.setFieldValue("webPageUrls", [url]);
                                    props.setFieldValue("fileUrl", null);
                                }}
                                setUseFileUpload={(value: boolean) => props.setFieldValue("usefileUpload", value)}
                            />
                        </FormBodyWrapper>
                    </CustomScrollbars>
                    <FooterBar className={footerStyle}>
                        <div>
                            <StyledButton
                                themes={['primary', 'start']}
                                text="Submit"
                                loading={props.isLoading}
                                onClick={() => props.handleSubmit()}
                            />
                        </div>
                        <StyledButton
                            themes={['secondary']}
                            text="Cancel"
                            onClick={props.onClose}
                        />
                    </FooterBar>
                </form>
            </AutoGenerateFormFieldResponsesModalStyled>
        </div>
    )
}

export default AutoGenerateFormFieldResponsesModal;

const AutoGenerateFormFieldResponsesModalStyled = styled(ModalLayout)`
    color: ${color_text_default};
    height: 850px;
    width: 750px;
    min-width: 30%;
    min-height: 30%;
    ${breakpoint_small} {
        height: calc(100vh - 100px);
    }

`;

const StyledButton = styled(Button)`
    margin: 0px;
    margin-left: 10px;

    @media(max-width: 900px){
        font-size: 15px;
        padding: 0px 10px 0px 10px;
    }

    @media(max-width: 500px){
        font-size: 13px;
        padding: 0px 10px 0px 10px;
        margin-top: 10px;
    }
`;

const FormBodyWrapper = styled.div`
    padding: 20px 30px 0px 30px;
`;


const footerStyle = css`
    display: flex;
    justify-content: space-between;
    padding-right: 10px;

    @media(max-width: 500px){
        height: auto;
        padding: 10px;
    }
`;