import { css } from '@emotion/css';
import React from 'react';
import { TabList, Tabs } from 'react-tabs';
import { dark_grey_blue } from '../../../constants/colors';
import { ImageTabItem } from '../ImageInnerTabs';
import PanelHeaderContainer from './PanelHeaderContainer';
import PanelIconTab from './PanelIconTab';

// used for containing data in the form style card

interface TabContainerPanelProps {
    tabs: ImageTabItem[]
    selectedIndex?: number
    forceRenderTabPanel?: boolean
    onTabSelected?: (index: number, last: number) => void
    className?: string
    additionalHeaderContent?: () => JSX.Element
}

const TabContainerPanel: React.FC<TabContainerPanelProps> = (props) => {

    function renderTab(tab: ImageTabItem, index) {
        return PanelIconTab({
            tab: tab,
            index: index,
            selectedIndex: props.selectedIndex,
        });
    }

    return (
        <div className={`${panelStyle} ${props.className != null ? props.className : ""}`}>
            <Tabs forceRenderTabPanel={props.forceRenderTabPanel} defaultIndex={props.selectedIndex} onSelect={props.onTabSelected}>
                <PanelHeaderContainer>
                    <TabList className="panel-tab-list">
                        {props.tabs.map(renderTab)}
                    </TabList>
                    {props.additionalHeaderContent ? props.additionalHeaderContent() : null}
                </PanelHeaderContainer>
                {props.children}
            </Tabs>
        </div>
    )
    
};

const panelStyle = css`
    position: relative;
    font-family: Muli;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    overflow:hidden;
    flex: 1;
    .panel-tab-list {
        color: white;
        list-style-type: none;
        display: flex; 
        align-items: center;
        margin-left: 28px;
    }
`;

export default TabContainerPanel;