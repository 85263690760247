import React, { useState } from "react"
import { createContainer } from "unstated-next"

function useSparkContainer() {
    const [inSpark, setInSpark] = useState(false);

    return {
        setInSpark,
        inSpark
    };
}

const SparkContainer = createContainer(useSparkContainer);
export default SparkContainer;
