import React from 'react';
import { css } from '@emotion/css';
import { color_colors_growth, color_colors_royalty, color_variants_royalty_light_20 } from '../../../constants/colors';

interface TreePathProps {
    direction: PathDirection
    active: boolean
    offset: number
    containerWidth: number
    width: number
    height: number
    lineSize: number
}
export type PathDirection = "down-left" | "down-right" | "up-left" | "up-right";

const TreePath: React.SFC<TreePathProps> = (props) => {
    const svgStyle = css`
        position: absolute;
        margin-bottom: 16px;
        width: ${props.containerWidth}px;
        height: ${Math.abs(props.height)}px;
        overflow: visible;
        ${props.active ? 'z-index: 2;' : ''}
        path {
            stroke: ${props.active ? color_colors_royalty : color_variants_royalty_light_20};
            stroke-width: ${props.lineSize + 1}px;
            fill: transparent;
        }
    `

    const center = (props.containerWidth / 2) - props.offset;

    // special case for straight line line up
    if (props.width == 0) {
        return (<svg viewBox={`0, 0, ${props.containerWidth}, ${props.height}`} className={`${svgStyle} ${props.direction}`}>
            <path d={`
                M ${center},0
                L ${center},${props.height}
            `} />
        </svg>)
    }

    return (<svg viewBox={`0, 0, ${props.containerWidth}, ${props.height}`} className={`${svgStyle} ${props.direction}`}>
        {props.direction == "down-right" &&
            <path d={`
            M ${center},0
            L ${center},${props.height / 4}
            Q ${center},${props.height / 4 + 10}
              ${center + 10},${props.height / 4 + 10}
            L ${center + props.width - 10},${props.height / 4 + 10}
            Q ${center + props.width},${props.height / 4 + 10}
              ${center + props.width},${props.height / 4 + 20}
            L ${center + props.width},${props.height}
        `} />
        }
        {props.direction == "down-left" &&
            <path d={`
            M ${center},0
            L ${center},${props.height / 4}
            Q ${center},${props.height / 4 + 10}
              ${center - 10},${props.height / 4 + 10}
            L ${center + props.width + 10},${props.height / 4 + 10}
            Q ${center + props.width},${props.height / 4 + 10}
              ${center + props.width},${props.height / 4 + 20}
            L ${center + props.width},${props.height}
        `} />
        }
        {props.direction == "up-left" &&
            <path d={`
            M ${center},${props.height}
            L ${center},${props.height / 4 + 20}
            Q ${center},${props.height / 4 + 10}
              ${center - 10},${props.height / 4 + 10}
            L ${center + props.width + 10},${props.height / 4 + 10}
            Q ${center + props.width},${props.height / 4 + 10}
              ${center + props.width},${props.height / 4}
            L ${center + props.width},0
        `} />
        }
        {props.direction == "up-right" &&
            <path d={`
            M ${center},${props.height}
            L ${center},${props.height / 4 + 20}
            Q ${center},${props.height / 4 + 10}
              ${center + 10},${props.height / 4 + 10}
            L ${center + props.width - 10},${props.height / 4 + 10}
            Q ${center + props.width},${props.height / 4 + 10}
              ${center + props.width},${props.height / 4}
            L ${center + props.width},0
        `} />
        }
    </svg>)
}


export default TreePath;
