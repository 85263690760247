import axios from 'axios';
import { GET_WELCOMEMESSAGES, ADD_WELCOMEMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import WelcomeMessageModel from '../../models/features/api/Welcome/WelcomeMessageModel';
import NewWelcomeMessageRequest from '../../models/features/api/Welcome/NewWelcomeMessageRequest';
import UpdateWelcomeMessageRequest from '../../models/features/api/Welcome/UpdateWelcomeMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import { parsePath } from 'history';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getWelcomeMessagesForApp = (applicationId: string): Promise<IResult<WelcomeMessageModel[]>> => {
    return sendGet<WelcomeMessageModel[]>(`${GET_WELCOMEMESSAGES}/${applicationId}`);
}

export const getWelcomeMessagesForAppFeature = (applicationFeatureId: string): Promise<IResult<WelcomeMessageModel[]>> => {
    return sendGet<WelcomeMessageModel[]>(`${GET_WELCOMEMESSAGES}/byFeature/${applicationFeatureId}`);
}

export function addWelcomeMessage(applicationId: string, model: NewWelcomeMessageRequest): Promise<IResult<WelcomeMessageModel>>{
    return sendPost<WelcomeMessageModel>(`${ADD_WELCOMEMESSAGE}/${applicationId}`, model);
}
export function addFullWelcomeMessage(model: WelcomeMessageModel): Promise<IResult<WelcomeMessageModel>>{
    return sendPost<WelcomeMessageModel>(`${ADD_WELCOMEMESSAGE}/full`, model);
}


export const toggleWelcomeMessageLive = (welcomeMessageId: string, isLive: boolean) : Promise<IResult<WelcomeMessageModel>> => {
    return sendPut<WelcomeMessageModel>(`/WelcomeMessage/${welcomeMessageId}/isLive`, {
        isLive
    });
}
export const toggleWelcomeMessageSync = (welcomeMessageId: string, shouldNotSync: boolean): Promise<IResult<WelcomeMessageModel>> => {
    return sendPut<WelcomeMessageModel>(`/WelcomeMessage/${welcomeMessageId}/shouldNotSync`, {
        shouldNotSync
    });
}


export const updateWelcomeMessage = (welcomeMessageId: string, model: UpdateWelcomeMessageRequest) : Promise<IResult<WelcomeMessageModel>> => {
    return sendPut<WelcomeMessageModel>(`/WelcomeMessage/${welcomeMessageId}/update`, model);
}
export const updateFullWelcomeMessage = (welcomeMessageId: string, model: WelcomeMessageModel) : Promise<IResult<WelcomeMessageModel>> => {
    return sendPut<WelcomeMessageModel>(`/WelcomeMessage/${welcomeMessageId}/full`, model);
}

export const deleteWelcomeMessage = (welcomeMessageId: string) : Promise<IResult<WelcomeMessageModel>> => {
    return sendDelete<WelcomeMessageModel>(`/WelcomeMessage/${welcomeMessageId}`);
}
export const findWelcomeMessage = (welcomeMessageId: string) : Promise<IResult<WelcomeMessageModel>> => {
    return sendGet<WelcomeMessageModel>(`/WelcomeMessage/full/${welcomeMessageId}`);
}
export const getWelcomeMessageWebhooks = (welcomeMessageId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/WelcomeMessage/${welcomeMessageId}/webhooks`);
}

export const removeWelcomeMessageWebhook = (welcomeMessageWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/WelcomeMessage/webhook/${welcomeMessageWebhookId}`);
}

export const addWelcomeMessageWebhook = (welcomeMessageId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/WelcomeMessage/${welcomeMessageId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateWelcomeMessageWebhook = (welcomeMessageId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/WelcomeMessage/${welcomeMessageId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const copyWelcomeMessage = (contentItemId: string): Promise<IResult<WelcomeMessageModel>> => {
    return sendPost<WelcomeMessageModel>(`/WelcomeMessage/${contentItemId}/copy`, null);
}

export const bulkUpdateWelcomeMessageWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/WelcomeMessage/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getWelcomeMessagePublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/WelcomeMessage/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}