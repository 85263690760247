import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, dark_grey_blue } from '../../constants/colors';

export type Direction = 'up' | 'down' | 'left' | 'right';
type ToggleIndicatorType = 'triangle' | 'chevron';

interface ToggleIndicatorProps {
    direction: Direction
    type?: ToggleIndicatorType
    onClick?: () => void
    className?: string
    disabled?: boolean
}

const disabledChevronStyle = css`
    width: 4px;
    height: 4px;
    border-left: solid 1px ${color_shades_dark};
    border-top: solid 1px ${color_shades_dark};
    &.down {
        transform: rotate(-135deg);
    }
`

const chevronStyle = css`
    width: 4px;
    height: 4px;
    border-left: solid 1px ${dark_grey_blue};
    border-top: solid 1px ${dark_grey_blue};
    &.up {
        transform: rotate(45deg);
    }

    &.down {
        transform: rotate(-135deg);
    }

    &.left {
        transform: rotate(-45deg);
    }

    &.right {
        transform: rotate(135deg);
    }
`

const triangleStyle = css`
    .down {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid #324661;
    }
    .up {
        width: 0; 
        height: 0; 
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid #324661;
    }
    .right {
        width: 0; 
        height: 0; 
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 3px solid #324661;
        margin-right: 3px;
    }
    width: 6px;
    height: 6px;
`

const ToggleIndicator: React.FC<ToggleIndicatorProps> = (props) => {
    const type: ToggleIndicatorType = props.type ?? 'chevron';
    switch (type) {
        case ('chevron'):
            return (
                <div className={`toggle-indicator ${props.disabled ? disabledChevronStyle : chevronStyle} ${props.direction} ${props.className}`} onClick={props.onClick}>
                </div>
            )
        case ('triangle'):
            return (
                <div className={`${triangleStyle} ${props.className}`} onClick={props.onClick}>
                    <div className={props.direction} />
                </div>
            )
    }
}

export default ToggleIndicator;