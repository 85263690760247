import React from 'react';
import { css } from '@emotion/css';
import { ImageTabItem } from '../ImageInnerTabs';
import { Tab } from 'react-tabs';
import TabIndicator from '../TabIndicator';
import { silver_two, dark_sky_blue, ocean_blue, color_text_light, color_text_link, color_variants_ocean_light_opaque, color_text_default, color_shades_darkest, color_shades_dark } from '../../../constants/colors';
import TooltipWrapper from '../../general/TooltipWrapper';

interface ImageTabProps {
    tab: ImageTabItem
    index: number
    className?: string
    selectedIndex: number
}
function PanelIconTab(props: ImageTabProps) {
    return (
        <Tab key={props.index} selectedClassName={activeTabStyle} className={`${tabStyle} ${props.className ? props.className : ''}`}>
            <div className={`tab-wrapper ${props.tab.className ?? ''}`}>
                <div className="tab-content">
                    <img className="tab-icon" src={props.selectedIndex == props.index ? props.tab.selectedIcon : props.tab.icon} />
                    <p>{props.tab.title}</p>
                </div>
            </div>
        </Tab>
    )
}
const tabStyle = css`
color: black;
cursor: pointer;
flex-grow: 1;
align-items: center;
margin: 0 4px;
background: white;    
color: ${color_shades_darkest};
min-width: 80px;
flex: 0;
.tab-wrapper {
    display: flex;
    align-items: center;
}
.tab-content {
    min-width: 48px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 54px;
    p {
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: ${color_text_default};
    }
    img {
        width: 24px;
        height: auto;
    }
}
.tab-indicator {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
}

`;

const activeTabStyle = css`
background-color: ${color_variants_ocean_light_opaque};

>.tab-indicator {
    display: block;
}

.tab-content {
    p {
        color: ${color_text_link};
    }
}
`;

export default PanelIconTab;