import React from 'react';
import { css } from '@emotion/css';
import LevelSelector from './LevelSelector';
import ssmlParseService from '../../../services/ssmlParseService';
import SsmlParseService from '../../../services/ssmlParseService';

interface SpeedSelectorProps {
    onChange: (ssml: string, placeholder?: string) => void
    ssml?: string
}

interface SpeedSelectorState {
    value: number
    isCustomSelected: boolean

}

class SpeedSelector extends React.Component<SpeedSelectorProps, SpeedSelectorState> {
    private ssmlParser = new SsmlParseService()
    private placeholder: string = 'Speed changed text'
    private options = [
        { name: 'Very Slow', value: 20 },
        { name: 'Slow', value: 50 },
        { name: 'Medium', value: 100 },
        { name: 'Fast', value: 150 },
        { name: 'Very Fast', value: 200 },
    ];
    componentWillMount() {
        this.setInitialState();
        // send out the default ssml when it is first added
        var ssml = this.props.ssml ? this.props.ssml : `<prosody rate="50%">${this.placeholder}</prosody>`;
        this.props.onChange(ssml, this.placeholder);
    }

    private setInitialState() {
        if (this.props.ssml) {
            // parse the break tag to set state
            const rate = this.ssmlParser.getElementProperty(this.props.ssml, 'prosody', 'rate')
            if (rate) {
                this.setState({
                    ...this.state,
                    isCustomSelected: !this.options.some(o => o.value + '%' == rate),
                    value: rate.replace('%', '')
                })
                return;
            }
        }
        this.setState({
            ...this.state,
            value: 50,
            isCustomSelected: false
        })
    }

    buildSsml(value?: number) {
        value = value ? value : this.state.value
        const ssml = `<prosody rate="${value}%">${this.placeholder}</prosody>`;
        this.props.onChange(ssml, this.placeholder);
    }

    handleCustomSelected() {
        this.setState({
            ...this.state,
            isCustomSelected: true
        })
        this.buildSsml();
    }

    handleOptionSelected(option) {
        this.setState({
            ...this.state,
            isCustomSelected: false,
            value: option.value
        })
        this.buildSsml(option.value);
    }

    handleCustomChanged(value: number) {
        this.setState({
            ...this.state,
            isCustomSelected: true,
            value: value
        })
        this.buildSsml(value);
    }

    render() {
        return (
            <div>
                <LevelSelector options={this.options}
                    onCustomSelected={this.handleCustomSelected.bind(this)}
                    onOptionSelected={this.handleOptionSelected.bind(this)}
                    onCustomValueChanged={this.handleCustomChanged.bind(this)}
                    value={this.state.value}
                    unit='%'
                    unitDetail='Min. 20'
                    isCustom={this.state.isCustomSelected}
                />
            </div>
        )
    }
}

export default SpeedSelector;