import React from 'react';
import { Subscribe } from 'unstated';
import { ConversationTrackingTreeBaseNode } from '../../models/analytics/api/ConversationEventTrackingTreeNode';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import CustomRequestContainer from '../../state/containers/CustomRequestContainer';
import ExitMessageContainer from '../../state/containers/ExitMessageContainer';
import FallbackMessageContainer from '../../state/containers/FallbackMessageContainer';
import HelpMessageContainer from '../../state/containers/HelpMessageContainer';
import LatestMessageContainer from '../../state/containers/LatestMessageContainer';
import NumberRangeContainer from '../../state/containers/NumberRangeContainer';
import QuestionAnswerContainer from '../../state/containers/QuestionAnswerContainer';
import SimpleChoiceContainer from '../../state/containers/SimpleChoiceContainer';
import WelcomeMessageContainer from '../../state/containers/WelcomeMessageContainer';
import ScriptPreview from './components/ScriptPreview';

interface ScriptProps {
    nodes: ConversationTrackingTreeBaseNode[]
    applicationId: string
    hasStart: boolean,
}

const ConversationScript: React.SFC<ScriptProps> = (props) => (
    <Subscribe to={[
        WelcomeMessageContainer,
        HelpMessageContainer,
        FallbackMessageContainer,
        ExitMessageContainer,
        QuestionAnswerContainer,
        LatestMessageContainer,
        SimpleChoiceContainer,
        NumberRangeContainer,
        CustomRequestContainer,
        ApplicationContainer]}>
        {(welcomeContainer: WelcomeMessageContainer,
            helpContainer: HelpMessageContainer,
            fallbackContainer: FallbackMessageContainer,
            exitContainer: ExitMessageContainer,
            questionAnswerContainer: QuestionAnswerContainer,
            latestMessageContainer: LatestMessageContainer,
            simpleChoiceContainer: SimpleChoiceContainer,
            numberRangeContainer: NumberRangeContainer,
            customRequestContainer: CustomRequestContainer,
            appContainer: ApplicationContainer) => (
                <ScriptPreview welcomeContainer={welcomeContainer}
                    helpContainer={helpContainer}
                    fallbackContainer={fallbackContainer}
                    exitContainer={exitContainer}
                    questionAnswerContainer={questionAnswerContainer}
                    latestMessageContainer={latestMessageContainer}
                    simpleChoiceContainer={simpleChoiceContainer}
                    numberRangeContainer={numberRangeContainer}
                    customRequestContainer={customRequestContainer}
                    appContainer={appContainer}
                    applicationId={props.applicationId}
                    nodes={props.nodes}
                    hasStart={props.hasStart} />
            )}
    </Subscribe>
);

export default ConversationScript;