import React, { useEffect, useState } from 'react';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import ApplicationAnalyticsCharts from './ApplicationAnalyticsCharts';
import ApplicationUserAnalyticsContainer from '../../../state/containers/ApplicationUserAnalyticsContainer';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';

interface ApplicationUserChartsProps {
    stateContainer: ApplicationUserAnalyticsContainer
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string
}

const ApplicationUserCharts = (props: ApplicationUserChartsProps) => {
    useEffect(() => {
        loadChartData(); 
    }, [props.interval, props.filter])

    const [loading, setLoading] = useState(false);
    const loadChartData = async () => {
        if(loading) return;
        await setLoading(true);
        props.stateContainer.loadPrimaryBreakdown(
            {applicationId: props.applicationId},
            props.filter,
            props.stateContainer.state.currentBreakdown)

        props.stateContainer.loadSecondaryBreakdown(
            {applicationId: props.applicationId},
            props.filter,
            props.stateContainer.state.currentBreakdown)

        await props.stateContainer.loadPrimaryHistogram(
            {applicationId: props.applicationId},
            props.innerInterval,
            props.filter
        )
        await props.stateContainer.loadSecondaryHistogram(
            {applicationId: props.applicationId},
            props.innerInterval,
            props.filter
        );
        await props.stateContainer.getAverages(
            {applicationId: props.applicationId},
            props.filter)
        await setLoading(false);
    }

    return (
        <ApplicationAnalyticsCharts
            applicationId={props.applicationId}
            filter={props.filter}
            innerInterval={props.innerInterval}
            interval={props.interval}
            dataType='Users'
            stateContainer={props.stateContainer}
            showPrimary={props.stateContainer.state.currentDisplayOption?.toLocaleLowerCase()?.indexOf("total") > -1}
            showSecondary={props.stateContainer.state.currentDisplayOption?.toLowerCase()?.indexOf("new") > -1}
            secondaryDataName="New Users"
            primaryDataName="Total Users" />
    )
    
}
export default ApplicationUserCharts;