import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue_10, silver_three, denim_blue, color_highlights_blue, color_text_default } from '../../constants/colors';

interface UserAvatarProps {
    src?: string
    firstName: string
    lastName: string
    size: number
    className?: string
    noBackground?: boolean
    noImageText?: string
    noShadow?: boolean
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => (
    <div className={`${containerStyle} ${props.noShadow == true ? '' : shadowStyle} ${props.className ?? ''}`} style={{ width: props.size, height: props.size, minWidth: props.size, minHeight: props.size }}>
        {
            props.src ?
            <img className={generateIconStyle(props)} src={props.src} />
            : 
            <div className={generateNameStyle(props)}>
                <span>{props.noImageText ?? `${props.firstName == null ? "N" : props.firstName[0]} ${props.lastName == null ? "A" : props.lastName[0]}`}</span>
            </div>
        }
    </div>
)

const containerStyle = css`
    border-radius: 50%;
    border: solid 1px ${silver_three};
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    box-sizing: border-box;
`;

const shadowStyle = css`
    box-shadow: 0 2px 4px 0 ${dark_grey_blue_10};
`;

const generateIconStyle = (props: UserAvatarProps): string=> {
    var size = props.size - 8; //size is 8 less than container
    if(props.noBackground)
        size = props.size;
    return css`
        border-radius: 50%;
        width: ${size}px;
        height: ${size}px;
        box-sizing: border-box;
    `;
}

const generateNameStyle = (props: UserAvatarProps): string => {
    var size = props.size - 8; //size is 8 less than container
    if(props.noBackground)
        size = props.size;

    return css`
        border-radius: 50%;
        color: ${color_text_default};
        background: ${color_highlights_blue};
        font-family: Muli;
        font-size: ${size/3}px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -1px;
        text-align: center;
        width: ${size}px;
        height: ${size}px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    `;
}

export default UserAvatar;

