import React from 'react';
import { css } from '@emotion/css';
import LevelSelector from './LevelSelector';
import ssmlParseService from '../../../services/ssmlParseService';
import SsmlParseService from '../../../services/ssmlParseService';

interface PitchSelectorProps {
    onChange: (ssml: string, placeholder?: string) => void
    ssml?: string
}

interface PitchSelectorState {
    value: number
    isCustomSelected: boolean

}

class PitchSelector extends React.Component<PitchSelectorProps, PitchSelectorState> {
    private ssmlParser = new SsmlParseService()
    private placeholder: string = 'Pitch changed text'
    private options = [
        { name: 'Very Low', value: -33 },
        { name: 'Low', value: -10 },
        { name: 'Medium', value: 0 },
        { name: 'High', value: 25 },
        { name: 'Very High', value: 50 },
    ]
    componentWillMount() {
        this.setInitialState();
        // send out the default ssml when it is first added
        var ssml = this.props.ssml ? this.props.ssml : `<prosody pitch="+25%">${this.placeholder}</prosody>`;
        this.props.onChange(ssml, this.placeholder);
    }

    private setInitialState() {
        if (this.props.ssml) {
            // parse the break tag to set state
            const pitch = this.ssmlParser.getElementProperty(this.props.ssml, 'prosody', 'pitch')
            if (pitch) {
                this.setState({
                    ...this.state,
                    isCustomSelected: !this.options.some(o => o.value+"%" == pitch.replace('+', '')),
                    value: pitch.replace('%', '').replace('+', '')
                })
                return;
            }
        }
        this.setState({
            ...this.state,
            value: 25,
            isCustomSelected: false
        })
    }

    buildSsml(value?: number) {
        value = value ? value : this.state.value
        var valueLabel = "" + value

        // need explicit "+"
        if (value > 0) valueLabel = "+" + valueLabel

        const ssml = `<prosody pitch="${valueLabel}%">${this.placeholder}</prosody>`;
        this.props.onChange(ssml, this.placeholder);
    }

    handleCustomSelected() {
        this.setState({
            ...this.state,
            isCustomSelected: true
        })
        this.buildSsml();
    }

    handleOptionSelected(option) {
        this.setState({
            ...this.state,
            isCustomSelected: false,
            value: option.value
        })
        this.buildSsml(option.value);
    }

    handleCustomChanged(value: number) {
        this.setState({
            ...this.state,
            isCustomSelected: true,
            value: value
        })
        this.buildSsml(value);
    }

    render() {
        return (
            <div>
                <LevelSelector options={this.options}
                    onCustomSelected={this.handleCustomSelected.bind(this)}
                    onOptionSelected={this.handleOptionSelected.bind(this)}
                    onCustomValueChanged={this.handleCustomChanged.bind(this)}
                    value={this.state.value}
                    unit='%'
                    isCustom={this.state.isCustomSelected}
                />
            </div>
        )
    }
}

export default PitchSelector;