import React from 'react';
import { css } from '@emotion/css';
import TreePath from './TreePath';
import { ConversationTrackingTreeBaseNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import _ from 'lodash';

interface TreePathsProps {
    prevIndex: number,
    prevLength: number,
    nodes: ConversationTrackingTreeBaseNode[]
    activeIndex?: number
    isUp?: boolean
    nodeOffset: number
    prevNodeOffset: number
}

const NODE_WIDTH = 144; // the width of a node including margins

const DownTreePaths: React.SFC<TreePathsProps> = (props) => {
    const directionMultiplier = (length: number, index: number, prevLength: number, prevIndex: number) => {
        const currentPositionOffset = (length / 2) - index + props.nodeOffset;
        const prevPositionOffset = (prevLength / 2) - prevIndex + props.prevNodeOffset;

        return prevPositionOffset - currentPositionOffset;
    }
    const totalWidth = NODE_WIDTH * (props.prevLength > props.nodes.length ? props.prevLength : props.nodes.length) * ((Math.abs(props.prevNodeOffset) > Math.abs(props.nodeOffset) ? Math.abs(props.prevNodeOffset) : Math.abs(props.nodeOffset) + 1))
    const totalSessions = props.nodes.length == 0 ? 0 : props.nodes.map(n => n.sessionIds?.length ?? 0).reduce((a, b) => (a + b));
    const containerStyle = css`
        position: relative;
        width: ${totalWidth}px;
        height: 170px;
    `;

    // each tree path is an SVG that is the width of the larger of the two rows it is being drawn between.
    // we calculate the offset from the center left or right of the top row and account for border-box (hence the -1)
    // we then draw the svg from the offset within a coordinate plane the size of the width of the container and the height
    // and go down, then over the direction width difference from the offset to the bottom row offset
    // and then draw the line down to the point.
    return (
        <div className={containerStyle}>
            {props.nodes.map((n, i) => {
                const percentage = (n.sessionIds?.length ?? 0) / totalSessions;
                return (
                    <TreePath key={i}
                        direction={directionMultiplier(props.nodes.length, i, props.prevLength, props.prevIndex) > 0 ? 'down-right' : 'down-left'}
                        width={NODE_WIDTH * directionMultiplier(props.nodes.length, i, props.prevLength, props.prevIndex)}
                        containerWidth={totalWidth}
                        offset={((props.prevLength / 2) - (props.prevIndex - props.prevNodeOffset)) * NODE_WIDTH - (NODE_WIDTH / 2) - 1}
                        height={170}
                        lineSize={Math.round(19 * percentage)}
                        active={props.activeIndex == i} />)
            }
            )}
        </div>
    );
};


export default DownTreePaths;