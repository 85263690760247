import React from 'react';
import { InjectedFormikProps, withFormik } from "formik";
import * as yup from 'yup';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import UserContainer from '../../../state/containers/UserContainer';
import Loader from '../../../components/general/Loader';
import { css } from '@emotion/css';
import { color_shades_dark } from '../../../constants/colors';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';

interface ChangePasswordFormProps {
    userContainer: UserContainer
    onFinish?: () => void
    onClose?: () => void
    onChange?: () => void
}
interface ChangePasswordFormData {
    oldPassword?: string
    changePassword?: string
    confirmPassword?: string
}
interface ChangePasswordFormState {
    imageUrl?: string
}
class InnerForm extends React.Component<InjectedFormikProps<ChangePasswordFormProps, ChangePasswordFormData>, ChangePasswordFormState> {
    componentWillMount() {

        if (this.props.userContainer.state.currentUser == null) {
            this.props.userContainer.getUser().then(result => {
                this.setState({
                    ...this.state,
                    imageUrl: this.props.userContainer.state.currentUser.imageUrl
                });
            });
        }
        else {
            this.setState({
                ...this.state,
                imageUrl: this.props.userContainer.state.currentUser.imageUrl
            });
        }
    }
    handleImageSelected(file: File) {
        this.readDataUrl(file);

        this.props.setFieldValue("imageFile", file);
    }
    readDataUrl(file: File) {
        const reader = new FileReader();
        reader.onloadend = e => {
            this.setState({
                ...this.state,
                imageUrl: reader.result.toString()
            })
        }

        reader.readAsDataURL(file);
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.userContainer.state.isLoading;

        return (
            <div className={containerStyle}>
                {props.userContainer.state.currentUser == null ? <Loader /> :
                    <form onSubmit={props.handleSubmit}>
                        <div className="title">Change Password</div>
                        <HorizontalSeparator />
                        <FormFieldsContainer className={fieldsContainerStyle}>
                            <TextField name="oldPassword" type="password" value={props.values.oldPassword} label="Old Password" placeholder="Enter your old password" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                            <TextField name="changePassword" type="password" value={props.values.changePassword} label="Change Password" placeholder="It's a secret to everybody" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                            <TextField name="confirmPassword" type="password" value={props.values.confirmPassword} label="Confirm Password" placeholder="One more time" onChange={props.handleChange} onBlur={props.handleBlur} disabled={isLoading} />
                        </FormFieldsContainer>
                        <HorizontalSeparator />
                        <Button className="submit-button" loading={isLoading} disabled={isLoading} themes={["primary"]} text="Update" type="submit" />
                    </form>
                }
            </div>
        )
    }
}

const ChangePasswordForm = withFormik<ChangePasswordFormProps, ChangePasswordFormData>({
    mapPropsToValues: props => ({
        oldPassword: '',
        changePassword: '',
        confirmPassword: ''
    }),
    validationSchema: yup.object().shape({
        oldPassword: yup.string().required("You must enter your old password"),
        changePassword: yup.string(),
        confirmPassword: yup.string().when('changePassword', {
            is: (val) => val !== undefined && val.length > 0,
            then: yup.string().required("You must confirm your password"),
            otherwise: yup.string()
        })
    }),
    handleSubmit: (values, { props }) => {
        props.userContainer.changeUserPassword({
            oldPassword: values.oldPassword,
            newPassword: values.changePassword,
            newPasswordConfirmation: values.confirmPassword
        })

    }
})(InnerForm);
export default ChangePasswordForm;


const fieldsContainerStyle = css`
    margin-top: 12px;
    margin-bottom: 32px;
`

const containerStyle = css`
    border: 1px solid ${color_shades_dark};
    background: white;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 24px 32px;
    padding-bottom: 0px;
    .submit-button {
        margin-left: 0px;
    }
    .title {        
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
    }
`