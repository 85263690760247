import React from 'react';
import { css } from '@emotion/css';
import FilterWrapper from './FilterWrapper';
import LanguageFilter from './LanguageFilter';
import AnalyticsFilterState from '../../../models/analytics/AnalyticsFilterState';
import LanguageModel from '../../../models/features/api/LanguageModel';
import { color_shades_dark, color_shades_lighter, color_text_default, color_colors_ocean, color_text_light } from '../../../constants/colors';
import DeviceTargetModel from '../../../models/features/api/MediaResponses/DeviceTargetModel';
import DeviceTypeFilter from './DeviceTypeFilter';
import InterfaceTypeFilter from './InterfaceTypeFilter';
import AssistantFilter from './AssistantFilter';
import Button from '../../general/Button';
import { style_border_default } from '../../../constants/stylesValues';
import ActiveFilterIndicator from './ActiveFilterIndicator';
import LanguageCollectionAvatar from '../../general/LanguageCollectionAvatar';
const clearIcon = require('../../../content/images/analytics-icons/clear-icon.svg')
const flagIcon = require('../../../content/images/content-explorer/flag.svg');
const assistantIcon = require('../../../content/images/analytics-icons/assistant.svg');
const interfaceTypeIcon = require('../../../content/images/analytics-icons/interfaces.svg');
const deviceTypeIcon = require('../../../content/images/analytics-icons/device-type.svg');
const customBotIcon = require("../../../content/images/platforms/custom-chat.svg");

interface FiltersProps {
    currentFilter: AnalyticsFilterState
    onChange: (filter: AnalyticsFilterState) => void
    filterCount: number
    languages: LanguageModel[]
    deviceTargets: DeviceTargetModel[]
    interfaces: any[]
    assistants: any[]
}
interface FiltersState {
    currentFilter: AnalyticsFilterState
    isExpanded: boolean
}

class AnalyticsFilters extends React.Component<FiltersProps, FiltersState> {
    constructor(props) {
        super(props);
        this.state = {
            currentFilter: props.currentFilter,
            isExpanded: true
        }
    }
    handleConfirmChanges() {
        // map language ids to locales
        let currentFilter = this.state.currentFilter;
        // todo: Aadu Pirn 9/2/21, refactor this entire component and all lanugage filters to just use locales and not language ids.
        currentFilter.locales = [];
        if (currentFilter.languageIds && currentFilter.languageIds.length > 0) {
            currentFilter.languageIds.forEach(id => {
                const language = this.props.languages.find(l => l.id == id);
                currentFilter.locales.push(language?.shortCode ?? language?.regionCode);
            });
        }
        this.props.onChange(this.state.currentFilter);
        this.setState({
            ...this.state,
            isExpanded: false
        })
    }
    handleLanguageFilterChange(languageIds: string[]) {
        this.setState({
            currentFilter: {
                ...this.state.currentFilter,
                languageIds: [...languageIds]
            }
        })
    }
    handleDeviceChange(deviceIds: string[]) {
        this.setState({
            currentFilter: {
                ...this.state.currentFilter,
                deviceTargetIds: [...deviceIds]
            }
        })
    }
    handleAssistantsChange(assistants: string[]) {
        this.setState({
            currentFilter: {
                ...this.state.currentFilter,
                assistants: [...assistants]
            }
        })
    }
    handleInterfaceChange(interfaces: string[]) {
        this.setState({
            currentFilter: {
                ...this.state.currentFilter,
                interfaces: [...interfaces]
            }
        })
    }
    handleToggleExpand() {
        this.setState({
            ...this.state,
            isExpanded: !this.state.isExpanded
        })
    }
    handleClearFilters() {
        this.setState({
            ...this.state,
            currentFilter: {
                assistants: [],
                deviceTargetIds: [],
                interfaces: [],
                languageIds: [],
            }
        })
        this.props.onChange({
            assistants: [],
            deviceTargetIds: [],
            interfaces: [],
            languageIds: []
        })
    }
    handleCancelChanges() {
        this.setState({
            ...this.state,
            currentFilter: this.props.currentFilter,
            isExpanded: false
        })
    }
    isUnfiltered() {
        return !(this.state.currentFilter?.languageIds?.length > 0
            || this.state.currentFilter?.assistants?.length > 0
            || this.state.currentFilter?.interfaces?.length > 0
            || this.state.currentFilter?.deviceTargetIds?.length > 0)
    }
    render() {
        return (<div className={containerStyle}>
            <div className="active-filter-section">
                {this.isUnfiltered()
                    ? <p className="ac-analytics-active-filters">No Active Filters - Add some below</p>
                    : <>
                        {this.state.currentFilter?.languageIds?.length > 0 &&
                            <ActiveFilterIndicator items={[]}
                                onClear={() => this.handleLanguageFilterChange([])}
                                limit={5}
                                icon={flagIcon}
                                activeFilterRender={() =>
                                    <div className="language-avatars">
                                        <LanguageCollectionAvatar className={languageCollectionAvatarStyle} limit={5} languages={this.props.languages.filter(l => this.state.currentFilter.languageIds.indexOf(l.id) > -1)} />
                                    </div>
                                } />}
                        {this.state.currentFilter?.assistants?.length > 0 &&
                            <ActiveFilterIndicator items={this.state.currentFilter.assistants.map(a => ({
                                label: a,
                                icon: this.props.assistants.find(m => m.name == a).iconUrl
                            }))}
                                onClear={() => this.handleAssistantsChange([])}
                                limit={5}
                                icon={assistantIcon} />}
                        {this.state.currentFilter?.deviceTargetIds?.length > 0 &&
                            <ActiveFilterIndicator items={this.state.currentFilter.deviceTargetIds.map(a => ({
                                label:  this.props.deviceTargets.find(m => m.id == a).displayName,
                                icon: this.props.deviceTargets.find(m => m.id == a).iconUrl ?? customBotIcon
                            }))}
                                onClear={() => this.handleDeviceChange([])}
                                limit={5}
                                icon={deviceTypeIcon} />}
                        {this.state.currentFilter?.interfaces?.length > 0 &&
                            <ActiveFilterIndicator items={this.state.currentFilter.interfaces.map(a => ({
                                label: a,
                                icon: this.props.interfaces.find(m => m.name == a).iconUrl
                            }))}
                                onClear={() => this.handleInterfaceChange([])}
                                limit={5}
                                icon={interfaceTypeIcon} />}
                    </>}
                <div className="button-container">
                    <Button text="Clear All" className="clear-button" disabled={this.props.filterCount <= 0} rightIcon={clearIcon} themes={['flat']} onClick={this.handleClearFilters.bind(this)} />
                    {this.props.currentFilter != this.state.currentFilter
                        ? <>
                            <Button text="Cancel" themes={['secondary-small']} onClick={this.handleCancelChanges.bind(this)} />
                            <Button text="Update Filters" themes={['primary-small']} onClick={this.handleConfirmChanges.bind(this)} />
                        </>
                        : <Button text="Edit Filters" themes={['primary-small']} onClick={this.handleToggleExpand.bind(this)} />}
                </div>
            </div>
            {this.state.isExpanded && <div className="update-filter-section">
                <FilterWrapper>
                    <LanguageFilter onChange={this.handleLanguageFilterChange.bind(this)}
                        languageIds={this.state.currentFilter?.languageIds ?? []}
                        languages={this.props.languages ?? []} />
                </FilterWrapper>
                <FilterWrapper>
                    <AssistantFilter onChange={this.handleAssistantsChange.bind(this)}
                        selectedAssistants={this.state.currentFilter?.assistants ?? []}
                        assistants={this.props.assistants ?? []} />
                </FilterWrapper>
                <FilterWrapper>
                    <DeviceTypeFilter onChange={this.handleDeviceChange.bind(this)}
                        deviceIds={this.state.currentFilter?.deviceTargetIds ?? []}
                        devices={this.props.deviceTargets ?? []} />
                </FilterWrapper>
                <FilterWrapper>
                    <InterfaceTypeFilter onChange={this.handleInterfaceChange.bind(this)}
                        selectedInterfaces={this.state.currentFilter?.interfaces ?? []}
                        interfaces={this.props.interfaces ?? []} />
                </FilterWrapper>
            </div>}
        </div>)
    }
}

const languageCollectionAvatarStyle = css`
    margin-left: -12px;
`

const containerStyle = css`
    border-bottom: 1px solid ${color_shades_dark};
    background: ${color_shades_lighter};
    padding: 0 16px;
    .active-filter-section {
        display: flex;
        align-items: center;
        padding:24px;
        >div {
            margin-right: 16px;
        }
        >p {
            font-style: italic;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
        .language-avatars {
            margin-left: 24px;
        }
        .button-container {
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-left: auto;
            button {
                margin: 0;
                margin-left: 16px;
            }
            .clear-button {
                border-right: ${style_border_default};
                border-radius: 0;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                height: 32px;
                padding: 0;
                padding-right: 16px;
                &:disabled {
                    color: ${color_text_light};
                    img {                        
                        filter: grayscale(100%);
                    }
                }
            }
        }
        border-bottom: ${style_border_default};
    }
    .update-filter-section {
        display: flex;
        align-items: center;
        padding-bottom: 24px;
    }

    .search-bar {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        margin: 0 16px;
        border-bottom: 1px solid ${color_shades_dark};
        label {
            color: ${color_text_default};
            font-size: 16px;
            line-height: 24px;
        }
        .input-wrapper {
            background: white;            
            border-radius: 8px;
            border: 1px solid ${color_shades_dark};
            margin-left: 12px;
            flex: 1;
            display: flex;
            align-items: center;
            height: 48px;
            padding: 0 16px;

            img {
                margin-right: 12px;
            }
            &:focus-within {
                border: 1px solid ${color_colors_ocean};
            }
        }
        input {
            border: none;
            background: transparent;
            flex: 1;
            color: ${color_text_default};
            line-height: 20px;
            font-size: 14px;
            ::placeholder {
                font-style: italic;
                color: ${color_text_light};                
                font-weight: normal;
                line-height: 20px;
                font-size: 14px;
            }
        }
    }

    .filters-wrapper {
        display: flex;
        justify-content: stretch;
        max-width: 100%;
        flex-basis: 0;
    }
`

export default AnalyticsFilters;