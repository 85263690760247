import React from 'react';
import { css } from '@emotion/css';
import AnalyticsSelectField from '../analytics/AnalyticsSelectField';
import { color_colors_ocean, color_text_light } from '../../constants/colors';
import AverageItem from '../../models/analytics/Averageitem';
import SecondaryLoader from '../general/SecondaryLoader';
const deltaDown = require('../../content/images/analytics-icons/delta-down.svg');
const deltaUp = require('../../content/images/analytics-icons/delta-up.svg');

interface BreakdownProps {
    items: AverageItem[]
    comparedDateRangeLabel?: string
    showValue: boolean
    showPercentage: boolean
    isLoading?: boolean
}


const AveragesCard: React.SFC<BreakdownProps> = (props) => (
    <div className={containerStyle}>
        <div className="header-container ac-analytics-averages-card">
            <h5>Averages:</h5>
            <p>{props.comparedDateRangeLabel}</p>
        </div>
        {props.isLoading ? <div className="loader-container"><SecondaryLoader /></div> :
            props.items?.map((item, i) => (
                <div key={i} className="average-item">
                    <p className="item-label">{item.title}</p>
                    <div className="item-values-container">
                        <p className="item-value">{item.value}</p>
                        <img src={item.deltaValue > 0 ? deltaUp : deltaDown} />
                        {props.showValue && <p className="item-delta">{item.deltaValue}</p>}
                        {props.showValue && props.showPercentage && <div className="item-separator" />}
                        {props.showPercentage && <p className="item-delta">{Math.round(Math.abs(item.deltaPercentage))}%</p>}
                    </div>
                </div>
            ))}
    </div>
)


const containerStyle = css`
    height: 80px;
    width: 100%;
    font-family: Muli;
    border: 1px solid #CBCCD2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 32px;
    background: white;
    display: flex;
    align-items: center;
    margin-bottom: 128px;
    .header-container {
        flex: 1;
        h5 {
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        p {
            font-size: 12px;
            line-height: 16px;
            color: ${color_text_light};
        }
    }
   
    .average-item {
        flex: 1; 
        .item-label {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${color_text_light};
        }
        .item-values-container {
            display: flex;
            align-items: center;
            img {
                margin-left: 8px;
                margin-right: 4px;
            }
            .item-value {
                font-size: 18px;
                line-height: 24px;
            }
            .item-delta {
                color: ${color_text_light};
                font-size: 14px;
                line-height: 20px;
            }
            .item-separator {
                height: 16px;
                width: 1px;
                background :${color_text_light};
                margin: 0 8px;
            }

        }
    }
    .loader-container {
     display: flex;
     flex: 1;
     align-items: center;
     .spinner > div {
         background: ${color_colors_ocean};
     }
    }

`

export default AveragesCard;