import React from 'react';
import { css } from '@emotion/css';
import { color_shades_darkest, color_shades_dark, color_shades_darker } from '../../../constants/colors';
import IconCollectionItem from '../../../models/media/IconCollectionItem';
import IconCollectionAvatar from '../../../components/general/IconCollectionAvatar';
import UserAvatar from '../../../components/general/UserAvatar';
import MembershipModel from '../../../models/organizations/api/MembershipModel';
import InvitationModel from '../../../models/organizations/api/InvitationModel';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import OverflowMenu from '../../../components/general/overflowMenu/OverflowMenu';
import CheckboxField from '../../../components/forms/CheckboxField';
import styled from '@emotion/styled';
const removeIcon = require('../../../content/images/remove-member-dark.svg');
const removeMemberDisabled = require('../../../content/images/remove-member-disabled.svg');
const copyImage = require('../../../content/images/copy-text.svg');
const resendMail = require('../../../content/images/resend-mail.svg');

interface OrganizationMemberTableItemProps {
    member?: MembershipModel
    invitation?: InvitationModel
    updateRole?: (memberId: string, roleName: string) => void
    remove?: () => void
    changeReceiveAnalyticsEmail?: () => void
    resendInvitation?: () => void
    allowRoleChange?: boolean
    allowRemove?: boolean
}

interface OrganizationMemberTableItemState {
    showRoleDropdown: boolean
}
class OrganizationMemberTableItem extends React.Component<OrganizationMemberTableItemProps, OrganizationMemberTableItemState> {
    constructor(props) {
        super(props);
        this.state = {
            showRoleDropdown: false
        }
    }
    copyLink() {
        const element = document.createElement('textarea');
        element.value = `${location.host}/acceptinvitation/${this.props.invitation?.id}`;
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
    }
    toggleRoleDropdown() {
        this.setState({
            ...this.state,
            showRoleDropdown: !this.state.showRoleDropdown
        });
    }
    selectRole(role: string) {
        if (this.props.allowRoleChange)
            this.props.updateRole(this.props.member.id, role);
    }
    render() {
        const member = this.props.member;
        const invitation = this.props.invitation;
        const firstName = member == null ? "New" : member?.firstName;
        const lastName = member == null ? "User" : member?.lastName;
        const email = member == null ? invitation?.email : member?.email;
        const noImageText = member == null ? "?" : null;
        let memberRole = member?.isAdmin ? "Administrator" : "Contributor"
        if (member == null)
            memberRole = invitation?.isAdmin ? "Administrator" : "Contributor"
        return (
            <div className={`${containerStyle} ac-members-item`} key={member?.id ?? invitation?.id}>
                <div className={nameAndIcon}>
                    <UserAvatar
                        className="member-icon"
                        firstName={firstName}
                        lastName={lastName}
                        noImageText={noImageText}
                        src={member?.imageUrl}
                        size={42}
                        noBackground={true}
                        noShadow={true} />
                    <div className={nameStyle}>{`${firstName} ${lastName}`}</div>
                </div>
                <div className={`${emailStyle} ac-member-email`}>{email}</div>
                {this.props.member == null ?
                    <div className={`${roleStyle} ac-member-role`}>
                        <div>{memberRole}</div>
                    </div>
                    :
                    <div className={`${roleStyle} ac-member-role`}>
                        <div className={"current-role"} onClick={this.toggleRoleDropdown.bind(this)}>
                            <div>{memberRole}</div>
                            <ToggleIndicator className={chevronStyle} direction={this.state.showRoleDropdown ? "up" : "down"} />
                            {this.state.showRoleDropdown &&
                                <OverflowMenu className={overflowMenuStyle}
                                    optionClassName={overflowMenuItemStyle}
                                    onClose={this.toggleRoleDropdown.bind(this)}
                                    onOptionSelected={this.selectRole.bind(this)}
                                    options={[{ isDisabled: !this.props.allowRoleChange, label: "Administrator" }, { isDisabled: !this.props.allowRoleChange, label: "Contributor" }]} />
                            }
                        </div>
                    </div>
                }
                {this.props.member == null ?
                    <div className={inviteStyle}>
                        <div className={`${inviteSentStyle} ac-invite-sent`}>Invite Sent</div>
                        <img onClick={() => this.props.resendInvitation()} className={`${resendMailStyle} ac-invite-resend`} src={resendMail} />
                        {/* <div onClick={this.copyLink.bind(this)} className={copyLinkStyle}><img src={copyImage} /></div>
                        <input
                            value={`${location.host}/acceptinvitation/${invitation.id}`}
                            className={`${inviteInput} ac-invite-code`}
                            contentEditable={false}
                            disabled
                        /> */}
                    </div>
                    :
                    <IconCollectionAvatar
                        className={`${appCollectionStyle} ac-members-apps`}
                        items={member?.applicationMemberships.map(a => {
                            var collectionItem: IconCollectionItem;
                            collectionItem = {
                                noImageText: `${a.applicationName == null ? "NA" : a.applicationName[0]}`,
                                tooltipText: a.applicationName,
                                imageUrl: a.applicationImageUrl
                            }
                            return collectionItem;
                        })}
                        limit={4} />
                }
                {
                    <div className={receivesAnalyticsEmailStyle}>
                        <StyledCheckboxField
                            label=""
                            checked={member.receivesAnalyticsEmail ?? false}
                            disabled={this.props.changeReceiveAnalyticsEmail === undefined}
                            onChange={(e) => this.props.changeReceiveAnalyticsEmail()}
                        />
                    </div>
                }
                {this.props.allowRemove ?
                    <img src={removeIcon} className={`${removeIconStyle} ac-member-remove`} onClick={(this.props.remove)} />
                    :
                    <img src={this.props.allowRoleChange ? removeIcon : removeMemberDisabled} className={`${removeIconStyle} ${this.props.allowRoleChange ? '' : "disabled"} ac-member-remove`} onClick={this.props.allowRoleChange ? this.props.remove : null} />
                }
            </div>
        )
    }
};

var nameAndIconWidth = "28%";
var emailWidth = "20%";
var roleWidth = "20%";
var appCollectionWidth = "20%";
var receivesAnalyticsWidth = "10%"

const inviteStyle = css`
    display: flex;
    flex-direction: row;
    width: 20%;
    margin-right: 12px;
`;

const resendMailStyle = css`
    width: 20px;
    height: 20px;
    margin: 15px 16px 0px 8px;
    cursor: pointer;
`;

const copyLinkStyle = css`
    width: 36px;
    height: 32px;
    border: 1px solid ${color_shades_dark};
    border-radius: 4px 0px 0px 4px;
    border-right: none;
    background: white;
    cursor: pointer;
    margin-top: 8px;
    img {
        margin-top: 5px;
        margin-left: 6px;
        width: 20px;
        height: 20px;
    }
`;

const inviteSentStyle = css`
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin: 13px 8px 0px 0px;
`;

const containerStyle = css`
    display: flex; 
    flex-direction: row;    
    position: relative;
    border-bottom: solid 1px ${color_shades_dark};
    padding: 8px 32px;
    .counts {
        width: 30%;
        line-height: 24px;
        margin: auto 8px;        
        color: ${color_shades_darker};
        img {
            margin-right: 4px;
        }
    }
`;

const overflowMenuStyle = css`
    width: 120px;
    top: 14px;
    margin-left: 0px;
    left: -8px;
`;

const overflowMenuItemStyle = css`
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
`;

const removeIconStyle = css`
    width: 24px;
    height: 25px;
    margin-top: 12px;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
    }
`;

const chevronStyle = css`        
    margin-left: 8px;
    margin-top: 6px;
    margin-right: 6px;
`

const nameAndIcon = css`
    display: flex;
    flex-direction: row;
    width: ${nameAndIconWidth};      
`;

const nameStyle = css`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: ${color_shades_darkest};
    margin: auto 8px;
    margin-left: 16px;
`;

const emailStyle = css`
    width: ${emailWidth};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color_shades_darkest};
    margin-top: 11px;
`;

const roleStyle = css`
    width: ${roleWidth};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color_shades_darkest};
    margin-top: 12px;
    cursor: pointer;
    .current-role {
        position: relative;
        display: flex;
        flex-direction: row;
    }
`;

const appCollectionStyle = css`
    width: ${appCollectionWidth};
    margin-top: auto;
    margin-bottom: auto;
`;

const receivesAnalyticsEmailStyle = css`
    width: ${receivesAnalyticsWidth};
`;

const inviteInput = css`    
    width: 202px;
    height: 32px;
    box-shadow: none;
    padding: 8px 12px;
    border-radius:  0px 4px 4px 0px;
    border: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    margin: 8px 0px;
    box-shadow: inset 0px 1px 2px rgba(50, 70, 97, 0.1);
`;

const StyledCheckboxField = styled(CheckboxField)`
    margin-top: 16px;
    margin-bottom: -10px;
`;

export default OrganizationMemberTableItem;