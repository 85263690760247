import ApiKeyModel from "../../models/auth/api/ApiKeyModel";
import ApiKeyResult from "../../models/auth/api/ApiKeyResult";
import NewApiKeyRequest from "../../models/auth/api/NewApiKeyRequest";
import ApplicationConversationFlagModel from "../../models/features/api/Flags/ApplicationConversationFlagModel";
import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";

export const getApplicationConversationFlags = (applicationId:string) : Promise<IResult<ApplicationConversationFlagModel[]>> => {
    return sendGet(`/applicationFlags/forApp/${applicationId}`);
}

export const createApplicationConversationFlag = (model:ApplicationConversationFlagModel) : Promise<IResult<ApiKeyModel[]>> => {
    return sendPost(`/applicationFlags`, model);
}

export const updateApplicationConversationFlag = (applicationConversationFlagId :string, model:ApplicationConversationFlagModel) : Promise<IResult<ApiKeyModel>> => {
    return sendPut(`/applicationFlags/${applicationConversationFlagId}`, model);
}
export const deleteApplicationConversationFlag = (applicationConversationFlagId :string) : Promise<IResult<ApiKeyModel>> => {
    return sendDelete(`/applicationFlags/${applicationConversationFlagId}`);
}