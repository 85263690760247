import React from 'react';
import { Subscribe } from 'unstated';
import TitleStateContainer from '../../state/containers/TitleStateContainer';
import HeaderTitleView from '../../components/structure/HeaderTitleView';
import ApplicationContainer from '../../state/containers/ApplicationContainer';

interface DashboardHeaderProps {
    noBreadCrumb?: boolean
}

const DashboardHeader: React.FC<DashboardHeaderProps> = (props) => {
    return (
        <Subscribe to={[ApplicationContainer, TitleStateContainer]}>
            {(appContainer: ApplicationContainer, titleContainer: TitleStateContainer) => {
                return (
                    <HeaderTitleView
                        text={titleContainer.state.title}
                        app={appContainer.state.currentApp}
                        breadcrumb={!props.noBreadCrumb && titleContainer.state.breadcrumb}
                    />
                );
            }}
        </Subscribe>
    );
}

export default DashboardHeader;