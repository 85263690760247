
export const WelcomeMessagesFeatureTypeId = "80170a3b-ea36-4901-9f40-b74c4749cde8";
export const ExitMessagesFeatureTypeId = "f7f7914e-37ef-4a80-83b2-183ddac061cf";
export const HelpMessagesFeatureTypeId = "3d997f5d-bf9c-4b9e-9d8c-431cb2102738";
export const QuestionAnswerFeatureTypeId = "205ee1e2-bc1c-4308-b8b7-41b332d491a9";
export const LatestMessagesFeatureTypeId = "cafc2ebd-ba7d-468d-b4d3-c951b938ceed";
export const FallbackFeatureTypeId = "2048dbd5-3c48-4bca-a34b-66f48719cea7";
export const SimpleChoiceFeatureTypeId = "69c02847-a647-4cd4-b0a8-22f385053354";
export const NumberRangeFeatureTypeId = "53ca606b-577b-44a5-b860-df011677136b";
export const CustomRequestsFeatureTypeId = "8154f972-d307-456b-bff5-d635d1fd7d47";

export const SPARK_EXCLUDED_FEATURE_TYPE_IDS = [WelcomeMessagesFeatureTypeId, FallbackFeatureTypeId];
