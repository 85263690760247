import CustomAssistantRequest from "../../models/customAssistant/CustomAssistantRequest";
import { sendPost } from "./generic";
import { getAssistantApiUrl, getNlpApiUrl } from "../../constants/Urls";
import CustomAssistantResponse from "../../models/customAssistant/CustomAssistantResponse";
import TokenResponse from "../../models/customAssistant/TokenResponse";
import VoicifyNlpResult from "../../models/nlp/VoicifyNlpResult";

export const processNlp = (text: string,
    modelId: string,
    sessionToken: string) => {
    return sendPost<VoicifyNlpResult>(
        `/Nlp/Process`,
        {
            text: text,
            modelId: modelId
        }, getNlpApiUrl(), sessionToken);
}