import React from 'react';
import { css } from '@emotion/css';
import { cool_grey, pale_grey } from '../../../constants/colors';
import SsmlObject from '../../../models/ssml/SsmlObject';
import SsmlHoverTooltip from './SsmlHoverTooltip';
import SsmlValueMiniIndicator from './SsmlValueMiniIndicator';

const breakIcon = require('../../../content/images/ssml/pause-dark.svg');
const audioIcon = require('../../../content/images/ssml/audio-file-dark.svg');
const speedIcon = require('../../../content/images/ssml/music-metronome-dark.svg');
const pitchIcon = require('../../../content/images/ssml/pitch-dark.svg');
const emphasisIcon = require('../../../content/images/ssml/megaphone-dark.svg');
const volumeIcon = require('../../../content/images/ssml/volume-control-dark.svg');
const spellOutIcon = require('../../../content/images/ssml/Spell-Out-dark.svg');
const digitsIcon = require('../../../content/images/ssml/Count-dark.svg');
const expletiveIcon = require('../../../content/images/ssml/smiley-decode-dark.svg');
const phoneIcon = require('../../../content/images/ssml/phone-dial-dark.svg');
const addressIcon = require('../../../content/images/ssml/location-dark.svg');
const cardinalIcon = require('../../../content/images/ssml/cardinal-dark.svg');
const ordinalIcon = require('../../../content/images/ssml/rank-dark.svg');
const fractionIcon = require('../../../content/images/ssml/fraction-dark.svg');
const dateIcon = require('../../../content/images/ssml/calendar-date-dark.svg');
const timeIcon = require('../../../content/images/ssml/time-dark.svg');
const unitIcon = require('../../../content/images/ssml/measure-dark.svg');
interface SsmlPreviewElementProps {
    allowChildren?: boolean
    contentEditable: boolean
    icon?: string
    name?: string
    attributeIcon?: string
    attributeValue?: string
    ssmlObject: SsmlObject
    hoverActive?: boolean
    minValue?: number
    maxValue?: number
    digitValue?: number
    onViewHover?: () => void
    onIconHover?: () => void
}

const getIcon = (ssmlObject: SsmlObject): string => {
    if (ssmlObject.name == "audio") {
        return audioIcon;
    }
    if (ssmlObject.name == "break") {
        return breakIcon;
    }
    if (ssmlObject.name == "emphasis") {
        return emphasisIcon;
    }
    if (ssmlObject.name == "say-as") {
        const subType = ssmlObject.attributes.find(a => a.name == "interpret-as");
        const subValue = subType ? subType.value : null
        if (subValue == 'characters' || subValue == 'spell-out' || subValue == 'verbatim') return spellOutIcon;
        if (subValue == 'digits') return digitsIcon;
        if (subValue == 'expletive') return expletiveIcon;
        if (subValue == 'telephone') return phoneIcon;
        if (subValue == 'cardinal') return cardinalIcon;
        if (subValue == 'ordinal') return ordinalIcon;
        if (subValue == 'fraction') return fractionIcon;
        if (subValue == 'date') return dateIcon;
        if (subValue == 'time') return timeIcon;
        if (subValue == 'unit') return unitIcon;
        if (subValue == 'address') return addressIcon;

    }
    if (ssmlObject.name == "prosody") {
        const isSpeed = ssmlObject.attributes.find(a => a.name == "rate");
        const isPitch = ssmlObject.attributes.find(a => a.name == "pitch");
        const isVolume = ssmlObject.attributes.find(a => a.name == "volume");
        if (isSpeed) return speedIcon;
        if (isPitch) return pitchIcon;
        if (isVolume) return volumeIcon;
    }

    return null;
}
const getDisplayName = (ssmlObject: SsmlObject): string => {
    if (ssmlObject.name == "say-as") {
        const subType = ssmlObject.attributes.find(a => a.name == "interpret-as");
        return subType ? subType.value : ssmlObject.name
    }
    if (ssmlObject.name == "prosody") {

        const isSpeed = ssmlObject.attributes.find(a => a.name == "rate");
        const isPitch = ssmlObject.attributes.find(a => a.name == "pitch");
        const isVolume = ssmlObject.attributes.find(a => a.name == "volume");
        if (isSpeed) return "Speed";
        if (isPitch) return "Pitch";
        if (isVolume) return "Volume";
    }

    return ssmlObject.name
}

const getSsmlClass = (ssmlObject: SsmlObject) => {
    if (ssmlObject.name == "prosody") {
        const isSpeed = ssmlObject.attributes.find(a => a.name == "rate");
        const isPitch = ssmlObject.attributes.find(a => a.name == "pitch");
        const isVolume = ssmlObject.attributes.find(a => a.name == "volume");
        if (isSpeed) return "speed";
        if (isPitch) return "pitch";
        if (isVolume) return "volume";
    }
    return ssmlObject.name;
}
class SsmlPreviewElement extends React.Component<SsmlPreviewElementProps> {

    render() {
        const props = this.props;
        var ssmlClass = getSsmlClass(props.ssmlObject);
        if (props.allowChildren !== false) {
            ssmlClass += " inner-padded";
        }
        return (
            <>
                <span suppressContentEditableWarning={true}
                    contentEditable={props.allowChildren && props.contentEditable}
                    id={props.ssmlObject.id}
                    data-ssml-element={props.ssmlObject.name}
                    data-ssml-attributes={JSON.stringify(props.ssmlObject.attributes)}
                    className={"ssml-element " + ssmlClass}>
                    <span contentEditable={false}
                        style={{ backgroundImage: `url('${getIcon(props.ssmlObject)}')` }}
                        className="ssml-icon"
                        data-id={props.ssmlObject.id}
                        data-ignore={true}>
                        {props.hoverActive ? <SsmlHoverTooltip
                            elementName={getDisplayName(props.ssmlObject)}
                            attributeValue={props.attributeValue}
                            icon={getIcon(props.ssmlObject)}
                            dataId={props.ssmlObject.id} />
                            : null}
                        &nbsp;
                {this.props.digitValue != null ? <SsmlValueMiniIndicator min={this.props.minValue} max={this.props.maxValue} value={this.props.digitValue} /> : null}
                    </span>
                    {props.children}
                </span>
                <span data-spacer="true"></span>
            </>
        )
    }
}

export default SsmlPreviewElement