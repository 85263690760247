import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import MediaItemModel from '../../models/media/MediaItemModel';
import MediaItemLinkedContent from '../../models/media/MediaItemLinkedContent';

type MediaState = {
    mediaItems: MediaItemModel[]
    isUploading: boolean
    isLoading: boolean
    errors: string[]
    applicationId: string
    hasUpdate: boolean
    linkedContentCache: MediaItemLinkedContent[]
}

export default class MediaContainer extends Container<MediaState> {
    // default state
    state = {
        mediaItems: [],
        isUploading: false,
        isLoading: false,
        errors: [],
        applicationId: '',
        hasUpdate: true,
        linkedContentCache: []
    }

    deleteMediaItem(item : MediaItemModel) : Promise<IResult<MediaItemModel>> {
        var promise = new Promise<IResult<MediaItemModel>>((resolve, reject) => {
            var deletePromise = item.isDisabled ? voicifyApi.deleteMediaItem(item.id) : voicifyApi.disableMediaItem(item.id);
            deletePromise.then(result => {
                if (result.resultType == "Ok") {
                    const index = this.state.mediaItems.findIndex(mi => mi.id === item.id);
                    this.setState({
                        ...this.state,
                        hasUpdate: true,
                        mediaItems: [
                            ...this.state.mediaItems.slice(0, index),
                            result.data,
                            ...this.state.mediaItems.slice(index+1, this.state.mediaItems.length),
                        ],
                        errors: [],
                    })
                    resolve(result);
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                    })
                    reject(result.errors);
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });

        return promise;
    }

    getMediaItems() : void {
        this.getMediaItemsForApp(this.state.applicationId);
    }

    getLinkedContent(mediaItemId : string) : Promise<IResult<MediaItemLinkedContent>> {
        var existingContent = this.state.linkedContentCache.find((c) => { return mediaItemId == c.mediaItemId });
        if(existingContent != null) {
            return new Promise<IResult<MediaItemLinkedContent>>((resolve, reject) => {
                resolve({
                    data: existingContent,
                    errors: [],
                    resultType: "Ok"
                });
            });
        }
        var promise = voicifyApi.getContentItemsForMediaItem(mediaItemId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const linkedContent = [ ...this.state.linkedContentCache ];
                linkedContent.push(result.data);
                this.setState({
                    ...this.state,
                    linkedContentCache: linkedContent
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                })
            }
        });
        return promise;
    }

    resetMediaItems(applicationId: string) : Promise<IResult<MediaItemModel[]>> {
        this.setState({
            ...this.state,
            isLoading: true,
            applicationId: applicationId
        });
        var promise = new Promise<IResult<MediaItemModel[]>>((resolve, reject) => {
            voicifyApi.getMediaItemsForApplication(applicationId).then(result => {
                if (result.resultType == "Ok") {
                    this.setState({
                        ...this.state,
                        errors: [],
                        mediaItems: result.data,
                        isLoading: false,
                        hasUpdate: false,                    
                    })
                    resolve(result);
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                        isLoading: false
                    })
                    reject(result.errors);
                }
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        })
        return promise;
    }

    getMediaItemsForApp(applicationId: string): Promise<IResult<MediaItemModel[]>> {
        // if we already have apps for this org, just return them
        if (this.state.applicationId == applicationId && !this.state.hasUpdate) {
            return new Promise<IResult<MediaItemModel[]>>((resolve, reject) => {
                resolve({
                    data: this.state.mediaItems,
                    errors: [],
                    resultType: "Ok"
                });
            });
        }

        this.setState({
            ...this.state,
            isLoading: true,
            applicationId: applicationId
        });
        
        var promise = voicifyApi.getMediaItemsForApplication(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    mediaItems: result.data,
                    isLoading: false,
                    hasUpdate: false,                    
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            console.log(error);
        });
        return promise;
    }    

    updateMediaItem(mediaItem: MediaItemModel) : Promise<IResult<MediaItemModel>> {
        var promise = new Promise<IResult<MediaItemModel>>((resolve, reject) => {
            voicifyApi.updateMediaItem(mediaItem.id, mediaItem).then(result => {
                if (result.resultType === "Ok") { //update is successful                
                    const index = this.state.mediaItems.findIndex(mi => mi.id === mediaItem.id);
                    this.setState({
                        ...this.state,
                        hasUpdate: true,
                        mediaItems: [
                            ...this.state.mediaItems.slice(0, index),
                            result.data,
                            ...this.state.mediaItems.slice(index+1, this.state.mediaItems.length)
                        ],
                    })
                    resolve(result);
                }
                else { //update is not successful
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                        isUploading: false
                    })
                    reject(result.errors);
                }
            });
        });
        return promise;
    }

    uploadMediaItem(applicationId: string, name: string, file: File, caption?: string, progressCallback?: (number) => void): Promise<IResult<MediaItemModel>> {
        this.setUploading(true);
        var promise = new Promise<IResult<MediaItemModel>>((resolve, reject) => {

            // first get the url
            voicifyApi.getUploadUrl(applicationId, file.name, name).then(result => {
                if (result.resultType == "Ok") {
                    // we have the url, upload to s3
                    voicifyApi.uploadDirect(result.data, file, progressCallback).then(uploadResult => {
                        if (uploadResult.resultType == "Ok") {
                            // upload is good, let's add the media item
                            voicifyApi.createMediaItem(applicationId, {
                                name: name,
                                fileName: file.name,
                                url: result.data.split("?")[0],
                                caption: caption
                            }).then(createResult => {
                                this.setState({
                                    ...this.state,
                                    errors: [],
                                    isUploading: false,
                                    hasUpdate: true
                                })
                                resolve(createResult);
                            })
                        }
                        else {
                            this.setState({
                                ...this.state,
                                errors: result.errors,
                                isUploading: false
                            })
                            reject(result.errors);
                        }
                    }).catch(error => {
                        this.setState({
                            ...this.state,
                            errors: [error],
                            isUploading: false
                        })
                        reject(error);
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                        isUploading: false
                    })
                    reject(result.errors);
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    errors: [error],
                    isUploading: false
                })
                reject(error);
                console.log(error);
            });
        });
        return promise;
    }    
    uploadOrganizationMedia(organizationId: string, name: string, file: File, caption?: string, progressCallback?: (number) => void): Promise<IResult<string>> {
        this.setUploading(true);
        var promise = new Promise<IResult<string>>((resolve, reject) => {

            // first get the url
            voicifyApi.getOrganizationUploadUrl(organizationId, file.name, name).then(result => {
                if (result.resultType == "Ok") {
                    // we have the url, upload to s3
                    voicifyApi.uploadDirect(result.data, file, progressCallback).then(uploadResult => {
                        if (uploadResult.resultType == "Ok") {
                            resolve({
                                resultType: 'Ok',
                                errors: [],
                                data: result.data.split("?")[0]
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                errors: result.errors,
                                isUploading: false
                            })
                            reject(result.errors);
                        }
                    }).catch(error => {
                        this.setState({
                            ...this.state,
                            errors: [error],
                            isUploading: false
                        })
                        reject(error);
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                        isUploading: false
                    })
                    reject(result.errors);
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    errors: [error],
                    isUploading: false
                })
                reject(error);
                console.log(error);
            });
        });
        return promise;
    }    

    replaceMediaItem(applicationId: string, item: MediaItemModel, file: File): Promise<IResult<MediaItemModel>> {
        this.setUploading(true);
            // first get the url
        
        var promise = new Promise<IResult<MediaItemModel>>((resolve, reject) => {
            voicifyApi.getUploadUrl(applicationId, file.name, name).then(result => {
                if (result.resultType == "Ok") {
                    // we have the url, upload to s3
                    voicifyApi.uploadDirect(result.data, file, (number) => {}).then(uploadResult => {
                        if (uploadResult.resultType == "Ok") {
                            // upload is good, let's add the media item
                            voicifyApi.updateMediaItem(item.id, 
                                {
                                    ...item,
                                    name: file.name,
                                    url: result.data.split("?")[0]
                                }).then(updateResult => {
                                    if (updateResult.resultType === "Ok") { //update is successful                
                                            
                                        const index = this.state.mediaItems.findIndex(mi => mi.id === item.id);
                                        this.setState({
                                            ...this.state,
                                            errors: [],
                                            isUploading: false,
                                            hasUpdate: true,                                        
                                            mediaItems: [
                                                ...this.state.mediaItems.slice(0, index),
                                                updateResult.data,
                                                ...this.state.mediaItems.slice(index+1, this.state.mediaItems.length)
                                            ],
                                        });
                                        resolve(updateResult);
                                    }
                                    else { //update is not successful
                                        this.setState({
                                            ...this.state,
                                            errors: updateResult.errors,
                                            isUploading: false
                                        });
                                        reject(updateResult.errors);
                                    }
                            })
                        }
                        else {
                            this.setState({
                                ...this.state,
                                errors: result.errors,
                                isUploading: false
                            });
                            reject(result.errors);
                        }
                    }).catch(error => {
                        this.setState({
                            ...this.state,
                            errors: [error],
                            isUploading: false
                        });
                        reject(error);
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                        isUploading: false
                    });
                    reject(result.errors);
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    errors: [error],
                    isUploading: false
                })
                console.log(error);
                reject(error);
            });
        });
        return promise;
    }

    uploadSsmlItem(applicationId: string, name: string, file: File, caption?: string, progressCallback?: (number) => void): Promise<IResult<MediaItemModel>> {

        // validate file type
        if (!file.type.startsWith('audio')) {
            this.setState({
                ...this.state,
                errors: ["Only audio files are allowed"]
            })

            return new Promise<IResult<MediaItemModel>>((resolve, reject) => {
                reject({
                    errors: ["Only audio files are allowed"],
                    resultType: "Invalid"
                })
            });
        }

        this.setUploading(true);

        var promise = new Promise<IResult<MediaItemModel>>((resolve, reject) => {

            // first get the url
            voicifyApi.getUploadUrl(applicationId, file.name, name).then(result => {
                if (result.resultType == "Ok") {
                    // we have the url, upload to s3
                    voicifyApi.uploadDirect(result.data, file, progressCallback).then(uploadResult => {
                        if (uploadResult.resultType == "Ok") {
                            // upload is good, let's add the media item
                            voicifyApi.createMediaItem(applicationId, {
                                name: name,
                                fileName: file.name,
                                url: result.data.split("?")[0],
                                caption: caption
                            }).then(createResult => {
                                if (createResult.resultType == "Ok") {
                                    // add is good, let's make the ssml conversion
                                    voicifyApi.convertAudioItemToSsml(createResult.data.id).then(ssmlResult => {
                                        if (ssmlResult.resultType == "Ok") {
                                            this.setState({
                                                ...this.state,
                                                errors: [],
                                                isUploading: false
                                            })
                                            resolve(ssmlResult);
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                errors: result.errors,
                                                isUploading: false
                                            })
                                            reject(result.errors);
                                        }
                                    }).catch(error => {
                                        this.setState({
                                            ...this.state,
                                            isUploading: false,
                                            errors: [error]
                                        })
                                    })
                                }
                            })
                        }
                        else {
                            this.setState({
                                ...this.state,
                                errors: result.errors,
                                isUploading: false
                            })
                            reject(result.errors);
                        }
                    }).catch(error => {
                        this.setState({
                            ...this.state,
                            errors: [error],
                            isUploading: false
                        })
                        reject(error);
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                        isUploading: false
                    })
                    reject(result.errors);
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    errors: [error],
                    isUploading: false
                })
                reject(error);
                console.log(error);
            });
        });
        return promise;
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    };

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }

    private setUploading(isUploading: boolean) {
        this.setState({
            ...this.state,
            isUploading: isUploading
        });
    }
}