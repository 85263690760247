import React from 'react';
import { css } from '@emotion/css';
import SsmlElementSelector, { SsmlElement } from './SsmlElementSelector';
import SsmlElementCarousel from './SsmlElementCarousel';
const spellOutIcon = require('../../../content/images/ssml/Spell-Out-dark.svg');
const digitsIcon = require('../../../content/images/ssml/Count-dark.svg');
const expletiveIcon = require('../../../content/images/ssml/smiley-decode-dark.svg');
const phoneIcon = require('../../../content/images/ssml/phone-dial-dark.svg');
const addressIcon = require('../../../content/images/ssml/location-dark.svg');
const cardinalIcon = require('../../../content/images/ssml/cardinal-dark.svg');
const ordinalIcon = require('../../../content/images/ssml/rank-dark.svg');
const fractionIcon = require('../../../content/images/ssml/fraction-dark.svg');
const dateIcon = require('../../../content/images/ssml/calendar-date-dark.svg');
const timeIcon = require('../../../content/images/ssml/time-dark.svg');
const unitIcon = require('../../../content/images/ssml/measure-dark.svg');

const spellOutBlueIcon = require('../../../content/images/ssml/Spell-Out-blue.svg');
const digitsBlueIcon = require('../../../content/images/ssml/Count-blue.svg');
const expletiveBlueIcon = require('../../../content/images/ssml/smiley-decode-blue.svg');
const phoneBlueIcon = require('../../../content/images/ssml/phone-dial-blue.svg');
const addressBlueIcon = require('../../../content/images/ssml/location-blue.svg');
const cardinalBlueIcon = require('../../../content/images/ssml/cardinal-blue.svg');
const ordinalBlueIcon = require('../../../content/images/ssml/rank-blue.svg');
const fractionBlueIcon = require('../../../content/images/ssml/fraction-blue.svg');
const dateBlueIcon = require('../../../content/images/ssml/calendar-date-blue.svg');
const timeBlueIcon = require('../../../content/images/ssml/time-blue.svg');
const unitBlueIcon = require('../../../content/images/ssml/measure-blue.svg');

interface SsmlElementMenuProps {
    onElementSelected: (element: SsmlElement) => void
    selectedValue?: string
}

const containerStyle = css`
    display: flex;
    flex-wrap: wrap;

    > * + * {
        margin-left: 20px;
    }
`
const SayAsMenu: React.SFC<SsmlElementMenuProps> = (props) => (
    <div className={containerStyle}>
        <SsmlElementCarousel
            selectedValue={props.selectedValue}
            options={[
                { value: 'characters', name: 'Characters', icon: spellOutIcon, selectedIcon: spellOutBlueIcon },
                { value: 'digits', name: 'Digits', icon: digitsIcon, selectedIcon: digitsBlueIcon },
                { value: 'expletive', name: 'Censored', icon: expletiveIcon, selectedIcon: expletiveBlueIcon },
                { value: 'telephone', name: 'Phone #', icon: phoneIcon, selectedIcon: phoneBlueIcon },
                // { value: 'address', name: 'Address', icon: addressIcon }, // address is alexa only
                { value: 'cardinal', name: 'Cardinal', icon: cardinalIcon, selectedIcon: cardinalBlueIcon },
                { value: 'ordinal', name: 'Ordinal', icon: ordinalIcon, selectedIcon: ordinalBlueIcon },
                { value: 'fraction', name: 'Fraction', icon: fractionIcon, selectedIcon: fractionBlueIcon },
                { value: 'date', name: 'Date', icon: dateIcon, selectedIcon: dateBlueIcon },
                { value: 'time', name: 'Time', icon: timeIcon, selectedIcon: timeBlueIcon },
                { value: 'unit', name: 'Unit', icon: unitIcon, selectedIcon: unitBlueIcon },
            ]}
            onOptionSelected={props.onElementSelected} />
    </div>

)

export default SayAsMenu;