import React from 'react';
import { css } from '@emotion/css';
import { color_shades_darkest } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';

interface HeaderBarProps { }

const HeaderBar: React.FC<HeaderBarProps> = (props) => (
    <header className={headerStyle}>
        {props.children}
    </header>
);

const headerStyle = css`    
    display: flex;
    align-items: center;
    height: 80px;
    background: ${color_shades_darkest};
    color: white;
    top: 0;
    width: 100vw;
`;

export default HeaderBar; 