import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import MenuItemSizeGroupEditor from './MenuItemSizeGroupEditor'
import MenuContainer from '../../../hooks/MenuContainer'
import { color_shades_dark } from '../../../constants/colors'
import CustomScrollbars from '../../../components/structure/CustomScrollbars'
import { style_border_default } from '../../../constants/stylesValues'
import Button from '../../../components/general/Button'
import Breadcrumb from '../../../components/general/Breadcrumb'
import MenuItemOptionEditor from './MenuItemOptionEditor'
import MenuItemOptionGroupEditor from './MenuItemOptionGroupEditor'
import ApplicationNlpMenuContainer from '../../../hooks/ApplicationNlpMenuContainer'
import ApplicationModel from '../../../models/applications/api/ApplicationModel'
import PanelTitle from '../../contentPage/components/PanelTitle'
import MenuItemSizeEditor from './MenuItemSizeEditor'
import Scrollbars from 'react-custom-scrollbars'
import Switch from '../../../components/forms/Switch'
import ModalLayout from '../../../components/structure/ModalLayout'
import ModalHeader from '../../../components/general/ModalHeader';
import FooterBar from '../../../components/structure/FooterBar'
import { css } from '@emotion/css'
import useWindowSize from '../../../hooks/UseWindowSize'
import { v4 as uuidv4 } from 'uuid';
import PersistentPropertyTitle from './PersistentPropertyTitle'
import { GenAIFoodOrdering } from '../../../constants/featureFlagIds'

interface OptionGroupAndOptionPanelProps {
    application: ApplicationModel,
    history: any
    rightPanelRef: React.RefObject<Scrollbars>
}
export default function OptionGroupAndOptionPanel(props: OptionGroupAndOptionPanelProps) {
    const menuContainer = MenuContainer.useContainer();
    const [saveAction, setSaveAction] = React.useState<"save" | "save-and-close">("save");
    const applicationNlpMenuContainer = ApplicationNlpMenuContainer.useContainer();
    const [saving, setSaving] = React.useState(false);
    const intervalIdRef = useRef(null);

    useEffect(() => {
        isAugmenting();
        return () => stopInterval();
    }, [props.application.manageMenuApplicationId]);

    const askToUpdateOtherItems = async () => {
        return await menuContainer.askToUpdateOtherItems(props.application.manageMenuApplicationId)
    }

    const saveAndCloseButton = async () => {
        setSaveAction("save-and-close");
        const otherItems = await askToUpdateOtherItems();
        if (otherItems.length < 2) {
            await saveAndClose();
        }
    }

    const saveButton = async () => {
        setSaveAction("save");
        const otherItems = await askToUpdateOtherItems();
        if (otherItems.length < 2) {
            handleSaveItemOnly();
        }
    }

    const saveAndClose = async () => {
        handleSaveItemOnly();
        menuContainer.resetMenuItemSelections();
        menuContainer.setMenuItemAugmentStatus(null);
        props.history.goBack()
    }

    async function handleSaveItemOnly() {
        setSaving(true);
        menuContainer.setShowAskToUpdateOtherItemsModal(false);
        const menuItemToAugment = menuContainer.getCleanedMenuItemForAugment();
        if (menuItemToAugment) {
            menuContainer.menuItem.lastAugmentStartTime = new Date().getTime().toString();//to track augment status
        }
        const menuUpdateResponse = await applicationNlpMenuContainer.updateVoicifyMenuItem(props.application.manageMenuApplicationId, menuContainer.menuItem);

        //the menu item changes are saved and it requires augmentation
        if (menuUpdateResponse?.resultType === "Ok" && menuItemToAugment && !props.application.featureFlags?.some(flag => flag.id === GenAIFoodOrdering)) {
            const augmentRequest = {
                applicationId: props.application.manageMenuApplicationId,
                forceAugment: true,
                generateMenuItemColloquialNames: false,//no need to regenerate colloquial name for the menu item
                generateForOptionGroups: true,
                generateForSizeGroups: true,
                propertiesToAugment: [],
                menuItemsToAugment: [menuItemToAugment]
            }
            menuContainer.resetMenuItemSelections();
            menuContainer.setMenuItemAugmentStatus("loading");
            startInterval();
            applicationNlpMenuContainer.augmentMenu(augmentRequest);
        }
        menuContainer.setShowAskToUpdateOtherItemsModal(false)
        setSaving(false);
        if (saveAction === "save-and-close")
            props.history.goBack();
    }

    async function handleSaveAll() {
        setSaving(true)
        menuContainer.setShowAskToUpdateOtherItemsModal(false);
        menuContainer.saveAndAugmentAll(props.application)
        menuContainer.resetMenuItemSelections();
        startInterval();
        setSaving(false);
        if (saveAction === "save-and-close")
            props.history.goBack();
    }

    function getColloquialName(colloquialName: string | null) {
        if (colloquialName) {
            return `"${colloquialName}"`
        }
        return "";
    }

    function getHeaderTitleAndDescription() {
        let title = menuContainer.menuItem?.originalName;
        let subtitle = "";
        let description = "Update the {text} below."
        if (menuContainer.path) {
            const lastItem = menuContainer.path[menuContainer.path.length - 1];
            if (!lastItem) return { title: "", description: "" };
            const sizeGroupArray = lastItem.selectedSizeGroup && lastItem.selectedSizeGroup?.sizes?.map(size => size.name);
            const sizeGroupList = sizeGroupArray?.join(", ");
            const optionColloquialName = getColloquialName(lastItem.selectedOption?.colloquialName);
            const sizeColloquialName = getColloquialName(lastItem.selectedSize?.colloquialName);
            if (lastItem.selectedOption) {
                title = `${lastItem.selectedOption.name}`
                subtitle = `${(optionColloquialName != "") ? optionColloquialName + " option" : "Option"} for ${menuContainer.menuItem?.originalName}`
                description = description.replace("{text}", `${lastItem.selectedOption.name} option`)
            }
            if (lastItem.selectedOptionGroup) {
                title = `${lastItem.selectedOptionGroup.name}`
                subtitle = `Option Group for ${menuContainer.menuItem?.originalName}`
                description = description.replace("{text}", `${lastItem.selectedOptionGroup.name} option group`)
            }
            if (lastItem.selectedSizeGroup) {
                title = sizeGroupList
                subtitle = `Size Group for ${menuContainer.menuItem?.originalName}`
                description = description.replace("{text}", `${menuContainer.menuItem?.originalName} size group`)
            }
            if (lastItem.selectedSize) {
                title = `${lastItem.selectedSize.name}`
                subtitle = `${(sizeColloquialName != "" ? sizeColloquialName + " size" : "Size")} for ${menuContainer.menuItem?.originalName}`
                description = description.replace("{text}", `${lastItem.selectedSize.name} size`)
            }
        }
        return { title, subtitle, description };
    }

    function getSelectedObject() {
        const lastItem = menuContainer.path[menuContainer.path.length - 1];
        if (!lastItem) return;
        if (lastItem.selectedOptionGroup) {
            return lastItem.selectedOptionGroup
        }
        if (lastItem.selectedOption) {
            return lastItem.selectedOption
        }
        if (lastItem.selectedSizeGroup) {
            return lastItem.selectedSizeGroup
        }
        if (lastItem.selectedSize) {
            return lastItem.selectedSize
        }
    }

    function toggleDisalbedLastItem() {
        let changedObj = getSelectedObject();
        if (!changedObj) return;
        changedObj.isDisabled = !changedObj.isDisabled;
        menuContainer.updateMenuItem(menuContainer.menuItem, changedObj, "isDisabled");
    }

    function getIsDisabled(): boolean {
        const selectedObj = getSelectedObject();
        if (selectedObj) {
            return selectedObj.isDisabled;
        }
    }

    function whatToRender() {
        if (menuContainer.path) {
            const lastItem = menuContainer.path[menuContainer.path.length - 1];
            if (!lastItem) return (
                <EmptyViewTextWrapper>
                    <EmptyViewText>
                        Select an option or size group to see more details and manage values.
                    </EmptyViewText>
                </EmptyViewTextWrapper>
            );
            if (lastItem.selectedOptionGroup) {
                return (
                    <MenuItemOptionGroupEditor rightPanelRef={props.rightPanelRef} />
                )
            }
            if (lastItem.selectedSizeGroup) {
                return (
                    <MenuItemSizeGroupEditor />
                )
            }
            if (lastItem.selectedOption) {
                return (
                    <MenuItemOptionEditor />
                )
            }
            if (lastItem.selectedSize) {
                return (
                    <MenuItemSizeEditor />
                )
            }
        }
    }

    const startInterval = () => {
        if (!intervalIdRef.current) {
            intervalIdRef.current = setInterval(() => {
                isAugmenting();
            }, 5000);
        }
    };

    const stopInterval = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null; // clear the interval ref
        }
    };

    async function isAugmenting() {
        const url = window.location.pathname;
        if (!url) return;
        const urlItemIdMath = url.match(/\/menu\/item\/([^/]+)/)
        const itemId = Array.isArray(urlItemIdMath) ? urlItemIdMath[1] : null;

        const menuItem = await menuContainer.getMenuItem(props.application.manageMenuApplicationId, itemId);
        if (!menuItem) return;

        const eightMinutesMS = 8 * 60 * 1000;
        const startAugmentTime = parseInt(menuItem.lastAugmentStartTime);
        const finishAugmentTime = parseInt(menuItem.lastAugmentFinishTime);

        if ((startAugmentTime && !finishAugmentTime) || startAugmentTime > finishAugmentTime) {
            if ((new Date().getTime() - startAugmentTime) <= eightMinutesMS) {
                menuContainer.setMenuItemAugmentStatus("loading");
                startInterval();//if the intarval has not started yet
            }
            else {
                menuContainer.setMenuItemAugmentStatus("fail");
                stopInterval();
            }
        } else if (startAugmentTime < finishAugmentTime) {
            //only show success if we are checking in the interval
            if (intervalIdRef.current) {
                menuContainer.setMenuItemAugmentStatus("success");
            }
            stopInterval();
        }
    }

    function handleAddRemoveMatchingItems(itemIndex: string) {
        if (menuContainer.itemsToNotAugment.includes(itemIndex)) {
            menuContainer.setItemsToNotAugment(prev => prev.filter(existingItem => existingItem !== itemIndex));
        } else {
            menuContainer.setItemsToNotAugment(prev => [...prev, itemIndex])
        }

    }
    function renderMatchingItems() {
        if (menuContainer.matchingItemsWithSameOptionGroupOrSizeGroup.length > 0) {
            return menuContainer.matchingItemsWithSameOptionGroupOrSizeGroup?.map((item, index) => {
                const isEditedItem = item.updatedItem.originalName === menuContainer.menuItem.originalName
                return (
                    <div key={index}>
                        {(!isEditedItem && index === 1) && <MatchingItemsTitle>Matching Items:</MatchingItemsTitle>}
                        {isEditedItem &&
                            <>
                                <MatchingItemsTitle>Current Modifications:</MatchingItemsTitle>
                            </>
                        }
                        <MatchingItemsWrapper >
                            <div className={itemAndChangesStyle}>
                                <ItemName>{item.updatedItem.originalName}</ItemName>
                                {item.diffs.map((diff, diffIndex) =>
                                    <div className={differencesStyle} key={diffIndex}>
                                        {typeof diff.newValue === "boolean" ?
                                            <h6>{`${diff.whatsChanged ?? diff.diff}: ${!diff.originalValue} -> ${!diff.newValue}`}</h6> //Because isDsiabled is opposite
                                            :
                                            <h6>{`${diff.whatsChanged ?? diff.diff}: ${diff.originalValue} -> ${diff.newValue}`}</h6>
                                        }

                                    </div>
                                )}
                            </div>
                            {!isEditedItem &&
                                <Button themes={["black-small"]} text={menuContainer.itemsToNotAugment.includes(item.updatedItem.id) ? "Add" : "Remove"}
                                    onClick={() => handleAddRemoveMatchingItems(item.updatedItem.id)}
                                />
                            }
                        </MatchingItemsWrapper>
                        <hr></hr>
                        {(menuContainer.matchingItemsWithSameOptionGroupOrSizeGroup.length <= 1) && <MatchingItemsTitle>No Matching Item Found!</MatchingItemsTitle>}
                    </div>
                )
            })
        } else {
            return (
                <EmptyViewTextWrapper>
                    <EmptyViewText>
                        No item has been changed.
                    </EmptyViewText>
                </EmptyViewTextWrapper>
            )
        }

    }

    const { title, subtitle, description } = getHeaderTitleAndDescription();

    const isDisabled = getIsDisabled();
    const isEditable = menuContainer.menuItemAugmentStatus && menuContainer.menuItemAugmentStatus !== "success" ? false : true;
    const MODAL_SCROLLBAR_SUBTRACTION: number = 200;
    const windowSize = useWindowSize();

    const diffs = menuContainer.findChangesInMenuItems()

    return (
        <>
            {menuContainer.showAskToUpdateOtherItemsModal &&
                <ModalLayout isVisible={true}>
                    <ModalHeader title="Apply changes to other items?" onClose={() => { menuContainer.setShowAskToUpdateOtherItemsModal(!menuContainer.showAskToUpdateOtherItemsModal); }} className={modalHeaderStyle} />
                    <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                        {renderMatchingItems()}
                    </CustomScrollbars>
                    <FooterBar className={footerBarStyle}>
                        <StyledSubmitButton
                            themes={['secondary']}
                            text="Cancel"
                            onClick={() => menuContainer.setShowAskToUpdateOtherItemsModal(!menuContainer.showAskToUpdateOtherItemsModal)}
                        />
                        <div>
                            <StyledSubmitButton
                                themes={['primary', 'start']}
                                text="Confirm and close"
                                loading={saving}
                                disabled={saving || diffs.length === 0 || menuContainer.matchingItemsWithSameOptionGroupOrSizeGroup.length <= 1}
                                onClick={async () => await handleSaveAll()}
                            />
                            <StyledSubmitButton
                                themes={['primary', 'start']}
                                text="No only this one"
                                loading={saving}
                                disabled={saving || diffs.length === 0 || menuContainer.matchingItemsWithSameOptionGroupOrSizeGroup.length === 0}
                                onClick={async () => await handleSaveItemOnly()}
                            />
                        </div>
                    </FooterBar>
                </ModalLayout>
            }
            <HeaderWrapper>
                <BreadcrumbWrapper>
                    <Breadcrumb
                        useItemTitleAsHome={true}
                        onItemClick={(breadcrumbItem: { id: string, title: string, pathLength: number }) => {
                            menuContainer.path.splice(breadcrumbItem.pathLength + 1)
                            menuContainer.setPath([...menuContainer.path])
                        }}
                        items={menuContainer.path?.map((path, index) => ({
                            id: index.toString(),
                            title: path.text,
                            ...path
                        }))}
                    />
                </BreadcrumbWrapper>
                <SaveChangesButton
                    type="button"
                    themes={["secondary-small"]}
                    loading={saving || menuContainer.fetchingMenu}
                    disabled={saving || !isEditable}
                    text="Save Changes"
                    onClick={() => {
                        saveButton();
                    }}
                />
                <SaveAndCloseButton
                    type="button"
                    themes={["primary-small"]}
                    loading={saving || menuContainer.fetchingMenu}
                    disabled={saving || !isEditable}
                    text="Save and Close"
                    onClick={() => {
                        saveAndCloseButton();
                    }}
                />
            </HeaderWrapper>
            {title &&
                <HeaderWrapper>
                    <OptionHeader>
                        <PanelTitle
                            title={title}
                            subtitle={subtitle}
                            detail={description}
                            truncate={true}
                        >
                        </PanelTitle>
                        <PersistentPropertyLabelWrapper>
                            <StyledEnabledSwitch checked={!isDisabled}
                                onChange={() => {
                                    toggleDisalbedLastItem()
                                }} detail={isDisabled ? "Can't Order" : "Can Order"} />
                            <PersistentPropertyTitle
                                title='Can Order'
                                showTitle={false}
                                item={getSelectedObject()}
                                propertyName='isDisabled'
                            />
                        </PersistentPropertyLabelWrapper>
                    </OptionHeader>
                </HeaderWrapper>
            }
            <CustomScrollbars
                autoHide
                autoHeight
                autoHeightMax="calc(100vh - 284px)"
                id="rightPanel"
                ref={props.rightPanelRef}
            >
                <RightPanelItemsWrapper>
                    {whatToRender()}
                </RightPanelItemsWrapper>
            </CustomScrollbars>
        </>
    )
}

const PersistentPropertyLabelWrapper = styled.div`
    display: flex;
    height: fit-content;
    align-items: flex-end;
    margin-right: 10px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: ${style_border_default};
`;

const OptionHeader = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
`;

const BreadcrumbWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
`;

const RightPanelItemsWrapper = styled.div`
    height: 100%;
    border-radius: 5px;
`;

const EmptyViewTextWrapper = styled.div`
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const EmptyViewText = styled.div`
    background: white;
    border: ${style_border_default};
    border-radius: 8px;
    color: ${color_shades_dark};
    padding: 24px;
    margin-top: 24px;
`;

const SaveAndCloseButton = styled(Button)`
    margin-right: 24px;
`;

const SaveChangesButton = styled(Button)`
    margin-left: auto;
`;

const StyledEnabledSwitch = styled(Switch)`
    margin: 16px 0px auto auto;
`;
const StyledSubmitButton = styled(Button)`
    margin: 0px;
    margin-left: 10px;
`;

const modalHeaderStyle = css`
    h2 {
        margin-left: 20px;
    }
`;

const ItemName = styled.h4`
    font-size: 15px;
`;

const MatchingItemsWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 20px;
`;

const differencesStyle = css`
    opacity: 0.7;
    font-size: 12px;
    padding: 5px 0px 0px 10px;
`;

const itemAndChangesStyle = css`
    padding: 10px 0px 0px 10px;
`;

const MatchingItemsTitle = styled.h3`
    margin-left: 20px;
    margin-top: 10px;
`;

const footerBarStyle = css`
    display: flex;
    justify-content: space-between; 
    width: 100%;
    padding-right: 10px;
`;