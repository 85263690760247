import React from 'react';
import UserContainer from '../../../state/containers/UserContainer';
import Loader from '../../../components/general/Loader';
import PageError from '../../../components/general/PageError';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';

interface AcceptorProps {
    container: UserContainer
    orgContainer: OrganizationContainer
    invitationCode: string
    history: any
}

class Acceptor extends React.Component<AcceptorProps> {
    componentWillMount() {
        this.props.container.acceptInvitation(this.props.invitationCode).then(result => {
            if (result.resultType == "Ok") {
                this.props.orgContainer.getOrganizations().then(orgResult => {
                    if (orgResult.resultType == "Ok") {
                        this.props.orgContainer.setCurrentOrgById(result.data.organizationId)

                    }
                })
                this.props.history.push("/v");
            }
            else {

            }
        }).catch(error => {
            alert("Unable to accept the invitation. Please try again later.");
        })
    }
    render() {
        return (
            <div>
                <Loader />
                <PageError errors={this.props.container.state.errors} />
            </div>)
    }
}

export default Acceptor;