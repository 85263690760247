import React, { useState } from "react"
import { createContainer } from "unstated-next"
import VoicifyNotification from "../models/notifications/VoicifyNotification";
import IResult from "../models/result/IResult";
import * as voicifyApi from '../api';

function useNotificationsContainer() {
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([] as VoicifyNotification[]);
    const [errors, setErrors] = useState([] as string[]);

    const getNotifications = async (): Promise<IResult<VoicifyNotification[]>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.getAllNotifications();
            if (result.resultType == "Ok") {
                const newNots = result.data.sort(function (a, b) {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
                setNotifications(newNots);
                setErrors([]);
            }
            else {
                setErrors(result.errors);
            }
            setIsLoading(false);
            return result;
        } catch (e) {
            setIsLoading(false);
            setErrors(e);
        }
    }

    const markAllNotificationsAsRead = async (): Promise<IResult<VoicifyNotification[]>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.markAllNotificationsAsRead();
            if (result.resultType == "Ok") {
                const newNotifications = [...notifications];
                newNotifications.forEach(n => n.isRead = true);
                setNotifications(newNotifications);
                setErrors([]);
            }
            else {
                setErrors(result.errors);
            }
            setIsLoading(false);
            return result;
        } catch (e) {
            setIsLoading(false);
            setErrors(e);
        }
    }

    const markNotificationAsRead = async (notificationId: string): Promise<IResult<VoicifyNotification>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.markNotificationAsRead(notificationId);
            if (result.resultType == "Ok") {
                const newNotifications = [...notifications];
                newNotifications.find(n => n.id === notificationId).isRead = true;
                setNotifications(newNotifications);
                setErrors([]);
            }
            else {
                setErrors(result.errors);
            }
            setIsLoading(false);
            return result;
        } catch (e) {
            setIsLoading(false);
            setErrors(e);
        }
    }

    const clearErrors = () => {
        setErrors([]);
    }


    return {
        isLoading,
        notifications,
        setNotifications,
        errors,
        clearErrors,
        markAllNotificationsAsRead,
        markNotificationAsRead,
        getNotifications
    };
}

const NotificationsContainer = createContainer(useNotificationsContainer);
export default NotificationsContainer;
