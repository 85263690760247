import React from 'react';
import CookieConsent from "react-cookie-consent";
import { css } from '@emotion/css';
import { color_shades_darkest } from '../../constants/colors';
import { COOKIE_TERMS_URL } from '../../constants/Urls';
import { breakpoint_small } from '../../constants/breakpoints';
import styled from '@emotion/styled';

const cookiesIcon = require('../../content/images/cookies-white.svg');

const CookiesComponent: React.FC = () => (
    <div className={containerStyle}>
        <CookieConsent
            disableStyles
            buttonText={"Accept & Close"}
            buttonClasses={"accept"}
        >
            <CookiesConsentWrapper>
                <div className={"innerContainer"}>
                    <div className={"header"}><img src={cookiesIcon} />{`Yup! We use cookies, too!`}</div>
                    <div className={"content"}>
                        {`And now it's mandated that we let you know. Check out our `}
                        <a target={"blank"} className="link" href={COOKIE_TERMS_URL}>Cookie Policy</a>
                        {`. You can manage how cookies are used in your settings at any time.`}
                    </div>
                </div>
                <a href={COOKIE_TERMS_URL} target={"_blank"} className={"learn"}> Learn More </a>
            </CookiesConsentWrapper>
        </CookieConsent>
    </div>
)

const CookiesConsentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const containerStyle = css`
    background: transparent;
    border-radius: 8px;
    display: block;
    color: white;
    z-index: 102;
    position: absolute;
    bottom: 20px;
    left: 20px;
    overflow: hidden; 
    font-family: Muli;
    font-size: 18px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    ${breakpoint_small} {
        bottom: 50px;
        left: auto;
        margin: 16px;
    }
    .cookieConsent {
        background: ${color_shades_darkest};
        padding: 16px 24px;
        width: 575px;
        height: 180px;
        ${breakpoint_small} {
            width: auto;
            height: auto;
        }
    }
    .innerConainer {
        position: relative;
        height: 100%;
    }
    .header {
        border-bottom: solid 1px white;
        display: flex;
        flex-direction: row;
        line-height: 1.33;
        padding-bottom: 16px;
        margin-bottom: 16px;
        img {
            padding-right: 16px;
        }
    }
    .content {
        font-family: Muli;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        .link {
            color: white;
            text-decoration: underline;
        }
    }
    .accept {
        position: relative;
        background: transparent;
        border-color: white;
        border-width: 1px;
        cursor: pointer;
        width: 148px;
        height: 32px;
        border-style: solid;
        border-radius: 16px;
        box-sizing: border-box;
        color: white;
        font-size: 14px;
    }
    .learn {
        position: relative;
        top: 23px;
        left: 450px;
        border-color: transparent;
        border-style: none;
        font-size: 14px;
        color: white;
        cursor: pointer;
        background: transparent;
        ${breakpoint_small} {
            margin: 16px 0;
            top: auto;
            left: auto;
        }
    }
`;

export default CookiesComponent;