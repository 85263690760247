import axios from 'axios';
import { GET_ORGANIZATIONS, GET_SUBSCRIPTIONS } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { queryStringSerialize, sendPost, sendGet, sendPut, sendDelete } from './generic';
import SubscriptionModel from '../../models/organizations/api/SubscriptionModel';

export const getOrganizationSubscriptions = (organizationId: string): Promise<IResult<SubscriptionModel[]>> => {
    return sendGet<SubscriptionModel[]>(`${GET_SUBSCRIPTIONS}/forOrganization/${organizationId}`);
}
export const getOrganizationSubscription = (organizationId: string): Promise<IResult<SubscriptionModel>> => {
    return sendGet<SubscriptionModel>(`${GET_SUBSCRIPTIONS}/${organizationId}/default`);
}