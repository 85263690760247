import React from 'react';
import { css } from '@emotion/css';
import LevelSelector from './LevelSelector';
import ssmlParseService from '../../../services/ssmlParseService';
import SsmlParseService from '../../../services/ssmlParseService';
import TextField from '../TextField';

interface DateSelectorProps {
    onChange: (ssml: string) => void
    ssml?: string
}

interface DateSelectorState {
    value: string
}

class DateSelector extends React.Component<DateSelectorProps, DateSelectorState> {
    private ssmlParser = new SsmlParseService()
    componentWillMount() {
        this.setInitialState();
        // send out the default ssml when it is first added
        var ssml = this.props.ssml ? this.props.ssml : '<say-as interpret-as="date" format="dmy"> </say-as>';
        this.props.onChange(ssml);
    }

    private setInitialState() {
        if (this.props.ssml) {
            // parse the break tag to set state
            const format = this.ssmlParser.getElementProperty(this.props.ssml, 'say-as', 'format')
            if (format) {
                this.setState({
                    ...this.state,
                    value: format ? format : 'dmy'
                })
                return;
            }
        }
        this.setState({
            ...this.state,
            value: 'dmy',
        })
    }

    buildSsml(value?: string) {
        value = value ? value : this.state.value
        const ssml = `<say-as interpret-as="date" format="${value}"> </say-as>`;
        this.props.onChange(ssml);
    }

    handleTextChange(e) {
        this.setState({
            ...this.state,
            value: e.target.value
        })
        this.buildSsml(e.target.value)
    }

    render() {
        return (
            <div>
               <TextField name="format" placeholder="date format (dmy)" value={this.state.value} onChange={this.handleTextChange.bind(this)} onBlur={() => {}} label="Date Format" />
            </div>
        )
    }
}

export default DateSelector;