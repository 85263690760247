import IResult, { ResultType } from "./IResult";

export default class SuccessResult<T> implements IResult<T> {
    data: T;
    resultType: ResultType = "Ok";
    errors: string[] = [];

    constructor (data: T) {
        this.data = data;
    }
}