import React from 'react';
import { css } from '@emotion/css';
import { cool_grey } from '../../constants/colors';

interface SecondaryLoaderProps {
  className?: string
}

const SecondaryLoader: React.SFC<SecondaryLoaderProps> = (props) => (
  <div className={`${wrapperStyle} ${props.className} loader`}>
    <div className="spinner">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  </div>
);

const wrapperStyle = css`
  .spinner {
      margin: -8px auto;
      width: 50px;
      height: 40px;
      text-align: center;
      font-size: 10px;
    }
  .spinner > div {
    background-color: white;
    height: 100%;
    width: 2px;
    margin-right: 5px;
    display: inline-block;
    
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
  &.grey {
    .spinner > div {
      background-color: ${cool_grey};
    }
  }
`;

export default SecondaryLoader;