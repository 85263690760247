import React from 'react';
import { Diff, PendingUpdate } from '../../../../shared/hooks/MenuContainer';
import styled from '@emotion/styled';
import { color_colors_decline, color_colors_growth, color_shades_darker } from '../../../../shared/constants/colors';
import ContentCollapsingSection from '../../../../shared/components/structure/ContentCollapsingSection';

interface MenuManagementSaveChangesDiff {
    pendingMenuUpdates: PendingUpdate[];
}

const MenuManagementSaveChangesDiff: React.FC<MenuManagementSaveChangesDiff> = (props) => {

    const menuItemPropertyNames = {
        applicationId: '',
        comboId: '',
        id: '',
        comboOnly: '',
        originalName: '',
        colloquialName: 'Referred to As',
        fullName: '',
        defaultInferrence: '',
        defaultOnConflict: '',
        defaultOnConflictChoiceWithOption: '',
        allowHalving: '',
        ignoreNegatorSelections: '',
        isPrimaryMenuItem: '',
        requiredFlags: '',
        defaultInCombo: '',
        optionGroups: 'Option Group',
        sizeGroups: 'Size Group',
        isDisabled: "Can't Order",
        transferIfOrdered: 'Transfer If Ordered',
        additionalSynonyms: 'Synonyms',
        attributes: 'Description | Category',
        isAugmented: 'Prompts',
        persistentProperties: 'Persistent Properties',
        lastAugmentStartTime: '',
        lastAugmentFinishTime: ''
    };

    const getMenuItemPropertyName = (key: string) => {
        let updatedKey = key;
        if (updatedKey.includes(".")) {
            updatedKey = key.substring(0, key.indexOf('.'));
        };
        return menuItemPropertyNames[updatedKey] || updatedKey;
    };

    const filteredPendingMenuUpdates = () => {

        const consolidatedUpdates: PendingUpdate[] = [];

        for (const pendingMenuUpdate of props.pendingMenuUpdates) {

            if (pendingMenuUpdate.diffs.length === 0) {
                continue;
            };

            const consolidatedDiffs = {};

            for (const diff of pendingMenuUpdate.diffs) {

                if (diff.key.includes("optionGroups") || diff.key.includes("sizeGroups")) {
                    // there are a lot of change details in the optionGroups and sizeGroups, so we are ignoring them to not overwhelm the user
                    diff.value = {
                        original: '',
                        current: ''
                    };

                };

                // if there is more than one change to the same property, consolidate them
                const updatedKey = diff.key.includes(".") ? diff.key.substring(0, diff.key.indexOf('.')) : diff.key;

                if (!consolidatedDiffs[updatedKey]) {
                    consolidatedDiffs[updatedKey] = { ...diff };
                } else {
                    consolidatedDiffs[updatedKey].value = {
                        original: consolidatedDiffs[updatedKey].value.original
                            ? `${consolidatedDiffs[updatedKey].value.original}, ${diff.value.original ?? ''}`.trimEnd()
                            : diff.value.original,
                        current: consolidatedDiffs[updatedKey].value.current && diff.value.current
                            ? `${consolidatedDiffs[updatedKey].value.current}, ${diff.value.current}`
                            : consolidatedDiffs[updatedKey].value.current || diff.value.current
                    };
                };

            };

            consolidatedUpdates.push({
                ...pendingMenuUpdate,
                diffs: Object.values(consolidatedDiffs)
            });

        };

        const filteredUpdates = consolidatedUpdates
            .map(consolidatedUpdate => {
                const diffKeysToIgnore = ['lastAugmentStartTime', 'lastAugmentFinishTime', 'persistentProperties']; // Ignoring persistent props for now
                return {
                    ...consolidatedUpdate,
                    diffs: consolidatedUpdate.diffs.filter(diff => !diffKeysToIgnore.some(ignoreKey => diff.key && diff.key.includes(ignoreKey)))
                };
            })
            .filter(consolidatedUpdate => consolidatedUpdate.diffs.length > 0);


        return filteredUpdates;

    };

    const renderDiffValues = (diff: Diff) => {
        if (diff.value.original && !diff.value.current) {
            // if only original value exists, treat it as the current value
            return (
                <CurrentDiffValue>{diff.value.original}</CurrentDiffValue>
            )
        } else if (diff.value.original && diff.value.current) {
            return (
                <DiffValuesWrapper>
                    <OriginalDiffValue>{diff.value.current}</OriginalDiffValue>
                    <CurrentDiffValue>{diff.value.original}</CurrentDiffValue>
                </DiffValuesWrapper>
            )
        } else if (!diff.value.original && diff.value.current) {
            return (
                <CurrentDiffValue>{diff.value.current}</CurrentDiffValue>
            )
        } else if (diff.key === "isDisabled") {
            return (
                <DiffValuesWrapper>
                    <OriginalDiffValue>Can Order</OriginalDiffValue>
                    <CurrentDiffValue>Can't Order</CurrentDiffValue>
                </DiffValuesWrapper>
            )
        } else {
            if (diff.key.includes("optionGroups") || diff.key.includes("sizeGroups")) {
                return (
                    <NoDiffText>We've hidden {getMenuItemPropertyName(diff.key)} changes for brevity.</NoDiffText>
                )
            } else {
                return null;
            }
        }
    };

    return (
        <>
            {filteredPendingMenuUpdates().map((pendingMenuUpdate, index) => {
                return (
                    <div key={pendingMenuUpdate.menuItemId}>
                        <CustomContentCollapsingSection
                            title={pendingMenuUpdate.menuItemName}
                            isCollapsedByDefault={true}
                            bottom={index === filteredPendingMenuUpdates().length - 1 ? true : false}
                        >
                            <DiffWrapper>
                                {pendingMenuUpdate.diffs.map((diff, index) => {
                                    if (diff) {
                                        return (
                                            <DiffValueWrapper key={`diffs.${index}`}>
                                                <DiffProperty>{getMenuItemPropertyName(diff.key)}</DiffProperty>
                                                {renderDiffValues(diff)}
                                            </DiffValueWrapper>
                                        )
                                    } else {
                                        return (
                                            <NoDiffText>no diff</NoDiffText>
                                        )
                                    }
                                })}
                            </DiffWrapper>
                        </CustomContentCollapsingSection>
                    </div>
                )
            })}
        </>
    )
};

const NoDiffText = styled.div`
    color: ${color_shades_darker};
`;

const CustomContentCollapsingSection = styled(ContentCollapsingSection)`
    .card-title-container {
        h4 {
            font-size: 16px;
        }
    }
`;

const DiffValuesWrapper = styled.div`
    display: flex;
`;

const DiffProperty = styled.div``;

const DiffWrapper = styled.div``;

const DiffValueWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    font-size: 12px;
    margin-bottom: 8px;
`;

const OriginalDiffValue = styled.div`
    margin-right: 8px;
    text-decoration: line-through;
    color: ${color_colors_decline};
`;

const CurrentDiffValue = styled.div`
    color: ${color_colors_growth};
`;

export default MenuManagementSaveChangesDiff;