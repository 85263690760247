import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import FeatureModel from '../../models/features/api/FeatureModel';
import ApplicationFeatureModel from '../../models/features/api/ApplicationFeatureModel';
import IResult from '../../models/result/IResult';
import UpdateApplicationFeatureRequest from '../../models/features/api/UpdateApplicationFeatureRequest';
import ConversationCountModel from '../../models/features/api/ConversationCountModel';
import _ from 'lodash';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import BulkContentRequest from '../../models/features/api/BulkContentRequest';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import ResponseInstanceModel from '../../models/features/api/ResponseInstanceModel';
import { WelcomeMessagesFeatureTypeId, FallbackFeatureTypeId, HelpMessagesFeatureTypeId, ExitMessagesFeatureTypeId, QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, NumberRangeFeatureTypeId, SimpleChoiceFeatureTypeId, CustomRequestsFeatureTypeId } from '../../constants/featureTypeIds';
import ContentItemPublishHistoryModel from '../../models/features/api/ContentItemPublishHistoryModel';
import { string } from 'yup/lib/locale';
import ContentItemReferenceModel from '../../models/applications/api/backups/ContentItemReferenceModel';

type FeaturesState = {
    allFeatures: ApplicationFeatureModel[]
    systemFeatures: FeatureModel[]
    conversationCounts: ConversationCountModel[]
    generalContentItems: GenericContentItemModel[]
    isLoadingSystemFeatures: boolean
    isLoadingFeatures: boolean
    isAddingFeature: boolean
    isDeleting: boolean
    isDeletingModule: boolean
    isLoadingBulkOperation: boolean
    loadingAppFeatureIds?: string[]
    newApplicationFeatureId?: string
    currentTopicFeatureId?: string
    errors: string[]
    isMoving: boolean
    isCopying: boolean
    featureContentPublishStatus: ContentItemPublishHistoryModel[]
    loadedPublishHistoryFeatureId: string
}

export default class FeatureContainer extends Container<FeaturesState> {
    // default state
    state = {
        allFeatures: [] as ApplicationFeatureModel[],
        systemFeatures: [] as FeatureModel[],
        conversationCounts: [] as ConversationCountModel[],
        generalContentItems: [] as GenericContentItemModel[],
        featureContentPublishStatus: [] as ContentItemPublishHistoryModel[],
        loadedPublishHistoryFeatureId: "",
        isLoadingSystemFeatures: false,
        isLoadingFeatures: false,
        isAddingFeature: false,
        isDeleting: false,
        isMoving: false,
        isCopying: false,
        isDeletingModule: false,
        isLoadingBulkOperation: false,
        newApplicationFeatureId: null,
        loadingAppFeatureIds: [],
        currentTopicFeatureId: null,
        errors: []
    }

    async clearErrors() {
        await this.setState({ ...this.state, errors: [] });
    }
    
    removeByKey(array, params) {
        array.some(function (item, index) {
            if (array[index][params.key] === params.value) {
                // found it!
                array.splice(index, 1);
                return true; // stops the loop
            }
            return false;
        });
        return array;
    }
    setCurrentTopicFeature(applicationFeatureId: string) {
        return this.setState({
            ...this.state,
            currentTopicFeatureId: applicationFeatureId
        })
    }
    getCurrentChildFeatures() {
        return this.state.allFeatures.filter(af => af.parentId == this.state.currentTopicFeatureId);
    }
    findFeatureById(applicationFeatureId: string) {
        return this.state.allFeatures.find(af => af.id == applicationFeatureId);
    }
    findTopicFeatureByChildId(applicationFeatureId) {
        const feature = this.findFeatureById(applicationFeatureId);
        if (!feature?.parentId) return feature;

        return this.findTopicFeatureByChildId(feature.parentId);
    }

    refreshForAppFeature(applicationId: string, applicationFeatureId: string, background: boolean = false): Promise<[IResult<ApplicationFeatureModel[]>, IResult<GenericContentItemModel[]>]> {
        if(!background) {
            this.setState({
                ...this.state,
                loadingAppFeatureIds: [...this.state.loadingAppFeatureIds, applicationFeatureId],
                isLoadingFeatures: true
            });
        }
        const getFeaturesPromise = voicifyApi.getFeaturesForApp(applicationId);
        const getContentPromise = voicifyApi.getContentItemsForFeature(applicationFeatureId);
        const promise = Promise.all([getFeaturesPromise, getContentPromise]);
        promise.then(results => {
            const featuresResult = results[0];
            const contentResult = results[1];
            if (featuresResult.resultType == "Ok" && contentResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    allFeatures: featuresResult.data,
                    isLoadingFeatures: false,
                    loadingAppFeatureIds: this.state.loadingAppFeatureIds.filter(s => s !== applicationFeatureId),
                    generalContentItems: contentResult.data,
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: [...featuresResult.errors, ...contentResult.errors],
                    loadingAppFeatureIds: this.state.loadingAppFeatureIds.filter(s => s !== applicationFeatureId),
                })
            }
        })
        return promise;
    }

    getFeaturesForApp(applicationId: string): Promise<IResult<ApplicationFeatureModel[]>> {
        this.setLoading(true);
        const promise = voicifyApi.getFeaturesForApp(applicationId);
        promise.then(featuresResult => {
            if (featuresResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    allFeatures: featuresResult.data,
                    isLoadingFeatures: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: featuresResult.errors,
                    isLoadingFeatures: false
                })
            }
        }).catch(error => {
            console.log(error);
        })
        return promise;
    }

    async getApplicationFeature(applicationFeatureId: string): Promise<IResult<ApplicationFeatureModel>> {
        try {
            return await voicifyApi.getApplicationFeature(applicationFeatureId);
        } catch (e) {
            this.setState({
                ...this.state,
                loadingAppFeatureIds: this.state.loadingAppFeatureIds.filter(s => s !== applicationFeatureId),
                errors: [e.toString()]
            })
        };
    }

    async getContentItemsForAppFeature(applicationFeatureId: string): Promise<IResult<GenericContentItemModel[]>> {
        try {

        await this.setState({
            ...this.state,
            loadingAppFeatureIds: [...this.state.loadingAppFeatureIds, applicationFeatureId]
        })
        var result = await voicifyApi.getContentItemsForFeature(applicationFeatureId);
            if (result.resultType == "Ok") {
                const contentItems = this.state.generalContentItems.filter(c => c.applicationFeatureId != applicationFeatureId);
                contentItems.push(...result.data);

                await this.setState({
                    ...this.state,
                    loadingAppFeatureIds: this.state.loadingAppFeatureIds.filter(s => s !== applicationFeatureId),
                    generalContentItems: contentItems,
                    errors: []
                })
            } else {
                await this.setState({
                    ...this.state,
                    loadingAppFeatureIds: this.state.loadingAppFeatureIds.filter(s => s !== applicationFeatureId),
                    errors: result.errors
                })

            }
        } catch (e) {
            this.setState({
                ...this.state,
                loadingAppFeatureIds: this.state.loadingAppFeatureIds.filter(s => s !== applicationFeatureId),
                errors: [e.toString()]
            })
        };

        return result;
    }

    findContentById(contentItemId: string) {
        return this.state.generalContentItems.find(c => c.id == contentItemId);
    }

    getContentByFeatureId(applicationFeatureId: string) {
        return this.state.generalContentItems.filter(c => c.applicationFeatureId == applicationFeatureId);
    }

    addContentItemToState(contentItem: GenericContentItemModel) {
        this.setState({
            ...this.state,
            generalContentItems: [contentItem, ...this.state.generalContentItems]
        })
    }

    getFeaturesForParent(applicationFeatureId: string) {
        return _.orderBy(this.state.allFeatures.filter(af => af.parentId == applicationFeatureId), af => af.priority, 'asc');
    }

    getRootFeature(applicationId: string) {
        return this.state.allFeatures.find(af => !af.parentId && af.applicationId == applicationId)
    }
    getTopLevelFeatures(applicationId: string) {
        const root = this.getRootFeature(applicationId);
        if (!root) return [];
        return _.orderBy(this.state.allFeatures.filter(af => af.parentId == root.id), af => af.priority, 'asc');
    }
    getAllAncestors(applicationFeatureId: string) {
        const feature = this.findFeatureById(applicationFeatureId);
        if (!feature) return [];
        const output = [];
        output.push(feature);
        var parentId = feature.parentId;
        while (parentId) {
            const parent = this.findFeatureById(parentId);
            output.push(parent);
            parentId = parent?.parentId;
        }
        return output;
    }
    getCurrentFeature() {
        return this.state.allFeatures.find(af => af.id == this.state.currentTopicFeatureId);
    }
    getTopFeature(applicationFeatureId: string) {
        var appFeature = this.findFeatureById(applicationFeatureId);
        while (!appFeature) {
            if (!appFeature?.parentId) return appFeature;
            appFeature = this.findFeatureById(appFeature?.parentId);
        }
    }
    getTopLevelFeaturesForModule(applicationModuleId: string, applicationId: string) {
        return _.sortBy(this.state.allFeatures.filter(af => af.applicationId == applicationId && af.applicationModuleId == applicationModuleId && !af.parentId), af => af.priority)
    }

    findGeneralByFeatureType(featureTypeId: string): ApplicationFeatureModel {
        const appFeature = this.state.allFeatures.find(a => a.feature.featureType.id == featureTypeId);
        return appFeature;
    }

    getConversationCounts(applicationId: string) {
        var promise = voicifyApi.getAppFeatureConversationCount(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var counts = this.state.conversationCounts;
                result.data.forEach(count => {
                    var existingCount = counts.find(c => c.applicationFeatureId == count.applicationFeatureId);
                    if (existingCount == null) {
                        counts.push(count);
                    }
                    else {
                        counts[counts.indexOf(existingCount)] = count;
                    }
                });

                this.setState({
                    ...this.state,
                    conversationCounts: counts
                })
            }
        })
        return promise;
    }

    getConversationCountForFeature(applicationFeatureId: string): number {
        const appFeatureCount = this.state.conversationCounts.find(af => af.applicationFeatureId == applicationFeatureId);
        if (appFeatureCount === null || appFeatureCount === undefined) {
            return 0;
        }

        var count = appFeatureCount.conversationCount;
        this.getFeaturesForParent(applicationFeatureId)?.forEach(af => {
            count += (this.getConversationCountForFeature(af.id) ?? 0);
        })
        return count;
    }

    getSystemFeatures(): Promise<IResult<FeatureModel[]>> {
        if (this.state.systemFeatures.length > 0) {
            return new Promise((resolve) => resolve(
                {
                    data: this.state.systemFeatures,
                    errors: null,
                    resultType: "Ok"
                }));
        }

        this.setState({
            ...this.state,
            isLoadingSystemFeatures: true
        })
        var promise = voicifyApi.getSystemFeatures();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    systemFeatures: result.data,
                    isLoadingSystemFeatures: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoadingSystemFeatures: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoadingSystemFeatures: false
            })
        })
        return promise;

    }

    addApplicationFeature(applicationId: string, name: string, parentApplicationFeatureId: string) {
        this.setState({
            ...this.state,
            isAddingFeature: true
        });
        var promise = voicifyApi.addFeature(applicationId, { name, parentApplicationFeatureId });
        promise.then(result => {
            if (result.resultType == "Ok") {
                const features = this.state.allFeatures;
                features.unshift(result.data);
                this.setState({
                    ...this.state,
                    isAddingFeature: false,
                    errors: [],
                    allFeatures: features
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isAddingFeature: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isAddingFeature: false,
                errors: [e.toString()]
            })
        })
        return promise;
    }

    deleteApplicationFeature(applicationFeatureId: string): Promise<IResult<ApplicationFeatureModel>> {
        this.setState({
            ...this.state,
            isDeleting: true
        })

        const promise = voicifyApi.disableApplicationFeature(applicationFeatureId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const features = this.state.allFeatures;
                features.splice(features.indexOf(this.findFeatureById(applicationFeatureId)), 1);

                this.setState({
                    ...this.state,
                    allFeatures: features,
                    isDeleting: false,
                    errors: result.errors
                })
            }
            this.setState({
                ...this.state,
                isDeleting: false
            })
        }).catch(error => {
            this.setState({
                ...this.state,
                isDeleting: false,
                errors: [error]
            })
        })

        return promise;
    }

    updateApplicationFeature(applicationFeatureId: string, model: UpdateApplicationFeatureRequest): Promise<IResult<ApplicationFeatureModel>> {
        var existingFeature = this.findFeatureById(applicationFeatureId);
        existingFeature.name = model.name;
        this.setState({
            ...this.state,
            allFeatures: this.state.allFeatures // TODO: check if this works
        })
        const promise = voicifyApi.updateApplicationFeature(applicationFeatureId, model);
        promise.then(result => {
            if (result.resultType != "Ok") {
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error]
            })
        })

        return promise;
    }

    moveFeatureUp(applicationFeatureId: string) {
        const feature = this.findFeatureById(applicationFeatureId);

        // sort locally
        var familyFeatures = _.orderBy(this.state.allFeatures.filter(af => af.parentId == feature.parentId && af.applicationId == feature.applicationId), af => af.priority, 'asc').map(af => af.id);
        var originalIndex = familyFeatures.indexOf(feature.id);
        if (originalIndex <= 0) return;
        familyFeatures = this.moveFeatureInParent(familyFeatures, originalIndex, originalIndex - 1);

        this.setFeatureOrder(feature.applicationId, feature.parentId, familyFeatures);
    }

    moveFeatureDown(applicationFeatureId: string) {
        const feature = this.findFeatureById(applicationFeatureId);

        // sort locally
        var familyFeatures = _.orderBy(this.state.allFeatures.filter(af => af.parentId == feature.parentId && af.applicationId == feature.applicationId), af => af.priority, 'asc').map(af => af.id);
        var originalIndex = familyFeatures.indexOf(feature.id);
        if (originalIndex >= familyFeatures.length) return;
        familyFeatures = this.moveFeatureInParent(familyFeatures, originalIndex, originalIndex + 1);

        this.setFeatureOrder(feature.applicationId, feature.parentId, familyFeatures);
    };

    setFeatureOrder(applicationId: string, parentApplicationFeatureId: string, sortedAppFeatureIds: string[]) {

        // set local state for perf, then call api
        const allFeatures = this.state.allFeatures;
        sortedAppFeatureIds.forEach((id, index) => {
            const feature = allFeatures.find(af => af.id == id);
            feature.priority = index;
        });

        this.setState({
            ...this.state,
            allFeatures
        })

        // sort in api
        voicifyApi.setFeatureOrder(applicationId, parentApplicationFeatureId, sortedAppFeatureIds).then(result => {
            if (result.resultType != "Ok")
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
        }).catch(e => {
            this.setState({
                ...this.state,
                errors: [e.toString()]
            })
        });
    };

    setFeatureContentItemsOrder(applicationFeatureId: string, sortedContentItems: GenericContentItemModel[]) {
        
        // set local state for perf, then call api
        let priority = 0;
        // Set new Priority based on order
        const itemsOrdered = sortedContentItems.map(item => {
            let itemWithPriority: GenericContentItemModel = item;
            itemWithPriority.priority = priority++;
            return itemWithPriority;
        });

        this.setState({
            ...this.state,
            generalContentItems: itemsOrdered
        });

        const contentItemsRequest: ContentItemReferenceModel[] = sortedContentItems.map((item) => {
            const refItem: ContentItemReferenceModel = {
                contentId: item.id,
                featureTypeId: item.featureTypeId
            };
            return refItem;
        });

        // sort in api
        voicifyApi.setFeatureContentItemsOrder(applicationFeatureId, contentItemsRequest).then(result => {
            if (result.resultType != "Ok")
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
        }).catch(e => {
            this.setState({
                ...this.state,
                errors: [e.toString()]
            })
        });
    };

    private moveFeatureInParent(features: string[], originalPosition: number, newPosition: number) {
        if (newPosition >= features.length) {
            var k = newPosition - features.length + 1;
            while (k--) {
                features.push(undefined);
            }
        }
        features.splice(newPosition, 0, features.splice(originalPosition, 1)[0]);
        return features;
    };

    bulkMoveToAppFeature(applicationFeatureId: string, request: BulkContentRequest) {
        this.setState({
            ...this.state,
            isMoving: true
        });
        var returnPromise = this.bulkOperation(request.applicationId, voicifyApi.bulkMoveTo(applicationFeatureId, request));
        returnPromise.then(() => {
            this.refreshForAppFeature(request.applicationId, applicationFeatureId);
            this.setState({
                ...this.state,
                isMoving: false
            });
        })
        return returnPromise;
    }
    bulkCopyToAppFeature(applicationFeatureId: string, request: BulkContentRequest) {
        this.setState({
            ...this.state,
            isCopying: true
        });
        var returnPromise = this.bulkOperation(request.applicationId, voicifyApi.bulkCopyTo(applicationFeatureId, request));
        returnPromise.then(() => {
            this.refreshForAppFeature(request.applicationId, applicationFeatureId);
            this.setState({
                ...this.state,
                isCopying: false
            });
        })
        return returnPromise;
    }
    bulkDeleteContent(applicationFeatureId: string, request: BulkContentRequest) {
        this.setState({
            ...this.state,
            isDeleting: true,
        });
        var returnPromise = this.bulkOperation(request.applicationId, voicifyApi.bulkDisableAndDelete(request));
        returnPromise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    generalContentItems: this.state.generalContentItems.filter(c => !request.content.some(rc => rc.id === c.id)),
                    allFeatures: this.state.allFeatures.filter(c => !request.applicationFeatures.some(rf => rf.id === c.id)),
                    isDeleting: false
                })
            }
            else
                this.setState({
                    ...this.state,
                    isDeleting: false
                });
        })
        return returnPromise;
    }

    // After full update need to synchronize changes by updating feature's content items state
    addOrUpdateContentItem(contentItem: ContentItemModel, response: ResponseInstanceModel, featureTypeId: string) {
        const generalContentItems = this.state.generalContentItems;
        const genericItem = generalContentItems.find(c => c.id == contentItem?.id);
        var newItem = {
            ...genericItem,
            ...contentItem,
            featureTypeId,
            hasAudio: response?.audio != null,
            hasBackgroundImage: response?.backgroundImage != null,
            hasForegroundImage: response?.largeImage != null,
            hasVideo: response?.video != null
        }
        if (!genericItem){
            // New content item
            generalContentItems.push(newItem);
        }
        else {
            // Edited content item
            generalContentItems[generalContentItems.indexOf(genericItem)] = newItem;
        }

        this.setState({
            ...this.state,
            generalContentItems
        })
    }

    private async bulkOperation(applicationId: string, promise: Promise<IResult<string>>) {
        this.setState({
            ...this.state,
            isLoadingBulkOperation: true
        });
        try {
            var result = await promise;

            if (result?.resultType == "Ok") {                
                this.getConversationCounts(applicationId);
                this.setState({
                    ...this.state,
                    isLoadingBulkOperation: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoadingBulkOperation: false,
                    errors: result.errors
                })
            }
        }
        catch (e) {
            this.setState({
                ...this.state,
                isLoadingBulkOperation: false,
                errors: [e?.toString()]
            })
        }

        return promise;
    }

    getFeatureTypeById(id: string) {
        const feature = this.state.systemFeatures.find(f => f.featureType.id == id);
        if (!feature) return null;
        return feature.featureType;
    }

    toggleLive(contentId: string, featureTypeId: string, isLive: boolean) {
        var promise;
        switch (featureTypeId) {
            case WelcomeMessagesFeatureTypeId:
                promise = voicifyApi.toggleWelcomeMessageLive(contentId, isLive);
                break;
            case FallbackFeatureTypeId:
                promise = voicifyApi.toggleFallbackMessageLive(contentId, isLive);
                break;
            case HelpMessagesFeatureTypeId:
                promise = voicifyApi.toggleHelpMessageLive(contentId, isLive);
                break;
            case ExitMessagesFeatureTypeId:
                promise = voicifyApi.toggleExitMessageLive(contentId, isLive);
                break;
            case QuestionAnswerFeatureTypeId:
                promise = voicifyApi.toggleQuestionAnswerLive(contentId, isLive);
                break;
            case LatestMessagesFeatureTypeId:
                promise = voicifyApi.toggleLatestMessageLive(contentId, isLive);
                break;
           case NumberRangeFeatureTypeId:
                promise = voicifyApi.toggleNumberRangeLive(contentId, isLive);
                break;
            case SimpleChoiceFeatureTypeId:
                promise = voicifyApi.toggleSimpleChoiceLive(contentId, isLive);
                break;
            case CustomRequestsFeatureTypeId:
                promise = voicifyApi.toggleCustomRequestLive(contentId, isLive);
                break;
        }
        const contentItems = this.state.generalContentItems;
        const contentItem = contentItems.find(c => c.id == contentId);
        if (contentItem) {
            contentItems[contentItems.indexOf(contentItem)] = { ...contentItem, isLive }
            this.setState({
                ...this.state,
                generalContentItems: contentItems
            })
        }
        return promise;
    }

    async getApplicationFeatureContentPublishStatus(appFeatureId: string, environmentId: string) {
        try {
            var result = await voicifyApi.getApplicationFeaturePublishHistory(appFeatureId, environmentId, 0, 1);

            if (result?.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    featureContentPublishStatus: result.data,
                    loadedPublishHistoryFeatureId: appFeatureId,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
            }
        }
        catch (e) {
            this.setState({
                ...this.state,
                isLoadingBulkOperation: false,
                errors: [e?.toString()]
            })
        }

        return result;
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoadingFeatures: isLoading
        });
    }
}
