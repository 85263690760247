import React, { useEffect } from 'react'
import { breakpoint_small } from '../../../constants/breakpoints';
import TermsContainer from '../../../hooks/TermsContainer';
import CheckboxField from '../../../components/forms/CheckboxField';
import { css } from '@emotion/css';
const expandIcon = require('../../../content/images/expand-white.svg');

interface TermsConditionI{
    handleChange: (e: React.ChangeEvent<any>) => void
    handleBlur: (e: React.ChangeEvent<any>) => void
    isSubmitting: boolean
    termsAgreed: boolean
}

const TermsCondition: React.FC<TermsConditionI> = (props)  => {
    const termsHook = TermsContainer.useContainer()
    useEffect(() => {
        termsHook.loadLatestTerm();
    }, []);
    return (
        <div className={termsContainer}>
            <a target={"_blank"} href={termsHook.latestTerm.url} className={`row titleRow`}>
                <span className="titleText">{`Terms & Conditions of use`}</span>
                <img src={expandIcon} />
            </a>
            <div className={`row checkRow`}>
                <CheckboxField name="termsAgreed" onChange={props.handleChange} onBlur={props.handleBlur} disabled={props.isSubmitting} checked={props.termsAgreed} required={true} />
                <span className="acceptText">{`I accept and agree to the terms and conditions`}</span>
            </div>
        </div>
    )
}

const termsContainer = css`
    border: solid 1px white;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    color: white;
    background: transparent;
    .row {
        display: flex;
        flex-direction: row;
        padding: 20px;
        .titleText{            
            padding-top: 4px;
            color: white;
            padding-right: 5px;
        }
    }
    .titleRow {
        font-size: 16px;    
        padding-bottom: 0px;
    }
    .checkRow {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    ${breakpoint_small} {
        padding: 12px;
        .row {
            padding: 0px;
            .titleText {
                padding-top: 0px;
            }
        }
    }
`;

export default TermsCondition;
