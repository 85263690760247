import React, { FocusEvent } from 'react';
import { css } from '@emotion/css';
import TextareaAutosize from 'react-textarea-autosize';
import IconTooltip from '../../general/IconTooltip';
import Button from '../../general/Button';
import FieldError from '../FieldError';
import { silver_two, faded_red, cool_grey, dark_grey_blue, color_colors_ocean, color_shades_light } from '../../../constants/colors';
import SsmlManager from './SsmlManager';
import SsmlPreviewer from './SsmlPreviewer';
import SsmlObject from '../../../models/ssml/SsmlObject';
import SsmlParseService from '../../../services/ssmlParseService';
import TooltipWrapper from '../../general/TooltipWrapper';

const ssmlIcon = require('../../../content/images/ssml/ssml-dark.svg');
const deleteIcon = require('../../../content/images/bin.svg')

interface SsmlFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (value: string) => void
    onFocus?: () => void
    onBlur?: (e: FocusEvent<any>) => void
    onDelete?: () => void
    label?: string
    id?: string
    canDelete?: boolean
    canCopy?: boolean
    required?: boolean
    tooltip?: string
    className?: string
    icon?: string
    applicationId: string
    deletePosition?: IconPosition
}

type IconPosition = "top" | "right";

interface SsmlFieldState {
    isRaw: boolean
    isModalOpen: boolean
    cursorPosition: number
    cursorEndPosition: number
    ssmlObject?: SsmlObject
    currentObject?: SsmlObject
    editingObject?: SsmlObject
    renderError?: string
}

class SsmlField extends React.Component<SsmlFieldProps, SsmlFieldState> {

    private ssmlParser: SsmlParseService = new SsmlParseService()
    private currentEditingTree: SsmlObject = null;

    componentWillMount() {
        this.setState({
            isRaw: true,
            isModalOpen: false,
            cursorPosition: this.props.value ? this.props.value.length : 0,
            cursorEndPosition: this.props.value ? this.props.value.length : 0,
            currentObject: null,
            editingObject: null,
            ssmlObject: this.parseFromString(this.props.value)
        });
    };

    componentDidUpdate(prevProps: SsmlFieldProps) {
        if (prevProps.value != this.props.value) {
            this.setState({
                ...this.state,
                ssmlObject: this.parseFromString(this.props.value)
            })
        }
    };

    private parseFromString(ssml: string): SsmlObject {
        return this.ssmlParser.ssmlToObject(ssml, ['say-as', 'emphasis', 'audio', 'prosody', 'break', 'speak'])
    };

    toggleSsmlEditor(editingObject?: SsmlObject) {
        this.setState({
            ...this.state,
            isModalOpen: !this.state.isModalOpen,
            editingObject: editingObject ? editingObject : null
        })
    };

    togglePreviewer(error?: string) {
        this.setState({
            ...this.state,
            isRaw: !this.state.isRaw,
            renderError: error
        })
    };

    handleSsmlElementSelected(ssmlId: string, currentTree: SsmlObject) {
        const foundElement = this.searchTree(currentTree, ssmlId);
        this.toggleSsmlEditor(foundElement);
        this.currentEditingTree = currentTree;
    };

    private searchTree(ssmlObject: SsmlObject, id: string): SsmlObject {
        if (ssmlObject.id == id) {
            return ssmlObject;
        } else if (ssmlObject.children != null) {
            let i;
            let result = null;
            for (i = 0; result == null && i < ssmlObject.children.length; i++) {
                result = this.searchTree(ssmlObject.children[i], id);
            }
            return result;
        }
        return null;
    };

    updateSsmlObject(ssmlObject: SsmlObject) {
        // find the object and replace it
        const tree = this.currentEditingTree;
        const existingObject = this.searchTree(tree, ssmlObject.id);
        existingObject.attributes = ssmlObject.attributes;
        existingObject.name = ssmlObject.name;
        this.setState({
            ...this.state,
            editingObject: null,
            ssmlObject: tree,
            isModalOpen: false
        });
        this.fireChange(tree)
    };

    updateSsml(ssml: string, ssmlClose?: string, placeholder?: string) {
        const currentValue = this.props.value ? this.props.value : ''
        placeholder = placeholder ? placeholder : '';
        ssmlClose = ssmlClose ? ssmlClose : '';
        if (this.state.currentObject == null) {
            let value = currentValue;
            if (!this.state.cursorPosition && !this.state.cursorEndPosition) {
                value += (ssml + placeholder + ssmlClose);
            } else {
                if (ssmlClose) {
                    value = [value.slice(0, this.state.cursorEndPosition), this.state.cursorEndPosition == this.state.cursorPosition ? placeholder : '', ssmlClose, value.slice(this.state.cursorEndPosition)].join('');

                }
                value = [value.slice(0, this.state.cursorPosition), ssml, value.slice(this.state.cursorPosition)].join('');
            }
            this.props.onChange(value);
        }
        else {
            const tree = this.state.ssmlObject;

            const innerObject = this.searchTree(tree, this.state.currentObject.id);

            innerObject.value = [innerObject.value.slice(0, this.state.cursorPosition), ssml, innerObject.value.slice(this.state.cursorPosition)].join('');

            this.setState({
                ...this.state,
                ssmlObject: tree
            });
            this.fireChange(tree);
        }
        this.setState({
            ...this.state,
            isModalOpen: false,
            cursorEndPosition: 0,
            cursorPosition: 0
        })
    };

    fireChange(ssmlObject?: SsmlObject) {
        if (!ssmlObject) ssmlObject = this.state.ssmlObject
        this.props.onChange(this.ssmlParser.convertToString(ssmlObject));
    };

    handleSsmlObjectChange(ssmlObject: SsmlObject) {
        this.setState({
            ...this.state,
            ssmlObject: ssmlObject
        })
        this.fireChange(ssmlObject);
    };

    handleTextAreaBlur(e: FocusEvent<any>) {
        // keep track of the last position for adding ssml
        this.setState({
            ...this.state,
            cursorPosition: e.target.selectionStart,
            cursorEndPosition: e.target.selectionEnd,
            currentObject: null
        })
        this.props.onBlur(e);
    };

    handlePreviewerBlur(e: FocusEvent<any>, ssmlObject: SsmlObject) {
        e.target.name = this.props.name
        this.setState({
            ...this.state,
            cursorPosition: document.getSelection().anchorOffset,
            currentObject: ssmlObject
        })
        this.props.onBlur(e);
    };

    handleDeleteObject(ssmlObject: SsmlObject) {
        const tree = this.state.ssmlObject;
        this.ssmlParser.findDelete(ssmlObject, tree, true)
        this.setState({
            ...this.state,
            ssmlObject: tree,
            editingObject: null,
            isModalOpen: false
        });
        this.fireChange(tree)
    };

    handleTextAreaFocus(e: FocusEvent<any>) {
        // keep track of the last position for adding ssml
        this.setState({
            ...this.state,
            cursorPosition: e.target.selectionStart
        });
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    handleRichSelectionChange(start, end) {
        this.setState({
            ...this.state,
            cursorPosition: start,
            cursorEndPosition: end
        })
    };

    handleRichRenderError() {
        // only show the raw text and render an error to tell them to fix it
        this.togglePreviewer("Please include a valid SSML format to allow rich-view rendering");
    };

    renderViewer() {
        const props = { ...this.props }
        if (this.state.isRaw) {
            return (
                <TextareaAutosize
                    className={`${textFieldStyles} ${props.icon == null ? noBorder : ''}`}
                    type={props.type}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={this.props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    onBlur={this.handleTextAreaBlur.bind(this)}
                    onFocus={this.handleTextAreaFocus.bind(this)}
                    disabled={props.disabled} />)
        }
        else {
            return <SsmlPreviewer
                className={props.icon == null ? noBorder : null}
                ssmlObject={this.state.ssmlObject}
                placeholder={props.placeholder}
                onChange={this.handleSsmlObjectChange.bind(this)}
                onBlur={this.handlePreviewerBlur.bind(this)}
                onFocus={this.props.onFocus}
                onSelectionChange={this.handleRichSelectionChange.bind(this)}
                onElementSelected={this.handleSsmlElementSelected.bind(this)}
                onRenderError={this.handleRichRenderError.bind(this)}
                disabled={props.disabled} />
        }
    }

    render() {
        const props = { ...this.props }
        return (
            <div className={`${containerStyle} ${props.className} ${props.disabled ? 'disabled' : ''}`} id={props.id}>
                <div className={labelContainer}>
                    {props.label !== undefined ? <label htmlFor={props.name} className={labelStyle}>{props.label}</label> : null}
                    {props.canDelete && (props.deletePosition == undefined || props.deletePosition == "top") ? <Button additionalClasses={deleteIconStyle} type="button" onClick={props.onDelete} themes={["icon"]} icon={deleteIcon} /> : null}
                    {props.tooltip !== undefined ? <IconTooltip text={props.tooltip} className={tooltipStyle} place="top" /> : null}
                    {props.required ? <TooltipWrapper text="Required field"><span className={requiredLabelStyle}>*</span></TooltipWrapper> : null}
                </div>
                <div className={modalContainer}>
                    {this.state.isModalOpen ?
                        <SsmlManager applicationId={this.props.applicationId}
                            onCancel={this.toggleSsmlEditor.bind(this, null)}
                            onAdd={this.updateSsml.bind(this)}
                            onUpdate={this.updateSsmlObject.bind(this)}
                            onRemove={this.handleDeleteObject.bind(this)}
                            ssml={this.state.editingObject ? this.ssmlParser.convertToString(this.state.editingObject) : ""}
                            ssmlObject={this.state.editingObject} />
                        : null}
                </div>
                <div className={`${fieldWrapper} ${props.disabled ? 'disabled' : ''}`}>

                    <div className={`${fieldContainer} ${props.disabled ? 'disabled' : ''}`}>
                        {props.icon !== undefined ?
                            <img className="icon" src={props.icon} /> : null}
                        {this.renderViewer()}
                        <img className="ssml-button" onClick={this.toggleSsmlEditor.bind(this, null)} src={ssmlIcon} />

                    </div>
                    {props.canDelete && props.canCopy && props.deletePosition == "right" ? <Button additionalClasses={deleteLargeIconStyle} type="button" onClick={props.onDelete} themes={["icon"]} icon={deleteIcon} /> : null}
                </div>
                <div className="bottom-container">
                    {this.state.renderError ? <div className="error-text">{this.state.renderError}</div> : null}
                    <div className="view-switch" onClick={this.togglePreviewer.bind(this, null)}>{this.state.isRaw ? 'Rich SSML' : 'Plain SSML'}</div>
                </div>
                <FieldError name={props.name} />
            </div>
        )
    }
}

const noBorder = css`
    border-left: none;
`;

const textFieldStyles = css`
    color: ${dark_grey_blue};
    font-family: Muli;
    text-align: left;
    min-height: 34px;

    line-height: 32px;
    width: 100%;
    border: none; 
    padding: 8px 16px;
    font-size: 14px;
    margin: 12px 0;
    resize: none;
    border-left: 1px solid ${silver_two};
    border-right: 1px solid ${silver_two};
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: normal;
        color: ${cool_grey};
    }
`;
const containerStyle = css`
margin-bottom: 24px;
flex: 1;


&.tight {
    margin-bottom: 8px;
    label {
        margin: 8px 16px 0 16px;
    }
}

.bottom-container {
    display: flex;
    margin: 4px 2px;
    .error-text {
        color: ${faded_red};
        font-size: 12px;
        flex: 1;
    }
    
    .view-switch {
        color: ${color_colors_ocean};;
        font-size: 10px;
        text-align: right;
        margin: 0 8px 0 auto;
        cursor: pointer;
    }
}
`

const modalContainer = css`
    position: relative;

`
const labelStyle = css`
font-family: Muli;
font-size: 14px;
font-weight: 600;
text-align: left;
margin: 0 16px;
`;

const deleteIconStyle = css`
    height: 14px;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 0;
    img {
        height: 14px;
    }
`

const deleteLargeIconStyle = css`
    height: 32px;
    margin-left: 24px;
    margin-right: 0;
    img {
        height: 32px;
        
        &.left-img {
            margin-right: 0;
        }
    }
`

const tooltipStyle = css`
    margin: 0;
    margin-top: 24px;
    margin-bottom: 0;
`

const labelContainer = css`
display: flex;
align-items: center;
`

const fieldWrapper = css`
display: flex;
flex-direction: row;
align-items: center;
`
const requiredLabelStyle = css`
    margin-right: 0;
    margin-left: auto;
    margin-top: 14px;
    margin-bottom: -20px;
    font-family: Muli;
    font-size: 24px;
    color: ${faded_red};
`


const fieldContainer = css`
position: relative;
display: flex;
align-items: center;
width: 100%;
border-radius: 5px;
border: solid 1px ${silver_two}; 
background: white;
margin-top: 8px;
.icon {
    margin: 16px;
}

.ssml-button {
    margin: 18px 6px 0 6px;
    cursor: pointer;
    align-self: start;
}

&.disabled {
    background: ${color_shades_light};
}
`


export default SsmlField