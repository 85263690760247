import React, { useState } from 'react'
import TextField from '../../../../../shared/components/forms/TextField'
import styled from '@emotion/styled';
import { css } from '@emotion/css'
import CheckboxField from '../../../../../shared/components/forms/CheckboxField';
import Button from '../../../../../shared/components/general/Button';
import { color_colors_light_ocean, dark_red } from '../../../../../shared/constants/colors';
import ApplicationModel from '../../../../../shared/models/applications/api/ApplicationModel';
import SelectField from '../../../../../shared/components/forms/SelectField';
import { OutboundCallModel } from '../../../../../shared/models/features/api/OutboundCall/OutboundCallModel';
import OutboundCallContainer from '../../../../../shared/hooks/OutboundCallContainer';
import { CreateOutboundCallRequest } from '../../../../../shared/models/features/api/OutboundCall/CreateOutboundCallRequest';
import { useForm, Controller, Resolver } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SelectFieldFC from '../../../../../shared/components/forms/SelectFieldFC';

const alertTriangle = require('../../../../../shared/content/images/alert-triangle.svg');

interface props {
    application: ApplicationModel,
    organizationId: string,
}

type FormValues = {
    numberToCall: string;
    assistantNumber: string;
    callDate: string;
    startHour: string;
    endHour: string;
};

export default function CreateOutboundCall(props: props) {
    const [isVoicemailChecked, setIsVoicemailChecked] = useState<boolean>(true);
    const [startPeriod, setStartPeriod] = useState<string>('PM');
    const [endPeriod, setEndPeriod] = useState<string>('PM');
    const [customErrorMessage, setCustomErrorMessage] = useState<string>('');

    const outboundCallContainer = OutboundCallContainer.useContainer();

    const resolver: Resolver<FormValues> = async (values) => {
        const errors: any = {};
        if (!values.numberToCall) {
            errors.numberToCall = {
                type: 'required',
                message: 'Number to call is required.',
            };
        } else if (!/^[1][0-9]{10}$/.test(values.numberToCall)) {
            errors.numberToCall = {
                type: 'pattern',
                message: 'Number to call should be in this format 16475465015 and start with 1.',
            };
        }
        if (!values.assistantNumber) {
            errors.assistantNumber = {
                type: 'required',
                message: 'Assistant number is required.',
            };
        }
        if (!values.callDate) {
            errors.callDate = {
                type: 'required',
                message: 'Call date is required.',
            };
        } else if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(values.callDate)) {
            errors.callDate = {
                type: 'pattern',
                message: 'Call date should be in this format 2024-11-06.',
            };
        }
        if (!values.startHour) {
            errors.startHour = {
                type: 'required',
                message: 'Start time is required.',
            };
        } else if (!/^[0-9]{1,2}$/.test(values.startHour) || parseInt(values.startHour) > 12) {
            errors.startHour = {
                type: 'pattern',
                message: 'Start time should be a valid hour and not greater than 12.',
            };
        }
        if (!values.endHour) {
            errors.endHour = {
                type: 'required',
                message: 'End time is required.',
            };
        } else if (!/^[0-9]{1,2}$/.test(values.endHour) || parseInt(values.endHour) > 12) {
            errors.endHour = {
                type: 'pattern',
                message: 'End time should be a valid hour and not greater than 12.',
            };
        }
        return {
            values: Object.keys(errors).length ? {} : values,
            errors,
        };
    };

    const { control, handleSubmit, setValue, formState: { errors } } = useForm<FormValues>({ resolver });

    const onSubmit = async (data: any) => {
        const { numberToCall, callDate, startHour, endHour, assistantNumber } = data;

        // Convert 12-hour format to 24-hour format to make sure it is valid
        const convertTo24Hour = (hour: string, period: string) => {
            let hourInt = parseInt(hour);
            if (period === 'PM' && hourInt !== 12) {
                hourInt += 12;
            } else if (period === 'AM' && hourInt === 12) {
                hourInt = 0;
            }
            return hourInt;
        };

        const startHour24 = convertTo24Hour(startHour, startPeriod);
        const endHour24 = convertTo24Hour(endHour, endPeriod);

        // Check if start time is less than end time
        if (startHour24 > endHour24) {
            setCustomErrorMessage('Start hour should be less than end hour');
            return;
        }

        // Convert callDate and timeRange to UTC
        const localCallDate = new Date(callDate);
        const utcCallDate = new Date(Date.UTC(localCallDate.getUTCFullYear(), localCallDate.getUTCMonth(), localCallDate.getUTCDate()));

        const localStartTime = new Date(localCallDate);
        localStartTime.setHours(startHour24, 0, 0, 0);

        const localEndTime = new Date(localCallDate);
        localEndTime.setHours(endHour24, 0, 0, 0);

        // Adjust utcCallDate if startHour24 is in the evening and converts to the next day in UTC
        if (startHour24 >= 12 && localStartTime.getUTCHours() < startHour24) {
            utcCallDate.setUTCDate(utcCallDate.getUTCDate() + 1);
        }

        const formatTo12Hour = (hour: number) => {
            const period = hour >= 12 ? 'PM' : 'AM';
            const hour12 = hour % 12 || 12;
            return `${hour12}${period}`;
        };

        const startHourUTC = formatTo12Hour(localStartTime.getUTCHours());
        const endHourUTC = formatTo12Hour(localEndTime.getUTCHours());

        const timeRange = `${startHourUTC} - ${endHourUTC}`;
        const request: CreateOutboundCallRequest = {
            applicationSecret: props.application.secret,
            numberToCall: numberToCall,
            timeRange: timeRange,
            callDate: utcCallDate,
            leaveVoicemail: isVoicemailChecked,
            numberOfAttempts: 0,
            frequencyOfAttempts: 0,
            assistantNumber: assistantNumber.value
        }
        const result = await outboundCallContainer.createOutboundCall(request);

        if (result.resultType === 'Ok') {
            outboundCallContainer.setNumbersToCall([...outboundCallContainer.numbersToCall, { ...result.data }]);
            setCustomErrorMessage('');
            // Clear the numberToCall field
            setValue('numberToCall', '');
        }
    }

    return (
        <AddNumberToCallWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={textFieldsStyle}>
                    <>
                        <Controller
                            name="numberToCall"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    useForm={false}
                                    label='Number To Call'
                                    rightLabel='Format: 16475465015'
                                    placeholder='16475465015'
                                    rightLabelStyle={rightLabelStyle}
                                    labelStyle={labelStyle}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="numberToCall"
                            render={({ message }) => (
                                <ErrorMessageWrapper className={errorMessageWrapperStyle}>
                                    <ErrorIcon src={alertTriangle} alt="Error" />
                                    <p>{message}</p>
                                </ErrorMessageWrapper>
                            )}
                        />
                    </>

                    <>
                        <Controller
                            name="assistantNumber"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <SelectFieldFC
                                        field={field}
                                        name='assistantNumber'
                                        label="Assistant Number"
                                        labelStyle={labelStyle}
                                        options={outboundCallContainer?.assistantNumbers?.map(numbers => ({ value: numbers.number, label: `${numbers.number} - ${numbers.name}` }))}
                                        clearable={false}
                                    />
                                );
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="assistantNumber"
                            render={({ message }) => (
                                <ErrorMessageWrapper className={errorMessageWrapperStyle}>
                                    <ErrorIcon src={alertTriangle} alt="Error" />
                                    <p>{message}</p>
                                </ErrorMessageWrapper>
                            )}
                        />
                    </>

                    <>
                        <Controller
                            name="callDate"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    useForm={false}
                                    label='Call Date'
                                    placeholder='2024-11-06'
                                    labelStyle={labelStyle}
                                    className=''
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="callDate"
                            render={({ message }) => (
                                <ErrorMessageWrapper className={errorMessageWrapperStyle}>
                                    <ErrorIcon src={alertTriangle} alt="Error" />
                                    <p>{message}</p>
                                </ErrorMessageWrapper>
                            )}
                        />
                    </>

                    <div className={timeFieldsRowStyle}>
                        <div className={timeFieldWrapperStyle}>
                            <Controller
                                name="startHour"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        useForm={false}
                                        label="Start Hour"
                                        placeholder='5'
                                        labelStyle={labelStyle}
                                        type='number'
                                        className={timeFieldStyle}
                                        tooltip='Place the call between the start and end time'
                                        tooltipStyle={timeTooltipStyle}
                                    />
                                )}
                            />
                            <SelectField
                                useForm={false}
                                useFormTheme={true}
                                options={[{ value: 'AM', label: 'AM' }, { value: 'PM', label: 'PM' }]}
                                onChange={(e) => setStartPeriod(e?.value)}
                                value={startPeriod}
                                clearable={false}
                                className={periodFieldStyle}
                            />
                        </div>
                        <div className={timeFieldWrapperStyle}>
                            <Controller
                                name="endHour"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        useForm={false}
                                        label="End Hour"
                                        placeholder='9'
                                        labelStyle={labelStyle}
                                        type='number'
                                        className={timeFieldStyle}
                                        tooltip='Place the call between the start and end time'
                                        tooltipStyle={timeTooltipStyle}
                                    />
                                )}
                            />
                            <SelectField
                                useForm={false}
                                useFormTheme={true}
                                options={[{ value: 'AM', label: 'AM' }, { value: 'PM', label: 'PM' }]}
                                onChange={(e) => setEndPeriod(e?.value)}
                                value={endPeriod}
                                clearable={false}
                                className={periodFieldStyle}
                            />
                        </div>
                    </div>
                    <div className={startEndHourErrorsStyle}>
                        <div>
                            <ErrorMessage
                                errors={errors}
                                name="startHour"
                                render={({ message }) => (
                                    <ErrorMessageWrapper>
                                        <ErrorIcon src={alertTriangle} alt="Error" />
                                        <p>{message}</p>
                                    </ErrorMessageWrapper>
                                )}
                            />
                        </div>
                        <div>
                            <ErrorMessage
                                errors={errors}
                                name="endHour"
                                render={({ message }) => (
                                    <ErrorMessageWrapper>
                                        <ErrorIcon src={alertTriangle} alt="Error" />
                                        <p>{message}</p>
                                    </ErrorMessageWrapper>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <VoiceMailWrapper>
                    <CheckboxField label='Leave Voicemail' labelStyle={voiceMailCheckboxStyle} onChange={(e) => setIsVoicemailChecked(e.target.checked)} checked={isVoicemailChecked} />
                </VoiceMailWrapper>
                {customErrorMessage &&
                    <div className={customErrorMessageStyle}>
                        <ErrorIcon src={alertTriangle} alt="Error" />
                        <p>{customErrorMessage}</p>
                    </div>
                }
                <Button themes={["action-item"]} text='Schedule' className={addBtnStyle} type="submit" />
            </form>
        </AddNumberToCallWrapper>
    )
}

const AddNumberToCallWrapper = styled.div`
    width: 100%;
`;

const textFieldsStyle = css`
 & > *:first-child {
    padding: 0px;
    margin: 0px;
  }
  & > *:not(:first-child) {
    padding: 0px;
    margin: 0px;
    margin-top: 14px;
  }
`;

const timeFieldsRowStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
`;

const timeFieldWrapperStyle = css`
    display: flex;
    width: 48%;
`;

const periodFieldStyle = css`
    margin: 0;
    margin-left: 4px;
    margin-top: -6px;
`;

const timeFieldStyle = css`
    padding: 0;
    margin: 0;
`;


const labelStyle = css`
    margin: 0;
    margin-bottom: -4px;
    font-weight: 500;
`;

const rightLabelStyle = css`
    padding: 0;
    margin: 0;
    font-size: 12px;
    opacity: 0.8;
`;

const timeTooltipStyle = css`
    margin-left: 4px;
    width: 14px;
    height: 14px;
`;

const VoiceMailWrapper = styled.div`
    margin-top: 24px;
`;

const voiceMailCheckboxStyle = css`
    padding: 0px;
    padding-left: 30px;
`;

const addBtnStyle = css`
    margin: 0px;
    width: 100%;

    :hover{
        background-color: ${color_colors_light_ocean};
    }
`;

const ErrorMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${dark_red};
    font-size: 14px;
    margin-top: 2px;
`;

const ErrorIcon = styled.img`
    margin-right: 4px;
    width: 12px;
    height: 12px;
`;

const startEndHourErrorsStyle = css`
    display: flex;
    justify-content: space-between;
    margin-top: 4px !important;
    & > div {
        width: 48%;
    }
`;

const errorMessageWrapperStyle = css`
    margin-top: 4px !important;
`;

const customErrorMessageStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    color: ${dark_red};
`;
