import NewUserRequest from "../../models/user/NewUserRequest";
import IResult from "../../models/result/IResult";
import User from "../../models/user/User";
import { sendPost, sendPut, sendGet, uploadFile } from "./generic";
import { CREATE_USER, RESET_PASSWORD, SET_PASSWORD, GET_QUESTIONANSWER, GET_CURRENT_USER, UPDATE_USER_DETAILS, UPDATE_USER_IMAGE, GET_USER_TERMS } from "../../constants/Urls";
import ResetRequest from "../../models/user/ResetRequest";
import CreateNewPasswordRequest from "../../models/user/CreateNewPasswordRequest";
import UpdateUserRequest from "../../models/user/UpdateUserRequest";
import UserRoleModel from "../../models/user/UserRoleModel";
import NewApiUserRequest from "../../models/user/NewApiUserRequest";
import ApiUser from "../../models/user/ApiUser";
import UserTermsAgreement from "../../models/term/UserTermsAgreement";
import ChangePasswordRequest from "../../models/user/ChangePasswordRequest";

export const createUser = (request: NewUserRequest): Promise<IResult<User>> => {
    return sendPost<User>(CREATE_USER, request);
}

export const createApiUser = (request: NewApiUserRequest): Promise<IResult<ApiUser>> => {
    return sendPost<ApiUser>(`/User/ApiUser`, request)
}

export const requestPasswordReset = (request: ResetRequest): Promise<IResult<{}>> => {
    return sendPost<any>(RESET_PASSWORD, request);
}

export const setPassword = (request: CreateNewPasswordRequest): Promise<IResult<{}>> => {
    return sendPut<any>(SET_PASSWORD, request);
}

export const getCurrentUser = (): Promise<IResult<User>> => {
    return sendGet<User>(GET_CURRENT_USER);
}

export const changePassword = (request: ChangePasswordRequest): Promise<IResult<any>> => {
    return sendPut<any>(`/User/ChangePassword`, request);
}

export const checkIfUserUsesSso = (email: string): Promise<IResult<boolean>> => {
    return sendGet<boolean>(`/User/useSso/${email}`);
}


export const getCurrentUserTermsAgreement = (): Promise<IResult<UserTermsAgreement>> => {
    return sendGet<UserTermsAgreement>(GET_USER_TERMS);
}

export const updateUserDetails = (request: UpdateUserRequest): Promise<IResult<User>> => {
    return sendPut<User>(UPDATE_USER_DETAILS, request);
}

export const updateProfileImage = (file: File): Promise<IResult<User>> => {
    return uploadFile(UPDATE_USER_IMAGE, file);
}

export const getSystemUserRoles = (): Promise<IResult<UserRoleModel[]>> => {
    return sendGet<UserRoleModel[]>('/User/SystemRoles');
}