import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, color_shades_lighter, color_colors_ocean } from '../../../constants/colors';
import Scrollbars from 'react-custom-scrollbars';
import UserContainer from '../../../state/containers/UserContainer';
import PersonalInfoForm from './PersonalInfoForm';
import UserAvatar from '../../../components/general/UserAvatar';
import Dropzone from 'react-dropzone';
import ChangePasswordForm from './ChangePasswordForm';
const pencil = require('../../../content/images/pencil-blue.svg');

interface UserSettingsProps {
    userContainer: UserContainer
}
interface UserSettingsState {
    imageUrl: string
}
class UserSettings extends React.Component<UserSettingsProps, UserSettingsState> {
    dropZone: Dropzone;
    componentWillMount() {
        this.setState({
            ...this.state,
            imageUrl: this.props.userContainer.state.currentUser.imageUrl
        })
    }
    handleFilePicked(files: File[]) {
        if (files.length > 0) {
            this.readDataUrl(files[0]);
            this.props.userContainer.updateUserImage(files[0]);
        }
        else {
            this.setState({
                ...this.state,
                imageUrl: undefined
            })
        }
    }
    readDataUrl(file: File) {
        const reader = new FileReader();
        reader.onloadend = e => {
            this.setState({
                ...this.state,
                imageUrl: reader.result.toString()
            })
        }
        reader.readAsDataURL(file);
    }
    render() {
        const currentUser = this.props.userContainer.state.currentUser;
        return (
            <div className={containerStyle}>
                <div className={tabTitleStyle}>
                    <div className="title">Account Settings</div>
                    <UserAvatar
                        className={userImage}
                        size={80}
                        firstName={currentUser.firstName}
                        lastName={currentUser.lastName}
                        src={currentUser.imageUrl ?? null} />

                    <Dropzone ref={(node) => { this.dropZone = node }}
                        onDrop={this.handleFilePicked.bind(this)}
                        style={dropzoneStyle}
                        accept={".png,.jpg,.jpeg"}
                        multiple={false}>
                        <div className={editImageStyle}>Edit Image</div>
                        <img className={pencilStyle} src={pencil} />
                    </Dropzone>
                </div>
                <div className={formsContainer}>
                    <PersonalInfoForm userContainer={this.props.userContainer} />
                    <ChangePasswordForm userContainer={this.props.userContainer} />
                </div>
            </div>
        )
    }
}

const pencilStyle = css`
    margin-left: 8px;
`

const editImageStyle = css`
    font-size: 16px;
    line-height: 24px;
`

const tabTitleStyle = css`
    height: 120px;
    border-bottom: 1px solid ${color_shades_dark};
    font-size: 18px;
    position: relative;
    line-height: 24px;
    .title {
        padding-left: 32px;
        padding-top: 16px;
    }
`

const dropzoneStyle: React.CSSProperties = {
    position: 'absolute',
    top: 88,
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    color: color_colors_ocean,
    right: 32,
};

const userImage = css`
    position: absolute;
    z-index: 4;       
    top: 64px;
    left: 33px;
    margin: 0px;
`

const formsContainer = css`
    background-color: ${color_shades_lighter};
    padding: 40px 32px;
`

const containerStyle = css`
    margin-bottom: 80px;
    width: 100%;
    overflow-x: hidden;
`

export default UserSettings;