import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import StatisticsModel from '../../models/analytics/api/StatisticsModel';
import ContentStatisticsModel from '../../models/analytics/ContentStatisticsModel';
import ApplicationMissStatisticsModel from '../../models/analytics/api/ApplicationMissStatisticsModel';
import MissStatisticsModel from '../../models/analytics/MissStatisticsModel';
import TopContentHitModel from '../../models/analytics/api/TopContentHitModel';
import TopContentMissModel from '../../models/analytics/api/TopContentMissModel';
import GenericAnalyticsContainer, { AnalyticsState } from './GenericAnalyticsContainer';
import AnalyticsRequestFilter from '../../models/analytics/AnalyticsRequestFilter';
import AggregatedFeatureTypeInteractions from '../../models/analytics/api/AggregatedFeatureTypeInteractions';
import { FallbackFeatureTypeId } from '../../constants/featureTypeIds';

interface ApplicationAnalyticsState extends AnalyticsState {
    isLoadingTotals: boolean
    appTopHits: TopContentHitModel[]
    appTopMisses: TopContentMissModel[]
    appStats: ContentStatisticsModel[]
    appMissStats: MissStatisticsModel[]
    sparkDisplayOption: string
    aggregatedFeatureTypeInteractions: AggregatedFeatureTypeInteractions[]
    previousAggregatedFeatureTypeInteractions: AggregatedFeatureTypeInteractions[]
}

export default class ApplicationStatisticsContainer extends GenericAnalyticsContainer<ApplicationAnalyticsState> {
    constructor() {
        super();
        this.state = {
            appStats: [] as ContentStatisticsModel[],
            appMissStats: [] as MissStatisticsModel[],
            appTopHits: [] as TopContentHitModel[],
            appTopMisses: [] as TopContentMissModel[],
            aggregatedFeatureTypeInteractions: [] as AggregatedFeatureTypeInteractions[],
            previousAggregatedFeatureTypeInteractions: [] as AggregatedFeatureTypeInteractions[],
            isLoading: false,
            isLoadingTotals: false,
            currentDisplayOption: this.displayOptions[0],
            sparkDisplayOption: this.sparkDisplayOptions[0],
            currentDeltaPeriod: 'Previous Period',
            currentDeltaType: 'Value',
            errors: []
        }
    }

    sparkDisplayOptions = ['Topic Hits', 'Topic Misses', 'AI Ordering', 'AI Ordering Export']

    displayOptions = ['Content Hits', 'Content Misses', 'AI Ordering', 'AI Ordering Export']

    async loadContentHitsByFeatureTypeCounts(applicationId: string, filter: AnalyticsRequestFilter) {
        this.setLoading(true);
        try {
            const result = await voicifyApi.getAggregatedInteractionsByFeatureType(applicationId, filter);
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    aggregatedFeatureTypeInteractions: result.data
                })
                return result.data;
            } else {
                this.setLoading(false);
                return result.errors;
            }
        } catch (error) {
            this.setLoading(false);
            return error;
        }
    }

    async loadPreviousContentHitsByFeatureTypeCounts(applicationId: string, filter: AnalyticsRequestFilter) {
        this.setLoading(true);
        try {
            const result = await voicifyApi.getAggregatedInteractionsByFeatureType(applicationId, filter);
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    previousAggregatedFeatureTypeInteractions: result.data
                })
                return result.data;
            } else {
                this.setLoading(false);
                return result.errors;
            }
        } catch (error) {
            this.setLoading(false);
            return error;
        }
    }

    loadTopContentMissesForApp(applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<TopContentMissModel>> {
        this.setLoading(true);

        const existingStats = this.state.appTopMisses.find(r => this.buildCacheKey(r.applicationId, r.filter) == this.buildCacheKey(applicationId, filter));
        if (existingStats !== undefined && existingStats !== null) {
            this.setLoading(false);
            return new Promise<IResult<TopContentMissModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: {
                    applicationId: existingStats.applicationId,
                    items: existingStats.items,
                    filter
                }
            }))
        }
        const promise = voicifyApi.getApplicationTopContentMisses(applicationId, filter);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var stats = this.state.appTopMisses;
                stats.push({
                    applicationId: applicationId,
                    items: result.data.items,
                    filter
                });
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    appTopMisses: stats
                })
            }
            else {
                this.setLoading(false);
            }
        })
            .catch(() => {
                this.setLoading(false);
            });
        return promise;
    }


    loadTopContentHitsForApp(applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<TopContentHitModel>> {
        this.setLoading(true);

        const existingStats = this.state.appTopHits.find(r => this.buildCacheKey(r.applicationId, r.filter) == this.buildCacheKey(applicationId, filter));
        if (existingStats !== undefined && existingStats !== null) {
            this.setLoading(false);
            return new Promise<IResult<TopContentHitModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: {
                    applicationId: existingStats.applicationId,
                    items: existingStats.items,
                    filter
                }
            }))
        }
        const promise = voicifyApi.getApplicationTopContentHits(applicationId, filter);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var stats = this.state.appTopHits;
                stats.push({
                    applicationId: applicationId,
                    items: result.data.items,
                    filter
                });
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    appTopHits: stats
                })
            }
            else {
                this.setLoading(false);
            }
        })
            .catch(() => {
                this.setLoading(false);
            });
        return promise;
    }



    async loadStats(contextId: string, filter: AnalyticsRequestFilter): Promise<IResult<StatisticsModel>> {
        await this.setLoading(true);

        const previousPeriod = this.getPreviousDates(filter.startDate, filter.endDate);

        const existingStats = this.state.appStats?.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(contextId, filter));
        const existingPrevious = this.state.appStats?.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(contextId, {
            ...filter,
            startDate: previousPeriod.startDate,
            endDate: previousPeriod.endDate
        }));

        if (!existingPrevious)
            await this.reloadStats(contextId, {
                ...filter,
                startDate: previousPeriod.startDate,
                endDate: previousPeriod.endDate
            });

        if (existingStats) {
            this.setLoading(false);
            return new Promise<IResult<StatisticsModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: existingStats.data,
            }))
        }

        // load primary and previous period at the same time
        var primaryPromise = this.reloadStats(contextId, filter);
        return primaryPromise
    }

    reloadStats(applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<StatisticsModel>> {
        this.setLoadingTotals(true);

        const existingStats = this.state.appStats.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(applicationId, filter));

        if (existingStats !== undefined && existingStats !== null) {
            this.setLoadingTotals(false);
            return new Promise<IResult<StatisticsModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: existingStats.data
            }))
        }

        const promise = voicifyApi.getApplicationStats(applicationId, filter);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var usage = this.state.appStats;
                usage.push({
                    id: applicationId,
                    filter,
                    data: result.data
                });
                this.setState({
                    ...this.state,
                    isLoadingTotals: false,
                    errors: [],
                    appStats: usage
                })
            }
            else {
                this.setLoadingTotals(false);
            }
        })
            .catch(() => {
                this.setLoadingTotals(false);
            });

        return promise;
    }


    async loadMissStats(applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ApplicationMissStatisticsModel>> {
        await this.setLoading(true);

        const previousPeriod = this.getPreviousDates(filter.startDate, filter.endDate);

        const existingStats = this.state.appMissStats?.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(applicationId, filter));
        const existingPrevious = this.state.appMissStats?.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(applicationId, {
            ...filter,
            startDate: previousPeriod.startDate,
            endDate: previousPeriod.endDate
        }));

        if (!existingPrevious)
            await this.reloadMissStats(applicationId, {
                ...filter,
                startDate: previousPeriod.startDate,
                endDate: previousPeriod.endDate
            });

        if (existingStats) {
            this.setLoading(false);
            return new Promise<IResult<ApplicationMissStatisticsModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: existingStats.data,
            }))
        }

        // load primary and previous period at the same time
        var primaryPromise = this.reloadMissStats(applicationId, filter);
        return primaryPromise
    }

    async reloadMissStats(applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ApplicationMissStatisticsModel>> {
        this.setLoadingTotals(true);
        const existingStats = this.state.appMissStats.find(r => this.buildCacheKey(r.id, r.filter) == this.buildCacheKey(applicationId, filter));

        if (existingStats !== undefined && existingStats !== null) {
            this.setLoadingTotals(false);
            return new Promise<IResult<ApplicationMissStatisticsModel>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: existingStats.data
            }))
        }

        const promise = voicifyApi.getApplicationMissStats(applicationId, filter);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var usage = this.state.appMissStats;
                usage.push({
                    id: applicationId,
                    filter,
                    data: result.data
                });
                this.setState({
                    ...this.state,
                    isLoadingTotals: false,
                    errors: [],
                    appMissStats: usage
                })
            }
            else {
                this.setLoadingTotals(false);
            }
        })
            .catch(() => {
                this.setLoadingTotals(false);
            });

        return promise;
    }

    private setLoadingTotals(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoadingTotals: isLoading
        })
    }
}