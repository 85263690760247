import { GET_LATESTMESSAGES, GET_LATESTMESSAGES_FOR_MODULE, CREATE_LATESTMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import SimpleChoiceModel from '../../models/features/api/SimpleChoices/SimpleChoiceModel';
import NewSimpleChoiceRequest from '../../models/features/api/SimpleChoices/NewSimpleChoiceRequest';
import BulkUpdateSimpleChoiceRequest from '../../models/features/api/SimpleChoices/BulkUpdateSimpleChoiceRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getSimpleChoicesForModule = (applicationModuleId: string): Promise<IResult<SimpleChoiceModel[]>> => {
    return sendGet<SimpleChoiceModel[]>(`/SimpleChoice/module/${applicationModuleId}`);

}

export const getSimpleChoicesForApp = (applicationId: string): Promise<IResult<SimpleChoiceModel[]>> => {
    return sendGet<SimpleChoiceModel[]>(`/SimpleChoice/${applicationId}`);
}

export const getSimpleChoicesForAppFeature = (applicationFeatureId: string): Promise<IResult<SimpleChoiceModel[]>> => {
    return sendGet<SimpleChoiceModel[]>(`/SimpleChoice/byFeature/${applicationFeatureId}`);
}


export const createFullSimpleChoice = (request: SimpleChoiceModel): Promise<IResult<SimpleChoiceModel>> => {
    return sendPost<SimpleChoiceModel>(`/SimpleChoice/full`, request);
}
export const createSimpleChoice = (request: NewSimpleChoiceRequest): Promise<IResult<SimpleChoiceModel>> => {
    return sendPost<SimpleChoiceModel>(`/SimpleChoice`, request);
}
export const createSimpleChoiceStub = (request: NewSimpleChoiceRequest): Promise<IResult<SimpleChoiceModel>> => {
    return sendPost<SimpleChoiceModel>(`/SimpleChoice/stub`, request);
}

export const toggleSimpleChoiceLive = (simpleChoiceId: string, isLive: boolean) : Promise<IResult<SimpleChoiceModel>> => {
    return sendPut<SimpleChoiceModel>(`/SimpleChoice/${simpleChoiceId}/isLive`, {
        isLive
    });
}
export const toggleSimpleChoiceSync = (simpleChoiceId: string, shouldNotSync: boolean): Promise<IResult<SimpleChoiceModel>> => {
    return sendPut<SimpleChoiceModel>(`/SimpleChoice/${simpleChoiceId}/shouldNotSync`, {
        shouldNotSync
    });
}

export const updateFullSimpleChoice = (simpleChoiceId: string, request: SimpleChoiceModel): Promise<IResult<SimpleChoiceModel>> => {
    return sendPut<SimpleChoiceModel>(`/SimpleChoice/${simpleChoiceId}/full`, request);
}
export const bulkUpdateSimpleChoice = (simpleChoiceId: string, request: BulkUpdateSimpleChoiceRequest): Promise<IResult<SimpleChoiceModel>> => {
    return sendPut<SimpleChoiceModel>(`/SimpleChoice/${simpleChoiceId}/bulkUpdate`, request);
}

export const bulkUpdateSimpleChoiceStub = (simpleChoiceId: string, request: BulkUpdateSimpleChoiceRequest): Promise<IResult<SimpleChoiceModel>> => {
    return sendPut<SimpleChoiceModel>(`/SimpleChoice/${simpleChoiceId}/bulkUpdate/stub`, request);
}
export const deleteSimpleChoice = (simpleChoiceId: string): Promise<IResult<SimpleChoiceModel>> => {
    return sendDelete<SimpleChoiceModel>(`/SimpleChoice/${simpleChoiceId}`);
}
export const findSimpleChoice = (simpleChoiceId: string): Promise<IResult<SimpleChoiceModel>> => {
    return sendGet<SimpleChoiceModel>(`/SimpleChoice/full/${simpleChoiceId}`);
}

export const getSimpleChoiceWebhooks = (simpleChoiceId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/SimpleChoice/${simpleChoiceId}/webhooks`);
}

export const removeSimpleChoiceWebhook = (simpleChoiceWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/SimpleChoice/webhook/${simpleChoiceWebhookId}`);
}

export const addSimpleChoiceWebhook = (simpleChoiceId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/SimpleChoice/${simpleChoiceId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateSimpleChoiceWebhook = (simpleChoiceId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/SimpleChoice/${simpleChoiceId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const copySimpleChoice = (contentItemId: string): Promise<IResult<SimpleChoiceModel>> => {
    return sendPost<SimpleChoiceModel>(`/SimpleChoice/${contentItemId}/copy`, null);
}

export const bulkUpdateSimpleChoiceWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/SimpleChoice/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getSimpleChoicePublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/SimpleChoice/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}