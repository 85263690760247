import React from 'react';
import {css} from '@emotion/css';
import VerticalSeparator from '../../structure/VerticalSeparator';
import { cool_grey, silver_two } from '../../../constants/colors';


interface SsmlPageProps {
    onCancel: () => void
    cancelIcon?: string
    titleIcon?: string
    title?: string
}
const containerStyle = css`

    .header-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
            margin-right: 16px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .body-wrapper {
        margin-top: 22px;
    }
    .close-button {
        width: 19px;
        height: 19px;
        cursor: pointer;
    }

    .separator {
        width: 1px;
        background: ${silver_two};
        height: 22px;
    }

    .title-icon {
        width: auto;
        height: 19px;
    }

    h3 {
        font-family: Muli;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #304562;
        text-transform: uppercase;
    }
`

const SsmlDialog: React.SFC<SsmlPageProps> = (props) => (
    <div className={containerStyle}>
        <div className="header-wrapper">
            <img className="close-button" src={props.cancelIcon} onClick={props.onCancel}/>
            <div className="separator" />
            <img className="title-icon" src={props.titleIcon} />
            <h3>{props.title}</h3>
        </div>
        <div className="body-wrapper">
            {props.children}
        </div>

    </div>
)

export default SsmlDialog;