import React from 'react';
import { css } from '@emotion/css';
import ConversationRequest from '../../models/conversationFlow/ConversationRequest';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import PositionIndicator from '../structure/ConversationFlow/PositionIndicator';
import { dark_grey_blue, highlight_green, dark_grey_blue_20, greeny_blue, cool_grey } from '../../constants/colors';
import DOMPurify from 'dompurify';

const openQuoteIcon = require('../../content/images/quote-open.svg');
const closeQuoteIcon = require('../../content/images/quote-close.svg');
const userIcon = require('../../content/images/user.svg');

interface UserVariationsBubbleProps {
    variations: ConversationRequest[]
    title?: string
    detail?: string
    className?: string
    noHighlights?: boolean
    bubbleWidthPercentage?: number
}
interface UserVariationsBubbleState {
    position: number
}

class UserVariationsBubble extends React.Component<UserVariationsBubbleProps, UserVariationsBubbleState> {

    componentWillMount() {
        this.setState({ position: 0 });
    }


    buildLabel(item: ConversationRequest) {
        if (!item) return null;
        if (!this.props.noHighlights)
            item.highlights.forEach(highlight => {
                item.content = item.content.replace(highlight, `<span>${highlight}</span>`);
            });
        return (
            <p className={inputStyle} key={item.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}>
            </p>
        )
    }

    handlePositionChange(position: number) {
        this.setState({
            ...this.state,
            position
        })
    }

    render() {
        const { title, variations } = this.props;
        if (!variations) return null;
        return (
            <div className={`${containerStyle} ${this.props.className}`}>
                <img src={userIcon} />
                <div className={bubbleContainer(this.props.bubbleWidthPercentage ?? 75)}>
                    {title ? <> <span className="title">{title}</span>
                        <HorizontalSeparator /> </> : null}
                    <img className="open-quote" src={openQuoteIcon} />
                    <div className="content-container">
                        {this.buildLabel(variations[this.state.position])}
                        <p className="detail">{this.props.detail}</p>
                    </div>
                    <img className="close-quote" src={closeQuoteIcon} />
                    {
                        variations.length > 1 ?
                            <>
                                <HorizontalSeparator />
                                <div className="carousel-container">
                                    <span>{`VARIATION: ${this.state.position + 1} of ${variations.length}`}</span>
                                    <PositionIndicator count={variations.length} position={this.state.position} onChange={this.handlePositionChange.bind(this)} />
                                </div>
                            </>
                            : null
                    }
                </div>
            </div>
        );
    }
}

const containerStyle = css`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
    }
`
const bubbleContainer = (bubbleWidth: number): string => {
    return css`
        margin: 30px 80px 0 0;
        border: solid 1px ${greeny_blue};
        box-shadow: -1px 4px 10px 0 ${dark_grey_blue_20};
        background: white;
        border-radius: 0 10px 10px 10px;
        padding: 24px;
        display: flex;
        position: relative;
        flex-direction: column;
        flex: 1;
        @media only screen and (min-width: 1240px) {
            max-width: ${bubbleWidth}%;
            margin-right: auto;
            margin-left: 0;
        }
        .content-container {
            overflow-y: hidden;
            margin-left: 12px;
            .detail {
                font-size: 10px;
                margin-top: 24px;
            }
        }

        .title {
            font-family: Muli;
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${dark_grey_blue};
            margin-bottom: 24px;
        }

        .fade {
            display: none;
            width: 100%;
            height: 12px;
            background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
        }

        .open-quote {
            margin-left: 0;
            margin-right: auto;
            margin-bottom: 16px;
            margin-top: 16px;
        }

        .close-quote {
            margin-right: 0;
            margin-left: auto;
            margin-bottom: 16px;
            margin-top: 0;
        }

        .carousel-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 16px;
            span {
                margin-bottom: 8px;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.4px;
                text-align: center;
                color: ${cool_grey};
            }
        }
    `;
}
const inputStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    margin-top: 12px;
    span {
        background: ${highlight_green};
    }
`;

export default UserVariationsBubble;
