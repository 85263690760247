import React, { useEffect } from 'react';
import SettingsCard from '../../../components/structure/SettingsCard';
import UpdateOrganizationForm from './UpdateOrganizationForm';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import TemplateConfigurationContainer from '../../../state/containers/TemplateConfigurationContainer';
import SparkContainer from '../../../hooks/SparkContainer';
import { aiAnsweringCommonAppName, aiAnsweringCustomModuleName } from '../../../constants/sparkConstants/moduleConsts';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import UpdateApplicationRequest from '../../../models/applications/api/UpdateApplicationRequest';

interface OrganizationInfoSettingsProps {
    orgContainer: OrganizationContainer
    templateConfigurationContainer?: TemplateConfigurationContainer
    commonApp?: ApplicationModel
    appContainer?: ApplicationContainer
}

const OrganizationInfoSettings: React.FC<OrganizationInfoSettingsProps> = (props) => {

    const sparkContainer = SparkContainer.useContainer();

    useEffect(() => {
        if (sparkContainer.inSpark) {
            updateCommonAppNames();
        };
    }, [props.commonApp]);

    const updateCommonAppNames = async () => {

        if (props.commonApp) {

            const orgTemplateConfigs = await props.templateConfigurationContainer.getOrganizationTemplates(props.orgContainer?.state?.currentOrganization?.id);
            const commonAppTemplateConfigs = orgTemplateConfigs.data.filter(templateConfig => templateConfig.templateApplicationId === props.commonApp?.id);

            if ((props.commonApp?.name === aiAnsweringCommonAppName) && (commonAppTemplateConfigs[0].name === aiAnsweringCustomModuleName)) {
                return;
            }

            // update common app name
            if (props.commonApp?.name !== aiAnsweringCommonAppName) {
                const updatedApplication: UpdateApplicationRequest = { ...props.commonApp, name: aiAnsweringCommonAppName };
                props.appContainer.updateApplication(props.commonApp?.id, updatedApplication);
            };

            // update common app template config/module name
            if (orgTemplateConfigs) {
                if (commonAppTemplateConfigs) {
                    if (commonAppTemplateConfigs.length === 1) {
                        if (commonAppTemplateConfigs[0].name !== aiAnsweringCustomModuleName) {
                            const commonAppUpdatedTemplateConfiguration: TemplateConfigurationModel = {
                                ...commonAppTemplateConfigs[0],
                                name: aiAnsweringCustomModuleName
                            }
                            await props.templateConfigurationContainer.updateTemplateConfiguration(commonAppUpdatedTemplateConfiguration);
                        }
                    }
                };
            };

        };

    };

    return (
        <SettingsCard title="Organization Information">
            <UpdateOrganizationForm orgContainer={props.orgContainer} />
        </SettingsCard>
    )
}

export default OrganizationInfoSettings;