import * as voicifyApi from '../../api';
import FallbackMessageModel from '../../models/features/api/Fallback/FallbackMessageModel';
import NewFallbackMessageRequest from '../../models/features/api/Fallback/NewFallbackMessageRequest';
import UpdateFallbackMessageRequest from '../../models/features/api/Fallback/UpdateFallbackMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import FallbackMessageCategoryModel from '../../models/features/api/Fallback/FallbackMessageCategoryModel';
import { FallbackFeatureTypeId } from '../../constants/featureTypeIds';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { EditFallbackMessageFormData } from '../../scenes/formScenes/FallbackMessageForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import IResult from '../../models/result/IResult';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

interface FallbackMessageState extends ContentItemState<FallbackMessageModel> {
    categories: FallbackMessageCategoryModel[]
    isLoadingCategories: boolean
}

export default class FallbackMessageContainer extends GenericContentItemContainer<FallbackMessageState, FallbackMessageModel, NewFallbackMessageRequest, UpdateFallbackMessageRequest, EditFallbackMessageFormData> {

    // default state
    state = {
        contentItems: [] as FallbackMessageModel[],
        isLoading: false,
        errors: [],
        isSaving: false,
        isSavingStub: false,
        isPublishing: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isLoadingCategories: false,
        isLoadingFull: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        categories: [] as FallbackMessageCategoryModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }


    mapModelToGenericContentItemModel = (model: FallbackMessageModel) => ({
        ...model,
        hasAudio: model.audio != null,
        hasVideo: model.video != null,
        hasImage: model.largeImage != null,
        hasBackgroundImage: model.backgroundImage != null,
        featureTypeId: FallbackFeatureTypeId,
    })

    mapFormDataToModel = (originalModel: FallbackMessageModel, formData: EditFallbackMessageFormData) => ({
        ...originalModel,
        webhookChainingType: formData.webhookChainingType,
        title: formData.title,
        category: this.state.categories.find(fc => fc.id == formData.categoryId),
        isLive: formData.isLive,
        languages: formData.languages,
        requiresParent: formData.requiresParent,
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,
        disabledForFeatureFlagIds: formData.disabledForFeatureFlagIds,

        // we null these values out so they don't conflict with the responses field for variations        
        smallImage: null,
        largeImage: null,
        backgroundImage: null,
        audio: null,
        video: null,
        content: null,
        reprompt: null,
        followUp: null,
        mediaResponseContainer: null,
        mediaResponseContainerId: null,
        displayTitleOverride: null,
        displayTextOverride: null,
        followUpId: null,
        responseId: null
    });

    featureTypeId = FallbackFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getFallbackMessagesForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => null; // not implemented
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getFallbackMessagesForAppFeature(applicationFeatureId);
    createPromise = (model: NewFallbackMessageRequest) => voicifyApi.addFallbackMessage(model.applicationId, model);
    createStubPromise = (model: NewFallbackMessageRequest) => voicifyApi.addFallbackMessageStub(model);
    createFromModelPromise = (model: FallbackMessageModel) => voicifyApi.addFullFallbackMessage(model);
    updatePromise = (contentItemId: string, model: UpdateFallbackMessageRequest) => voicifyApi.updateFallbackMessage(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: UpdateFallbackMessageRequest) => voicifyApi.updateFallbackMessageStub(contentItemId, model);
    updateFromModelPromise = (model: FallbackMessageModel) => voicifyApi.updateFullFallbackMessage(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteFallbackMessage(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleFallbackMessageLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleFallbackMessageSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyFallbackMessage(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findFallbackMessage(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getFallbackMessagePublishHistory(contentItemId, environmentId, skip, take);


    getWebhooksPromise = (contentItemId: string) => voicifyApi.getFallbackMessageWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeFallbackMessageWebhook(contentItemWebhookId);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addFallbackMessageWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateFallbackMessageWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateFallbackMessageWebhookInstances(contentItemId, updateRequest);

    getFallbackCategories() {

        // if we already have categories, we don't need to load again
        if (this.state.categories.length > 0) return;

        this.setState({
            ...this.state,
            isLoadingCategories: true
        });

        voicifyApi.getFallbackMessageCategories().then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    categories: result.data,
                    isLoadingCategories: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoadingCategories: false
                })
            }
        }).catch(error => {
            console.log(error);
            this.setState({
                ...this.state,
                errors: [error],
                isLoadingCategories: false
            })
        })
    }

    getSimpleSampleFlows = (contentItem: FallbackMessageModel, application?: ApplicationModel) => [{
        requests: [{ content: "[Unhandled Response]", highlights: [], id: "1" }],
        followUp: contentItem.followUp,
        responses: [{
            content: contentItem.content,
            highlights: [],
            id: "10"
        }],
        requestTitle: "User Says",
        responseTitle: "Device Response"
    }]
}
