import React from 'react';
import { css } from '@emotion/css';
import { cool_grey, dark_grey_blue, color_shades_darkest } from '../../../constants/colors';

interface ValueProps {
    value: number
    min: number
    max: number
}

const container = css`
    align-items: baseline;
    display: inline-flex;
    vertical-align: middle;
    padding-left: 8px;
   .bar {
        width: 1px;
        height: 20px;
        background: ${color_shades_darkest};
   }
   .triangle-wrapper {
        .triangle {
                width: 0;
                height: 0;
                margin-left: 1px;
                border-top: 2px solid transparent;
                border-right: 2px solid ${color_shades_darkest};
                border-bottom: 2px solid transparent;
        }
    }
`
const calculatePadding = (min: number, max: number, value: number) => {
    if(min == null || max == null || value == null) return 0;
    return  (((value - min)/(max - min)) * 16)
}
const SsmlValueMiniIndicator: React.SFC<ValueProps> = (props) => (
    <div data-ignore="true" className={container}>
        <div className="bar"></div>
        <div className="triangle-wrapper" style={{ paddingBottom: calculatePadding(props.min, props.max, props.value) }}>
            <div className="triangle" ></div>
        </div>
    </div>
);

export default SsmlValueMiniIndicator;