import React from 'react';
import { css } from '@emotion/css';
import { style_border_default } from '../../constants/stylesValues';
import { color_shades_dark } from '../../constants/colors';
const leftIcon = require("../../content/images/analytics-icons/chevron-left.svg");
const rightIcon = require("../../content/images/analytics-icons/chevron-right.svg");
interface PagerProps {
    onLeft: () => void
    onRight: () => void
    canMoveLeft: boolean
    canMoveRight: boolean
}

var leftInterval;
var rightInterval;
const TreeRowPager: React.SFC<PagerProps> = (props) => {
    const handleLeftDown = () => {
        props.onLeft()
        if (leftInterval)
            clearInterval(leftInterval);
        if (rightInterval)
            clearInterval(rightInterval)
        leftInterval = setInterval(() => {
            props.onLeft()
        }, 200)
    }
    const handleRightDown = () => {
        props.onRight()
        if (leftInterval)
            clearInterval(leftInterval);
        if (rightInterval)
            clearInterval(rightInterval)
        rightInterval = setInterval(() => {
            props.onRight()
        }, 200)
    }
    const handleLeftUp = () => {
        clearInterval(leftInterval)
    }
    const handleRightUp = () => {
        clearInterval(rightInterval)
    }
    return (
        <div className={`${containerStyle} ac-analytics-tracking-pager`}>
            <img src={leftIcon} onMouseDown={handleLeftDown} onMouseUp={handleLeftUp} className={props.canMoveLeft ? 'enabled' : 'disabled'} />
            <div className="separator" />
            <img src={rightIcon} onMouseDown={handleRightDown} onMouseUp={handleRightUp} className={props.canMoveRight ? 'enabled' : 'disabled'} />
        </div>
    );
}

const containerStyle = css`
    width: 48px;
    height: 24px;
    border-radius: 16px;
    border: ${style_border_default};
    background: white;
    display: flex;
    align-items: center;
    z-index: 5;
    >img {
        cursor: pointer;
        padding: 4px;
        flex: 1;
        margin: 3px;
    }
    .separator {
        width: 1px;
        height: 12px;
        background: ${color_shades_dark};
    }
    .disabled {
        opacity: 0.5;
    }
`

export default TreeRowPager;