import React, { useEffect, useMemo, useRef, useState } from 'react';
import AnalyticsEventStreamContainer, { LiveSession } from '../../../hooks/AnalyticsEventStreamContainer';
import styled from '@emotion/styled';
import { bluegreen, color_shades_dark } from '../../../constants/colors';
import CodeOutputModal from '../../simulator/components/CodeOutputModal';
import Button from '../../../components/general/Button';
import { SESSION_DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loader from '../../../components/general/Loader';
import SessionReview from './SessionReview';
import ModalLayout from '../../../components/structure/ModalLayout';
import ModalHeader from '../../../components/general/ModalHeader';
import FooterBar from '../../../components/structure/FooterBar';
import useWindowSize from '../../../hooks/UseWindowSize';

interface ApplicationLiveSessionsProps {
    applicationId: string,
    history: any,
}

const ApplicationLiveSessions = (props: ApplicationLiveSessionsProps) => {
    const [sessions, setSessions] = useState([] as LiveSession[]);
    const sessionContainer = AnalyticsEventStreamContainer.useContainer();
    const [expandedSessionIds, setExpandedSessionIds] = useState([] as string[]);
    const [sessionEventsToReview, setSessionEventsToReview] = useState([]);
    const [eventsToShow, setEventsToShow] = useState(null as any);
    const [multiApp, setMultiApp] = useState(false);
    const [liveCalls, setLiveCalls] = useState([] as LiveSession[]);
    const sessionsRef = useRef([]);
    const windowSize = useWindowSize();

    useEffect(() => {
        if (expandedSessionIds.length) {
            const newExpandedSession = sessions.find(s => s.sessionId === expandedSessionIds[0]);
            setSessionEventsToReview([...newExpandedSession.events]);
        } else {
            setSessionEventsToReview([]);
        }
    }, [expandedSessionIds]);

    useEffect(() => {
        if (expandedSessionIds?.length) {
            const newExpandedSession = sessions.find(s => s.sessionId === expandedSessionIds[0]);
            if (newExpandedSession?.events.length !== sessionEventsToReview?.length) {
                setSessionEventsToReview([...newExpandedSession.events]);
            }
        }
    }, [sessions]);

    useEffect(() => {
        let ma = false;
        if (sessions?.length) {
            const firstAppId = sessions[0].events[0].applicationId;
            if (firstAppId?.length) {
                ma = sessions.some(s => s.events[0]?.applicationId?.length &&
                    s.events[0].applicationId !== firstAppId);

            }
        }
        setMultiApp(ma);
    }, [sessions]);

    useEffect(() => {
        let newLiveCalls = sessions.filter(s => s.live);
        setLiveCalls(newLiveCalls);
    }, [sessionContainer.liveSessions]);

    useEffect(() => {
        sessionContainer.connect(props.applicationId);
    }, []);

    const convertEventsForCodeOutputModal = (events: any[]) => {
        var returnObj = {};
        events?.forEach((e, idx) => {
            returnObj[`${idx}-${e.eventType}`] = e;
        })

        // get last event that is request received
        const lastContentHit = events?.slice()?.reverse()?.find(e => e.eventType === "ContentHit");
        if (lastContentHit?.originalRequest?.sessionAttributes) {
            returnObj[`${events.length}-LatestSessionAttributes`] = lastContentHit.originalRequest.sessionAttributes;
        }

        return returnObj;
    };

    const memoizedModal = useMemo(
        () => (
            <CodeOutputModal
                title="JSON"
                closeModal={() => setEventsToShow(null)}
                data={convertEventsForCodeOutputModal(eventsToShow)}
                showCodeOutputModal={eventsToShow}
            />
        ),
        [eventsToShow],
    );

    const CustomSessionReview = () => {
        if (!sessionEventsToReview?.length || eventsToShow?.length)
            return <></>;
        const e = (sessionEventsToReview?.length && sessionEventsToReview[0]) ? sessionEventsToReview[0] : null;
        return (
            <div onClick={(e) => { e.stopPropagation() }}>
                <ModalLayout isVisible>
                    <ModalHeader title={moment(e?.eventDate).format(SESSION_DATE_FORMAT)} onClose={() => setExpandedSessionIds([])} />
                    <SessionReview
                        inModal
                        manualHeight={(windowSize.windowSize.innerHeight * .9) - 168}
                        events={sessionEventsToReview ?? []}
                        applicationId={props.applicationId}
                        setEventsToShow={setEventsToShow}
                    />
                    <FooterBar>
                        <Link
                            to={`/v/apps/${e?.applicationId}/analytics/sessions?sessionId=${expandedSessionIds[0]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <Button
                                disabled={false}
                                themes={['secondary']}
                                text="Go To Session"
                                onClick={null}
                                type="button" />
                        </Link>
                        <FooterButton
                            type="button"
                            themes={["secondary"]}
                            text="Close"
                            onClick={(e) => { setExpandedSessionIds([]); e.stopPropagation(); }}
                        />
                    </FooterBar>
                </ModalLayout>
            </div>
        )
    };

    const memoizedCustomSessionReview = useMemo(() => <CustomSessionReview />, [sessionEventsToReview, eventsToShow]);

    const toggleOnlySession = (sessionId: string) => {
        const isExpanded = expandedSessionIds.includes(sessionId);
        if (isExpanded) {
            setExpandedSessionIds(expandedSessionIds.filter(s => s !== sessionId))
        } else {
            const idx = sessions.findIndex(s => s.sessionId === sessionId);
            sessionsRef.current[idx].scrollIntoView({
                behavior: "instant",
                block: "end",
            });
            setExpandedSessionIds([sessionId])
        }
    };

    useEffect(() => {
        if (props.applicationId in sessionContainer.liveSessions)
            setSessions(sessionContainer.liveSessions[props.applicationId])
    }, [sessionContainer.liveSessions]);

    const stripSsml = (ssml: string) => {
        return ssml.replace(/<[^>]*>/g, '');
    };

    const renderExpandedSession = (session: LiveSession, index: number) => {
        return (
            <Session
                key={index}
                ref={el => sessionsRef.current[index] = el} >
                {renderSessionHeader(session, false)}
            </Session>
        )
    };

    const renderSessionHeader = (session: LiveSession, small: boolean) => {
        let requestEvents = session.events.filter(e => e.eventType === "RequestReceived");
        let responseEvents = session.events.filter(e => e.eventType === "AssistantSpeechStarted"
            || e.eventType === "AssistantSpeechPaused"
            || e.eventType === "AssistantSpeechEnded"
            || e.eventType === "ContentHit");

        // get last by eventDate
        let lastRequest = "";
        let lastResponse = "";
        if (requestEvents?.length) {
            const lastEvent = requestEvents[requestEvents.length - 1];
            if (lastEvent) {
                lastRequest = stripSsml(lastEvent?.utterance ?? "");
            }
        }
        if (responseEvents?.length) {
            const lastEvent = responseEvents[responseEvents.length - 1];
            if (lastEvent) {
                lastResponse = stripSsml(lastEvent?.response?.data?.content ?? lastEvent?.speechText ?? "");
            }
        }
        if (small) {
            return (
                <SmallSessionHeader key={session.sessionId} className={session.live ? "live" : ""} onClick={() => toggleOnlySession(session.sessionId)}>
                    {multiApp ? <SessionHeaderAppName>{sessionContainer.getAppName(session.events[0].applicationId)}</SessionHeaderAppName> : null}
                    <SessionHeaderDate className="small">
                        {moment(session.events[0].eventDate).format(SESSION_DATE_FORMAT)}
                    </SessionHeaderDate>
                    {(lastResponse?.length || lastRequest?.length) ?
                        <SmallConvPreview>
                            <SessionHeaderLastRequest className='small'>
                                User: {lastRequest}
                            </SessionHeaderLastRequest>
                            <SessionHeaderLastResponse className='small'>
                                Assistant: {lastResponse}
                            </SessionHeaderLastResponse>
                        </SmallConvPreview> : null
                    }
                </SmallSessionHeader>
            )
        };

        return (
            <SessionHeader className={session.live ? "live" : ""} onClick={() => toggleOnlySession(session.sessionId)}>
                {multiApp ? <SessionHeaderAppName>{sessionContainer.getAppName(session.events[0].applicationId)}</SessionHeaderAppName> : null}
                <SessionHeaderName>{session.events[0].platform ?? session.events[0].assistant}</SessionHeaderName>
                <SessionHeaderDate>
                    {moment(session.events[0].eventDate).format(SESSION_DATE_FORMAT)}
                </SessionHeaderDate>
                {(lastResponse?.length || lastRequest?.length) ?
                    <ConvPreview>
                        <SessionHeaderLastRequest>
                            User: {lastRequest}
                        </SessionHeaderLastRequest>
                        <SessionHeaderLastResponse>
                            Assistant: {lastResponse}
                        </SessionHeaderLastResponse>
                    </ConvPreview> : null
                }
                <SessionLink
                    to={`/v/apps/${session.events[0].applicationId}/analytics/sessions?sessionId=${session.sessionId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <SessionLinkButton
                        disabled={false}
                        themes={['secondary-small']}
                        text="Full Session"
                        onClick={null}
                        type="button" />
                </SessionLink>
            </SessionHeader>
        )
    };

    const renderCollapsedSession = (session: LiveSession, index: number) => {
        return (
            <Session ref={el => sessionsRef.current[index] = el} key={index}>
                {renderSessionHeader(session, false)}
            </Session>
        )
    };

    if (!sessions?.length)
        return (<Loader />)
    return (
        <AllSessionContainer>
            <LiveCallsContainer>
                {
                    liveCalls.length === 0 ?
                        <CallCount className="invisible">
                            No live calls
                        </CallCount>
                        :
                        <CallCount className="live">
                            Live calls: {liveCalls.length}
                        </CallCount>
                }
                {liveCalls.map((session, index) => {
                    return renderSessionHeader(session, true);
                })}
            </LiveCallsContainer>
            <AllSessions>
                {sessions.map((session, index) => {
                    // collapsable sessions
                    if (expandedSessionIds.includes(session.sessionId)) {
                        return renderExpandedSession(session, index);
                    } else {
                        return renderCollapsedSession(session, index);
                    }
                })}
                {memoizedModal}
                {memoizedCustomSessionReview}
            </AllSessions>
        </AllSessionContainer>
    )

};

const Session = styled.div`
    width: 95%;
    margin-right: 64px;
    margin-left: 32px;
`;

const AllSessionContainer = styled.div`
    margin-bottom: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
`;

const AllSessions = styled.div`
    width: 75%;
`;

const LiveCallsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-left: 32px;
    margin-top: 24px;
`;

const SessionHeader = styled.h3`
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 16px 16px;
    border: 1px solid ${color_shades_dark};
    cursor: pointer;
    border-radius: 12px 12px 12px 12px;
    display: flex;
    flex-direction: row;
    position: relative;
    &.live {
        border: 1px solid ${bluegreen};
    }
`;

const CallCount = styled.h3`
    padding: 16px 16px;
    border: 1px solid ${color_shades_dark};
    cursor: pointer;
    border-radius: 12px 12px 12px 12px;
    display: flex;
    flex-direction: row;
    position: relative;
    &.live {
        border: 1px solid ${bluegreen};
    }
`;

const SmallSessionHeader = styled.h3`
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 16px 16px;
    border: 1px solid ${color_shades_dark};
    cursor: pointer;
    border-radius: 12px 12px 12px 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    &.live {
        border: 1px solid ${bluegreen};
    }
    &.invisible {
        visibility: hidden;
    }
`;

const FooterButton = styled(Button)`
    margin-left: auto;
`;

const SessionLinkButton = styled(Button)`
    margin: 0;
`;

const SessionLink = styled(Link)`
    margin-left: auto;
`;

const SessionHeaderName = styled.div`
    margin-top: 6px;
    margin-right: 8px;
    min-width: 120px;
    font-weight: bolder;
`;

const SessionHeaderLastResponse = styled.div`
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 32px;
    font-weight: lighter;
    font-size: 12px;
    &.small {
        margin-left: 4px;
    }
    font-weight: bold;
`;

const SessionHeaderLastRequest = styled.div`
    margin-top: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 32px;
    font-weight: lighter;
    font-size: 12px;
    &.small {
        margin-left: 4px;
        margin-top: 8px;
    }
`;

const ConvPreview = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
`;

const SmallConvPreview = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SessionHeaderDate = styled.div`
    margin-top: 6px;
    margin-right: 8px;
    font-weight: lighter;
    min-width: 180px;
    &.small {
        margin-top: 0px;
    }
`;

const SessionHeaderAppName = styled.div`
    position: absolute;
    top: -16px;
    left: 0px;
    font-weight: lighter;
    font-size: 12px;
`;

export default ApplicationLiveSessions;