import React, { useEffect, useState } from 'react';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { Redirect } from 'react-router';
import AuthContainer from '../../state/containers/AuthContainer';
import Loader from '../../components/general/Loader';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import FeatureContainer from '../../state/containers/FeatureContainer';
import NotificationsContainer from '../../hooks/NotificationsContainer';
import LanguageContainer from '../../hooks/LanguageContainer';

// responsbile for initial load and route set up
interface SplashProps {
    authContainer: AuthContainer
    orgContainer: OrganizationContainer
    appContainer: ApplicationContainer
    featureContainer: FeatureContainer
}
const Splash = (props: SplashProps) => {
    const [isLoading, setIsLoading] = useState(true);

    const languagesContainer = LanguageContainer.useContainer();

    useEffect(() => {
        const asyncLoad = async () => {
            await props.authContainer.refreshToken();
            const promises = [];
            promises.push(languagesContainer.loadLanguages());
            promises.push(props.featureContainer.getSystemFeatures());
            promises.push(props.orgContainer.getOrganizations());
            await Promise.all(promises);
            setIsLoading(false);
        }
        asyncLoad();

        // Add cleanup to avoid Warning: Can't perform a React state update on an unmounted component.
        return () => { setIsLoading(false); };
    }, []);    

    if (isLoading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    if (props.orgContainer.state.currentOrganization == null) {
        if (location.href != "/orgs") {
            return <Redirect to="/orgs" />
        }
    }

    return (
        <div>
            <Loader />
        </div>
    );
    
}

export default Splash;