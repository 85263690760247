import React from 'react';
import { Subscribe } from 'unstated';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import ExitMessageContainer from '../../../state/containers/ExitMessageContainer';
import CustomRequestContainer from '../../../state/containers/CustomRequestContainer';
import HelpMessageContainer from '../../../state/containers/HelpMessageContainer';
import FallbackMessageContainer from '../../../state/containers/FallbackMessageContainer';
import WelcomeMessageContainer from '../../../state/containers/WelcomeMessageContainer';
import QuestionAnswerContainer from '../../../state/containers/QuestionAnswerContainer';
import LatestMessageContainer from '../../../state/containers/LatestMessageContainer';
import SimpleChoiceContainer from '../../../state/containers/SimpleChoiceContainer';
import NumberRangeContainer from '../../../state/containers/NumberRangeContainer';
import {
    WelcomeMessagesFeatureTypeId, ExitMessagesFeatureTypeId, HelpMessagesFeatureTypeId, QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, FallbackFeatureTypeId,
    SimpleChoiceFeatureTypeId, NumberRangeFeatureTypeId, CustomRequestsFeatureTypeId
} from '../../../constants/featureTypeIds';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationOrderingExport from './ApplicationOrderingExport';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';

interface ApplicationOrderingWrapperProps {
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string,
    ssmlContainer: SsmlContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    history: any
    envContainer: ApplicationEnvironmentsContainer
}

const ApplicationOrderingExportWrapper = (props: ApplicationOrderingWrapperProps) => (
    <Subscribe to={[ApplicationContainer]}>
        {(
            applicationContainer: ApplicationContainer
        ) => {
            return (
                <ApplicationOrderingExport
                    {...props}
                    applicationContainer={applicationContainer}
                />
            );

        }}
    </Subscribe>
);

export default ApplicationOrderingExportWrapper;
