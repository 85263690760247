import React, { FocusEvent } from 'react';
import { css } from '@emotion/css';
import SsmlParseService from '../../../services/ssmlParseService';
import SsmlObject from '../../../models/ssml/SsmlObject';
import { dark_grey_blue, silver_two, pastel_red, pastel_blue, pastel_light_blue, pastel_purple, pastel_green, pastel_grey, pastel_pink, pastel_yellow, cool_grey, color_shades_light } from '../../../constants/colors';
import SsmlPreviewElement from './SsmlPreviewElement';
import ContentEditable from 'react-contenteditable';
import { renderToString } from 'react-dom/server';


interface SsmlPreviewerProps {
    ssmlObject: SsmlObject
    placeholder: string
    disabled?: boolean
    lastNewElement?: SsmlObject
    id?: string
    className?: string
    onChange: (ssmlObject: SsmlObject) => void
    onBlur: (ssmlObject: SsmlObject, e: FocusEvent<any>) => void
    onFocus: () => void
    onElementSelected: (ssmlId: string, currentTree: SsmlObject) => void
    onSelectionChange: (startPosition?: number, endPosition?: number) => void
    onRenderError: () => void
}
interface SsmlPreviewerState {
    isEditing: boolean
    currentHoverId?: string
    compiledHtml?: string
}
interface SelectionRange {
    startId?: string
    endId?: string
    startOffset: number
    endOffset: number
}
class SsmlPreviewer extends React.Component<SsmlPreviewerProps, SsmlPreviewerState> {
    private parser: SsmlParseService = new SsmlParseService();
    private updatedObject: SsmlObject = this.props.ssmlObject; // we don't want this in state because we are delaying re-render
    private startPosition?: number; // kept out of state to ensure we don't re-render
    private endPosition?: number; // same here
    private selectionChangeHandler = this.handleSelectionChange.bind(this)
    private pasteHandler = this.handleDivPaste.bind(this);
    private previousSelectionRange: SelectionRange
    editable: any = null;
    state = {
        isEditing: true,
        currentHoverId: null,
        compiledHtml: ''
    }

    componentDidMount() {
        this.updatedObject = this.props.ssmlObject;
        const html = this.getRenderString(this.updatedObject);
        this.setState({
            ...this.state,
            compiledHtml: html
        })
        document.addEventListener("selectionchange", this.selectionChangeHandler, true);
        // needs the stuff rendered first
        if (this.editable) {
            this.editable.setAttribute('placeholder', this.props.placeholder)
        }
    }
    componentWillUnmount() {
        document?.removeEventListener('selectionchange', this.selectionChangeHandler, true);
    }
    rebindPaste() {
        var selector = document.querySelector('div[contenteditable="true"]');
        if (selector) {
            selector.removeEventListener("paste", this.pasteHandler, true);
            selector.addEventListener("paste", this.pasteHandler, true);
        }
    }
    componentDidUpdate(prevProps: SsmlPreviewerProps, prevState: SsmlPreviewerState) {
        if (prevProps.ssmlObject != this.props.ssmlObject) {
            this.updatedObject = this.props.ssmlObject;
            const html = this.getRenderString(this.updatedObject);
            this.setState({
                ...this.state,
                compiledHtml: html
            })
        }
        if (prevProps.lastNewElement != this.props.lastNewElement) {
            // TODO: set the highlight to the new element if it is a placeholder
        }
        if (prevState.currentHoverId != this.state.currentHoverId) {
            this.rebindPaste();
            this.setSelectionToPrevious();
        }
    }
    getAllowsForChildren(name: string) {
        if (name == 'break') return false;
        return true;
    }
    handleDivPaste(e) {
        e.preventDefault();
        // note: if we wanted to handle pasting copied ssml elements, this is where we should kick it off
        // const tree = this.updatedObject;
        // const rotated = this.parser.rotateMutableIds(tree);
        // var html = e.clipboardData.getData("text/html");
        // (html)
        var text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    }

    setSelectionToPrevious() {
        try {
            if (this.previousSelectionRange && this.previousSelectionRange.startId) {
                var selection = window.getSelection();
                if (!selection) return;
                const range = document.createRange();
                const startElement = document.getElementById(this.previousSelectionRange.startId);
                const endElement = document.getElementById(this.previousSelectionRange.endId);
                if (startElement && endElement && startElement.childNodes && endElement.childNodes) {
                    range.setStart(startElement.childNodes[0], this.previousSelectionRange.startOffset);
                    range.setEnd(endElement.childNodes[0], this.previousSelectionRange.endOffset);

                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        }
        catch (e) {
            // IE throws errors with empty messages that we should catch since we have to handle selection universally
        }
    }
    handleSelectionChange() {
        try {
            const selection = document.getSelection();
            if (selection == null || selection.rangeCount <= 0) return;

            const range = selection.getRangeAt(0);
            var startId = range.startContainer.parentElement.attributes["id"] ? range.startContainer.parentElement.attributes["id"].value : null;
            var startOffset = range.startOffset;
            var endId = range.endContainer.parentElement.attributes["id"] ? range.endContainer.parentElement.attributes["id"].value : null;
            var endOffset = range.endOffset;

            // this is used for the case where you are selecting managed text as well as the parent container text
            // such as the default ending &nbsp; to reselect the offset. Without this, the selection range is 0-1 and the
            // selection id's are null since it's within the entire parent.
            try {
                if (endId == null && startId != null) {
                    const endNode = range.endContainer.previousSibling;
                    if (endNode) {
                        endId = (endNode as HTMLElement).attributes["id"].value;
                        endOffset = endNode.lastChild.nodeValue.length;
                    }
                }
            } catch { }// eat it 

            this.previousSelectionRange = {
                startOffset: startOffset,
                endOffset: endOffset,
                startId,
                endId
            };


            var ssmlParseResult = this.parser.convertToStringWithSelection(
                this.updatedObject,
                null,
                '',
                startId,
                startOffset,
                endId,
                endOffset);

            // TODO: figure out how to handle when all things are selected and there are a bunch of elements

            if (endId == null && startId == null && endOffset == 1 && startOffset == 1) {
                // in the end spacer - put stuff at the end
                this.startPosition = null;
                this.endPosition = null;
            }
            else if (endId == null && startId == null && this.updatedObject.children && this.updatedObject.children.length > 0) {
                // in new text but with previous children - select the start/end of the new text relative
                var totalLength = ssmlParseResult.ssml.length;
                var innerLength = this.updatedObject.children[this.updatedObject.children.length - 1].value.length;
                const otherLength = totalLength - innerLength;
                this.startPosition = otherLength + startOffset;
                this.endPosition = otherLength + endOffset;
            }
            else if (endId == null && startId == null) {
                // just in new text section
                this.startPosition = startOffset;
                this.endPosition = endOffset;
            }
            else {
                // normal case with already processed ids
                this.startPosition = ssmlParseResult.startIndex;
                this.endPosition = ssmlParseResult.endIndex;
            }

        }
        catch {
            // global selection issues will throw here, so we should ignore them since they have nothing to do with our logic
        }
    }
    private getAttributeValue(ssmlObject: SsmlObject) {
        if (ssmlObject.name == "say-as" || ssmlObject.name == "audio") return null;
        return ssmlObject.attributes.filter(a => a.value).length > 0 ? ssmlObject.attributes[0].value : null
    }
    startEditing() {
        this.setState({
            ...this.state,
            isEditing: true
        })
    }
    stopEditing() {
        this.setState({
            ...this.state,
            isEditing: false
        })
    }

    getRenderString(ssmlObject: SsmlObject) {
        var html = renderToString(this.renderSsmlElement(ssmlObject, 0, 0, null, true));

        if (html.includes('&lt;parsererror') && html.includes('parsererror&gt;')) {
            this.props.onRenderError();
            return 'error';
        }
        return html ? html + "&nbsp;" : null;
    }
    handleElementClicked(e: React.MouseEvent<HTMLDivElement>) {
        if (!(e.target as any).hasAttribute('data-id')) {
            const openTarget = e.target as HTMLDivElement
            if (openTarget.hasAttribute('data-container')) {
                this.editable.focus();
                if (this.props.onFocus)
                    this.props.onFocus();
            }
            return;
        };
        if ((e.target as any).attributes['data-id']) {
            this.setState({
                ...this.state,
                currentHoverId: null
            })
            this.props.onElementSelected((e.target as any).attributes['data-id'].value, this.updatedObject);
        }
    }

    handleElementLeave(e: React.MouseEvent<HTMLDivElement>) {
        this.setState({
            ...this.state,
            currentHoverId: null
        })
    }
    handleMouseMove(e) {
        if (!(e.target as any).hasAttribute('data-id') && this.state.currentHoverId != null) {
            this.setState({
                ...this.state,
                currentHoverId: null
            });

            this.setSelectionToPrevious();
            return;
        }
        else if ((e.target as any).hasAttribute('data-id')) {
            var id = (e.target as any).attributes['data-id'].value;
            if (id != this.state.currentHoverId) {
                this.setState({
                    ...this.state,
                    currentHoverId: id
                })
                this.setSelectionToPrevious();
            }
        }
    }
    handleMouseLeave() {
        // when we leave the entire div - clear hover states
        this.setState({
            ...this.state,
            currentHoverId: null
        })
    }
    handleHtmlChange(e) {
        const ssmlObject = this.parser.htmlToObject(e.target.value);
        this.updatedObject = ssmlObject;
    }
    handleBlur(ssmlObject: SsmlObject, e: FocusEvent<any>) {
        this.props.onBlur(ssmlObject, e);
        this.props.onChange(this.updatedObject);
        this.props.onSelectionChange(this.startPosition, this.endPosition);
    }
    renderSsmlElement(ssmlObject: SsmlObject, depth: number, index: number, parent: SsmlObject, contentEditable: boolean) {
        if (!ssmlObject) return null;
        if (ssmlObject.name == 'speak') {
            return ssmlObject.children.length > 0 ? ssmlObject.children.map((c, i) => this.renderSsmlElement(c, depth + 1, i, ssmlObject, contentEditable)) : null
        }
        else if (ssmlObject.name == "plain-text") {
            if (ssmlObject.isUnsupportedElement) {
                return <span className="ssml-element" key={ssmlObject.id} id={ssmlObject.id}>{ssmlObject.value}</span>
            }
            else if (ssmlObject.value == "\n" || ssmlObject.value == "\r\n") {
                return <div className="new-line"><span key={ssmlObject.id} id={ssmlObject.id}><br /></span></div>
            }
            else {
                return <span key={ssmlObject.id} id={ssmlObject.id}>{ssmlObject.value}</span>
            }
        }
        else {
            var rule = this.parser.getDisplayedRule(ssmlObject);
            var digitValue = this.parser.getRawAttributeValue(ssmlObject);
            const allowsChildren = this.getAllowsForChildren(ssmlObject.name);
            return <SsmlPreviewElement
                onViewHover={this.startEditing.bind(this)}
                onIconHover={this.stopEditing.bind(this)}
                hoverActive={this.state.currentHoverId == ssmlObject.id}
                key={ssmlObject.id}
                ssmlObject={ssmlObject}
                minValue={rule ? rule.min : null}
                maxValue={rule ? rule.max : null}
                digitValue={digitValue}
                allowChildren={allowsChildren}
                contentEditable={contentEditable}
                attributeValue={this.getAttributeValue(ssmlObject)}>
                {ssmlObject.children.length > 0 ? ssmlObject.children.map((c, i) => this.renderSsmlElement(c, depth + 1, i, ssmlObject, contentEditable)) : <span className={allowsChildren ? "empty-tag" : ""}></span>}
            </SsmlPreviewElement>
        }
    }
    render() {
        const html = this.getRenderString(this.updatedObject);
        return (
            <div
                id={this.props.id}
                className={`${containerStyle} ${this.props.className} ${this.props.disabled ? 'disabled' : ''}`}
                onClick={this.handleElementClicked.bind(this)}
                onMouseMove={this.handleMouseMove.bind(this)}
                data-container="true"
                suppressContentEditableWarning={true}>

                <ContentEditable className="editable"
                    innerRef={(inner) => this.editable = inner}
                    onBlur={this.handleBlur.bind(this)}
                    html={html ? html : ''}
                    onChange={this.handleHtmlChange.bind(this)}
                    onPaste={this.handleDivPaste.bind(this)}
                    disabled={this.props.disabled} />

            </div>)
    }
}
const containerStyle = css`
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
color: ${dark_grey_blue};
font-family: Muli;
text-align: left;
min-height: 34px;

width: 100%;
border: none; 
padding: 8px 16px;
font-size: 14px;
margin: 12px 0;
resize: none;
border-left: 1px solid ${silver_two};
border-right: 1px solid ${silver_two};
[contenteditable=true]:empty::before, editable:empty::before {
  content: attr(placeholder);
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${cool_grey};
  cursor: text;
}
.editable {
    line-height: 32px;
    white-space: pre-wrap;
    width: 100%;
}
&.disabled {
    background: ${color_shades_light};
}

.ssml-element {
    border-radius: 20px;
    padding: 6px;
    border: 1px solid white;
    background: ${pastel_grey};

    &.say-as {
        background: ${pastel_red};
    }
    &.volume {
        background: ${pastel_light_blue};
    }
    &.pitch {
        background: ${pastel_blue};
    }
    &.emphasis {
        background: ${pastel_purple};
    }
    &.speed {
        background: ${pastel_green};
    }
    &.audio {
        background: ${pastel_pink};
    }
    &.break {
        background: ${pastel_yellow};
    }

    button {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
    }
    .ssml-icon {
        cursor: pointer;
        padding: 0 8px;
        margin: 0 0 0 8px;
        margin: 0 px;
        letter-spacing: 12px;
        background-size: initial;
        background-repeat: no-repeat;
        background-position: initial;
        position: relative;
        display: inline-block;
        line-height: 24px;
    }

    &.inner-padded {
        padding-right: 16px;
    }
}

.new-line {
    display: block;
}
`


export default SsmlPreviewer;