import React from 'react';
import { css } from '@emotion/css';
import LanguageModel from '../../models/features/api/LanguageModel';
import Tooltip from 'rc-tooltip';
import TooltipWrapper from './TooltipWrapper';
import { color_highlights_blue, color_shades_darkest, color_text_default, cool_grey } from '../../constants/colors';
import OrganizationMemberModel from '../../models/organizations/api/OrganizationMemberModel';
import ApplicationMemberRoleModel from '../../models/applications/api/ApplicationMemberRoleModel';
import IconCollectionItem from '../../models/media/IconCollectionItem';

interface IconCollectionProps {
    items: IconCollectionItem[]
    limit: number
    className?: string
}
const IconCollectionAvatar: React.SFC<IconCollectionProps> = (props) => (
    <div className={containerStyle + (props.className ? ` ${props.className}` : '')}>
        {props.items?.slice(0, props.limit).map((i, idx) => (
            <TooltipWrapper text={i.tooltipText} className={toolTip} key={idx}>
                <div key={idx} className={ `item ${props.items.length == 1 ? `single` : ``}`}>
                    <div className="inner-app">
                        {i.imageUrl == null ? 
                            <p>{i.noImageText}</p>
                            :                            
                            <img src={i.imageUrl} />
                        }
                    </div>
                </div>
            </TooltipWrapper>
        ))}
        {props.items?.length === props.limit + 1 ?
            <TooltipWrapper text={props.items.slice(props.limit).map(l => l.tooltipText).join(', ')} className={toolTip}>
            <div className={ `item ${props.items.length == 1 ? `single` : ``}`}>
                    <div className="inner-app">
                        {props.items[props.limit].imageUrl == null ? 
                            <p>{props.items[props.limit].noImageText}</p>
                            :                          
                            <img src={props.items[props.limit].imageUrl} />  
                        }
                    </div>
                </div>
            </TooltipWrapper>
            : null}
        {props.items?.slice(props.limit).length > 1 ?
            <TooltipWrapper text={props.items.slice(props.limit).map(l => l.tooltipText).join(', ')} className={toolTip}>
                <div className={ `item ${props.items.length == 1 ? `single` : ``}`}>
                    <div className="inner-app overflow-icon">
                        <p>+{props.items.slice(props.limit).length}</p>
                    </div>
                </div>
            </TooltipWrapper>
            : null}
    </div>
);

const containerStyle = css`
    display: flex;
    margin-left: 12px;
    .inner-app {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid white;
        &.overflow-icon {
            margin: 2px;
            color: ${color_shades_darkest};
            font-size: 12;    
        }
        img, p {
            height: 24px;
            width: 24px;
            border: 1px solid ${cool_grey};
            border-radius: 50%;
        }
        p {
            font-size: 8px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -1px;
            text-align: center;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;            
            color: ${color_text_default};
            background: ${color_highlights_blue};
            justify-content: center;
            box-sizing: border-box;
        }
    }
    .item {
        padding: 1px;
        width: 24px;
        height: 24px;
        max-height: 24px;
        max-width: 24px;
        border-radius: 50%;
        margin-left: -12px;
        z-index: 0;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #ffffff;
        &:hover {
            z-index: 1;
        }
    }
`
const toolTip = css`
    .rc-tooltip-inner {
        max-width: 200px;
    }
`
export default IconCollectionAvatar;