import React from "react";
import { Provider, Subscribe } from 'unstated';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { Redirect, RouteComponentProps } from "react-router";
import AuthContainer from "../../state/containers/AuthContainer";
import FrontContainer from "../../components/structure/FrontContainer";
import SplitContainer from "../../components/structure/SplitContainer";
import LandingContainer from "../../components/structure/LandingContainer";
import UserContainer from "../../state/containers/UserContainer";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import ResetSuccess from "./components/ResetSuccess";

interface ForgotPasswordProps {}
class ForgotPassword extends React.Component<RouteComponentProps<ForgotPasswordProps>> {
    render() {
        return (
            <LandingContainer>
                <Subscribe to={[UserContainer, AuthContainer]}>
                    {(user: UserContainer, auth: AuthContainer) => {

                        return (
                            <FrontContainer>
                                {user.state.isResetRequestSent 
                                    ? <ResetSuccess history={this.props.history}/> 
                                    : <ForgotPasswordForm stateContainer={user as UserContainer} />
                                }
                            </FrontContainer>
                        );
                    }
                    }
                </Subscribe>
            </LandingContainer>
        );
    }
};

export default ForgotPassword;