import { Container } from "unstated";
import DeviceTargetModel from "../../models/features/api/MediaResponses/DeviceTargetModel";
import IResult from "../../models/result/IResult";
import * as voicifyApi from "../../api"
import { getPlatformIcon } from "../../models/extensions/platforms";
import AnalyticsRequestFilter from "../../models/analytics/AnalyticsRequestFilter";
import AnalyticsFilterState from "../../models/analytics/AnalyticsFilterState";
const speakerIcon = require('../../content/images/speaker.svg');
const screenIcon = require('../../content/images/screen.svg');
const chatIcon = require('../../content/images/chat-icon.svg');

interface FacetState {
    assistants: {name: string, iconUrl: string}[]
    deviceTargets: DeviceTargetModel[]
    interfaces: {name: string, iconUrl: string}[]
    isLoading: boolean
    errors: string[]
}
export default class ApplicationAnalyticsFacetContainer extends Container<FacetState> {
    constructor() {
        super()
        this.state = {
            assistants: [],
            deviceTargets: [],
            interfaces: [{name: 'Audio', iconUrl: speakerIcon}, {name: 'Screen', iconUrl: screenIcon}, {name: 'Chat', iconUrl: chatIcon}],
            errors: [],
            isLoading: false
        }
    }

    getDeviceTargets(): Promise<IResult<DeviceTargetModel[]>> {
        // if we already have targets return them
        if (this.state.deviceTargets.length > 0) {
            return new Promise<IResult<DeviceTargetModel[]>>((resolve) => {
                resolve({
                    data: this.state.deviceTargets,
                    errors: [],
                    resultType: "Ok"
                });
            });
        }

        this.setLoading(true)

        var promise = voicifyApi.getAllDeviceTargets();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    deviceTargets: result.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        });
        return promise;
    }
    convertFilter(startDate: string, endDate: string, filterState: AnalyticsFilterState, deviceTargets: DeviceTargetModel[]) : AnalyticsRequestFilter {
        const filter: AnalyticsRequestFilter = {
            startDate: startDate,
            endDate: endDate,
            assistants: filterState.assistants,
            languageIds: filterState.languageIds,
            locales: filterState.locales
        };

        // aggregate the explicit devicetarget ids and the interface types
        const deviceTargetIds = filterState.deviceTargetIds ?? []
        if(filterState?.interfaces?.some(i => i.toLocaleLowerCase() == "screen")) {
            const screenDevices = deviceTargets.filter(d => d.supportsForegroundImages)?.map(d => d.id);
            deviceTargetIds.push(...screenDevices);
        }
        if(filterState?.interfaces?.some(i => i.toLocaleLowerCase() == "audio")) {
            const audioDevices = deviceTargets.filter(d => d.supportsRichAudio && !d.supportsForegroundImages)?.map(d => d.id);
            deviceTargetIds.push(...audioDevices);
        }
        if(filterState?.interfaces?.some(i => i.toLocaleLowerCase() == "chat")) {
            const chatDevices = deviceTargets.filter(d => d.supportsDisplayText && !d.supportsRichAudio)?.map(d => d.id);
            deviceTargetIds.push(...chatDevices);
        }

        filter.deviceTargetIds = deviceTargetIds;

        return filter;
    }

    async getAssistants(applicationId: string, startDate: string, endDate: string) {
        this.setLoading(true);
        try {
            const assistantNamesResult = await voicifyApi.getApplicationUniqueAssistants(applicationId, {startDate, endDate});
            const assistants = assistantNamesResult?.data?.map(n => ({
                name: n,
                iconUrl: getPlatformIcon(n)
            })) ?? [];
            this.setState({
                ...this.state,
                isLoading: false,
                assistants: assistants
            })
        } catch {
            this.setLoading(false);
        }
    }

    setLoading(isLoading: boolean){
        this.setState({
            ...this.state,
            isLoading
        })
    }
}