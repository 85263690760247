import React from "react";
import { amazonAuthCallback } from '../../api/controllers/amazonAuthorization';
class AmazonAuth extends React.Component {
    componentWillMount() {
        const urlParams = new URLSearchParams(window.location.search);
        var csrfToken = (urlParams.get("state")).split(";")[3];
        var idType = (urlParams.get("state")).split(";")[0];
        var primaryId = (urlParams.get("state")).split(";")[1];
        let applicationId = primaryId;
        if(idType != "applicationId" && urlParams.get("state").includes("applicationId"))
            applicationId = (urlParams.get("state")).split(";")[5];
        if (csrfToken === localStorage.getItem("amazon-salt")) {
            localStorage.removeItem("amazon-salt")
            amazonAuthCallback(window.location.search).then(res => {
                if(idType == "applicationId" || urlParams.get("state").includes("applicationId"))
                {
                    let url = `${window.location.origin}/v/apps/${applicationId}/appSettings/deployments`;
                    if(urlParams.get("state").includes("environmentId")) {
                        url = `${url}/${primaryId}`;
                    }
                    window.open(url, "_self")
                }
                else
                {
                    window.open(`${window.location.origin}/v/orgSettings/credentials`, "_self")
                }
            })
        } else {
            //TODO: handle failure
            localStorage.removeItem("amazon-salt");
            window.open(window.location.origin, "_self")
        }
    }

    render() {
        return (<div></div>)
    }
}

export default AmazonAuth;