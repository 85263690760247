import React from "react";
import { css } from "@emotion/css";
import FieldError from "./FieldError";
import { Field } from "formik";
import IconTooltip from "../general/IconTooltip";
import {
    primary_gradient,
    pale_grey,
    color_variants_ocean_light_opaque,
} from "../../constants/colors";

interface CheckboxFieldProps {
    checked?: boolean;
    name?: string;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<any>) => void;
    onBlur?: (e: any) => void;
    onDelete?: () => void;
    label?: string;
    labelStyle?: string;
    id?: string;
    canDelete?: boolean;
    required?: boolean;
    className?: string;
    tooltip?: string;
    tooltipPosition?: string;
    tooltipStyle?: string;
    tooltipContentStyle?: string;
    icon?: string;
    labelExtension?: any
}
class CheckboxField extends React.Component<CheckboxFieldProps, {}> {
    render() {
        const props = {
            ...this.props,
        };

        return (
            <div>
                <div className={`${containerStyle} ${props.className}`}>
                    <label className={`${labelStyle} ${props?.labelStyle}`}>
                        {props.name ? (
                            <Field
                                id={props.id}
                                type="checkbox"
                                name={props.name}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                disabled={props.disabled}
                                checked={props.checked}
                                value={props.checked}
                            />
                        ) : (
                            <input
                                id={props.id}
                                type="checkbox"
                                name={props.name}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                disabled={props.disabled}
                                checked={props.checked}
                            />
                        )}
                        <span className="checkmark"></span>
                        {props.icon && <img src={props.icon} />}
                        <span className="check-label">{props.label}</span>
                    </label>
                    {props.labelExtension}
                    {props.tooltip !== undefined ? (
                        <IconTooltip
                            text={props.tooltip}
                            className={`${props.tooltipStyle} tooltip ${tooltipStyle}`}
                            place={props.tooltipPosition ? props.tooltipPosition : "top"}
                            replacementContentStyle={
                                props.tooltipContentStyle && props.tooltipContentStyle
                            }
                        />
                    ) : null}
                </div>
                {props.name && <FieldError name={props.name} />}
            </div>
        );
    }
}

const containerStyle = css`
  display: flex;
  flex-direction: row;
`;
const tooltipStyle = css`
  margin: 0;
  margin-top: 24px;
  margin-bottom: 0;
`;

const labelStyle = css`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  padding-left: 36px;
  margin-bottom: 24px;
  line-height: 22px;

  > img {
    margin-right: 8px;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #cccccc;
    margin-right: 16px;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: ${color_variants_ocean_light_opaque};
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background: ${primary_gradient};
  }

  input:disabled ~ .checkmark {
    background: ${pale_grey};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default CheckboxField;
