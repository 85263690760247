import React from 'react';
import { css } from '@emotion/css';
import { InjectedFormikProps, withFormik, Form } from 'formik';
import * as yup from 'yup';
import Button from '../../../components/general/Button';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import TextField from '../../../components/forms/TextField';
import UserContainer from '../../../state/containers/UserContainer';

interface ResetFormData {
    email?: string;
}
interface ResetFormProps {
    stateContainer: UserContainer
}

const buttonSplitStyle = css`
    margin-top: 60px;
    margin-bottom: -56px;
`

class InnerForm extends React.Component<InjectedFormikProps<ResetFormProps, ResetFormData>> {
    componentWillMount() {
        this.props.stateContainer.resetPasswordResetRequest();
    }
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit}>
                <FormFieldsContainer>
                    <h2>Forgot Password</h2>
                    <p>Enter the email of the account. We will send you an email with instructions to reset your password.</p>
                    <TextField type="email" name="email" label="Email" placeholder="The one you sign in with" value={this.props.values.email} onChange={this.props.handleChange} onBlur={this.props.handleBlur} />
                </FormFieldsContainer>
                <Button themes={["primary", "wide"]} type="submit" disabled={this.props.isSubmitting} text="Submit" additionalClasses={buttonSplitStyle} />
            </Form>

        );
    }
}
const ForgotPasswordForm = withFormik<ResetFormProps, ResetFormData>({
    mapPropsToValues: props => ({ email: '' }),
    validationSchema: yup.object().shape({
        email: yup.string().email("Must be a valid email").required("You must enter an email address")
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        if (values.email !== '') {
            props.stateContainer.resetPassword(values.email).then(result => {
                setSubmitting(false);
            });
        }
        else {
            setSubmitting(false);
        }
    }
})(InnerForm);

export default ForgotPasswordForm;