import { withFormik } from 'formik';
import * as yup from 'yup';
import SparkNewTopic from './SparkNewTopic';
import AppliedApplicationTemplateContainer from '../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import OrganizationContainer from '../../../../shared/state/containers/OrganizationContainer';
import TemplateConfigurationContainer from '../../../../shared/state/containers/TemplateConfigurationContainer';
import TemplateConfigurationModel from '../../../../shared/models/templating/api/TemplateConfigurationModel';
import UpdateAppliedApplicationTemplateFormRequest from '../../../../shared/models/templating/api/UpdateAppliedApplicationTemplateFormRequest';
import { getOfferTextTemplateConfigId, getRespondTemplateConfigId, getTransferTemplateConfigId } from '../../../../shared/constants/sparkConstants/customRequestModuleIds';
import TemplateSectionStatusModel from '../../../../shared/models/templating/TemplateSectionStatusModel';

export interface SparkNewTopicProps {
    toggleNewTopicModal: () => void
    templateConfigContainer: TemplateConfigurationContainer
    orgContainer: OrganizationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    applicationId: string
    sections: TemplateSectionStatusModel[] | any[]
}

export interface SparkNewTopicFormData {
    topicName: string
    topicResponseType: string
}

const SparkNewTopicForm = withFormik<SparkNewTopicProps, SparkNewTopicFormData>({
    mapPropsToValues: props => ({
        topicName: '',
        topicResponseType: 'respond'
    }),
    validationSchema: (props) => {
        return yup.object().shape({
            topicName: yup.string()
                .required('Please enter a topic name')
                .test('is-unique', 'Topic name must be unique', function(value) {
                    if (!value) return true; // If there's no value, other validators will handle the required case
                    const lowerCaseValue = value.toLowerCase();
                    return !props.sections.some(section => {
                        return section.section.title.toLowerCase() === lowerCaseValue
                    });
                }),
            topicResponseType: yup.string().required('Please select a response type')
        });
    },
    handleSubmit: async (values, { props }) => {
        const availableTemplates = await props.templateConfigContainer.getAvailableTemplates(props.orgContainer.state.currentOrganization.id);
        let matchingTemplate: TemplateConfigurationModel;
        const respondTemplateConfigId = getRespondTemplateConfigId();
        const transferTemplateConfigId = getTransferTemplateConfigId();
        const offerTextTemplateConfigId = getOfferTextTemplateConfigId();
        switch (values.topicResponseType) {
            case 'respond':
                matchingTemplate = availableTemplates.data.find((template) => template.id === respondTemplateConfigId);
                break;
            case 'transfer':
                matchingTemplate = availableTemplates.data.find((template) => template.id === transferTemplateConfigId);
                break;
            case 'offerText':
                matchingTemplate = availableTemplates.data.find((template) => template.id === offerTextTemplateConfigId);
                break;
        }
        if (matchingTemplate) {
            const appliedAppTemplateResponse = await props.appliedAppTemplateContainer.addAppliedTemplateToApp(props.applicationId, matchingTemplate.id);
            const nameTemplateFormSection = appliedAppTemplateResponse?.data?.templateForm?.templateFormSections?.find((section) => section.title === "Details");
            const nameTemplateFormField = nameTemplateFormSection?.templateFormFields?.find((field) => field.title === "Name");
            const formFieldValueToUpdate = appliedAppTemplateResponse?.data?.templateFormFieldValues?.find((field) => field.label === "Name");
            const updatedAppliedAppTemplate: UpdateAppliedApplicationTemplateFormRequest = {
                templateFormFieldValues: [
                    ...appliedAppTemplateResponse?.data?.templateFormFieldValues,
                    {
                        ...formFieldValueToUpdate,
                        templateFormFieldId: nameTemplateFormField.id,
                        value: values.topicName
                    }
                ],
                name: values.topicName
            };
            await props.appliedAppTemplateContainer.updateAppliedTemplate(appliedAppTemplateResponse.data.id, updatedAppliedAppTemplate);
            await props.appliedAppTemplateContainer.loadAppliedTemplates(props.applicationId);
            props.toggleNewTopicModal();
        }
    }
})(SparkNewTopic);

export default SparkNewTopicForm;