import LanguageModel from "../features/api/LanguageModel";

const argentina = require('../../content/images/flags/Argentina.svg');
const australia = require('../../content/images/flags/Australia.svg');
const belgium = require('../../content/images/flags/Belgium.svg');
const brazil = require('../../content/images/flags/Brazil.svg');
const canada = require('../../content/images/flags/Canada.svg');
const china = require('../../content/images/flags/China.svg');
const denmark = require('../../content/images/flags/Denmark.svg');
const finland = require('../../content/images/flags/Finland.svg');
const france = require('../../content/images/flags/France.svg');
const germany = require('../../content/images/flags/Germany.svg');
const greece = require('../../content/images/flags/Greece.svg');
const iceland = require('../../content/images/flags/Iceland.svg');
const india = require('../../content/images/flags/India.svg');
const ireland = require('../../content/images/flags/Ireland.svg');
const israel = require('../../content/images/flags/Israel.svg');
const italy = require('../../content/images/flags/Italy.svg');
const japan = require('../../content/images/flags/Japan.svg');
const mexico = require('../../content/images/flags/Mexico.svg');
const netherlands = require('../../content/images/flags/Netherlands.svg');
const newZealand = require('../../content/images/flags/New Zealand.svg');
const norway = require('../../content/images/flags/Norway.svg');
const poland = require('../../content/images/flags/Poland.svg');
const russia = require('../../content/images/flags/Russia.svg');
const southKorea = require('../../content/images/flags/South Korea.svg');
const spain = require('../../content/images/flags/Spain.svg');
const switzerland = require('../../content/images/flags/Switzerland.svg');
const turkey = require('../../content/images/flags/Turkey.svg');
const uk = require('../../content/images/flags/UK.svg');
const us = require('../../content/images/flags/USA.svg');
const defaultFlag = require('../../content/images/flags/Default.svg');
const austria = require('../../content/images/flags/Austria.svg');
const indonesia = require('../../content/images/flags/Indonesia.svg');
const latinAmerica = require('../../content/images/flags/Latin America.svg');
const portugal = require('../../content/images/flags/Portugal.svg');
const singapore = require('../../content/images/flags/Singapore.svg');
const sweden = require('../../content/images/flags/Sweden.svg');
const thailand = require('../../content/images/flags/Thailand.svg');

export const getFlagIcon = (regionCode: string) => {
    switch (regionCode?.toLowerCase()) {
        case "us": return us;
        case "gb": return uk;
        case "mx": return mexico;
        case "fr": return france;
        case "jp": return japan;
        case "es": return spain;
        case "au": return australia;
        case "in": return india;
        case "de": return germany;
        case "it": return italy;
        case "ca": return canada;
        case "419": return latinAmerica;
        case "cn": return china;
        case "hk": return china;
        case "tw": return china;
        case "dk": return denmark;
        case "nl": return netherlands;
        case "be": return belgium;
        case "ch": return switzerland;
        case "at": return austria;
        case "id": return indonesia;
        case "kr": return southKorea;
        case "no": return norway;
        case "pl": return poland;
        case "br": return brazil;
        case "ru": return russia;
        case "se": return sweden;
        case "th": return thailand;
        case "tr": return turkey;
        case "sg": return singapore;
    }
    return defaultFlag;
}

export const getFlagIconForLanguage = (model: LanguageModel) => {
    if(!model) return '';
    return getFlagIcon(model?.regionCode);
}

export const getRegionName = (regionCode: string) => {
    switch (regionCode?.toLowerCase()) {
        case "us": return "United States";
        case "gb": return "United Kingdom";
        case "mx": return "Mexico";
        case "fr": return "France";
        case "jp": return "Japan";
        case "es": return "Spain";
        case "au": return "Australia";
        case "in": return "India";
        case "de": return "Germany";
        case "it": return "Italy";
        case "ca": return "Canada";
        case "419": return "Latin America";
        case "cn": return "China";
        case "hk": return "Cantonese"
        case "tw": return "Traditional Chinese";
        case "dk": return "Denmark";
        case "nl": return "Netherlands";
        case "be": return "Belgium";
        case "ch": return "Switzerland";
        case "at": return "Austria";
        case "id": return "Indonesia";
        case "kr": return "Korea";
        case "no": return "Norway";
        case "pl": return "Poland";
        case "br": return "Brazil";
        case "ru": return "Russia";
        case "se": return "Sweden";
        case "th": return "Thailand";
        case "tr": return "Turkey";
        case "sg": return "Singapore";
    }

    return "";
}
export const getRegionyNameForLanguage = (model: LanguageModel) => {
    if(!model) return '';
    return getRegionName(model?.regionCode);
}

export const getLanguageName = (languageCode: string) => {
    if (!languageCode) return '';

    switch (languageCode?.toLowerCase()) {
        case "es": return "Spanish";
        case "en": return "English";
        case "fr": return "French";
        case "hi": return "Hindi";
        case "it": return "Italian";
        case "ja": return "Japanese";
        case "pt": return "Portuguese";
        case "tr": return "Turkish";
        case "th": return "Thai";
        case "sv": return "Swedish";
        case "ru": return "Russian";
        case "pl": return "Polish";
        case "no": return "Norwegian";
        case "ko": return "Korean";
        case "id": return "Indonesian";
        case "de": return "German";
        case "nl": return "Dutch";
        case "da": return "Danish";
        case "zh": return "Chinese";
    }
}
export const getLanguageNameForLanguage = (model: LanguageModel) => {
    if(!model) return '';
    return getLanguageName(model?.languageGroupCode);
}
