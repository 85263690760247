import React from "react";
import { Subscribe } from 'unstated';
import AuthContainer from '../../state/containers/AuthContainer';
import { Redirect, RouteComponentProps } from "react-router";
import UserContainer from "../../state/containers/UserContainer";
import Acceptor from "./components/Acceptor";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import ApplicationContainer from "../../state/containers/ApplicationContainer";
import Splash from "../splash";
import FeatureContainer from "../../state/containers/FeatureContainer";

interface AcceptRouteProps {
    invitationCode?: string
}
interface LoginProps extends RouteComponentProps<AcceptRouteProps> { }

const AcceptInvitation = (props: LoginProps) => {
    return (
        <div>
            <Subscribe to={[AuthContainer,
                UserContainer,
                OrganizationContainer,
                ApplicationContainer,
                FeatureContainer
            ]}>
                {(authContainer: AuthContainer,
                    userContainer: UserContainer,
                    orgContainer: OrganizationContainer,
                    appContainer: ApplicationContainer,
                    featureContainer: FeatureContainer) => {

                    // if we are logged in, accept the invite
                    // otherwise go to login
                    if (authContainer.state.isAuthenticated === true) {

                        // refresh token and data first
                        if (authContainer.state.expirationDate < new Date() || !authContainer.state.accessToken) {
                            return <Splash
                                authContainer={authContainer}
                                orgContainer={orgContainer}
                                appContainer={appContainer}
                                featureContainer={featureContainer} />
                        }
                        return <Acceptor orgContainer={orgContainer} container={userContainer} invitationCode={props.match.params.invitationCode} history={props.history} />
                    }
                    return <Redirect to={`/login/${props.match.params.invitationCode}`} />
                }}
            </Subscribe>
        </div>
    );
}

export default AcceptInvitation;