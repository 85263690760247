import React, { useState } from 'react';
import { css } from '@emotion/css';
import MenuCollapseToggle from './MenuCollapseToggle';
import { silver_four, text_blue } from '../../constants/colors';
import SlideInSlideOutComponent from './SlideInSlideOutComponent';
import useIsMobile from '../../hooks/UseIsMobile';
import { breakpoint_small } from '../../constants/breakpoints';

const menuIcon = require('../../content/images/menu-dark.svg');

interface CollapsedMenuPanelProps {
    onExpand: (e) => void
    expandedViewRender: () => JSX.Element
    minimizedDrawer?: boolean
}

const CollapsedMenuPanel: React.FC<CollapsedMenuPanelProps> = (props) => {
    const [contentExpanded, setContentExpanded] = useState(false);

    const isMobile = useIsMobile();

    const handleExpand = () => {
        setContentExpanded(true);
        addLeaveListener();
    };

    const addLeaveListener = () => {
        // we need to wait till the panel is expanded to start listening for mouse leave. 
        setTimeout(
            () => {
                const element = document.getElementById("expanded-view");
                element.addEventListener("mouseleave", () => {
                    handleMinimize()
                })
            },
            300
        );
    };

    const handleMinimize = () => {
        setContentExpanded(false);
    };

    return (
        <div className={`${props.minimizedDrawer && minimizedDrawerStyle} ${containerStyle}`}>
            {
                !contentExpanded &&
                <div className="header-container">
                    <MenuCollapseToggle
                        isMinified={true}
                        onToggle={props.onExpand}
                        minimizedDrawer={props.minimizedDrawer && props.minimizedDrawer}
                    />
                </div>
            }
            <div className="menu-container">
                {isMobile ?
                    <div className="menu-toggle" onPointerDown={props.onExpand}>
                        <img src={menuIcon} />
                    </div>
                    :
                    <div className="menu-toggle" onClick={handleExpand}>
                        <img src={menuIcon} />
                    </div>
                }
                <SlideInSlideOutComponent
                    direction="right"
                    showChildren={contentExpanded}
                    styleName={"collapsedMenuSlide"}
                >
                    <div id="expanded-view" className="floating-tree-container">
                        {props.expandedViewRender()}
                    </div>
                </SlideInSlideOutComponent>
            </div>
        </div>
    )
};

const minimizedDrawerStyle = css`
    width: 0px;
        .header-container {
            width: 0px;
        }
`;

const containerStyle = css`
    .header-container {
        position: relative;
        width: 80px;
        display: flex;
    }
    .menu-toggle {
        width: 80px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .highlighted {
        .inner - toggle {
            background: ${text_blue};
        }
    }
    .floating-tree-container {
        width: 288px;
        position: absolute;
        top: 0;
        bottom: 0;
        background: white;
        border-right: 1px solid ${silver_four};
    }
`;

export default CollapsedMenuPanel;