import React from 'react';
import { css } from '@emotion/css';
import LevelSelector from './LevelSelector';
import ssmlParseService from '../../../services/ssmlParseService';
import SsmlParseService from '../../../services/ssmlParseService';
import AudioUploader from './AudioUploader';
import MediaItemModel from '../../../models/media/MediaItemModel';
import { Subscribe } from 'unstated';
import MediaContainer from '../../../state/containers/MediaContainer';
import HorizontalSeparator from '../../structure/HorizontalSeparator';
import TextField from '../TextField';
import { cool_grey } from '../../../constants/colors';
const linkIcon = require("../../../content/images/ssml/hyperlink-3-gray.svg");

interface AudioSelectorProps {
    onChange: (ssml: string, placeholder?: string) => void
    ssml?: string
    applicationId: string
}

interface AudioSelectorState {
    value: string
}

const containerStyle = css`

    .field-container {
        height: 40px;
    }

    label {
        color: #304562;
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    p {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: ${cool_grey};
    }

`

class AudioSelector extends React.Component<AudioSelectorProps, AudioSelectorState> {
    private ssmlParser = new SsmlParseService()
    private placeholder = 'Text here is used for display';
    componentWillMount() {
        this.setInitialState();
    }

    private setInitialState() {
        if (this.props.ssml) {
            // parse the break tag to set state
            const src = this.ssmlParser.getElementProperty(this.props.ssml, 'audio', 'src')
            if (src) {
                this.setState({
                    ...this.state,
                    value: src
                })
                return;
            }
        }
        this.setState({
            ...this.state,
            value: '',
        })
    }

    handleUploadFinish(mediaItem: MediaItemModel) {
        this.setState({
            ...this.state,
            value: mediaItem.url
        })
        this.buildSsml(mediaItem.url);
    }
    handleUrlChange(e) {
        const value = e.target.value;
        this.setState({
            ...this.state,
            value: value
        })

        this.buildSsml(value);
    }
    buildSsml(value?: string) {
        value = value ? value : this.state.value
        const ssml = `<audio src="${value}">${this.placeholder}</audio>`;
        this.props.onChange(ssml, this.placeholder);
    }

    render() {
        return (
            <div className={containerStyle}>
                <p>Upload a new file or copy an existing URL below</p>
                <Subscribe to={[MediaContainer]}>
                    {(mediaContainer: MediaContainer) => {
                        return (
                            <AudioUploader
                                onFinish={this.handleUploadFinish.bind(this)}
                                applicationId={this.props.applicationId}
                                stateContainer={mediaContainer} />
                        )
                    }}
                </Subscribe>
                <HorizontalSeparator />
                <TextField icon={linkIcon} name="src" value={this.state.value} label="URL" placeholder="The url of the .mp3 file" onChange={this.handleUrlChange.bind(this)} onBlur={() => { }} />

            </div>
        )
    }
}

export default AudioSelector;