import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { color_shades_darker, color_colors_decline } from '../../../constants/colors';
import UserContainer from '../../../state/containers/UserContainer';
import AccountPanel from './AccountPanel';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import VerticalSeparator from '../../../components/structure/VerticalSeparator';
import SlideInSlideOutComponent from '../../../components/structure/SlideInSlideOutComponent';
import VoicifyNotification from '../../../models/notifications/VoicifyNotification';
import UserAvatar from '../../../components/general/UserAvatar';
import NotificationsContainer from '../../../hooks/NotificationsContainer';

interface ProfileProps {
    organizationContainer: OrganizationContainer
    userContainer: UserContainer
}

const Profile = (props: ProfileProps) => {

    const notificationsHook = NotificationsContainer.useContainer();
    const [isMenuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        props.userContainer.getUser();
        props.organizationContainer.getOrganizations();
    }, []);

    const getUnreadNotificationsCount = (notifications: VoicifyNotification[]) => {
        return notifications.filter(n => !n.isRead).length;
    };

    if (props.userContainer.state.currentUser === null) return null;
    const unreadNotificationsCount = getUnreadNotificationsCount(notificationsHook.notifications) ?? 0;
    const currentUser = props.userContainer.state.currentUser;

    return (
        <div>
            <div className={containerStyle} onClick={() => setMenuOpen(!isMenuOpen)}>
                <VerticalSeparator className={verticalSeparator} />
                {unreadNotificationsCount > 0 && <div className={unreadCount}>{unreadNotificationsCount}</div>}
                {currentUser != null && <UserAvatar className={`${avatarStyle}${unreadNotificationsCount > 0 ? " alert" : "'"} ac-user-profile-avatar`} lastName={currentUser?.lastName} firstName={currentUser?.firstName} src={currentUser?.imageUrl} size={40} />}
            </div>
            <SlideInSlideOutComponent
                styleName={"userProfileStyle"}
                direction={"left"}
                showChildren={isMenuOpen}
            >
                <AccountPanel
                    key="accountPanel"
                    organizationContainer={props.organizationContainer}
                    userContainer={props.userContainer}
                    onClose={() => setMenuOpen(false)}
                />
            </SlideInSlideOutComponent>
        </div>
    )

};

const containerStyle = css`
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    position: relative;
`

const unreadCount = css`
    background-color: ${color_colors_decline}; 
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    position: absolute;
    top: -2px;
    right: 6px;
`
const avatarStyle = css`
    margin-right: 8px;
    border-radius: 50%;
    &.alert {
        border: solid 2px ${color_colors_decline};
    }
`

const verticalSeparator = css`
    background: ${color_shades_darker};
`

export default Profile;