import React from 'react';
import { ItemGroup } from '../../../components/forms/GroupedMultiSelector';
import FilterSelector from './FilterSelector';
import FilterOption from './FilterOption';
import LanguageModel from '../../../models/features/api/LanguageModel';
import { getLanguageName, getFlagIconForLanguage, getRegionyNameForLanguage } from '../../../models/extensions/languages';
import _ from 'lodash';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';

const flagIcon = require('../../../content/images/content-explorer/flag.svg');

interface LanguageFilterProps {
    languageIds: string[]
    languages: LanguageModel[]
    onChange: (languageIds: string[]) => void
}

interface LanguageFilterState {
    expandedLanguageIds: string[]
}

class LanguageFilter extends React.Component<LanguageFilterProps, LanguageFilterState> {
    constructor(props) {
        super(props);
        this.state = {
            expandedLanguageIds: []
        }
    }

    getSelectedText() {
        const selectedLangs = this.props.languages?.filter(l => this.props.languageIds?.some(id => l.id == id));
        return selectedLangs?.map(l => l.shortCode)?.join(", ");
    }
    getLanguageGroups(): ItemGroup<LanguageModel>[] {
        var dict = _.groupBy(this.props.languages, l => l.languageGroupCode)
        var groups = Object.keys(dict).map(key => ({
            key: key,
            label: `${key.toUpperCase()} - ${getLanguageName(key)}`,
            subItems: dict[key].map(lang => ({
                data: lang,
                value: lang.id,
                icon: getFlagIconForLanguage(lang),
                label: getRegionyNameForLanguage(lang),
                groupKey: key,
                isSelected: this.props.languageIds?.some(id => id == lang.id)
            }))
        }));
        groups = _.sortBy(groups, group => group.key);
        return groups;
    }
    handleToggleExpandGroup(key: string) {
        var expandedKeys = this.state.expandedLanguageIds == null ? [] : this.state.expandedLanguageIds;
        if (expandedKeys.some(l => l == key)) {
            expandedKeys.splice(expandedKeys.indexOf(key), 1);
        }
        else {
            expandedKeys.push(key);
        }
        this.setState({
            ...this.state,
            expandedLanguageIds: expandedKeys
        })
    }
    handleToggleSelectGroup(key: string, group: ItemGroup<LanguageModel>) {      
        var languages = this.props.languages?.filter(lang => lang.languageGroupCode == key);
        var languageIds = this.props.languageIds ?? [];
        if(group?.subItems?.some(s => s.isSelected) && !group?.subItems?.some(s => !s.isSelected))// all languages are selected we should deselect everything in this group
        {
            var newLanguages = languageIds.filter(lId => !languages?.some(l => l.id == lId));               
            this.props.onChange(newLanguages)
        }
        else // either some are selected or none are selected, either way select everything in the group. 
        {
            this.props.onChange([ ...languageIds, ...(languages?.map(l => l.id) ?? [])]);
        }
    }
    handleToggleLanguage(languageId: string) {
        var languageIds = this.props.languageIds ?? []
        if (languageIds.some(l => l == languageId)) {
            languageIds.splice(languageIds.indexOf(languageId), 1);
        }
        else {
            languageIds.push(languageId);
        }

        this.props.onChange(languageIds);
    }
    handleClear() {
        this.props.onChange(null);
    }
    render() {
        const groups = this.getLanguageGroups();
        const selectedText = this.getSelectedText();
        return (
            <FilterSelector label="Languages"
                icon={flagIcon}
                placeholder={'Any Languages'}
                selectedText={selectedText}
                className="ac-analytics-language-filter"
                onClear={this.handleClear.bind(this)}>
                <CustomScrollbars autoHide autoHeight autoHeightMax={400}>
                    {groups.map(group => (
                        <React.Fragment key={group.key}>
                            <FilterOption title={group.label}
                                key={group.key}
                                onClick={() => this.handleToggleExpandGroup(group.key)}
                                onCheckClick={() => this.handleToggleSelectGroup(group.key, group) }
                                isPartialSelected={group?.subItems?.some(s => s.isSelected) && group?.subItems?.some(s => !s.isSelected)}
                                isSelected={!group.subItems.some(s => !s.isSelected)}
                                isExpanded={this.state.expandedLanguageIds.some(id => id == group.key)}
                                hasChildren
                                optionType="check" />
                            {this.state.expandedLanguageIds.some(id => id == group.key) &&
                                group.subItems.map(item => (
                                    <FilterOption title={item.label}
                                        icon={item.icon}
                                        selectedIcon={item.icon}
                                        onClick={() => this.handleToggleLanguage(item.value)}
                                        isSelected={item.isSelected}
                                        key={item.value}
                                        isChild
                                        optionType="check" />
                                ))}
                        </React.Fragment>
                    )) ?? <p>No languages available</p>}
                </CustomScrollbars>
            </FilterSelector>
        )
    }
}

export default LanguageFilter;

