import IResult from "../../models/result/IResult";
import { sendPost, sendPut, sendDelete } from "./generic";
import AppliedApplicationTemplateModel from "../../models/templating/api/AppliedApplicationTemplateModel";
import ApplicationModel from "../../models/applications/api/ApplicationModel";
import CreateApplicationFromCompletedFormsRequest from "../../models/templating/api/CreateApplicationFromCompletedFormsRequest";
import UpdateAppliedApplicationTemplateFormRequest from "../../models/templating/api/UpdateAppliedApplicationTemplateFormRequest";

export const createApplicationFromTemplateForms = (request: CreateApplicationFromCompletedFormsRequest): Promise<IResult<ApplicationModel>> => {
    return sendPost(`/ApplicationTemplating/createFromForm`, request);
}
export const updateApplicationFromTemplateForms = (applicationId: string, appliedTemplateFormId: string, request: UpdateAppliedApplicationTemplateFormRequest): Promise<IResult<AppliedApplicationTemplateModel>> => {
    return sendPut(`/ApplicationTemplating/${applicationId}/UpdateAppliedApplicationTemplateForm/${appliedTemplateFormId}`, request);
}
export const removeAppliedApplicationTemplate = (applicationId: string, appliedTemplateFormId: string): Promise<IResult<AppliedApplicationTemplateModel>> => {
    return sendDelete(`/ApplicationTemplating/${applicationId}/RemoveAppliedApplicationTemplateForm/${appliedTemplateFormId}`);
}


