import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../shared/components/general/Button';
import { OutboundCallAnsweredEvent } from '../../../../../shared/models/analytics/OutboundCallAnsweredEvent';
import moment from 'moment';
import { color_shades_dark, color_shades_light, dark_grey_blue_10, dark_grey_blue_80, faded_grey, light_black, light_gray } from '../../../../../shared/constants/colors';
import CustomScrollbars from '../../../../../shared/components/structure/CustomScrollbars';
import OutboundCallContainer from '../../../../../shared/hooks/OutboundCallContainer';
import DateRangeIntervalPicker from '../../../../../shared/components/analytics/DateRangeIntervalPicker';
import AnalyticsInterval from '../../../../../shared/models/analytics/AnalyticsInterval';
import { DATE_FORMAT } from '../../../../../shared/models/analytics/AnalyticsDateFormat';
import { css } from '@emotion/css';
import PanelHeaderContainer from '../../../../../shared/components/structure/Panels/PanelHeaderContainer';
import TimeIntervalSelectorHeader from '../../../../../shared/components/analytics/TimeIntervalSelectorHeader';
const refreshIcon = require('../../../../../shared/content/images/refresh.svg');

export default function CallHistory() {
    const [outboundCallAnsweredEvents, setOutboundCallAnsweredEvents] = useState<OutboundCallAnsweredEvent[]>([]);
    const [startDate, setStartDate] = useState<string>(moment().startOf('month').format(DATE_FORMAT));
    const [endDate, setEndDate] = useState<string>(moment().endOf('month').format(DATE_FORMAT));
    const [interval, setInterval] = useState<AnalyticsInterval>('this month');
    const [isDatePickerExpanded, setIsDatePickerExpanded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const outboundCallContainer = OutboundCallContainer.useContainer();

    useEffect(() => {
        if (outboundCallContainer.application) {
            handleRefetchCallHistory();
        }
    }, [outboundCallContainer.application, startDate, endDate]);

    const handleRefetchCallHistory = async () => {
        setIsLoading(true);
        const callHistory = await outboundCallContainer.getOutboundCallAnsweredEvents(startDate, endDate);
        if (callHistory.resultType === "Ok") {
            setOutboundCallAnsweredEvents(callHistory.data);
        }
        setIsLoading(false);
    };

    const toggleDatePicker = () => {
        setIsDatePickerExpanded(!isDatePickerExpanded);
    };

    const handleDateRangeChange = (startDate: string, endDate: string, interval: AnalyticsInterval) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setInterval(interval);
        setIsDatePickerExpanded(false);
    };

    const formatDate = (dateString: string) => {
        return moment(dateString).format('MMMM Do YYYY, h:mm:ss A');
    };

    return (
        <ClassHistoryWrapper>
            <HeaderWrapper>
                <PanelHeaderContainer className={panelHeaderStyle}>
                    <div>
                        <Title>Call History <Button themes={["icon"]} icon={refreshIcon} onClick={handleRefetchCallHistory} /></Title>
                        <TitleDescription>Status</TitleDescription>
                    </div>
                    <div>
                        <TimeIntervalSelectorHeader
                            startDate={startDate}
                            endDate={endDate}
                            isExpanded={isDatePickerExpanded}
                            onClick={toggleDatePicker}
                            className={intervalSelectorStyle}
                        />
                        {isDatePickerExpanded && (
                            <DateRangeIntervalPicker
                                startDate={startDate}
                                endDate={endDate}
                                interval={interval}
                                onChange={handleDateRangeChange}
                                showTimeIntervalPicker={false}
                                alignLeft={true}
                                onClose={toggleDatePicker}
                            />
                        )}
                    </div>
                </PanelHeaderContainer>
            </HeaderWrapper>
            {isLoading ? (
                <LoadingMessage>Loading...</LoadingMessage>
            ) : outboundCallAnsweredEvents.length === 0 ? (
                <NoCallsMessage>No calls found for the selected date range.</NoCallsMessage>
            ) : (
                <CustomScrollbars autoHide={false} autoHeight autoHeightMax="72vh">
                    {outboundCallAnsweredEvents.map((event, index) => {
                        return (
                            <CallHistoryItemWrapper key={index}>
                                <NumberInfoWrapper>
                                    <h3>{event.targetNumber}</h3>
                                    <div>
                                        <h4>{event.humanAnswered ? "Human Answered" : "Left Voicemail"}</h4>
                                        <EventDate>{formatDate(event.eventDate)}</EventDate>
                                        <Button themes={["blue-pill"]} text='Review Session' className="reviewSession-button" type='button' onClick={() => window.location.href = `/v/apps/${event.applicationId}/analytics?sessionId=${event.sessionId}`} />
                                    </div>
                                </NumberInfoWrapper>
                                <hr></hr>
                            </CallHistoryItemWrapper>
                        )
                    })}
                </CustomScrollbars>
            )}
        </ClassHistoryWrapper>
    )
}

const intervalSelectorStyle = css`
    .time-selection {
        padding-left: 16px;
        font-size: 14px;
        line-height: 32px;
        border-left: 1px solid ${color_shades_dark};   
        >span {
            margin-right: 12px;
        }
    }
`;
const panelHeaderStyle = css`
    padding: 0 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        margin-right: 0;
        margin-left: auto;
    }
    h2 {
        font-size: 18px;
        line-height: 24px;
    }
    border: none;
`;
const NoCallsMessage = styled.div`
    text-align: center;
    font-size: 16px;
    opacity: 0.9;
    padding: 20px;
    border: 1px solid ${light_gray};
    border-radius: 4px;
    background-color: ${light_gray};
    margin-top: 20px;
`;

const ClassHistoryWrapper = styled.div`
    border: 1px solid ${color_shades_light};
    border-radius: 10px;
    box-shadow: 0 4px 8px ${light_black};
    padding: 20px;
    height: 83vh;
`;

const CallHistoryItemWrapper = styled.div`
    margin-top: 20px;
    padding-right: 10px;
`;

const Title = styled.h1`
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0;
    gap: 4px;
    justify-content: center;
`;

const TitleDescription = styled.p`
    font-size: 14px;
    font-weight: light;
    opacity: 0.8;
    margin-top: -14px;
`;

const NumberInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    .reviewSession-button {
        margin: 0px;
        margin-top: 4px;
        width: 100%;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const LoadingMessage = styled.div`
    text-align: center;
    font-size: 16px;
    color: ${dark_grey_blue_80};
    padding: 20px;
    border: 1px solid ${faded_grey};
    border-radius: 4px;
    background-color: ${dark_grey_blue_10};
    margin-top: 20px;
`;

const EventDate = styled.h5`
    font-size: 12px;
    opacity: 0.8;
`;