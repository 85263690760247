import HistogramModel from "../../models/analytics/HistogramModel";
import FeatureTypeModel from "../../models/features/FeatureTypeModel";
import { Container } from "unstated";
import DatedUsageModel from "../../models/analytics/api/DatedUsageModel";
import IResult from "../../models/result/IResult";
import AnalyticsInterval from "../../models/analytics/AnalyticsInterval";
import * as voicifyApi from '../../api';
import CardinalityAggregate from "../../models/analytics/api/CardinalityAggregate";
import BreakdownModel from "../../models/analytics/BreakdownModel";
import BreakdownItem from "../../models/analytics/BreakdownItem";
import React from "react";
import { getFeatureTypeIcon, getSmallFeatureTypeIcon } from "../../models/extensions";
import { getFlagIconForLanguage, getFlagIcon, getLanguageName, getRegionName } from "../../models/extensions/languages";
import AverageItem from "../../models/analytics/Averageitem";
import AvatarContainer from "../../components/general/AvatarContainer";
import AveragesModel from "../../models/analytics/AveragesModel";
import DeviceTargetModel from "../../models/features/api/MediaResponses/DeviceTargetModel";
import moment from "moment";
import DeltaPeriod from "../../models/analytics/DeltaPeriod";
import GenericAnalyticsContainer, { AnalyticsState } from "./GenericAnalyticsContainer";
import AnalyticsScope from "../../models/analytics/AnalyticsScope";
import { getPlatformIcon } from "../../models/extensions/platforms";
import AnalyticsRequestFilter from "../../models/analytics/AnalyticsRequestFilter";
const speakerIcon = require('../../content/images/speaker.svg');
const screenIcon = require('../../content/images/screen.svg');
const chatIcon = require('../../content/images/chat-icon.svg');

export interface MeasurementState extends AnalyticsState {
    isLoadingTotals?: boolean
    isLoadingHistogram?: boolean
    isLoadingSecondaryHistogram?: boolean
    isLoadingBreakdown?: boolean
    currentBreakdown?: string
    primaryHistogramData?: HistogramModel[]
    secondaryHistogramData?: HistogramModel[]
    previousPrimaryHistogramData?: HistogramModel[]
    previousSecondaryHistogramData?: HistogramModel[]
    primaryBreakdownData?: BreakdownModel[]
    secondaryBreakdownData?: BreakdownModel[]
    previousPrimaryBreakdownData?: BreakdownModel[]
    previousSecondaryBreakdownData?: BreakdownModel[]
    averages?: AveragesModel[]
    featureTypes?: FeatureTypeModel[]
    deviceTypes?: DeviceTargetModel[]
}
export default abstract class GenericMeasurementsContainer<TState extends MeasurementState> extends GenericAnalyticsContainer<TState> {
    async loadPrimaryHistogram(scope: AnalyticsScope, interval: AnalyticsInterval, filter: AnalyticsRequestFilter): Promise<IResult<DatedUsageModel[]>> {
        try {
            await this.setLoadingHistogram(true);
            const previousPeriod = this.getPreviousDates(filter.startDate, filter.endDate);
            const existingHistogram = this.state.primaryHistogramData?.find(r => this.buildHistogramKey(r) == this.buildCacheKey(this.getContextId(scope), filter));
            const existingPrevious = this.state.primaryHistogramData?.find(r => this.buildHistogramKey(r) == this.buildCacheKey(this.getContextId(scope), {
                ...filter,
                startDate: previousPeriod.startDate,
                endDate: previousPeriod.endDate
            }));

            if (!existingPrevious)
                await this.reloadPrimaryHistogram(scope, interval, {
                    ...filter,
                    startDate: previousPeriod.startDate,
                    endDate: previousPeriod.endDate
                });

            if (existingHistogram) {
                await this.setLoadingHistogram(false);
                return new Promise<IResult<DatedUsageModel[]>>(resolve => resolve({
                    resultType: "Ok",
                    errors: null,
                    data: existingHistogram.data
                }))
            }

            // load primary and previous period at the same time
            var primaryPromise = this.reloadPrimaryHistogram(scope, interval, filter);
            await this.setLoadingHistogram(false);
            return primaryPromise
        } catch {
            await this.setLoadingHistogram(false);
        }
    }

    private async reloadPrimaryHistogram(scope: AnalyticsScope, interval: AnalyticsInterval, filter: AnalyticsRequestFilter): Promise<IResult<DatedUsageModel[]>> {
        try {
            const result = await this.getPrimaryHistogramPromise(scope, interval, filter);
            if (result.resultType == "Ok") {
                const newUsage: HistogramModel = {
                    id: this.getContextId(scope),
                    filter: { ...filter },
                    interval: interval,
                    data: result.data
                };
                newUsage.key = this.buildHistogramKey(newUsage);
                var usage = this.state.primaryHistogramData ?? [];
                usage.push(newUsage);
                await this.setState({
                    ...this.state,
                    isLoadingHistogram: false,
                    errors: [],
                    primaryHistogramData: usage
                });
            }
            return result;

        } catch {
        }

    }

    async loadSecondaryHistogram(scope: AnalyticsScope, interval: AnalyticsInterval, filter: AnalyticsRequestFilter): Promise<IResult<DatedUsageModel[]>> {
        try {
            await this.setSecondaryLoadingHistogram(true);

            const previousPeriod = this.getPreviousDates(filter.startDate, filter.endDate);

            const existingHistogram = this.state.secondaryHistogramData?.find(r => this.buildHistogramKey(r) == this.buildCacheKey(this.getContextId(scope), filter));

            const existingPrevious = this.state.secondaryHistogramData?.find(r => this.buildHistogramKey(r) == this.buildCacheKey(this.getContextId(scope), {
                ...filter,
                startDate: previousPeriod.startDate,
                endDate: previousPeriod.endDate
            }));

            if (!existingPrevious)
                await this.reloadSecondaryHistogram(scope, interval, { ...filter, startDate: previousPeriod.startDate, endDate: previousPeriod.endDate });

            if (existingHistogram) {
                await this.setSecondaryLoadingHistogram(false);
                return new Promise<IResult<DatedUsageModel[]>>(resolve => resolve({
                    resultType: "Ok",
                    errors: null,
                    data: existingHistogram.data
                }))
            }

            // load primary and previous period at the same time
            var secondaryPromise = await this.reloadSecondaryHistogram(scope, interval, filter);
            await this.setSecondaryLoadingHistogram(false);
            return secondaryPromise;
        } catch {
            await this.setSecondaryLoadingHistogram(false);
        }
    }
    private async reloadSecondaryHistogram(scope: AnalyticsScope, interval: AnalyticsInterval, filter: AnalyticsRequestFilter): Promise<IResult<DatedUsageModel[]>> {
        try {
            const result = await this.getSecondaryHistogramPromise(scope, interval, filter);
            if (result.resultType == "Ok") {
                const newUsage: HistogramModel = {
                    id: this.getContextId(scope),
                    filter,
                    interval: interval,
                    data: result.data
                };
                newUsage.key = this.buildHistogramKey(newUsage);
                var usage = this.state.secondaryHistogramData ?? [];
                usage.push(newUsage);
                await this.setState({
                    ...this.state,
                    errors: [],
                    secondaryHistogramData: usage
                })
            }
            return result;

        } catch {

        }
    }

    async loadPrimaryBreakdown(scope: AnalyticsScope, filter: AnalyticsRequestFilter, breakdownType: string): Promise<IResult<BreakdownItem[]>> {
        try {
            await this.setLoadingBreakdown(true);
            const existingBreakdown = this.state.primaryBreakdownData?.find(r => this.buildBreakdownKey(r) == this.buildCacheKey(this.getContextId(scope), filter, breakdownType));
            if (existingBreakdown) {
                await this.setLoadingBreakdown(false);
                return new Promise<IResult<BreakdownItem[]>>(resolve => resolve({
                    resultType: "Ok",
                    errors: null,
                    data: existingBreakdown.items
                }))
            }
            const result = await this.getPrimaryBreakdownPromise(scope, filter, breakdownType);
            if (result.resultType == "Ok") {
                var usage = this.state.primaryBreakdownData ?? [];
                const newUsage: BreakdownModel = {
                    id: this.getContextId(scope),
                    filter,
                    type: breakdownType,
                    items: result.data
                }
                newUsage.key = this.buildBreakdownKey(newUsage);
                usage.push(newUsage);
                await this.setState({
                    ...this.state,
                    isLoadingBreakdown: false,
                    errors: [],
                    primaryBreakdownData: usage
                });

            }
            await this.setLoadingBreakdown(false);
            return result;
        } catch {
            await this.setLoadingBreakdown(false);
        }
    }
    async loadSecondaryBreakdown(scope: AnalyticsScope, filter: AnalyticsRequestFilter, breakdownType: string): Promise<IResult<BreakdownItem[]>> {
        await this.setLoadingBreakdown(true);


        const existingBreakdown = this.state.secondaryBreakdownData?.find(r => this.buildBreakdownKey(r) == this.buildCacheKey(this.getContextId(scope), filter, breakdownType));

        if (existingBreakdown) {
            await this.setLoadingBreakdown(false);
            return new Promise<IResult<BreakdownItem[]>>(resolve => resolve({
                resultType: "Ok",
                errors: null,
                data: existingBreakdown.items
            }))
        }
        const promise = this.getSecondaryBreakdownPromise(scope, filter, breakdownType);
        promise.then(async result => {
            if (result.resultType == "Ok") {
                var usage = this.state.secondaryBreakdownData ?? [];
                const newUsage: BreakdownModel = {
                    id: this.getContextId(scope),
                    filter,
                    type: breakdownType,
                    items: result.data
                };
                newUsage.key = this.buildBreakdownKey(newUsage);
                usage.push(newUsage);
                await this.setState({
                    ...this.state,
                    isLoadingBreakdown: false,
                    errors: [],
                    secondaryBreakdownData: usage
                });

            }
            else {
                await this.setLoadingBreakdown(false);
            }
        })
            .catch(async error => {
                await this.setLoadingBreakdown(false);
            });

        return promise;
    }

    protected createBreakdownItems(aggregateData: CardinalityAggregate[], title: string, labelRender: () => string, iconRender: () => JSX.Element): BreakdownItem[] {
        const total = aggregateData.reduce((a, b) => {
            return a + b.count;
        }, 0);
        return aggregateData.map(a => ({
            count: a.count,
            percentage: a.count / total * 100,
            iconRender: iconRender,
            label: labelRender()
        }))
    }

    loadFeatureTypes(): Promise<IResult<FeatureTypeModel[]>> {
        if (this.state.featureTypes?.length > 0) {
            return new Promise((resolve) => {
                resolve({
                    data: this.state.featureTypes,
                    resultType: "Ok",
                    errors: null
                })
            });
        }

        const promise = voicifyApi.getFeatureTypes();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    featureTypes: result.data
                })
            }
        })
        return promise;
    }
    async loadDeviceTypes() {
        if (this.state.deviceTypes?.length > 0) {
            return {
                data: this.state.deviceTypes,
                resultType: "Ok",
                errors: null
            }
        }

        const promise = voicifyApi.getAllDeviceTargets();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    deviceTypes: result.data
                })
            }
        })
        return await promise;
    }

    findFeatureType(featureTypeId: string): FeatureTypeModel {
        var type = this.state.featureTypes.find(f => f.id == featureTypeId);

        if (type === undefined || type === null)
            return {
                id: 'unknown',
                name: 'Unknown',
                description: 'Unknown'
            };
        return type;
    }
    findDeviceType(deviceTypeId: string) {
        return this.state.deviceTypes?.find(f => f.id == deviceTypeId);
    }
    async handleBreakdownChange(option) {
        await this.setState({
            ...this.state,
            currentBreakdown: option
        });
    }
    getHistogramDateLabel(interval: AnalyticsInterval, date: moment.Moment) {
        if (interval == "hour")
            return date.format("hh:mm a");//the other intervals need to be converted to utc because their hour:minutes is 0
        if (interval == "dayAndHour") //more then a day but less than 2 days, need to supply Day and Hour
            return date.utc().format("DD hh:mm a")
        if (interval == "year")
            return date.utc().year().toString();
        if (interval == "month")
            return date.utc().format("MMM, YYYY");

        return date.utc().format('M/DD');
    }

    abstract getPrimaryHistogramPromise: (scope: AnalyticsScope, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) => Promise<IResult<DatedUsageModel[]>>
    abstract getSecondaryHistogramPromise: (scope: AnalyticsScope, interval: AnalyticsInterval, filter: AnalyticsRequestFilter) => Promise<IResult<DatedUsageModel[]>>
    abstract getPrimaryBreakdownPromise: (scope: AnalyticsScope, filter: AnalyticsRequestFilter, breakdownType: string) => Promise<IResult<BreakdownItem[]>>
    abstract getSecondaryBreakdownPromise: (scope: AnalyticsScope, filter: AnalyticsRequestFilter, breakdownType: string) => Promise<IResult<BreakdownItem[]>>
    abstract breakdownOptions: string[]
    abstract getAverages: (scope: AnalyticsScope, filter: AnalyticsRequestFilter) => Promise<IResult<AverageItem[]>>
    abstract getContextId: (scope: AnalyticsScope) => string

    protected getDeviceTypeImage(deviceTargetId: string) {
        var deviceType = this.findDeviceType(deviceTargetId);
        if (!deviceType?.iconUrl)
            return <AvatarContainer size={32} />

        return <img src={deviceType.iconUrl} />;
    }
    protected getDeviceCapabilityImage(category: string) {
        switch (category) {
            case "Audio": return <img src={speakerIcon} />
            case "Screen": return <img src={screenIcon} />
            case "Chat": return <img src={chatIcon} />
        }
    }
    protected getPlatformImage(platform: string) {
        return <AvatarContainer size={32} noPadding>
            <img src={getPlatformIcon(platform)} />
        </AvatarContainer>
    }
    protected getFeatureTypeImage(featureTypeId: string) {
        return <img src={getSmallFeatureTypeIcon(featureTypeId)} />
    }
    protected getLanguageImage(locale: string) {
        return <AvatarContainer size={32} noPadding>
            <img src={getFlagIcon(locale.length == 5 ? locale.substr(3, 2) : locale)} />
        </AvatarContainer>
    }
    protected getLanguageTitle(locale: string) {
        if (locale.length == 2) {
            return getLanguageName(locale);
        }
        else if (locale.length == 5) {
            return `${getLanguageName(locale.substr(0, 2))} - ${getRegionName(locale.substr(3, 2))}`;
        }
        return locale;
    }
    private async setLoadingHistogram(isLoading: boolean) {
        if (this.state.isLoadingHistogram != isLoading)
            await this.setState({
                ...this.state,
                isLoadingHistogram: isLoading
            })
    }
    private async setSecondaryLoadingHistogram(isLoading: boolean) {
        if (this.state.isLoadingSecondaryHistogram != isLoading)
            await this.setState({
                ...this.state,
                isLoadingSecondaryHistogram: isLoading
            })
    }
    private async setLoadingBreakdown(isLoading: boolean) {
        if (this.state.isLoadingBreakdown != isLoading)
            await this.setState({
                ...this.state,
                isLoadingBreakdown: isLoading
            })
    }
}