import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPut } from "./generic";
import DialogflowAgentResult from "../../models/deployments/api/DialogflowAgentListResult";
import DeploymentLink from "../../models/applications/api/DeploymentLink";
import ApplicationLinksByPlatform from "../../models/applications/api/ApplicationLinksByPlatform";
import { PLATFORM_DIALOGFLOW } from "../../constants/Urls";

export const getApplicationsLinkedToDialogflow = (organizationId: string): Promise<IResult<ApplicationLinksByPlatform[]>> => {
    return sendGet(`${PLATFORM_DIALOGFLOW}/organizationLinksToGoogle/${organizationId}`);
}

export const listDialogflowAgents = (tokenId:string) : Promise<IResult<DialogflowAgentResult[]>> => {
    return sendGet<DialogflowAgentResult[]>(`${PLATFORM_DIALOGFLOW}/Agents/${tokenId}`);
}

export const getEnvironmentDeploymentInfoForDialogflow = (applicationId: string, environmentId: string): Promise<IResult<DeploymentLink>> => {
    return sendGet(`${PLATFORM_DIALOGFLOW}/${applicationId}/environment/${environmentId}/link`)
}

export const linkEnvironmentToDialogflowAgent = (applicationId: string, environmentId: string, dialogflowAgentId: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_DIALOGFLOW}/${applicationId}/environment/${environmentId}?dialogflowAgentId=${dialogflowAgentId}`, {});
}

export const linkDialogflowTokenToEnvironment = (applicationId: string, environmentId: string, googleAuthTokenId: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_DIALOGFLOW}/${applicationId}/environment/${environmentId}/authToken/${googleAuthTokenId}`, {});
}

export const unlinkDialogflowAgentFromEnvironment = (applicationId: string, environmentId: string): Promise<IResult<boolean>> => {
    return sendDelete(`${PLATFORM_DIALOGFLOW}/${applicationId}/environment/${environmentId}`)
}

export const removeDialogflowAuthTokenFromEnvironment = (applicationId: string, environmentId: string): Promise<IResult<boolean>> => {
    return sendDelete(`${PLATFORM_DIALOGFLOW}/${applicationId}/environment/${environmentId}/authToken`)
}
