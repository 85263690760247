import { createContainer } from "unstated-next"
import AnalyticsRequestFilter from "../models/analytics/AnalyticsRequestFilter";
import { useEffect, useState } from "react";
import * as voicifyApi from '../api';
import SessionDateModel from "../models/analytics/api/SessionDateModel";
import { set } from "lodash";

function useOrderingAnalyticsContainer() {
    const [loading, setLoading] = useState(true);
    const [loadingSessionId, setLoadingSessionId] = useState("");
    const [errors, setErrors] = useState([]);
    const [itemAddedToOrderEvents, setItemAddedToOrderEvents] = useState([]);
    const [callTransferredEvents, setCallTransferredEvents] = useState([]);
    const [orderPlacedEvents, setOrderPlacedEvents] = useState([]);
    const [unsuccessfulOrderSessionIds, setFailedOrderSessionIds] = useState([]);
    const [ordersPlacedSessionIds, setOrdersPlacedSessionIds] = useState([]);
    const [orderTransferredSessionIds, setOrderTransferredSessionIds] = useState([]);
    const [noOrderSessionIds, setNoOrderSessionIds] = useState([]);
    const [allEventsCache, setAllEventsCache] = useState({});
    const [immediateTransferEvents, setImmediateTransferEvents] = useState([]);
    const [conversationalSuccessEvents, setConversationalSuccessEvents] = useState([]);
    const [sessions, setSessions] = useState([] as SessionDateModel[]);    
    const [orderAttempts, setOrderAttempts] = useState(0);
    const [ordersPlaced, setOrdersPlaced] = useState(0);
    const [transfers, setTransfers] = useState(0);
    const [orderPlacedRate, setOrderPlacedRate] = useState(0);
    const [orderTransferRate, setOrderTransferRate] = useState(0);
    const [orderTransfers, setOrderTransfers] = useState<number>(null);
    const [transferRate, setTransferRate] = useState(0);
    const [immediateTransfers, setImmediateTransfers] = useState(0);
    const [immediateTransferRate, setImmediateTransferRate] = useState(0);
    const [conversationalSuccessRate, setConversationalSuccessRate] = useState(0);
    const [attemptedOrdersConversationalSuccessRate, setAttemptedOrdersConversationalSuccessRate] = useState(0);
    const [attemptedOrderConversationalSuccess, setAttemptedOrderConversationalSuccess] = useState(0);
    const [conversationalSuccess, setConversationalSuccess] = useState(0);

    useEffect(() => {
        const itemAddedToOrderSessionIds = getUniqueSessionIds(itemAddedToOrderEvents);
        const orderAttempts = itemAddedToOrderSessionIds.length;
        setOrderAttempts(orderAttempts);

        const placedSessionIds = getUniqueSessionIds(orderPlacedEvents); 

        const ordersPlaced = placedSessionIds.length;
        setOrdersPlaced(ordersPlaced);
        setOrdersPlacedSessionIds(placedSessionIds);

        const failOrderSessionIds = itemAddedToOrderSessionIds.filter(x => !placedSessionIds.includes(x));
        setFailedOrderSessionIds(failOrderSessionIds);

        const noOrderSessions = sessions.filter(x => !itemAddedToOrderSessionIds.includes(x.sessionId));
        setNoOrderSessionIds(noOrderSessions.map(s => s.sessionId));

        const callTransferedSessionIds = getUniqueSessionIds(callTransferredEvents);
        setTransfers(callTransferedSessionIds.length);

        setTransferRate(callTransferedSessionIds.length / sessions.length);

        // union of call transferred and order attempted session ids
        const oTransferSessIds = callTransferedSessionIds.filter(x => itemAddedToOrderSessionIds.includes(x));
        setOrderTransferredSessionIds(oTransferSessIds);

        const immediateTransferSessionIds = getUniqueSessionIds(immediateTransferEvents);
        setImmediateTransferRate(immediateTransferSessionIds.length / sessions.length);
        setImmediateTransfers(immediateTransferSessionIds.length);

        // get call transfers that happened in a session where items were added to order
        if (!itemAddedToOrderSessionIds?.length) {
            setOrderPlacedRate(-1);
            setOrderTransferRate(-1);
            setOrderTransfers(-1);
        } else {
            setOrderPlacedRate(placedSessionIds.length / itemAddedToOrderSessionIds.length);
            const orderTransferedSessionIds = callTransferedSessionIds.filter(x => itemAddedToOrderSessionIds.includes(x));
            setOrderTransferRate(orderTransferedSessionIds.length / itemAddedToOrderSessionIds.length);
            setOrderTransfers(orderTransferedSessionIds?.length ?? 0);

            const conversationalSuccessSessionIds = new Set([...conversationalSuccessEvents.map(x => x.sessionId)]);
            const conversationalSuccessCount = conversationalSuccessSessionIds.size;
            setConversationalSuccess(conversationalSuccessCount);

            const attemptedSuccessSessions = itemAddedToOrderSessionIds.filter(x => conversationalSuccessSessionIds.has(x));
            const attemptedConversationalSuccessRate = attemptedSuccessSessions.length / orderAttempts;
            setAttemptedOrdersConversationalSuccessRate(attemptedConversationalSuccessRate);
            setAttemptedOrderConversationalSuccess(attemptedSuccessSessions.length);

            const sessionsCount = sessions.length;
            const allConversationalSuccessRate = conversationalSuccessCount / sessionsCount;
            setConversationalSuccessRate(allConversationalSuccessRate)
        }

    }, [itemAddedToOrderEvents, orderPlacedEvents, callTransferredEvents, sessions, immediateTransferEvents, conversationalSuccessEvents]);

    const getUniqueSessionIds = (events: any[]) => {
        const grouped = events.reduce((acc, item) => {
            if (!acc[item.sessionId]) {
                acc[item.sessionId] = [];
            }
            acc[item.sessionId].push(item);
            return acc;
        }, {});
        return Object.keys(grouped);
    };

    const loadAllEventsForSession = async (sessionId: string, applicationId: string) => {
        if (sessionId in allEventsCache) {
            return allEventsCache[sessionId];
        } else {
            setLoadingSessionId(sessionId);
            const allEvents = await voicifyApi.getAllEventsForSession(applicationId, sessionId);
            if (allEvents.resultType === "Ok") {
                allEvents.data.forEach(e => {
                    if (e.eventDate) {
                        e.timestamp = new Date(e.eventDate).getTime()
                    }
                })
                // sort by timestampt
                allEvents.data.sort((a, b) => a.timestamp - b.timestamp);
                const newEventsCache = { ...allEventsCache };
                newEventsCache[sessionId] = allEvents.data;
                setAllEventsCache(newEventsCache);
            }
            setLoadingSessionId("");
            return allEvents;
        }
    };

    const loadOrderingAnalytics = async (applicationId: string, filter: AnalyticsRequestFilter) => {
        setLoading(true);
        const tasks = []
        tasks.push(voicifyApi.getSessionsByApplication(applicationId, filter));
        tasks.push(voicifyApi.getItemAddedToOrderEventsByApplication(applicationId, filter));
        tasks.push(voicifyApi.getOrderPlacedEventsByApplication(applicationId, filter));
        tasks.push(voicifyApi.getCallTransferredEventsByApplication(applicationId, filter));
        tasks.push(voicifyApi.getImmediateTransferEventsByApplication(applicationId, filter));
        tasks.push(voicifyApi.getConversationalSuccessEventsByApplication(applicationId, filter));
        // wait for all tasks
        const results = await Promise.all(tasks);
        let errors = [];
        const errorResults = results.filter(x => x.resultType !== "Ok");
        if (errorResults.length) {
            errors = [...errors, ...errorResults.map(x => x.errors[0])];
            setErrors(errors);
            setLoading(false);
            return;
        }
        setSessions(results[0].data);
        setItemAddedToOrderEvents(results[1].data);
        setOrderPlacedEvents(results[2].data);
        setCallTransferredEvents(results[3].data);
        setImmediateTransferEvents(results[4].data);
        setConversationalSuccessEvents(results[5].data);
        setLoading(false);
    };
    
    return {
        loading,
        errors,
        unsuccessfulOrderSessionIds,
        orderAttempts,
        ordersPlaced,
        sessions,
        orderPlacedRate,
        orderTransferRate,
        transferRate,
        transfers,
        orderTransfers,
        allEventsCache,
        noOrderSessionIds,
        immediateTransfers,
        immediateTransferRate,
        loadingSessionId,
        ordersPlacedSessionIds,
        orderTransferredSessionIds,
        conversationalSuccessRate,
        attemptedOrdersConversationalSuccessRate,
        loadOrderingAnalytics,
        loadAllEventsForSession,
        conversationalSuccess,
        attemptedOrderConversationalSuccess
    };
}

const OrderingAnalyticsContainer = createContainer(useOrderingAnalyticsContainer);
export default OrderingAnalyticsContainer;
