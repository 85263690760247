import { TelephonyDeploymentFormData } from "../../scenes/applicationSettings/components/telephonyDeployments/TelephonyDeploymentFormData";
import { female1Voice } from "../sparkConstants/voices";

export const defaultTelephonyDeploymentSettings: TelephonyDeploymentFormData = {
    noTracking: false,
    phoneNumberStartsWith: "",
    ttsVoice: female1Voice,
    locale: "en-US",
    asrEndpointing: 50,
    asrModel: "nova-2",
    asrTier: "",
    firstMessageDelayMS: 0,
    mergeTurnsAfterPauses: true,
    mergeTurnTimeoutMS: 2000,
    disableBargeIn: false,
    repromptAttempts: 2,
    repromptDelaySeconds: 8,
    name: "",
    ttsProvider: "ElevenLabs",
    proactiveResponseDelayMS: 1500,
    proactiveResponses: "One moment|Let's see|Just a moment|Hold on a sec|Hang on while I check on that|Give me a moment|Hang on while I look into that",
    useProactiveResponses: true,
    disableWelcomeMessageBargeIn: true,
    disableSpeechStarted: false,
    speechStartedUnconfirmedBargeInLimit: 3,
    speechStartedPauseMS: 2000,
    speechStartedRequiredBufferCount: 8,
    callRecordingEnabled: false,
    speechStartedLevel: 0.9,
    attemptInitialTransfer: false,
    initialTransferTimeoutSeconds: 8,
    initialTransferTarget: "",
    initialTransferWithCallingNumber: false,
    virtualNumber: null,
    useVirtualNumber: false
};