import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import MediaItemModel from '../../models/media/MediaItemModel';
import { resolve } from 'url';
import SsmlCacheItem from '../../models/ssml/SsmlCacheItem';
import SpeechOutput from '../../models/ssml/api/SpeechOutput';
import SsmlRequest from '../../models/ssml/api/SsmlRequest';

type SsmlState = {
    ssmlCacheItems: SsmlCacheItem[]
    isUploading: boolean
    isLoading: boolean
    errors: string[]
    applicationId: string
}

export default class SsmlContainer extends Container<SsmlState> {
    // default state
    state = {
        ssmlCacheItems: [] as SsmlCacheItem[],
        isUploading: false,
        isLoading: false,
        errors: [],
        applicationId: ''
    }

    getCachedSsmlSpeech(ssml: string, assistant: string, locale: string): SpeechOutput[] {
        const existingItem = this.state.ssmlCacheItems.find(c => c.ssml == ssml && c.assistant == assistant && c.locale == locale);
        if(!existingItem) return null;

        return existingItem.outputAudioFiles;
    }

    getSpeechOutput(ssml: string, assistant: string, locale: string): Promise<IResult<SpeechOutput[]>> {
        const existingItem = this.state.ssmlCacheItems.find(c => c.ssml == ssml && c.assistant == assistant && c.locale == locale);
        if(existingItem) {
            return new Promise(resolve => resolve({
                resultType: "Ok",
                data: existingItem.outputAudioFiles,
                errors: null
            }));
        }

        this.setLoading(true);
        const request: SsmlRequest = {
            ssml: ssml,
            locale: locale 
        };
        var promise = voicifyApi.ssmlToSpeech(request, assistant);
        promise.then(result => {
            if(result.resultType == "Ok") {
                const cache = this.state.ssmlCacheItems;
                cache.push({
                    assistant: assistant,
                    ssml: ssml,
                    outputAudioFiles: result.data,
                    locale: locale
                });
                this.setState({
                    ...this.state,
                    errors: [],
                    isLoading: false,
                    ssmlCacheItems: cache                
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors:result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })
        return promise;
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    };

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}