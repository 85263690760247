import IResult, { ResultType } from "./IResult";

export default class UnauthorizedResult<T> implements IResult<T> {
    data: T = null;
    resultType: ResultType = "Unauthorized";
    errors: string[];

    constructor (error: string = "Your session has expired. Please sign in and try again.") {
        this.errors = [error];
    }
}