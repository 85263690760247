import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import TemplateConfigurationModel, { TemplateType } from '../../models/templating/api/TemplateConfigurationModel';
import TemplateCategoryModel from '../../models/templating/api/TemplateCategoryModel';
import TemplateIndustryModel from '../../models/templating/api/TemplateIndustryModel';
import { CardTableOption } from '../../models/general/CardTableOption';
import TemplateConfigurationCountModel from '../../models/templating/api/TemplateConfigurationCountModel';

type TemplateConfigurationState = {
    availableTemplates: TemplateConfigurationModel[]
    organizationTemplates: TemplateConfigurationModel[]
    privateTemplates: TemplateConfigurationModel[]
    categories: TemplateCategoryModel[]
    industries: TemplateIndustryModel[]
    appliedTemplateCounts: TemplateConfigurationCountModel[]
    isLoading: boolean
    isSaving: boolean
    isLoadingCategories: boolean
    isLoadingIndustries: boolean
    defaultPageSize: number
    defaultViewType: CardTableOption
    categoryIdFilter?: string
    industryIdFilter?: string
    templateTypeFilter?: TemplateType
    searchFilter?: string
    currentDiscoverPage?: number
    defaultSortBy?: string
    organizationId?: string
    errors: string[]
}

export default class TemplateConfigurationContainer extends Container<TemplateConfigurationState> {
    // default state
    constructor() {
        super();
        const params = new URLSearchParams(location.search);
        this.state = {
            availableTemplates: [] as TemplateConfigurationModel[],
            organizationTemplates: [] as TemplateConfigurationModel[],
            privateTemplates: [] as TemplateConfigurationModel[],
            categories: [] as TemplateCategoryModel[],
            industries: [] as TemplateIndustryModel[],
            appliedTemplateCounts: [] as TemplateConfigurationCountModel[],
            isLoadingCategories: false,
            isLoadingIndustries: false,
            isLoading: false,
            isSaving: false,
            defaultPageSize: parseInt(params.get("pageSize") ?? localStorage.getItem("modulesPageSize") ?? "25"),
            currentDiscoverPage: parseInt(params.get("page") ?? "1"),
            templateTypeFilter: params.get("templateType") as TemplateType,
            categoryIdFilter: params.get("categoryId"),
            industryIdFilter: params.get("industryId"),
            defaultViewType: (localStorage.getItem("modulesViewType") ?? "card") as CardTableOption,
            defaultSortBy: "NameAscending",
            organizationId: JSON.parse(localStorage.getItem("CURRENT_ORG") ?? "{}")?.id,
            errors: []
        }
    }
    async loadTemplateConfigurationCounts(organizationTemplates: TemplateConfigurationModel[]): Promise<TemplateConfigurationCountModel[]> {
        try {
            const templateConfigurationCountArray: TemplateConfigurationCountModel[] = [];
            for (var i = 0; i < organizationTemplates.length; i++) {
                const template = organizationTemplates[i];
                const result = await voicifyApi.getAppliedTemplateFormCounts(template.id);
                if (result.resultType == "Ok") {
                    templateConfigurationCountArray.push(result.data);
                }
                else {
                    await this.setState({
                        ...this.state,
                        errors: result.errors
                    })
                }
            }
            await this.setState({
                ...this.state,
                appliedTemplateCounts: templateConfigurationCountArray,
                errors: []
            })
            return templateConfigurationCountArray;
        } catch (error) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        }
    };

    getAvailableTemplates(organizationId: string) {
        this.setLoading(true);
        const promise = voicifyApi.getAvailableTemplateConfigurations(organizationId, {
            skip: this.state.defaultPageSize * (this.state.currentDiscoverPage - 1),
            take: this.state.defaultPageSize,
            searchQuery: this.state.searchFilter,
            templateCategoryId: this.state.categoryIdFilter,
            templateIndustryId: this.state.industryIdFilter,
            templateType: this.state.templateTypeFilter,
            sortBy: this.state.defaultSortBy
        });
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    availableTemplates: result.data,
                    errors: []
                })
            } else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            })
        })
        return promise;
    };

    async getOrganizationTemplates(organizationId: string) {
        try {
            await this.setLoading(true);
            const result = await voicifyApi.getOrganizationTemplateConfigurations(organizationId);
            if (result.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    organizationTemplates: result.data,
                    isLoading: false,
                    errors: []
                })
                this.loadTemplateConfigurationCounts(result.data);
            } else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;
        } catch (e) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            })
        }
    };

    getPrivateTemplates(organizationId: string) {
        this.setLoading(true);
        const promise = voicifyApi.getPrivateTemplateConfigurations(organizationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    privateTemplates: result.data,
                    errors: []
                })
            } else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            })
        })
        return promise;
    };

    async createEmptyTemplateConfiguration(orgId: string, applicationId?: string, dynamicFinalizeUrl?: string) {
        await this.setSaving(true);
        try {
            const result = await voicifyApi.createTemplateConfiguration({
                organizationId: orgId,
                templateApplicationId: applicationId ? applicationId : null,
                name: "New Template",
                description: "template description",
                finalizeFormUrl: dynamicFinalizeUrl ? dynamicFinalizeUrl : null,
                templateForm: {
                    templateFormSections: []
                },
                currentVersion: {
                    versionName: "1.0",
                    releaseNotes: "initial template"
                }
            });

            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: [],
                    organizationTemplates: [...this.state.organizationTemplates, result.data]
                });
            } else {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
            }
            return result;
        } catch (e) {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [e?.toString()]
            })
        }

    };

    updateTemplateConfiguration(template: TemplateConfigurationModel) {
        this.setSaving(true);
        const promise = voicifyApi.updateTemplateConfiguration(template.id, template);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const templates = this.state.organizationTemplates;
                const templateIndex = templates.findIndex(t => t.id == template.id);
                templates[templateIndex] = result.data;

                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: [],
                    organizationTemplates: templates
                });
            } else {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [e?.toString()]
            })
        })
        return promise;
    };

    async changeTemplateFilter(newState: TemplateConfigurationState) {
        // updates state and reloads the templates using it
        await this.setState(newState);
        await this.getAvailableTemplates(this.state.organizationId);
    };

    async loadCategories() {
        await this.setState({
            ...this.state,
            isLoadingCategories: true
        })
        try {
            const result = await voicifyApi.getTemplateCategories();
            await this.setState({
                ...this.state,
                isLoadingCategories: false,
                errors: result.errors,
                categories: result.data
            })
            return result;

        } catch (e) {
            await this.setState({
                ...this.state,
                isLoadingCategories: false
            })
            return {
                resultType: "Unexpected",
                errors: [e.toString()]
            }
        }
    };

    async loadIndustries() {
        await this.setState({
            ...this.state,
            isLoadingIndustries: true
        })
        try {
            const result = await voicifyApi.getTemplateIndustries();
            await this.setState({
                ...this.state,
                isLoadingIndustries: false,
                errors: result.errors,
                industries: result.data
            })
            return result;

        } catch (e) {
            await this.setState({
                ...this.state,
                isLoadingIndustries: false
            })
            return {
                resultType: "Unexpected",
                errors: [e.toString()]
            }
        }
    };

    async disableTemplateConfiguration(templateConfigurationId: string) {
        try {
            await this.setLoading(true);
            const result = await voicifyApi.disableTemplateConfiguration(templateConfigurationId);
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: result.errors
            })
            return result;

        } catch (e) {
            await this.setState({
                ...this.state,
                isLoadingIndustries: false
            })
            return {
                resultType: "Unexpected",
                errors: [e.toString()]
            }
        }
    };

    private async setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading
        });
    };

    private setSaving(isSaving: boolean) {
        this.setState({
            ...this.state,
            isSaving
        });
    };

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    };

}