import { VoicifyAssistantSettings } from "@voicify/voicify-browser-assistant-sdk";
import { useState } from "react";
import { getAssistantPreviewApiUrl } from "../../../constants/Urls";
import { getKotlinHexValue, MobileAssistantUIProps } from "../../../models/customAssistant/CustomAssistantCodeExportTypes";
import { CustomAssistantConfigurationsFormData } from "../../../models/customAssistant/CustomAssistantConfigurationsFormData";
import { Theme } from "../../../models/customAssistant/CustomAssistantConfigurationsTheme";
import { Header } from "../../../models/customAssistant/CustomAssistantConfigurationStyleHeader";
import { Body } from "../../../models/customAssistant/CustomAssistantConfigurationStyleBody";
import { Toolbar } from "../../../models/customAssistant/CustomAssistantConfigurationStyleToolbar";
import { transparent } from "../../../constants/customAssistantConfigurationStyles/customAssistantConfigurationsColors";
import { CodeExportType } from "../components/customAssistantConfigurations/CustomAssisantExportTypeDropdown";
import { createContainer } from "unstated-next";
import { Gradient } from "./useCustomAssistantConfigurationColors";

function useCustomAssistantConfigurationSettings() {
    const [browserAssistantSettings, setBrowserAssistantSettings] = useState<VoicifyAssistantSettings>(null);
    const [swiftAssistantSettings, setSwiftAssistantSettings] = useState<MobileAssistantUIProps>(null);
    const [kotlinAssistantSettings, setKotlinAssistantSettings] = useState<MobileAssistantUIProps>(null);
    const [assistantSettingsExport, setAssistantSettingsExport] = useState<string>('');
    const [loading, setLoading] = useState<boolean>();

    type CodeExportLanguage = "JavaScript" | "Swift" | "Kotlin";

    const closeImages = {
        style1: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/close.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/close.png',
        },
        style2: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/closeTwo.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/close-2.png',
        }
    }

    const micImages = {
        style1: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/micActive.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/mic-image.png',
        },
        style2: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/micTwoActive.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/mic-image-2.png',
        },
        style3: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/micThreeActive.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/mic-image-3.png',
        }
    }

    const sendImages = {
        style1: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendActive.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/send-image.png',
        },
        style2: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendTwoActive.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/send-image-2.png',
        },
        style3: {
            browser: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/sendThreeActive.svg',
            mobile: 'https://voicify-prod-files.s3.amazonaws.com/sdk-images/send-image-3.png'
        }
    }

    const sharedAssistantSettings = (values: CustomAssistantConfigurationsFormData, applicationId: string, appKey: string) => {
        return {
            serverRootUrl: getAssistantPreviewApiUrl(),
            appId: applicationId,
            appKey: appKey,
            locale: values.locale,
            channel: "My App",
            device: "My device",
            textToSpeechVoice: values.textToSpeechVoice ?? "",
            autoRunConversation: values.autoRunConversation,
            initializeWithWelcomeMessage: values.initializeWithWelcomeMessage,
            textToSpeechProvider: values.textToSpeechProvider,
            useVoiceInput: values.useVoiceInput,
            useOutputSpeech: values.useOutputSpeech,
            useDraftContent: values.useDraftContent,
            noTracking: values.noTracking
        }
    }

    const sharedHeaderProps = (values: CustomAssistantConfigurationsFormData, theme: Theme, appAvatar: string, appName: string, isKotlin = false): Header => {
        return {
            backgroundColor: values.headerBackgroundColor ? getKotlinHexValue(values.headerBackgroundColor, isKotlin) : values.overallBackgroundColor ? transparent : getKotlinHexValue(theme.header.backgroundColor, isKotlin),
            assistantName: values.displayName ? values.displayName : appName,
            assistantNameTextColor: values.headerAssistantNameTextColor ? getKotlinHexValue(values.headerAssistantNameTextColor, isKotlin) : getKotlinHexValue(theme.assistantNameTextColor, isKotlin),
            assistantImage: values.avatarUrl ? values.avatarUrl : appAvatar,
            fontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
        }
    }

    const sharedBodyProps = (values: CustomAssistantConfigurationsFormData, theme: Theme, appAvatar: string, isKotlin = false): Body => {
        return {
            backgroundColor: values.bodyBackgroundColor ? getKotlinHexValue(values.bodyBackgroundColor, isKotlin) : getKotlinHexValue(values.overallBackgroundColor, isKotlin) ? transparent : getKotlinHexValue(theme.body.backgroundColor, isKotlin),
            messageSentTextColor: values.bodyMessageSentTextColor ? getKotlinHexValue(values.bodyMessageSentTextColor, isKotlin) : theme.messageSentTextColor && getKotlinHexValue(theme.messageSentTextColor, isKotlin),
            messageSentBackgroundColor: values.primaryColor ? getKotlinHexValue(values.primaryColor, isKotlin) : values.bodyMessageSentBackgroundColor ? getKotlinHexValue(values.bodyMessageSentBackgroundColor, isKotlin) : theme.messageSentBackgroundColor ? getKotlinHexValue(theme.messageSentBackgroundColor, true) : null,
            messageReceivedTextColor: values.bodyMessageReceivedTextColor ? getKotlinHexValue(values.bodyMessageReceivedTextColor, isKotlin) : theme.messageReceivedTextColor && getKotlinHexValue(theme.messageReceivedTextColor, isKotlin),
            messageReceivedBackgroundColor: values.bodyMessageReceivedBackgroundColor ? getKotlinHexValue(values.bodyMessageReceivedBackgroundColor, isKotlin) : theme.messageReceivedBackgroundColor ? getKotlinHexValue(theme.messageReceivedBackgroundColor, isKotlin) : null,
            messageSentFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            messageReceivedFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            hintsTextColor: theme.hintsTextColor && getKotlinHexValue(theme.hintsTextColor, isKotlin),
            hintsBackgroundColor: theme.hintsBackgroundColor && getKotlinHexValue(theme.hintsBackgroundColor, isKotlin),
            hintsFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            assistantImage: values.avatarUrl ? values.avatarUrl : appAvatar,
            messageReceivedBorderColor: getKotlinHexValue(values.overallBorderColor, isKotlin),
            messageSentBorderColor: getKotlinHexValue(values.overallBorderColor, isKotlin),
            borderBottomColor: getKotlinHexValue(values.overallBorderColor, isKotlin),
            borderTopColor: getKotlinHexValue(values.overallBorderColor, isKotlin),
        }
    }

    const sharedToolbarProps = (values: CustomAssistantConfigurationsFormData, theme: Theme, isKotlin = false): Toolbar => {
        return {
            backgroundColor: values.toolbarBackgroundColor ? getKotlinHexValue(values.toolbarBackgroundColor, isKotlin) : values.overallBackgroundColor ? transparent : getKotlinHexValue(theme.toolbar.backgroundColor, isKotlin),
            speakActiveTitleColor: (values.themeStyle === "darkStyle2") ? getKotlinHexValue(theme.speakActiveTitleColor, isKotlin) : values.primaryColor ? getKotlinHexValue(values.primaryColor, isKotlin) : getKotlinHexValue(theme.speakActiveTitleColor, isKotlin),
            speakInactiveTitleColor: values.toolbarFontColor ? getKotlinHexValue(values.toolbarFontColor, isKotlin) : getKotlinHexValue(theme.speakInactiveTitleColor, isKotlin),
            typeActiveTitleColor: (values.themeStyle === "darkStyle2") ? getKotlinHexValue(theme.typeActiveTitleColor, isKotlin) : values.primaryColor ? getKotlinHexValue(values.primaryColor, isKotlin) : getKotlinHexValue(theme.typeActiveTitleColor, isKotlin),
            typeInactiveTitleColor: values.toolbarFontColor ? getKotlinHexValue(values.toolbarFontColor, isKotlin) : getKotlinHexValue(theme.typeInactiveTitleColor, isKotlin),
            partialSpeechResultTextColor: theme.partialSpeechResultTextColor && getKotlinHexValue(theme.partialSpeechResultTextColor, isKotlin),
            fullSpeechResultTextColor: theme.fullSpeechResultTextColor && getKotlinHexValue(theme.fullSpeechResultTextColor, isKotlin),
            speechResultBoxBackgroundColor: values.primaryColor ? getKotlinHexValue(values.primaryColor, isKotlin) : getKotlinHexValue(theme.speechResultBoxBackgroundColor, isKotlin),
            textInputTextColor: theme.textInputTextColor && getKotlinHexValue(theme.textInputTextColor, isKotlin),
            helpTextFontColor: values.toolbarFontColor ? getKotlinHexValue(values.toolbarFontColor, isKotlin) : getKotlinHexValue(theme.helpTextFontColor, isKotlin),
            partialSpeechResultFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            assistantStateFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            helpTextFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            speakFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            typeFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            textboxFontFamily: values.font ? values.font : theme.font ? theme.font : 'sans-serif',
            textInputActiveLineColor: getKotlinHexValue(theme.textInputActiveLineColor, isKotlin),
            textInputLineColor: theme.textInputLineColor && getKotlinHexValue(theme.textInputLineColor, isKotlin),
            placeholder: values.toolbarPlaceholder,
        }
    }

    const updateAssistantSettings = (values: CustomAssistantConfigurationsFormData, gradient: Gradient, applicationId: string, appKey: string, browserTheme: Theme, mobileTheme: Theme, iconFilter: string,
        appAvatar: string, appName: string, highlight: string) => {
    
        const isMinimalUI: boolean = values.uiType === "minimal";

        const isDarkStyle2: boolean = values.themeStyle === "darkStyle2";

        const updatedBrowserAssistantSetting: VoicifyAssistantSettings = {
            ...sharedAssistantSettings(values, applicationId, appKey),
            sessionTimeout: 60 * 60 * 1000,
            environmentId: null,
            uiType: values.uiType,
            openByDefault: false,
            textToSpeechVoice: values.textToSpeechVoice,
            activeInput: values.activeInput,
            showConversationHistory: values.showConversationHistory,
            styles: {
                assistant: {
                    zIndex: '1',
                    backgroundColor: values.overallBackgroundColor ? values.overallBackgroundColor : (isDarkStyle2 && values.primaryColor && isMinimalUI) ? highlight : (isDarkStyle2 && values.primaryColor) ? gradient.swift : browserTheme.overallBackgroundColor,
                },
                header: {
                    ...sharedHeaderProps(values, browserTheme, appAvatar, appName),
                    closeAssistantColor: isDarkStyle2 ? browserTheme.closeAssistantColor : iconFilter ? iconFilter : browserTheme.closeAssistantColor,
                    minimizeAssistantColor: isDarkStyle2 ? browserTheme.minimizeAssistantColor : iconFilter ? iconFilter : browserTheme.minimizeAssistantColor,
                    minimizeIcon: values.headerMinimizeIcon,
                    closeAssistantButtonImage: values.headerCloseAssistantButtonImage
                },
                body: {
                    ...sharedBodyProps(values, browserTheme, appAvatar)
                },
                toolbar: {
                    ...sharedToolbarProps(values, browserTheme),
                    textboxActiveHighlightColor: highlight ? highlight : browserTheme.textboxActiveHighlightColor,
                    equalizerColor: isDarkStyle2 ? browserTheme.equalizerColor : gradient.javaScript ? gradient.javaScript : browserTheme.equalizerColor && browserTheme.equalizerColor,
                    equalizerColorOne: values.primaryColor && values.primaryColor,
                    equalizerColorTwo: highlight && highlight,
                    muteImageColor: browserTheme.muteImageColor,
                    muteImage: "https://voicify-prod-files.s3.amazonaws.com/sdk-images/mute.svg",
                    muteFontColor: values.toolbarFontColor ? values.toolbarFontColor : browserTheme.muteFontColor,
                    muteFontFamily: values.font ? values.font : browserTheme.font ? browserTheme.font : 'sans-serif',
                    micActiveColor: isDarkStyle2 ? browserTheme.micActiveColor : iconFilter ? iconFilter : browserTheme.micActiveColor,
                    keyboardColor: isDarkStyle2 ? browserTheme.keyboardColor : iconFilter ? iconFilter : browserTheme.keyboardColor,
                    sendActiveColor: isDarkStyle2 ? browserTheme.sendActiveColor : iconFilter ? iconFilter : browserTheme.sendActiveColor,
                    sendInactiveColor: isDarkStyle2 ? browserTheme.sendInactiveColor : iconFilter ? iconFilter : browserTheme.sendInactiveColor,
                    assistantStateFontColor: values.toolbarFontColor ? values.toolbarFontColor : browserTheme.assistantStateFontColor,
                    assistantStateTextColor: values.headerAssistantNameTextColor ? values.headerAssistantNameTextColor : browserTheme.assistantNameTextColor,
                    micActiveImage: values.toolbarMicActiveImage,
                    micInactiveImage: values.toolbarMicActiveImage,
                    sendActiveImage: values.toolbarSendActiveImage,
                    sendInactiveImage: values.toolbarSendActiveImage
                },
                start: {
                    assistantImage: values.avatarUrl ? values.avatarUrl : appAvatar,
                    buttonBackgroundColor: values.primaryColor ? values.primaryColor : browserTheme.buttonBackgroundColor,
                    buttonText: values.startButtonText,
                    buttonFontFamily: values.font ? values.font : browserTheme.font ? browserTheme.font : 'sans-serif',
                    buttonFontSize: values.startButtonFontSize,
                }
            }
        };

        const
            updatedSwiftAssistantSettings = {
                assistantSettings: {
                    ...sharedAssistantSettings(values, applicationId, appKey),
                    initializeWithText: values.activeInput === "textbox",
                    backgroundColor: values.overallBackgroundColor ? values.overallBackgroundColor : (isDarkStyle2 && values.primaryColor && isMinimalUI) ? highlight : (isDarkStyle2 && values.primaryColor) ? gradient.swift : mobileTheme.overallBackgroundColor,
                },
                headerProps: {
                    ...sharedHeaderProps(values, mobileTheme, appAvatar, appName),
                    closeAssistantColor: mobileTheme.closeAssistantColor,
                    closeAssistantButtonImage: values.headerCloseAssistantButtonImage === closeImages.style1.browser ? closeImages.style1.mobile : closeImages.style2.mobile,
                },
                bodyProps: {
                    ...sharedBodyProps(values, mobileTheme, appAvatar)
                },
                toolbarProps: {
                    ...sharedToolbarProps(values, mobileTheme),
                    textboxActiveHighlightColor: mobileTheme.textboxActiveHighlightColor,
                    equalizerColor: isDarkStyle2 ? mobileTheme.equalizerColor : gradient.swift ? gradient.swift : mobileTheme.equalizerColor && mobileTheme.equalizerColor,
                    equalizerColorOne: values.primaryColor && values.primaryColor,
                    equalizerColorTwo: highlight && highlight,
                    micActiveColor: mobileTheme.micActiveColor,
                    keyboardColor: mobileTheme.keyboardColor,
                    sendActiveColor: mobileTheme.sendActiveColor,
                    sendInactiveColor: mobileTheme.sendInactiveColor,
                    assistantStateTextColor: values.toolbarFontColor ? values.toolbarFontColor : mobileTheme.assistantStateFontColor,
                    micActiveImage: values.toolbarMicActiveImage === micImages.style1.browser ? micImages.style1.mobile : values.toolbarMicActiveImage === micImages.style2.browser ? micImages.style2.mobile : micImages.style3.mobile,
                    micInactiveImage: values.toolbarMicActiveImage === micImages.style1.browser ? micImages.style1.mobile : values.toolbarMicActiveImage === micImages.style2.browser ? micImages.style2.mobile : micImages.style3.mobile,
                    sendActiveImage: values.toolbarSendActiveImage === sendImages.style1.browser ? sendImages.style1.mobile : values.toolbarSendActiveImage === sendImages.style2.browser ? sendImages.style2.mobile : sendImages.style3.mobile,
                    sendInactiveImage: values.toolbarSendActiveImage === sendImages.style1.browser ? sendImages.style1.mobile : values.toolbarSendActiveImage === sendImages.style2.browser ? sendImages.style2.mobile : sendImages.style3.mobile,
                }
            }

        const updatedKotlinAssistantSettings = {
            assistantSettings: {
                ...sharedAssistantSettings(values, applicationId, appKey),
                initializeWithText: values.activeInput === "textbox",
                backgroundColor: values.overallBackgroundColor ? getKotlinHexValue(values.overallBackgroundColor, true) : (isDarkStyle2 && values.primaryColor && isMinimalUI) ? highlight : (isDarkStyle2 && values.primaryColor) ? highlight : getKotlinHexValue(mobileTheme.overallBackgroundColor, true),
            },
            headerProps: {
                ...sharedHeaderProps(values, mobileTheme, appAvatar, appName, true),
                closeAssistantColor: getKotlinHexValue(mobileTheme.closeAssistantColor, true),
                closeAssistantButtonImage: values.headerCloseAssistantButtonImage === closeImages.style1.browser ? closeImages.style1.mobile : closeImages.style2.mobile
            },
            bodyProps: {
                ...sharedBodyProps(values, mobileTheme, appAvatar, true)
            },
            toolbarProps: {
                ...sharedToolbarProps(values, mobileTheme, true),
                textboxActiveHighlightColor: getKotlinHexValue(mobileTheme.textboxActiveHighlightColor, true),
                equalizerColor: isDarkStyle2 ? getKotlinHexValue(mobileTheme.equalizerColor, true) : gradient.kotlin ? gradient.kotlin : getKotlinHexValue(mobileTheme.equalizerColor, true) && getKotlinHexValue(mobileTheme.equalizerColor, true),
                equalizerColorOne: getKotlinHexValue(values.primaryColor, true) && getKotlinHexValue(values.primaryColor, true),
                equalizerColorTwo: getKotlinHexValue(highlight, true) && getKotlinHexValue(highlight, true),
                micActiveColor: getKotlinHexValue(mobileTheme.micActiveColor, true),
                keyboardColor: getKotlinHexValue(mobileTheme.keyboardColor, true),
                sendActiveColor: getKotlinHexValue(mobileTheme.sendActiveColor, true),
                sendInactiveColor: getKotlinHexValue(mobileTheme.sendInactiveColor, true),
                assistantStateTextColor: values.toolbarFontColor ? getKotlinHexValue(values.toolbarFontColor, true) : getKotlinHexValue(mobileTheme.assistantStateFontColor, true),
                micActiveImage: values.toolbarMicActiveImage === micImages.style1.browser ? micImages.style1.mobile : values.toolbarMicActiveImage === micImages.style2.browser ? micImages.style2.mobile : micImages.style3.mobile,
                micInactiveImage: values.toolbarMicActiveImage === micImages.style1.browser ? micImages.style1.mobile : values.toolbarMicActiveImage === micImages.style2.browser ? micImages.style2.mobile : micImages.style3.mobile,
                sendActiveImage: values.toolbarSendActiveImage === sendImages.style1.browser ? sendImages.style1.mobile : values.toolbarSendActiveImage === sendImages.style2.browser ? sendImages.style2.mobile : sendImages.style3.mobile,
                sendInactiveImage: values.toolbarSendActiveImage === sendImages.style1.browser ? sendImages.style1.mobile : values.toolbarSendActiveImage === sendImages.style2.browser ? sendImages.style2.mobile : sendImages.style3.mobile,
            }
        }

        setBrowserAssistantSettings(updatedBrowserAssistantSetting);
        setSwiftAssistantSettings(updatedSwiftAssistantSettings)
        setKotlinAssistantSettings(updatedKotlinAssistantSettings)
    }

    const generateAssistantSettingsExport = (customAssistantSDKVersion: string, codeExportLanguage: CodeExportLanguage, exportType: CodeExportType, configurationId: string, appId: string, appKey: string) => {
        setLoading(true);

        const dynamicBrowserAssistantSetting = {
            configurationId: configurationId,
            serverRootUrl: getAssistantPreviewApiUrl(),
            appId: appId,
            appKey: appKey
        };

        let codeExport = "";
        const browserSettingsString = JSON.stringify(browserAssistantSettings, null, 2);
        const dynamicBrowserSettingsString = JSON.stringify(dynamicBrowserAssistantSetting, null, 2);
        if (exportType === "static") {
            switch (codeExportLanguage) {
                case "JavaScript":
                    codeExport =
                        `<script src='https://sdk.voicify.com/browser-sdk/${customAssistantSDKVersion}/voicifyAssistant.js'></script>\n` +
                        "<script>\ndocument.addEventListener('DOMContentLoaded', (event) => {\nwindow.initializeVoicifyAssistant(" +
                        browserSettingsString.replace(/"([^"]+)":/g, '$1:') +
                        "\n)});</script>\n";
                    break;
                case "Swift":
                    let swiftAssistantSettingsJSON = mobileFormattedJSON(swiftAssistantSettings?.assistantSettings)
                    let swiftHeaderJSON = mobileFormattedJSON(swiftAssistantSettings?.headerProps)
                    let swiftBodyJSON = mobileFormattedJSON(swiftAssistantSettings?.bodyProps)
                    let swiftToolbarJSON = mobileFormattedJSON(swiftAssistantSettings?.toolbarProps)
                    codeExport =
                        `AssistantDrawerUI(\n assistantSettings: AssistantSettingsProps(${swiftAssistantSettingsJSON.replace("null", "nil")}),\nheaderProps: HeaderProps( ${swiftHeaderJSON.replace("null", "nil")}),\nbodyProps: BodyProps( ${swiftBodyJSON.replace("null", "nil")}),\ntoolbarProps: ToolbarProps( ${swiftToolbarJSON.replace("null", "nil")} )\n)`
                    break;
                case "Kotlin":
                    const kotlinAssistantSettingsJSON = mobileFormattedJSON(kotlinAssistantSettings?.assistantSettings)
                    const kotlinHeaderJSON = mobileFormattedJSON(kotlinAssistantSettings?.headerProps)
                    const kotlinBodyJSON = mobileFormattedJSON(kotlinAssistantSettings?.bodyProps)
                    const kotlinToolbarJSON = mobileFormattedJSON(kotlinAssistantSettings?.toolbarProps)
                    let formattedKotlinAssistantSettingsJSON = kotlinAssistantSettingsJSON.replace(/: \s*/g, " = ");
                    let formattedKotlinHeaderJSON = kotlinHeaderJSON.replace(/: \s*/g, " = ");
                    let formattedKotlinBodyJSON = kotlinBodyJSON.replace(/: \s*/g, " = ");
                    let formattedKotlinToolbarJSON = kotlinToolbarJSON.replace(/: \s*/g, " = ");
                    codeExport =
                        `val voiceAssistant = AssistantDrawerUI.newInstance(\n AssistantSettingsProps(${formattedKotlinAssistantSettingsJSON.replace(`https\s=\s`, "https:")} ),\n HeaderProps( ${formattedKotlinHeaderJSON.replace(`https\s=\s`, "https:")}),\n BodyProps( ${formattedKotlinBodyJSON.replace(`https\s=\s`, "https:")}),\n ToolbarProps( ${formattedKotlinToolbarJSON.replace(`https\s=\s`, "https:")} )\n)`
                    break;
            }
        } else {
            switch (codeExportLanguage) {
                case "JavaScript":
                    codeExport =
                        `<script src='https://sdk.voicify.com/browser-sdk/${customAssistantSDKVersion}/voicifyAssistant.js'></script>\n` +
                        "<script>\ndocument.addEventListener('DOMContentLoaded', (event) => {\nwindow.initializeVoicifyAssistant(" +
                        dynamicBrowserSettingsString.replace(/"([^"]+)":/g, '$1:') +
                        "\n)});</script>\n";
                    break;
                case "Swift":
                    codeExport =
                        `AssistantDrawerUI(\n  assistantSettings:\n   AssistantSettingsProps(\n    configurationId: "${configurationId}", \n    serverRootUrl: "${getAssistantPreviewApiUrl()}", \n    appId: "${appId}", \n    appKey: "${appKey}"\n   )\n)`
                    break;
                case "Kotlin":
                    codeExport =
                        `val voiceAssistant = AssistantDrawerUI.newInstance(\n  AssistantSettingsProps(\n   configurationId = "${configurationId}", \n   serverRootUrl = "${getAssistantPreviewApiUrl()}", \n   appId = "${appId}", \n   appKey = "${appKey}"\n  )\n)`
                    break;
            }
        }
        setAssistantSettingsExport(codeExport);
        setLoading(false);
    }

    const mobileFormattedJSON = (json: any): string => {
        return JSON.stringify(json, null, 2)?.replace(/"([^"]+)":/g, '$1:')?.replace("{", "")?.replace("}", "");
    }

    return {
        updateAssistantSettings,
        browserAssistantSettings,
        kotlinAssistantSettings,
        swiftAssistantSettings,
        generateAssistantSettingsExport,
        assistantSettingsExport,
        loading
    }
}

const CustomAssistantConfigurationsContainer = createContainer(useCustomAssistantConfigurationSettings);

export default CustomAssistantConfigurationsContainer;
