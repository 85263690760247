import React from 'react';
import CustomScrollbars from './CustomScrollbars';

interface TabPanelProps {
    className?: string
    autoHeight?: boolean
    autoHeightMax?: number | string
}

// note: .scrollable-panel is used for auto scroll, not css. Don't use it to override styles
const ScrollablePanelBody: React.FC<TabPanelProps> = (props) => (
    <CustomScrollbars autoHide autoHeight={props.autoHeight} autoHeightMax={props.autoHeightMax} className="scrollable-panel">
        <div className={props.className}>
            {props.children}
        </div>
    </CustomScrollbars>
);

export default ScrollablePanelBody;