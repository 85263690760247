import React from 'react';
import { css } from '@emotion/css';
import MediaContainer from '../../../state/containers/MediaContainer';
import MediaItemModel from '../../../models/media/MediaItemModel';
import Dropzone from 'react-dropzone';
import Button from '../../general/Button';
import ProgressBar from '../../general/ProgressBar';
import { silver_two } from '../../../constants/colors';
import PageError from '../../general/PageError';

interface AudioUploaderProps {
    stateContainer: MediaContainer
    onFinish: (mediaItem: MediaItemModel) => void
    applicationId: string
}

interface AudioUploaderState {
    progress: number
}

const dropzoneStyle = {
    height: 'auto',
    width: `auto`,
    borderRadius: 20,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
};
const containerStyle = css`
    display: flex;
    align-items: center;
`

const loaderWrapperStyle = css`
    flex: 1;
`
class AudioUploader extends React.Component<AudioUploaderProps, AudioUploaderState> {
    dropZone: Dropzone;

    componentWillMount() {
        this.setState({
            progress: 0
        })
    }
    handleProgressUpdate(progress: number) {
        this.setState({
            ...this.state,
            progress: progress
        });
    }
    handleFilePicked(files: File[]) {
        (files);

        if (files.length > 0) {
            this.handleUpload(files[0]);
        }
    }

    handleUpload(file: File) {
        var promise = this.props.stateContainer.uploadSsmlItem(this.props.applicationId, file.name, file, null, this.handleProgressUpdate.bind(this));
        promise.then(result => {
            this.handleProgressUpdate(0);
            if (result.resultType == "Ok") {
                ("File uploaded");
                (result.data);
                this.props.onFinish(result.data)
            }
        }).catch(error => {
            this.handleProgressUpdate(0);
        });
    }

    render() {
        return (
            <div className={containerStyle}>
                <Dropzone ref={(node) => { this.dropZone = node }}
                    onDrop={this.handleFilePicked.bind(this)}
                    style={dropzoneStyle}
                    accept={'audio/*'}
                    multiple={false}>
                    <Button themes={['white', 'white-small', 'start-tight']} text="Select & Upload" type="button" />
                </Dropzone>

                <div className={loaderWrapperStyle}>
                    <ProgressBar percent={this.state.progress} />
                </div>

                <PageError errors={this.props.stateContainer.state.errors} />
            </div>
        )
    }
}

export default AudioUploader