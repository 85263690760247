import React from "react";
import styled from "@emotion/styled";
import { css } from '@emotion/css';
import ReactMarkdown from 'react-markdown';
import { color_variants_shadow_10 } from "../../../constants/colors";
import UserAvatar from "../../../components/general/UserAvatar";
import { style_border_default } from "../../../constants/stylesValues";

const botAvatar = require("../../../content/images/support-assistant/bot-avatar.svg");

interface ConversationHistoryItemProps {
    conversationHistoryItem: ConversationTurn;
    userImage: string;
    userFirstName: string;
    userLastName: string;
}

interface ConversationTurn {
    bot: string,
    user: string,
}

const ConversationHistoryItem: React.FC<ConversationHistoryItemProps> = ({ conversationHistoryItem, userImage, userFirstName, userLastName }) => {

    return (
        conversationHistoryItem.user ?
            <UserConversationItemStyleContainer>
                <UserAvatar src={userImage} firstName={userFirstName} lastName={userLastName} size={24} className={userAvatarStyle}/>
                <UserConversationItem>{conversationHistoryItem.user}</UserConversationItem>
            </UserConversationItemStyleContainer>
            :
            <BotConversationItemStyleContainer>
                <BotConversationItem><ReactMarkdown children={conversationHistoryItem.bot} linkTarget={"_blank"} /></BotConversationItem>
                <BotAvatar src={botAvatar} />
            </BotConversationItemStyleContainer>
    )
};

const BotConversationItemStyleContainer = styled.div`
    margin-right: 8px;
    display: flex;
    justify-content: flex-end;
    :first-child {
        margin-top: 16px;
    }
`

const BotConversationItem = styled.div`
    background: white;
    border: ${style_border_default};
    width: fit-content;
    margin: 8px 8px 16px 16px;
    padding: 1rem 2rem;
    box-shadow: 0px 2px 4px ${color_variants_shadow_10};
    border-radius: 12px 0px 12px 12px;
    word-break:break-word;
`

const BotAvatar = styled.img`
    border: ${style_border_default};
    background: white;
    height: 24px;
    width: 24px;
    padding: 1px;
    margin: 16px 8px 0 0;
    border-radius: 50px;
`

const UserConversationItemStyleContainer = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
`

const UserConversationItem = styled.div`
    background: white;
    border: ${style_border_default};
    width: fit-content;
    margin: 0 16px 16px 8px;
    padding: 1rem 2rem;
    border-radius: 12px;
    box-shadow: 0px 2px 4px ${color_variants_shadow_10};
    border-radius: 0px 12px 12px 12px;
`

const userAvatarStyle = css`
    border: ${style_border_default};
`

export default ConversationHistoryItem;