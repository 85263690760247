import ApplicationFeatureModel from "../features/api/ApplicationFeatureModel";
import { QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, SimpleChoiceFeatureTypeId, NumberRangeFeatureTypeId, ExitMessagesFeatureTypeId, HelpMessagesFeatureTypeId, FallbackFeatureTypeId, WelcomeMessagesFeatureTypeId, CustomRequestsFeatureTypeId } from "../../constants/featureTypeIds";
import FeatureModel from "../features/api/FeatureModel";
const faqIcon = require('../../content/images/qa.svg');
const latestMessagesIcon = require('../../content/images/messages.svg');
const simpleChoiceIcon = require('../../content/images/simple.svg');
const rangeIcon = require('../../content/images/range.svg');
const helpIcon = require('../../content/images/help_icon.svg');
const fallbackIcon = require('../../content/images/fallback.svg');
const welcomeIcon = require('../../content/images/rocket.svg')
const exitIcon = require('../../content/images/exit.svg');
const faqIconBlack = require('../../content/images/qa-black.svg');
const latestMessagesIconBlack = require('../../content/images/messages-black.svg');
const simpleChoiceIconBlack = require('../../content/images/simple-black.svg');
const rangeIconBlack = require('../../content/images/range-black.svg');
const helpIconBlack = require('../../content/images/help-black.svg');
const fallbackIconBlack = require('../../content/images/fallback-black.svg');
const welcomeIconBlack = require('../../content/images/welcome-black.svg')
const exitIconBlack = require('../../content/images/exit-black.svg');
const customRequestIcon = require('../../content/images/custom-request.svg');
const customRequestIconBlack = require('../../content/images/custom-request-black.svg');


const questionAnswerSmall = require('../../content/images/interaction-types/small-black/question-answer.svg');
const customSmall = require('../../content/images/interaction-types/small-black/custom.svg');
const exitSmall = require('../../content/images/interaction-types/small-black/exit.svg');
const fallbackSmall = require('../../content/images/interaction-types/small-black/fallback.svg');
const helpSmall = require('../../content/images/interaction-types/small-black/help.svg');
const latestSmall = require('../../content/images/interaction-types/small-black/latest-message.svg');
const navigationSmall = require('../../content/images/interaction-types/small-black/navigation.svg');
const numberSmall = require('../../content/images/interaction-types/small-black/number-range.svg')
const welcomeSmall = require('../../content/images/interaction-types/small-black/welcome.svg');
const simpleChoiceSmall = require('../../content/images/interaction-types/small-black/simple-choice.svg');

const questionAnswerSmallLight = require('../../content/images/interaction-types/small-light/question-answer.svg');
const customSmallLight = require('../../content/images/interaction-types/small-light/custom.svg');
const exitSmallLight = require('../../content/images/interaction-types/small-light/exit.svg');
const fallbackSmallLight = require('../../content/images/interaction-types/small-light/fallback.svg');
const helpSmallLight = require('../../content/images/interaction-types/small-light/help.svg');
const latestSmallLight = require('../../content/images/interaction-types/small-light/latest-message.svg');
const navigationSmallLight = require('../../content/images/interaction-types/small-light/navigation.svg');
const numberSmallLight = require('../../content/images/interaction-types/small-light/number-range.svg')
const welcomeSmallLight = require('../../content/images/interaction-types/small-light/welcome.svg');
const simpleChoiceSmallLight = require('../../content/images/interaction-types/small-light/simple-choice.svg');

export function getFeatureCreateUrl(applicationFeature: ApplicationFeatureModel): string {
    return getFeatureTypeCreateUrl(applicationFeature.feature.featureType.id, applicationFeature.id);

}
export function getFeatureTypeCreateUrl(featureTypeId: string, applicationFeatureId: string): string {
    switch (featureTypeId) {
        case WelcomeMessagesFeatureTypeId: return `/v/${applicationFeatureId}/new/welcomemessage/`;
        case QuestionAnswerFeatureTypeId: return `/v/${applicationFeatureId}/new/faq/`;
        case ExitMessagesFeatureTypeId: return `/v/${applicationFeatureId}/new/exitmessage/`;
        case LatestMessagesFeatureTypeId: return `/v/${applicationFeatureId}/new/latestmessage/`;
        case HelpMessagesFeatureTypeId: return `/v/${applicationFeatureId}/new/helpmessage/`;
        case FallbackFeatureTypeId: return `/v/${applicationFeatureId}/new/fallbackmessage/`;
        case NumberRangeFeatureTypeId: return `/v/${applicationFeatureId}/new/numberrange/`;
        case SimpleChoiceFeatureTypeId: return `/v/${applicationFeatureId}/new/simplechoice/`;
        case CustomRequestsFeatureTypeId: return `/v/${applicationFeatureId}/new/customRequest/`;
    }
}

export function getFeatureUrl(applicationFeature: ApplicationFeatureModel): string {
    return `/v/apps/${applicationFeature.applicationId}/content/${applicationFeature.id}`;
    // return getFeatureIdUrl(applicationFeature.feature.featureType.id, applicationFeature.id);
}
export function getFeatureContentUrl(applicationId: string, applicationFeatureId: string) {
    return `/v/apps/${applicationId}/content/${applicationFeatureId}`;
}
export function getFeatureIdUrl(featureTypeId: string, applicationFeatureId: string) {
    switch (featureTypeId) {
        case WelcomeMessagesFeatureTypeId: return `/v/${applicationFeatureId}/welcomemessages/`;
        case QuestionAnswerFeatureTypeId: return `/v/${applicationFeatureId}/faqs/`;
        case ExitMessagesFeatureTypeId: return `/v/${applicationFeatureId}/exitmessages/`;
        case LatestMessagesFeatureTypeId: return `/v/${applicationFeatureId}/latestmessages/`;
        case HelpMessagesFeatureTypeId: return `/v/${applicationFeatureId}/helpmessages/`;
        case FallbackFeatureTypeId: return `/v/${applicationFeatureId}/fallbackmessages/`;
        case NumberRangeFeatureTypeId: return `/v/${applicationFeatureId}/numberranges/`;
        case SimpleChoiceFeatureTypeId: return `/v/${applicationFeatureId}/simplechoices/`;
        case CustomRequestsFeatureTypeId: return `/v/${applicationFeatureId}/customrequests/`;
    }

}
export function getEditUrl(contentId: string, applicationFeatureId: string, featureTypeId: string): string {
    switch (featureTypeId) {
        case WelcomeMessagesFeatureTypeId: return `/v/${applicationFeatureId}/welcomemessages/${contentId}/`;
        case QuestionAnswerFeatureTypeId: return `/v/${applicationFeatureId}/faqs/${contentId}/`;
        case ExitMessagesFeatureTypeId: return `/v/${applicationFeatureId}/exitmessages/${contentId}/`;
        case LatestMessagesFeatureTypeId: return `/v/${applicationFeatureId}/latestmessages/${contentId}/`;
        case HelpMessagesFeatureTypeId: return `/v/${applicationFeatureId}/helpmessages/${contentId}/`;
        case FallbackFeatureTypeId: return `/v/${applicationFeatureId}/fallbackmessages/${contentId}/`;
        case NumberRangeFeatureTypeId: return `/v/${applicationFeatureId}/numberranges/${contentId}/`;
        case SimpleChoiceFeatureTypeId: return `/v/${applicationFeatureId}/simplechoices/${contentId}/`;
        case CustomRequestsFeatureTypeId: return `/v/${applicationFeatureId}/customrequests/${contentId}/`;
    }
}
export const getDefaultFeatureTypeName: (featureTypeId: string) => string = (featureTypeId) => {
    switch (featureTypeId) {
        case WelcomeMessagesFeatureTypeId: return 'Welcome Message';
        case QuestionAnswerFeatureTypeId: return 'Question and Answer';
        case ExitMessagesFeatureTypeId: return 'Exit Message';
        case LatestMessagesFeatureTypeId: return 'Latest Message';
        case HelpMessagesFeatureTypeId: return 'Help Message';
        case FallbackFeatureTypeId: return 'Fallback Message';
        case NumberRangeFeatureTypeId: return 'Number / Range';
        case SimpleChoiceFeatureTypeId: return 'Simple Choice';
        case CustomRequestsFeatureTypeId: return 'Custom Request';
    }
}
export const getIcon: (appFeature: ApplicationFeatureModel) => string = (appFeature) => {
    return getFeatureIcon(appFeature.feature);
}
export const getFeatureIcon: (feature: FeatureModel) => string = (feature) => {
    return getFeatureTypeIcon(feature.featureType.id);
}
export const getFeatureTypeIcon: (featureTypeId: string, blackVersion?: boolean) => string = (featureTypeId, blackVersion) => {
    if (blackVersion == null || blackVersion === false) {
        switch (featureTypeId) {
            case QuestionAnswerFeatureTypeId: return faqIcon;
            case LatestMessagesFeatureTypeId: return latestMessagesIcon;
            case SimpleChoiceFeatureTypeId: return simpleChoiceIcon;
            case NumberRangeFeatureTypeId: return rangeIcon;
            case HelpMessagesFeatureTypeId: return helpIcon;
            case FallbackFeatureTypeId: return fallbackIcon;
            case WelcomeMessagesFeatureTypeId: return welcomeIcon;
            case ExitMessagesFeatureTypeId: return exitIcon;
            case CustomRequestsFeatureTypeId: return customRequestIcon;
        }
    }
    else {
        switch (featureTypeId) {
            case QuestionAnswerFeatureTypeId: return faqIconBlack;
            case LatestMessagesFeatureTypeId: return latestMessagesIconBlack;
            case SimpleChoiceFeatureTypeId: return simpleChoiceIconBlack;
            case NumberRangeFeatureTypeId: return rangeIconBlack;
            case HelpMessagesFeatureTypeId: return helpIconBlack;
            case FallbackFeatureTypeId: return fallbackIconBlack;
            case WelcomeMessagesFeatureTypeId: return welcomeIconBlack;
            case ExitMessagesFeatureTypeId: return exitIconBlack;
            case CustomRequestsFeatureTypeId: return customRequestIconBlack;
        }
    }
    return '';
}

export const getSmallFeatureTypeIcon: (featureTypeId: string) => string = (featureTypeId) => {
    switch (featureTypeId) {
        case QuestionAnswerFeatureTypeId: return questionAnswerSmall;
        case LatestMessagesFeatureTypeId: return latestSmall;
        case SimpleChoiceFeatureTypeId: return simpleChoiceSmall;
        case NumberRangeFeatureTypeId: return numberSmall;
        case HelpMessagesFeatureTypeId: return helpSmall;
        case FallbackFeatureTypeId: return fallbackSmall;
        case WelcomeMessagesFeatureTypeId: return welcomeSmall;
        case ExitMessagesFeatureTypeId: return exitSmall;
        case CustomRequestsFeatureTypeId: return customSmall;
    }
    return '';
}

export const getSmallLightFeatureTypeIcon: (featureTypeId: string) => string = (featureTypeId) => {
    switch (featureTypeId) {
        case QuestionAnswerFeatureTypeId: return questionAnswerSmallLight;
        case LatestMessagesFeatureTypeId: return latestSmallLight;
        case SimpleChoiceFeatureTypeId: return simpleChoiceSmallLight;
        case NumberRangeFeatureTypeId: return numberSmallLight;
        case HelpMessagesFeatureTypeId: return helpSmallLight;
        case FallbackFeatureTypeId: return fallbackSmallLight;
        case WelcomeMessagesFeatureTypeId: return welcomeSmallLight;
        case ExitMessagesFeatureTypeId: return exitSmallLight;
        case CustomRequestsFeatureTypeId: return customSmallLight;
    }
    return '';
}