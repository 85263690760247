import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";
import AlexaSkillResult from "../../models/deployments/api/AlexaSkillResult";
import SkillCertificationSummary from "../../models/deployments/api/SkillCertificationSummary";
import AlexaVendorResponse from "../../models/deployments/api/AlexaVendorResponse";
import NewSkillResponse from "../../models/deployments/api/NewSkillResponse";
import DeploymentLink from "../../models/applications/api/DeploymentLink";
import ApplicationLinksByPlatform from "../../models/applications/api/ApplicationLinksByPlatform";
import { PLATFORM_ALEXA_SKILL } from "../../constants/Urls";

export const getApplicationsLinkedToAmazon = (organizationId: string): Promise<IResult<ApplicationLinksByPlatform[]>> => {
    return sendGet(`${PLATFORM_ALEXA_SKILL}/organizationLinksToAmazon/${organizationId}`);
}

export const listSkills = (vendorId: string, tokenId: string): Promise<IResult<AlexaSkillResult[]>> => {
    return sendGet(`${PLATFORM_ALEXA_SKILL}/Skills/${vendorId}/${tokenId}`)
}

export const getAlexaSkillCertifications = (applicationId: string, environmentId: string, skillId: string): Promise<IResult<SkillCertificationSummary[]>> => {
    return sendGet(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/${skillId}/Certifications`);
}

export const getEnvironmentDeploymentInfoForAmazon = (applicationId: string, environmentId: string): Promise<IResult<DeploymentLink>> => {
    return sendGet(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/link`)
}

export const unlinkAlexaSkillFromEnvironment = (applicationId: string, environmentId: string): Promise<IResult<boolean>> => {
    return sendDelete(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}`)
}

export const removeAmazonAuthTokenFromEnvironment = (applicationId: string, environmentId: string): Promise<IResult<boolean>> => {
    return sendDelete(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/authToken`)
}

export const removeAlexaVendorFromEnvironment = (applicationId: string, environmentId: string): Promise<IResult<boolean>> => {
    return sendDelete(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/vendor`)
}

export const getEnvironmentAlexaVendors = (applicationId: string, environmentId: string): Promise<IResult<AlexaVendorResponse>> => {
    return sendGet(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/vendors`)
}

export const linkAmazonTokenToEnvironment = (applicationId: string, environmentId: string, amazonAuthToken: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/authToken/${amazonAuthToken}`, {});
}

export const linkEnvironmentAlexaVendor = (applicationId: string, environmentId: string, vendorId: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/vendor/${vendorId}`, {});
}

export const createAndDeployEnvironmentNewAlexaSkill = (applicationId: string, environmentId: string): Promise<IResult<NewSkillResponse>> => {
    return sendPost(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/createAndDeploy`, {})
}

export const linkEnvironmentToAlexaSkill = (applicationId: string, environmentId: string, alexaSkillId: string): Promise<IResult<boolean>> => {
    return sendPut(`${PLATFORM_ALEXA_SKILL}/${applicationId}/environment/${environmentId}/link/${alexaSkillId}`, {});
}
