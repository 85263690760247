import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, color_variants_ocean_light_opaque, color_shades_darker, color_colors_ocean, color_text_link } from '../../../constants/colors';
import VoicifyNotification, { VoicifyNotificationType } from '../../../models/notifications/VoicifyNotification';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
const productUpdateIcon = require('../../../content/images/account-settings/product-update.svg');
const alertIcon = require('../../../content/images/alert-triangle.svg');
const infoIcon = require('../../../content/images/info_icon.svg');


interface NotificationsListItemProps {
    notification: VoicifyNotification,
    onTouch: () => any
}

interface NotificationsListItemState {
    isExpanded: boolean
}

class NotificationsListItem extends React.Component<NotificationsListItemProps, NotificationsListItemState> {
    componentWillMount() {
        this.setState({
            isExpanded: false
        });
    }
    getNotificationTypeImage(type: VoicifyNotificationType) {
        switch (type) {
            case ("ProductUpdate"):
                return productUpdateIcon;
            case ("Issue"):
                return alertIcon;
            case ("Information"):
            default:
                return infoIcon;
        }
    }
    getNotificationTypeText(type: VoicifyNotificationType) {
        switch (type) {
            case ("ProductUpdate"):
                return "PRODUCT UPDATE";
            case ("Issue"):
                return "ISSUE";
            case ("Information"):
            default:
                return "INFORMATION";
        }
    }
    renderStartDate(startDate: string) {
        const date = new Date(startDate);
        const today = new Date();
        if (date.toDateString() == today.toDateString()) {
            return `Today, ${moment(date).format('h:mm A')}`;
        }
        return `${moment(date).format('MMM Do, YYYY h:mm A')}`;
    }
    toggleExpand() {
        this.setState({
            ...this.state,
            isExpanded: !this.state.isExpanded
        });
    }
    render() {
        return (
            <div onClick={() => this.props.onTouch()} className={`${containerStyle} ${this.props.notification.isRead ? `read` : `unread`}`}>
                <ToggleIndicator onClick={() => this.toggleExpand()} direction={this.state.isExpanded ? 'down' : 'right'} className={expandStyle} type='triangle' />
                <div onClick={() => this.toggleExpand()} className={typeIconStyle}>
                    <img src={this.getNotificationTypeImage(this.props.notification.notificationType)} />
                </div>
                <div>
                    <div className="notificationType">{`${this.getNotificationTypeText(this.props.notification.notificationType)} - ${this.renderStartDate(this.props.notification.startDate)}`}</div>
                    <div className="title">{this.props.notification.title}</div>
                    <div className={`${markdown}${this.state.isExpanded ? "" : " collapsed"}`}>
                        <ReactMarkdown
                            source={this.props.notification.markdown}
                            renderers={{ link: (props) => { return <a href={props.href} target="_blank">{props.children}</a> } }} />
                    </div>
                    {
                        !this.state.isExpanded &&
                        <div className={`${fadeOutStyle} ${this.props.notification.isRead ? "read" : "unread"}`} />
                    }
                    <div onClick={() => this.toggleExpand()} className="readMore">
                        <ToggleIndicator className={chevronStyle} direction={this.state.isExpanded ? "up" : "down"} />
                        {this.state.isExpanded ? "Collapse" : "Read More"}
                    </div>
                </div>
            </div>
        )
    }
}

const containerStyle = css`    
    border-bottom: 1px solid ${color_shades_dark};
    padding: 24px 32px;
    padding-bottom: 16px;
    position: relative;
    padding-left: 18px;
    display: flex;
    flex-direction: row;
    &.read {
        background-color: white;
    }
    &.unread{
        background-color: ${color_variants_ocean_light_opaque}
    }
    .title {
        font-size: 16px;
        line-height: 24px;
        z-index: 4;
        margin-bottom: 8px;
    }
    .subtitle {
        z-index: 4;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    .notificationType {
        font-size: 12px;
        line-height: 16px;
        color: ${color_shades_darker};
        margin-bottom: 8px;
    }
    .readMore {        
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        color: ${color_colors_ocean};
    }
`

const chevronStyle = css`        
    border-left: solid 1px ${color_colors_ocean};
    border-top: solid 1px ${color_colors_ocean};
    margin-top: 6px;
    margin-right: 6px;
`

const fadeOutStyle = css`
    height: 24px;
    left: -68px;
    right: -32px; 
    bottom: 50px;
    position: absolute;
    &.read {
        background: linear-gradient(0deg, #FFFFFF 33.33%, rgba(255, 255, 255, 0) 100%);
    }
    &.unread {
        background: linear-gradient(0deg, #EDF5FA 33.33%, rgba(237, 245, 250, 0.992188) 33.34%, rgba(237, 245, 250, 0) 100%);
    }
`

const typeIconStyle = css`
    margin-right: 12px;
    cursor: pointer;
`

const expandStyle = css`
    margin-top: 9px;
    margin-right: 10px;
    cursor: pointer;
`

const markdown = css`
    z-index: 3;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    font-size: 14px;
    width: 476px;
    line-height: 20px;
    word-wrap: break-word;
    min-height: 90px;
    &.collapsed {
        height: 60px;
        min-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p {        
        font-size: 14px;
        line-height: 20px;
        margin: 8px 0;
    }
    ul, ol {
        padding-left: 24px;
        margin: 12px 0;
        li {
            margin: 4px;
        }
    }
    h1 {
        margin-bottom: 16px;
        margin-top: 16px;  
        font-size: 24px;
        font-weight: bold;
    }
    h2 {
        margin-bottom: 16px;
        margin-top: 16px;  
        font-size: 20px;
        font-weight: bold;
    }
    h3 {
        margin-bottom: 16px;
        margin-top: 16px;        
        font-size: 18px;
        font-weight: bold;
    }
    h4 {
        margin-bottom: 16px;
        margin-top: 16px;  
        font-size: 16px;
        font-weight: bold;
    }
    h5 {
        margin-bottom: 16px;
        margin-top: 16px;  
        font-size: 14px;
        font-weight: bold italic;
    }
    h6 {
        margin-bottom: 16px;
        margin-top: 16px;  
        font-size: 14px;
        font-weight: bold;
    }
    img {
        max-width: calc(100% - 32px);
        margin: 0 16px;
    }
    a {
        color: ${color_text_link};
    }
    
`

export default NotificationsListItem;