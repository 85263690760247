import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import OrganizationModel from '../../../models/organizations/api/OrganizationModel';
import { dark_grey_blue, ocean_blue, color_shades_lighter } from '../../../constants/colors';
import { Link } from 'react-router-dom';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import UserContainer from '../../../state/containers/UserContainer';
import styled from '@emotion/styled';
import { AIAnsweringFeatureFlagId, FoodOrderingFeatureFlagId } from '../../../constants/featureFlagIds';
import OrgSetupRequest from '../../../models/orgSetup/OrgSetupRequest';
import OrgSetupContainer from '../../../hooks/OrgSetupContainer';
import Button from '../../../components/general/Button';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import SparkContainer from '../../../hooks/SparkContainer';

const addOrgIcon = require('../../../content/images/add-org/add-org.svg');
const organizationIcon = require('../../../content/images/account-settings/organization-dark.svg');

interface OrganizationsMenuItemProps {
    currentOrganization: OrganizationModel
    organizations: OrganizationModel[]
    onOrganizationSelected: (org: OrganizationModel) => void
    userContainer: UserContainer
    organizationContainer: OrganizationContainer
}

const OrganizationsMenuItem: React.FC<OrganizationsMenuItemProps> = (props) => {

    const [addingAnOrganization, setAddingAnOrganization] = useState(false);

    const orgSetupContainer = OrgSetupContainer.useContainer();
    const sparkContainer = SparkContainer.useContainer();

    const newOrgListItem = useRef(null);
    const orgNameTextInput = useRef(null);

    useEffect(() => {
        orgNameTextInput.current?.focus();
    }, [addingAnOrganization]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (newOrgListItem.current && !newOrgListItem.current.contains(event.target)) {
                setAddingAnOrganization(false);
            }
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [newOrgListItem]);


    const handleOrgNameSubmitNameKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        e.stopPropagation();
        if (e.key === "Enter") {
            handleOrgNameSubmit(e);
        }
        else if (e.key === "Escape") {
            setAddingAnOrganization(false);
        }
    };

    const handleOrgNameSubmit = async (e: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        const orgName = orgNameTextInput.current?.value;
        if (orgName) {
            let newOrganizationRequest: OrgSetupRequest;
            if (sparkContainer.inSpark) {
                newOrganizationRequest = {
                    organizationName: orgName,
                    featureFlagId: AIAnsweringFeatureFlagId
                }
            } else {
                newOrganizationRequest = {
                    organizationName: orgName,
                    featureFlagId: FoodOrderingFeatureFlagId
                }
            }
            const setupOrgResponse = await orgSetupContainer.setupOrg(newOrganizationRequest);
            if (setupOrgResponse.resultType === "Ok") {
                const newOrg: OrganizationModel = {
                    id: setupOrgResponse.data.id,
                    secret: setupOrgResponse.data.secret,
                    name: setupOrgResponse.data.name,
                    description: setupOrgResponse.data.description,
                    instructionsMarkdown: setupOrgResponse.data.instructionsMarkdown ?? ""
                }
                props.onOrganizationSelected(newOrg)
            }
            setAddingAnOrganization(false);
        }
    };

    const renderHorizontalSeparator = (index: number) => {
        if (index !== props.organizations.length - 1) {
            return <HorizontalSeparator />
        } else {
            if (props.userContainer.state.currentUser.canCreateOrganizations) {
                return <HorizontalSeparator />
            }
        }
    };

    const handleNewOrgSetupClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setAddingAnOrganization(true);
    };

    return (
        <div className={containerStyle}>
            <div className={listContainerStyle}>
                <div className={listInnerContainerStyle}>
                    {props.organizations.length > 0 ? props.organizations.map((org, index) =>
                        <div key={org.id}>
                            <Link to="/v" className={`${listItemStyle} ${org.id == props.currentOrganization.id ? currentOrgStyle : ''}`} onClick={() => props.onOrganizationSelected(org)}>
                                <p>{org.name}</p>
                            </Link>
                            {renderHorizontalSeparator(index)}
                        </div>
                    ) : <p className={emptyLabelStyle}>This is where you'll see other organizations you're a member of.</p>}
                    {props.userContainer.state.currentUser.canCreateOrganizations ?
                        (
                            addingAnOrganization ?
                                <div className={listItemStyle} ref={newOrgListItem}>
                                    <NameInput type="text"
                                        placeholder="Enter the new organization's name here"
                                        ref={orgNameTextInput}
                                        onClick={(e) => e.stopPropagation()}
                                        onKeyUp={(e) => handleOrgNameSubmitNameKeyUp(e)}
                                    />
                                    <StyledButton
                                        disabled={(orgNameTextInput.current?.value === "") || orgSetupContainer.isLoading}
                                        loading={orgSetupContainer.isLoading}
                                        themes={["primary-small"]}
                                        text={"Create"}
                                        type="button"
                                        onClick={(e) => handleOrgNameSubmit(e)}
                                    />
                                </div>
                                :
                                <Link to="/v" className={addOrgListItemStyle} onClick={(e) => handleNewOrgSetupClick(e)}>
                                    <p>Create a New Organization</p>
                                    <AddOrgIcon src={addOrgIcon} alt="Add Organization" />
                                    <OrgIcon src={organizationIcon} alt="Organization" />
                                </Link>
                        )
                        : null
                    }
                </div>
            </div>
        </div>
    )
};

const StyledButton = styled(Button)`
    height: 28px;
    width: 28px;
    border-radius: 18px;
    font-size: 10px;
`;

const AddOrgIcon = styled.img`
    margin-right: 8px;
`;

const OrgIcon = styled.img`
    margin-right: 22px;
`;

const NameInput = styled.input`
    border: none;
    height: 36px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: ${dark_grey_blue};
    flex: 1;
    margin-right: 0px;
`;

const listContainerStyle = css`
    padding: 32px;
    background: ${color_shades_lighter};
`;

const listInnerContainerStyle = css`
    border-radius: 8px;
    background-color: white;
    border: solid 1px #cccccc;
`;

const addOrgListItemStyle = css`
    height: 48px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: ${dark_grey_blue};
    display: flex;
    align-items: center;
    p {
        margin-right: 8px;
        margin-left: auto;
    }
    &:hover {
        color: ${ocean_blue};
    }
`;

const listItemStyle = css`
    height: 48px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: ${dark_grey_blue};
    display: flex;
    align-items: center;
    p {
        margin-right: 22px;
        margin-left: auto;
    }
    p:hover {
        color: ${ocean_blue};
    }
`;

const emptyLabelStyle = css`
    height: 48px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${dark_grey_blue};
    display: flex;
    align-items: center;
    padding: 8px;
`

const currentOrgStyle = css`
    color: ${ocean_blue};
`;

const containerStyle = css`
    overflow: hidden;
    border-radius: 0 0 20px 20px;
    margin-bottom: 124px;
`;

export default OrganizationsMenuItem;