import React, { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import InteractionModelStatus from "../models/interactionModel/api/InteractionModelStatus";
import IResult from "../models/result/IResult";
import * as voicifyApi from '../api';

function useDeploymentsContainer() {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [status, setStatus] = useState(null as InteractionModelStatus);
    const [waitingForAlexa, setWaitingForAlexa] = useState(false);
    const [waitingForDialogflow, setWaitingForDialogflow] = useState(false);
    const [pollingForDeploymentStatus, setPollingForDeploymentStatus] = useState(false);
    const [pollingApplicationId, setPollingApplicationId] = useState("");
    const [pollingEnvironmentId, setPollingEnvironmentId] = useState("");
    const [currentApplicationId, setCurrentApplicationId] = useState("");
    const [pollingTrigger, setPollingTrigger] = useState(0);

    const waitForEnvironmentDeployment = async (applicationId: string, environmentId: string, alexa: boolean, dialogflow: boolean, googleActions: boolean) => {
        await setPollingEnvironment(applicationId, environmentId);
        setWaitingForDialogflow(dialogflow);
        setWaitingForAlexa(alexa);
        if (alexa || dialogflow || googleActions)
            pollForEnvironmentDeploymentStatus();
    }

    const stopWaitingForDialogflowDeployment = async () => {
        setWaitingForDialogflow(false);
    }

    const stopWaitingForAlexaDeployment = async () => {
        setWaitingForAlexa(false);
    }

    useEffect(() => {
        setPollingForDeploymentStatus(( waitingForAlexa || waitingForDialogflow));
    }, [waitingForDialogflow, waitingForAlexa])

    const setPollingEnvironment = async (applicationId: string, environmentId: string) => {
        if (pollingApplicationId != applicationId || pollingEnvironmentId != environmentId) {
            setCurrentApplicationId(applicationId);
            setPollingApplicationId(applicationId);
            setPollingEnvironmentId(environmentId);
            setPollingForDeploymentStatus(false);
            setWaitingForAlexa(false);
            setWaitingForDialogflow(false);
        }
    };

    const isPlatformDeploymentComplete = (platform: string, status: InteractionModelStatus) => {
        let isDeploymentComplete: boolean = false;

        const hasQueuedDeployments = status?.queuedForDeploymentToPlatforms?.some(q => q.platform == platform);

        if (!hasQueuedDeployments) {
            // If there are no more environment platform queued deployments - lets check what was latest deployment status
            const deploymentResult = status?.latestDeploymentResults?.find(r => r.platform == platform)

            if (!deploymentResult || deploymentResult?.error || deploymentResult?.success || deploymentResult.status === "SUCCEEDED") {
                isDeploymentComplete = true;
            }
        }

        return isDeploymentComplete;
    }

    useEffect(() => {
        if (pollingForDeploymentStatus)
            poll();
    }, [pollingTrigger])
    
    const poll = async () => {
        try {
            const interactionModelStatusResult = await voicifyApi.getInteractionModelStatusPerEnvironment(pollingApplicationId, pollingEnvironmentId);

            if (interactionModelStatusResult.resultType == "Ok") {
                const newStatus: InteractionModelStatus = interactionModelStatusResult.data;
                setStatus(newStatus);
                let shouldContinueAlexa = waitingForAlexa;
                let shouldContinueDialogflow = waitingForDialogflow;
                // if we're polling for a platform then check if that one finished
                if (waitingForAlexa && isPlatformDeploymentComplete("alexa", newStatus)) {
                    setWaitingForAlexa(false);
                    shouldContinueAlexa = false;
                }
                if (waitingForDialogflow && isPlatformDeploymentComplete("dialogflow", newStatus)) {
                    setWaitingForDialogflow(false);
                    shouldContinueDialogflow = false;
                }
                setPollingForDeploymentStatus(shouldContinueAlexa || shouldContinueDialogflow );
                if (shouldContinueAlexa || shouldContinueDialogflow) {
                    await new Promise(r => setTimeout(r, 2000));
                    setPollingTrigger(pollingTrigger + 1);
                }
            } else {
                setErrors(interactionModelStatusResult.errors);
                setWaitingForAlexa(false);
                setWaitingForDialogflow(false);
                setPollingForDeploymentStatus(false);
            }
            
        } catch (err) {
            setErrors(err);
            setWaitingForAlexa(false);
            setWaitingForDialogflow(false);
            setPollingForDeploymentStatus(false);
        }
    }

    const pollForEnvironmentDeploymentStatus = async () => {
        try {
            // if we're already waiting then get out of it
            if (pollingForDeploymentStatus) {
                return;
            }
            setPollingForDeploymentStatus(true);
            setPollingTrigger(pollingTrigger + 1);
        } catch (err) {
            setErrors(err);
            setWaitingForAlexa(false);
            setWaitingForDialogflow(false);
            setPollingForDeploymentStatus(false);
        }
    }

    const loadInteractionModelEnvironmentStatus = async (applicationId: string, environmentId: string): Promise<IResult<InteractionModelStatus>> => {
        try {
            const interactionModelStatusResult = await voicifyApi.getInteractionModelStatusPerEnvironment(applicationId, environmentId);
            if (interactionModelStatusResult.resultType == "Ok") {
                setStatus(interactionModelStatusResult.data)
            }
            else {
                setErrors(interactionModelStatusResult.errors);
            }
            return interactionModelStatusResult;
        } catch (err) {
            setErrors(err.toString());
        }
    };
    const clearErrors = () => {
        setErrors([]);
    }

    return {
        errors,
        isLoading,
        currentApplicationId,
        status,
        setErrors,
        clearErrors,
        waitForEnvironmentDeployment,
        stopWaitingForAlexaDeployment,
        stopWaitingForDialogflowDeployment,
        loadInteractionModelEnvironmentStatus,
        pollForEnvironmentDeploymentStatus,
        setPollingEnvironment
    };
}

const InteractionModelContainer = createContainer(useDeploymentsContainer);
export default InteractionModelContainer;
