import React, { useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import DeploymentAppInfo from "../models/deployments/DeploymentAppInfo";
import PlatformCredentials from "../models/deployments/PlatformCredentials";
import GoogleAuthUrlRequest from "../models/deployments/api/GoogleAuthUrlRequest";
import DialogflowAgentResult from "../models/deployments/api/DialogflowAgentListResult";
import DeploymentLink from "../models/applications/api/DeploymentLink";

export type DeploymentSelectionType = "new" | "existing";

function useDialogflowDeploymentContainer() {
    type DialogflowDeploymentStepType = "initial" | "linkingApp" | "finished";

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [accountSelection, setAccountSelection] = useState("new" as DeploymentSelectionType);
    const [availableApps, setAvailableApps] = useState([] as DeploymentAppInfo[]);
    const [availableCredentials, setAvailableCredentials] = useState([] as PlatformCredentials[]);
    const [tokenId, setTokenId] = useState(null as string);
    const [linkedProjectId, setLinkedProjectId] = useState(null as string);
    const [userName, setUserName] = useState(null as string);
    const [loadingAvailableApps, setLoadingAvailableApps] = useState(false);
    const [status, setStatus] = useState("initial" as DialogflowDeploymentStepType);
    const [currentApplicationId, setCurrentApplicationId] = useState(null as string);
    const [currentEnvironmentId, setCurrentEnvironmentId] = useState(null as string);

    const reset = () => {
        setStatus("initial");
        setAccountSelection("new");
        setLoadingAvailableApps(false);
        setAvailableApps([]);
        setAvailableCredentials([]);
        setTokenId (null);
        setLinkedProjectId (null);
        setUserName(null);
        setErrors([]);
        setCurrentApplicationId(null);
        setCurrentEnvironmentId(null);
    }

    const setSelection = async (selection: DeploymentSelectionType) => {
        if(selection === "new")
        {
            await setTokenId(null);
        }
        await setAccountSelection(selection);
    }

    const setDeployToApp = (linkedProjectId: string) => {
        setLinkedProjectId(linkedProjectId);
    }

    const setLinkingStatus = async (status: DialogflowDeploymentStepType) => {
        await setStatus(status);
    }

    const setAccountToLink = (tokenId: string, userName: string) => {
        setTokenId(tokenId);
        setUserName(userName);
    }

    const getAuthUrl = (request: GoogleAuthUrlRequest) => {
        return voicifyApi.getDialogflowAuthUrl(request)
            .catch(err => {
                setErrors([err.toString()]);
            })
    }

    const getAgents = (tokenId: string) => {
        setLoadingAvailableApps(true);

        return voicifyApi.listDialogflowAgents(tokenId)
            .then(result => {
                if (result.resultType == "Ok") {
                    setLoadingAvailableApps(false);
                    setAvailableApps(result.data.map((a: DialogflowAgentResult) =>
                        ({ id: a.parent, name: a.displayName } as DeploymentAppInfo)));
                } else {
                    setLoadingAvailableApps(false);
                    setErrors(result.errors);
                }
                return result;
            }).catch(err => {
                setLoadingAvailableApps(false);
                setErrors([err.toString()]);
            })
    }

    const getAvailableCredentials = (organizationId: string) => {
        return voicifyApi.getAvailableDialogflowCredentials(organizationId)
            .then(result => {
                if (result.resultType == "Ok") {
                    setAvailableCredentials( result.data.map(t =>
                        ({ id: t.id, userName: t.userName } as PlatformCredentials)));
                } else {
                    setErrors(result.errors);
                }
                return result;
            }).catch(err => {
                setErrors([err.toString()]);
            })
    }
    
    const loadEnvironmentDeploymentInformation = async (applicationId: string, environmentId: string, organizationId: string) => {
        try {
            if (currentApplicationId === applicationId && currentEnvironmentId === environmentId)
                return; 

            await setIsLoading(true);
            const link = (await getEnvironmentDeploymentStatus(applicationId, environmentId)) as DeploymentLink;

            if (link.linkedProjectId && link.userName) {
                await setLinkingStatus("finished");
                await setSelection("existing");
            }
            else if (link.userName) {
                await setLinkingStatus("linkingApp");
                await setSelection("existing");
            }
            
            await getAvailableCredentials(organizationId);
            if (link.tokenId) {
                await getAgents(link.tokenId);
            }
            await setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setErrors([err.toString()]);
        }
    }

    const getEnvironmentDeploymentStatus = async (applicationId: string, environmentId: string) => {
        try { 
            await setCurrentApplicationId(applicationId); 
            await setCurrentEnvironmentId(environmentId);
            // Need to clear ProjectId (DialogflowAgentId), UserName and TokenId before getEnvironmentDeploymentInfoForDialogflow call
            await setLinkedProjectId(null);
            await setUserName(null); 
            await setTokenId(null);

            const result = await voicifyApi.getEnvironmentDeploymentInfoForDialogflow(applicationId, environmentId);

            if (result.resultType === "Ok") {
                const status: DeploymentLink = result.data;
                await setLinkedProjectId(status.linkedProjectId);
                await setUserName(status.userName); 
                await setTokenId(status.tokenId);
                await setErrors([]);
                return status;
            } else {
                await setErrors(result.errors);
            }
        } catch (err) {
            setErrors([err.toString()]);
        }
    };

    const linkEnvironment = async (applicationId: string, environmentId:string, projectId: string) => {
        try {
            const response = await voicifyApi.linkEnvironmentToDialogflowAgent(applicationId, environmentId, projectId);
            if (response.resultType != "Ok") {
                await setErrors(response.errors);
            }
            else {
                await setLinkingStatus("finished");
                await setLinkedProjectId(projectId);
            }
        }catch (err) {
            setErrors([err.toString()]);
        }
    }

    const linkTokenToEnvironment = async (applicationId: string, environmentId:string, tokenId: string) => {
        return voicifyApi.linkDialogflowTokenToEnvironment(applicationId, environmentId, tokenId)
            .then(result => {
                if (result.resultType != "Ok") {
                    setErrors(result.errors);
                }
                return result;
            })
            .catch(err => {
                setErrors([err.toString()]);
            })
    }

    const unlinkEnvironmentAgent = async (applicationId: string, environmentId: string) => {
        setStatus("linkingApp");
        setLinkedProjectId(null);
        return voicifyApi.unlinkDialogflowAgentFromEnvironment(applicationId, environmentId)
            .then(result => {
                if (result.resultType != "Ok") {
                    setErrors(result.errors);
                }
            })
            .catch(err => {
                setErrors([err.toString()]);
            })
    }

    const unlinkEnvironmentUserAccount = async (applicationId: string, environmentId: string) => {
        if(linkedProjectId != null)
            unlinkEnvironmentAgent(applicationId, environmentId);

        return voicifyApi.removeDialogflowAuthTokenFromEnvironment(applicationId, environmentId)
            .then(result => {
                if (result.resultType != "Ok") {
                    setErrors(result.errors);
                }
            })
            .catch(err => {
                setErrors([err.toString()]);
            })
    }

    const clearErrors = () => {
        setErrors([]);
    }

    return {
        reset,
        setSelection,
        setDeployToApp,
        setLinkingStatus,
        setAccountToLink,
        getAuthUrl,
        getAgents, 
        getAvailableCredentials,
        loadEnvironmentDeploymentInformation,
        getEnvironmentDeploymentStatus,
        linkEnvironment, 
        linkTokenToEnvironment, 
        unlinkEnvironmentAgent, 
        unlinkEnvironmentUserAccount,
        currentEnvironmentId,
        status,
        accountSelection, 
        linkedProjectId, 
        tokenId, 
        userName, 
        availableCredentials, 
        availableApps,
        loadingAvailableApps,
        isLoading,
        errors,
        clearErrors
    };
}

const DialogflowDeploymentContainer = createContainer(useDialogflowDeploymentContainer);
export default DialogflowDeploymentContainer;