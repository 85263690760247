import axios from 'axios';
import { GET_HELPMESSAGES, ADD_HELPMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import FollowUpModel from '../../models/features/api/FollowUp/FollowUpModel';
import NewFollowUpRequest from '../../models/features/api/FollowUp/NewFollowUpRequest';
import UpdateFollowUpRequest from '../../models/features/api/FollowUp/UpdateFollowUpRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import ParentContentContainerModel from '../../models/features/api/Relationships/ParentContentContainerModel';

export const getFollowUpsForApp = (applicationId: string): Promise<IResult<FollowUpModel[]>> => {
    return sendGet<FollowUpModel[]>(`/FollowUp/${applicationId}`);
}
export const addFollowUp = (applicationId: string, model: NewFollowUpRequest): Promise<IResult<FollowUpModel>> => {
    return sendPost<FollowUpModel>(`/FollowUp/${applicationId}`, model);
}
export const updateFollowUp = (model: UpdateFollowUpRequest) : Promise<IResult<FollowUpModel>> => {
    return sendPut<FollowUpModel>(`/FollowUp`, model);
}
export const deleteFollowUp = (followUpId: string) : Promise<IResult<FollowUpModel>> => {
   return sendDelete<FollowUpModel>(`/FollowUp/${followUpId}`);
}
export const findFollowUpById = (followUpId: string): Promise<IResult<FollowUpModel>> => {
    return sendGet<FollowUpModel>(`/FollowUp/find/${followUpId}`);
}
export const getParentContentItems = (featureTypeId: string, contentId: string): Promise<IResult<ParentContentContainerModel>> => {
    return sendGet<ParentContentContainerModel>(`/FollowUp/parents/${featureTypeId}/${contentId}/content`);
}
