import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import AppliedApplicationTemplateModel from '../../models/templating/api/AppliedApplicationTemplateModel';
import TemplateFormSectionModel from '../../models/templating/api/TemplateFormSectionModel';
import UpdateAppliedApplicationTemplateFormRequest from '../../models/templating/api/UpdateAppliedApplicationTemplateFormRequest';
import FinishTemplateSectionRequest from '../../models/templating/api/FinishTemplateSectionRequest';
import DynamicFormResponse from '../../models/templating/api/DynamicFormResponse';
import _ from 'lodash';
import FinalizeTemplateFormRequest from '../../models/templating/api/FinalizeTemplateFormRequest';
import TemplateFormFieldValueModel from '../../models/templating/api/TemplateFormFieldValueModel';
import UpdateAppliedApplicationTemplateFormsRequest from '../../models/templating/api/UpdateAppliedApplicationTemplateFormsRequest';
import { AutoGenerateAppliedAppValuesRequest } from '../../models/templating/api/AutoGenerateAppliedAppValuesRequest';

export interface PerApplicationAppliedTemplate {
    applicationId: string
    appliedTemplates: AppliedApplicationTemplateModel[]
}

export type MultipleTemplateSyncsStatus = "none" | "syncing" | "success" | "error" | "templateConfigDisabled";

type AppliedApplicationTemplateState = {
    appliedTemplates: AppliedApplicationTemplateModel[]
    perApplicationAppliedTemplates: PerApplicationAppliedTemplate[]
    isLoading: boolean
    isSaving: boolean
    isWaitingForTemplateSync: boolean
    errors: string[],
    templateSyncErrors: string[],
    appliedTemplateSyncId: string,
    multipleTemplateSyncsStatus: MultipleTemplateSyncsStatus,
}

export default class AppliedApplicationTemplateContainer extends Container<AppliedApplicationTemplateState> {
    // default state
    state = {
        appliedTemplates: [] as AppliedApplicationTemplateModel[],
        perApplicationAppliedTemplates: [] as PerApplicationAppliedTemplate[],
        isLoading: false,
        isSaving: false,
        isWaitingForTemplateSync: true,
        errors: [],
        templateSyncErrors: [],
        appliedTemplateSyncId: null,
        multipleTemplateSyncsStatus: "none" as MultipleTemplateSyncsStatus
    }

    loadAppliedTemplates(applicationId: string) {
        this.setLoading(true);
        const promise = voicifyApi.getAppliedApplicationTemplatesForApp(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const appliedForms = result.data;
                appliedForms.forEach(appliedForm => {
                    // find applied forms with dynamic form urls and create the values cache. 
                    if (appliedForm.templateForm.templateConfiguration.dynamicFormUrl) {
                        const allValues = appliedForm.templateFormFieldValues;
                        if (allValues && allValues.length > 0) {
                            const dynamicValues = allValues.filter(v => v.dynamicFormFieldId || v.dynamicFormFieldName) ?? [];
                            const standardValues = allValues.filter(v => v.templateFormFieldId) ?? [];
                            appliedForm.templateFormFieldValues = standardValues;
                            appliedForm.dynamicFormFieldValuesCache = dynamicValues; // values cache will be used when we add dynamic sections
                        }
                    }
                    // apply form field info to existing values
                    appliedForm.templateForm?.templateFormSections.forEach(section => {
                        section?.templateFormFields?.forEach(field => {
                            const value = appliedForm.templateFormFieldValues.find(v => v.templateFormFieldId == field.id);
                            if (value) {
                                value.fieldType = field.fieldType;
                                value.associatedVariable = field.associatedVariable;
                                value.label = field.label;
                                value.tip = field.tip;
                                value.title = field.title;
                                value.placeholder = field.placeholder;
                                value.defaultValue = field.defaultValue;
                            }
                        });
                    })
                });
                const applicationAppliedTemplates = { applicationId: applicationId, appliedTemplates: appliedForms };
                const newPerApp = this.state.perApplicationAppliedTemplates.filter(m => m.applicationId != applicationId);
                this.setState({
                    ...this.state,
                    isLoading: false,
                    appliedTemplates: appliedForms,
                    perApplicationAppliedTemplates: [
                        ...newPerApp,
                        applicationAppliedTemplates
                    ],
                    errors: []
                })
            } else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            })
        })
        return promise;
    }

    updateAppliedTemplate(appliedTemplateId: string, model: UpdateAppliedApplicationTemplateFormRequest) {
        this.setSaving(true);
        const promise = voicifyApi.updateAppliedApplicationTemplate(appliedTemplateId, model);
        promise.then(result => {
            if (result.resultType == "Ok") {

                const appliedTemplates = this.state.appliedTemplates;
                const existingAppliedTemplate = appliedTemplates.find(m => m.id == appliedTemplateId);
                if (existingAppliedTemplate != null)
                    appliedTemplates[appliedTemplates.indexOf(existingAppliedTemplate)] = result.data;
                else
                    appliedTemplates.push(result.data);

                this.setState({
                    ...this.state,
                    isSaving: false,
                    appliedTemplates,
                    errors: []
                })
            } else {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [e?.toString()]
            })
        })
        return promise;
    }

    async insertDynamicFormSection(appliedTemplateId: string, dynamicFormSection: TemplateFormSectionModel, finishedSection: TemplateFormSectionModel): Promise<TemplateFormSectionModel> {
        // first find applied template
        const appliedTemplates = this.state.appliedTemplates;
        const appliedTemplate = appliedTemplates.find(m => m.id == appliedTemplateId);
        if (!appliedTemplate) return;

        // add dynamic section as next section to fill out
        let sections = [...appliedTemplate.templateForm.templateFormSections];
        dynamicFormSection.priority = finishedSection.priority + .0001;
        sections.push(dynamicFormSection);
        sections = _.sortBy(sections, s => s.priority);

        // find any values in our dynamic values cache that we have for the fields in this dynamic section
        var values = appliedTemplate.templateFormFieldValues;
        var dynamicValuesCache = appliedTemplate.dynamicFormFieldValuesCache;
        dynamicFormSection.dynamicFormFields.forEach(f => {
            var cachedValue = dynamicValuesCache?.find(v => f.name && v.dynamicFormFieldName == f.name);
            if (cachedValue) {
                values.push(cachedValue);
            }
        });

        // update applied template and set state
        appliedTemplates[appliedTemplates.indexOf(appliedTemplate)].templateForm.templateFormSections = sections;
        appliedTemplates[appliedTemplates.indexOf(appliedTemplate)].templateFormFieldValues = values;
        await this.setState({
            ...this.state,
            appliedTemplates
        })
        return dynamicFormSection;
    }

    async removeDynamicFormSection(appliedTemplateId: string, sectionName: string) {
        // first find applied template
        const appliedTemplates = this.state.appliedTemplates;
        const appliedTemplate = appliedTemplates.find(m => m.id == appliedTemplateId);
        if (!appliedTemplate) return;

        // remove the dynamic section
        let sections = [...appliedTemplate.templateForm.templateFormSections];
        let sectionIndex = sections.findIndex(s => s.name == sectionName);
        sections.splice(sectionIndex, 1);

        // update applied template and set state
        appliedTemplates[appliedTemplates.indexOf(appliedTemplate)].templateForm.templateFormSections = sections;
        await this.setState({
            ...this.state,
            appliedTemplates
        })
    }

    async finishFormSection(appliedTemplateId: string, model: FinishTemplateSectionRequest): Promise<IResult<DynamicFormResponse>> {
        try {
            await this.setSaving(true);
            const appliedTemplates = this.state.appliedTemplates;
            const appliedTemplate = appliedTemplates.find(m => m.id == appliedTemplateId);
            model.fieldValues = this.stripValuesNotInSections(appliedTemplate, model.fieldValues);
            // if there's no existing applied template something went wrong. 
            if (appliedTemplate == null) {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: ['No applied template loaded']
                })
                return {
                    data: null,
                    errors: ['No applied template loaded'],
                    resultType: "Invalid"
                }
            }
            appliedTemplates[appliedTemplates.indexOf(appliedTemplate)].templateFormFieldValues = model.fieldValues;
            await this.setState({
                ...this.state,
                appliedTemplates,
            })

            const result = await voicifyApi.finishTemplateSection(appliedTemplateId, model);
            if (result.resultType == "Ok") {
                if (result.data.responseType == "Custom") {

                }
                await this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: []
                })
                return result;
            }
            else {
                await this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
                return result;
            }
        } catch (e) {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [e?.toString()]
            })
        }
    }

    syncronousUpdateApplicationFromAppliedTemplate(applicationId: string, appliedTemplateId: string, model: UpdateAppliedApplicationTemplateFormRequest) {
        this.setSaving(true);
        const appliedTemplate = this.state.appliedTemplates.find(m => m.id == appliedTemplateId);
        model.dynamicFormSections = appliedTemplate.templateForm.templateFormSections.filter(s => s?.name);
        const promise = voicifyApi.updateApplicationFromTemplateForms(applicationId, appliedTemplateId, model);

        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [e?.toString()]
            })
        })

        return promise;
    }

    stripValuesNotInSections(appliedTemplate: AppliedApplicationTemplateModel, values: TemplateFormFieldValueModel[]): TemplateFormFieldValueModel[] {
        const updatedValues: TemplateFormFieldValueModel[] = [];
        appliedTemplate.templateForm.templateFormSections?.forEach(section => {
            const fields = (section.dynamicFormFields && section.dynamicFormFields.length > 0) ? section.dynamicFormFields : section.templateFormFields;
            fields?.forEach(field => {
                if (field.name) {
                    const value = values.find(v => v.dynamicFormFieldName == field.name);
                    if (value) {
                        updatedValues.push(value);
                    }
                }
                else {
                    const value = values.find(v => v.templateFormFieldId == field.id);
                    if (value) {
                        updatedValues.push(value);
                    }
                }
            })
        });
        return updatedValues;
    }

    async finalizeForms(applicationId: string, completeForms: UpdateAppliedApplicationTemplateFormsRequest[]): Promise<IResult<any>[]> {
        const results: IResult<any>[] = [];
        try {
            await this.setState({
                ...this.state,
                isWaitingForTemplateSync: true,
                multipleTemplateSyncsStatus: "syncing",
                templateSyncErrors: []
            });
            this.setSaving(true);

            const promises = completeForms.map(async (completeForm) => {
                const appliedTemplate = this.state.appliedTemplates.find(m => m.id === completeForm.appliedTemplateId);
                completeForm.templateFormFieldValues = this.stripValuesNotInSections(appliedTemplate, completeForm.templateFormFieldValues);
                completeForm.dynamicFormSections = appliedTemplate.templateForm.templateFormSections.filter(s => s?.name);
                let result;

                if (appliedTemplate.templateForm.templateConfiguration.finalizeFormUrl) {
                    const request: FinalizeTemplateFormRequest = {
                        applicationId: applicationId,
                        data: completeForm
                    };
                    result = await voicifyApi.finalizeDynamicForm(completeForm.appliedTemplateId, request);
                } else {
                    result = await voicifyApi.queueUpdateApplicationFromTemplateFormsSync(applicationId, completeForm.appliedTemplateId, completeForm);
                }

                if (result.resultType === "Ok") {
                    if (result.data.errors && result.data.errors.length > 0) {
                        await this.setState({
                            ...this.state,
                            isSaving: false,
                            isWaitingForTemplateSync: false,
                            templateSyncErrors: result.data.errors
                        });
                    } else if (!result.data.appliedTemplateSyncId) {
                        await this.setState({
                            ...this.state,
                            isSaving: false,
                            isWaitingForTemplateSync: false,
                            templateSyncErrors: []
                        });
                    } else {
                        await this.setState({
                            ...this.state,
                            appliedTemplateSyncId: result.data.appliedTemplateSyncId,
                            templateSyncErrors: []
                        });
                        this.waitForSyncToComplete();
                        await this.setState({
                            ...this.state,
                            isSaving: false,
                            templateSyncErrors: []
                        });
                    }
                } else {
                    await this.setState({
                        ...this.state,
                        isSaving: false,
                        isWaitingForTemplateSync: false,
                        templateSyncErrors: result.errors
                    });
                }

                results.push(result);
                return result;
            });

            await Promise.all(promises);

            //if all results are ok, set the status to complete
            const allResultsOk = results.every(result => result.resultType === "Ok");
            if (allResultsOk) {
                await this.setState({
                    ...this.state,
                    isSaving: false,
                    isWaitingForTemplateSync: false,
                    templateSyncErrors: [],
                    multipleTemplateSyncsStatus: "success"
                });
            } else {
                await this.setState({
                    ...this.state,
                    isSaving: false,
                    isWaitingForTemplateSync: false,
                    multipleTemplateSyncsStatus: "error",
                    templateSyncErrors: results.filter(result => result.resultType !== "Ok").map(result => result.errors).flat(1)
                });
            }
            return results;
        } catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                isWaitingForTemplateSync: false,
                multipleTemplateSyncsStatus: "error",
                templateSyncErrors: [e?.toString()]
            });
        }
    }

    async finalizeForm(applicationId: string, appliedTemplateId: string, model: UpdateAppliedApplicationTemplateFormRequest): Promise<IResult<any>> {
        try {
            await this.setState({
                ...this.state,
                isWaitingForTemplateSync: true,
                templateSyncErrors: []
            });
            this.setSaving(true);
            const appliedTemplate = this.state.appliedTemplates.find(m => m.id == appliedTemplateId);
            model.templateFormFieldValues = this.stripValuesNotInSections(appliedTemplate, model.templateFormFieldValues);
            model.dynamicFormSections = appliedTemplate.templateForm.templateFormSections.filter(s => s?.name);

            if (appliedTemplate.templateForm.templateConfiguration.finalizeFormUrl) { // there's a finalize form url. call that.
                const request: FinalizeTemplateFormRequest = {
                    applicationId: applicationId,
                    data: model
                }
                const result = await voicifyApi.finalizeDynamicForm(appliedTemplateId, request);
                if (result.resultType == "Ok") {
                    if (result.data.errors && result.data.errors.length > 0) { // there were errors passed back from the integration
                        this.setState({
                            ...this.state,
                            isSaving: false,
                            isWaitingForTemplateSync: false,
                            templateSyncErrors: result.data.errors
                        })
                    }
                    else if (!result.data.appliedTemplateSyncId) { // successful sync, not sync ID returned.
                        this.setState({
                            ...this.state,
                            isSaving: false,
                            isWaitingForTemplateSync: false,
                            templateSyncErrors: []
                        });
                    }
                    else {
                        await this.setState({  // success and passed back applied template sync id. Let's wait for it
                            ...this.state,
                            appliedTemplateSyncId: result.data.appliedTemplateSyncId,
                            templateSyncErrors: []
                        })
                        this.waitForSyncToComplete();
                        await this.setState({
                            ...this.state,
                            isSaving: false,
                            templateSyncErrors: []
                        });
                    }
                }
                else {
                    this.setState({
                        ...this.state,
                        isSaving: false,
                        isWaitingForTemplateSync: false,
                        templateSyncErrors: result.errors
                    })
                }
                return result;
            }
            else {
                const result = await voicifyApi.queueUpdateApplicationFromTemplateFormsSync(applicationId, appliedTemplateId, model);
                if (result.resultType == "Ok") {
                    await this.setState({
                        ...this.state,
                        appliedTemplateSyncId: result.data.id,
                        templateSyncErrors: []
                    })
                    this.waitForSyncToComplete();
                    await this.setState({
                        ...this.state,
                        isSaving: false,
                        templateSyncErrors: []
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        isSaving: false,
                        isWaitingForTemplateSync: false,
                        templateSyncErrors: result.errors
                    })
                }
                return result;
            }
        }
        catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                isWaitingForTemplateSync: false,
                templateSyncErrors: [e?.toString()]
            })
        }
    }

    async waitForSyncToComplete() {
        try {
            if (!this.state.appliedTemplateSyncId) {
                await this.setState({
                    ...this.state,
                    isWaitingForTemplateSync: false,
                    appliedTemplateSyncId: null,
                    errors: ["Unable to determine template sync."]
                })
            }
            while (this.state.appliedTemplateSyncId) {
                const result = await voicifyApi.getAppliedTemplateSync(this.state.appliedTemplateSyncId);
                if (result.resultType == "Ok") {
                    const sync = result.data;
                    switch (sync.stage) {
                        case ("Processing"):
                        case ("Queued"):
                            await new Promise(r => setTimeout(r, 1000));// wait 1000 ms and then we can try again. 
                            break;
                        case ("Complete"):
                            if (sync.error) {
                                await this.setState({
                                    ...this.state,
                                    isWaitingForTemplateSync: false,
                                    appliedTemplateSyncId: null,
                                    templateSyncErrors: [sync.error]
                                });
                            } else {
                                await this.setState({
                                    ...this.state,
                                    isWaitingForTemplateSync: false,
                                    appliedTemplateSyncId: null,
                                    errors: []
                                })
                            }
                            break;
                        default:
                            await this.setState({
                                ...this.state,
                                isWaitingForTemplateSync: false,
                                appliedTemplateSyncId: null,
                                templateSyncErrors: ["Unknown issue with template sync"]
                            });
                    }
                }
                else {
                    this.setState({
                        ...this.state,
                        isWaitingForTemplateSync: false,
                        appliedTemplateSyncId: null,
                        templateSyncErrors: result.errors
                    })
                }
            }
        }
        catch (e) {
            await this.setState({
                ...this.state,
                isSaving: false,
                isWaitingForTemplateSync: false,
                templateSyncErrors: [e?.toString()]
            })
        }
    }

    async addAppliedTemplateToApp(applicationId: string, templateConfigId: string) {
        try {
            await this.setSaving(true);
            const result = await voicifyApi.addAppliedApplicationTemplate(applicationId, templateConfigId);
            if (result.resultType == "Ok") {
                const appliedTemplates = this.state.appliedTemplates;
                appliedTemplates.push(result.data);
                await this.setState({
                    ...this.state,
                    appliedTemplates,
                    isSaving: false,
                    errors: []
                })
            } else {
                await this.setState({
                    ...this.state,
                    isSaving: false,
                    errors: result.errors
                })
            }
            return result;
        } catch (e) {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [e?.toString()]
            })
        }
    }

    async autoGenerateAppliedApplicationTemplateFieldValues(applicationId: string, request: AutoGenerateAppliedAppValuesRequest) {
        try {
            await this.setSaving(true);
            const result = await voicifyApi.autoGenerateAppliedApplicationTemplateFieldValues(applicationId, request);
            if (result.resultType == "Ok") {
                const updatedAppliedTemplates = result.data;
                const errorsList = [];
                for (const updatedAppliedTemplate of updatedAppliedTemplates) {
                    const appliedTemplateToReplaceIndex = this.state.appliedTemplates.findIndex(m => m.id == updatedAppliedTemplate.id);
                    if (appliedTemplateToReplaceIndex > -1) {
                        this.state.appliedTemplates[appliedTemplateToReplaceIndex] = updatedAppliedTemplate;
                    } else {
                        errorsList.push(`Unable to find applied template with id ${updatedAppliedTemplate.id}`);
                    }
                };
                await this.setState({
                    ...this.state,
                    appliedTemplates: this.state.appliedTemplates,
                    isSaving: false,
                    errors: errorsList
                });
            };
            return result;
        } catch (e) {
            this.setState({
                ...this.state,
                isSaving: false,
                errors: [e?.toString()]
            })
        }
    };

    removeAppliedTemplate(applicationId: string, appliedTemplateId: string, successCallback: (result) => void) {
        this.setSaving(true);
        const promise = voicifyApi.removeAppliedApplicationTemplate(applicationId, appliedTemplateId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    isSaving: false
                }).then(() => successCallback(result))
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                })
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                errors: [e?.toString()],
                isSaving: false
            })
        })
        return promise;
    }

    getLoadedTemplatesForApp(applicationId: string) {
        return this.state.appliedTemplates?.filter(t => t.appliedApplicationId == applicationId) ?? [];
    };

    getCurrentStatus(appliedTemplate: AppliedApplicationTemplateModel) {
        if (!appliedTemplate)
            return "not-enabled";
        const incompleteSections = this.getIncompleteSections(appliedTemplate);
        if (incompleteSections.length > 0) return "incomplete";
        if (appliedTemplate?.lastSyncedDate) return "synced";
        return "complete";
    };

    getIsSectionOfCurrentComplete(appliedTemplate: AppliedApplicationTemplateModel, section: TemplateFormSectionModel) {
        const currentSection = appliedTemplate?.templateForm?.templateFormSections?.find(s => (section?.name && s?.name == section?.name) || (section?.id && s?.id == section?.id));
        let fields = currentSection?.templateFormFields;
        if (!fields)
            fields = currentSection?.dynamicFormFields;

        const values = appliedTemplate?.templateFormFieldValues;
        if (!fields)
            return false;

        let isComplete = true;
        fields.forEach((field) => {
            if (field?.isRequired) {
                const value = values?.find(v => (field?.id && field.id == v.templateFormFieldId) || (field?.name && field.name == v.dynamicFormFieldName));
                if (!value || !value.value) {
                    isComplete = false;
                }
            }
        });
        return isComplete;
    };

    getIncompleteSections(appliedTemplate: AppliedApplicationTemplateModel) {
        return appliedTemplate?.templateForm?.templateFormSections?.filter(s => !this.getIsSectionOfCurrentComplete(appliedTemplate, s)) ?? []
    };

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    };

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading
        });
    };

    private async setSaving(isSaving: boolean) {
        await this.setState({
            ...this.state,
            isSaving
        });
    };

}