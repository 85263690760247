import React, { useState } from "react";
import { createContainer } from "unstated-next";
import * as voicifyApi from '../api';
import CustomAssistantDevice from '../models/customAssistant/CustomAssistantDevice';
import LanguageModel from '../models/features/api/LanguageModel';
import { SimulatorMessage } from '../scenes/simulator/components/SimulatorInteraction';
import CustomAssistantResponse from '../models/customAssistant/CustomAssistantResponse';
import { Dictionary } from 'lodash';
import DeviceTargetModel from '../models/features/api/MediaResponses/DeviceTargetModel';
import ContentItemModel from '../models/features/api/ContentItemModel';
import ConversationSessionState from '../models/sessionAttributes/ConversationSessionState';
import ConversationHistory from '../models/sessionAttributes/ConversationHistory';
import ApplicationModel from '../models/applications/api/ApplicationModel';
import CustomAssistantRequest from '../models/customAssistant/CustomAssistantRequest';
import { SimulatorEnvironment } from '../scenes/simulator/components/Simulator';
import SimulatorHistoryContentItem from '../models/features/api/SimulatorHistoryContentItem';
import IGenericContentContainer from "../state/definitions/IGenericContentContainer";
import generateGuid from '../services/guids';
import ConversationTurn from "../models/sessionAttributes/ConversationTurn";
import { getDefaultFeatureTypeName } from "../models/extensions";
const TurnHistoryKey = "ConversationTurnHistoryState";
const CamelTurnHistoryKey = "conversationTurnHistoryState";

function useSimulatorContainer() {
    const [selectedLanguage, setSelectedLanguage] = useState(null as LanguageModel);
    const [requests, setRequests] = useState([] as SimulatorMessage[]);
    const [responses, setResponses] = useState([] as CustomAssistantResponse[]);
    const [contentItemsFromHistory, setContentItemsFromHistory] = useState([] as SimulatorHistoryContentItem[]);
    const [sessionId, setSessionId] = useState(generateGuid());
    const [sessionAttributes, setSessionAttributes] = useState({} as Dictionary<any>);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [deviceTarget, setDeviceTarget] = useState(null as DeviceTargetModel);
    const [useDraftContent, setUseDraftContent] = useState(false);
    const [contentItemCache, setContentItemCache] = useState([] as SimulatorHistoryContentItem[]);

    const buildIntentRequest = (requestName: string,
        text: string,
        sessionId: string,
        locale: string,
        userId: string,
        requiresLanguageUnderstanding: boolean) => {
        const intentRequest: CustomAssistantRequest = {
            requestId: generateGuid(),
            user: {
                id: userId
            },
            device: getTestDevice(),
            context: {
                sessionId: sessionId,
                requestName: requestName,
                requestType: 'IntentRequest',
                requiresLanguageUnderstanding: requiresLanguageUnderstanding,
                originalInput: text,
                noTracking: true,
                locale: locale,
                channel: 'Voicify Simulator'
            }
        }
        return intentRequest;
    };

    const buildLaunchRequest = (text: string, userId: string, sessionId: string, language: LanguageModel) => {
        return buildIntentRequest("VoicifyWelcome", text, sessionId, language.shortCode, userId, false);
    };

    const buildStandardRequest = (text: string, userId: string, sessionId: string, language: LanguageModel) => {
        return buildIntentRequest(null, text, sessionId, language.shortCode, userId, true);
    };

    const buildFallbackRequest = (userId: string, text: string, sessionId: string, language: LanguageModel) => {
        return buildIntentRequest("VoicifyFallback", text, sessionId, language.shortCode, userId, false);
    };

    const updateConversationHistory = async (session: ConversationSessionState,
        responsesArg: CustomAssistantResponse[],
        contentContainers: Dictionary<IGenericContentContainer<ContentItemModel, any, any, any>>,
        request: CustomAssistantRequest,
        lastItemOverride: ConversationTurn = null) => {
        try {
            let turnHistory: ConversationHistory = null;
            let lastItem: ConversationTurn = null;
            if (lastItemOverride)
                lastItem = lastItemOverride;
            else if (session.sessionAttributes) {
                if (session.sessionAttributes[TurnHistoryKey]) {
                    turnHistory = session.sessionAttributes[TurnHistoryKey]
                    lastItem = turnHistory.TurnHistory[turnHistory.TurnHistory.length - 1];
                } else if (session.sessionAttributes[CamelTurnHistoryKey]) {
                    turnHistory = session.sessionAttributes[CamelTurnHistoryKey]
                    lastItem = turnHistory.TurnHistory[turnHistory.TurnHistory.length - 1];
                } 
            }

            if (!session.sessionAttributes && contentItemsFromHistory?.length) {
                // try to get old session
                session = {
                    id: "",
                    sessionAttributes: contentItemsFromHistory[contentItemsFromHistory.length - 1]?.sessionAttributes
                }
            }

            // get id if its an environment id
            let contentId = lastItem?.ContentId;
            if (contentId?.length && contentId.length > 40) {
                contentId = contentId.substring(0, 36);
            }

            let contentItem = {
                title: "Unavailable",
                applicationFeatureId: "",
                applicationId: "",
                id: "",
                hasBeenDeleted: true,
                isDraft: useDraftContent
            } as SimulatorHistoryContentItem;

            if (contentId && !contentItemCache.find(c => c.id == lastItem.ContentId)) {
                const result = await contentContainers[lastItem.FeatureTypeId].findFullById(contentId);

                if (result.resultType == "Ok") {
                    contentItem = result.data as SimulatorHistoryContentItem;

                    contentItem = {
                        ...contentItem,
                        id: lastItem.ContentId, // remapping content id in case we're in an environment
                        contentId: contentId,
                        hasBeenDeleted: false,
                        isDraft: useDraftContent,
                        interactionType: getDefaultFeatureTypeName(lastItem.FeatureTypeId),
                        sessionAttributes: session.sessionAttributes,
                        response: responsesArg[responsesArg.length - 1],
                        request: request,
                    };
                } else {
                    contentItem = {
                        title: "Unavailable",
                        applicationFeatureId: "",
                        applicationId: "",
                        id: lastItem.ContentId,
                        contentId: contentId,
                        hasBeenDeleted: true,
                        isDraft: useDraftContent,
                        sessionAttributes: session?.sessionAttributes,
                        response: responsesArg[responsesArg.length - 1],
                        request: request,
                    };
                }
            } else if (contentId && contentItemCache.find(c => c.id == lastItem.ContentId)) {
                const lastContentItem = contentItemCache.find(c => c.id == lastItem.ContentId);
                contentItem = {
                    ...lastContentItem,
                    id: lastItem.ContentId, // remapping content id in case we're in an environment
                    contentId: contentId,
                    hasBeenDeleted: false,
                    isDraft: useDraftContent,
                    interactionType: getDefaultFeatureTypeName(lastItem.FeatureTypeId),
                    sessionAttributes: session.sessionAttributes,
                    response: responsesArg[responsesArg.length - 1],
                    request: request,
                };
            }
            else {
                contentItem = {
                    title: "Default fallback message",
                    applicationFeatureId: "",
                    applicationId: "",
                    id: null,
                    contentId: null,
                    hasBeenDeleted: true,
                    isDraft: useDraftContent,
                    sessionAttributes: session?.sessionAttributes,
                    response: responsesArg[responsesArg.length - 1],
                    request: request,
                };
            }
            setSessionAttributes(session?.sessionAttributes ?? {});
            setResponses(responsesArg);
            setContentItemsFromHistory([...contentItemsFromHistory, contentItem]);
            const newCache = [...contentItemCache, ...contentItemsFromHistory, contentItem];
            setContentItemCache(newCache.filter((item, index) => newCache.findIndex(i => i.contentId === item.contentId) === index));
            setIsWaitingForResponse(false);
        } catch (err) {
            setErrors([err]);
            setIsWaitingForResponse(false);
        }
    };

    const sendAndHandleCustomAssistantRequest = async (
        applicationId: string,
        applicationSecret: string,
        sessionId: string,
        request: CustomAssistantRequest,
        contentContainers: Dictionary<IGenericContentContainer<ContentItemModel, any, any, any>>) => {
        try {
            const result = await voicifyApi.sendCustomAssistantRequest(applicationId, applicationSecret, request, useDraftContent, true, true);
            if (result.resultType == "Ok") {
                const newResponses = [...responses, result.data];
                await updateConversationHistory({ id: sessionId, sessionAttributes: result.data.sessionAttributes }, newResponses, contentContainers, request);
            } else {
                setErrors(result.errors);
                setIsWaitingForResponse(false);
            }
        }
        catch (error) {
            setErrors([error]);
            setIsWaitingForResponse(false);
        }
    };

    const getTestDevice = (): CustomAssistantDevice => {
        return {
            id: 'voicify-simulator',
            name: 'Voicify Simulator',
            supportsAudio: true,
            supportsBackgroundImage: true,
            supportsDisplayText: true,
            supportsForegroundImage: true,
            supportsSsml: true,
            supportsTextInput: true,
            supportsVideo: true,
            supportsVoiceInput: true,
        }
    };

    const startNewSession = () => {
        setRequests([] as SimulatorMessage[]);
        setResponses([] as CustomAssistantResponse[]);
        setContentItemsFromHistory([] as SimulatorHistoryContentItem[]);
        setSessionId(generateGuid());
    };

    const addMessage = (text: string) => {
        setIsWaitingForResponse(true);
        setRequests([...requests, { text }]);
    };

    const handleDeviceSelected = (deviceTarget: DeviceTargetModel) => {
        startNewSession();
        setDeviceTarget(deviceTarget);
    }

    const handleLanguageSelected = async (language: LanguageModel) => {
        await setSelectedLanguage(language);
        startNewSession();
    }

    const setEnvironment = (env: SimulatorEnvironment) => {
        startNewSession();
        setUseDraftContent(env == "draft");
    };

    const getConversationHistory = () => {
        return sessionAttributes[TurnHistoryKey] ?? sessionAttributes[CamelTurnHistoryKey] ?? {};
    };

    const sendCustomAssistantRequest = async (application: ApplicationModel,
        text: string,
        userId: string,
        sessionId: string,
        language: LanguageModel,
        contentContainers: Dictionary<IGenericContentContainer<ContentItemModel, any, any, any>>) => {
        const cleanedText = text.trim().toLowerCase();
        const cleanedInvocationPhrase = application.invocationPhrase.trim().toLowerCase();
        let request: CustomAssistantRequest;
        if (cleanedText == `open ${cleanedInvocationPhrase}` || cleanedText == `talk to ${cleanedInvocationPhrase}`)
            request = buildLaunchRequest(text, userId, sessionId, language);
        else if (cleanedText == "[unhandled response]")
            request = buildFallbackRequest(userId, text, sessionId, language);
        else
            request = buildStandardRequest(text, userId, sessionId, language);
        await sendAndHandleCustomAssistantRequest(application.id, application.secret, sessionId, request, contentContainers);
    };

    const clearErrors = () => {
        setErrors([]);
    };

    return {
        selectedLanguage,
        sessionId,
        requests,
        responses,
        contentItemsFromHistory,
        sessionAttributes,
        isWaitingForResponse,
        errors,
        deviceTarget,
        useDraftContent,
        startNewSession,
        addMessage,
        handleDeviceSelected,
        handleLanguageSelected,
        setEnvironment,
        getConversationHistory,
        sendCustomAssistantRequest,
        clearErrors,
        updateConversationHistory
    };
}

const SimulatorContainer = createContainer(useSimulatorContainer);
export default SimulatorContainer;