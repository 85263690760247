export const getRespondTemplateConfigId = () => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev" || process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "77e0ee42-ded2-471f-b061-e8c4f33ab490";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "3103b6c3-cb34-465d-a8e2-d03ed229e0a2";
    }
    return null;
};

export const getTransferTemplateConfigId = () => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev" || process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "ebd07b5b-294f-4053-9b92-ea4af9de7433";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "a4bee9ad-f559-4330-8bce-7f1487b1748f";
    }
    return null;
}

export const getOfferTextTemplateConfigId = () => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev" || process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "2d2ae9b7-dbfc-4e8b-a876-602ce8dc210a";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "8366f581-0b5f-426e-8c8c-85cd7e9aa508";
    }
    return null;
};

