import React from 'react';
import {css} from '@emotion/css';
import { style_border_default } from '../../constants/stylesValues';

interface HortizontalSeparatorProps {
    className?: string
}

const HorizontalSeparator = (props: HortizontalSeparatorProps) => (
    <div className={`${separatorStyle} ${props.className} horizontal-separator`}></div>
);

const separatorStyle = css`
    width: 100%;
    flex-grow: 1;
    border-bottom: ${style_border_default};
`

export default HorizontalSeparator;