import { sendPost } from './generic';
import IResult from '../../models/result/IResult';
import { AnalyticsOrderState } from '../../models/customAssistant/AnalyticsOrderState';
import { getAnalyticsApiUrl, MANUAL_UPDATES } from '../../constants/Urls';

export const markOrderAsPlaced = (applicationId: string, sessionId: string, request: AnalyticsOrderState): Promise<IResult<AnalyticsOrderState>> => {
    return sendPost<AnalyticsOrderState>(`${MANUAL_UPDATES}/${applicationId}/${sessionId}/trackOrderPlaced`, request, getAnalyticsApiUrl());
}

export const markOrderAsConversationalSuccess = (applicationId: string, sessionId: string, request: AnalyticsOrderState): Promise<IResult<AnalyticsOrderState>> => {
    return sendPost<AnalyticsOrderState>(`${MANUAL_UPDATES}/${applicationId}/${sessionId}/trackConversationalSuccess`, request, getAnalyticsApiUrl());
}