import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import Login from "../../../../shared/scenes/login";
import Dashboard from "../dashboard";
import OrganizationList from "../../../../shared/scenes/organizationList";
import { css } from '@emotion/css';
import AcceptInvitation from "../../../../shared/scenes/acceptInvitation";
import ForgotPassword from "../../../../shared/scenes/forgotPassword";
import ResetPassword from "../../../../shared/scenes/resetPassword";
import GoogleAuth from "../../../../shared/scenes/googleAuth";
import AmazonAuth from "../../../../shared/scenes/amazonAuth";
import OAuthRedirector from "../../../../shared/scenes/oAuthRedirect";
import MicrosoftAuth from "../../../../shared/scenes/microsoftAuth";
import CookiesComponent from "../../../../shared/components/general/CookiesComponent";
import SingleSignOnLoginRedirector from "../../../../shared/scenes/singleSignOnRedirect/SingleSignOnLoginRedirect";
import SingleSignOnSignUpRedirector from "../../../../shared/scenes/singleSignOnRedirect/SingleSignOnSignUpRedirect";
import SparkContainer from "../../../../shared/hooks/SparkContainer";
import AcceptNewInvitation from "../../../../shared/scenes/acceptInvitation/AcceptNewInvitation";
import NewUserLogin from "../../../../shared/scenes/login/NewUserLogin";

const layoutStyle = css`
    height: 100%;
`;
const Layout = () => {

    const sparkContainer = SparkContainer.useContainer();

    useEffect(() => {
        sparkContainer.setInSpark(true);
    }, []);

    return (
        <div className={layoutStyle}>
            <Route path="/login" exact component={Login} />
            <Route path="/login/:invitationCode" exact component={Login} />
            <Route path="/login/:invitationCode/firstTime" exact component={Login} />
            <Route path="/createAccount/:invitationCode" exact component={NewUserLogin} />
            <Route path="/orgs" exact component={OrganizationList} />
            <Route path="/acceptInvitation/:invitationCode" exact component={AcceptInvitation} />
            <Route path="/acceptNewInvitation/:invitationCode" exact component={AcceptNewInvitation} />
            <Route path="/forgotPassword" exact component={ForgotPassword} />
            <Route path="/setPassword/:resetToken" exact component={ResetPassword} />
            <Route path="/googleAuthRedirect" component={GoogleAuth} />
            <Route path="/amazonAuthRedirect" component={AmazonAuth} />
            <Route path="/microsoftAuthRedirect" component={MicrosoftAuth} />
            <Route path="/oAuthRedirect" component={OAuthRedirector} />
            <Route path="/singleSignOnRedirect" exact component={SingleSignOnLoginRedirector} />
            <Route path="/singleSignOnRedirect/:invitationCode" exact component={SingleSignOnLoginRedirector} />
            <Route path="/singleSignOnSignUpRedirect/:invitationCode" exact component={SingleSignOnSignUpRedirector} />
            <Route path="/v" component={Dashboard} />
            <Route path="/" exact component={Dashboard} />
            <CookiesComponent />
        </div>
    );
};
export default Layout;