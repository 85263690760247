import ApplicationBackupVersionModel from "../../models/applications/api/backups/ApplicationBackupVersionModel";
import CreateApplicationBackupVersionRequest from "../../models/applications/api/backups/CreateApplicationBackupVersionRequest";
import CreatePartialApplicationBackupVersionRequest from "../../models/applications/api/backups/CreatePartialApplicationBackupVersionRequest";
import UpdateApplicationBackupVersionRequest from "../../models/applications/api/backups/UpdateApplicationBackupVersionRequest";
import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";

export const getApplicationBackupVersions = (applicationId: string): Promise<IResult<ApplicationBackupVersionModel[]>> => {
    return sendGet(`/applicationBackupVersion/forApp/${applicationId}`);
}

export const findBackupVersionById = (applicationVersionId: string): Promise<IResult<ApplicationBackupVersionModel>> => {
    return sendGet(`/applicationBackupVersion/${applicationVersionId}`);
}

export const updateApplicationBackupVersion = (applicationVersionId: string, model: UpdateApplicationBackupVersionRequest): Promise<IResult<ApplicationBackupVersionModel>> => {
    return sendPut(`/applicationBackupVersion/${applicationVersionId}`, model);
}

export const disableApplicationBackupVersion = (applicationVersionId: string): Promise<IResult<ApplicationBackupVersionModel>> => {
    return sendDelete(`/applicationBackupVersion/${applicationVersionId}/disable`);
}

export const queueCreateNewBackupVersion = (applicationId: string, model: CreateApplicationBackupVersionRequest): Promise<IResult<ApplicationBackupVersionModel>> => {
    return sendPost(`/applicationBackupVersion/create/${applicationId}/queue`, model);
}

export const queueCreateNewPartialBackupVersion = (applicationId: string, model: CreatePartialApplicationBackupVersionRequest): Promise<IResult<ApplicationBackupVersionModel>> => {
    return sendPost(`/applicationBackupVersion/create/${applicationId}/partial/queue`, model);
}