import { getWebsocketAssistantUrl, OUTBOUND_CALL, OUTBOUND_CALL_Config } from "../../constants/Urls";
import AnalyticsRequestFilter from "../../models/analytics/AnalyticsRequestFilter";
import { CreateOutboundCallRequest } from "../../models/features/api/OutboundCall/CreateOutboundCallRequest";
import { OutboundCallModel } from "../../models/features/api/OutboundCall/OutboundCallModel";
import { OutboundCallRequest } from "../../models/outboundCall/outboundCallRequest";
import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost } from "./generic";

export const placeOutboundCall = (request: OutboundCallRequest): Promise<IResult<string>> => {
    return sendPost(`${OUTBOUND_CALL}`, request, getWebsocketAssistantUrl());
};

export const getOutboundCalls = (organizationId: string, applicationId: string, filter: AnalyticsRequestFilter): Promise<IResult<OutboundCallModel[]>> => {
    const filterQuery = `startDate=${filter.startDate}&endDate=${filter.endDate}`
    return sendGet(`${OUTBOUND_CALL_Config}/org/${organizationId}/app/${applicationId}?${filterQuery}`);
};

export const createOutboundCall = (organizationId: string, applicationId: string, request: CreateOutboundCallRequest): Promise<IResult<OutboundCallModel>> => {
    return sendPost(`${OUTBOUND_CALL_Config}/org/${organizationId}/app/${applicationId}`, request);
};

export const deleteOutboundCall = (organizationId: string, applicationId: string, outboundCallId: string): Promise<IResult<string>> => {
    return sendDelete(`${OUTBOUND_CALL_Config}/org/${organizationId}/app/${applicationId}?outboundCallId=${outboundCallId}`);
}