import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import IResult from '../../models/result/IResult';
import { CustomAssistantConfiguration } from '../../models/customAssistant/CustomAssistantConfiguration';

export const getCustomAssistantConfigurations = (applicationId: string): Promise<IResult<CustomAssistantConfiguration[]>> => {
    return sendGet<CustomAssistantConfiguration[]>(`/CustomAssistantConfiguration/applicationId/${applicationId}`);
}

export const getCustomAssistantConfiguration = (configurationId: string): Promise<IResult<CustomAssistantConfiguration>> => {
    return sendGet<CustomAssistantConfiguration>(`/CustomAssistantConfiguration/${configurationId}`);
}

export const getCustomAssistantConfigurationsForAppAndEnvironment = (applicationId: string, environmentId: string): Promise<IResult<CustomAssistantConfiguration[]>> => {
    return sendGet<CustomAssistantConfiguration[]>(`/CustomAssistantConfiguration/applicationId/${applicationId}/environmentId/${environmentId}`);
}

export const deleteCustomAssistantConfiguration = (configurationId: string): Promise<IResult<CustomAssistantConfiguration>> => {
    return sendDelete<CustomAssistantConfiguration>(`/CustomAssistantConfiguration/${configurationId}`);
}

export const createCustomAssistantConfiguration = (configuration: CustomAssistantConfiguration): Promise<IResult<CustomAssistantConfiguration>> => {
    return sendPost<CustomAssistantConfiguration>(`/CustomAssistantConfiguration`, configuration);
}

export const updateCustomAssistantConfiguration = (configuration: CustomAssistantConfiguration): Promise<IResult<CustomAssistantConfiguration>> => {
    return sendPut<CustomAssistantConfiguration>(`/CustomAssistantConfiguration/${configuration.id}`, configuration);
}