import FollowUpModel from "../features/api/FollowUp/FollowUpModel";
import UpdateChildContentContainerRequest from "../features/api/Relationships/UpdateChildContentContainerRequest";
import { NumberRangeFeatureTypeId, SimpleChoiceFeatureTypeId, LatestMessagesFeatureTypeId, QuestionAnswerFeatureTypeId, FallbackFeatureTypeId, WelcomeMessagesFeatureTypeId, HelpMessagesFeatureTypeId, ExitMessagesFeatureTypeId, CustomRequestsFeatureTypeId } from "../../constants/featureTypeIds";
import GenericContentItemModelChildContainer from "../features/GenericContentItemModelChildContainer";
import { number } from "prop-types";
import ContentContainerModel from "../features/api/Relationships/ContentContainerModel";
import _ from "lodash";
import ChildContentContainerModel from "../features/api/Relationships/ChildContentContainerModel";
import GenericContentItemModel from "../features/api/GenericContentItemModel";

export const mapFollowUpChildrenToIds: (followUp?: FollowUpModel) => UpdateChildContentContainerRequest = (followUp) => {
    if (!followUp || !followUp.childContentContainer) return null;

    return {
        applicationId: followUp.childContentContainer.applicationId,
        isLimitedToChildren: followUp.childContentContainer.isLimitedToChildren,
        questionAnswerIds: followUp.childContentContainer.contentItems.filter(c => c.featureTypeId == QuestionAnswerFeatureTypeId).map(r => r.id),
        latestMessageIds: followUp.childContentContainer.contentItems.filter(c => c.featureTypeId == LatestMessagesFeatureTypeId).map(r => r.id),
        simpleChoiceIds: followUp.childContentContainer.contentItems.filter(c => c.featureTypeId == SimpleChoiceFeatureTypeId).map(r => r.id),
        numberRangeIds: followUp.childContentContainer.contentItems.filter(c => c.featureTypeId == NumberRangeFeatureTypeId).map(r => r.id),
        customRequestIds: followUp.childContentContainer.contentItems.filter(c => c.featureTypeId == CustomRequestsFeatureTypeId).map(r => r.id)
    }
}

export const mapFollowUpChildrenToGenericContentItemModels: (followUp?: FollowUpModel) => GenericContentItemModelChildContainer = (followUp) => {
    if (!followUp || !followUp.childContentContainer) return null;

    const conversationItems = followUp.childContentContainer.contentItems.map(c => ({
        ...c,
        isEditing: false,
    }));

    return {
        conversationItems,
        isLimitedToChildren: followUp.childContentContainer.isLimitedToChildren,
        applicationId: followUp.applicationId
    }
}


export const mapContentContainerToGenericContentItemModels: (contentContainer: ContentContainerModel) => GenericContentItemModel[] = (contentContainer) => {
    if (!contentContainer) return null;
    const conversationItems = contentContainer.contentItems.map(c => ({
        ...c,
        isEditing: false,
    }));

    return _.uniqBy(conversationItems, 'id');
}

export function getNumberRangePrefix(min?: number, max?: number) {
    if (min != null && max != null) {
        return min == max ? `${min}: ` : `${min}-${max}: `;
    }
    if (min != null) {
        return `>${min}: `;
    }
    if (max != null) {
        return `<${max}: `
    }
    return null
}

export const mapGeneralItemsToIds: (conversationItemsContainer: ChildContentContainerModel) => UpdateChildContentContainerRequest =
    (conversationItemsContainer) => {
        if (!conversationItemsContainer || !conversationItemsContainer.contentItems) return
        const { isLimitedToChildren, applicationId, contentItems } = conversationItemsContainer;
        return {
            isLimitedToChildren,
            applicationId,
            questionAnswerIds: contentItems.filter(c => c.featureTypeId == QuestionAnswerFeatureTypeId).map(r => r.id),
            latestMessageIds: contentItems.filter(c => c.featureTypeId == LatestMessagesFeatureTypeId).map(r => r.id),
            simpleChoiceIds: contentItems.filter(c => c.featureTypeId == SimpleChoiceFeatureTypeId).map(r => r.id),
            numberRangeIds: contentItems.filter(c => c.featureTypeId == NumberRangeFeatureTypeId).map(r => r.id),
            customRequestIds: contentItems.filter(c => c.featureTypeId == CustomRequestsFeatureTypeId).map(r => r.id)
        }
    }