import styled from '@emotion/styled';
import React from 'react';


interface CollapsingPanelProps {
    isCollapsed: boolean
    collapsedView: JSX.Element;
    expandedView: JSX.Element;
}

const CollapsingPanel: React.FC<CollapsingPanelProps> = (props) => (
    <CollapsingPanelWrapper>
        {props.isCollapsed ? props.collapsedView : props.expandedView}
    </CollapsingPanelWrapper>
);

const CollapsingPanelWrapper = styled.div``;

export default CollapsingPanel;