export const getAIAnsweringModuleId = (): string => {

    if (process.env.VOICIFY_ENVIRONMENT == "dev" || process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "1593985d-76f2-47c8-a3cd-2ac4609834eb";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "15079da8-21fd-455c-8f49-96829bd1c558";
    }
    return null;

};

export const getFoodOrderingModuleId = (): string => {

    if (process.env.VOICIFY_ENVIRONMENT == "dev" || process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "39ea3fa5-9d5d-4fd6-b564-80c2223a372c"; // todo: figure out a module id for dev.
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "39ea3fa5-9d5d-4fd6-b564-80c2223a372c";
    }
    return null;

};