import { GET_LATESTMESSAGES, GET_LATESTMESSAGES_FOR_MODULE, CREATE_LATESTMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import NumberRangeModel from '../../models/features/api/NumberRanges/NumberRangeModel';
import NewNumberRangeRequest from '../../models/features/api/NumberRanges/NewNumberRangeRequest';
import BulkUpdateNumberRangeRequest from '../../models/features/api/NumberRanges/BulkUpdateNumberRangeRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getNumberRangesForModule = (applicationModuleId: string): Promise<IResult<NumberRangeModel[]>> => {
    return sendGet<NumberRangeModel[]>(`/NumberRange/module/${applicationModuleId}`);

}

export const getNumberRangesForApp = (applicationId: string): Promise<IResult<NumberRangeModel[]>> => {
    return sendGet<NumberRangeModel[]>(`/NumberRange/${applicationId}`);
}

export const getNumberRangesForAppFeature = (applicationFeatureId: string): Promise<IResult<NumberRangeModel[]>> => {
    return sendGet<NumberRangeModel[]>(`/NumberRange/byFeature/${applicationFeatureId}`);
}

export const createFullNumberRange = (request: NumberRangeModel): Promise<IResult<NumberRangeModel>> => {
    return sendPost<NumberRangeModel>(`/NumberRange/full`, request);
}
export const createNumberRange = (request: NewNumberRangeRequest): Promise<IResult<NumberRangeModel>> => {
    return sendPost<NumberRangeModel>(`/NumberRange`, request);
}
export const createNumberRangeStub = (request: NewNumberRangeRequest): Promise<IResult<NumberRangeModel>> => {
    return sendPost<NumberRangeModel>(`/NumberRange/stub`, request);
}

export const toggleNumberRangeLive = (numberRangeId: string, isLive: boolean) : Promise<IResult<NumberRangeModel>> => {
    return sendPut<NumberRangeModel>(`/NumberRange/${numberRangeId}/isLive`, {
        isLive
    });
}

export const toggleNumberRangeSync = (numberRangeId: string, shouldNotSync: boolean): Promise<IResult<NumberRangeModel>> => {
    return sendPut<NumberRangeModel>(`/NumberRange/${numberRangeId}/shouldNotSync`, {
        shouldNotSync
    });
}
export const updateFullNumberRange = (numberRangeId: string, request: NumberRangeModel): Promise<IResult<NumberRangeModel>> => {
    return sendPut<NumberRangeModel>(`/NumberRange/${numberRangeId}/full`, request);
}
export const bulkUpdateNumberRange = (numberRangeId: string, request: BulkUpdateNumberRangeRequest): Promise<IResult<NumberRangeModel>> => {
    return sendPut<NumberRangeModel>(`/NumberRange/${numberRangeId}/bulkUpdate`, request);
}

export const bulkUpdateNumberRangeStub = (numberRangeId: string, request: BulkUpdateNumberRangeRequest): Promise<IResult<NumberRangeModel>> => {
    return sendPut<NumberRangeModel>(`/NumberRange/${numberRangeId}/bulkUpdate/stub`, request);
}
export const deleteNumberRange = (numberRangeId: string): Promise<IResult<NumberRangeModel>> => {
    return sendDelete<NumberRangeModel>(`/NumberRange/${numberRangeId}`);
}

export const findNumberRange = (numberRangeId: string): Promise<IResult<NumberRangeModel>> => {
    return sendGet<NumberRangeModel>(`/NumberRange/full/${numberRangeId}`);
}
export const getNumberRangeWebhooks = (numberRangeId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/NumberRange/${numberRangeId}/webhooks`);
}

export const removeNumberRangeWebhook = (numberRangeWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/NumberRange/webhook/${numberRangeWebhookId}`);
}

export const addNumberRangeWebhook = (numberRangeId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/NumberRange/${numberRangeId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateNumberRangeWebhook = (numberRangeId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/NumberRange/${numberRangeId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const copyNumberRange = (contentItemId: string): Promise<IResult<NumberRangeModel>> => {
    return sendPost<NumberRangeModel>(`/NumberRange/${contentItemId}/copy`, null);
}

export const bulkUpdateNumberRangeWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/NumberRange/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getNumberRangePublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/NumberRange/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}