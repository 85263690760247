import React, { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import AnalyticsRequestFilter from "../models/analytics/AnalyticsRequestFilter";
import SessionDateModel from "../models/analytics/api/SessionDateModel";

export interface BaseEvent {
    sessionId: string;
    eventDate: string;
    requestId: string;
    applicationId: string;
}

export interface SessionListModel {
    sessionId: string,
    startDate: Date,
    phoneNumbers?: string[]
}

function useSessionAnalyticsContainer() {
    const [events, setEvents] = useState({} as { [sessionId: string]: (BaseEvent | any)[] });
    const [sessions, setSessions] = useState([] as SessionListModel[])
    const [loading, setLoading] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [error, setError] = useState("")
    const [simpleView, setSimpleView] = useState(true);

    useEffect(() => {
        // get simpleview boolean from application storage
        const simpleViewStr = localStorage.getItem("VOICIFY_SIMPLE_SESSION_ANALYTICS");
        if (simpleViewStr?.length) {
            // parse the string to boolean
            setSimpleView(simpleViewStr === "true");
        }
    }, []);

    const updateSimpleView = (simple: boolean) => {
        setSimpleView(simple);
        localStorage.setItem("VOICIFY_SIMPLE_SESSION_ANALYTICS", simple ? "true" : "false");
    };

    useEffect(() => {
        setLoadingEvents(false);
    }, [events]);

    const loadSessions = async (appId: string, filter: AnalyticsRequestFilter) => {
        try {
            setLoading(true);
            const tasks = [];
            tasks.push(voicifyApi.getSessionsByApplication(appId, filter));
            tasks.push(voicifyApi.getCallStartedEventsByApplication(appId, filter));
            var results = await Promise.all(tasks);
            let sessionModels = [];
            let callStartedEvents = [];
            if (results[0].resultType === "Ok") {
                sessionModels = results[0].data as SessionDateModel[];
            } else {
                setError(results[0].errors.join(", "));
            }
            if (results[1].resultType === "Ok") {
                if (results[1].data?.length) {
                    callStartedEvents = results[1].data as any[];
                }
            } else {
                setError(results[1].errors.join(", "));
            }

            const newSessions = [] as SessionListModel[];
            for (const session of sessionModels ?? []) {
                const callStarted = callStartedEvents.find(e => e.sessionId === session.sessionId);
                const phoneNumbers = [];
                if (callStarted?.from?.length)
                    phoneNumbers.push(callStarted.from);
                if (callStarted?.to?.length)
                    phoneNumbers.push(callStarted.to);
                if (callStarted?.phoneNumber?.length)
                    phoneNumbers.push(callStarted.phoneNumber);
                newSessions.push({
                    sessionId: session.sessionId,
                    startDate: new Date(session.startDate),
                    phoneNumbers: phoneNumbers
                });

            }
            // sort sessions by most recent start date first
            newSessions.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
            setSessions(newSessions);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(e.message);
        }
    };

    const loadEvents = async (appId: string, sessionId: string) => {
        setLoadingEvents(true);
        const eventsResponse = await voicifyApi.getAllEventsForSession(appId, sessionId);
        if (eventsResponse.resultType === "Ok") {
            eventsResponse.data.forEach(e => {
                if (e.eventDate) {
                    e.timestamp = new Date(e.eventDate).getTime()
                }
            })
            setEvents({ ...events, [sessionId]: eventsResponse.data });
            return eventsResponse.data;
        } else {
            setError(eventsResponse.errors.join(", "));
        }
    };

    const loadEventsForSession = async (appId: string, sessionId: string, forceEventLoad?: boolean): Promise<any[]> => {
        try {
            setError("");
            if (forceEventLoad || !(sessionId in events)) {
                const loadEventsResponse = await loadEvents(appId, sessionId);
                return loadEventsResponse;
            } else {
                return events[sessionId];
            }
        } catch (e) {
            setError(e.message);
        }
    };

    const getRecordingUrls = async (appId: string, sessionId: string, processed: boolean): Promise<string> => {
        try {
            const recordingResponse = await voicifyApi.getCallRecording(appId, sessionId, processed);
            if (recordingResponse.resultType === "Ok") {
                return recordingResponse.data.url;
            } else {
                setError(recordingResponse.errors.join(", "));
            }

        } catch (e) {
            setError(e.message);
        }
    };

    return {
        loadSessions,
        loadEventsForSession,
        sessions,
        events,
        loading,
        loadingEvents,
        simpleView,
        updateSimpleView,
        getRecordingUrl: getRecordingUrls,
        error
    };

}

const SessionAnalyticsContainer = createContainer(useSessionAnalyticsContainer);
export default SessionAnalyticsContainer;
