import React from 'react';
import { css } from '@emotion/css';
import { ResponsiveLine } from '@nivo/line';
import AssistantUsageModel from '../../models/analytics/api/AssistantUsageModel';
import { ocean_blue, color_colors_ocean } from '../../constants/colors';
import DatedUsageModel from '../../models/analytics/api/DatedUsageModel';
import HistogramChart from './HistogramChart';
import SecondaryLoader from '../general/SecondaryLoader';
const primaryIndicator = require('../../content/images/analytics-icons/histogram-primary-indicator.svg');
const secondaryIndicator = require('../../content/images/analytics-icons/histogram-secondary-indicator.svg');
interface HistogramChartProps {
    title: string
    primaryDataName?: string
    secondaryDataName?: string
    data: { x: string, y: number, color: string, fullDate: string, link?: string }[]
    secondaryData: { x: string, y: number, color: string, fullDate: string, link?: string }[]
    showSecondary: boolean
    showPrimary: boolean
    isLoading?: boolean
}
export const HistogramChartCard: React.SFC<HistogramChartProps> = (props) => (

    <div className={containerStyle}>
        <div className="title-section ac-analytics-histogram-card">
            <h5>{props.title}</h5>
            <div className="legend-container">
                {props.showSecondary && <>
                    <img src={secondaryIndicator} />
                    <p>{props.secondaryDataName}</p>
                </>}
                {props.showPrimary && <>
                    <img src={primaryIndicator} />
                    <p>{props.primaryDataName}</p>
                </>}
            </div>
        </div>
        {props.isLoading
            ? <div className="loader-container"><SecondaryLoader /></div>
            : <HistogramChart data={props.showPrimary ? props.data : null} secondaryData={props.showSecondary ? props.secondaryData : null} primaryDataName={props.primaryDataName} secondaryDataName={props.secondaryDataName} />}
    </div>
)

const containerStyle = css`
    height: 468px;
    width: auto;
    font-family: Muli;
    border: 1px solid #CBCCD2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 32px;
    flex: 1;
    background: white;
    .loader-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        .spinner > div {
            background: ${color_colors_ocean};
        }
    }

    .title-section {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        h5 {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            flex: 1;
        }

        .legend-container {
            display: flex;
            align-items: center;
            margin-right: 0;
            margin-left: auto;

            >img {
                margin-right: 8px;
            }
            >p {                
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                text-align: right;
                margin-right: 16px;
            }
        }
    }
`

export default HistogramChartCard;