
import React, { useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import LanguageModel from "../models/features/api/LanguageModel";
import _ from "lodash";
import EditableNlpEntity from "../models/nlpEntity/EditableNlpEntity";
import NlpEntityModel from "../models/nlpEntity/api/NlpEntityModel";
import IResult from "../models/result/IResult";
import UpdateNlpEntityRequest from "../models/nlpEntity/api/UpdateNlpEntityRequest";
import ApplicationNlpEntityModel from "../models/nlpEntity/api/ApplicationNlpEntityModel";
import NewNlpEntityRequest from "../models/nlpEntity/api/NewNlpEntityRequest";

export const LARGE_ENTITY_VALUES_COUNT: number = 1500;

function useApplicationNlpEntityContainer() {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [currentApplicationId, setCurrentApplicationId] = useState(null as string);
    const [selectedLanguages, setSelectedLanguages] = useState([] as LanguageModel[]);
    const [applicationNlpEntities, setApplicationNlpEntities] = useState([] as EditableNlpEntity[]);
    const [prebuiltNlpEntities, setPrebuiltNlpEntities] = useState([] as NlpEntityModel[]);
    
    const getApplicationNlpEntities = async (applicationId: string, languages: LanguageModel[]) => {
        try {
            setSelectedLanguages(languages);
            if(applicationId == currentApplicationId && applicationNlpEntities?.length > 0) {
                return;
            }
            await loadAllApplicationNlpEntities(applicationId);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setErrors([err.toString()]);
        }
    };
    
    const loadAllApplicationNlpEntities = async (applicationId: string) => {
        try {
            setIsLoading(true);
            setApplicationNlpEntities([]);
            setErrors([]);
            
            const result = await voicifyApi.getApplicationNlpEntities(applicationId);
            if (result?.resultType == "Ok") {
                const allEntities = result.data;
                
                let editable: EditableNlpEntity[] = allEntities?.map(entity => {
                    const numberOfvalues: number = entity?.values?.length || 0;
                    const isLarge: boolean = numberOfvalues > LARGE_ENTITY_VALUES_COUNT;
                    
                    const editableEntity: EditableNlpEntity = {...entity, 
                        isModified: false, 
                        isAdded: false, 
                        isDeleted: false, 
                        isLarge};

                    return editableEntity;
                });

                editable = _.orderBy(editable ?? [], (e) => e.name, ["asc"]);
                await setApplicationNlpEntities(editable);
                await setCurrentApplicationId(applicationId);
            } 
            else {
                await setErrors([...errors, ...result.errors, "Unable to load application NLP entities"]);
            }

            await setIsLoading(false);
        } catch (err) {
            await setIsLoading(false);
            await setErrors([err.toString()]);
        }
    };

    const loadPrebuiltNlpEntities = async (applicationId: string, languages: LanguageModel[]) => {
        try {
            setIsLoading(true);
            setSelectedLanguages(languages);
            setPrebuiltNlpEntities([]);
            setErrors([]);
            
            let merged: NlpEntityModel[] = [];

            for (const lang of languages) {
                const locale = lang.shortCode;
                const result = await voicifyApi.getApplicationPrebuiltNlpEntitiesInLocale(applicationId, locale);
                if (result?.resultType == "Ok") {
                    
                    const prebuiltLocaleEntities: NlpEntityModel[] = result.data;
                    
                    if(merged.length == 0) {
                        merged = prebuiltLocaleEntities;
                    }
                    else {
                        const mergedEntitiesIds = merged.map(e => e.id);
                        const localeEntitiesIds = prebuiltLocaleEntities?.map(e => e.id);
                        const hasAll = localeEntitiesIds?.every(val => mergedEntitiesIds?.includes(val));

                        if(!hasAll) {
                            prebuiltLocaleEntities?.forEach( e => {
                                if( !mergedEntitiesIds?.includes(e.id)) {
                                    merged.push(e);
                                }
                            });
                        }
                    }
                    
                    merged = _.orderBy(merged ?? [], (e) => e.name, ["asc"]);
                    setPrebuiltNlpEntities(merged);
                    setCurrentApplicationId(applicationId);
                } 
                else {
                    await setErrors([...errors, ...result.errors, `Unable to load Prebuilt NLP entities in locale "${locale}" `]);
                }
            }
            
            await setIsLoading(false);
        } catch (err) {
            await setIsLoading(false);
            await setErrors([err.toString()]);
        }
    };

    const updateApplicationNlpEntity = async (applicationId: string, nlpEntityId: string, request: UpdateNlpEntityRequest) : Promise<IResult<ApplicationNlpEntityModel>>  => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.updateApplicationNlpEntity(applicationId, nlpEntityId, request);
            if (result?.resultType === "Ok") {
                setIsLoading(false);
                return result;
            } else {
                await setErrors([...errors, ...result.errors, "Unable to Update Entity"]);
            }
            setIsLoading(false);
        } catch (e) {
            setErrors([e?.toString(), "Unable to Update Entity"]);
            setIsLoading(false);
        }
    };

    const addApplicationNlpEntity = async (applicationId: string, request: NewNlpEntityRequest) : Promise<IResult<ApplicationNlpEntityModel>>  => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.addApplicationNlpEntity(applicationId, request);
            if (result?.resultType === "Ok") {
                setIsLoading(false);
                return result;
            } else {
                await setErrors([...errors, ...result.errors, "Unable to Add Entity"]);
            }
            setIsLoading(false);
        } catch (e) {
            setErrors([e?.toString(), "Unable to Add Entity"]);
            setIsLoading(false);
        }
    };

    const deleteApplicationNlpEntity = async (applicationId: string, nlpEntityId: string) : Promise<IResult<ApplicationNlpEntityModel>>  => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.deleteApplicationNlpEntity(applicationId, nlpEntityId);
            if (result?.resultType === "Ok") {
                setIsLoading(false);
                return result;
            } else {
                await setErrors([...errors, ...result.errors, "Unable to Delete Entity"]);
            }
            setIsLoading(false);
        } catch (e) {
            setErrors([e?.toString(), "Unable to Delete Entity"]);
            setIsLoading(false);
        }
    };

    const clearErrors = () => {
        setErrors([]);
    };

    return {
        getApplicationNlpEntities,
        loadAllApplicationNlpEntities,
        loadPrebuiltNlpEntities,
        isLoading,
        currentApplicationId,
        languages: selectedLanguages,
        applicationNlpEntities,
        prebuiltNlpEntities,
        errors,
        clearErrors,
        updateApplicationNlpEntity,
        addApplicationNlpEntity,
        deleteApplicationNlpEntity
    };
}

const ApplicationNlpEntityContainer = createContainer(useApplicationNlpEntityContainer);
export default ApplicationNlpEntityContainer;