import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import User from '../../models/user/User';
import NewUserRequest from '../../models/user/NewUserRequest';
import OrganizationModel from '../../models/organizations/api/OrganizationModel';
import CreateNewPasswordRequest from '../../models/user/CreateNewPasswordRequest';
import UpdateUserRequest from '../../models/user/UpdateUserRequest';
import UserTermsAgreement from '../../models/term/UserTermsAgreement';
import UserTerm from '../../models/term/UserTerm';
import ChangePasswordRequest from '../../models/user/ChangePasswordRequest';

type UserState = {
    currentUser: User
    isLoading: boolean
    errors: string[]
    isResetRequestSent: boolean
    isSetPasswordSent: boolean
    currentUserTermsAgreement: UserTermsAgreement
    userRegistrationInformation: {
        firstName: string,
        lastName: string,
        email: string,
        termsAgreed: boolean,
        enteringPassword: boolean
    }
}

export default class UserContainer extends Container<UserState> {
    // default state
    state = {
        currentUser: null,
        currentUserTermsAgreement: {
            latestTerm: {
                url: "",
                id: ""
            },
            userTerms: [],
            hasAgreedToLatest: true,
        },
        isLoading: false,
        errors: [] as string[],
        isResetRequestSent: false,
        isSetPasswordSent: false,
        userRegistrationInformation: {
            firstName: "",
            lastName: "",
            email: "",
            termsAgreed: false,
            enteringPassword: false,
        }
    }

    signUp(model: NewUserRequest): Promise<IResult<User>> {
        this.setLoading(true);
        var promise = voicifyApi.createUser(model);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    currentUser: result.data,
                    isLoading: false,
                    userRegistrationInformation: {
                        firstName: "",
                        lastName: "",
                        email: "",
                        termsAgreed: false,
                        enteringPassword: false,
                    },
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })

        return promise;
    }

    checkIfUserUsesSso(email: string): Promise<IResult<boolean>> {
        var promise = voicifyApi.checkIfUserUsesSso(email);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })

        return promise;
    }

    promptUserToCreatePassword(email: string, firstName: string, lastName: string, termsAgreed: boolean) {
        this.setState({
            ...this.state,
            userRegistrationInformation: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                termsAgreed: termsAgreed,
                enteringPassword: true
            }
        });
    }

    acceptInvitation(invitationCode: string): Promise<IResult<OrganizationModel>> {
        var promise = voicifyApi.acceptInvitation(invitationCode);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })

        return promise;
    }

    resetPassword(email: string): Promise<IResult<{}>> {
        this.setLoading(true);
        var promise = voicifyApi.requestPasswordReset({ email: email })
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isResetRequestSent: true,
                    isLoading: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            }
        }).catch(error => {

            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })

        return promise;
    }

    setPassword(request: CreateNewPasswordRequest): Promise<IResult<{}>> {
        this.setLoading(true);
        var promise = voicifyApi.setPassword(request)
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isSetPasswordSent: true,
                    isLoading: false
                });
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            }
        }).catch(error => {

            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })

        return promise;
    }

    getCurrentUserTermsAgreement(): Promise<IResult<UserTermsAgreement>> {
        this.setState({
            ...this.state,
            isLoading: true
        });
        var promise = voicifyApi.getCurrentUserTermsAgreement();
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    currentUserTermsAgreement: result.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            }
        })
        return promise;
    }

    updateUserImage(imageFile: File): Promise<IResult<User>> {
        this.setLoading(true);
        var promise = voicifyApi.updateProfileImage(imageFile);
        promise.then(imageResult => {
            if (imageResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    currentUser: imageResult.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: imageResult.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        });
        return promise;
    }

    changeUserPassword(request: ChangePasswordRequest): Promise<IResult<any>> {
        this.setLoading(true);
        var promise = voicifyApi.changePassword(request);
        promise.then(imageResult => {
            if (imageResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: imageResult.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        });
        return promise;
    }

    updateUserDetails(model: UpdateUserRequest, imageFile?: File): Promise<IResult<User>> {
        this.setLoading(true);
        var promise = voicifyApi.updateUserDetails(model);
        promise.then(result => {
            if (result.resultType == "Ok") {
                if (imageFile) {
                    voicifyApi.updateProfileImage(imageFile).then(imageResult => {
                        if (result.resultType == "Ok") {
                            this.setState({
                                ...this.state,
                                errors: [],
                                currentUser: imageResult.data,
                                isLoading: false
                            })
                        }
                        else {
                            this.setState({
                                ...this.state,
                                errors: result.errors,
                                isLoading: false
                            })
                        }
                    }).catch(error => {
                        this.setState({
                            ...this.state,
                            errors: [error],
                            isLoading: false
                        })
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: [],
                        currentUser: result.data,
                        isLoading: false
                    })
                }
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })
        return promise;
    }

    resetPasswordResetRequest() {
        this.setState({
            ...this.state,
            isResetRequestSent: false
        })
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    }

    resetPasswordSetRequest() {
        this.setState({
            ...this.state,
            isSetPasswordSent: false
        })
    }

    async getUser() : Promise<IResult<User>> {
        try {            
            var result = await voicifyApi.getCurrentUser();
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    currentUser: result.data
                })
            }
            return result;
        }catch (e) {
            console.log(e)
        }
    }

    addUserTermAgreement(termId: string): Promise<IResult<UserTerm>> {
        this.setState({
            ...this.state,
            isLoading: true
        });
        const promise = voicifyApi.createUserTerm({ termId: termId, agreed: true })
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                })
                this.getCurrentUserTermsAgreement();
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false
                })
            }
        })

        return promise;
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}