import React from 'react';
import {css} from '@emotion/css';
import { color_text_light } from '../../constants/colors';
const emptyImage = require('../../content/images/analytics-icons/sleepy-speaker.svg');

const AnalyticsEmptyView = () => (
    <div className={containerStyle}>
        <img src={emptyImage}/>
        <h2>No sessions found for this view</h2>
        <p>If you expect there to be activity for this timeframe,
check to see if you have any active filters that could be restricting what appears</p>
    </div>
);

const containerStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 64px 32px;
    >h2 {
        margin: 32px 0 0 8px;
        
        font-size: 24px;
        line-height: 32px;
    }
    >p {
        color:${color_text_light};
        font-size: 14px;
        line-height: 20px;
    }
`;

export default AnalyticsEmptyView;