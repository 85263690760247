import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';

const headerStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color:${dark_grey_blue};
    width: 100%;
    vertical-align:center;
    padding: 16px 0;
`;

interface TableHeaderProps {
    text: string
}
const TableHeader: React.SFC<TableHeaderProps> = (props) => (
    <div className={headerStyle}>
        {props.text}
    </div>
)

export default TableHeader