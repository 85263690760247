import { withFormik } from 'formik';
import * as yup from 'yup';
import MediaContainer from '../../../../../shared/state/containers/MediaContainer';
import AutoGenerateFormFieldResponses from './AutoGenerateFormFieldResponses';
import AppliedApplicationTemplateContainer from '../../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import { AppendedSection } from '../ApplicationManagerContent';
import TemplateFormSectionModel from '../../../../../shared/models/templating/api/TemplateFormSectionModel';
import { AutoGenerateAppliedAppValuesRequest } from '../../../../../shared/models/templating/api/AutoGenerateAppliedAppValuesRequest';
import AutoGenerateFormFieldResponsesModal, { AutoGenerateFormFieldResponsesModalProps } from './AutoGenerateFormFieldResponsesModal';

export interface AutoGenerateFormFieldResponsesProps {
    applicationId: string
    handlePreviousSection: () => void
    handleNextSection: () => void
    mediaContainer: MediaContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    appendedSections: AppendedSection[]
    setAppendedSections: (sections: AppendedSection[]) => void
    incompleteSections: TemplateFormSectionModel[]
    setIncompleteSections: (sections: TemplateFormSectionModel[]) => void
    autoGenerateFormResponsesSection: AppendedSection
    handleRemoveSection: (sectionToRemove: AppendedSection) => void
    removeSectionFromIncompleteSections: (sectionToRemove: AppendedSection) => void
    isLoading: boolean,
    setIsLoading: (value: boolean) => void
}

export interface AutoGenerateFormFieldResponsesFormData {
    autoGenerateFormFieldResponses: boolean
    usefileUpload: boolean
    fileUrl: string
    webPageUrls: string[]
}

interface Values {
    autoGenerateFormFieldResponses: boolean,
    usefileUpload: boolean,
    fileUrl: string,
    webPageUrls: string[]
}

async function handleSubmit(values: Values, { props }) {
    if (values.autoGenerateFormFieldResponses) {
        if ("setIsLoading" in props) {
            props.setIsLoading(true);
        }
        const appliedTemplatesToUpdate = props.appliedAppTemplateContainer?.state?.appliedTemplates;
        const appliedTemplatesToUpdateIds = appliedTemplatesToUpdate.map(template => template.id);
        const autoGenerateAppliedAppValuesRequest: AutoGenerateAppliedAppValuesRequest = {
            appliedApplicationTemplateIds: appliedTemplatesToUpdateIds,
            fileUrl: values.fileUrl,
            webPageUrls: values.webPageUrls
        };
        await props.appliedAppTemplateContainer.autoGenerateAppliedApplicationTemplateFieldValues(props.applicationId, autoGenerateAppliedAppValuesRequest);
        if ("setIsLoading" in props) {
            props.setIsLoading(false);
        }
    }
    if ("onClose" in props) {
        props.onClose();
    }
    if ("handleNextSection" in props) {
        props.handleNextSection();
    }
}

const AutoGenerateFormFieldResponsesFormModal = withFormik<AutoGenerateFormFieldResponsesModalProps, AutoGenerateFormFieldResponsesFormData>({
    mapPropsToValues: props => (
        {
            autoGenerateFormFieldResponses: true,
            usefileUpload: false,
            fileUrl: '',
            webPageUrls: ['']
        }
    ),
    validationSchema: yup.object().shape({}),
    handleSubmit: handleSubmit
})(AutoGenerateFormFieldResponsesModal);

const AutoGenerateFormFieldResponsesForm = withFormik<AutoGenerateFormFieldResponsesProps, AutoGenerateFormFieldResponsesFormData>({
    mapPropsToValues: props => (
        {
            autoGenerateFormFieldResponses: false,
            usefileUpload: false,
            fileUrl: '',
            webPageUrls: ['']
        }
    ),
    validationSchema: yup.object().shape({}),
    handleSubmit
})(AutoGenerateFormFieldResponses);


export { AutoGenerateFormFieldResponsesFormModal, AutoGenerateFormFieldResponsesForm };