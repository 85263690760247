import {Container} from 'unstated';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import { BreadcrumbItem } from '../../components/general/Breadcrumb';

// container for the state of the page title.
// use this for managing the title of the dashboard 
// and updating based on the page that is current

interface TitleStateContainerState {
    title: string
    breadcrumb: BreadcrumbItem[]
    application?: ApplicationModel
}
class TitleStateContainer extends Container<TitleStateContainerState> {
    state = {
        title: '',
        breadcrumb: [],
        application: null
    };

    setTitle(text: string){
        if(this.state.title == text) return;

        this.setState({
            ...this.state,
            title: text
        });
    }

    setTitleView(title: string, breadcrumb: BreadcrumbItem[], application: ApplicationModel) {
        this.setState({
            ...this.state,
            title,
            breadcrumb,
            application
        })
    }
}

export default TitleStateContainer;