import React, { useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import MicrosoftDeploymentLink from "../models/deployments/api/MicrosoftDeploymentLink";
import LuisApplicationInformation from "../models/deployments/api/LuisApplicationInformation";

export type LuisAppSelectionType = 'new' | 'existing';

function useMicrosoftDeploymentContainer() {
    
    const defaultLuisRegion = 'westus';
    
    const emptyLink:MicrosoftDeploymentLink = {
        botServiceName: "",
        microsoftAppId: "",
        microsoftAppPassword: "",
        luisRegion: "",
        luisPredictionRegion: "",
        luisAuthoringKey: "",
        luisPredictionKey: "",
        luisPredictionEndpoint: "",
        luisAppId: ""
    };

    const [errors, setErrors] = useState([] as string[]);
    const [isLoadingApps, setIsLoadingApps] = useState(false);
    const [isLoadingLink, setIsLoadingLink] = useState(false);
    const [isCreatingApp, setIsCreatingApp] = useState(false);
    const [isLinkingApp, setIsLinkingApp] = useState(false);
    const [luisApps, setLuisApps] = useState([] as LuisApplicationInformation[]);
    const [deploymentLink, setDeploymentLink] = useState(emptyLink as MicrosoftDeploymentLink);
    const [currentStep, setCurrentStep] = useState(1 as number);
    const [luisAppType, setLuisAppType] = useState('new' as LuisAppSelectionType);
    const [luisAppName, setLuisAppName] = useState(null as string);

    const [currentApplicationId, setCurrentApplicationId] = useState(null as string);
    const [currentEnvironmentId, setCurrentEnvironmentId] = useState(null as string);

    const reset = () => {
        setIsLoadingApps(false);
        setIsLoadingLink(false);
        setIsCreatingApp(false);
        setIsLinkingApp(false);
        setLuisApps([]);
        setDeploymentLink(emptyLink);
        setCurrentStep(1);
        setLuisAppType('new');
        setLuisAppName(null);
        setErrors([]);
        setCurrentApplicationId(null);
        setCurrentEnvironmentId(null);
    }

    // determines the step in the process by what has been filled out so far
    const getCurrentStep = (link: MicrosoftDeploymentLink): number => {
        let step = 1;

        if (!link) return step;

        if (link.microsoftAppId && link.microsoftAppPassword)
            step++;
        else return step;

        if (link.luisAuthoringKey && link.luisPredictionKey && link.luisPredictionRegion)
            step++;
        else return step;

        if (link.luisAppId)
            step++;

        return step;
    };

    const getEnvironmentCurrentLink = async (applicationId: string, environmentId: string) => {
        try {   
            await setIsLoadingLink(true);
            await setCurrentApplicationId(applicationId);
            await setCurrentEnvironmentId(environmentId);

            const result = await voicifyApi.getEnvironmentDeploymentInfoForMicrosoft(applicationId, environmentId);
            if (result.resultType == "Ok") {
                const link: MicrosoftDeploymentLink = result.data;
                await setDeploymentLink(link);
                await setCurrentStep(getCurrentStep(link));
                await setIsLoadingLink(false);
                if (link.luisAuthoringKey) {
                    await getEnvironmentLuisApps(applicationId, environmentId);
                }
            } else {
                await setIsLoadingLink(false);
                await setErrors(result.errors);
            }
            return result;
        }
        catch(err) {
            setErrors([err.toString()]);
            setIsLoadingLink(false);
        }
    };

    const linkEnvironmentToBot = (applicationId: string, environmentId: string) => {
        // extra validation
        if (!deploymentLink.botServiceName) {
            setErrors(["Bot service name is required"]);
            return;
        }
        if (!deploymentLink.microsoftAppId) {
            setErrors(["Microsoft App ID is required"]);
            return;
        }
        if (!deploymentLink.microsoftAppPassword) {
            setErrors(["Microsoft App Password is required"]);
            return;
        }

        setIsLinkingApp(true);

        const promise = voicifyApi.linkBotServiceToEnvironment(applicationId, environmentId, deploymentLink.botServiceName, deploymentLink.microsoftAppId, deploymentLink.microsoftAppPassword);
        promise.then(result => {
            if (result.resultType == "Ok") {
                setIsLinkingApp(false);
                setCurrentStep(getCurrentStep(deploymentLink));
            }
            else {
                setIsLinkingApp(false);
                setErrors(result.errors);
            }
        }).catch(err => {
            setErrors([err.toString()]);
            setIsLinkingApp(false);
        });
        return promise;
    };

    const linkEnvironmentToLuisApp = async (applicationId: string, environmentId:string) => {
        // extra validation
        if (!deploymentLink.luisAppId) {
            setErrors(["You must select a Luis app to link to"]);
            return;
        }

        if (!deploymentLink.luisRegion) {
            await setDeploymentLink({
                ...deploymentLink,
                luisRegion: defaultLuisRegion
                }
            );
        }

        setIsLinkingApp(true);

        const promise = voicifyApi.linkLuisAppToEnvironment(applicationId, environmentId, deploymentLink.luisAppId, deploymentLink.luisRegion ? deploymentLink.luisRegion : defaultLuisRegion);
        promise.then(result => {
            if (result.resultType == "Ok") {
                setIsLinkingApp(false);
                setCurrentStep(getCurrentStep(deploymentLink));
            }
            else {
                setIsLinkingApp(false);
                setErrors(result.errors);
            }
        }).catch(err => {
            setErrors([err.toString()]);
            setIsLinkingApp(false);
        });
        return promise;
    };

    const linkEnvironmentLuisResources = async (applicationId: string, environmentId: string) => {
        // extra validation
        if (!deploymentLink.luisAuthoringKey) {
            setErrors(["The Luis authoring key is required"]);
            return;
        }
        if (!deploymentLink.luisPredictionKey) {
            setErrors(["The Luis prediction key is required"]);
            return;
        }
        if (!deploymentLink.luisPredictionRegion) {
            setErrors(["The Luis prediction region is required"]);
            return;
        }
        if (!deploymentLink.luisPredictionEndpoint) {
            setErrors(["The Luis prediction endpoint is required"]);
            return;
        }

        await setIsLinkingApp(true);

        const promise = voicifyApi.linkLuisKeyToEnvironment(applicationId, environmentId, deploymentLink.luisAuthoringKey);
        promise.then(async result => {
            if (result.resultType == "Ok") {
                await setIsLinkingApp(false);
                await setCurrentStep(getCurrentStep(deploymentLink));
                await linkEnvironmentLuisPredictionResources(applicationId, environmentId);
                await getEnvironmentLuisApps(applicationId, environmentId);
            }
            else {
                await setIsLinkingApp(false);
                await setErrors(result.errors);
            }
        }).catch(err => {
            setErrors([err.toString()]);
            setIsLinkingApp(false);
        });
        return promise;
    };

    const linkEnvironmentLuisPredictionResources = async (applicationId: string, environmentId: string) => {
        // extra validation
        if (!deploymentLink.luisPredictionKey) {
            setErrors(["The Luis prediction key is required"]);
            return;
        }

        await setIsLinkingApp(true);

        const promise = voicifyApi.linkLuisPredictionResourcesToEnvironment(
            applicationId, 
            environmentId, 
            deploymentLink.luisPredictionKey, 
            deploymentLink.luisPredictionRegion, 
            deploymentLink.luisPredictionEndpoint);

        promise.then(async result => {
            if (result.resultType == "Ok") {
                await setIsLinkingApp(false);
                await setCurrentStep(getCurrentStep(deploymentLink));
                await getEnvironmentLuisApps(applicationId, environmentId);
            }
            else {
                await setIsLinkingApp(false);
                await setErrors(result.errors);
            }
        }).catch(err => {
            setErrors([err.toString()]);
            setIsLinkingApp(false);
        });
        return promise;
    };

    const createAndDeployEnvironmentNewLuisApp = async (applicationId: string, environmentId: string) => {
        setIsCreatingApp(true);

        const promise = voicifyApi.createAndDeployEnvironmentNewLuisApp(applicationId, environmentId, defaultLuisRegion);
        promise.then(async result => {
            if (result.resultType == "Ok") {
                let link = {...deploymentLink};
                link.luisAppId = result.data.luisAppId;
                link.luisRegion = defaultLuisRegion;
                
                await setDeploymentLink(link);
                await setLuisAppName(result.data.name);
                await setCurrentStep(getCurrentStep(link));
                await setIsCreatingApp(false);
                await setErrors([]);
            }
            else {
                await setIsCreatingApp(false);
                await setErrors(result.errors);
            }
        }).catch(err => {
            setErrors([err.toString()]);
            setIsCreatingApp(false);
        });
        return promise;
    };

    const getEnvironmentLuisApps = async (applicationId: string, environmentId: string) => {
        try {
            await setIsLoadingApps(true);

            const result = await voicifyApi.listLuisAppsForEnvironment(applicationId, environmentId);
            if (result.resultType == "Ok") {
                await setLuisApps(result.data);
                await setIsLoadingApps(false);
                await setIsLoadingLink(false);
            } else {
                await setIsLoadingApps(false);
                await setIsLoadingLink(false);
                await setErrors(result.errors);
            }
            return result;
        }
        catch(err) {
            setErrors([err.toString()]);
            setIsLoadingApps(false);
            setIsLoadingLink(false);
        };
    };

    const unlinkEnvironmentMicrosoftProject = async (applicationId: string, environmentId: string) => {
        try {
            setIsLinkingApp(true);

            const result = await voicifyApi.unlinkBotServiceFromEnvironment(applicationId, environmentId);
        
            if (result.resultType == "Ok") {
                await setDeploymentLink(emptyLink);
                await setIsLinkingApp(false);
                await setCurrentStep(1);
            }
            else {
                await setIsLinkingApp(false);
                await setErrors(result.errors);
            }
        }
        catch(err) {
            setErrors([err.toString()]);
            setIsLinkingApp(false);
        };
    };

    const unlinkEnvironmentLuisApp = async (applicationId: string, environmentId: string) => {
        try {
            setIsLinkingApp(true);

            const result = await voicifyApi.unlinkLuisFromEnvironment(applicationId, environmentId);
            if (result.resultType == "Ok") {
                const link = {...deploymentLink};
                link.luisAppId = "";
                link.luisAuthoringKey = "";
                link.luisPredictionKey = "";
                link.luisPredictionRegion = "";
                link.luisPredictionEndpoint = "";
                await setDeploymentLink(link);
                await setIsLinkingApp(false);
                await setCurrentStep(2);
            }
            else {
                await setIsLinkingApp(false);
                await setErrors(result.errors);
            }
        }
        catch(err) {
            setErrors([err.toString()]);
            setIsLinkingApp(false);
        }
    };

    // form methods
    const setAuthoringKey = (text: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.luisAuthoringKey = text;
        setDeploymentLink(link);
    }

    const setPredictionKey = (text: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.luisPredictionKey = text;
        setDeploymentLink(link);
    }

    const setPredictionRegion = (text: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.luisPredictionRegion = text;
        setDeploymentLink(link);
    }

    const setPredictionEndpoint = (text: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.luisPredictionEndpoint = text;
        setDeploymentLink(link);
    }

    const setMsAppId = (text: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.microsoftAppId = text;
        setDeploymentLink(link);
    }

    const setMsAppPassword = (text: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.microsoftAppPassword = text;
        setDeploymentLink(link);
    }

    const setBotName = (text: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.botServiceName = text;
        setDeploymentLink(link);
    }

    const setLuisAppSelection = async (selection: LuisAppSelectionType)  => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.luisAppId = "";
        link.luisRegion = "";
        setDeploymentLink(link);
        await setLuisAppType(selection);
    }

    const setLuisAppId = async (id: string) => {
        const link: MicrosoftDeploymentLink = {...deploymentLink};
        link.luisAppId = id;

        // We want to use the luis apps PRODUCTION LuisAppEndpoint (totally different from our Production environment)
        // When we create a luis app and version of a luis app we can "publish" it to production, staging, or dev. 
        const endPointRegion = luisApps.find(a => a.id == id).endpoints?.PRODUCTION?.endpointRegion;
        if(endPointRegion) {
            link.luisRegion = endPointRegion;
        }
        await setDeploymentLink(link);
    }

    const clearErrors = async () => {
        await setErrors([]);
    }

    return {
        reset,
        getEnvironmentCurrentLink,
        createAndDeployEnvironmentNewLuisApp,
        linkEnvironmentToLuisApp,
        linkEnvironmentLuisResources,
        linkEnvironmentToBot,
        unlinkEnvironmentMicrosoftProject,
        unlinkEnvironmentLuisApp,
        setBotName,
        setMsAppId,
        setMsAppPassword,
        setAuthoringKey,
        setPredictionKey,
        setPredictionRegion,
        setPredictionEndpoint,
        setLuisAppSelection,
        setLuisAppId,
        currentEnvironmentId,
        isLoadingApps,
        isLoadingLink,
        isCreatingApp,
        isLinkingApp,
        luisApps,
        deploymentLink,
        currentStep,
        luisAppType,
        luisAppName,
        errors,
        clearErrors
    };
}

const MicrosoftDeploymentContainer = createContainer(useMicrosoftDeploymentContainer);
export default MicrosoftDeploymentContainer;