import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import IResult from '../../models/result/IResult';
import NewSimulatorPinnedMessageRequest from '../../models/user/NewSimulatorPinnedMessageRequest';
import SimulatorPinnedMessage from '../../models/user/SimulatorPinnedMessage';
import UserApplicationPreferences from '../../models/user/UserApplicationPreferences';

type UserApplicationPreferencesState = {
    currentUserApplicationPreferences: UserApplicationPreferences
    isLoading: boolean
    errors: string[]
}

export default class UserApplicationPreferencesContainer extends Container<UserApplicationPreferencesState> {
    // default state
    state = {
        currentUserApplicationPreferences: {
            id: null,
            userId: null, 
            applicationId: null,
            simulatorPinnedMessages: []
        },
        isLoading: true,
        errors: []
    }    
    setLoading(isLoading: boolean) {
        this.setState({
            ...this.setState,
            isLoading: isLoading
        });
    }
    addSimulatorPinnedMessage(applicationId: string, message: string): Promise<IResult<SimulatorPinnedMessage>> {
        this.setLoading(true);
        const request: NewSimulatorPinnedMessageRequest = {
            applicationId: applicationId,
            message: message
        };
        var promise = voicifyApi.addSimulatorPinnedMessage(request);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const pinnedMessages = this.state.currentUserApplicationPreferences.simulatorPinnedMessages;
                if(!pinnedMessages.some(p => p.message == message))
                    pinnedMessages.push(result.data);
                this.setState({
                    ...this.state,
                    currentUserApplicationPreferences: {
                        ...this.state.currentUserApplicationPreferences,
                        simulatorPinnedMessages: pinnedMessages
                    },
                    isLoading: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })

        return promise;
    }
    deleteSimulatorPinnedMessage(applicationId: string, pinnedMessageId: string): Promise<IResult<SimulatorPinnedMessage>> {
        this.setLoading(true);
        var promise = voicifyApi.deleteSimulatorPinnedMessage(applicationId, pinnedMessageId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const pinnedMessages = [...this.state.currentUserApplicationPreferences.simulatorPinnedMessages];
                const message = pinnedMessages.find(p => p.id == pinnedMessageId);
                const index = pinnedMessages.indexOf(message);                
                pinnedMessages.splice(index, 1);
                this.setState({
                    ...this.state,
                    currentUserApplicationPreferences: {
                        ...this.state.currentUserApplicationPreferences,
                        simulatorPinnedMessages: pinnedMessages
                    },
                    isLoading: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })

        return promise;
    }
    loadUserApplicationPreferences(applicationId: string): Promise<IResult<UserApplicationPreferences>> {
        this.setLoading(true);
        var promise = voicifyApi.getUserApplicationPreferences(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    currentUserApplicationPreferences: result.data,
                    isLoading: false,
                    errors: []
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                errors: [error],
                isLoading: false
            })
        })

        return promise;
    }

    clearErrors() {
        this.setState({
            ...this.state,
            errors: []
        })
    };
}