import { useEffect, useRef, useState } from "react";
import { createContainer } from "unstated-next"
import { connectWebsocket } from "../api/controllers/generic";
import * as voicifyApi from '../api';
import ApplicationModel from "../models/applications/api/ApplicationModel";

export interface LiveSession {
    sessionId?: string,
    events?: any[],
    live?: boolean
}
function useAnalyticsEventStreamContainer() {
    const [sockets, setSockets] = useState([] as {
        socket: WebSocket,
        appId: string,
    }[]);
    const [liveSessions, setLiveSessions] = useState({} as { [appId: string]: LiveSession[] });
    const [messages, setMessages] = useState([] as { appId?: string, message?: any }[]);
    const [appIdToClose, setAppIdToClose] = useState("");
    const [pollingFlag, setPollingFlag] = useState(0);
    const [applications, setApplications] = useState([] as ApplicationModel[]);
    const pollRef = useRef(null)

    useEffect(() => {
        const tenMS = 10 * 1000;

        pollRef.current = setInterval(() => {
            // random number 1-maxint
            setPollingFlag(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));
        }, tenMS);

        return () => {
            if (pollRef.current) {
                clearInterval(pollRef.current)
            }
        }
    }, [])

    useEffect(() => {
        poll();
    }, [pollingFlag])

    const loadNewApp = async (appId: string) => {
        const appResult = await voicifyApi.findApplication(appId);
        if (appResult.resultType === "Ok") {
            setApplications([...applications, appResult.data]);
        } else {
            setApplications([...applications, { id: appId, name: appId }]);
        }
    }

    const getAppName = (appId: string) => {
        const app = applications.find(a => a.id === appId);
        if (!app) {
            loadNewApp(appId);
        }
        return app?.name ?? appId;
    }


    const poll = async () => {
        // while socket is open
        for (const { socket } of sockets) {
            // if socket is open
            if (socket.readyState !== 1)
                continue;
            await socket.send("ka");
        }
    }

    useEffect(() => {
        if (messages.length) {
            for (const message of messages) {
                handleMessage(message.appId, message.message)
            }
            setMessages([]);            
        }
    }, [messages]);

    useEffect(() => {
        if (appIdToClose?.length) {
            const newSockets = sockets.filter(s => s.appId !== appIdToClose);
            setSockets(newSockets);
        }
    }, [appIdToClose])

    const handleMessage = (appId, message: any) => {
        if (!message?.data?.length)
            return;
        const event = JSON.parse(message.data);
        if (!event?.sessionId?.length)
            return;

        let sessions = [];
        if (appId in liveSessions) {
            sessions = liveSessions[appId];
        }
        let session = sessions.find(s => s.sessionId === event.sessionId) ?? {
            sessionId: event.sessionId,
            events: []
        };

        event.timestamp = new Date(event.timestamp).getTime();
        session.events.push(event);
        // sort events by eventDate
        session.events.sort((a, b) => a.timestamp - b.timestamp);


        const newSessions = { ...liveSessions };
        // remove old session with that id
        sessions = sessions.filter(s => s.sessionId !== event.sessionId);
        sessions.push(session);
        sessions.sort((a, b) => a.events[0].timestamp - b.events[0].timestamp);

        sessions.forEach(s => {
            const callComplete = s.events.find(e => e.eventType === "CallComplete");
            const callStarted = s.events.find(e => e.eventType === "CallStarted");
            if (callStarted && !callComplete) {
                s.live = true;
            } else {
                s.live = false;
            }
        })
        newSessions[appId] = sessions;
        setLiveSessions(newSessions);
    }

    const connect = async (appId: string) => {
        if (sockets.some(s => s.appId === appId))
            return;
        const socket = await connectWebsocket(`/EventWebSocket/application/${appId}`);
        socket.onmessage = (m) => setMessages([...messages, { appId, message: m }]);
        socket.onclose = () => setAppIdToClose(appId);
        socket.onerror = () => setAppIdToClose(appId);
        let appName = appId;
        const appResult = await voicifyApi.findApplication(appId);
        if (appResult.resultType === "Ok") {
            appName = appResult?.data?.name ?? appId;
        }

        const newSockets = [...sockets];
        newSockets.push({ socket: socket, appId: appId });
        while (newSockets.length > 4) {
            var socketToDrop = newSockets.shift();
            socketToDrop.socket.close();
        }
        setSockets(newSockets);     
    }

    return {
        connect,
        liveSessions,
        getAppName
    };
}

const AnalyticsEventStreamContainer = createContainer(useAnalyticsEventStreamContainer);
export default AnalyticsEventStreamContainer;
