import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, dark_grey_blue_20, dark_sky_blue } from '../../constants/colors';

interface DeviceLoadingChatBubbleProps {
    icon: string;
    iconClassName?: string;
}

const DeviceLoadingChatBubble: React.FC<DeviceLoadingChatBubbleProps> = ({ icon, iconClassName }) =>
(
    <div className={containerStyle}>
        <div className={bubbleStyle}>
            <div className="content-container">
                <div className={loadingIndicatorStyle}><div className="indicator" /></div>
            </div>
        </div>
        {iconClassName ?
            <img className={iconClassName} src={icon} />
            :
            <img src={icon} />
        }

    </div>
);

const containerStyle = css`
    display: flex;
    align-items: start;
    > img {
        background: white;
        border-radius: 50%;
        margin-top: 30px;
        margin-right: 12px;
        margin-left: 12px;
    }
`
const bubbleStyle = css`
    margin: 30px 0 0 115px;
    border: solid 1px ${dark_sky_blue};
    box-shadow: -1px 4px 10px 0 ${dark_grey_blue_20};
    background: white;
    border-radius: 10px 0 10px 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100px;
    margin-right: 0;
    margin-left: auto;
    
    .title {
        font-family: Muli;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${dark_grey_blue};
        margin-bottom: 24px;
    }
    .content-container {
        margin-left: auto;
        margin-right: auto;
    }
`;


const loadingIndicatorStyle = css`
    right: 0px;
    .indicator {
        position: relative;
        left: -9999px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        color: ${dark_sky_blue};
        animation: dot-typing 1.5s infinite linear;  
    }  
    @keyframes dot-typing {
        0% {
        box-shadow: 9984px 0 0 0 ${dark_sky_blue}, 9999px 0 0 0 ${dark_sky_blue}, 10014px 0 0 0 ${dark_sky_blue};
        }
        16.667% {
        box-shadow: 9984px -10px 0 0 ${dark_sky_blue}, 9999px 0 0 0 ${dark_sky_blue}, 10014px 0 0 0 ${dark_sky_blue};
        }
        33.333% {
        box-shadow: 9984px 0 0 0 ${dark_sky_blue}, 9999px 0 0 0 ${dark_sky_blue}, 10014px 0 0 0 ${dark_sky_blue};
        }
        50% {
        box-shadow: 9984px 0 0 0 ${dark_sky_blue}, 9999px -10px 0 0 ${dark_sky_blue}, 10014px 0 0 0 ${dark_sky_blue};
        }
        66.667% {
        box-shadow: 9984px 0 0 0 ${dark_sky_blue}, 9999px 0 0 0 ${dark_sky_blue}, 10014px 0 0 0 ${dark_sky_blue};
        }
        83.333% {
        box-shadow: 9984px 0 0 0 ${dark_sky_blue}, 9999px 0 0 0 ${dark_sky_blue}, 10014px -10px 0 0 ${dark_sky_blue};
        }
        100% {
        box-shadow: 9984px 0 0 0 ${dark_sky_blue}, 9999px 0 0 0 ${dark_sky_blue}, 10014px 0 0 0 ${dark_sky_blue};
        }
    }
`;


export default DeviceLoadingChatBubble;
