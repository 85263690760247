import React from 'react';
import { text_blue } from '../../constants/colors';
import styled from '@emotion/styled';
import MenuCollapseToggle from './MenuCollapseToggle';
import useIsMobile from '../../hooks/UseIsMobile';
import { style_border_default } from '../../constants/stylesValues';
import { breakpoint_small } from '../../constants/breakpoints';

interface ExpandedMenuPanelProps {
    onCollapse: (e) => void
    expandedViewRender: () => JSX.Element
    minimizedDrawer?: boolean
}

const ExpandedMenuPanel: React.FC<ExpandedMenuPanelProps> = (props) => {
    const isMobile = useIsMobile();

    return (
        <ExpandedMenuPanelWrapper isMobile={isMobile}>
            <HeaderWrapper isMobile={isMobile}>
                <MenuCollapseToggle
                    isMinified={false}
                    onToggle={props.onCollapse}
                    expandedMinimizedDrawer={props.minimizedDrawer}
                />
            </HeaderWrapper>
            <MenuWrapper>
                {props.expandedViewRender()}
            </MenuWrapper>
        </ExpandedMenuPanelWrapper>
    )
};

const HeaderWrapper = styled.div<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '90vw' : '288px')};
    position: relative;
    display: flex;
    align-items: center;
`;

const MenuWrapper = styled.div`
    height: calc(100vh - 80px);
    border-right: ${style_border_default};
    background: white;
`;

const ExpandedMenuPanelWrapper = styled.div<{ isMobile?: boolean }>`
    ${breakpoint_small} {
        z-index: 1;
        position: fixed;
    }
    width: ${({ isMobile }) => (isMobile ? '90vw' : '288px')};
    .panel-header {
        max-width: ${({ isMobile }) => (isMobile ? '90vw' : '288px')};
    }
    .menu-toggle {
        width: 80px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .highlighted {
        .inner-toggle {
            background: ${text_blue};
        }
    }
`;

export default ExpandedMenuPanel;