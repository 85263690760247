import React from 'react';
import { css } from '@emotion/css';
import AuthContainer from '../../../state/containers/AuthContainer';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import UserContainer from '../../../state/containers/UserContainer';
import PageError from '../../../components/general/PageError';
import SingleSignOnRequest from '../../../models/auth/api/SingleSignOnRequest';

interface SingleSignOnFormData {
    email?: string;
}

interface SingleSignOnFormProps {
    stateContainer: AuthContainer;
    userContainer: UserContainer;
    invitationCode?: string;
}

const InnerForm: React.FC<SingleSignOnFormProps & FormikProps<SingleSignOnFormData>> = ({ handleSubmit, isSubmitting, values, handleChange, handleBlur, stateContainer }) => (
    <div>
        <form onSubmit={handleSubmit}>
            <div className={explanation}>
                <div className={`row`}>
                    <span className="text">{`Use Single Sign On if it has been configured for your organization.`}</span>
                </div>
            </div>
            <TextField disabled={isSubmitting} name="email" value={values.email} label="Email" placeholder="Enter the email associated with your account" onChange={handleChange} onBlur={handleBlur} />

            <Button themes={["primary", "wide"]} type="submit" loading={isSubmitting} disabled={isSubmitting} text="Let's Go!" additionalClasses={buttonSplitStyle} />
        </form>
        <PageError errors={stateContainer.state.errors} />
    </div>
);


const SingleSignOnForm = withFormik<SingleSignOnFormProps, SingleSignOnFormData>({
    mapPropsToValues: props => ({ email: '' }),
    validationSchema: yup.object().shape({
        email: yup.string().email("Must be a valid email").required("You must enter your email"),
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        const ssoRequest: SingleSignOnRequest = {
            email: values.email,
            loginCompleteUrl: `${window.location.origin.toString()}/singleSignOnRedirect${(props.invitationCode == null ? "" : `/${props.invitationCode}`)}`
        }
        await props.stateContainer.beginSingleSignOnSignIn(ssoRequest)
        setSubmitting(false);
    }
})(InnerForm);

const buttonSplitStyle = css`
    margin-bottom: -56px;
    margin-top: 72px;
`

const explanation = css`
    border: solid 1px white;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    color: white;
    background: transparent;
    margin-bottom: 16px;
    .row {
        display: flex;
        flex-direction: row;
        padding: 15px;
        font-size: 16px;    
        .text{            
            color: white;
            padding-right: 5px;
        }
    }
`

export default SingleSignOnForm;