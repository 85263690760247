import axios from 'axios';
import { GET_HELPMESSAGES, ADD_HELPMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import HelpMessageModel from '../../models/features/api/Help/HelpMessageModel';
import NewHelpMessageRequest from '../../models/features/api/Help/NewHelpMessageRequest';
import UpdateHelpMessageRequest from '../../models/features/api/Help/UpdateHelpMessageRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getHelpMessagesForApp = (applicationId: string): Promise<IResult<HelpMessageModel[]>> => {
    return sendGet<HelpMessageModel[]>(`${GET_HELPMESSAGES}/${applicationId}`);
}

export const getHelpMessagesForAppFeature = (applicationFeatureId: string): Promise<IResult<HelpMessageModel[]>> => {
    return sendGet<HelpMessageModel[]>(`${GET_HELPMESSAGES}/byFeature/${applicationFeatureId}`);
}

export const addHelpMessage = (applicationId: string, model: NewHelpMessageRequest): Promise<IResult<HelpMessageModel>> => {
    return sendPost<HelpMessageModel>(`${ADD_HELPMESSAGE}/${applicationId}`, model);
}

export const addFullHelpMessage = (model: HelpMessageModel): Promise<IResult<HelpMessageModel>> => {
    return sendPost<HelpMessageModel>(`${ADD_HELPMESSAGE}/full`, model);
}
export const addHelpMessageStub = (model: NewHelpMessageRequest): Promise<IResult<HelpMessageModel>> => {
    return sendPost<HelpMessageModel>(`${ADD_HELPMESSAGE}/${model.applicationId}/stub`, model);
}

export const toggleHelpMessageLive = (helpMessageId: string, isLive: boolean) : Promise<IResult<HelpMessageModel>> => {
    return sendPut<HelpMessageModel>(`/HelpMessage/${helpMessageId}/isLive`, {
        isLive
    });
}
export const toggleHelpMessageSync = (helpMessageId: string, shouldNotSync: boolean): Promise<IResult<HelpMessageModel>> => {
    return sendPut<HelpMessageModel>(`/HelpMessage/${helpMessageId}/shouldNotSync`, {
        shouldNotSync
    });
}

export const updateFullHelpMessage = (helpMessageId: string, model: HelpMessageModel) : Promise<IResult<HelpMessageModel>> => {
    return sendPut<HelpMessageModel>(`/HelpMessage/${helpMessageId}/full`, model);
}
export const updateHelpMessage = (helpMessageId: string, model: UpdateHelpMessageRequest) : Promise<IResult<HelpMessageModel>> => {
    return sendPut<HelpMessageModel>(`/HelpMessage/${helpMessageId}/update`, model);
}
export const updateHelpMessageStub = (helpMessageId: string, model: UpdateHelpMessageRequest) : Promise<IResult<HelpMessageModel>> => {
    return sendPut<HelpMessageModel>(`/HelpMessage/${helpMessageId}/update/stub`, model);
}

export const deleteHelpMessage = (helpMessageId: string) : Promise<IResult<HelpMessageModel>> => {
   return sendDelete<HelpMessageModel>(`/HelpMessage/${helpMessageId}`);
}

export const findHelpMessage = (helpMessageId: string) : Promise<IResult<HelpMessageModel>> => {
    return sendGet<HelpMessageModel>(`/HelpMessage/full/${helpMessageId}`);
 }

export const getHelpMessageWebhooks = (helpMessageId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/HelpMessage/${helpMessageId}/webhooks`);
}

export const removeHelpMessageWebhook = (helpMessageWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/HelpMessage/webhook/${helpMessageWebhookId}`);
}

export const addHelpMessageWebhook = (helpMessageId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/HelpMessage/${helpMessageId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateHelpMessageWebhook = (helpMessageId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/HelpMessage/${helpMessageId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const copyHelpMessage = (contentItemId: string): Promise<IResult<HelpMessageModel>> => {
    return sendPost<HelpMessageModel>(`/HelpMessage/${contentItemId}/copy`, null);
}

export const bulkUpdateHelpMessageWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/HelpMessage/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getHelpMessagePublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/HelpMessage/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}