import React from 'react';
import {Subscribe} from 'unstated';
import AuthContainer from '../../state/containers/AuthContainer';
import {Redirect} from 'react-router';
import OrganizationContainer from '../../state/containers/OrganizationContainer';

class Logout extends React.Component {
    render() {
        return (
            <Subscribe to={[AuthContainer, OrganizationContainer]}>
            {(authContainer: AuthContainer, orgContainer: OrganizationContainer) => {
                authContainer.signOut();
                orgContainer.setCurrentOrgById(null);
                location.reload();
                return(<Redirect to="/login"/>);
            }}
            </Subscribe>
        )
    }
}

export default Logout;