import ContentItemReferenceModel from "../../models/applications/api/backups/ContentItemReferenceModel";
import CreateApplicationBackupVersionRequest from "../../models/applications/api/backups/CreateApplicationBackupVersionRequest";
import CreatePartialApplicationBackupVersionRequest from "../../models/applications/api/backups/CreatePartialApplicationBackupVersionRequest";
import UnpublishContentRequest from "../../models/applications/api/backups/UnpublishContentRequest";
import ApplicationBackupAndPublishResult from "../../models/applications/api/environments/ApplicationBackupAndPublishResult";
import ApplicationEnvironmentModel from "../../models/applications/api/environments/ApplicationEnvironmentModel";
import ApplicationEnvironmentPublishModel from "../../models/applications/api/environments/ApplicationEnvironmentPublishModel";
import ContentItemPublishResult from "../../models/features/api/ContentItemPublishResult";
import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost } from "./generic";

export const getApplicationEnvironments = (applicationId:string) : Promise<IResult<ApplicationEnvironmentModel[]>> => {
    return sendGet(`/applicationEnvironment/forApp/${applicationId}`);
}

export const findEnvironmentPubishById = (applicationPublishId:string) : Promise<IResult<ApplicationEnvironmentPublishModel>> => {
    return sendGet(`/applicationEnvironment/publishes/${applicationPublishId}`);
}
export const getEnvironmentPublishes = (applicationEnvironmentId: string, skip: number, take: number) : Promise<IResult<ApplicationEnvironmentPublishModel[]>> => {
    return sendGet(`/applicationEnvironment/publishes/forEnvironment/${applicationEnvironmentId}?skip=${skip}&take=${take}`);
}

export const queuePublishToEnvironment = (applicationEnvironmentId: string, applicationBackupVersionId: string) : Promise<IResult<ApplicationEnvironmentPublishModel>> => {
    return sendPost(`/applicationEnvironment/publishes/forEnvironment/${applicationEnvironmentId}/queue/${applicationBackupVersionId}`, null);
}

export const unpublishContentItemsFromEnvironment = (applicationEnvironmentId: string, request: UnpublishContentRequest) : Promise<IResult<ContentItemPublishResult[]>> => {
    return sendPost(`/applicationEnvironment/publishes/forEnvironment/${applicationEnvironmentId}/unpublishContent`, request);
}

export const queueBackupAndPublishApplication = (applicationId: string, applicationEnvironmentId: string, request: CreateApplicationBackupVersionRequest) : Promise<IResult<ApplicationBackupAndPublishResult>> => {
    return sendPost(`/applicationEnvironment/${applicationId}/backupAndPublish/full/forEnvironment/${applicationEnvironmentId}`, request);
}

export const queueBackupAndPublishPartialApplication = (applicationId: string, applicationEnvironmentId: string, request: CreatePartialApplicationBackupVersionRequest) : Promise<IResult<ApplicationBackupAndPublishResult>> => {
    return sendPost(`/applicationEnvironment/${applicationId}/backupAndPublish/partial/forEnvironment/${applicationEnvironmentId}`, request);
}