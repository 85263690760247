import React from 'react';
import { css } from '@emotion/css';
import { cool_grey, silver_two, pale_grey } from '../../../constants/colors';
import FooterBar from '../../structure/FooterBar';
import Button from '../../general/Button';
import SsmlDialog from './SsmlDialog';
import SsmlElementSelector, { SsmlElement } from './SsmlElementSelector';
import SsmlElementMenu from './SsmlElementMenu';
import SayAsMenu from './SayAsMenu';
import ProsodyMenu from './ProsodyMenu';
import BreakSelector from './BreakSelector';
import AudioSelector from './AudioSelector';
import SpeedSelector from './SpeedSelector';
import PitchSelector from './PitchSelector';
import EmphasisSelector from './EmphasisSelector';
import VolumeSelector from './VolumeSelector';
import DateSelector from './DateSelector';
import SsmlObject from '../../../models/ssml/SsmlObject';
import SsmlParseService from '../../../services/ssmlParseService';
const deleteIcon = require('../../../content/images/trash.svg');
const ssmlIcon = require('../../../content/images/ssml/ssml-dark.svg');
const cancelIcon = require('../../../content/images/ssml/remove-circle-dark.svg');
const breakIcon = require('../../../content/images/ssml/pause-dark.svg');
const audioIcon = require('../../../content/images/ssml/audio-file-dark.svg');
const sayAsIcon = require('../../../content/images/ssml/volume-control-settings-dark.svg');
const effectIcon = require('../../../content/images/ssml/equalizer-dark.svg');
const backIcon = require('../../../content/images/ssml/navigation-left-circle-dark.svg');
const speedIcon = require('../../../content/images/ssml/music-metronome-dark.svg');
const pitchIcon = require('../../../content/images/ssml/pitch-dark.svg');
const emphasisIcon = require('../../../content/images/ssml/megaphone-dark.svg');
const volumeIcon = require('../../../content/images/ssml/volume-control-dark.svg');

interface SsmlManagerProps {
    onCancel: () => void
    onAdd: (ssmlOpen: string, ssmlClose?: string, placeholder?: string) => void
    onUpdate?: (ssmlObject: SsmlObject) => void
    onRemove?: (ssmlObject: SsmlObject) => void
    ssml?: string
    applicationId: string
    ssmlObject?: SsmlObject
}
interface SsmlManagerState {
    ssml: string
    currentType?: SsmlType
    currentSubType?: string
    canGoBack: boolean
    placeholder?: string
}
export type SsmlType = "break" | "audio" | "say-as" | "prosody"

const containerStyle = css`
    position: absolute;
    width: 100%;
    border-radius: 5px;
    box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
    background-color: #ffffff;
    bottom: 2px;
    border: 1px solid ${silver_two};
    display: flex;
    flex-direction: column;
    .footer-bar {
        border-radius: 0 0 5px 5px;
    }

    .modal-body {
        padding: 22px;
        margin-bottom: 70px;
    }

    .button-container {
        padding: 0 22px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1;
    }

    .triangle {
        position: absolute;
        bottom: -5px;
        right: 14px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid ${silver_two};
        border-right: 1px solid ${silver_two};
        transform: rotate(45deg);
        background-color: ${pale_grey};
    }
`

class SsmlManager extends React.Component<SsmlManagerProps, SsmlManagerState> {
    private ssmlParser: SsmlParseService = new SsmlParseService();
    componentWillMount() {
        this.setState({
            ...this.state,
            ssml: this.props.ssml,
            currentType: this.getTypeFromObject(this.props.ssmlObject),
            currentSubType: this.getSubTypeFromObject(this.props.ssmlObject),
            canGoBack: this.props.ssmlObject == null,
            placeholder: ''
        })
    }

    getTypeFromObject(ssmlObject?: SsmlObject): SsmlType {
        if (ssmlObject == null) return null;

        if (ssmlObject.name == "emphasis") {
            return 'prosody';
        }
        return ssmlObject.name as SsmlType;
    }

    getSubTypeFromObject(ssmlObject?: SsmlObject): string {
        if (ssmlObject == null) return null;

        if (ssmlObject.name == "emphasis") {
            return 'emphasis'
        }
        if (ssmlObject.name == "prosody") {
            return ssmlObject.attributes[0].name;
        }
        if (ssmlObject.name == "say-as") {
            return ssmlObject.attributes.find(a => a.name == "interpret-as").value;
        }
        return null;
    }

    handleSsmlElementSelected(element: SsmlElement) {
        this.setState({
            ...this.state,
            currentType: element.value as SsmlType
        })
    }
    handleSayAsSelected(element: SsmlElement) {
        if (element.value == "date") {
            this.setState({
                ...this.state,
                currentType: 'say-as',
                currentSubType: element.value
            })
        }
        else {
            this.setState({
                ...this.state,
                currentType: 'say-as',
                currentSubType: element.value,
                ssml: `<say-as interpret-as="${element.value}">Spoken text</say-as> `,
                placeholder: "Spoken text"
            })
        }
    }
    handleProsodySelected(element: SsmlElement) {
        this.setState({
            ...this.state,
            currentType: 'prosody',
            currentSubType: element.value
        })
    }
    handleSsmlChange(ssml: string, placeholder?: string) {
        this.setState({
            ...this.state,
            ssml: ssml,
            placeholder
        });
    }
    handleCancelType() {
        this.setState({
            ...this.state,
            currentType: null,
            ssml: this.props.ssml
        })

        if (this.props.ssmlObject) {
            this.props.onCancel();
        }
    }
    handleCancelSubType() {
        this.setState({
            ...this.state,
            currentSubType: null,
            ssml: this.props.ssml
        })
    }
    handleSave() {
        if (this.props.ssmlObject) {
            // parse ssml, then update the base properties from the ssml object
            const ssmlObject = this.props.ssmlObject;
            const newBaseObject = this.ssmlParser.ssmlToObject(this.state.ssml).children[0];
            ssmlObject.name = newBaseObject.name;
            ssmlObject.attributes = newBaseObject.attributes;
            this.props.onUpdate(ssmlObject)
        }
        else {
            // if not self-closing, add end
            if (this.state.ssml.endsWith("/>")) {
                this.props.onAdd(this.state.ssml)
            }
            else {
                const separated = this.state.placeholder ? this.state.ssml.split(`>${this.state.placeholder}<`) : this.state.ssml.split("> <");
                const start = separated[0] + ">";
                const end = "<" + separated[1];
                this.props.onAdd(start, end, this.state.placeholder);
            }
        }
    }
    handleRemove() {
        const ssmlObject = this.props.ssmlObject;
        this.props.onRemove(ssmlObject);
    }
    getSsmlByType(tag: string) {
        if (!this.state.ssml) return null;
        if (!this.state.ssml.startsWith(`<${tag}`)) return null;

        return this.state.ssml;
    }
    renderInnerView() {
        if (this.state.currentType == null) {
            return (
                <SsmlDialog title="Add SSML" onCancel={this.props.onCancel} cancelIcon={cancelIcon} titleIcon={ssmlIcon}>
                    <SsmlElementMenu onElementSelected={this.handleSsmlElementSelected.bind(this)} />
                </SsmlDialog>)
        }
        if (this.state.currentType == "break") {
            return (
                <SsmlDialog title="Pause" onCancel={this.handleCancelType.bind(this)} cancelIcon={backIcon} titleIcon={breakIcon}>
                    <BreakSelector onChange={this.handleSsmlChange.bind(this)} ssml={this.getSsmlByType('break')} />
                </SsmlDialog>)
        }
        if (this.state.currentType == "audio") {
            return (
                <SsmlDialog title="Audio" onCancel={this.handleCancelType.bind(this)} cancelIcon={backIcon} titleIcon={audioIcon}>
                    <AudioSelector applicationId={this.props.applicationId} onChange={this.handleSsmlChange.bind(this)} ssml={this.getSsmlByType('audio')} />
                </SsmlDialog>)
        }
        if (this.state.currentType == "say-as") {
            if (this.state.currentSubType == "date") {
                return (
                    <SsmlDialog title="Say As" onCancel={this.handleCancelType.bind(this)} cancelIcon={backIcon} titleIcon={sayAsIcon}>
                        <DateSelector onChange={this.handleSsmlChange.bind(this)} ssml={this.getSsmlByType('say-as')} />
                    </SsmlDialog>)
            }
            else {
                return (
                    <SsmlDialog title="Say As" onCancel={this.handleCancelType.bind(this)} cancelIcon={backIcon} titleIcon={sayAsIcon}>
                        <SayAsMenu selectedValue={this.state.currentSubType} onElementSelected={this.handleSayAsSelected.bind(this)} />
                    </SsmlDialog>)
            }
        }
        if (this.state.currentType == "prosody") {
            if (this.state.currentSubType == "rate") {
                return (
                    <SsmlDialog title="Speed" onCancel={this.handleCancelSubType.bind(this)} cancelIcon={backIcon} titleIcon={pitchIcon}>
                        <SpeedSelector onChange={this.handleSsmlChange.bind(this)} ssml={this.getSsmlByType('prosody')} />
                    </SsmlDialog>
                )
            }
            if (this.state.currentSubType == "pitch") {
                return (
                    <SsmlDialog title="Pitch" onCancel={this.handleCancelSubType.bind(this)} cancelIcon={backIcon} titleIcon={speedIcon}>
                        <PitchSelector onChange={this.handleSsmlChange.bind(this)} ssml={this.getSsmlByType('prosody')} />
                    </SsmlDialog>
                )
            }
            if (this.state.currentSubType == "emphasis") {
                return (
                    <SsmlDialog title="Emphasis" onCancel={this.handleCancelSubType.bind(this)} cancelIcon={backIcon} titleIcon={emphasisIcon}>
                        <EmphasisSelector onChange={this.handleSsmlChange.bind(this)} ssml={this.getSsmlByType('emphasis')} />
                    </SsmlDialog>
                )
            }
            if (this.state.currentSubType == "volume") {
                return (
                    <SsmlDialog title="Volume" onCancel={this.handleCancelSubType.bind(this)} cancelIcon={backIcon} titleIcon={volumeIcon}>
                        <VolumeSelector onChange={this.handleSsmlChange.bind(this)} ssml={this.getSsmlByType('prosody')} />
                    </SsmlDialog>
                )
            }
            else {
                return (
                    <SsmlDialog title="Effect" onCancel={this.handleCancelType.bind(this)} cancelIcon={backIcon} titleIcon={effectIcon}>
                        <ProsodyMenu onElementSelected={this.handleProsodySelected.bind(this)} />
                    </SsmlDialog>
                )
            }
        }
        if (this.state.currentType == "say-as") {
        }
        return null;
    }
    render() {
        return (
            <div className={containerStyle}>
                <div className="modal-body">
                    {this.renderInnerView()}
                </div>
                <FooterBar small>
                    <div className="button-container">
                        <Button disabled={this.state.ssml == null || this.state.ssml.length <= 0}
                            themes={["primary-small", "start-tight"]}
                            text={this.props.ssmlObject != null ? "Update" : "Add"}
                            type="button"
                            onClick={this.handleSave.bind(this)} />
                        {this.props.ssmlObject != null ?
                            <Button themes={["white-small"]} text="Remove" type="button" onClick={this.handleRemove.bind(this)} />
                            : null}
                        <Button themes={["white-small", "end-tight"]} text="Cancel" type="button" onClick={this.props.onCancel} />
                    </div>
                </FooterBar>
                <div className="triangle" />
            </div>
        )
    }
}

export default SsmlManager;