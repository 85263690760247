import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import ApplicationStatisticsContainer from '../../../state/containers/ApplicationStatisticsContainer';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import ApplicationTopMissesTable from './ApplicationTopMissesTable';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import ApplicationTopHitsTable from './ApplicationTopHitsTable';
import AnalyticsSelectField from '../../../components/analytics/AnalyticsSelectField';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import ApplicationOrderingExportWrapper from './ApplicationOrderingExportWrapper';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import ApplicationOrderingAnalyticsReport from './ApplicationOrderingAnalyticsReport';
import SparkContainer from '../../../hooks/SparkContainer';
import { SPARK_EXCLUDED_FEATURE_TYPE_IDS } from '../../../constants/featureTypeIds';

interface ApplicationTopHitsTableProps {
    stateContainer: ApplicationStatisticsContainer
    featureContainer: FeatureContainer
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string
    ssmlContainer: SsmlContainer
    envContainer: ApplicationEnvironmentsContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    history: any
    application: ApplicationModel
    showTopicMisses?: boolean
    showTopicHits?: boolean
    statisticsContainer: ApplicationStatisticsContainer
}

export interface ContentHitLoadingState {
    others: boolean
    contentHits: boolean
}

export interface OrderingStatisticsPreferences {
    applicationId: string
    callerNumbersToIgnore: string[]
    assistantNumbersToUse: string[]
}

export const filterChanged = (oldFilter: AnalyticsRequestFilter, newFilter: AnalyticsRequestFilter) => {
    const deviceTargetDiff = oldFilter.deviceTargetIds?.filter(d => !newFilter.deviceTargetIds?.includes(d));
    if (oldFilter.startDate !== newFilter.startDate) {
        return true;
    }
    if (oldFilter.endDate !== newFilter.endDate) {
        return true;
    }
    if (oldFilter.languageIds !== newFilter.languageIds) {
        return true;
    }
    if (oldFilter.locales !== newFilter.locales) {
        return true;
    }
    if (oldFilter.assistants !== newFilter.assistants) {
        return true;
    }
    if (oldFilter.deviceTargetIds?.length !== newFilter.deviceTargetIds?.length || deviceTargetDiff?.length) {
        return true;
    }
    return false;
}

const ApplicationStatistics = (props: ApplicationTopHitsTableProps) => {
    const [loading, setLoading] = useState<ContentHitLoadingState>({ others: false, contentHits: false })
    const [interactionsCount, setInteractionsCount] = useState<number>(0);
    const [filteredInteractionsCount, setFilteredInteractionsCount] = useState<number>(0);
    const [aggregatedFeatureTypeInteractionsLoaded, setAggregatedFeatureTypeInteractionsLoaded] = useState(false);
    const [previousAggregatedFeatureTypeInteractionsLoaded, setPreviousAggregatedFeatureTypeInteractionsLoaded] = useState(false);
    const [previousInteractionsCount, setPreviousInteractionsCount] = useState<number>(0);
    const prevFilter = useRef<AnalyticsRequestFilter>();

    const sparkContainer = SparkContainer.useContainer();

    const previousDates = props.stateContainer.getPreviousDates(props.filter.startDate, props.filter.endDate);

    const loadPreviousContentHitsByFeatureTypeCounts = async () => {
        const filter: AnalyticsRequestFilter = {
            ...props.filter,
            startDate: previousDates.startDate,
            endDate: previousDates.endDate
        }
        await props.statisticsContainer.loadPreviousContentHitsByFeatureTypeCounts(props.applicationId, filter);
    }

    const loadContentHitsByFeatureTypeCounts = async () => {
        await props.statisticsContainer.loadContentHitsByFeatureTypeCounts(props.applicationId, props.filter);
    };

    useEffect(() => {
        if (props.statisticsContainer.state.aggregatedFeatureTypeInteractions) {
            setAggregatedFeatureTypeInteractionsLoaded(true);
            const aggregatedFeatureTypeInteractions = props.statisticsContainer.state.aggregatedFeatureTypeInteractions;
            let filteredInteractionCount = 0;
            let interactionCount = 0;
            const filteredAggregatedFeatureTypeInteractions = aggregatedFeatureTypeInteractions.filter(a => !SPARK_EXCLUDED_FEATURE_TYPE_IDS.includes(a.featureTypeId));
            for (const filteredAggregatedFeatureTypeInteraction of filteredAggregatedFeatureTypeInteractions) {
                filteredInteractionCount = filteredInteractionCount + filteredAggregatedFeatureTypeInteraction.count;
            };
            for (const aggregatedFeatureTypeInteraction of aggregatedFeatureTypeInteractions) {
                interactionCount = interactionCount + aggregatedFeatureTypeInteraction.count;
            };
            setFilteredInteractionsCount(filteredInteractionCount);
            setInteractionsCount(interactionCount);
        }
    }, [props.statisticsContainer.state.aggregatedFeatureTypeInteractions]);

    useEffect(() => {
        if (props.statisticsContainer.state.previousAggregatedFeatureTypeInteractions) {
            setPreviousAggregatedFeatureTypeInteractionsLoaded(true);
            const previousAggregatedFeatureTypeInteractions = props.statisticsContainer.state.previousAggregatedFeatureTypeInteractions;
            let interactionCount = 0;
            const filteredAggregatedFeatureTypeInteractions = previousAggregatedFeatureTypeInteractions.filter(a => !SPARK_EXCLUDED_FEATURE_TYPE_IDS.includes(a.featureTypeId));
            for (const aggregatedFeatureTypeInteraction of filteredAggregatedFeatureTypeInteractions) {
                interactionCount = interactionCount + aggregatedFeatureTypeInteraction.count;
            };
            setPreviousInteractionsCount(interactionCount);
        }
    }, [props.statisticsContainer.state.previousAggregatedFeatureTypeInteractions])

    useEffect(() => {
        if (!props.applicationId?.length || !props.filter?.startDate || !props.filter?.endDate) return;

        if (!prevFilter.current || filterChanged(prevFilter.current, props.filter)) {
            loadContentHitsByFeatureTypeCounts();
            loadPreviousContentHitsByFeatureTypeCounts();
            loadChartData();
        }
        prevFilter.current = props.filter;

    }, [props.interval, props.filter, props.applicationId])

    useEffect(() => {
        if (props.envContainer.state.environments.some(e => e.applicationId === props.applicationId)) return;
        props.envContainer.loadEnvironments(props.applicationId);
    }, [props.applicationId])


    async function loadStats() {
        setLoading(state => ({ ...state, others: true }));
        const { applicationId, filter } = props;
        const promises = [];
        promises.push(props.stateContainer.loadTopContentMissesForApp(applicationId, filter));
        promises.push(props.stateContainer.loadMissStats(applicationId, filter));
        promises.push(props.stateContainer.loadStats(applicationId, filter));
        promises.push(props.featureContainer.getSystemFeatures());
        promises.push()
        await Promise.all(promises);
        setLoading(state => ({ ...state, others: false }));
    }
    async function loadContentHits() {
        setLoading(state => ({ ...state, contentHits: true }));
        await props.stateContainer.loadTopContentHitsForApp(props.applicationId, props.filter);
        setLoading(state => ({ ...state, contentHits: false }));
    }

    const loadChartData = async () => {
        if (loading.contentHits || loading.others) return;
        await Promise.all([
            loadStats(),
            loadContentHits()
        ])
    };

    const handleDeltaPeriodChange = async (option: string) => {
        const { applicationId, filter, stateContainer } = props;
        await stateContainer.handleDeltaPeriodChange(option);
        stateContainer.loadTopContentHitsForApp(applicationId, filter);
        stateContainer.loadTopContentMissesForApp(applicationId, filter);
        stateContainer.loadMissStats(applicationId, filter);
        stateContainer.loadStats(applicationId, filter);
    };

    const buildCacheKey = props.stateContainer.buildCacheKey;
    const nonFoodOrderingDisplayOptions = [...props.stateContainer.displayOptions].filter(d => !d.includes('AI Ordering'));

    const renderVoicifyContent = () => {
        switch (props.stateContainer.state.currentDisplayOption) {
            case 'Content Hits':
                return (
                    <ApplicationTopHitsTable
                        isLoading={loading}
                        data={props.stateContainer.state.appTopHits.find(a => buildCacheKey(a.applicationId, a.filter) === buildCacheKey(props.applicationId, props.filter))}
                        totals={props.stateContainer.state.appStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, props.filter))?.data}
                        previousTotals={props.stateContainer.state.appStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, { ...props.filter, startDate: previousDates.startDate, endDate: previousDates.endDate }))?.data}
                        featureContainer={props.featureContainer}
                        startDate={props.filter.startDate}
                        endDate={props.filter.endDate}
                        showPercentage={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                        showValue={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1}
                        statisticsContainer={props.statisticsContainer}
                        aggregatedFeatureTypeInteractionsLoaded={aggregatedFeatureTypeInteractionsLoaded}
                        previousAggregatedFeatureTypeInteractionsLoaded={previousAggregatedFeatureTypeInteractionsLoaded}
                        filteredInteractionsCount={filteredInteractionsCount}
                        previousInteractionsCount={previousInteractionsCount}
                        previousDates={previousDates}
                        applicationId={props.applicationId}
                    />
                )
            case 'Content Misses':
                return (
                    <ApplicationTopMissesTable
                        envContainer={props.envContainer}
                        isLoading={loading.others}
                        data={props.stateContainer.state.appTopMisses.find(a => buildCacheKey(a.applicationId, a.filter) === buildCacheKey(props.applicationId, props.filter))}
                        totals={props.stateContainer.state.appMissStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, props.filter))}
                        previousTotals={props.stateContainer.state.appMissStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, { ...props.filter, startDate: previousDates.startDate, endDate: previousDates.endDate }))}
                        featureContainer={props.featureContainer}
                        startDate={props.filter.startDate}
                        endDate={props.filter.endDate}
                        filter={props.filter}
                        applicationId={props.applicationId}
                        showPercentage={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                        showValue={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1}
                        statisticsContainer={props.statisticsContainer}
                        aggregatedFeatureTypeInteractionsLoaded={aggregatedFeatureTypeInteractionsLoaded}
                        interactionsCount={interactionsCount}
                    />
                )
            case 'AI Ordering':
                return (
                    <ApplicationOrderingAnalyticsReport
                        filter={props.filter}
                        applicationId={props.applicationId}
                    />
                )
            case 'AI Ordering Export':
                return (
                    <ApplicationOrderingExportWrapper
                        {...props}
                        filter={props.filter}
                        interval={props.interval}
                        innerInterval={props.innerInterval}
                        ssmlContainer={props.ssmlContainer}
                        envContainer={props.envContainer}
                        history={props.history}
                        userApplicationPreferencesContainer={props.userApplicationPreferencesContainer}
                        applicationId={props.applicationId}
                    />
                )
        }
    };

    const renderSparkContent = () => {
        if (props.showTopicHits) {
            return (
                <ApplicationTopHitsTable
                    isLoading={loading}
                    data={props.stateContainer.state.appTopHits.find(a => buildCacheKey(a.applicationId, a.filter) === buildCacheKey(props.applicationId, props.filter))}
                    totals={props.stateContainer.state.appStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, props.filter))?.data}
                    previousTotals={props.stateContainer.state.appStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, { ...props.filter, startDate: previousDates.startDate, endDate: previousDates.endDate }))?.data}
                    featureContainer={props.featureContainer}
                    startDate={props.filter.startDate}
                    endDate={props.filter.endDate}
                    showPercentage={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                    showValue={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1}
                    statisticsContainer={props.statisticsContainer}
                    aggregatedFeatureTypeInteractionsLoaded={aggregatedFeatureTypeInteractionsLoaded}
                    previousAggregatedFeatureTypeInteractionsLoaded={previousAggregatedFeatureTypeInteractionsLoaded}
                    filteredInteractionsCount={filteredInteractionsCount}
                    previousInteractionsCount={previousInteractionsCount}
                    previousDates={previousDates}
                    applicationId={props.applicationId}
                />
            )
        } else {
            return (
                <ApplicationTopMissesTable
                    envContainer={props.envContainer}
                    isLoading={loading.others}
                    data={props.stateContainer.state.appTopMisses.find(a => buildCacheKey(a.applicationId, a.filter) === buildCacheKey(props.applicationId, props.filter))}
                    totals={props.stateContainer.state.appMissStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, props.filter))}
                    previousTotals={props.stateContainer.state.appMissStats.find(a => buildCacheKey(a.id, a.filter) === buildCacheKey(props.applicationId, { ...props.filter, startDate: previousDates.startDate, endDate: previousDates.endDate }))}
                    featureContainer={props.featureContainer}
                    startDate={props.filter.startDate}
                    endDate={props.filter.endDate}
                    filter={props.filter}
                    applicationId={props.applicationId}
                    showPercentage={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('percentage') > -1}
                    showValue={props.stateContainer.state.currentDeltaType.toLocaleLowerCase().indexOf('value') > -1}
                    statisticsContainer={props.statisticsContainer}
                    aggregatedFeatureTypeInteractionsLoaded={aggregatedFeatureTypeInteractionsLoaded}
                    interactionsCount={interactionsCount}
                />
            )
        }
    };

    return (
        <div className={wrapperStyle}>
            <div className={sparkContainer.inSpark ? 'spark-wrapper' : 'options-row-container'}>
                {
                    props.application?.featureFlags?.find(f => f.name === 'Food Ordering') ? (
                        !sparkContainer.inSpark &&
                        <AnalyticsSelectField
                            label="Display:"
                            options={props.stateContainer.displayOptions.map(s => ({ label: s, value: s }))}
                            value={props.stateContainer.state.currentDisplayOption}
                            onChange={(e) => props.stateContainer.handleDisplayOptionChange(e.value)}
                        />

                    ) : (
                        !sparkContainer.inSpark &&
                        <AnalyticsSelectField
                            label="Display:"
                            options={nonFoodOrderingDisplayOptions.map(s => ({ label: s, value: s }))}
                            value={props.stateContainer.state.currentDisplayOption}
                            onChange={(e) => props.stateContainer.handleDisplayOptionChange(e.value)}
                        />
                    )
                }
                {(!sparkContainer.inSpark && props.stateContainer.state.currentDisplayOption === 'Content Hits') && (
                    <div className="end-container">
                        <AnalyticsSelectField
                            label="Compare to:"
                            options={['Previous Period', 'Same Time Last Year'].map(s => ({ label: s, value: s }))}
                            value={props.stateContainer.state.currentDeltaPeriod}
                            onChange={(e) => handleDeltaPeriodChange(e.value)}
                        />
                        <AnalyticsSelectField
                            label="Change in:"
                            options={['Value', 'Percentage', 'Value & Percentage'].map(s => ({ label: s, value: s }))}
                            value={props.stateContainer.state.currentDeltaType}
                            onChange={(e) => props.stateContainer.handleDeltaTypeChange(e.value)}
                        />
                    </div>
                )}
            </div>

            {sparkContainer.inSpark ?
                renderSparkContent()
                :
                renderVoicifyContent()
            }
        </div>
    )
}

const wrapperStyle = css`
    .card-container {
        display: flex;
    }
    .options-row-container {
        display: flex;
        align-items: center;
        margin: 32px;
    }
    .spark-wrapper {
        display: flex;
        align-items: center;
        margin-top: 16px;
    }
    .end-container {
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-left: auto;
    >div {
        margin-left: 24px;
        }
    }
`;

export default ApplicationStatistics;