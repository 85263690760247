import { GET_APPLICATIONS } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendPost, sendGet, sendPut, sendDelete } from './generic';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import NewApplicationRequest from '../../models/applications/api/NewApplicationRequest';
import CopyApplicationTemplateRequest from '../../models/applications/api/CopyApplicationTemplateRequest';
import ApplicationMemberModel from '../../models/applications/api/ApplicationMemberModel';
import NewBulkApplicationMemberRequest from '../../models/applications/api/NewBulkApplicationMemberRequest';
import ApplicationWebhookModel from '../../models/webhooks/api/ApplicationWebhookModel';
import ApplicationFulfillmentEndpoints from '../../models/applications/api/ApplicationFulfillmentEndpoints';
import UpdateApplicationRequest from '../../models/applications/api/UpdateApplicationRequest';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import ApplicationCountsModel from '../../models/applications/api/ApplicationCountsModel';
import ApplicationsSearchResult from '../../models/applications/api/ApplicationsSearchResult';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import AssistantApplicationModel from '../../models/applications/api/AssistantApplicationModel';

export const findApplication = (applicationId: string): Promise<IResult<ApplicationModel>> => {
    return sendGet<ApplicationModel>(`${GET_APPLICATIONS}/find/${applicationId}`);
}
export const findApplicationWithFeatures = (applicationId: string): Promise<IResult<ApplicationModel>> => {
    return sendGet<ApplicationModel>(`${GET_APPLICATIONS}/find/${applicationId}/withfeatures`);
}
export const getApplicationsForOrg = (organizationId: string): Promise<IResult<ApplicationModel[]>> => {
    return sendGet<ApplicationModel[]>(`${GET_APPLICATIONS}/${organizationId}`);
}
export const getApplicationsWithFeaturesForOrg = (organizationId: string): Promise<IResult<ApplicationModel[]>> => {
    return sendGet<ApplicationModel[]>(`${GET_APPLICATIONS}/${organizationId}/withFeatures`);
}

export const searchForApplications = (organizationId: string, skip: number, take: number, sortBy: string, search: string): Promise<IResult<ApplicationsSearchResult>> => {
    return sendGet<ApplicationsSearchResult>(`${GET_APPLICATIONS}/${organizationId}/withMembers/search?take=${take}&skip=${skip}&sortBy=${sortBy}&search=${search}`);
}

export const createEmptyApplication = (organizationId: string, model: NewApplicationRequest): Promise<IResult<ApplicationModel>> => {
    return sendPost<ApplicationModel>(`${GET_APPLICATIONS}/${organizationId}`, model);
}

export const createApplicationWithSamples = (organizationId: string, model: NewApplicationRequest): Promise<IResult<ApplicationModel>> => {
    return sendPost<ApplicationModel>(`${GET_APPLICATIONS}/${organizationId}/withSamples`, model);
}

export const setApplicationImage = (applicationId: string, mediaItemId: string): Promise<IResult<ApplicationModel>> => {
    return sendPut<ApplicationModel>(`${GET_APPLICATIONS}/${applicationId}/setImage/${mediaItemId}`, null);
}

export const addTemplateContentToApplication = (applicationId: string, templates: CopyApplicationTemplateRequest[]): Promise<IResult<ApplicationModel>> => {
    return sendPut<ApplicationModel>(`${GET_APPLICATIONS}/${applicationId}/addTemplates`, templates);
}

export const enableDetailedAnalytics = (applicationId: string): Promise<IResult<ApplicationModel>> => {
    return sendPut<ApplicationModel>(`${GET_APPLICATIONS}/${applicationId}/enableDetailedAnalytics`, null);
}

export const disableDetailedAnalytics = (applicationId: string): Promise<IResult<ApplicationModel>> => {
    return sendPut<ApplicationModel>(`${GET_APPLICATIONS}/${applicationId}/disableDetailedAnalytics`, null);
}

export const getApplicationMembers = (applicationId: string): Promise<IResult<ApplicationMemberModel[]>> => {
    return sendGet<ApplicationMemberModel[]>(`${GET_APPLICATIONS}/${applicationId}/members`);
}

export const addApplicationMember = (applicationId: string, model: NewBulkApplicationMemberRequest): Promise<IResult<ApplicationMemberModel[]>> => {
    return sendPost<ApplicationMemberModel[]>(`${GET_APPLICATIONS}/${applicationId}/members/bulkAdd`, model);
}

export const updateApplicationMemberRole = (applicationMemberId: string, roleId: string): Promise<IResult<ApplicationMemberModel>> => {
    return sendPut<ApplicationMemberModel>(`${GET_APPLICATIONS}/members/${applicationMemberId}`, { roleId });
}

export const removeApplicationMember = (applicationMemberId: string): Promise<IResult<ApplicationMemberModel>> => {
    return sendDelete<ApplicationMemberModel>(`${GET_APPLICATIONS}/members/${applicationMemberId}`);
}

export const disableApplication = (applicationId: string): Promise<IResult<ApplicationModel>> => {
    return sendDelete<ApplicationModel>(`${GET_APPLICATIONS}/${applicationId}/disable`);
}

export const getApplicationConversationCounts = (organizationId: string): Promise<IResult<ApplicationCountsModel[]>> => {
    return sendGet<ApplicationCountsModel[]>(`${GET_APPLICATIONS}/${organizationId}/apps/conversationCounts`);
}

export const getApplicationCounts = (organizationId: string, appIds: string[]): Promise<IResult<ApplicationCountsModel[]>> => {
    var queryString = appIds.join("&appIds=");    
    queryString = `?appIds=${queryString}`;
    const url = `${GET_APPLICATIONS}/${organizationId}/conversationAndFeatureCounts${queryString}`;
    return sendGet<ApplicationCountsModel[]>(url);
}

export const getApplicationWebhookInstances = (applicationId: string): Promise<IResult<ApplicationWebhookModel[]>> => {
    return sendGet(`/Application/${applicationId}/webhooks`);
}

export const removeApplicationWebhook = (applicationWebhookId: string): Promise<IResult<ApplicationWebhookModel>> => {
    return sendDelete(`/Application/webhook/${applicationWebhookId}`);
}

export const addApplicationWebhook = (applicationId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<ApplicationWebhookModel>> => {
    return sendPost(`/Application/${applicationId}/webhooks/${webhookId}`, { values: defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateApplicationWebhook = (applicationId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<ApplicationWebhookModel>> => {
    return sendPut(`/Application/${applicationId}/webhooks/${webhookInstanceId}`, { values: defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const bulkUpdateApplicationWebhookInstances = (applicationId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/Application/${applicationId}/webhooks/bulk`, updateRequest);
}

export const getApplicationFulfillmentEndpoints = (applicationId: string): Promise<IResult<ApplicationFulfillmentEndpoints>> => {
    return sendGet(`/Application/${applicationId}/fulfillmentEndpoints`);
}

export const updateApplication = (applicationId: string, model: UpdateApplicationRequest): Promise<IResult<ApplicationModel>> => {
    return sendPut(`/application/${applicationId}`, model);
}

export const copyApplication = (organizationId: string, applicationId: string): Promise<IResult<ApplicationModel>> => {
    return sendPost(`${GET_APPLICATIONS}/${organizationId}/copy/${applicationId}`, null);
}

export const updateApplicationLanguages = (applicationId: string, languageIds: string[]): Promise<IResult<ApplicationModel>> => {
    return sendPut(`/application/${applicationId}/languages`, languageIds);
}

export const updateApplicationDefaultLanguage = (applicationId: string, languageId: string): Promise<IResult<ApplicationModel>> => {
    return sendPut(`/application/${applicationId}/defaultlanguage/${languageId}`, languageId);
}

export const toggleAllowsTemplating = (applicationId: string, allowsTemplating: boolean): Promise<IResult<ApplicationModel>> => {
    return sendPut(`/application/${applicationId}/toggleAllowsTemplate`, {
        isTemplate: allowsTemplating
    })
}

export const getAssistantApplication = (applicatonId: string) : Promise<IResult<AssistantApplicationModel>> => {
    return sendGet(`/application/${applicatonId}/assistant`)
}

export const getFeatureFlags = () : Promise<IResult<FeatureFlagModel[]>> => {
    return sendGet(`/application/featureFlags`)
}