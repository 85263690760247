import SsmlObject from "../models/ssml/SsmlObject";
import SsmlElementRule from "../models/ssml/SsmlElementRule";
import SelectedSsmlResult from "../models/ssml/SelectedSsmlResult";

class SsmlParseService {
    parser = new DOMParser();
    getElementProperty(ssml: string, tag: string, property: string) {
        try {
            let xmlDoc = this.parser.parseFromString(ssml, 'text/xml');

            return xmlDoc.getElementsByTagName(tag)[0].attributes[property].value
        } catch {
            return null;
        }
    }

    ssmlToObject(ssml: string, supportedTags?: string[]) {
        if (!ssml.startsWith('<speak>')) ssml = `<speak>${ssml.trim()}</speak>`;
        let xmlDoc = this.parser.parseFromString(ssml, 'application/xml') as XMLDocument;
        var json = this.xmlToJson(xmlDoc.documentElement, supportedTags, true);
        return json
    }

    htmlToObject(html: string) {
        try {
            let xmlDoc = this.parser.parseFromString(html, 'text/html') as XMLDocument;
            var htmlObject = this.xmlToJson(xmlDoc.documentElement, null);
            var ssmlObject = this.convertHtmlObjectToSsmlObject(htmlObject);
            return ssmlObject
        } catch (e) {
            console.log(e)
        }
    }

    findFirstOfType(tree: SsmlObject, elementName: string): SsmlObject {
        if (tree.name.toLowerCase() == elementName.toLowerCase()) return tree;

        if (tree.children) {
            var found = null;
            for (var i = 0; i < tree.children.length; i++) {
                found = this.findFirstOfType(tree.children[i], elementName);
                if (found)
                    return found;
            }
        }

        return null;
    }

    getIsInvalidProsody(xml: any) : boolean{
        if(xml.nodeName.toLowerCase() == 'prosody') {
            return xml.attributes.length > 1; // we can't support multi-attributed prosody
        }

        return false;
    }

    getIsInvalidSayAs(xml: any) : boolean {
        if(xml.nodeName.toLowerCase() == 'say-as') {
            const validInterpretAs = ["characters", "spell-out", "cardinal", "number", "ordinal", "digits", "fraction", "unit", "date", "time", "telephone", "expletive", "bleep"];
            return !validInterpretAs.some(v => v == xml.attributes.item('interpret-as').nodeValue)
        }
        return false;
    }

    xmlToJson(xml: any, supportedTags?: string[], separateUnsupported?: boolean): SsmlObject {
        var obj = {
            children: [],
            attributes: [],
            id: this.uuidv4()
        } as SsmlObject;

        if (xml.nodeType == 1) { // element
            // do attributes
            if (!supportedTags || supportedTags.some(t => t == xml.nodeName) && !this.getIsInvalidSayAs(xml) && !this.getIsInvalidProsody(xml)) {
                obj.name = xml.nodeName
                if (xml.attributes.length > 0) {
                    for (var j = 0; j < xml.attributes.length; j++) {
                        var attribute = xml.attributes.item(j);
                        obj.attributes.push({
                            name: attribute.nodeName,
                            value: attribute.nodeValue
                        });
                    }
                }
            }
            else {
                obj.name = "plain-text";
                obj.isUnsupportedElement = true;
                obj.value = this.elementToString(xml, true);
            }
        } else if (xml.nodeType == 3) { // text
            obj.name = "plain-text";
            obj.value = xml.nodeValue;
        }

        // do children
        if (xml.hasChildNodes()) {
            for (var i = 0; i < xml.childNodes.length; i++) {
                var item = xml.childNodes.item(i);
                obj.children.push(this.xmlToJson(item, supportedTags))
            }
        }
        return obj;
    };

    getElementPropertyValue(ssml: string, tag: string, property: string) {
        try {
            let xmlDoc = this.parser.parseFromString(ssml, 'text/xml');

            return xmlDoc.getElementsByTagName(tag)[0].attributes[property].value
        } catch {
            return null;
        }
    }

    convertToStringWithSelection(
        ssmlObject: SsmlObject,
        current: SelectedSsmlResult,
        builtSsml: string,
        selectedStartId: string,
        selectedStartOffset?: number,
        selectedEndId?: string,
        selectedEndOffset?: number): SelectedSsmlResult {
        if (current == null) {
            current = {
                ssml: ''
            }
        }
        var output = '';
        const rule = this.getDisplayedRule(ssmlObject);
        if (ssmlObject.name == "plain-text") {
            output = ssmlObject.value;
            if (ssmlObject.id == selectedStartId || !selectedStartId) {
                current.startIndex = builtSsml.replace('<speak>', '').length + selectedStartOffset;
            }
            if (ssmlObject.id == selectedEndId || !selectedEndId) {
                current.endIndex = builtSsml.replace('<speak>', '').length + selectedEndOffset;
            }
        }

        else if (ssmlObject.children.length > 0 || (rule && rule.hasChildren)) {
            output += `<${ssmlObject.name}${ssmlObject.attributes.length > 0 ? ' ' + ssmlObject.attributes.map(a => a.name + '=' + '"' + a.value + '"').join(' ') : ''}>`;
            ssmlObject.children.forEach(c => {
                output += this.convertToStringWithSelection(c, current, builtSsml + output, selectedStartId, selectedStartOffset, selectedEndId, selectedEndOffset).ssml;
            })
            output += `</${ssmlObject.name}>`;
        }
        else if (ssmlObject.children.length == 0) {
            output += `<${ssmlObject.name}${ssmlObject.attributes.length > 0 ? ' ' + ssmlObject.attributes.map(a => a.name + '=' + '"' + a.value + '"').join(' ') : ''}/>`;
        }
        output = output.replace('<speak>', '').replace('</speak>', '').replace('<speak/>', '').replace('&nbsp;', ' ');
        current.ssml = output;
        return current;
    }
    convertToString(ssmlObject: SsmlObject) {
        var output = '';
        const rule = this.getDisplayedRule(ssmlObject);
        if (ssmlObject.name == "plain-text") {
            output = ssmlObject.value;
        }
        else if (ssmlObject.children.length > 0 || (rule && rule.hasChildren)) {
            output += `<${ssmlObject.name}${ssmlObject.attributes.length > 0 ? ' ' + ssmlObject.attributes.map(a => a.name + '=' + '"' + a.value + '"').join(' ') : ''}>`;
            ssmlObject.children.forEach(c => {
                output += this.convertToString(c);
            })
            output += `</${ssmlObject.name}>`;
        }
        else if (ssmlObject.children.length == 0) {
            output += `<${ssmlObject.name}${ssmlObject.attributes.length > 0 ? ' ' + ssmlObject.attributes.map(a => a.name + '=' + '"' + a.value + '"').join(' ') : ''}/>`;
        }
        output = output.replace('<speak>', '').replace('</speak>', '').replace('<speak/>', '').replace('&nbsp;', ' ');
        return output;
    }

    convertHtmlObjectToSsmlObject(htmlObject: SsmlObject): SsmlObject {
        var htmlRoot = this.findFirstOfType(htmlObject, 'body');
        if (!htmlRoot) {
            return null;
        }

        var root: SsmlObject = {
            id: '',
            name: 'speak',
            children: [],
            attributes: [],
        };

        // start converting
        htmlRoot.children.forEach(h => {
            var converted = this.mapSpanToSsml(h, root);
            if (converted) root.children.push(converted);
        });
        return root;

    }

    rotateMutableIds(tree: SsmlObject) {
        tree.id = this.uuidv4();
        if (tree.children.length > 0) {
            tree.children.forEach(n => this.rotateMutableIds(n))
        }
        return tree;
    }

    mapSpanToSsml(htmlObject: SsmlObject, parentObject: SsmlObject): SsmlObject {
        if (!htmlObject || htmlObject.attributes.some(a => a.name == 'data-ignore')) return null;

        // just straight plain text
        if (htmlObject.name == "plain-text") {
            var plainTextObject = htmlObject;
            plainTextObject.id = this.uuidv4();
            return plainTextObject;
        }
        // contains plain text
        if (htmlObject.name.toLowerCase() == "span"
            && !htmlObject.attributes.some(a => a.name == "data-ssml-element")
            && htmlObject.children.some(c => c.name == 'plain-text' || c.name.toLowerCase() == "br")) {
            const plainTextObject = htmlObject.children.find(c => c.name == 'plain-text');
            if (plainTextObject) {
                var idAttribute = htmlObject.attributes.find(a => a.name == 'id')
                plainTextObject.id = idAttribute ? idAttribute.value : this.uuidv4();
                if (!htmlObject.attributes.some(a => a.name == 'id')) {
                    plainTextObject.value = plainTextObject.value;
                }
                return plainTextObject;
            }
            const breakObject = htmlObject.children.find(c => c.name.toLowerCase() == "br");
            if (breakObject) {
                return {
                    id: this.uuidv4(),
                    attributes: [],
                    value: "\n",
                    children: [],
                    name: "plain-text"
                }
            }
        }
        else if (htmlObject.name.toLowerCase() == "br") {
            return {
                id: this.uuidv4(),
                attributes: [],
                value: "\n",
                children: [],
                name: "plain-text"
            }
        }
        else if (htmlObject.name.toLowerCase() == "span" && htmlObject.attributes.some(a => a.name == "data-ssml-element")) {
            const ssmlObject = {
                children: [],
                attributes: [],
                id: this.uuidv4()
            } as SsmlObject;

            ssmlObject.name = htmlObject.attributes.find(a => a.name == 'data-ssml-element').value;
            ssmlObject.id = htmlObject.attributes.find(a => a.name == 'id').value;
            if (htmlObject.attributes && htmlObject.attributes.some(a => a.name == 'data-ssml-attributes')) {
                ssmlObject.attributes = JSON.parse(htmlObject.attributes.find(a => a.name == 'data-ssml-attributes').value.replace(/&quot;/g, '"'));
            }

            htmlObject.children.forEach(h => {
                var converted = this.mapSpanToSsml(h, ssmlObject);
                if (converted) ssmlObject.children.push(converted);
            });
            return ssmlObject;
        }
        else if (htmlObject.name.toLowerCase() == "div" && !htmlObject.attributes || !htmlObject.attributes.some(a => a.name == "data-ignore")) {
            // new empty lines are <div><span><br></span></div> so lets skip the div and go right to the span
            // new lines with content are <div><span>text</span></div>

            htmlObject.children.forEach(h => {
                var converted = this.mapSpanToSsml(h, parentObject);
                if (converted) parentObject.children.push(converted);
            });

            return null;
        }

        return null;
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    stripTags(input: string) : string {
        const sourceArray =  [...input];
        let output = "";
        let inside: boolean = false;
        for(let i = 0; i < sourceArray.length; ++i) {
            let c = sourceArray[i];
            if (c === '<')
            {
                inside = true;
                continue;
            }
            if (c == '>')
            {
                inside = false;
                continue;
            }
            if (!inside)            
                output += c;            
        }
        return output;
    }

    elementToString(who, deep) {
        if (!who || !who.tagName) return '';
        var txt, ax, el = document.createElement("div");
        el.appendChild(who.cloneNode(false));
        txt = el.innerHTML;
        if (deep) {
            ax = txt.indexOf('>') + 1;
            txt = txt.substring(0, ax) + who.innerHTML + txt.substring(ax);
        }
        el = null;
        return txt;
    }
    findDelete(ssmlObject: SsmlObject, parent: SsmlObject, liftChildren: boolean) {
        if (ssmlObject.id == ssmlObject.id) {
            if (liftChildren && ssmlObject.children && ssmlObject.children.length > 0) {
                // replace position of children too
                parent.children.splice(parent.children.indexOf(ssmlObject), 1, ...ssmlObject.children)
            }
            else {
                parent.children.splice(parent.children.indexOf(ssmlObject), 1);
            }
            return ssmlObject;
        } else if (ssmlObject.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < ssmlObject.children.length; i++) {
                result = this.findDelete(ssmlObject.children[i], ssmlObject, liftChildren);
            }
            return result;
        }
    }

    getDisplayedSsmlRules(): SsmlElementRule[] {
        // gets some general rules for different ssml elements
        return [{
            name: 'prosody',
            attribute: 'rate',
            unit: '%',
            max: 200,
            min: 20,
            hasChildren: true
        }, {
            name: 'prosody',
            attribute: 'volume',
            unit: 'dB',
            max: 4,
            min: -4,
            hasChildren: true
        }, {
            name: 'prosody',
            attribute: 'pitch',
            unit: '%',
            max: 50,
            min: -33,
            hasChildren: true
        }, {
            name: 'break',
            attribute: 'time',
            unit: 's',
            max: 2,
            min: 0.25,
            hasChildren: false
        }, {
            name: 'emphasis',
            attribute: 'level',
            unit: '',
            max: 3, // mapped to string 'strong'
            min: 1, // mapped to string 'reduced'
            hasChildren: true
        }, {
            name: 'audio',
            attribute: 'src',
            unit: '',
            hasChildren: true
        }, {
            name: 'say-as',
            attribute: 'interpret-as',
            unit: '',
            hasChildren: true
        }]
    }
    getDisplayedRule(ssmlObject: SsmlObject) {
        return this.getDisplayedSsmlRules().find(r => r.name == ssmlObject.name && ssmlObject.attributes.length > 0 && r.attribute == ssmlObject.attributes[0].name);

    }
    getRawAttributeValue(ssmlObject: SsmlObject): number {
        var value: number = null;
        var rule = this.getDisplayedRule(ssmlObject);
        if (ssmlObject.name == 'prosody' || ssmlObject.name == 'break') {
            value = Number.parseFloat(ssmlObject.attributes[0].value);
        }
        else if (ssmlObject.name == 'emphasis') {
            switch (ssmlObject.attributes[0]?.value) {
                case 'strong': value = 3;
                    break;
                case 'moderate': value = 2;
                    break;
                default: value = 1;
            }
        }

        if (rule) {
            if (value < rule.min || value > rule.max) {
                value = null;
            }
        }

        return value;
    }
}


export default SsmlParseService