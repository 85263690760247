import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue_10, silver_two, pale_grey } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';

// component for the bottom of the page as a fixed footer.
// used for content edit / add scenes
interface FooterBarProps {
    small?: boolean,
    className?: string
    usedInModal?: boolean
}

const FooterBar: React.FC<FooterBarProps> = (props) => (
    <div className={`footer-bar ${footerStyle} ${props.usedInModal ? modalFooterStyle : ''} ${props.small ? 'small' : ''} ${props.className ?? ''}`}>
        {props.children}
    </div>
);

const modalFooterStyle = css`
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
`;

const footerStyle = css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 110;
    height: 80px;
    box-shadow: 0 4px 20px 0 ${dark_grey_blue_10};
    border-top: solid 1px ${silver_two};
    background-color: ${pale_grey};
    &.small {
        height: 70px;
    }
    ${breakpoint_small} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`

export default FooterBar;