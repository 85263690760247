import IResult from "../../models/result/IResult";
import { AxiosResponse } from 'axios';
import axios from 'axios';
import SuccessResult from "../../models/result/SuccessResult";
import InvalidResult from "../../models/result/InvalidResult";
import UnauthorizedResult from "../../models/result/UnauthorizedResult";
import UnexpectedResult from "../../models/result/UnexpectedResult";
import { getAnalyticsWebsocketApiUrl, getBaseApiUrl } from "../../constants/Urls";
import { VOICIFY_ACCESS_TOKEN_STORE } from "../../constants/Keys";
import NotFoundResult from "../../models/result/NotFoundResult";

// generic http method for interacting with the voicify api
export const sendPost = async <T>(url: string, body: any, baseUrl?: string, tokenOverride: string = null): Promise<IResult<T>> => {
    const fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url;
    try {
        setToken(tokenOverride);
        const response = await axios.post<T | string[]>(fullUrl, body);
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};

export const sendPostForFile = async (url: string, body: any, baseUrl?: string): Promise<IResult<Blob>> => {
    const fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url;
    try {
        setToken();
        const response = await axios.post<Blob | string[]>(fullUrl, body, {
            responseType: 'blob'
        });
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};

export const uploadFile = async <T>(url: string, file: File, baseUrl?: string): Promise<IResult<T>> => {
    const fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url;
    try {
        setToken();
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post<T | string[]>(fullUrl, formData);
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};

export const putFile = async <T>(url: string, file: File, progressCallback?: (number) => void): Promise<IResult<T>> => {
    try {
        const instance = axios.create();
        instance.defaults.headers.common = {};
        const response = await instance.put(url, file, {
            headers: {
                'Content-Type': getFileType(file.type),
            },
            onUploadProgress: (progressEvent) => progressCallback(Math.floor((progressEvent.loaded * 100) / progressEvent.total))
        });
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};


const getFileType = (fileType: string) => {
    // override file type for content type since certain browsers just make up their own rules
    if (fileType == "audio/mpeg") return "audio/mp3";
    return fileType;
}


export const sendGet = async <T>(url: string, baseUrl?: string): Promise<IResult<T>> => {
    const fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url;
    try {
        setToken();
        const response = await axios.get<T | string[]>(fullUrl);
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};

export const connectWebsocket = async <T>(url: string, baseUrl?: string): Promise<WebSocket> => {
    const fullUrl = (baseUrl === undefined ? getAnalyticsWebsocketApiUrl() : baseUrl) + url;
    try {
        let accessToken = sessionStorage.getItem(VOICIFY_ACCESS_TOKEN_STORE);
        const ws = new WebSocket(fullUrl, ["voicify-client", `bearer-${accessToken}`]);
        return ws;
    }
    catch (error) {
        return null;
    }
};


export const sendGetFile = async (url: string, baseUrl?: string): Promise<IResult<Blob>> => {
    const fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url;
    try {
        setToken();
        const response = await axios.get<Blob | string[]>(fullUrl, {
            responseType: 'blob'
        });
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};

export const sendPut = async <T>(url: string, body?: any, baseUrl?: string): Promise<IResult<T>> => {
    const fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url;
    try {
        setToken();
        const response = await axios.put<T | string[]>(fullUrl, body);
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};

export const sendDelete = async <T>(url: string, baseUrl?: string): Promise<IResult<T>> => {
    const fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url;
    try {
        setToken();
        const response = await axios.delete(fullUrl);
        return handleResponse(response);
    }
    catch (error) {
        return handleResponse(error.response);
    }
};

export const queryStringSerialize = (model: any): string => {
    const stringArray = [];
    for (const property in model) {
        if (model.hasOwnProperty(property) && model[property] != null) {
            stringArray.push(encodeURIComponent(property) + "=" + encodeURIComponent(model[property]));
        }
    }

    return stringArray.join("&");
}

const setToken = (tokenOverride = null) => {
    // pull token from local store
    let accessToken = sessionStorage.getItem(VOICIFY_ACCESS_TOKEN_STORE);
    if (tokenOverride?.length) {
        accessToken = tokenOverride;
    }
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    // set to default header
    if (accessToken !== undefined) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
}

// maps an axios response to a result
const handleResponse = <T>(response: AxiosResponse<T | string[]>): IResult<T> => {
    if (!response)
        return new UnexpectedResult();
    if (response?.status === 200 || response?.status === 204) {
        if (response.data === undefined) {
            return new SuccessResult(null);
        }
        return new SuccessResult(response.data as T);
    }
    else if (response?.status === 400) {
        const errors = response.data as string[];
        let error = errors[0];
        if (error == undefined) {
            const attributeErrorNames = Object.getOwnPropertyNames(errors)
            if (attributeErrorNames[0] != undefined && errors[attributeErrorNames[0]] != null) {
                error = errors[attributeErrorNames[0]];
            }
            else {
                error = response.data["Responses[0].Content"][0]
            }
        }
        return new InvalidResult(error);
    }
    else if (response?.status === 404) {
        return new NotFoundResult();
    }
    else if (response?.status === 403) {
        return new UnauthorizedResult();
    }

    return new UnexpectedResult();
}