import { withFormik } from "formik";
import * as yup from 'yup';
import OrganizationInformationForm, { OrganizationInformationFormProps, OrganizationInformationFormData } from "../../formScenes/OrganizationInformationForm";

const UpdateOrganizationForm = withFormik<OrganizationInformationFormProps, OrganizationInformationFormData>({
    mapPropsToValues: props => ({
        name: props.orgContainer.state.currentOrganization.name ? props.orgContainer.state.currentOrganization.name : '',
        description: props.orgContainer.state.currentOrganization.description ? props.orgContainer.state.currentOrganization.description : '',
    }),
    validationSchema: yup.object().shape({
        name: yup.string().required("You must enter a name for your organization").min(2, "You must enter a longer name"),
    }),
    handleSubmit: (values, { props }) => {
        props.orgContainer.updateOrganizationInfo({
            name: values.name,
            description: values.description
        })
    }
})(OrganizationInformationForm);

export default UpdateOrganizationForm;
