import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import { isAbsolute } from 'path';
import AuthenticationResponse from '../../models/auth/api/AuthenticationResponse';
import { VOICIFY_ACCESS_TOKEN_STORE, VOICIFY_REFRESH_TOKEN_STORE } from '../../constants/Keys';
import ExitMessageModel from '../../models/features/api/Exit/ExitMessageModel';
import NewExitMessageRequest from '../../models/features/api/Exit/NewExitMessageRequest';
import IResult from '../../models/result/IResult';
import UpdateExitMessageRequest from '../../models/features/api/Exit/UpdateExitMessageRequest';
import IContentWebhookContainer from '../definitions/IContentWebhookContainer';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import _ from 'lodash';
import WebhookParameterValue from '../../models/webhooks/api/WebhookParameterValue';
import { addOrReplace } from '../../models/extensions';
import IGenericContentContainer from '../definitions/IGenericContentContainer';
import WebhookModel from '../../models/webhooks/api/WebhookModel';
import GenericContentItemContainer, { ContentItemState } from './GenericContentItemContainer';
import { ExitMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import { EditExitMessageFormData } from '../../scenes/formScenes/ExitMessageForm';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ConversationItem from '../../models/conversationFlow/ConversationItem';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';

interface ExitMessageState extends ContentItemState<ExitMessageModel> {
}

export default class ExitMessageContainer extends GenericContentItemContainer<ExitMessageState, ExitMessageModel, NewExitMessageRequest, UpdateExitMessageRequest, EditExitMessageFormData> {
    getSimpleSampleFlows = (contentItem: ExitMessageModel, application?: ApplicationModel) => [{
        requests: [{ content: "Exit", highlights: [], id: "1" }, { content: "Cancel", highlights: [], id: "2" }],
        reprompt: null,
        followUp: null,
        responses: [{
            content: contentItem.content,
            highlights: [],
            id: "10",
        }],
        requestTitle: "User Says",
        responseTitle: "Device Response"
    }]
    // default state
    state = {
        contentItems: [],
        isLoading: false,
        errors: [],
        isSaving: false,
        isSavingStub: false,
        isPublishing: false,
        isLoadingWebhooks: false,
        isUpdatingWebhooks: false,
        isTogglingSync: false,
        attachedWebhooks: [] as WebhookInstanceModel[],
        hasWebhookUpdate: false,
        webhookUpdates: null
    }

    mapModelToGenericContentItemModel = (model: ExitMessageModel) => ({
        ...model,
        hasAudio: model.audio != null,
        hasVideo: model.video != null,
        hasImage: model.largeImage != null,
        hasBackgroundImage: model.backgroundImage != null,
        featureTypeId: ExitMessagesFeatureTypeId
    })

    mapFormDataToModel = (originalModel: ExitMessageModel, formData: EditExitMessageFormData) => ({
        ...originalModel,
        webhookChainingType: formData.webhookChainingType,
        title: formData.title,
        isLive: formData.isLive,
        requiresParent: formData.requiresParent,
        languages: formData.languages,
        responses: formData.responses,
        sensitiveConversationChange: formData.sensitiveConversationChange,
        disabledForFeatureFlagIds: formData.disabledForFeatureFlagIds,
        // we null these values out so they don't conflict with the responses field for variations        
        smallImage: null,
        largeImage: null,
        backgroundImage: null,
        audio: null,
        video: null,
        content: null,
        reprompt: null,
        followUp: null,
        mediaResponseContainer: null,
        mediaResponseContainerId: null,
        displayTitleOverride: null,
        displayTextOverride: null,
        followUpId: null,
        responseId: null
    });

    featureTypeId = ExitMessagesFeatureTypeId;
    getContentItemsByAppPromise = (applicationId: string) => voicifyApi.getExitMessagesForApp(applicationId);
    getContentItemsByAppModulePromise = (applicationModuleId: string) => null; // not implemented
    getContentItemsByAppFeaturePromise = (applicationFeatureId: string) => voicifyApi.getExitMessagesForAppFeature(applicationFeatureId);
    createPromise = (model: NewExitMessageRequest) => voicifyApi.addExitMessage(model.applicationId, model);
    createStubPromise = (model: NewExitMessageRequest) => voicifyApi.addExitMessage(model.applicationId, model);
    createFromModelPromise = (model: ExitMessageModel) => voicifyApi.addFullExitMessage(model);
    updatePromise = (contentItemId: string, model: UpdateExitMessageRequest) => voicifyApi.updateExitMessage(contentItemId, model);
    updateStubPromise = (contentItemId: string, model: UpdateExitMessageRequest) => voicifyApi.updateExitMessage(contentItemId, model);
    updateFromModelPromise = (model: ExitMessageModel) => voicifyApi.updateFullExitMessage(model.id, model);
    deletePromise = (contentItemId: string) => voicifyApi.deleteExitMessage(contentItemId);
    toggleLivePromise = (contentItemId: string, isLive: boolean) => voicifyApi.toggleExitMessageLive(contentItemId, isLive);
    toggleSyncPromise = (contentItemId: string, shouldNotSync: boolean) => voicifyApi.toggleExitMessageSync(contentItemId, shouldNotSync);
    copyPromise = (contentItemId: string) => voicifyApi.copyExistMessage(contentItemId);
    findFullByIdPromise = (contentItemId: string) => voicifyApi.findExitMessage(contentItemId);
    getPublishHistoryPromise = (contentItemId: any, environmentId: string, skip: number, take: number) => voicifyApi.getExitMessagePublishHistory(contentItemId, environmentId, skip, take);


    getWebhooksPromise = (contentItemId: string) => voicifyApi.getExitMessageWebhooks(contentItemId);
    removeWebhookPromise = (contentItemWebhookId: string) => voicifyApi.removeExitMessageWebhook(contentItemWebhookId);
    bulkUpdateWebhookInstancesPromise = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest) => voicifyApi.bulkUpdateExitMessageWebhookInstances(contentItemId, updateRequest);
    addWebhookPromise = (id: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.addExitMessageWebhook(id, webhookId, defaultParameters, userDefinedParameters);
    updateWebhookPromise = (id: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest) => voicifyApi.updateExitMessageWebhook(id, webhookInstanceId, defaultParameters, userDefinedParameters);
}
