import { GET_LATESTMESSAGES, GET_LATESTMESSAGES_FOR_MODULE, CREATE_LATESTMESSAGE } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import CustomRequestModel from '../../models/features/api/CustomRequests/CustomRequestModel';
import NewCustomRequestRequest from '../../models/features/api/CustomRequests/NewCustomRequestRequest';
import BulkUpdateCustomRequestRequest from '../../models/features/api/CustomRequests/BulkUpdateCustomRequestRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const getCustomRequestsForModule = (applicationModuleId: string): Promise<IResult<CustomRequestModel[]>> => {
    return sendGet<CustomRequestModel[]>(`/customRequest/module/${applicationModuleId}`);

}

export const getCustomRequestsForApp = (applicationId: string): Promise<IResult<CustomRequestModel[]>> => {
    return sendGet<CustomRequestModel[]>(`/customRequest/${applicationId}`);
}

export const getCustomRequestsForAppFeature = (applicationFeatureId: string): Promise<IResult<CustomRequestModel[]>> => {
    return sendGet<CustomRequestModel[]>(`/customRequest/byFeature/${applicationFeatureId}`);
}


export const createFullCustomRequest = (request: CustomRequestModel): Promise<IResult<CustomRequestModel>> => {
    return sendPost<CustomRequestModel>(`/customRequest/full`, request);
}
export const createCustomRequest = (request: NewCustomRequestRequest): Promise<IResult<CustomRequestModel>> => {
    return sendPost<CustomRequestModel>(`/customRequest/`, request);
}
export const createCustomRequestStub = (request: NewCustomRequestRequest): Promise<IResult<CustomRequestModel>> => {
    return sendPost<CustomRequestModel>(`/customRequest/stub`, request);
}

export const toggleCustomRequestLive = (customRequestId: string, isLive: boolean) : Promise<IResult<CustomRequestModel>> => {
    return sendPut<CustomRequestModel>(`/CustomRequest/${customRequestId}/isLive`, {
        isLive
    });
}

export const toggleCustomRequestSync = (customRequestId: string, shouldNotSync: boolean): Promise<IResult<CustomRequestModel>> => {
    return sendPut<CustomRequestModel>(`/CustomRequest/${customRequestId}/shouldNotSync`, {
        shouldNotSync
    });
}

export const updateFullCustomRequest = (customRequestId: string, request: CustomRequestModel): Promise<IResult<CustomRequestModel>> => {
    return sendPut<CustomRequestModel>(`/CustomRequest/${customRequestId}/full`, request);
}

export const bulkUpdateCustomRequest = (customRequestId: string, request: BulkUpdateCustomRequestRequest): Promise<IResult<CustomRequestModel>> => {
    return sendPut<CustomRequestModel>(`/CustomRequest/${customRequestId}/bulkUpdate`, request);
}

export const bulkUpdateCustomRequestStub = (customRequestId: string, request: BulkUpdateCustomRequestRequest): Promise<IResult<CustomRequestModel>> => {
    return sendPut<CustomRequestModel>(`/CustomRequest/${customRequestId}/bulkUpdate/stub`, request);
}
export const deleteCustomRequest = (customRequestId: string): Promise<IResult<CustomRequestModel>> => {
    return sendDelete<CustomRequestModel>(`/CustomRequest/${customRequestId}`);
}
export const findCustomRequest = (customRequestId: string): Promise<IResult<CustomRequestModel>> => {
    return sendGet<CustomRequestModel>(`/CustomRequest/full/${customRequestId}`);
}

export const getCustomRequestWebhooks = (customRequestId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/CustomRequest/${customRequestId}/webhooks`);
}

export const removeCustomRequestWebhook = (customRequestWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/CustomRequest/webhook/${customRequestWebhookId}`);
}

export const addCustomRequestWebhook = (customRequestId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/CustomRequest/${customRequestId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateCustomRequestWebhook = (questionAnswerId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/CustomRequest/${questionAnswerId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const copyCustomRequest = (contentItemId: string): Promise<IResult<CustomRequestModel>> => {
    return sendPost<CustomRequestModel>(`/CustomRequest/${contentItemId}/copy`, null);
}

export const bulkUpdateCustomRequestWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/CustomRequest/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getCustomRequestPublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/CustomRequest/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}