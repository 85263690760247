import React from 'react';
import FilterSelector from './FilterSelector';
import FilterOption from './FilterOption';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
const assistantIcon = require('../../../content/images/analytics-icons/assistant.svg');
interface AssistantTypeFilterProps {
    selectedAssistants: string[]
    assistants: {name: string, iconUrl: string}[]
    onChange: (selectedAssistants: string[]) => void
}

class AssistantFilter extends React.Component<AssistantTypeFilterProps> {
    handleToggleAssistantType(selectedAssistant: string) {
        var selectedAssistants = this.props.selectedAssistants ?? []
        if (selectedAssistants.some(l => l == selectedAssistant)) {
            selectedAssistants.splice(selectedAssistants.indexOf(selectedAssistant), 1);
        }
        else {
            selectedAssistants.push(selectedAssistant);
        }

        this.props.onChange(selectedAssistants);
    }
    handleClear() {
        this.props.onChange(null);
    }

    render() {
        const props = this.props;
        const selectedText = props.assistants.filter(f => props.selectedAssistants?.some(id => id == f.name))?.map(f => f.name)?.join(', ');
        return (
            <FilterSelector label="Platform"
                icon={assistantIcon}
                placeholder={'All Assistant Platforms'}
                selectedText={selectedText}
                className="ac-analytics-platform-filter"
                onClear={this.handleClear.bind(this)}>
                <CustomScrollbars autoHide autoHeight autoHeightMax={400}>
                    <FilterOption title="- Select All / None -"
                        onClick={() => props.onChange([])}
                        isSelected={!props.selectedAssistants}
                        isPartialSelected={true}
                        optionType="check" />
                    {props.assistants.map(f => (
                        <FilterOption key={f.name} title={f.name}
                            icon={f.iconUrl}
                            selectedIcon={f.iconUrl}
                            isSelected={props.selectedAssistants?.some(id => id == f.name)}
                            optionType="check"
                            onClick={() => this.handleToggleAssistantType(f.name)} />
                    ))}
                </CustomScrollbars>
            </FilterSelector>
        )
    }
}

export default AssistantFilter;

