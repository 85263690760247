import React, { useEffect, useMemo, useState } from 'react';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import SessionAnalyticsContainer, { SessionListModel } from '../../../hooks/SessionAnalyticsContainer';
import styled from '@emotion/styled';
import { Dictionary } from 'lodash';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import ContentHitEvent from '../../../models/analytics/api/ContentHitEvent';
import CustomAssistantResponse from '../../../models/customAssistant/CustomAssistantResponse';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import useWindowSize from '../../../hooks/UseWindowSize';
import Loader from '../../../components/general/Loader';
import SimulatorConversationHistoryItem from '../../simulator/components/SimulatorConversationHistoryItem';
import IGenericContentContainer from '../../../state/definitions/IGenericContentContainer';
import ContentItemModel from '../../../models/features/api/ContentItemModel';
import SimulatorContainer from '../../../hooks/SimulatorContainer';
import moment from 'moment';
import { color_shades_dark, color_shades_darkest, color_shades_lighter } from '../../../constants/colors';
import { SESSION_DATE_FORMAT } from '../../../models/analytics/AnalyticsDateFormat';
import ConversationTurn from '../../../models/sessionAttributes/ConversationTurn';
import qs from 'query-string';
import SearchField from '../../../components/forms/SearchField';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import CustomAssistantRequest from '../../../models/customAssistant/CustomAssistantRequest';
import SessionReview from './SessionReview';
import CodeOutputModal from '../../simulator/components/CodeOutputModal';
import SparkContainer from '../../../hooks/SparkContainer';
import useIsMobile from '../../../hooks/UseIsMobile';
import CollapsingPanel from '../../../components/structure/Panels/CollapsingPanel';
import CollapsedMenuPanel from '../../../components/structure/CollapsedMenuPanel';
import ExpandedMenuPanel from '../../../components/structure/ExpandedMenuPanel';
import { breakpoint_small } from '../../../constants/breakpoints';
import ContentAnalytics from '../../contentAnalytics';
import ContentContainer from '../../../hooks/ContentContainer';

interface ApplicationSessionAnalyticsProps {
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string,
    ssmlContainer: SsmlContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    contentContainers: Dictionary<IGenericContentContainer<ContentItemModel, any, any, any>>
    history: any
    envContainer: ApplicationEnvironmentsContainer
}

const ApplicationSessionAnalytics = (props: ApplicationSessionAnalyticsProps) => {
    const [selectedSession, setSelectedSession] = useState("");
    const [sessionToLoad, setSessionToLoad] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [contentHits, setContentHits] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([] as SessionListModel[]);
    const [loaded, setLoaded] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [eventsToShow, setEventsToShow] = useState(null as any);
    const [endDate, setEndDate] = useState("");
    const [detailedAnalyticsEnabled, setDetailedAnalyticsEnabled] = useState(true);
    const [searchFieldValue, setSearchFieldValue] = useState('');
    const [isLeftExpanded, setIsLeftExpanded] = useState<boolean>(true);

    const simulatorContainer = SimulatorContainer.useContainer();
    const sessionContainer = SessionAnalyticsContainer.useContainer();
    const sparkContainer = SparkContainer.useContainer();
    const contentContainer = ContentContainer.useContainer();
    const windowSize = useWindowSize();
    const isMobile = useIsMobile();

    useEffect(() => {
        simulatorContainer.startNewSession();
        initialLoad();
    }, [])

    useEffect(() => {
        if (selectedSession?.length
            && selectedSession in sessionContainer.events
            && (!allEvents.length || allEvents[0].sessionId !== selectedSession)) {
            const newEvents = sessionContainer.events[selectedSession];
            // sort by event date
            newEvents.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime());
            setAllEvents(newEvents);
        }
    }, [sessionContainer.events, selectedSession])

    useEffect(() => {
        const contentHits = allEvents?.filter(e => e.eventType === "ContentHit");
        // group by req id
        const grouped = contentHits.reduce((acc, hit) => {
            if (!acc[hit.requestId]) {
                acc[hit.requestId] = [];
            }
            acc[hit.requestId].push(hit);
            return acc;
        }, {} as Dictionary<ContentHitEvent[]>);
        // take last hit of each group
        const lastHits = Object.values(grouped).map((hits: []) => hits[hits.length - 1]);
        if (lastHits.length) {
            setSessionToLoad(lastHits);
            setContentHits(lastHits);
        }
    }, [allEvents])

    useEffect(() => {
        if (sessionToLoad.length) {
            loadSessionTurn();
        }
    }, [sessionToLoad])

    useEffect(() => {
        let newFilteredSessions = sessionContainer.sessions;
        const query = qs.parse(window.location.search);
        if (query?.contentId?.length) {
            contentContainer.getSession(props.applicationId, query.contentId as string, props.filter).then(sessionIds => {
                if (sessionIds) {
                    setFilteredSessions(sessionContainer.sessions.filter(s => sessionIds.includes(s.sessionId)));
                }
            });

            return;
        }

        if (searchFieldValue?.length) {
            newFilteredSessions = [];
            for (const session of sessionContainer.sessions) {
                if (session.sessionId.includes(searchFieldValue))
                    newFilteredSessions.push(session);
                else if (session?.phoneNumbers?.some(p => p.includes(searchFieldValue))) {
                    newFilteredSessions.push(session);
                }
            }
        }
        setFilteredSessions(newFilteredSessions);
    }, [sessionContainer.sessions, searchFieldValue])

    useEffect(() => {
        if (props.envContainer.state.currentAppId === props.applicationId
            && props.envContainer.state.environments?.length) {
            setDetailedAnalyticsEnabled(props.envContainer.state.environments?.[0]?.detailedAnalyticsEnabled ?? false);
        }
    }, [props.envContainer.state.environments])

    useEffect(() => {
        if (startDate === "" && props.filter.startDate) {
            setStartDate(props.filter?.startDate);
            setEndDate(props.filter?.endDate);
            return;
        }
        if (props.filter?.startDate !== startDate || props.filter?.endDate !== endDate) {
            setStartDate(props.filter?.startDate);
            setEndDate(props.filter?.endDate);
            simulatorContainer.startNewSession();
            sessionContainer.loadSessions(props.applicationId, props.filter);
        }
    }, [props.filter])

    useEffect(() => {
        const query = qs.parse(window.location.search);
        if (query?.sessionId?.length) {
            if (selectedSession === query.sessionId)
                return;
            if (sessionContainer.sessions.some(s => s.sessionId === query.sessionId as string)) {
                setSelectedSession(query.sessionId as string);
            }
        }
    }, [window.location.search, sessionContainer.sessions])

    useEffect(() => {
        if (selectedSession.length == 0) return;
        sessionContainer.loadEventsForSession(props.applicationId, selectedSession);
        setLoaded(true);
    }, [selectedSession]);

    const convertEventsForCodeOutputModal = (events: any[]) => {
        var returnObj = {};
        events?.forEach((e, idx) => {
            returnObj[`${idx}-${e.eventType}`] = e;
        })

        // get last event that is request received
        const lastContentHit = events?.slice()?.reverse()?.find(e => e.eventType === "ContentHit");
        if (lastContentHit?.originalRequest?.sessionAttributes) {
            returnObj[`${events.length}-LatestSessionAttributes`] = lastContentHit.originalRequest.sessionAttributes;
        }

        return returnObj;
    }

    const memoizedModal = useMemo(
        () => (
            <CodeOutputModal
                title="JSON"
                closeModal={() => setEventsToShow(null)}
                data={convertEventsForCodeOutputModal(eventsToShow)}
                showCodeOutputModal={eventsToShow}
            />
        ),
        [eventsToShow],
    );

    const loadSessionTurn = async () => {
        const hit = sessionToLoad[0];
        const newResponses = [];
        newResponses.push(mapContentHitToResponse(hit))
        // we're adding the hit to the simulator container so that we can use the simulator to render the conversation
        await simulatorContainer.updateConversationHistory({
            id: hit.sessionId,
            sessionAttributes: {
                ...(hit.originalRequest?.sessionAttributes ?? {}),
                ...(hit.response?.additionalSessionAttributes ?? {})
            }
        },
            newResponses,
            props.contentContainers,
            mapContentHitToRequest(hit),
            mapContentHitToConversationTurn(hit)
        )
        setSessionToLoad(sessionToLoad.slice(1));
    }

    const mapContentHitToResponse = (hit: ContentHitEvent): CustomAssistantResponse => {
        let resposneContent = hit.response?.data?.content;
        if (hit?.response?.followUp?.content?.length)
            resposneContent = `${resposneContent} ${hit?.response?.followUp?.content}`;
        if (hit)
            return {
                responseId: hit.response?.data?.responseId,
                ssml: resposneContent,
                outputSpeech: resposneContent,
                displayText: resposneContent,
                displayTitle: resposneContent,
                endSession: false,
                hints: hit.response?.followUp?.followupHints ?? [],
                sessionAttributes: hit.originalRequest?.sessionAttributes ?? {},
                nlp: hit.originalRequest?.nlp
            }
    }

    const mapContentHitToRequest = (hit: ContentHitEvent): CustomAssistantRequest => {
        let responseContent = hit.response?.data?.content;
        if (hit?.response?.followUp?.content?.length)
            responseContent = `${responseContent} ${hit?.response?.followUp?.content}`;
        if (hit)
            return hit?.originalRequest?.nativeRequest;
        else
            return {
                requestId: hit.requestId,
                context: {
                    sessionId: hit.sessionId,
                    noTracking: false,
                    requestType: hit.originalRequest?.requestType,
                    originalInput: hit.originalRequest?.originalInput,
                    channel: hit.originalRequest?.channel ?? "",
                    requiresLanguageUnderstanding: hit.originalRequest?.nativeRequest?.requiresLanguageUnderstanding ?? true,
                    locale: hit.originalRequest?.locale,
                },
                device: null,
                user: null
            };
    }

    const initialLoad = async () => {
        const envsResult = await props.envContainer.loadEnvironments(props.applicationId);
        if (envsResult.resultType !== 'Ok') {
            setDetailedAnalyticsEnabled(false);
            setLoaded(true);
            return;
        }
        const enabled = envsResult.data[0]?.detailedAnalyticsEnabled ?? false;
        setDetailedAnalyticsEnabled(enabled);
        if (!enabled) {
            setLoaded(true);
            return;
        }
        const query = qs.parse(window.location.search);
        sessionContainer.loadSessions(props.applicationId, props.filter);
        setLoaded(true);
    }

    const mapContentHitToConversationTurn = (hit: ContentHitEvent): ConversationTurn => {
        return {
            ContentId: hit?.response?.contentItem?.contentItemId,
            FeatureTypeId: hit?.featureTypeId,
            ApplicationId: props.applicationId,
            FollowUpId: hit?.response?.followUp?.id,
            HelpMessageId: '',
            FallbackMessageId: '',
            ChildTurns: [],
            IsLimitedToChildren: hit?.response?.followUp?.isLimitedToChildren,
            RequestDate: new Date(hit?.eventDate),
        }
    };

    const updateFilterToBeginningAndEndOfDay = (filter) => {
        const newStartDate = new Date(filter.startDate);
        newStartDate.setUTCHours(0, 0, 0, 0);
        const newEndDate = new Date(filter.endDate);
        newEndDate.setUTCHours(23, 59, 59, 999);
        return {
            ...filter,
            startDate: newStartDate.toISOString(),
            endDate: newEndDate.toISOString()
        }
    };

    const handleToggleExpandLeft = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, expanded: boolean) => {
        setIsLeftExpanded(expanded);
        localStorage.setItem("contentMenu", expanded?.toString());
        e?.stopPropagation();
    };

    const renderViewerWrapper = () => {
        return (
            <ViewerWrapper>
                {
                    sessionContainer.loadingEvents ? <Loader /> :
                        allEvents?.length ?
                            <SessionReviewContainer>
                                <SessionReview
                                    manualHeight={(windowSize.windowSize.innerHeight) - 250}
                                    events={allEvents}
                                    applicationId={props.applicationId}
                                    hideBorder
                                    simple={sessionContainer.simpleView}
                                    setEventsToShow={setEventsToShow}
                                    resetCopyButtonText={() => null}
                                />
                            </SessionReviewContainer>
                            :
                            <>
                                <EmptyConversationText>
                                    <h4>Select a session</h4>
                                    <p>to view the conversation</p>
                                </EmptyConversationText>
                                <Loader static />
                            </>
                }
            </ViewerWrapper>
        )
    };

    const renderSessionList = () => {
        return (
            <SessionListWrapper>
                <CustomScrollbars
                    autoHide
                    autoHeight
                    autoHeightMin={(windowSize.windowSize.innerHeight) - 200}
                    autoHeightMax={(windowSize.windowSize.innerHeight) - 200}>
                    <SessionList>
                        <SessionsHeader>
                            {sparkContainer.inSpark ? <LeftHeader>{`${filteredSessions.length} CALLS`}</LeftHeader> : <LeftHeader>{`${filteredSessions.length} SESSIONS`}</LeftHeader>}
                        </SessionsHeader>
                        <StyledSearchField
                            id="searchField"
                            name={"searchField"}
                            placeholder={"Session or Content Item ID"}
                            value={searchFieldValue}
                            disabled={!loaded}
                            onChange={(e) => setSearchFieldValue(e.target.value)}
                            boxType={"square"}
                        />
                        {
                            filteredSessions.map((session, idx) => {
                                return <SessionPill
                                    className={`${(window.location.search?.includes(session.sessionId)) ? "selected" : ''} ${(idx === Object.keys(filteredSessions).length - 1) ? "last" : ''}`}
                                    key={session.sessionId}
                                    onPointerDown={(e) => {
                                        if (window.location.search?.includes(session.sessionId)) return;
                                        simulatorContainer.startNewSession();
                                        const newFilter = updateFilterToBeginningAndEndOfDay(props.filter);
                                        props.history.push({
                                            search: `?sessionId=${session.sessionId}&startDate=${newFilter.startDate}&endDate=${newFilter.endDate}&interval=${props.interval}`
                                        })
                                        if (isMobile) {
                                            handleToggleExpandLeft(e, false)
                                        }
                                    }}
                                >
                                    {/* show local time of session, RDS update is now returning server time */}
                                    {moment(session.startDate).format(SESSION_DATE_FORMAT)}

                                </SessionPill>
                            })
                        }
                    </SessionList>
                </CustomScrollbars>
            </SessionListWrapper>
        )
    };

    const renderMenu = () => {
        return (
            renderSessionList()
        )
    };

    const renderCollapsingPanel = () => {
        return (
            <CollapsingPanel
                isCollapsed={!isLeftExpanded}
                collapsedView={
                    <CollapsedMenuPanel
                        onExpand={(e) => handleToggleExpandLeft(e, true)}
                        expandedViewRender={renderMenu}
                        minimizedDrawer={isMobile}
                    />
                }
                expandedView={
                    <ExpandedMenuPanel
                        onCollapse={(e) => handleToggleExpandLeft(e, false)}
                        expandedViewRender={renderMenu}
                        minimizedDrawer={isMobile}
                    />
                }
            />
        );
    };

    const renderMobileView = () => {
        return (
            <SectionWrapper>
                {renderCollapsingPanel()}
                {renderViewerWrapper()}
            </SectionWrapper>
        )
    };

    const renderDesktopView = () => {
        return (
            <SectionWrapper>
                {renderSessionList()}
                {renderViewerWrapper()}
                <HistoryListWrapper>
                    <CustomScrollbars autoHide autoHeight autoHeightMin={(windowSize.windowSize.innerHeight) - 200} autoHeightMax={(windowSize.windowSize.innerHeight) - 200}>
                        {simulatorContainer.contentItemsFromHistory?.length ? <HistoryHeader>{"CONVERSATION HISTORY"}</HistoryHeader> : null}
                        <HistoryList>
                            {simulatorContainer.contentItemsFromHistory.map((c, idx) =>
                                <SimulatorConversationHistoryItem
                                    key={idx}
                                    turn={mapContentHitToConversationTurn(contentHits[idx])}
                                    content={c} />
                            )}
                        </HistoryList>
                    </CustomScrollbars>
                </HistoryListWrapper>
                {memoizedModal}
            </SectionWrapper>
        )
    };

    return (
        <>
            {(!loaded || sessionContainer.loading || contentContainer.isLoading) ? (
                <Loader />
            ) : !detailedAnalyticsEnabled ? (
                <>
                    <EmptyConversationText>
                        <h4>Enable detailed analytics</h4>
                        <p>to view sessions</p>
                    </EmptyConversationText>
                    <Loader static />
                </>
            ) : (
                isMobile ? renderMobileView() : renderDesktopView()
            )}
        </>
    );

};

const SessionReviewContainer = styled.div``;

const SessionList = styled.div`
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
`;

const SectionWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const HistoryListWrapper = styled.div`
    height: 100%;
    width: 60%;
    max-width: 432px;
    min-width: 300px;
`;

const HistoryList = styled.div`
    padding: 16px;
`;

const SessionListWrapper = styled.div`
    max-width: 432px;
    min-width: 300px;
`;

const SessionPill = styled.div`
    height: 56px;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    background: white;
    margin: 0 24px 24px 24px;
    padding: 16px;
    cursor: pointer;
    &.selected {
        background: ${color_shades_lighter};
    }
    &.last {
        margin-bottom: 32px;
    }
`;

const StyledSearchField = styled(SearchField)`    
    margin: 0 24px 24px 24px;
`;

const ViewerWrapper = styled.div`
    height: calc(100vh - 200px);
    background: ${color_shades_lighter};
    width: 75%;
    ${breakpoint_small} {
        width: 100%;
        height: calc(100vh - 175px);
    }
`;

const SessionsHeader = styled.div`
    margin: 24px 24px 16px 24px;
    display: flex;
    flex-direction: row;
`

const LeftHeader = styled.div`
    margin-right: auto;
`;

const SessionsSubHeader = styled.div`
    margin: 0 24px 18px 24px;
    font-size: 12px;
    display: flex;
    flex-direction: row 
`;

const HistoryHeader = styled.div`
    margin: 24px 0px 0px 24px;
`

const EmptyConversationText = styled.div`
    color: ${color_shades_darkest}; 
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 60px 0 0 -95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export default ApplicationSessionAnalytics;