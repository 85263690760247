import React from 'react';
import { css } from '@emotion/css';
import { pale_grey, color_variants_ocean_light_opaque, color_shades_darker, color_shades_light, color_shades_lighter, color_gradients_blue_shift, color_gradients_green_shift } from '../../constants/colors';

interface CheckProps {
  checked: boolean
  partial?: boolean
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void
  disabled?: boolean
  checkType?: CheckType
  className?: string
}

export type CheckType = 'radio' | 'check'; // default 'check';

const getCheckClass = (props: CheckProps) => {
  var className = containerStyle;
  if (props.checkType == "radio")
    className += " radio";
  else
    className += " checkmark";

  if (props.checked)
    className += " selected";
  if (props.partial)
    className += " partial selected";
  if (props.disabled)
    className += " disabled";

  return className;
}

const CheckIndicator: React.SFC<CheckProps> = (props) => (
  <span onClick={props.onClick}
    className={`${getCheckClass(props)} ${props.className ?? ''}`}>
  </span>
);


const containerStyle = css`
    width: 20px;
    height: 20px;
    margin-right: 16px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
  position: relative;
  cursor: pointer;
  box-sizing: content-box;

/* On mouse-over, add a grey background color */
&:hover {
  background-color: ${color_variants_ocean_light_opaque};
}

/* When the checkbox is checked, add a blue background */
&.selected {
  background: white;
}

&.disabled {
    background: ${color_shades_lighter};
}

/* Create the checkmark/indicator (hidden when not checked) */
&.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


/* Style the checkmark/indicator */
&.checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;  
  border-image-source: linear-gradient(to top, #76c3b5, #3ea6ac, #5296b3);
  border-image-slice: 1;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

&.radio {
  border-radius: 50%;
}

&.radio:after {
  content: "";
  position: absolute;
  display: none;
}
&.radio:after {
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
  border-radius: 50%;
  background: ${color_gradients_green_shift};
}

/* Show the checkmark when checked */
&.selected:after {
  display: block;
}
&.partial:after {
  left: 5px;
  top: 9px;
  width: 10px;
  height: 2px;
  transform: none;
  border: none;
  background: linear-gradient(to top, #76c3b5, #3ea6ac, #5296b3);
  
}

&.disabled {
  background: ${color_shades_lighter};
  cursor: not-allowed;
}
`

export default CheckIndicator;