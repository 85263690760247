import IResult from "../../models/result/IResult";
import { sendPut, sendDelete, sendGet } from "./generic";
import UpdateAppliedApplicationTemplateFormRequest from "../../models/templating/api/UpdateAppliedApplicationTemplateFormRequest";
import AppliedTemplateSyncModel from "../../models/templating/api/AppliedTemplateSyncModel";
import CompleteTemplateFormModel from "../../models/templating/api/CompleteTemplateFormModel";

export const queueUpdateApplicationFromTemplateFormsSync = (applicationId: string, appliedApplicationTemplateId: string, request: UpdateAppliedApplicationTemplateFormRequest): Promise<IResult<AppliedTemplateSyncModel>> => {
    return sendPut(`/AppliedTemplateSync/${applicationId}/UpdateAppliedApplicationTemplateForm/${appliedApplicationTemplateId}`, request);
}

export const queueUpdateApplicationFromTemplateFormsDynamicSync = (applicationId: string, appliedTemplateFormId: string, request: UpdateAppliedApplicationTemplateFormRequest): Promise<IResult<AppliedTemplateSyncModel>> => {
    return sendPut(`/AppliedTemplateSync/${applicationId}/UpdateAppliedApplicationTemplateForm/${appliedTemplateFormId}/Dynamic`, request);
}

export const queueAddAppliedApplicationTemplateFormSync = (applicationId: string, appliedTemplateFormId: string, request: CompleteTemplateFormModel): Promise<IResult<AppliedTemplateSyncModel>> => {
    return sendPut(`/AppliedTemplateSync/${applicationId}/addAppliedApplicationTemplateForm/${appliedTemplateFormId}`, request);
}

export const queueAddAppliedApplicationTemplateFormDynamicSync = (applicationId: string, appliedTemplateFormId: string, request: CompleteTemplateFormModel): Promise<IResult<AppliedTemplateSyncModel>> => {
    return sendPut(`/AppliedTemplateSync/${applicationId}/addAppliedApplicationTemplateForm/${appliedTemplateFormId}/Dynamic`, request);
}

export const queueRemoveAppliedApplicationTemplateSync = (applicationId: string, appliedTemplateFormId: string): Promise<IResult<AppliedTemplateSyncModel>> => {
    return sendDelete(`/AppliedTemplateSync/${applicationId}/RemoveAppliedApplicationTemplateForm/${appliedTemplateFormId}`);
}

export const getAppliedTemplateSync = (appliedtemplateSyncId: string): Promise<IResult<AppliedTemplateSyncModel>> => {
    return sendGet(`/AppliedTemplateSync/${appliedtemplateSyncId}`);
}

export const getAppliedTemplateSyncsForApplication = (applicationId: string): Promise<IResult<AppliedTemplateSyncModel[]>> => {
    return sendGet(`/AppliedTemplateSync/${applicationId}/getAppliedTemplateSyncs`);
}