import axios from 'axios';
import { GET_WELCOMEMESSAGES, ADD_WELCOMEMESSAGE, GET_QUESTIONANSWER, ADD_QUESTIONANSWER } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { sendGet, sendPost, sendPut, sendDelete } from './generic';
import QuestionAnswerModel from '../../models/features/api/QuestionAnswers/QuestionAnswerModel';
import NewQuestionAnswerRequest from '../../models/features/api/QuestionAnswers/NewQuestionAnswerRequest';
import BulkQuestionAnswerUpdateRequest from '../../models/features/api/QuestionAnswers/BulkQuestionAnswerUpdateRequest';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParameterValue from '../../models/webhooks/api/WebhookParameterValue';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import WebhookInstanceRequest from '../../models/webhooks/api/WebhookInstanceRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import GenericContentItemPublishModel from '../../models/features/api/GenericContentItemPublishModel';

export const createQuestionAnswer = (request: NewQuestionAnswerRequest): Promise<IResult<QuestionAnswerModel>> => {
    return sendPost<QuestionAnswerModel>(ADD_QUESTIONANSWER, request);
}
export const createFullQuestionAnswer = (model: QuestionAnswerModel): Promise<IResult<QuestionAnswerModel>> => {
    return sendPost<QuestionAnswerModel>(`${ADD_QUESTIONANSWER}/full`, model);
}
export const createQuestionAnswerStub = (request: NewQuestionAnswerRequest): Promise<IResult<QuestionAnswerModel>> => {
    return sendPost<QuestionAnswerModel>(`${ADD_QUESTIONANSWER}/stub`, request);
}


export const toggleQuestionAnswerLive = (questionAnswerId: string, isLive: boolean): Promise<IResult<QuestionAnswerModel>> => {
    return sendPut<QuestionAnswerModel>(`/QuestionAnswer/${questionAnswerId}/isLive`, {
        isLive
    });
}

export const toggleQuestionAnswerSync = (questionAnswerId: string, shouldNotSync: boolean): Promise<IResult<QuestionAnswerModel>> => {
    return sendPut<QuestionAnswerModel>(`/QuestionAnswer/${questionAnswerId}/shouldNotSync`, {
        shouldNotSync
    });
}

export const getQuestionAnswersForApp = (applicationId: string): Promise<IResult<QuestionAnswerModel[]>> => {
    return sendGet<QuestionAnswerModel[]>(`${GET_QUESTIONANSWER}/${applicationId}/faqs`);
}

export const getQuestionAnswersForAppFeature = (applicationFeatureId: string): Promise<IResult<QuestionAnswerModel[]>> => {
    return sendGet<QuestionAnswerModel[]>(`${GET_QUESTIONANSWER}/byFeature/${applicationFeatureId}`);
}

export const updateFullQuestionAnswer = (questionAnswerId: string, model: QuestionAnswerModel): Promise<IResult<QuestionAnswerModel>> => {
    return sendPut<QuestionAnswerModel>(`/QuestionAnswer/${questionAnswerId}/full`, model);
}

export const bulkUpdateQuestionAnswer = (questionAnswerId: string, model: BulkQuestionAnswerUpdateRequest): Promise<IResult<QuestionAnswerModel>> => {
    return sendPut<QuestionAnswerModel>(`/QuestionAnswer/${questionAnswerId}/bulkUpdate`, model);
}

export const bulkUpdateQuestionAnswerStub = (questionAnswerId: string, model: BulkQuestionAnswerUpdateRequest): Promise<IResult<QuestionAnswerModel>> => {
    return sendPut<QuestionAnswerModel>(`/QuestionAnswer/${questionAnswerId}/bulkUpdate/stub`, model);
}
export const deleteQuestionAnswer = (questionAnswerId: string): Promise<IResult<QuestionAnswerModel>> => {
    return sendDelete<QuestionAnswerModel>(`/QuestionAnswer/${questionAnswerId}`);
}
export const copyQuestionAnswer = (questionAnswerId: string): Promise<IResult<QuestionAnswerModel>> => {
    return sendPost<QuestionAnswerModel>(`/QuestionAnswer/${questionAnswerId}/copy`, null);
}
export const findQuestionAnswer = (questionAnswerId: string): Promise<IResult<QuestionAnswerModel>> => {
    return sendGet<QuestionAnswerModel>(`/QuestionAnswer/full/${questionAnswerId}`);
}

export const getQuestionAnswerWebhooks = (questionAnswerId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/QuestionAnswer/${questionAnswerId}/webhooks`);
}

export const removeQuestionAnswerWebhook = (questionAnswerWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/QuestionAnswer/webhook/${questionAnswerWebhookId}`);
}

export const addQuestionAnswerWebhook = (questionAnswerId: string, webhookId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/QuestionAnswer/${questionAnswerId}/webhooks/${webhookId}`, { values: defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateQuestionAnswerWebhook = (questionAnswerId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/QuestionAnswer/${questionAnswerId}/webhooks/${webhookInstanceId}`, { values: defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const bulkUpdateQuestionAnswerWebhookInstances = (contentItemId: string, updateRequest: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/QuestionAnswer/${contentItemId}/webhooks/bulk`, updateRequest);
}

export const getQuestionAnswerPublishHistory = (contentItemId: any, environmentId: string, skip: number, take: number) :Promise<IResult<GenericContentItemPublishModel[]>> => {
    return sendGet(`/QuestionAnswer/${contentItemId}/publishes/forenvironment/${environmentId}?skip=${skip}&take=${take}`)
}