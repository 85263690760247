import React from 'react';
import { Tabs, Tab, TabList } from 'react-tabs';
import TabIndicator from './TabIndicator';
import { css } from '@emotion/css';
import { dark_sky_blue, ocean_blue, silver_two, blue_white, dark_grey_blue } from '../../constants/colors';
import { breakpoint_small } from '../../constants/breakpoints';
import SparkContainer from '../../hooks/SparkContainer';
import useIsMobile from '../../hooks/UseIsMobile';

interface TabbedLayoutProps {
    tabs: string[]
    top?: boolean
    selectedIndex?: number
    forceRenderTabPanel?: boolean
    onTabSelected?: (index: number, last: number) => void
}

const TabbedLayout: React.FC<TabbedLayoutProps> = (props) => {
    const sparkContainer = SparkContainer.useContainer();
    const inSpark = sparkContainer.inSpark;
    const inMobile = useIsMobile();

    const tabListStyle = css`
        ${tabStripStyle};
        ${(inSpark && inMobile) && sparkMobileTabStripStyle};
    `;

    const indicatorGradient = `linear-gradient(267deg, ${dark_sky_blue}, ${ocean_blue});`;

    function renderTab(name: string) {
        return (
            <Tab selectedClassName={activeTabStyle} className={`${tabStyle} ac-tab-${name}`} key={name}>
                <span>{name}</span>
                <TabIndicator color={indicatorGradient} className={tabIndicatorStyle} />
            </Tab>
        );
    }
    return (
        <Tabs forceRenderTabPanel={props.forceRenderTabPanel} className={props.top ? topTabLayoutStyle : tablayoutStyle} defaultIndex={props.selectedIndex} onSelect={props.onTabSelected}>
            <TabList className={`${tabListStyle}`}>
                {props.tabs.map(renderTab)}
            </TabList>
            {props.children}
        </Tabs>
    );
}

const sparkMobileTabStripStyle = css`
    height: 44px;
    align-items: center;
`;

const tabStripStyle = css`
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 80px;
    list-style: none;
    border-bottom: solid 1px ${silver_two};
`;

const tabStyle = css`
    display: flex;
    flex-direction: column;
    width: 22%;
    color: black;
    text-align: center;
    cursor: pointer;
    span {
        flex-grow: 1;
        line-height: 80px;
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0.5px;
        color: ${dark_grey_blue};
    }
    div {
        display: none;
        flex-grow: 0;
        flex-shrink: 0;
    }
    ${breakpoint_small} {
        width: 100%;
        span {
            font-size: 14px;
            text-align: auto;
        }
    }
`;

const activeTabStyle = css`
    color: ${ocean_blue};
    div {
        display: block;
    }
`;

const tablayoutStyle = css`
margin-top: 80px;
background: ${blue_white};
`;

const topTabLayoutStyle = css`
    margin-top: 0;
    background: ${blue_white};
`;

const tabIndicatorStyle = css`
    margin: -2px auto 0 auto;
`;

export default TabbedLayout;
