import IResult, { ResultType } from "./IResult";

export default class InvalidResult<T> implements IResult<T> {
    data: T = null;
    resultType: ResultType = "Invalid";
    errors: string[];

    constructor (error: string) {
        this.errors = [error];
    }
}