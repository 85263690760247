import { GET_FEATURES, GET_SYSTEM_FEATURES } from '../../constants/Urls';
import IResult from '../../models/result/IResult';
import { sendPost, sendGet, sendPut, sendDelete } from './generic';
import FeatureModel from '../../models/features/api/FeatureModel';
import ApplicationFeatureModel from '../../models/features/api/ApplicationFeatureModel';
import FeatureTypeModel from '../../models/features/FeatureTypeModel';
import UpdateApplicationFeatureRequest from '../../models/features/api/UpdateApplicationFeatureRequest';
import ConversationCountModel from '../../models/features/api/ConversationCountModel';
import WebhookInstanceModel from '../../models/webhooks/WebhookInstanceModel';
import WebhookParametersRequest from '../../models/webhooks/api/WebhookParametersRequest';
import GenericContentItemModel from '../../models/features/api/GenericContentItemModel';
import CreateApplicationFeatureRequest from '../../models/features/api/CreateApplicationFeatureRequest';
import BulkWebhookInstancesUpdateRequest from '../../models/webhooks/api/BulkWebhookInstancesUpdateRequest';
import ContentItemPublishHistoryModel from '../../models/features/api/ContentItemPublishHistoryModel';
import ContentItemReferenceModel from '../../models/applications/api/backups/ContentItemReferenceModel';


export const getFeaturesForApp = (applicationId: string): Promise<IResult<ApplicationFeatureModel[]>> => {
    return sendGet<ApplicationFeatureModel[]>(`${GET_FEATURES}/${applicationId}/features`);
}

export const getApplicationFeature = (appFeatureId: string): Promise<IResult<ApplicationFeatureModel>> => {
    return sendGet<ApplicationFeatureModel>(`${GET_FEATURES}/${appFeatureId}`);
}

export const getSystemFeatures = (): Promise<IResult<FeatureModel[]>> => {
    return sendGet<FeatureModel[]>(GET_SYSTEM_FEATURES);
}
export const addFeature = (applicationId: string, request: CreateApplicationFeatureRequest): Promise<IResult<ApplicationFeatureModel>> => {
    return sendPost<ApplicationFeatureModel>(`/feature/${applicationId}/AddFeature`, request)
}

export const getFeatureTypes = (): Promise<IResult<FeatureTypeModel[]>> => {
    return sendGet<FeatureTypeModel[]>('/Feature/types');
}

export const disableApplicationFeature = (applicationFeatureId: string): Promise<IResult<ApplicationFeatureModel>> => {
    return sendDelete<ApplicationFeatureModel>(`/Feature/${applicationFeatureId}/disable`);
}

export const updateApplicationFeature = (applicationFeatureId: string, model: UpdateApplicationFeatureRequest): Promise<IResult<ApplicationFeatureModel>> => {
    return sendPut<ApplicationFeatureModel>(`/Feature/${applicationFeatureId}/update`, model);
}

export const getAppFeatureConversationCount = (applicationId: string): Promise<IResult<ConversationCountModel[]>> => {
    return sendGet<ConversationCountModel[]>(`/Feature/${applicationId}/features/conversationCounts`);
}

export const setFeatureOrder = (applicationId: string, parentApplicationFeatureId: string, applicationFeatureIds: string[]): Promise<IResult<ApplicationFeatureModel[]>> => {
    return sendPut(`/Feature/${applicationId}/reorderFeatures?parentApplicationFeatureId=${parentApplicationFeatureId ? parentApplicationFeatureId : ''}`, applicationFeatureIds);
}

export const setFeatureContentItemsOrder = (applicationFeatureId: string, contentItems: ContentItemReferenceModel[]): Promise<IResult<ApplicationFeatureModel>> => {
    return sendPut(`/Feature/${applicationFeatureId}/reorderContentItems`, contentItems);
}

export const changeFeatureModule = (applicationFeatureId: string, applicationModuleId: string): Promise<IResult<ApplicationFeatureModel>> => {
    return sendPut(`/Feature/${applicationFeatureId}/changeModule/${applicationModuleId}`, {})
}

export const getApplicationFeatureWebhooks = (applicationFeatureId: string): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendGet(`/Feature/${applicationFeatureId}/webhooks`);
}

export const removeApplicationFeatureWebhook = (applicationFeatureWebhookId: string): Promise<IResult<WebhookInstanceModel>> => {
    return sendDelete(`/Feature/webhook/${applicationFeatureWebhookId}`);
}

export const addApplicationFeatureWebhook = (applicationFeatureId: string, webhookId: string, string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPost(`/Feature/${applicationFeatureId}/webhooks/${webhookId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const updateApplicationFeatureWebhook = (applicationFeatureId: string, webhookInstanceId: string, defaultParameters?: WebhookParametersRequest, userDefinedParameters?: WebhookParametersRequest): Promise<IResult<WebhookInstanceModel>> => {
    return sendPut(`/Feature/${applicationFeatureId}/webhooks/${webhookInstanceId}`, { values:defaultParameters, userDefinedParameters: userDefinedParameters });
}

export const getContentItemsForFeature = (applicationFeatureId: string): Promise<IResult<GenericContentItemModel[]>> => {
    return sendGet(`/Feature/${applicationFeatureId}/content`)
}

export const bulkUpdateFeatureWebhookInstances = (appFeatureId: string, updateRequest?: BulkWebhookInstancesUpdateRequest): Promise<IResult<WebhookInstanceModel[]>> => {
    return sendPut(`/Feature/${appFeatureId}/webhooks/bulk`, updateRequest);
}

export const getApplicationFeaturePublishHistory = (applicationFeatureId: string, environmentId: string, conversationItemHistorySkip: number, conversationItemHistoryTake: number): Promise<IResult<ContentItemPublishHistoryModel[]>> => {
    return sendGet(`/Feature/${applicationFeatureId}/publishes/forEnvironment/${environmentId}?conversationItemHistorySkip=${conversationItemHistorySkip}&conversationItemHistoryTake=${conversationItemHistoryTake}`);
}