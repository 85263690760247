import React from 'react';
import { css } from '@emotion/css';
import { ConversationTrackingTreeContentNode } from '../../../models/analytics/api/ConversationEventTrackingTreeNode';
import { style_border_default } from '../../../constants/stylesValues';
import { color_colors_growth, color_colors_royalty, color_shades_light } from '../../../constants/colors';
import NodeSocket from './NodeSocket';
const powerIcon = require('../../../content/images/analytics-icons/start.svg');
interface SessionEndNodeProps {
    selected: boolean
    active: boolean
    percentage?: number
    onClick: () => void
}
const SessionStartNode: React.SFC<SessionEndNodeProps> = (props) => {
    let percentage: string | number = props.percentage == null ? null : props.percentage * 100;
    if (percentage != null)
        percentage = percentage !== Math.round(percentage) ? percentage.toFixed(2) : percentage
    return (
        <div className={`${containerStyle} ${props.active ? 'active' : 'inactive'}`} id="start-session-node">
            <div className="percentage-container">
                {percentage != null && <p>{percentage}%</p>}
            </div>
            <div className="node-card" onClick={props.onClick}>
                <div className="card-body">
                    <img src={powerIcon} alt="session starts" />
                    <p>Session Starts</p>
                </div>
                <div className="out-socket">
                    <NodeSocket direction="out" active={props.selected} />
                </div>
            </div>
        </div>
    );
}

const containerStyle = css`
    width: 120px;
    margin: 0 12px;
    position: relative;
    &.inactive {
        opacity: 0.4;
    }
    .percentage-container {
        display: flex;
        position: absolute;
        top: -16px;
        right: 0;
        >p {
            margin-right: 0;
            margin-left: auto;
            color: ${color_colors_royalty};
            text-align: right;   
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .node-card {
        cursor: pointer;
        width: 120px;
        height: 80px;
        border-radius: 4px;
        border: ${style_border_default};
        background: ${color_colors_growth};
        padding: 8px 0;
        position: relative;
        .card-body {
            background: white;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            >p {
                text-align: right;
                font-size: 12px;
                line-height: 16px;
            }
        }

        .out-socket {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            position: absolute;
            bottom: -5px;
            z-index: 3;
        }
    }
`;

export default SessionStartNode;