import { css } from '@emotion/css';
import React, { useEffect } from 'react';
import { color_shades_darker } from '../../../constants/colors';
import NotificationsContainer from '../../../hooks/NotificationsContainer';
import NotificationsListItem from './NotificationListItem';
const notificationsNone = require('../../../content/images/account-settings/notifications-none.svg');

const NotificationsList = () => {
    const notificationsHook = NotificationsContainer.useContainer();
    const markNotificationAsRead = (idx: number) => {
        var notification = notificationsHook.notifications[idx];
        if (!notification.isRead)
            notificationsHook.markNotificationAsRead(notification.id);
    }
    const notifications = notificationsHook.notifications;
    return (
        <div className={containerStyle}>
            {
                notifications.length > 0 ?
                    (notifications.map((n, idx) =>
                        <div key={idx + "div"}>
                            <NotificationsListItem
                                notification={n}
                                key={idx}
                                onTouch={() => markNotificationAsRead(idx)} />
                        </div>
                    ))
                    :
                    (<div className={noNotifications}>
                        <img src={notificationsNone} />
                        <div>No Notifications Yet...</div>
                    </div>)
            }
        </div>
    )
    
}
const containerStyle = css`
    overflow: hidden;
    margin-bottom: 136px;
`

const noNotifications = css`
    font-style: italic;
    font-size: 16px;
    line-height: 24px;
    padding-top: 64px;
    text-align: center;
    color: ${color_shades_darker};
    img {
        height: 72px;
        width: 72px;
        margin-bottom: 24px;
    }
`

export default NotificationsList;