import axios from 'axios';
import AuthenticationResponse from '../../models/auth/api/AuthenticationResponse';
import { SIGN_IN, SINGLE_SIGN_ON } from '../../constants/Urls';
import AuthenticationRequest from '../../models/auth/api/AuthenticationRequest';
import IResult from '../../models/result/IResult';
import SuccessResult from '../../models/result/SuccessResult';
import { queryStringSerialize, sendPost } from './generic';
import SignInRequestModel from '../../models/auth/SignInRequestModel';
import { VOICIFY_CLIENT_ID, getVoicifyClientSecret } from "../../constants/Keys";
import RefreshRequest from '../../models/auth/RefreshRequest';
import SingleSignOnRequest from '../../models/auth/api/SingleSignOnRequest';
import SingleSignOnResponse from '../../models/auth/api/SingleSignOnResponse';
import SignInRequest from '../../models/auth/api/SignInRequest';
import RefreshTokenRequest from '../../models/auth/api/RefreshTokenRequest';

// functions for sending requests to the authentication api
export const signIn = (model: SignInRequestModel): Promise<IResult<AuthenticationResponse>> => {

    const authModel: SignInRequest = {
        username: model.email,
        password: model.password,
        grant_type: "password",
        client_id: VOICIFY_CLIENT_ID,
        client_secret: getVoicifyClientSecret()
    };

    return sendPost<AuthenticationResponse>(`${SIGN_IN}/signIn`,authModel);
};

export const beginSingleSignOnSignIn = (ssoRequest: SingleSignOnRequest): Promise<IResult<SingleSignOnResponse>> => {
    ssoRequest.clientId = VOICIFY_CLIENT_ID;
    ssoRequest.clientSecret = getVoicifyClientSecret();
    return sendPost<SingleSignOnResponse>(`${SINGLE_SIGN_ON}/signIn`, ssoRequest);
};

export const beginSingleSignOnSignUp = (ssoRequest: SingleSignOnRequest): Promise<IResult<SingleSignOnResponse>> => {
    ssoRequest.clientId = VOICIFY_CLIENT_ID;
    ssoRequest.clientSecret = getVoicifyClientSecret();
    return sendPost<SingleSignOnResponse>(`${SINGLE_SIGN_ON}/signUp`, ssoRequest);
};

export const refreshToken = (model: RefreshRequest): Promise<IResult<AuthenticationResponse>> => {
    const authModel: RefreshTokenRequest = {
        username: model.email,
        grant_type: "refresh_token",
        client_id: VOICIFY_CLIENT_ID,
        client_secret: getVoicifyClientSecret(),
        refresh_token: model.refreshToken
    };

    return sendPost<AuthenticationResponse>(`${SIGN_IN}/token`, authModel);
};
