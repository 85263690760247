import { useState } from "react";
import ApplicationModel from "../models/applications/api/ApplicationModel";
import { createContainer } from "unstated-next";
import * as voicifyApi from "../api/controllers/outboundCall";
import { OutboundCallModel } from "../models/features/api/OutboundCall/OutboundCallModel";
import * as telephonyDeploymentVoicifyApi from '../../shared/api/controllers/telephonyDeployment';
import * as ApplicationAnalyticsVoicifyApi from '../../shared/api/controllers/applicationAnalytics';
import { TelephonyDeploymentResponse } from "../models/telephonyDeployment/TelephonyDeploymentResponse";
import { CreateOutboundCallRequest } from "../models/features/api/OutboundCall/CreateOutboundCallRequest";
import { OutboundCallRequest } from "../models/outboundCall/outboundCallRequest";

function useOutboundCallContainer() {
    const [application, setApplication] = useState<ApplicationModel>();
    const [organizationId, setOrganizationId] = useState<string>('');
    const [numbersToCall, setNumbersToCall] = useState<OutboundCallModel[]>([]);
    const [assistantNumbers, setAssistantNumbers] = useState<{ number: string, name: string }[]>([]);

    async function placeOutboundCall(outboundCallRequest: OutboundCallRequest) {
        try {
            const result = await voicifyApi.placeOutboundCall(outboundCallRequest);
            return result;
        }
        catch (e) {
            console.log(e);
            return { resultType: "Unexpected", data: null, errors: [e.message] };
        }
    }

    async function deleteOutboundCall(outboundCallId: string) {
        try {
            const result = await voicifyApi.deleteOutboundCall(organizationId, application.id, outboundCallId);
            return result;
        } catch (e) {
            console.log(e);
            return { resultType: "Unexpected", data: null, errors: [e.message] };
        }
    }

    async function fetchAssistantNumbers(applicationId: string) {
        try {
            const assistantNumbers = await telephonyDeploymentVoicifyApi.getTelephonyDeployments(applicationId);
            if (assistantNumbers.resultType === 'Ok') {
                //Only the real phone numbers should be shown (virtual number does not work for outbound calls)
                setAssistantNumbers(assistantNumbers.data
                    .filter((x: TelephonyDeploymentResponse) => x.virtualNumber === "")
                    .map((x: TelephonyDeploymentResponse) => ({ number: x.phoneNumber, name: x.name })));
            }
        }
        catch (e) {
            console.log(e);
        }

    }

    async function fetchOutboundCalls(startDate: string, endDate: string) {
        try {
            if (!organizationId) {
                return;
            }
            const numbersResult = await voicifyApi.getOutboundCalls(organizationId, application.id, { startDate, endDate });
            if (numbersResult.resultType === "Ok") {
                setNumbersToCall(numbersResult.data);
            }
        } catch (e) {
            console.log(e);
        }


    }

    async function createOutboundCall(request: CreateOutboundCallRequest) {
        try {
            const result = await voicifyApi.createOutboundCall(organizationId, application.id, request);
            return result;
        }
        catch (e) {
            console.log(e);
            return { resultType: "Unexpected", data: null, errors: [e.message] };
        }
    }

    async function getOutboundCallAnsweredEvents(startDate: string, endDate: string) {
        try {
            const result = await ApplicationAnalyticsVoicifyApi.getOutboundCallAnsweredEventsByApplication(application.id, { startDate, endDate });
            return result;
        }
        catch (e) {
            console.log(e);
            return { resultType: "Unexpected", data: null, errors: [e.message] };
        }
    }


    return {
        application,
        setApplication,
        organizationId,
        setOrganizationId,
        numbersToCall,
        setNumbersToCall,
        deleteOutboundCall,
        assistantNumbers,
        fetchAssistantNumbers,
        fetchOutboundCalls,
        createOutboundCall,
        placeOutboundCall,
        getOutboundCallAnsweredEvents,
    }
}


const OutboundCallContainer = createContainer(useOutboundCallContainer);
export default OutboundCallContainer;