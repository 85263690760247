import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue, color_shades_dark } from '../../constants/colors';

interface RadioGroupProps {
    onChange: (selectedOption: RadioOption) => void
    options: RadioOption[]
    value: string | boolean
    disabled?: boolean
    selectedOptionStyle?: string
    checkContainerStyle?: string
}

export interface RadioOption {
    label?: string
    detailOverride?: () => JSX.Element
    value: string | boolean
    icon?: string
}


class RadioGroup extends React.Component<RadioGroupProps> {

    handleOptionSelected(option: RadioOption) {
        if(this.props.onChange) this.props.onChange(option);
    }

    render() {
        return (
            <div className={`${containerStyle} ${this.props.disabled ? 'disabled' : ''}`}>
                {this.props.options.map((option, index) => (
                    <div className="radio-option" key={index} onClick={this.props.disabled ? undefined : this.handleOptionSelected.bind(this, option)}>
                        <div className={this.props.checkContainerStyle ? this.props.checkContainerStyle : `check-container`}>
                                {option.value == this.props.value ? <div className={this.props.selectedOptionStyle ? this.props.selectedOptionStyle : `selected-option`} /> : null}
                        </div>
                        {option.icon ? <img className="option-icon" src={option.icon}/> : null}
                        {option.label ? <p className="label">{option.label}</p> : <div className="label-filler"/>}
                        {option.detailOverride && option.detailOverride()}
                    </div>
                ))}
            </div>
        )
    }
}

const containerStyle = css`
    margin: 16px 0;
    >*{
    }

    &.disabled {
        .radio-option {
            cursor: not-allowed;
            .selected-option {
                background: ${color_shades_dark};
            }
        }
    }
    .radio-option {
        margin: 8px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

                
        .check-container {
            position: relative;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ffffff;
            border: solid 1px #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .selected-option {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-image: linear-gradient(225deg, #76c3b5, #3ea6ac 72%, #5296b3);
        }

        .label {
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${dark_grey_blue};
            margin-left: 16px;
            word-break: break-all;
            flex: 1;
        }
        .label-filler {
            margin-left: 16px;
        }
        .detail {            
            font-size: 14px;
            line-height: 20px;
            margin-top: 8px;
            margin-left: 16px;
        }
    }

    .option-icon {
        margin-left: 16px;
    }
`;
export default RadioGroup;