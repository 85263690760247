import React, { useState } from "react"
import { createContainer } from "unstated-next"
import IResult from "../models/result/IResult";
import OrgSetupResponse from "../models/orgSetup/OrgSetupResponse";
import OrgSetupRequest from "../models/orgSetup/OrgSetupRequest";
import * as voicifyApi from '../api';

function useOrgSetupContainer() {
    const [errors, setErrors] = useState([] as string[]);
    const [isLoading, setIsLoading] = useState(false);
    const [orgSetup, setOrgSetup] = useState(null as OrgSetupResponse);

    const setupOrg = async (orgSetupRequest: OrgSetupRequest): Promise<IResult<OrgSetupResponse>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.setupOrgForFeature(orgSetupRequest);
            if (result.resultType == "Ok") {
                setOrgSetup(result.data);
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    return {
        errors,
        isLoading,
        setupOrg,
        orgSetup
    };
}

const OrgSetupContainer = createContainer(useOrgSetupContainer);
export default OrgSetupContainer;
