import React from "react";
import { css } from "@emotion/css";
import "rc-tooltip/assets/bootstrap.css";
import TooltipWrapper from "./TooltipWrapper";

interface IconTooltipProps {
  text?: string;
  className?: string;
  place?: string
  replacementContentStyle?: string;
}
const IconTooltip: React.FC<IconTooltipProps> = (props) => (
  <TooltipWrapper
    place={props.place === undefined ? "top" : props.place}
    text={props.text}
    replacementContentStyle={props.replacementContentStyle}
  >
    <div className={`${tooltipStyle} ${props.className}`}>
      <span>?</span>
    </div>
  </TooltipWrapper>
);

const tooltipStyle = css`
  width: 17px;
  height: 17px;
  border: solid 1px #007dbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    width: 6px;
    height: 17px;
    font-family: sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #007dbb;
  }
`;

export default IconTooltip;
