import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, silver_two, faded_red, cool_grey } from '../../constants/colors';
import { Field } from 'formik';
import FieldError from './FieldError';
import Button from '../general/Button';
import IconTooltip from '../general/IconTooltip';
import TextareaAutosize from 'react-textarea-autosize';
import VerticalSeparator from '../structure/VerticalSeparator';
import TooltipWrapper from '../general/TooltipWrapper';
const deleteIcon = require('../../content/images/trash.svg');

// component used in building forms with consistent components for look and function
interface TextFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: any) => void
    onFocus?: (e) => void
    onDelete?: () => void
    label?: string
    id?: string
    canDelete?: boolean
    required?: boolean
    tooltip?: string
    className?: string
    icon?: string
    minRows?: number
    maxRows?: number
    useForm?: boolean
    stopPropagation?: boolean
}
class TextAreaField extends React.Component<TextFieldProps, {}> {
    render() {
        const props = {
            ...this.props
        };
        const showLabel = props.label !== undefined || props.canDelete || props.tooltip !== undefined || props.required;
        return (
            <div className={`${containerStyle} ${props.className}`} onClick={(e) => {props.stopPropagation ? e.stopPropagation(): null}}>
                {showLabel && <div className={labelContainer}>
                    {props.label !== undefined ? <label htmlFor={props.name} className={labelStyle}>{props.label}</label> : null}
                    {props.canDelete ? <Button additionalClasses={deleteIconStyle} type="button" onClick={props.onDelete} themes={["icon"]} icon={deleteIcon} /> : null}
                    {props.tooltip !== undefined ? <IconTooltip text={props.tooltip} className={tooltipStyle} place="top" /> : null}
                    {props.required ? <TooltipWrapper text="Required field"><span className={requiredLabelStyle}>*</span></TooltipWrapper> : null}
                </div>}
                <div className={fieldContainer}>
                    {props.icon !== undefined ?
                        <img src={props.icon} /> : null}
                    <TextareaAutosize id={props.id}
                        className={`${textFieldStyles} ${props.icon == null ? noBorder : ''}`}
                        type={props.type}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        minRows={props.minRows}
                        maxRows={props.maxRows}
                        disabled={props.disabled} />
                </div>

                {props.useForm ? <FieldError name={props.name} /> : <></>}
            </div>
        );
    }
}


const textFieldStyles = css`
    color: ${dark_grey_blue};
    font-family: Muli;
    text-align: left;
    width: 100%;
    border: none; 
    padding: 8px 16px;
    font-size: 14px;
    margin: 12px 0;
    resize: vertical;
    border-left: 1px solid ${silver_two};
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        letter-spacing: normal;
        color: ${cool_grey};
    }
`;

const noBorder = css`
    border-left: none;
`;
const containerStyle = css`
margin-bottom: 24px;

&.tight {
    margin-bottom: 8px;
    label {
        margin: 8px 16px 0 16px;
    }
}
`
const labelStyle = css`
font-family: Muli;
font-size: 14px;
font-weight: 600;
text-align: left;
margin: 0 16px;
`;

const deleteIconStyle = css`
    height: 14px;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 0;
    img {
        height: 14px;
    }
`

const tooltipStyle = css`
    margin: 0;
`

const labelContainer = css`
display: flex;
align-items: center;
`

const requiredLabelStyle = css`
    margin-right: 0;
    margin-left: auto;
    margin-top: 14px;
    margin-bottom: -20px;
    font-family: Muli;
    font-size: 24px;
    color: ${faded_red};
`


const fieldContainer = css`
display: flex;
align-items: center;
width: 100%;
border-radius: 5px;
border: solid 1px ${silver_two}; 
background: white;
margin-top: 8px;
img {
    margin: 16px;
}
`
export default TextAreaField;