import { Container } from 'unstated';
import AnalyticsRequestFilter from '../../models/analytics/AnalyticsRequestFilter';
import AnalyticsTrackTree from '../../models/analytics/AnalyticsTrackTree';
import * as voicifyApi from '../../api';
import GenericAnalyticsContainer from './GenericAnalyticsContainer';
import { ConversationTrackingTreeBaseNode, ConversationTrackingTreeContentNode } from '../../models/analytics/api/ConversationEventTrackingTreeNode';
import { ca } from 'date-fns/locale';
import { FallbackFeatureTypeId } from '../../constants/featureTypeIds';

interface ApplicationTrackingContainerState {
    isLoading: boolean
    isLoadingDetails: boolean
    errors: string[]
    trees: AnalyticsTrackTree[]
}

export default class ApplicationTrackingContainer extends GenericAnalyticsContainer<ApplicationTrackingContainerState> {
    displayOptions: string[] = [];
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isLoadingDetails: false,
            errors: [],
            trees: []
        }
    }

    async loadTree(applicationId: string, filter: AnalyticsRequestFilter) {
        await this.setLoading(true);
        try {
            const result = await voicifyApi.getApplicationConversationTracking(applicationId, filter);
            const trees = this.state.trees;
            if (result?.resultType == "Ok") {
                trees.push({
                    id: applicationId,
                    filter: filter,
                    data: result.data,
                    conversationItems: []
                })
            }

            await this.setState({
                ...this.state,
                isLoading: false,
                trees,
                errors: []
            })

            return result;

        } catch {
            await this.setLoading(false);
        }
    }

    async loadChildNodeDetails(applicationId: string, filter: AnalyticsRequestFilter, node: ConversationTrackingTreeBaseNode) {
        await this.setLoadingDetails(true);
        try {
            if (node.childNodes.length < 1)
                return null;
            const result = await voicifyApi.bulkLoadDetails({
                applicationId: applicationId,
                content: node.childNodes.filter(c => c.conversationTrackingNodeType == "Content")?.map(c => ({
                    id: (c as ConversationTrackingTreeContentNode).contentId,
                    featureTypeId: (c as ConversationTrackingTreeContentNode).featureTypeId ?? FallbackFeatureTypeId
                }))
            });

            if (result?.resultType == "Ok") {
                const trees = this.state.trees;
                const tree = this.findTree(applicationId, filter);
                const index = trees.indexOf(tree);
                if (!tree)
                    return null;
                const conversationItems = tree.conversationItems ?? [];
                result?.data?.forEach(c => {
                    if (!conversationItems.some(ci => c.id == ci.id)) {
                        conversationItems.push(c);
                    }
                });

                tree.conversationItems = conversationItems;
                trees[index] = tree;
                await this.setState({
                    ...this.state,
                    isLoadingDetails: false,
                    trees,
                    errors: []
                })

                // if we have any that were not found, try them in fallback
                const missedNodes = node.childNodes?.filter(c => c.conversationTrackingNodeType == "Content" && !result.data?.some(r => r.id == (c as ConversationTrackingTreeContentNode)?.contentId))
                if (missedNodes?.length > 0) {
                    const result = await voicifyApi.bulkLoadDetails({
                        applicationId: applicationId,
                        content: missedNodes?.map(c => ({
                            id: (c as ConversationTrackingTreeContentNode).contentId,
                            featureTypeId: FallbackFeatureTypeId
                        }))
                    });

                    if (result?.resultType == "Ok") {
                        const trees = this.state.trees;
                        const tree = this.findTree(applicationId, filter);
                        const index = trees.indexOf(tree);
                        if (!tree)
                            return null;
                        const conversationItems = tree.conversationItems ?? [];
                        result?.data?.forEach(c => {
                            if (!conversationItems.some(ci => c.id == ci.id)) {
                                conversationItems.push(c);
                            }
                        });

                        tree.conversationItems = conversationItems;
                        trees[index] = tree;
                        await this.setState({
                            ...this.state,
                            isLoadingDetails: false,
                            trees,
                            errors: []
                        })
                    }
                }
            }

            return result;
        } catch {
            await this.setLoadingDetails(false);
        }
    }
    protected setLoadingDetails(isLoadingDetails: boolean) {
        this.setState({
            ...this.state,
            isLoadingDetails
        })
    }


    findTree(applicationId: string, filter: AnalyticsRequestFilter) {
        return this.state.trees.find(h => this.buildCacheKey(h.id, h.filter) == this.buildCacheKey(applicationId, filter));
    }
}