import IResult from "../../models/result/IResult";
import { sendGet, sendPost, sendPut, sendDelete } from "./generic";
import AppliedApplicationTemplateModel from "../../models/templating/api/AppliedApplicationTemplateModel";
import UpdateAppliedApplicationTemplateFormRequest from "../../models/templating/api/UpdateAppliedApplicationTemplateFormRequest";
import FinishTemplateSectionRequest from "../../models/templating/api/FinishTemplateSectionRequest";
import DynamicFormResponse from "../../models/templating/api/DynamicFormResponse";
import FinalizeTemplateFormRequest from "../../models/templating/api/FinalizeTemplateFormRequest";
import FinalizeTemplateFormResult from "../../models/templating/api/FinalizeTemplateFormResult";
import { AutoGenerateAppliedAppValuesRequest } from "../../models/templating/api/AutoGenerateAppliedAppValuesRequest";

export const getAppliedApplicationTemplatesForApp = (applicationId: string): Promise<IResult<AppliedApplicationTemplateModel[]>> => {
    return sendGet(`/AppliedApplicationTemplate/forApp/${applicationId}`);
}
export const findAppliedApplicationTemplate = (appliedApplicationTemplateId: string): Promise<IResult<AppliedApplicationTemplateModel>> => {
    return sendGet(`/AppliedApplicationTemplate/${appliedApplicationTemplateId}`);
}
export const deleteAppliedApplicationTemplate = (appliedApplicationTemplateId: string): Promise<IResult<AppliedApplicationTemplateModel>> => {
    return sendDelete(`/AppliedApplicationTemplate/${appliedApplicationTemplateId}`);
}
export const updateAppliedApplicationTemplate = (appliedApplicationTemplateId: string, model: UpdateAppliedApplicationTemplateFormRequest): Promise<IResult<AppliedApplicationTemplateModel>> => {
    return sendPut(`/AppliedApplicationTemplate/${appliedApplicationTemplateId}`, model);
}
export const addAppliedApplicationTemplate = (applicationId: string, tempalteConfigId: string): Promise<IResult<AppliedApplicationTemplateModel>> => {
    return sendPost(`/AppliedApplicationTemplate/${applicationId}/add/${tempalteConfigId}`, {});
}
export const autoGenerateAppliedApplicationTemplateFieldValues = (applicationId: string, request: AutoGenerateAppliedAppValuesRequest): Promise<IResult<AppliedApplicationTemplateModel[]>> => {
    return sendPost(`/AppliedApplicationTemplate/${applicationId}/answers/generate`, request);
}
export const finishTemplateSection = (appliedApplicationTemplateId: string, request: FinishTemplateSectionRequest): Promise<IResult<DynamicFormResponse>> => {
    return sendPut(`/AppliedApplicationTemplate/${appliedApplicationTemplateId}/finishSection`, request);
}
export const finalizeDynamicForm = (appliedApplicationTemplateId: string, request: FinalizeTemplateFormRequest): Promise<IResult<FinalizeTemplateFormResult>> => {
    return sendPut(`/AppliedApplicationTemplate/${appliedApplicationTemplateId}/finalizeForm`, request);
}
