import styled from "@emotion/styled";
import React, { useEffect, useRef } from "react";
import { color_shades_dark, color_shades_darkest, color_variants_blue_2, color_variants_green_2, color_variants_red_2, color_variants_shadow_10 } from "../../constants/colors";
import Button from "./Button";

const closeIcon = require('../../content/images/close_icon.svg');
const redIcon = require('../../content/images/nlp-icons/training-indicator-red.svg');
const greenIcon = require('../../content/images/nlp-icons/training-indicator-green.svg');


const loadingGif = require('../../content/images/nlp-icons/brainimation.gif');

interface StatusIndicatorProps {
    state: "success" | "loading" | "fail"
    successMessage: string
    loadingMessage: string
    failedMessage: string
    isClosable?: boolean
    onClose?: () => void
    onAcceptFailure?: () => void
}

export default function StatusIndicator(props: StatusIndicatorProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
            if (props.isClosable) {
                props.onClose();
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        //clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props]);

    if (props.state === "success") {
        return <IndicatorWrapper ref={wrapperRef}>
            <GreenIndicator>
                <TrainingIcon src={greenIcon} />
                <IndicatorText>{props.successMessage}</IndicatorText>
                {props.isClosable ? <CloseIcon src={closeIcon} onClick={props.onClose} /> : <></>}
            </GreenIndicator>
        </IndicatorWrapper>
    }

    if (props.state === "loading") {
        return <IndicatorWrapper ref={wrapperRef}>
            <BlueIndicator>
                <TrainingGif src={loadingGif} />
                <IndicatorText>{props.loadingMessage}</IndicatorText>
                {props.isClosable ? <CloseIcon src={closeIcon} onClick={props.onClose} /> : <></>}
            </BlueIndicator>
        </IndicatorWrapper>
    }

    if (props.state === "fail") {
        return <IndicatorWrapper ref={wrapperRef}>
            <RedIndicator>
                <TrainingIcon src={redIcon} />
                <IndicatorText>{props.failedMessage}</IndicatorText>

                <AcceptButtonWrapper>
                    <Button
                        themes={["primary-small"]}
                        text={"Got It"}
                        type="button"
                        onClick={props.onAcceptFailure}
                    />
                </AcceptButtonWrapper>
                {props.isClosable ? <CloseIcon src={closeIcon} onClick={props.onClose} /> : <></>}
            </RedIndicator>
        </IndicatorWrapper>
    }

    return <></>
}


const IndicatorText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${color_shades_darkest};
    margin: auto auto auto 50px;
`;

const TrainingGif = styled.img`
    width: 96px;
    height: 92px;
    margin: auto 0px auto 16px;
`;

const TrainingIcon = styled.img`
    width: 88px;
    height: 80px;
    margin: auto 0px auto 16px;
`;

const CloseIcon = styled.img`
    margin: auto 32px auto auto;
    width: 12px;
    height: 12px;
`;

const IndicatorWrapper = styled.div`
    position: fixed;
    bottom: 32px;
    right: 32px;
    width: 528px;
    height: 104px;
    border-radius: 8px;
    border: 1px solid ${color_shades_dark};
    box-shadow: 0px 4px 20px ${color_variants_shadow_10};
    z-index: 1100;
    background: white;
    box-sizing: border-box;
    cursor: pointer;
`;

const Indicator = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    border-radius: 8px;
    width: 100%;
`;

const GreenIndicator = styled(Indicator)`
    background: ${color_variants_green_2};
`;

const RedIndicator = styled(Indicator)`
    background: ${color_variants_red_2};
`;

const BlueIndicator = styled(Indicator)`
    background: ${color_variants_blue_2};
`;

const AcceptButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`