import IResult, { ResultType } from "./IResult";

export default class UnexpectedResult<T> implements IResult<T> {
    data: T = null;
    resultType: ResultType = "Unexpected";
    errors: string[];

    constructor () {
        this.errors = ["An unexpected error has occurred. Please try again later."];
    }
}