import React, { useState } from 'react';
import SettingsCard from '../../../../../shared/components/structure/SettingsCard';
import ManageSparkLocationsForm from './ManageSparkLocationsForm';
import OrganizationContainer from '../../../../../shared/state/containers/OrganizationContainer';
import ApplicationModel from '../../../../../shared/models/applications/api/ApplicationModel';
import TemplateFormSectionModel from '../../../../../shared/models/templating/api/TemplateFormSectionModel';
import TemplateFormFieldModel from '../../../../../shared/models/templating/api/TemplateFormFieldModel';
import ApplicationContainer from '../../../../../shared/state/containers/ApplicationContainer';
import { css } from '@emotion/css';

interface OrganizationApplicationsProps {
    appContainer: ApplicationContainer
    orgContainer: OrganizationContainer
    commonApp: ApplicationModel
    locationApps?: ApplicationModel[]
    locationAppNames: string[]
    commonAppSections: TemplateFormSectionModel[]
    locationAppFormFields: TemplateFormFieldModel[];
    isLoadingTemplates: boolean
    history: any
    padding?: string
}

const OrganizationApplications: React.FC<OrganizationApplicationsProps> = (props) => {
    const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);

    return (
        <SettingsCard
            title="Location Setup"
            bodyStyle={bodyStyle}
            isLoading={props.isLoadingTemplates}
        >
            <ManageSparkLocationsForm
                appContainer={props.appContainer}
                orgContainer={props.orgContainer}
                commonApp={props.commonApp}
                locationApps={props.locationApps}
                locationAppNames={props.locationAppNames}
                commonAppSections={props.commonAppSections}
                locationAppFormFields={props.locationAppFormFields}
                isLoadingTemplates={props.isLoadingTemplates}
                history={props.history}
                isSubmittingForm={isSubmittingForm}
                setIsSubmittingForm={setIsSubmittingForm}
                padding={props.padding}
            />
        </SettingsCard>
    );
}

const bodyStyle = css`
    padding: 0px;
`;

export default OrganizationApplications;