import IResult from "../../models/result/IResult";
import { sendGet, sendPost, sendPut, sendDelete } from "./generic";
import WebhookFolderModel from "../../models/webhooks/api/WebhookFolderModel";
import BulkWebhookRequest from "../../models/features/api/BulkWebhookRequest";

export const getWebhookFolder = (folderId: string): Promise<IResult<WebhookFolderModel>> => {
    return sendGet(`/WebhookFolder/${folderId}`);
}

export const getWebhookFoldersForApplication = (applicationId: string): Promise<IResult<WebhookFolderModel[]>> => {
    return sendGet(`/WebhookFolder/forApplication/${applicationId}`);
}

export const createWebhookFolder = (model: WebhookFolderModel): Promise<IResult<WebhookFolderModel>> => {
    return sendPost(`/WebhookFolder`, model);
}

export const updateWebhookFolder = (folderId: string, model: WebhookFolderModel): Promise<IResult<WebhookFolderModel>> => {
    return sendPut(`/WebhookFolder/${folderId}`, model);
}

export const deleteWebhookFolder = (folderId: string): Promise<IResult<WebhookFolderModel>> => {
    return sendDelete(`/WebhookFolder/${folderId}`);
}

export const setWebhookFolderOrder = (applicationId: string, parentFolderId: string, folderIds: string[]): Promise<IResult<WebhookFolderModel>> => {
    return sendPut(`/WebhookFolder/${applicationId}/reorder?parentFolderId=${parentFolderId ? parentFolderId : ''}`, folderIds);
}

export const moveWebhooksAndFoldersToFolder = (folderId: string, request: BulkWebhookRequest): Promise<IResult<string>> => {
    return sendPut(`/WebhookFolder/moveToFolder?folderId=${folderId}`, request);
}

export const copyWebhooksAndFoldersToFolder = (folderId: string, request: BulkWebhookRequest): Promise<IResult<string>> => {
    return sendPut(`/WebhookFolder/copyToFolder?folderId=${folderId}`, request);
}

export const bulkDeleteWebhooksAndFolders = (request: BulkWebhookRequest): Promise<IResult<string>> => {
    return sendPut(`/WebhookFolder/bulkDelete`, request);
}