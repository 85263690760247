import React from "react";
import { Provider, Subscribe } from 'unstated';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { Redirect, RouteComponentProps } from "react-router";
import AuthContainer from "../../state/containers/AuthContainer";
import FrontContainer from "../../components/structure/FrontContainer";
import SplitContainer from "../../components/structure/SplitContainer";
import LandingContainer from "../../components/structure/LandingContainer";
import UserContainer from "../../state/containers/UserContainer";
import ResetSuccess from "./components/ResetSuccess";
import ResetPasswordForm from "./components/ResetPasswordForm";

interface ForgotPasswordProps {
    resetToken: string
}
class ResetPassword extends React.Component<RouteComponentProps<ForgotPasswordProps>> {
    render() {
        return (
            <LandingContainer>
                <Subscribe to={[UserContainer, AuthContainer]}>
                    {(user: UserContainer, auth: AuthContainer) => {

                        return (
                            <FrontContainer>
                                {user.state.isSetPasswordSent 
                                    ? <ResetSuccess history={this.props.history}/> 
                                    : <ResetPasswordForm stateContainer={user as UserContainer} resetToken={this.props.match.params.resetToken}/>
                                }
                            </FrontContainer>
                        );
                    }
                    }
                </Subscribe>
            </LandingContainer>
        );
    }
};

export default ResetPassword;