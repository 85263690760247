import { useState } from "react";
import * as voicifyApi from '../api';
import AnalyticsRequestFilter from "../models/analytics/AnalyticsRequestFilter";
import { createContainer } from "unstated-next";

function useContentContainer() {
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false)

    const getSession = async (applicationId: string, contentId: string, filter: AnalyticsRequestFilter) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.getContentItemSessionIds(applicationId, contentId, filter);
            if (result.resultType == "Ok") {
                setIsLoading(false);
                return result.data.sessionIds;
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return null;
        } catch (error) {
            setIsLoading(false);
            setErrors([error]);
            return null;
        }
    };

    return {
        isLoading,
        getSession,
        errors
    }
}

const ContentContainer = createContainer(useContentContainer);
export default ContentContainer;