import React from 'react';
import { Subscribe } from 'unstated';
import SsmlContainer from '../../../state/containers/SsmlContainer';
import ExitMessageContainer from '../../../state/containers/ExitMessageContainer';
import CustomRequestContainer from '../../../state/containers/CustomRequestContainer';
import HelpMessageContainer from '../../../state/containers/HelpMessageContainer';
import FallbackMessageContainer from '../../../state/containers/FallbackMessageContainer';
import WelcomeMessageContainer from '../../../state/containers/WelcomeMessageContainer';
import QuestionAnswerContainer from '../../../state/containers/QuestionAnswerContainer';
import LatestMessageContainer from '../../../state/containers/LatestMessageContainer';
import SimpleChoiceContainer from '../../../state/containers/SimpleChoiceContainer';
import NumberRangeContainer from '../../../state/containers/NumberRangeContainer';
import {
    WelcomeMessagesFeatureTypeId, ExitMessagesFeatureTypeId, HelpMessagesFeatureTypeId, QuestionAnswerFeatureTypeId, LatestMessagesFeatureTypeId, FallbackFeatureTypeId,
    SimpleChoiceFeatureTypeId, NumberRangeFeatureTypeId, CustomRequestsFeatureTypeId
} from '../../../constants/featureTypeIds';
import AnalyticsInterval from '../../../models/analytics/AnalyticsInterval';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import UserApplicationPreferencesContainer from '../../../state/containers/UserApplicationPreferencesContainer';
import ApplicationSessionAnalytics from './ApplicationSessionAnalytics';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';

interface SessionAnalyticsWrapperProps {
    interval: AnalyticsInterval
    innerInterval: AnalyticsInterval
    filter: AnalyticsRequestFilter
    applicationId: string,
    ssmlContainer: SsmlContainer
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    history: any
    envContainer: ApplicationEnvironmentsContainer
}

const SessionAnalyticsWrapper: React.FC<SessionAnalyticsWrapperProps> = (props) => (
    <Subscribe to={[ CustomRequestContainer, ExitMessageContainer, FallbackMessageContainer, HelpMessageContainer,
        LatestMessageContainer, SimpleChoiceContainer, NumberRangeContainer, QuestionAnswerContainer, WelcomeMessageContainer]}>
        {(
            customRequestContainer: CustomRequestContainer,
            exitContainer: ExitMessageContainer,
            fallbackContainer: FallbackMessageContainer,
            helpContainer: HelpMessageContainer,
            latestMessageContainer: LatestMessageContainer,
            simpleChoiceContainer: SimpleChoiceContainer,
            numberRangeContainer: NumberRangeContainer,
            questionAnswerContainer: QuestionAnswerContainer,
            welcomeContainer: WelcomeMessageContainer
        ) => {
            const contentContainers = {};
            contentContainers[CustomRequestsFeatureTypeId] = customRequestContainer;
            contentContainers[ExitMessagesFeatureTypeId] = exitContainer;
            contentContainers[FallbackFeatureTypeId] = fallbackContainer;
            contentContainers[HelpMessagesFeatureTypeId] = helpContainer;
            contentContainers[LatestMessagesFeatureTypeId] = latestMessageContainer;
            contentContainers[SimpleChoiceFeatureTypeId] = simpleChoiceContainer;
            contentContainers[NumberRangeFeatureTypeId] = numberRangeContainer;
            contentContainers[QuestionAnswerFeatureTypeId] = questionAnswerContainer;
            contentContainers[WelcomeMessagesFeatureTypeId] = welcomeContainer;

            return (
                <ApplicationSessionAnalytics
                    {...props}
                    contentContainers={contentContainers}/>
            );
        }}
    </Subscribe>
);

export default SessionAnalyticsWrapper;
