import React from 'react';
import { css } from '@emotion/css';
import { RouteComponentProps } from 'react-router';
import Button from '../../../components/general/Button';

interface ResetProps {
    history: any;
}

const buttonSplitStyle = css`
    margin-top: 60px;
    margin-bottom: -56px;
`
class ResetSuccess extends React.Component<ResetProps>
{
    handleBack() {
        this.props.history.push('/login');
    }
    render() {
        return (
            <div>
                <h2>New Password Set</h2>
                <p>Your new password is all set. Now use it to sign in again!</p>
                <Button themes={["primary", "wide"]} type="button" text="Back to Login" additionalClasses={buttonSplitStyle} onClick={this.handleBack.bind(this)}/>
            </div>
        );
    }
}

export default ResetSuccess;