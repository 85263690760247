import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark } from '../../../constants/colors';

interface PanelHeaderProps {
    className?: string
}
const PanelHeaderContainer: React.FC<PanelHeaderProps> = (props) => (
    <div className={`${headerStyle} ${props.className ?? ''} panel-header`}>
        {props.children}
    </div>
)

const headerStyle = css`
    height: 56px;
    width: 100%;
    background-color: white;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
    border-bottom: 1px solid ${color_shades_dark};
`;

export default PanelHeaderContainer;