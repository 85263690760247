import React from 'react';
import { css } from '@emotion/css';
import { Option } from 'react-select';
import { color_shades_darkest } from '../../constants/colors';
import RoundedSelectField from './RoundedSelectField';

// component used in building forms with consistent components for look and function
interface SelectProps {
    value?: string
    disabled?: boolean
    onChange?: (e: Option) => void
    options: Option[]
    label?: string
    className?: string
}

const FlatRoundedSelectField: React.FC<SelectProps> = (props) => (    
    <div className={`${containerStyle} ${props.className}`}>
        <div className={leftSideLabel}>{props.label}</div>
        <RoundedSelectField
            disabled={props.disabled ?? false}
            options={props.options}
            onChange={props.onChange}
            value={props.value}/>
    </div>
)

const containerStyle = css`
    display: flex;
    flex-direction: row;
    .Select-control {
        box-shadow: none;
    }
`;

const leftSideLabel = css`    
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${color_shades_darkest};
    margin-right: 16px;
    margin-top: 8px;
`;


export default FlatRoundedSelectField;