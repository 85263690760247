import React from "react";
import * as voicifyApi from '../../api';
class MicrosoftAuth extends React.Component {
    componentWillMount() {
        voicifyApi.microsoftAuthCallback(window.location.search).then(res => {
            window.open(window.location.origin, "_self")
        })
    }

    render() {
        return (<div></div>)
    }
}

export default MicrosoftAuth;