import React from 'react';
import { css } from '@emotion/css';
import { Pie } from '@nivo/pie';
import { color_colors_ocean, color_shades_light } from '../../constants/colors';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import { style_border_default } from '../../constants/stylesValues';
interface GaugeProps {
    percentage: number
    value: number
    label: string
    size: number
}

const SingleItemGauge: React.SFC<GaugeProps> = (props) => {
    const multiplier =  props.percentage / 100;

    const ToolTip = () => {
        return <div className={tooltipStyle}>
            <p className="value-label">{props.value}</p>
        </div>
    }
    return (
        <Pie
            data={[{
                id: props.label,
                label: props.label,
                value: props.percentage,
                color: color_colors_ocean
            },{
                id: 'empty',
                label: 'none',
                value: 100 - props.percentage,
                color: color_shades_light
            }]}
            width={props.size}
            height={props.size}
            margin={{ top: 4, right: 4, bottom:4, left: 4 }}
            enableRadialLabels={false}
            enableSliceLabels={false}
            innerRadius={0.6}
            colors={(d) => (d.data.color as string)}
            borderWidth={0}
            fit={false}
            isInteractive={true}
            tooltip={(c) => ToolTip()}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        />
    );
}
const tooltipStyle = css`
    background: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .value-label {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
`


export default SingleItemGauge;