import React from 'react';
import { css } from '@emotion/css';
import { color_variants_shadow_10, color_shades_light } from '../../constants/colors';
const gridViewIconBlack = require('../../content/images/layout-module-1-dark.svg');
const gridViewIconLight = require('../../content/images/layout-module-1-light.svg');
const tableViewIconBlack = require('../../content/images/layout-bullets-dark.svg');
const tableViewIconLight = require('../../content/images/layout-bullets-light.svg');

// component used in building forms with consistent components for look and function
interface ViewProps {
    updateViewValue: () => void
    selectedOption: boolean
    label?: string
}

const container = css`
    width: 25%;
    align-items: center;
    cursor: pointer;
    .number-container {
        display: inline-block;
        box-shadow: 0 2px 4px 0 ${color_variants_shadow_10};
        border: solid 1px #cccccc;
        border-radius: 15px;
        box-sizing: border-box;
        .flexer {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            div {
                box-sizing: border-box;
                padding: 5px;
                padding-left: 13px;
                padding-right: 13px;
                &.selected {
                    background: ${color_shades_light};
                }
                &.left {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }
                &.right {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }
    }
`

class RoundedTwoOptionButton extends React.Component<ViewProps, {}> {
    render() {
        return (
            <div onClick={this.props.updateViewValue.bind(this)} className={`${container} view-container selector`}>  
                {this.props.label === undefined ? null : <div className={'label view'}>{this.props.label}</div>}  
                {
                    !this.props.selectedOption ? 
                    <div className="number-container">
                        <div className="flexer">
                            <div className="selected left"><img src={gridViewIconLight}/></div>
                            <div className="right"><img src={tableViewIconBlack}/></div>
                        </div>
                    </div>
                    :
                    <div className="number-container">
                        <div className="flexer">
                            <div className="left"><img src={gridViewIconBlack}/></div>
                            <div className="selected right"><img src={tableViewIconLight}/></div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default RoundedTwoOptionButton;