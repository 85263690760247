import React from 'react';
import {css} from '@emotion/css';
import { cool_grey, color_shades_dark } from '../../../constants/colors';
import HorizontalSeparator from '../HorizontalSeparator';
import ToggleIndicator from '../../general/ToggleIndicator';
const forkIcon = require('../../../content/images/fork.svg');

interface ConversationBranchSwitcherProps {
    position: number
}
interface ConversationBranchSwitcherState {
    position: number
}

const indicatorStyle  = css`
    width: 10px;
    height: 10px;
    border: solid 1px ${cool_grey};
    border-radius: 50%;
    margin: 3px 4px;
    cursor: pointer;
`

const selectedIndicatorStyle = css`
    background: ${cool_grey};
`

const headerStyle = css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 32px;
`

const innerHeaderStyle = css`
    flex: 1;
    height: 64px;
    display: flex;
    align-items: center;
    border-radius: 32px;
    background-color: #ffffff;
    border: solid 1px ${color_shades_dark};
    padding: 0 8px;
    span {
        display: block;
        font-family: Muli;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: ${cool_grey};
    }
`

const iconContainer = css`
    min-width: 40px;
    min-height: 40px;
    background-color: #304562;
    border-radius: 50%;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const indicatorContainer = css`
    display: flex;
    align-items: center;
    min-width: 180px;
    .toggle-indicator {
        cursor: pointer;
        border-color: ${cool_grey};
    }
`


class ConversationBranchSwitcher extends React.Component<ConversationBranchSwitcherProps, ConversationBranchSwitcherState> {
    componentWillMount() {
        this.setState({
            position: this.props.position
        })
    }

    componentDidUpdate(previousProps: ConversationBranchSwitcherProps) {
        if(previousProps.position != this.props.position) {
            this.setState({
                position: this.props.position
            })
        }
    }
    setPosition(position: number) {
        if(position == this.state.position) return;

        this.setState({
            position: position
        })
    }
    getChildren() {
        return React.Children.map(this.props.children, x => x);
    }
    render() {
        var children = this.getChildren();
        return (
            <div>
                <div className={headerStyle}>
                    <HorizontalSeparator />
                        <div className={innerHeaderStyle}>
                            <div className={iconContainer}>
                                <img src={forkIcon}/>
                            </div>
                            <div>
                                <span>Conversation Forks Here</span>
                                <div className={indicatorContainer}>
                                    <ToggleIndicator direction="left" onClick={() => {
                                        if(this.state.position > 0) 
                                            this.setPosition(this.state.position - 1)
                                        else
                                            this.setPosition(children.length - 1)
                                    }}/>
                                        {children.map((x,i) => (
                                            <div key={i} className={`${indicatorStyle} ${i == this.state.position ? selectedIndicatorStyle : ''}`} onClick={() => this.setPosition(i)}></div>
                                        ))}
                                    <ToggleIndicator direction="right"onClick={() => {
                                        if(this.state.position < children.length - 1) 
                                            this.setPosition(this.state.position + 1)
                                        else 
                                            this.setPosition(0)
                                    }}/>
                                </div>
                            </div>
                        </div>
                    <HorizontalSeparator />
                </div>
                {children.filter((child, index) => index == this.state.position)}
            </div>
        )
    }
}

export default ConversationBranchSwitcher;