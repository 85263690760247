import ApiKeyModel from "../../models/auth/api/ApiKeyModel";
import ApiKeyResult from "../../models/auth/api/ApiKeyResult";
import NewApiKeyRequest from "../../models/auth/api/NewApiKeyRequest";
import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost } from "./generic";

export const createOrganizationAdminApiKey = (organizationId:string, request: NewApiKeyRequest) : Promise<IResult<ApiKeyResult>> => {
    return sendPost(`/ApiKey/organization/${organizationId}/admin`, request);
}

export const getOrganizationApiKeys = (organizationId:string) : Promise<IResult<ApiKeyModel[]>> => {
    return sendGet(`/ApiKey/organization/${organizationId}`);
}

export const deleteApiKey = (apiKeyId :string) : Promise<IResult<ApiKeyModel>> => {
    return sendDelete(`/ApiKey/${apiKeyId}`);
}