import React, { useEffect } from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue_10 } from '../../../constants/colors';
import MenuItem from './MenuItem';
import HorizontalSeparator from '../../structure/HorizontalSeparator';

interface MenuProps {
    onOptionSelected?: (optionName: string, event?: MouseEvent) => void
    options: OverflowOption[]
    onClose: () => void
    className?: string
    optionClassName?: string
    topOptionStyle?: string
    bottomOptionStyle?: string
}

export interface OverflowOption {
    label: string
    icon?: string
    isDestructive?: boolean
    isDisabled?: boolean
    hoverIcon?: string
    subOptions?: OverflowOption[]
    tooltip?: string
}

const OverflowMenu: React.FC<MenuProps> = (props) => {

    const handleDocumentClick = (e: any) => {
        if (e.target.closest(`.${menuStyle}`)) return;
        e.stopPropagation();

        props.onClose();
    }

    const handleLinkClicked = (name: string, event?: any,) => {
        if (event) {
            event.stopPropagation();
        }
        if (props.onOptionSelected && event) {
            props.onOptionSelected(name, event);
        }
        props.onClose();
    }

    const documentCloser = handleDocumentClick.bind(this);

    useEffect(() => {
        document.addEventListener("click", documentCloser, true);
        return () => {
            document.removeEventListener("click", documentCloser, true);
        }
    }, []);

    return (
        <div className={`${menuStyle} ${props.className}`}>
            <div className={`${triangleStyle} triangle`}></div>
            {props.options.map((o, i) => (
                <div key={i}>
                    <MenuItem
                        isDisabled={o.isDisabled}
                        className={`${i === 0 ? `${topItemStyle} ${props.topOptionStyle ?? ''}` : null} ${i === props.options.length - 1 ? `${bottomItemStyle} ${props.bottomOptionStyle ?? ''}` : null} ${props.optionClassName}`}
                        option={o}
                        onClick={(event) => {
                            handleLinkClicked(o.label, event)
                        }}
                        onSubClick={(label, event) => {
                            handleLinkClicked(label, event)
                        }} />
                    {i < props.options.length - 1 ? <HorizontalSeparator /> : null}
                </div>
            ))}
            {props.children}
        </div>
    )
}
const menuStyle = css`
    width: 320px;
    border: solid 1px #cccccc;
    background: white;
    box-shadow: 0 2px 8px - ${dark_grey_blue_10};
    border-radius: 12px;
    position: absolute;
    margin-left: -285px;
    margin-top: 12px;
    box-shadow: 0 4px 4px 0 ${dark_grey_blue_10};
    z-index: 99;
`
const triangleStyle = css`
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-bottom: -6px;
    border-top: solid 1px #cccccc;
    border-left: solid 1px #cccccc;
    background: white;
    margin-right: 16px;
    margin-left: auto;
`
const topItemStyle = css`
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
`
const bottomItemStyle = css`
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
`

export default OverflowMenu;