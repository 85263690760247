import React from 'react';
import { css } from '@emotion/css';
import { Subscribe } from 'unstated';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import UserContainer from '../../state/containers/UserContainer';
import Profile from './components/Profile';

const ProfileMenu: React.FC = () => {
    return (
        <Subscribe to={[OrganizationContainer, UserContainer]}>
            {(orgContainer: OrganizationContainer, userContainer: UserContainer) => {
                return (
                    <div className={containerStyle}>
                        <Profile
                            organizationContainer={orgContainer}
                            userContainer={userContainer}
                        />
                    </div>
                );
            }}
        </Subscribe>
    )
}

export default ProfileMenu;

const containerStyle = css`    
    margin-right: 32px;
    margin-left: 24px;
`;