import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, color_variants_main_bg } from '../../../constants/colors';

interface FullPagePanelProps {
    className?: string
}
const FullPagePanel: React.SFC<FullPagePanelProps> = (props) => {
    return (
        <div className={`${panelStyle} ${props.className ?? ''}`}>
            {props.children}
        </div>
    )
};

const panelStyle = css`
    background: ${color_variants_main_bg};
    position: absolute;
    font-family: Muli;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    overflow: visible;
    height: 100vh;
    width: 100vw;
    z-index: 1;
`;

export default FullPagePanel;